<template>
    <div class="ford2fr">            
      <div class="act">               
         <h class="pagetitle">Ford#2 Front</h>
         <div class="modalButtonContainer">
         <button class="button" @click="showModal = true">+ Result</button>
         <button class="button" @click="showdtModal = true">+ Downtime</button>
         <button class="button" @click="showdrModal = true">+ Daily Report</button>         
     <!--    <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>      -->
         <button class="button" @click="showrdModal = true">+ Repeat Data</button>
        </div>
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>     
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-4P8S': row.model === '4P8S', 'model-4P7S': row.model === '4P7S' , 'model-3P10S': row.model === '3P10S', 'model-3P8S': row.model === '3P8S'}" @click="editCellValue(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCellValue(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>                
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> 
                <td v-if="header === 'Fake Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> 

                <td v-if="header === 'Laser Marking Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lasermarkingsum === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lasermarkingsum', $event)">{{ row.ng_lasermarkingsum}}</td>
                <td v-if="header === 'LM Heat Staking'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingheatstaking === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lasermarkingheatstaking', $event)">{{ row.ng_lasermarkingheatstaking }}</td>
                <td v-if="header === 'LM Busbar'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingbusbar === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lasermarkingbusbar', $event)">{{ row.ng_lasermarkingbusbar }}</td>
                <td v-if="header === 'LM Assembly'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingassembly === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lasermarkingassembly', $event)">{{ row.ng_lasermarkingassembly }}</td>
                <td v-if="header === 'LM QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lasermarkingqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lasermarkingqrcode', $event)">{{ row.ng_lasermarkingqrcode }}</td>
                <td v-if="header === 'Middle Insp. Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_middleinspsum === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_middleinspsum', $event)">{{ row.ng_middleinspsum }}</td>
                <td v-if="header === 'MI Rivet height'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinsprivetheight === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_middleinsprivetheight', $event)">{{ row.ng_middleinsprivetheight }}</td>
                <td v-if="header === 'MI Damaged rivet'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinspdamagedrivet === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_middleinspdamagedrivet', $event)">{{ row.ng_middleinspdamagedrivet }}</td>
                <td v-if="header === 'MI Assembly'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinspassembly === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_middleinspassembly', $event)">{{ row.ng_middleinspassembly }}</td>
                <td v-if="header === 'MI QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_middleinspqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_middleinspqrcode', $event)">{{ row.ng_middleinspqrcode }}</td>
                
                <td v-if="header === 'AOI NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_aoingsum === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_aoingsum', $event)">{{ row.ng_aoingsum }}</td>
                <td v-if="header === 'AOI Soldering'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingsoldering === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_aoingsoldering', $event)">{{ row.ng_aoingsoldering }}</td>
                <td v-if="header === 'AOI Heat Staking'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingheatstaking === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_aoingheatstaking', $event)">{{ row.ng_aoingheatstaking }}</td>
                <td v-if="header === 'AOI Riveting'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingriveting === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_aoingriveting', $event)">{{ row.ng_aoingriveting }}</td>
                <td v-if="header === 'AOI QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_aoingqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_aoingqrcode', $event)">{{ row.ng_aoingqrcode }}</td>

                <td v-if="header === 'Coating NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingngsum === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingngsum', $event)">{{ row.ng_coatingngsum }}</td>
                <td v-if="header === 'Insfficient Coating'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingnginsufficientcoating === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingnginsufficientcoating', $event)">{{ row.ng_coatingnginsufficientcoating }}</td>
                <td v-if="header === 'Coating height'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingngcoatingheight === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingngcoatingheight', $event)">{{ row.ng_coatingngcoatingheight }}</td>
                <td v-if="header === 'Excessive coating'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingngexcessivecoating === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingngexcessivecoating', $event)">{{ row.ng_coatingngexcessivecoating }}</td>
                <td v-if="header === 'Coating QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_coatingngqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingngqrcode', $event)">{{ row.ng_coatingngqrcode }}</td>

                <td v-if="header === 'EOL NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eolngsum === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_eolngsum', $event)">{{ row.ng_eolngsum }}</td>
                <td v-if="header === 'Open circuit'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolngopencircuit === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_eolngopencircuit', $event)">{{ row.ng_eolngopencircuit }}</td>
                <td v-if="header === 'Over resistance'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolngoverresistance  === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_eolngoverresistance', $event)">{{ row.ng_eolngoverresistance }}</td>
                <td v-if="header === '2P connectors'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolng2pconnectors === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_eolng2pconnectors', $event)">{{ row.ng_eolng2pconnectors }}</td>
                <td v-if="header === 'EOL QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_eolngqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_eolngqrcode', $event)">{{ row.ng_eolngqrcode }}</td>

                <td v-if="header === 'LQC NG Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lqcngsum === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngsum', $event)">{{ row.ng_lqcngsum }}</td>
                <td v-if="header === 'LQC Busbars'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngbusbar === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngbusbar', $event)">{{ row.ng_lqcngbusbar }}</td>
                <td v-if="header === 'LQC Carrier'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngcarrier === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngcarrier', $event)">{{ row.ng_lqcngcarrier }}</td>
                <td v-if="header === 'LQC Bubbles'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngbubblesincoating  === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngbubblesincoating', $event)">{{ row.ng_lqcngbubblesincoating }}</td>
                <td v-if="header === 'LQC FPCB'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngfpcb === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngfpcb', $event)">{{ row.ng_lqcngfpcb }}</td>
                <td v-if="header === 'LQC Connector'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngconnector === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngconnector', $event)">{{ row.ng_lqcngconnector }}</td>
                <td v-if="header === 'LQC Damaged Rivets'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngdamagedrivets === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngdamagedrivets', $event)">{{ row.ng_lqcngdamagedrivets }}</td>
                <td v-if="header === 'LQC Bottom pins'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngbottompins === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngbottompins', $event)">{{ row.ng_lqcngbottompins }}</td>
                <td v-if="header === 'LQC QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_lqcngqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lqcngqrcode', $event)">{{ row.ng_lqcngqrcode }}</td>

                <td v-if="header === 'Final Insp. Sum'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_finalinspngsum === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngsum', $event)">{{ row.ng_finalinspngsum }}</td>
                <td v-if="header === 'FI Busbars'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngbusbar === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngbusbar', $event)">{{ row.ng_finalinspngbusbar }}</td>
                <td v-if="header === 'FI Carrier'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngcarrier === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngcarrier', $event)">{{ row.ng_finalinspngcarrier }}</td>
                <td v-if="header === 'FI Bubbles'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_ng_finalinspngbubblesincoating === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngbubblesincoating', $event)">{{ row.ng_finalinspngbubblesincoating }}</td>
                <td v-if="header === 'FI FPCB'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngfpcb === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngfpcb', $event)">{{ row.ng_finalinspngfpcb }}</td>
                <td v-if="header === 'FI Connector'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngconnector === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngconnector', $event)">{{ row.ng_finalinspngconnector }}</td>
                <td v-if="header === 'FI Damaged rivets'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngdamagedrivets === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngdamagedrivets', $event)">{{ row.ng_finalinspngdamagedrivets }}</td>
                <td v-if="header === 'FI Bottom pins'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngbottompins === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngbottompins', $event)">{{ row.ng_finalinspngbottompins }}</td>
                <td v-if="header === 'FI QR code'" :key="index" class="ng_sub_type" :class="{ 'zero-value_ngsubtype': row.ng_finalinspngqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_finalinspngqrcode', $event)">{{ row.ng_finalinspngqrcode }}</td>
                
                <td v-if="header === 'Fake Laser Marking Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lasermarkingsum === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lasermarkingsum', $event)">{{ row.fn_lasermarkingsum }}</td> 
                <td v-if="header === 'FLM Heat Staking'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lasermarkingheatstaking === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lasermarkingheatstaking', $event)">{{ row.fn_lasermarkingheatstaking }}</td>
                <td v-if="header === 'FLM QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lasermarkingqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lasermarkingqrcode', $event)">{{ row.fn_lasermarkingqrcode }}</td>
                
                <td v-if="header === 'Fake Middle Insp. Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_middleinspsum === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_middleinspsum', $event)">{{ row.fn_middleinspsum }}</td> 
                <td v-if="header === 'FMI Rivet height'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_middleinsprivetheight === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_middleinsprivetheight', $event)">{{ row.fn_middleinsprivetheight }}</td>
                <td v-if="header === 'FMI QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_middleinspqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_middleinspqrcode', $event)">{{ row.fn_middleinspqrcode }}</td>

                <td v-if="header === 'Fake AOI Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_aoingsum === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_aoingsum', $event)">{{ row.fn_aoingsum }}</td> 
                <td v-if="header === 'FA Soldering'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingsoldering === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_aoingsoldering', $event)">{{ row.fn_aoingsoldering }}</td>
                <td v-if="header === 'FA Heat Staking pin'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingheatstaking === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_aoingheatstaking', $event)">{{ row.fn_aoingheatstaking }}</td>
                <td v-if="header === 'FA Riveting'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingriveting === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_aoingriveting', $event)">{{ row.fn_aoingriveting }}</td>
                <td v-if="header === 'FA QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_aoingqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_aoingqrcode', $event)">{{ row.fn_aoingqrcode }}</td>

                <td v-if="header === 'Fake Coating Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_coatingsum === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_coatingsum', $event)">{{ row.fn_coatingsum }}</td> 
                <td v-if="header === 'FC Insufficient coating'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatinginsufficientcoating === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_coatinginsufficientcoating', $event)">{{ row.fn_coatinginsufficientcoating }}</td>
                <td v-if="header === 'FC Bubbles'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatingbubbles === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_coatingbubbles', $event)">{{ row.fn_coatingbubbles }}</td>
                <td v-if="header === 'FC Excessive coating'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatingexcessivecoating === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_coatingexcessivecoating', $event)">{{ row.fn_coatingexcessivecoating }}</td>
                <td v-if="header === 'FC QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_coatingqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_coatingqrcode', $event)">{{ row.fn_coatingqrcode }}</td>

                <td v-if="header === 'Fake EOL Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_eolsum === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_eolsum', $event)">{{ row.fn_eolsum }}</td> 
                <td v-if="header === 'FE Open circuit'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eolopencircuit === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_eolopencircuit', $event)">{{ row.fn_eolopencircuit }}</td>
                <td v-if="header === 'FE Over resistance'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eoloverresistance === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_eoloverresistance', $event)">{{ row.fn_eoloverresistance }}</td>
                <td v-if="header === 'FE 2P connectors'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eol2pconnectors === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_eol2pconnectors', $event)">{{ row.fn_eol2pconnectors }}</td>
                <td v-if="header === 'FE QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_eolqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_eolqrcode', $event)">{{ row.fn_eolqrcode }}</td>                

                <td v-if="header === 'Fake LQC Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_lqcsum === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lqcsum', $event)">{{ row.fn_lqcsum }}</td> 
                <td v-if="header === 'FL Busbars'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lqcbusbars === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lqcbusbars', $event)">{{ row.fn_lqcbusbars }}</td>
                <td v-if="header === 'FL QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_lqcqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_lqcqrcode', $event)">{{ row.fn_lqcqrcode }}</td>

                <td v-if="header === 'Fake Final Insp. Sum'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_finalinspsum === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_finalinspsum', $event)">{{ row.fn_finalinspsum }}</td> 
                <td v-if="header === 'FFI Busbars'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_finalinspbusbar === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_finalinspbusbar', $event)">{{ row.fn_finalinspbusbar }}</td>
                <td v-if="header === 'FFI QR code'" :key="index" class="fn_sub_type" :class="{ 'zero-value_fnsubtype': row.fn_finalinspqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_finalinspqrcode', $event)">{{ row.fn_finalinspqrcode }}</td>

                <td v-if="header === 'NG Comp. Sum'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_sum === 0 }" @click="editCellValue(dbTableName, row.id, 'nc_sum', $event)">{{ row.nc_sum }}</td>
                <td v-if="header === 'NG Comp. Carrier'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_carrier === 0 }" @click="editCellValue(dbTableName, row.id, 'nc_carrier', $event)">{{ row.nc_carrier }}</td>
                <td v-if="header === 'NG Comp. Busbar'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_busbar === 0 }" @click="editCellValue(dbTableName, row.id, 'nc_busbar', $event)">{{ row.nc_busbar }}</td>
                <td v-if="header === 'NG Comp. Connector'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_connector === 0 }" @click="editCellValue(dbTableName, row.id, 'nc_connector', $event)">{{ row.nc_connector }}</td>
                <td v-if="header === 'NG Comp. FPCB'" :key="index" class="ngcomp" :class="{ 'zero-value_ngcomp': row.nc_fpcb === 0 }" @click="editCellValue(dbTableName, row.id, 'nc_fpcb', $event)">{{ row.nc_fpcb }}</td>

                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCellValue(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCellValue(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCellValue(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCellValue(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
         <tr> <!--Shift 1 Subtotal-->
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{  shift1FnTotal }}</td>
          <td>{{ ((shift1FnTotal)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingassembly}}</td>
          <td>{{ sums.shiftSums[1].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspsum}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspassembly}}</td>
          <td>{{ sums.shiftSums[1].ng_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingsum}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingriveting}}</td>
          <td>{{ sums.shiftSums[1].ng_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.shiftSums[1].ng_coatingngqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngopencircuit}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngoverresistance}}</td>
          <td>{{ sums.shiftSums[1].ng_eolng2pconnectors}}</td>
          <td>{{ sums.shiftSums[1].ng_eolngqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngbusbar}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngcarrier}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngfpcb}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngconnector}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngbottompins}}</td>
          <td>{{ sums.shiftSums[1].ng_lqcngqrcode}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngsum}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngbusbar}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngcarrier}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngfpcb}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngconnector}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngbottompins}}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspngqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[1].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_middleinspsum}}</td>
          <td>{{ sums.shiftSums[1].fn_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[1].fn_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingsum}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingriveting}}</td>
          <td>{{ sums.shiftSums[1].fn_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingsum}}</td>
          <td>{{ sums.shiftSums[1].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingbubbles}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.shiftSums[1].fn_coatingqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_eolsum}}</td>
          <td>{{ sums.shiftSums[1].fn_eolopencircuit}}</td>
          <td>{{ sums.shiftSums[1].fn_eoloverresistance}}</td>
          <td>{{ sums.shiftSums[1].fn_eol2pconnectors}}</td>
          <td>{{ sums.shiftSums[1].fn_eolqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_lqcsum}}</td>
          <td>{{ sums.shiftSums[1].fn_lqcbusbars}}</td>
          <td>{{ sums.shiftSums[1].fn_lqcqrcode}}</td>
          <td>{{ sums.shiftSums[1].fn_finalinspsum}}</td>
          <td>{{ sums.shiftSums[1].fn_finalinspbusbar}}</td>
          <td>{{ sums.shiftSums[1].fn_finalinspqrcode}}</td>
          <td>{{ sums.shiftSums[1].nc_sum}}</td>
          <td>{{ sums.shiftSums[1].nc_carrier}}</td>
          <td>{{ sums.shiftSums[1].nc_busbar}}</td>
          <td>{{ sums.shiftSums[1].nc_connector}}</td>
          <td>{{ sums.shiftSums[1].nc_fpcb}}</td>          
        </tr>
        <tr> <!--Shfit 2 Subtotal-->
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{  shift2FnTotal }}</td>
          <td>{{ ((shift2FnTotal)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingassembly}}</td>
          <td>{{ sums.shiftSums[2].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspsum}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspassembly}}</td>
          <td>{{ sums.shiftSums[2].ng_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingsum}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingriveting}}</td>
          <td>{{ sums.shiftSums[2].ng_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.shiftSums[2].ng_coatingngqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngopencircuit}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngoverresistance}}</td>
          <td>{{ sums.shiftSums[2].ng_eolng2pconnectors}}</td>
          <td>{{ sums.shiftSums[2].ng_eolngqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngbusbar}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngcarrier}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngfpcb}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngconnector}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngbottompins}}</td>
          <td>{{ sums.shiftSums[2].ng_lqcngqrcode}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngsum}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngbusbar}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngcarrier}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngfpcb}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngconnector}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngbottompins}}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspngqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_lasermarkingsum}}</td>
          <td>{{ sums.shiftSums[2].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_middleinspsum}}</td>
          <td>{{ sums.shiftSums[2].fn_middleinsprivetheight}}</td>
          <td>{{ sums.shiftSums[2].fn_middleinspqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingsum}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingsoldering}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingheatstaking}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingriveting}}</td>
          <td>{{ sums.shiftSums[2].fn_aoingqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingsum}}</td>
          <td>{{ sums.shiftSums[2].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingbubbles}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.shiftSums[2].fn_coatingqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_eolsum}}</td>
          <td>{{ sums.shiftSums[2].fn_eolopencircuit}}</td>
          <td>{{ sums.shiftSums[2].fn_eoloverresistance}}</td>
          <td>{{ sums.shiftSums[2].fn_eol2pconnectors}}</td>
          <td>{{ sums.shiftSums[2].fn_eolqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_lqcsum}}</td>
          <td>{{ sums.shiftSums[2].fn_lqcbusbars}}</td>
          <td>{{ sums.shiftSums[2].fn_lqcqrcode}}</td>
          <td>{{ sums.shiftSums[2].fn_finalinspsum}}</td>
          <td>{{ sums.shiftSums[2].fn_finalinspbusbar}}</td>
          <td>{{ sums.shiftSums[2].fn_finalinspqrcode}}</td>
          <td>{{ sums.shiftSums[2].nc_sum}}</td>
          <td>{{ sums.shiftSums[2].nc_carrier}}</td>
          <td>{{ sums.shiftSums[2].nc_busbar}}</td>
          <td>{{ sums.shiftSums[2].nc_connector}}</td>
          <td>{{ sums.shiftSums[2].nc_fpcb}}</td>     
        </tr>
        <tr> <!--4P8S Subtotal-->
         <th rowspan="4">Model</th>
          <td>4P8S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['4P8S'].target }}</td>
          <td>{{ sums.modelSums['4P8S'].input }}</td>
          <td>{{ sums.modelSums['4P8S'].ok }}</td>
          <td>{{ sums.modelSums['4P8S'].ng }}</td>
          <td>{{ ((sums.modelSums['4P8S'].ng ) / (sums.modelSums['4P8S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model4p8sFnTotal }}</td>          
          <td>{{ ((model4p8sFnTotal)/(sums.modelSums['4P8S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['4P8S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['4P8S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_sum}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_connector}}</td>
          <td>{{ sums.modelSums['4P8S'].nc_fpcb}}</td>               
        </tr>
        <tr> <!--4P7S Subtotal-->
          <td>4P7S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['4P7S'].target }}</td>
          <td>{{ sums.modelSums['4P7S'].input }}</td>
          <td>{{ sums.modelSums['4P7S'].ok }}</td>
          <td>{{ sums.modelSums['4P7S'].ng }}</td>
          <td>{{ ((sums.modelSums['4P7S'].ng ) / (sums.modelSums['4P7S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model4p7sFnTotal }}</td>
          <td>{{ ((model4p7sFnTotal)/(sums.modelSums['4P7S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['4P7S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['4P7S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_sum}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_connector}}</td>
          <td>{{ sums.modelSums['4P7S'].nc_fpcb}}</td>
        </tr>
        <tr> <!--3P10S Subtotal-->
          <td>3P10S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['3P10S'].target }}</td>
          <td>{{ sums.modelSums['3P10S'].input }}</td>
          <td>{{ sums.modelSums['3P10S'].ok }}</td>
          <td>{{ sums.modelSums['3P10S'].ng }}</td>
          <td>{{ ((sums.modelSums['3P10S'].ng ) / (sums.modelSums['3P10S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model3p10sFnTotal }}</td>
          <td>{{ ((model3p10sFnTotal)/(sums.modelSums['3P10S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['3P10S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['3P10S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_sum}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_connector}}</td>
          <td>{{ sums.modelSums['3P10S'].nc_fpcb}}</td>
        </tr>
        <tr> <!--3P8S Subtotal-->
          <td>3P8S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['3P8S'].target }}</td>
          <td>{{ sums.modelSums['3P8S'].input }}</td>
          <td>{{ sums.modelSums['3P8S'].ok }}</td>
          <td>{{ sums.modelSums['3P8S'].ng }}</td>
          <td>{{ ((sums.modelSums['3P8S'].ng ) / (sums.modelSums['3P8S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ model3p8sFnTotal }}</td>
          <td>{{ ((model3p8sFnTotal)/(sums.modelSums['3P8S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingassembly}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspdamagedrivet}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspassembly}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingnginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngcoatingheight}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_coatingngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngopencircuit}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngoverresistance}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolng2pconnectors}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_eolngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngcarrier}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngfpcb}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngconnector}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngbottompins}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_lqcngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngsum}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngcarrier}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngbubblesincoating}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngfpcb}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngconnector}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngdamagedrivets}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngbottompins}}</td>
          <td>{{ sums.modelSums['3P8S'].ng_finalinspngqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lasermarkingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lasermarkingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lasermarkingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_middleinspsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_middleinsprivetheight}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_middleinspqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingsoldering}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingheatstaking}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingriveting}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_aoingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatinginsufficientcoating}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingbubbles}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingexcessivecoating}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_coatingqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eolsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eolopencircuit}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eoloverresistance}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eol2pconnectors}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_eolqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lqcsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lqcbusbars}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_lqcqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_finalinspsum}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_finalinspbusbar}}</td>
          <td>{{ sums.modelSums['3P8S'].fn_finalinspqrcode}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_sum}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_carrier}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_busbar}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_connector}}</td>
          <td>{{ sums.modelSums['3P8S'].nc_fpcb}}</td>
        </tr>
        <tr> <!--Total-->
         <th colspan="3">Ford#2 Front Total</th>         
          <td>{{ sums.prtotalSums.target }}</td>
          <td>{{ sums.prtotalSums.input  }}</td>
          <td class="ok" >{{ sums.prtotalSums.ok }}</td>
          <td class="ng" >{{ sums.prtotalSums.ng }}</td>
          <td class="ng">{{ ((sums.prtotalSums.ng) / (sums.prtotalSums.input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ fnTotal }} </td>
          <td class="fn">{{ ((fnTotal )/(sums.prtotalSums.input) * 100).toFixed(1) }}%</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_lasermarkingsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingheatstaking}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingbusbar}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingassembly}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lasermarkingqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_middleinspsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinsprivetheight}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinspdamagedrivet}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinspassembly}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_middleinspqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_aoingsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingsoldering}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingheatstaking}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingriveting}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_aoingqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_coatingngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingnginsufficientcoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingngcoatingheight}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingngexcessivecoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_coatingngqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_eolngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolngopencircuit}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolngoverresistance}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolng2pconnectors}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_eolngqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_lqcngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngbusbar}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngcarrier}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngbubblesincoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngfpcb}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngconnector}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngdamagedrivets}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngbottompins}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_lqcngqrcode}}</td>
          <td class="ng_type">{{ sums.prtotalSums.ng_finalinspngsum}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngbusbar}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngcarrier}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngbubblesincoating}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngfpcb}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngconnector}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngdamagedrivets}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngbottompins}}</td>
          <td class="ng_sub_type">{{ sums.prtotalSums.ng_finalinspngqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lasermarkingsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lasermarkingheatstaking}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lasermarkingqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_middleinspsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_middleinsprivetheight}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_middleinspqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_aoingsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingsoldering}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingheatstaking}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingriveting}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_aoingqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_coatingsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatinginsufficientcoating}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatingbubbles}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatingexcessivecoating}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_coatingqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_eolsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eolopencircuit}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eoloverresistance}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eol2pconnectors}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_eolqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_lqcsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lqcbusbars}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_lqcqrcode}}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_finalinspsum}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_finalinspbusbar}}</td>
          <td class="fn_sub_type">{{ sums.prtotalSums.fn_finalinspqrcode}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_sum}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_carrier}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_busbar}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_connector}}</td>
          <td class="ngcomp">{{ sums.prtotalSums.nc_fpcb}}</td>
        </tr>        
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall"> <!--QWall part-->
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader --> 
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCellValue(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index" ></td>
            <td v-if="header === 'Input'" :key="index" ></td>
            <td v-if="header === 'OK'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Laser Marking'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_lasermarking', $event)">{{ qw.ng_lasermarking }}</td>
            <td v-if="header === 'Middle Station'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_middlestation', $event)">{{ qw.ng_middlestation }}</td>
            <td v-if="header === 'AOI'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_aoi', $event)">{{ qw.ng_aoi }}</td>
            <td v-if="header === 'Coating'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_coating', $event)">{{ qw.ng_coating }}</td>
            <td v-if="header === 'EOL'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_eol', $event)">{{ qw.ng_eol }}</td>
            <td v-if="header === 'Final'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_final', $event)">{{ qw.ng_final }}</td>
          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCellValue(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Shift Name'" :key="index" @click="editCellValue(dtTableName, dt.id, 'shiftname', $event)">{{ dt.shiftname }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>      
      <dtModal  v-if="showdtModal" @onClose="showdtModal = false; callSearchData();"/>         
      <drModal  v-if="showdrModal" @onClose="showdrModal = false; callSearchData();"/> 
      <qwModal  v-if="showqwModal" @onClose="showqwModal = false; callSearchData();"/> 
      <rdModal  v-if="showrdModal" @onClose="showrdModal = false; callSearchData();"/> 
  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./ford2fr_prm.vue";
import dtModal from "./ford2fr_prdtm.vue";
import drModal from "./ford2fr_prdrm.vue";
import qwModal from "./ford2fr_qwm.vue";
import rdModal from "./ford2fr_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },    

    setup() {
        const lineName = "ford2fr"  //설비 변경시 필수 교체        
        const dbTableName = "ford2frpr";  //searchData에 있는 값도 바꿔야 함 
        const dtTableName = "ford2frdt";  //searchData에 있는 값도 바꿔야 함
        const drTableName = "ford2frdr";  //searchData에 있는 값도 바꿔야 함
        const qwTableName = "ford2frqw";  //searchData에 있는 값도 바꿔야 함
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'NG', "NG Rate", "Fake Sum", "Fake Rate",
                         "Laser Marking Sum", 'LM Heat Staking', 'LM Busbar', 'LM Assembly', 'LM QR code',
                         'Middle Insp. Sum', 'MI Rivet height', 'MI Damaged rivet', 'MI Assembly', 'MI QR code',
                         'AOI NG Sum', 'AOI Soldering', 'AOI Heat Staking', 'AOI Riveting', 'AOI QR code', 
                         'Coating NG Sum', 'Insfficient Coating', 'Coating height', 'Excessive coating', 'Coating QR code',
                         'EOL NG Sum', 'Open circuit', 'Over resistance', '2P connectors', 'EOL QR code',
                         'LQC NG Sum', 'LQC Busbars', 'LQC Carrier', 'LQC Bubbles', 'LQC FPCB', 'LQC Connector', "LQC Damaged Rivets", 'LQC Bottom pins', 'LQC QR code',
                         'Final Insp. Sum', 'FI Busbars', 'FI Carrier', 'FI Bubbles', 'FI FPCB', 'FI Connector', 'FI Damaged rivets', 'FI Bottom pins', 'FI QR code',
                         'Fake Laser Marking Sum', 'FLM Heat Staking', 'FLM QR code',
                         'Fake Middle Insp. Sum', 'FMI Rivet height', 'FMI QR code',
                         'Fake AOI Sum', 'FA Soldering', 'FA Heat Staking pin', 'FA Riveting', 'FA QR code', 
                         'Fake Coating Sum', 'FC Insufficient coating', 'FC Bubbles', 'FC Excessive coating', 'FC QR code',
                         'Fake EOL Sum', 'FE Open circuit', 'FE Over resistance', 'FE 2P connectors', 'FE QR code',
                         'Fake LQC Sum', 'FL Busbars', 'FL QR code',
                         'Fake Final Insp. Sum', 'FFI Busbars', 'FFI QR code',
                         'NG Comp. Sum', 'NG Comp. Carrier', 'NG Comp. Busbar', 'NG Comp. Connector', 'NG Comp. FPCB',
                         'Remark', 'Work Order', 'Supervisor', 'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Shift Name', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], });   

        //Repeat Data part
        const showrdModal = ref(false);         
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, fn_lasermarkingsum, fn_middleinspsum, fn_aoingsum, fn_coatingsum, fn_eolsum, fn_lqcsum, fn_finalinspsum,) => {
          const input = ok + ng;
          const fakeng = fn_lasermarkingsum + fn_middleinspsum + fn_aoingsum + fn_coatingsum + fn_eolsum + fn_lqcsum + fn_finalinspsum
          let ngRate = 0;
          let fn_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          return { input, ngRate, fakeng, fn_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(d.ok, d.ng, d.fn_lasermarkingsum, d.fn_middleinspsum, d.fn_aoingsum, d.fn_coatingsum, d.fn_eolsum, d.fn_lqcsum, d.fn_finalinspsum );
            return { ...d, input, ngRate, fakeng, fn_ngRate, }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
            console.log('Requesting data with:', {
              tablename: tableName,
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
            });

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };

            prTableData.data = [];
            dtTableData.data = [];
            drTableData.data = [];
            qwTableData.data = [];

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'ford2frpr':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                  case 'ford2frdt':
                    dtTableData.data = res.data;
                    break;
                  case 'ford2frdr':
                    drTableData.data = res.data;
                    break;
                  /*case 'ford2frqw':
                    qwTableData.data = res.data;
                    break;*/
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName);
            searchData(dtTableName);
            searchData(drTableName);
            settingCall();
            //searchData(qwTableName);
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let prtotalSums = { 
              target: 0, input: 0, ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
              fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
              fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
              fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
              fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
              fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
              fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
              nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0,
          };
          let shiftSums = {
            1: { target: 0, input: 0, ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
              fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
              fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
              fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
              fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
              fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
              fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
              nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0,  }, 
          
            2: { target: 0, input: 0, ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
              fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
              fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
              fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
              fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
              fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
              fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
              nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0, }, 
          };

          let modelSums = {
            "4P8S": { target: 0, input: 0, ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
              fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
              fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
              fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
              fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
              fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
              fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
              nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0, }, 
             
            "4P7S": { target: 0, input: 0, ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
              fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
              fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
              fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
              fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
              fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
              fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
              nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0, },  
                      
            "3P10S": { target: 0, input: 0, ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
              fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
              fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
              fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
              fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
              fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
              fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
              nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0, }, 
             
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0,
              fn_lasermarkingsum:0, fn_lasermarkingheatstaking:0, fn_lasermarkingqrcode:0, fn_middleinspsum:0, fn_middleinsprivetheight:0, fn_middleinspqrcode:0, 
              fn_aoingsum:0, fn_aoingsoldering:0, fn_aoingheatstaking:0, fn_aoingriveting:0, fn_aoingqrcode:0, 
              fn_coatingsum:0, fn_coatinginsufficientcoating:0, fn_coatingbubbles:0, fn_coatingexcessivecoating:0, fn_coatingqrcode:0,
              fn_eolsum:0, fn_eolopencircuit:0, fn_eoloverresistance:0, fn_eol2pconnectors:0, fn_eolqrcode:0, 
              fn_lqcsum:0, fn_lqcbusbars:0, fn_lqcqrcode:0, 
              fn_finalinspsum: 0,fn_finalinspbusbar:0, fn_finalinspqrcode:0,
              nc_sum:0, nc_carrier:0, nc_busbar:0, nc_connector:0, nc_fpcb:0, },      
          };
          
          let qwmodelSums = {
            "4P8S": { ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0, }, 
             
            "4P7S": {  ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0, },     
            
            "3P10S": { ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0, }, 
             
            "3P8S": { ok: 0, ng: 0, 
              ng_lasermarkingsum: 0, ng_lasermarkingheatstaking:0, ng_lasermarkingbusbar:0, ng_lasermarkingassembly:0, ng_lasermarkingqrcode:0, 
              ng_middleinspsum: 0, ng_middleinsprivetheight:0, ng_middleinspdamagedrivet:0, ng_middleinspassembly:0, ng_middleinspqrcode:0,
              ng_aoingsum:0, ng_aoingsoldering:0, ng_aoingheatstaking:0, ng_aoingriveting:0, ng_aoingqrcode:0, 
              ng_coatingngsum:0, ng_coatingnginsufficientcoating:0, ng_coatingngcoatingheight:0, ng_coatingngexcessivecoating:0, ng_coatingngqrcode:0,
              ng_eolngsum:0, ng_eolngopencircuit:0, ng_eolngoverresistance:0, ng_eolng2pconnectors:0, ng_eolngqrcode:0,
              ng_lqcngsum:0, ng_lqcngbusbar:0, ng_lqcngcarrier:0, ng_lqcngbubblesincoating:0, ng_lqcngfpcb:0, ng_lqcngconnector:0, ng_lqcngdamagedrivets:0, ng_lqcngbottompins:0, ng_lqcngqrcode:0,
              ng_finalinspngsum:0, ng_finalinspngbusbar:0, ng_finalinspngcarrier:0, ng_finalinspngbubblesincoating:0, ng_finalinspngfpcb:0, ng_finalinspngconnector:0, ng_finalinspngdamagedrivets:0, ng_finalinspngbottompins:0, ng_finalinspngqrcode:0, },     
          };

          for (const row of item.value) {
              prtotalSums.target += row.target;              
              prtotalSums.input += row.input;
              prtotalSums.ok += row.ok;
              prtotalSums.ng += row.ng;
              prtotalSums.ng_lasermarkingsum += row.ng_lasermarkingsum;
              prtotalSums.ng_lasermarkingheatstaking += row.ng_lasermarkingheatstaking;
              prtotalSums.ng_lasermarkingbusbar += row.ng_lasermarkingbusbar;
              prtotalSums.ng_lasermarkingassembly += row.ng_lasermarkingassembly;
              prtotalSums.ng_lasermarkingqrcode += row.ng_lasermarkingqrcode;
              prtotalSums.ng_middleinspsum += row.ng_middleinspsum;
              prtotalSums.ng_middleinsprivetheight += row.ng_middleinsprivetheight;
              prtotalSums.ng_middleinspdamagedrivet += row.ng_middleinspdamagedrivet;
              prtotalSums.ng_middleinspassembly += row.ng_middleinspassembly;
              prtotalSums.ng_middleinspqrcode += row.ng_middleinspqrcode;
              prtotalSums.ng_aoingsum += row.ng_aoingsum;
              prtotalSums.ng_aoingsoldering += row.ng_aoingsoldering;
              prtotalSums.ng_aoingheatstaking += row.ng_aoingheatstaking;
              prtotalSums.ng_aoingriveting += row.ng_aoingriveting;
              prtotalSums.ng_aoingqrcode += row.ng_aoingqrcode;
              prtotalSums.ng_coatingngsum += row.ng_coatingngsum;
              prtotalSums.ng_coatingnginsufficientcoating += row.ng_coatingnginsufficientcoating;
              prtotalSums.ng_coatingngcoatingheight += row.ng_coatingngcoatingheight;
              prtotalSums.ng_coatingngexcessivecoating += row.ng_coatingngexcessivecoating;
              prtotalSums.ng_coatingngqrcode += row.ng_coatingngqrcode;
              prtotalSums.ng_eolngsum += row.ng_eolngsum;
              prtotalSums.ng_eolngopencircuit += row.ng_eolngopencircuit;
              prtotalSums.ng_eolngoverresistance += row.ng_eolngoverresistance;
              prtotalSums.ng_eolng2pconnectors += row.ng_eolng2pconnectors;
              prtotalSums.ng_eolngqrcode += row.ng_eolngqrcode;
              prtotalSums.ng_lqcngsum += row.ng_lqcngsum;
              prtotalSums.ng_lqcngbusbar += row.ng_lqcngbusbar;
              prtotalSums.ng_lqcngcarrier += row.ng_lqcngcarrier;
              prtotalSums.ng_lqcngbubblesincoating += row.ng_lqcngbubblesincoating;
              prtotalSums.ng_lqcngfpcb += row.ng_lqcngfpcb;
              prtotalSums.ng_lqcngconnector += row.ng_lqcngconnector;
              prtotalSums.ng_lqcngdamagedrivets += row.ng_lqcngdamagedrivets;
              prtotalSums.ng_lqcngbottompins += row.ng_lqcngbottompins;
              prtotalSums.ng_lqcngqrcode += row.ng_lqcngqrcode;
              prtotalSums.ng_finalinspngsum += row.ng_finalinspngsum;
              prtotalSums.ng_finalinspngbusbar += row.ng_finalinspngbusbar;
              prtotalSums.ng_finalinspngcarrier += row.ng_finalinspngcarrier;
              prtotalSums.ng_finalinspngbubblesincoating += row.ng_finalinspngbubblesincoating;
              prtotalSums.ng_finalinspngfpcb += row.ng_finalinspngfpcb;
              prtotalSums.ng_finalinspngconnector += row.ng_finalinspngconnector;
              prtotalSums.ng_finalinspngdamagedrivets += row.ng_finalinspngdamagedrivets;
              prtotalSums.ng_finalinspngbottompins += row.ng_finalinspngbottompins;
              prtotalSums.ng_finalinspngqrcode += row.ng_finalinspngqrcode;
              prtotalSums.fn_lasermarkingsum += row.fn_lasermarkingsum;
              prtotalSums.fn_lasermarkingheatstaking += row.fn_lasermarkingheatstaking;
              prtotalSums.fn_lasermarkingqrcode += row.fn_lasermarkingqrcode;
              prtotalSums.fn_middleinspsum += row.fn_middleinspsum;
              prtotalSums.fn_middleinsprivetheight += row.fn_middleinsprivetheight;
              prtotalSums.fn_middleinspqrcode += row.fn_middleinspqrcode;
              prtotalSums.fn_aoingsum += row.fn_aoingsum;
              prtotalSums.fn_aoingsoldering += row.fn_aoingsoldering;
              prtotalSums.fn_aoingheatstaking += row.fn_aoingheatstaking;
              prtotalSums.fn_aoingriveting += row.fn_aoingriveting;
              prtotalSums.fn_aoingqrcode += row.fn_aoingqrcode;
              prtotalSums.fn_coatingsum += row.fn_coatingsum;
              prtotalSums.fn_coatinginsufficientcoating += row.fn_coatinginsufficientcoating;
              prtotalSums.fn_coatingbubbles += row.fn_coatingbubbles;
              prtotalSums.fn_coatingexcessivecoating += row.fn_coatingexcessivecoating;
              prtotalSums.fn_coatingqrcode += row.fn_coatingqrcode;
              prtotalSums.fn_eolsum += row.fn_eolsum;
              prtotalSums.fn_eolopencircuit += row.fn_eolopencircuit;
              prtotalSums.fn_eoloverresistance += row.fn_eoloverresistance;
              prtotalSums.fn_eol2pconnectors += row.fn_eol2pconnectors;
              prtotalSums.fn_eolqrcode += row.fn_eolqrcode;
              prtotalSums.fn_lqcsum += row.fn_lqcsum;
              prtotalSums.fn_lqcbusbars += row.fn_lqcbusbars;
              prtotalSums.fn_lqcqrcode += row.fn_lqcqrcode;
              prtotalSums.fn_finalinspsum += row.fn_finalinspsum;
              prtotalSums.fn_finalinspbusbar += row.fn_finalinspbusbar;
              prtotalSums.fn_finalinspqrcode += row.fn_finalinspqrcode;
              prtotalSums.nc_sum += row.nc_sum;
              prtotalSums.nc_carrier += row.nc_carrier;
              prtotalSums.nc_busbar += row.nc_busbar;
              prtotalSums.nc_connector += row.nc_connector;
              prtotalSums.nc_fpcb += row.nc_fpcb;
          }

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].ok += row.ok;
              shiftSums[row.shift].ng += row.ng;    
              shiftSums[row.shift].ng_lasermarkingsum += row.ng_lasermarkingsum;
              shiftSums[row.shift].ng_lasermarkingheatstaking += row.ng_lasermarkingheatstaking;
              shiftSums[row.shift].ng_lasermarkingbusbar += row.ng_lasermarkingbusbar;
              shiftSums[row.shift].ng_lasermarkingassembly += row.ng_lasermarkingassembly;
              shiftSums[row.shift].ng_lasermarkingqrcode += row.ng_lasermarkingqrcode;
              shiftSums[row.shift].ng_middleinspsum += row.ng_middleinspsum;
              shiftSums[row.shift].ng_middleinsprivetheight += row.ng_middleinsprivetheight;
              shiftSums[row.shift].ng_middleinspdamagedrivet += row.ng_middleinspdamagedrivet;
              shiftSums[row.shift].ng_middleinspassembly += row.ng_middleinspassembly;
              shiftSums[row.shift].ng_middleinspqrcode += row.ng_middleinspqrcode;
              shiftSums[row.shift].ng_aoingsum += row.ng_aoingsum;
              shiftSums[row.shift].ng_aoingsoldering += row.ng_aoingsoldering;
              shiftSums[row.shift].ng_aoingheatstaking += row.ng_aoingheatstaking;
              shiftSums[row.shift].ng_aoingriveting += row.ng_aoingriveting;
              shiftSums[row.shift].ng_aoingqrcode += row.ng_aoingqrcode;
              shiftSums[row.shift].ng_coatingngsum += row.ng_coatingngsum;
              shiftSums[row.shift].ng_coatingnginsufficientcoating += row.ng_coatingnginsufficientcoating;
              shiftSums[row.shift].ng_coatingngcoatingheight += row.ng_coatingngcoatingheight;
              shiftSums[row.shift].ng_coatingngexcessivecoating += row.ng_coatingngexcessivecoating;
              shiftSums[row.shift].ng_coatingngqrcode += row.ng_coatingngqrcode;
              shiftSums[row.shift].ng_eolngsum += row.ng_eolngsum;
              shiftSums[row.shift].ng_eolngopencircuit += row.ng_eolngopencircuit;
              shiftSums[row.shift].ng_eolngoverresistance += row.ng_eolngoverresistance;
              shiftSums[row.shift].ng_eolng2pconnectors += row.ng_eolng2pconnectors;
              shiftSums[row.shift].ng_eolngqrcode += row.ng_eolngqrcode;
              shiftSums[row.shift].ng_lqcngsum += row.ng_lqcngsum;
              shiftSums[row.shift].ng_lqcngbusbar += row.ng_lqcngbusbar;
              shiftSums[row.shift].ng_lqcngcarrier += row.ng_lqcngcarrier;
              shiftSums[row.shift].ng_lqcngbubblesincoating += row.ng_lqcngbubblesincoating;
              shiftSums[row.shift].ng_lqcngfpcb += row.ng_lqcngfpcb;
              shiftSums[row.shift].ng_lqcngconnector += row.ng_lqcngconnector;
              shiftSums[row.shift].ng_lqcngdamagedrivets += row.ng_lqcngdamagedrivets;
              shiftSums[row.shift].ng_lqcngbottompins += row.ng_lqcngbottompins;
              shiftSums[row.shift].ng_lqcngqrcode += row.ng_lqcngqrcode;
              shiftSums[row.shift].ng_finalinspngsum += row.ng_finalinspngsum;
              shiftSums[row.shift].ng_finalinspngbusbar += row.ng_finalinspngbusbar;
              shiftSums[row.shift].ng_finalinspngcarrier += row.ng_finalinspngcarrier;
              shiftSums[row.shift].ng_finalinspngbubblesincoating += row.ng_finalinspngbubblesincoating;
              shiftSums[row.shift].ng_finalinspngfpcb += row.ng_finalinspngfpcb;
              shiftSums[row.shift].ng_finalinspngconnector += row.ng_finalinspngconnector;
              shiftSums[row.shift].ng_finalinspngdamagedrivets += row.ng_finalinspngdamagedrivets;
              shiftSums[row.shift].ng_finalinspngbottompins += row.ng_finalinspngbottompins;
              shiftSums[row.shift].ng_finalinspngqrcode += row.ng_finalinspngqrcode;
              shiftSums[row.shift].fn_lasermarkingsum += row.fn_lasermarkingsum;
              shiftSums[row.shift].fn_lasermarkingheatstaking += row.fn_lasermarkingheatstaking;
              shiftSums[row.shift].fn_lasermarkingqrcode += row.fn_lasermarkingqrcode;
              shiftSums[row.shift].fn_middleinspsum += row.fn_middleinspsum;
              shiftSums[row.shift].fn_middleinsprivetheight += row.fn_middleinsprivetheight;
              shiftSums[row.shift].fn_middleinspqrcode += row.fn_middleinspqrcode;
              shiftSums[row.shift].fn_aoingsum += row.fn_aoingsum;
              shiftSums[row.shift].fn_aoingsoldering += row.fn_aoingsoldering;
              shiftSums[row.shift].fn_aoingheatstaking += row.fn_aoingheatstaking;
              shiftSums[row.shift].fn_aoingriveting += row.fn_aoingriveting;
              shiftSums[row.shift].fn_aoingqrcode += row.fn_aoingqrcode;
              shiftSums[row.shift].fn_coatingsum += row.fn_coatingsum;
              shiftSums[row.shift].fn_coatinginsufficientcoating += row.fn_coatinginsufficientcoating;
              shiftSums[row.shift].fn_coatingbubbles += row.fn_coatingbubbles;
              shiftSums[row.shift].fn_coatingexcessivecoating += row.fn_coatingexcessivecoating;
              shiftSums[row.shift].fn_coatingqrcode += row.fn_coatingqrcode;
              shiftSums[row.shift].fn_eolsum += row.fn_eolsum;
              shiftSums[row.shift].fn_eolopencircuit += row.fn_eolopencircuit;
              shiftSums[row.shift].fn_eoloverresistance += row.fn_eoloverresistance;
              shiftSums[row.shift].fn_eol2pconnectors += row.fn_eol2pconnectors;
              shiftSums[row.shift].fn_eolqrcode += row.fn_eolqrcode;
              shiftSums[row.shift].fn_lqcsum += row.fn_lqcsum;
              shiftSums[row.shift].fn_lqcbusbars += row.fn_lqcbusbars;
              shiftSums[row.shift].fn_lqcqrcode += row.fn_lqcqrcode;
              shiftSums[row.shift].fn_finalinspsum += row.fn_finalinspsum;
              shiftSums[row.shift].fn_finalinspbusbar += row.fn_finalinspbusbar;
              shiftSums[row.shift].fn_finalinspqrcode += row.fn_finalinspqrcode;
              shiftSums[row.shift].nc_sum += row.nc_sum;
              shiftSums[row.shift].nc_carrier += row.nc_carrier;
              shiftSums[row.shift].nc_busbar += row.nc_busbar;
              shiftSums[row.shift].nc_connector += row.nc_connector;
              shiftSums[row.shift].nc_fpcb += row.nc_fpcb;
              }

            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
              modelSums[row.model].target += row.target;
              modelSums[row.model].input += row.input;
              modelSums[row.model].ok += row.ok;
              modelSums[row.model].ng += row.ng;
              modelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum;
              modelSums[row.model].ng_lasermarkingheatstaking += row.ng_lasermarkingheatstaking;
              modelSums[row.model].ng_lasermarkingbusbar += row.ng_lasermarkingbusbar;
              modelSums[row.model].ng_lasermarkingassembly += row.ng_lasermarkingassembly;
              modelSums[row.model].ng_lasermarkingqrcode += row.ng_lasermarkingqrcode;
              modelSums[row.model].ng_middleinspsum += row.ng_middleinspsum;
              modelSums[row.model].ng_middleinsprivetheight += row.ng_middleinsprivetheight;
              modelSums[row.model].ng_middleinspdamagedrivet += row.ng_middleinspdamagedrivet;
              modelSums[row.model].ng_middleinspassembly += row.ng_middleinspassembly;
              modelSums[row.model].ng_middleinspqrcode += row.ng_middleinspqrcode;
              modelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              modelSums[row.model].ng_aoingsoldering += row.ng_aoingsoldering;
              modelSums[row.model].ng_aoingheatstaking += row.ng_aoingheatstaking;
              modelSums[row.model].ng_aoingriveting += row.ng_aoingriveting;
              modelSums[row.model].ng_aoingqrcode += row.ng_aoingqrcode;
              modelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              modelSums[row.model].ng_coatingnginsufficientcoating += row.ng_coatingnginsufficientcoating;
              modelSums[row.model].ng_coatingngcoatingheight += row.ng_coatingngcoatingheight;
              modelSums[row.model].ng_coatingngexcessivecoating += row.ng_coatingngexcessivecoating;
              modelSums[row.model].ng_coatingngqrcode += row.ng_coatingngqrcode;
              modelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              modelSums[row.model].ng_eolngopencircuit += row.ng_eolngopencircuit;
              modelSums[row.model].ng_eolngoverresistance += row.ng_eolngoverresistance;
              modelSums[row.model].ng_eolng2pconnectors += row.ng_eolng2pconnectors;
              modelSums[row.model].ng_eolngqrcode += row.ng_eolngqrcode;
              modelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              modelSums[row.model].ng_lqcngbusbar += row.ng_lqcngbusbar;
              modelSums[row.model].ng_lqcngcarrier += row.ng_lqcngcarrier;
              modelSums[row.model].ng_lqcngbubblesincoating += row.ng_lqcngbubblesincoating;
              modelSums[row.model].ng_lqcngfpcb += row.ng_lqcngfpcb;
              modelSums[row.model].ng_lqcngconnector += row.ng_lqcngconnector;
              modelSums[row.model].ng_lqcngdamagedrivets += row.ng_lqcngdamagedrivets;
              modelSums[row.model].ng_lqcngbottompins += row.ng_lqcngbottompins;
              modelSums[row.model].ng_lqcngqrcode += row.ng_lqcngqrcode;
              modelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              modelSums[row.model].ng_finalinspngbusbar += row.ng_finalinspngbusbar;
              modelSums[row.model].ng_finalinspngcarrier += row.ng_finalinspngcarrier;
              modelSums[row.model].ng_finalinspngbubblesincoating += row.ng_finalinspngbubblesincoating;
              modelSums[row.model].ng_finalinspngfpcb += row.ng_finalinspngfpcb;
              modelSums[row.model].ng_finalinspngconnector += row.ng_finalinspngconnector;
              modelSums[row.model].ng_finalinspngdamagedrivets += row.ng_finalinspngdamagedrivets;
              modelSums[row.model].ng_finalinspngbottompins += row.ng_finalinspngbottompins;
              modelSums[row.model].ng_finalinspngqrcode += row.ng_finalinspngqrcode;
              modelSums[row.model].fn_lasermarkingsum += row.fn_lasermarkingsum;
              modelSums[row.model].fn_lasermarkingheatstaking += row.fn_lasermarkingheatstaking;
              modelSums[row.model].fn_lasermarkingqrcode += row.fn_lasermarkingqrcode;
              modelSums[row.model].fn_middleinspsum += row.fn_middleinspsum;
              modelSums[row.model].fn_middleinsprivetheight += row.fn_middleinsprivetheight;
              modelSums[row.model].fn_middleinspqrcode += row.fn_middleinspqrcode;
              modelSums[row.model].fn_aoingsum += row.fn_aoingsum;
              modelSums[row.model].fn_aoingsoldering += row.fn_aoingsoldering;
              modelSums[row.model].fn_aoingheatstaking += row.fn_aoingheatstaking;
              modelSums[row.model].fn_aoingriveting += row.fn_aoingriveting;
              modelSums[row.model].fn_aoingqrcode += row.fn_aoingqrcode;
              modelSums[row.model].fn_coatingsum += row.fn_coatingsum;
              modelSums[row.model].fn_coatinginsufficientcoating += row.fn_coatinginsufficientcoating;
              modelSums[row.model].fn_coatingbubbles += row.fn_coatingbubbles;
              modelSums[row.model].fn_coatingexcessivecoating += row.fn_coatingexcessivecoating;
              modelSums[row.model].fn_coatingqrcode += row.fn_coatingqrcode;
              modelSums[row.model].fn_eolsum += row.fn_eolsum;
              modelSums[row.model].fn_eolopencircuit += row.fn_eolopencircuit;
              modelSums[row.model].fn_eoloverresistance += row.fn_eoloverresistance;
              modelSums[row.model].fn_eol2pconnectors += row.fn_eol2pconnectors;
              modelSums[row.model].fn_eolqrcode += row.fn_eolqrcode;
              modelSums[row.model].fn_lqcsum += row.fn_lqcsum;
              modelSums[row.model].fn_lqcbusbars += row.fn_lqcbusbars;
              modelSums[row.model].fn_lqcqrcode += row.fn_lqcqrcode;
              modelSums[row.model].fn_finalinspsum += row.fn_finalinspsum;
              modelSums[row.model].fn_finalinspbusbar += row.fn_finalinspbusbar;
              modelSums[row.model].fn_finalinspqrcode += row.fn_finalinspqrcode;
              modelSums[row.model].nc_sum += row.nc_sum;
              modelSums[row.model].nc_carrier += row.nc_carrier;
              modelSums[row.model].nc_busbar += row.nc_busbar;
              modelSums[row.model].nc_connector += row.nc_connector;
              modelSums[row.model].nc_fpcb += row.nc_fpcb;
            }
          }

          for (const row of qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
              qwmodelSums[row.model].ok += row.ok;
              qwmodelSums[row.model].ng += row.ng;
              qwmodelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum;
              qwmodelSums[row.model].ng_lasermarkingheatstaking += row.ng_lasermarkingheatstaking;
              qwmodelSums[row.model].ng_lasermarkingbusbar += row.ng_lasermarkingbusbar;
              qwmodelSums[row.model].ng_lasermarkingassembly += row.ng_lasermarkingassembly;
              qwmodelSums[row.model].ng_lasermarkingqrcode += row.ng_lasermarkingqrcode;
              qwmodelSums[row.model].ng_middleinspsum += row.ng_middleinspsum;
              qwmodelSums[row.model].ng_middleinsprivetheight += row.ng_middleinsprivetheight;
              qwmodelSums[row.model].ng_middleinspdamagedrivet += row.ng_middleinspdamagedrivet;
              qwmodelSums[row.model].ng_middleinspassembly += row.ng_middleinspassembly;
              qwmodelSums[row.model].ng_middleinspqrcode += row.ng_middleinspqrcode;
              qwmodelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              qwmodelSums[row.model].ng_aoingsoldering += row.ng_aoingsoldering;
              qwmodelSums[row.model].ng_aoingheatstaking += row.ng_aoingheatstaking;
              qwmodelSums[row.model].ng_aoingriveting += row.ng_aoingriveting;
              qwmodelSums[row.model].ng_aoingqrcode += row.ng_aoingqrcode;
              qwmodelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              qwmodelSums[row.model].ng_coatingnginsufficientcoating += row.ng_coatingnginsufficientcoating;
              qwmodelSums[row.model].ng_coatingngcoatingheight += row.ng_coatingngcoatingheight;
              qwmodelSums[row.model].ng_coatingngexcessivecoating += row.ng_coatingngexcessivecoating;
              qwmodelSums[row.model].ng_coatingngqrcode += row.ng_coatingngqrcode;
              qwmodelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              qwmodelSums[row.model].ng_eolngopencircuit += row.ng_eolngopencircuit;
              qwmodelSums[row.model].ng_eolngoverresistance += row.ng_eolngoverresistance;
              qwmodelSums[row.model].ng_eolng2pconnectors += row.ng_eolng2pconnectors;
              qwmodelSums[row.model].ng_eolngqrcode += row.ng_eolngqrcode;
              qwmodelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              qwmodelSums[row.model].ng_lqcngbusbar += row.ng_lqcngbusbar;
              qwmodelSums[row.model].ng_lqcngcarrier += row.ng_lqcngcarrier;
              qwmodelSums[row.model].ng_lqcngbubblesincoating += row.ng_lqcngbubblesincoating;
              qwmodelSums[row.model].ng_lqcngfpcb += row.ng_lqcngfpcb;
              qwmodelSums[row.model].ng_lqcngconnector += row.ng_lqcngconnector;
              qwmodelSums[row.model].ng_lqcngdamagedrivets += row.ng_lqcngdamagedrivets;
              qwmodelSums[row.model].ng_lqcngbottompins += row.ng_lqcngbottompins;
              qwmodelSums[row.model].ng_lqcngqrcode += row.ng_lqcngqrcode;
              qwmodelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              qwmodelSums[row.model].ng_finalinspngbusbar += row.ng_finalinspngbusbar;
              qwmodelSums[row.model].ng_finalinspngcarrier += row.ng_finalinspngcarrier;
              qwmodelSums[row.model].ng_finalinspngbubblesincoating += row.ng_finalinspngbubblesincoating;
              qwmodelSums[row.model].ng_finalinspngfpcb += row.ng_finalinspngfpcb;
              qwmodelSums[row.model].ng_finalinspngconnector += row.ng_finalinspngconnector;
              qwmodelSums[row.model].ng_finalinspngdamagedrivets += row.ng_finalinspngdamagedrivets;
              qwmodelSums[row.model].ng_finalinspngbottompins += row.ng_finalinspngbottompins;
              qwmodelSums[row.model].ng_finalinspngqrcode += row.ng_finalinspngqrcode;       
            }
          }     

          return { prtotalSums, shiftSums, modelSums, qwmodelSums };
        });

        const fnTotal = computed(() => {
          return (
            sums.value.prtotalSums.fn_lasermarkingsum + sums.value.prtotalSums.fn_middleinspsum +
            sums.value.prtotalSums.fn_aoingsum + sums.value.prtotalSums.fn_coatingsum + 
            sums.value.prtotalSums.fn_eolsum + 
            sums.value.prtotalSums.fn_lqcsum + sums.value.prtotalSums.fn_finalinspsum            
          );
        });

        const shift1FnTotal = computed(() => {
          return (
            sums.value.shiftSums[1].fn_lasermarkingsum + sums.value.shiftSums[1].fn_middleinspsum +
            sums.value.shiftSums[1].fn_aoingsum + sums.value.shiftSums[1].fn_coatingsum + 
            sums.value.shiftSums[1].fn_eolsum + 
            sums.value.shiftSums[1].fn_lqcsum + sums.value.shiftSums[1].fn_finalinspsum 
          );
        });
        const shift2FnTotal = computed(() => {
          return (
            sums.value.shiftSums[2].fn_lasermarkingsum + sums.value.shiftSums[2].fn_middleinspsum +
            sums.value.shiftSums[2].fn_aoingsum + sums.value.shiftSums[2].fn_coatingsum + 
            sums.value.shiftSums[2].fn_eolsum + 
            sums.value.shiftSums[2].fn_lqcsum + sums.value.shiftSums[2].fn_finalinspsum                   
          );
        });        
        const model4p8sFnTotal = computed(() => {
          return (
            sums.value.modelSums['4P8S'].fn_lasermarkingsum + sums.value.modelSums['4P8S'].fn_middleinspsum +
            sums.value.modelSums['4P8S'].fn_aoingsum + sums.value.modelSums['4P8S'].fn_coatingsum + 
            sums.value.modelSums['4P8S'].fn_eolsum + 
            sums.value.modelSums['4P8S'].fn_lqcsum + sums.value.modelSums['4P8S'].fn_finalinspsum                   
          );
        });
        const model4p7sFnTotal = computed(() => {
          return (
            sums.value.modelSums['4P7S'].fn_lasermarkingsum + sums.value.modelSums['4P7S'].fn_middleinspsum +
            sums.value.modelSums['4P7S'].fn_aoingsum + sums.value.modelSums['4P7S'].fn_coatingsum + 
            sums.value.modelSums['4P7S'].fn_eolsum + 
            sums.value.modelSums['4P7S'].fn_lqcsum + sums.value.modelSums['4P7S'].fn_finalinspsum                   
          );
        });
        const model3p10sFnTotal = computed(() => {
          return (
            sums.value.modelSums['3P10S'].fn_lasermarkingsum + sums.value.modelSums['3P10S'].fn_middleinspsum +
            sums.value.modelSums['3P10S'].fn_aoingsum + sums.value.modelSums['3P10S'].fn_coatingsum + 
            sums.value.modelSums['3P10S'].fn_eolsum + 
            sums.value.modelSums['3P10S'].fn_lqcsum + sums.value.modelSums['3P10S'].fn_finalinspsum                   
          );
        });
        const model3p8sFnTotal = computed(() => {
          return (
            sums.value.modelSums['3P8S'].fn_lasermarkingsum + sums.value.modelSums['3P8S'].fn_middleinspsum +
            sums.value.modelSums['3P8S'].fn_aoingsum + sums.value.modelSums['3P8S'].fn_coatingsum + 
            sums.value.modelSums['3P8S'].fn_eolsum + 
            sums.value.modelSums['3P8S'].fn_lqcsum + sums.value.modelSums['3P8S'].fn_finalinspsum                   
          );
        });

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error download excel data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });
          const wsDt = XLSX.utils.json_to_sheet(dtTableData.data, { header: dtHeaders.value });
          const wsDr = XLSX.utils.json_to_sheet(drTableData.data, { header: drHeaders.value });
          const wsQw = XLSX.utils.json_to_sheet(qwTableData.data, { header: headers.value });

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "PR Data");
          XLSX.utils.book_append_sheet(wb, wsDt, "DT Data");
          XLSX.utils.book_append_sheet(wb, wsDr, "DR Data");
          XLSX.utils.book_append_sheet(wb, wsQw, "QW Data");

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "data.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel, 
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
                 showrdModal,
                 fnTotal, shift1FnTotal, shift2FnTotal, model4p8sFnTotal, model4p7sFnTotal, model3p10sFnTotal, model3p8sFnTotal,
                 };        
    },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}

.modalButtonContainer {
  display: inline-flex;
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

.qwallButton {
  padding: 5px 5px;
  background-color: #00eb91;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}
.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, .qwallButton:hover, 
.button:hover {
    background-color: #2980b9;
  }

  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input
  {
  width : 80px;  
  margin-right: 5px;
  padding : 5px;
 }

label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}

#dailyReport td {
  text-align: left;
}

.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
}

.model-4P8S {
  background-color: #a0eefc; /* Adjust the color as needed for 2P12S */
}

.model-4P7S {
  background-color: #9887e4; /* Adjust the color as needed for 3P8S */
}
.model-3P10S {
  background-color: #b8ffb1; /* Adjust the color as needed for 2P12S */
}

.model-3P8S {
  background-color: #74ffff; /* Adjust the color as needed for 3P8S */
}

.ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.zero-value_ng {
  background-color: #fa7979; 
  color: #fa7979; 
}

.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}
.zero-value_ngtype {
  background-color: #fdb08d; 
  color: #fdb08d;
}
.ng_sub_type {
  background-color: #f8d4c3; /* Adjust the color as needed */
}
.zero-value_ngsubtype {
  background-color: #f8d4c3;
  color: #f8d4c3;
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}
.zero-value_fn {
  background-color: #ff72d5; 
  color: #ff72d5; 
}

.fn_type {
  background-color: #ff72d5; /* Adjust the color as needed */
}
.zero-value_fntype {
  background-color: #ff72d5; 
  color: #ff72d5; 
}

.fn_sub_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}

.zero-value_fnsubtype {
  background-color: #ff9bcd; 
  color: #ff9bcd; 
}

.ngcomp {
  background-color: #60cdff; /* Adjust the color as needed */
}

.zero-value_ngcomp {
  background-color: #60cdff; 
  color: #60cdff; 
}

.qwall {
  background-color: #66b179;  
}

.shift1 { background-color: rgb(174, 240, 147); }
.shift2 { background-color: rgba(248, 250, 145, 0.979); }

</style>