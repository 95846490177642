<template>
    <div class="maxpowerdb">            
        <h class="pagetitle">Evaluation (Person Base)</h><br>
        <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">                
                <div class="searchtarget">
                    <B>Employee Number:</B>
                    <input id="line" v-model="formInputs.employeenumber" />                            
                </div>                    
                <div class="searchTd">
                    <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
                </div>                    
                <div class="searchTd">
                    <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
                </div>                
            </div>      
        </form>   
        </div>     
        <div class="table">
            <table>
                <tr>
                    <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                </tr>           
                    <tr v-for="row in item" :key="row.id" :class="getRowClass(row)">                    
                        <td>{{ row.year }}-{{ row.month }}-{{ row.day}}</td>
                        <td>{{ row.type }}</td>
                        <td>{{ row.line }}</td>
                        <td>{{ row.station }}</td>                    
                        <td>{{ row.startyear }}-{{ row.startmonth }}-{{ row.startday }}</td>
                        <td>{{ row.oscompany }}</td>                   
                        <td>{{ row.employeenumber }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.surname }}</td>
                        <td>{{ row.position }}</td>
                        <td>{{ row.workmethod }}</td>
                        <td>{{ row.quality }}</td>
                        <td>{{ row.workattitude }}</td>
                        <td>{{ row.threefive }}</td>                        
                        <td :class="{'grade-E': row.grade === 'E', 'grade-N': row.grade === 'N', 'grade-T': row.grade === 'T'}">{{ row.grade }}</td>
                        <td>{{ row.confirm }}</td>
                        <td>{{ row.nextyear }}-{{ row.nextmonth }}-{{ row.nextday }}</td>
                        <td>{{ row.remark }}</td>   
                        <td><button class="confirmButton" :class="{ disabledButton: !canConfirm() }"  type="submit"  @click="handleClick(row)" :disabled="!canConfirm()">Confirm</button> </td>
                        <td><button class="evaButton" @click="handleEvaluationClick(row)" type="submit">Evaluation</button></td>
                        <td><button class="modifyButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>                    
                        <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="handleDeleteRow(dbTableName, row.id)">DEL</button></td>
                </tr>
            </table>                                
        </div>        
        <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>                                     
        <operatorModal  v-if="showOperatorModal" :row-id="selectedRowId" @onClose="showOperatorModal = false; callSearchData();"/>
        <lineTechModal  v-if="showLineTechModal" :row-id="selectedRowId" @onClose="showLineTechModal = false; callSearchData();"/>
        <leaderModal  v-if="showLeaderModal" :row-id="selectedRowId" @onClose="showLeaderModal = false; callSearchData();"/>
        <maintenanceModal  v-if="showMaintenanceModal" :row-id="selectedRowId" @onClose="showMaintenanceModal = false; callSearchData();"/>
        <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                             
    </div>
</template>

<script>
import { reactive, ref, computed, } from 'vue';
import axios from "axios";

import operatorModal from "./operatorModal.vue";
import lineTechModal from "./techModal.vue";
import leaderModal from "./leaderModal.vue";
import maintenanceModal from "./maintenanceModal.vue";
import modifyModal from "./evaLineModify.vue";
import { deleteRow, downloadExcel, } from '../../utils.js'; 

export default {  
    data() {
        return {    
          columnMapping: {            
            year: 'year',
            month: 'month',
            day: 'day',
            type: 'type',
            line: 'line',
            station: 'station',
            startyear: 'startyear',
            startmonth: 'startmonth',
            startday: 'startday',
            oscompany: 'oscompany',
            employeenumber: 'employeenumber',
            name : 'name',
            surname : 'surname',
            position : 'position',
            workmethod : 'workmethod',
            quality : 'quality',
            workattitude : 'workattitude',
            threefive : 'threefive',
            grade : 'grade',
            confirm : 'confirm',
            nextyear : 'nextyear',
            nextmonth : 'nextmonth',
            nextday : 'nextday',            
            remark : 'remark',            
          }
        }
    },   

    methods: {
        openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },    
        handleClick(row) { this.confirmSend(row); this.callSearchData(); },

        handleEvaluationClick(row) {
            if (row.position === "Operator" ||  row.position === "Senior Operator" || row.position === "Junior Operator"
                || row.position === "Senior Inspector" || row.position === "Junior Inspector" || row.position === "Inspector"
                ) {this.selectedRowId = row.id; this.showOperatorModal = true;}
            else if (row.position === "Technician") { this.selectedRowId = row.id; this.showLineTechModal = true;}
            else if (row.position === "Leader") { this.selectedRowId = row.id; this.showLeaderModal = true;}            
            else if (row.position === "Maintenance") { this.selectedRowId = row.id; this.showMaintenanceModal = true;}  
        },

        canConfirm() {            
            const currentUser = this.$route.meta.currentUser;  
            if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3; }
            else { return false;}
        },

        canClick() {            
            const currentUser = this.$route.meta.currentUser;       
            if (currentUser) { 
                return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 
                       || currentUser.id === 17 || currentUser.id === 32;
            } 
            else { return false; }
        },    

        async confirmSend(row) {         
            if (this.canConfirm()) {       
                const formData = {
                    id: row.id,
                    tableName: 'evaluation',
                    columnMapping: this.columnMapping,
                    values: {
                        year: row.year,
                        month: row.month,
                        day: row.day,
                        type: row.type,
                        line: row.line,
                        station: row.station,
                        startyear: row.startyear,
                        startmonth: row.startmonth,
                        startday: row.startday,
                        oscompany: row.oscompany,
                        employeenumber: row.employeenumber,
                        name : row.name,
                        surname : row.surname,
                        position : row.position,
                        workmethod : row.workmethod,
                        quality : row.quality,
                        workattitude : row.workattitude,
                        threefive : row.threefive,
                        grade : row.grade,
                        confirm : 'Confirmed',
                        nextyear : row.nextyear,
                        nextmonth : row.nextmonth,
                        nextday : row.nextday,
                        remark : row.remark,                            
                    }
                };

                const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

                if (hasUndefinedValue) { console.error('Undefined value found in formData.values:', formData.values); return; }

                formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));           
                try {
                    const response = await axios.put(`/api/materialput/${row.id}`, formData);
                    console.log('Server response:', response);
                    console.log('Data updated successfully');
                } catch (error) {
                    console.error('Error updating data:', error);
                }
            }
        },   
        handleDeleteRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
        downExcel() { downloadExcel(this.item, "Evaluation.xlsx"); },
    },

    components: { modifyModal, operatorModal, lineTechModal, leaderModal, maintenanceModal},  

    setup() {        
        const formInputs = reactive({ employeenumber: "" });
        const dbTableName = 'evaluation';  
        const loading = ref(false);  
        const showMoModal = ref(false);
        const showLineTechModal = ref(false); const showOperatorModal = ref(false);
        const showLeaderModal = ref(false); const showMaintenanceModal = ref(false);
        const headers = ['Date', 'Type', 'Line', 'Station', 'Work Start Date', 'Company', 'Employee Number',
                         'Name', 'SurName', 'Postion', 'Work Method', 'Quality', 'Work Attitude', '3C5S', 'Grade', 'Confirmed',
                         'Next Evaluation', 'Remark', 'Confirm', 'Evaluation', 'Modify', 'DEL'
        ];
        const dbData = reactive({ data: [], });        
        
        const sendDate = async () => {const sendDateData = [formInputs.employeenumber]; return {sendDateData};};     
        const searchData = async () => {
            const sendDateData = await sendDate();            
                await axios.get('/api/targetList', { params: { tableName: dbTableName, employeenumber: sendDateData.sendDateData[0] } })
                    .then((res) => { dbData.data = res.data; loading.value = true; }); 
        };

        const getRowClass = (row) => {
            if (!loading.value) return '';  

            const today = new Date();
            const nextEvaluationDate = new Date(row.nextyear, row.nextmonth - 1, row.nextday);
            const timeDiff = nextEvaluationDate - today;
            const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

            const nextData = dbData.data.find(data =>
                data.line === row.line && 
                data.station === row.station && 
                data.employeenumber === row.employeenumber &&
                data.id > row.id 
            );

            if (nextData && ['E', 'N', 'T'].includes(nextData.grade)) { return ''; }

            if (daysLeft < 0) { return 'red';} 
            else if (daysLeft <= 15) { return 'yellow'; }
            return '';
        };

        const callSearchData = async () => { searchData();  };     

        const item = computed(() => {return dbData.data.map(d => {return { ...d,};});});

        return { headers, item, callSearchData, dbTableName, formInputs, sendDate, 
                 showMoModal, showLineTechModal, showOperatorModal, showLeaderModal, showMaintenanceModal,
                 getRowClass, loading
        }        
    }}

</script>

<style scoped>     
    .searchtarget {border: 0px; width:350px }
    select {margin-left: 10px;}
    .searchtargetselect {width : 200px}
    .modifyButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none;
                border-radius: 4px; font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px;
                margin-left: 5px; margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .table { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload:hover { background-color: #009625; }

    .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .searchButton { padding: 5px 5px; background-color: #bbbbbb; color: #000000; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
    .start,.end { display: flex; flex-direction: row; border: 0px; }
    .start input,.end input { margin-right: 5px;}
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }
    .searchTd { border: 0px; }
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; }
    input { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; margin-left: 10px; }
    .confirmButton { padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
                    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .evaButton { padding: 5px 5px; background-color: #a14f02; color: #fff; border: none; border-radius: 4px;
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
                    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }   
    .grade-E { background-color: green; color: white; font-weight: bold;}
    .grade-N { background-color: yellow; font-weight: bold;}
    .grade-T { background-color: transparent; font-weight: bold;}
    .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
        font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .red {background-color: rgb(235, 105, 105)} 
    .yellow {background-color: rgb(255, 255, 0, 0.7)}   
    .horizontal-form { margin-top: 10px; margin-bottom: 10px; margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>