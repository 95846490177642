<template>
    <div class="fca1">            
      <div class="act">               
         <h class="pagetitle">Thermistor Pad</h>
         <div class="modalButtonContainer">
         <button class="button" @click="showModal = true">+ Result</button>
         <button class="button" @click="showdtModal = true">+ Downtime</button>
         <button class="button" @click="showdrModal = true">+ Daily Report</button>         
         <button class="button" @click="showrdModal = true">+ Repeat Data</button>
        </div>
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>     
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr></tr>
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-Front': row.model === 'Front', 'model-Rear': row.model === 'Rear'}" @click="editCellValue(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK Total'" :key="index" class="ok">{{ row.oktotal }}</td>
                <td v-if="header === 'OK A'" :key="index" class="ok" @click="editCellValue(dbTableName, row.id, 'ok_a', $event)">{{ row.ok_a }}</td>
                <td v-if="header === 'OK B'" :key="index" class="ok" @click="editCellValue(dbTableName, row.id, 'ok_b', $event)">{{ row.ok_b }}</td>
                <td v-if="header === 'OK C'" :key="index" class="ok" @click="editCellValue(dbTableName, row.id, 'ok_c', $event)">{{ row.ok_c }}</td>
                <td v-if="header === 'Verification'" :key="index" class="verify" :class="{ 'zero-value_verify': row.verification === 0 }" @click="editCellValue(dbTableName, row.id, 'verification', $event)">{{ row.verification }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'FPCA Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_fpcadamage === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_fpcadamage', $event)">{{ row.ng_fpcadamage }}</td>
                <td v-if="header === 'Thermistor Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_thermistordamage === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_thermistordamage', $event)">{{ row.ng_thermistordamage }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>               
                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td>                
                <td v-if="header === 'Operator Name A'" :key="index" @click="editCellValue(dbTableName, row.id, 'operator_name_a', $event)">{{ row.operator_name_a }}</td>                 
                <td v-if="header === 'Operator Name B'" :key="index" @click="editCellValue(dbTableName, row.id, 'operator_name_b', $event)">{{ row.operator_name_b }}</td>                 
                <td v-if="header === 'Operator Name C'" :key="index" @click="editCellValue(dbTableName, row.id, 'operator_name_c', $event)">{{ row.operator_name_c }}</td>                 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].oktotal }}</td>
          <td>{{ sums.shiftSums[1].ok_a }}</td>
          <td>{{ sums.shiftSums[1].ok_b }}</td>
          <td>{{ sums.shiftSums[1].ok_c }}</td>
          <td>{{ sums.shiftSums[1].verification }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_fpcadamage }}</td>                  
          <td>{{ sums.shiftSums[1].ng_thermistordamage }}</td>                  
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].oktotal }}</td>
          <td>{{ sums.shiftSums[2].ok_a }}</td>
          <td>{{ sums.shiftSums[2].ok_b }}</td>
          <td>{{ sums.shiftSums[2].ok_c }}</td>
          <td>{{ sums.shiftSums[2].verification }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_fpcadamage }}</td>                  
          <td>{{ sums.shiftSums[2].ng_thermistordamage }}</td>                                 
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>  
        </tr>
        <tr>
         <th rowspan="2">Model</th>
          <td>EVO 2P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.modelSums['EVO_2P'].input }}</td>
          <td>{{ sums.modelSums['EVO_2P'].oktotal }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok_a}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok_b}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok_c}}</td>
          <td>{{ sums.modelSums['EVO_2P'].verification}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_fpcadamage}}</td>          
          <td>{{ sums.modelSums['EVO_2P'].ng_thermistordamage}}</td>          
          <td>{{ ((sums.modelSums['EVO_2P'].ng) / (sums.modelSums['EVO_2P'].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>
          <td>EVO 3P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.modelSums['EVO_3P'].input }}</td>
          <td>{{ sums.modelSums['EVO_3P'].oktotal}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok_a}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok_b}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok_c}}</td>
          <td>{{ sums.modelSums['EVO_3P'].verification}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_fpcadamage}}</td>          
          <td>{{ sums.modelSums['EVO_3P'].ng_thermistordamage}}</td>   
          <td>{{ ((sums.modelSums['EVO_3P'].ng) / (sums.modelSums['EVO_3P'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th colspan="3">Total</th>
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok">{{ sums.shiftSums[1].oktotal + sums.shiftSums[2].oktotal }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok_a + sums.shiftSums[2].ok_a }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok_b + sums.shiftSums[2].ok_b }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok_c + sums.shiftSums[2].ok_c }}</td>
          <td class="verify" >{{ sums.shiftSums[1].verification + sums.shiftSums[2].verification }}</td>
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],].reduce((sum, obj) => sum + obj.ng_fpcadamage, 0) }}</td>          
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],].reduce((sum, obj) => sum + obj.ng_thermistordamage, 0) }}</td>  
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng ) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>                    
        </tr>   
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCellValue(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>      
      <dtModal  v-if="showdtModal" @onClose="showdtModal = false; callSearchData();"/>         
      <drModal  v-if="showdrModal" @onClose="showdrModal = false; callSearchData();"/>       
      <rdModal  v-if="showrdModal" @onClose="showrdModal = false; callSearchData();"/> 
  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./thermistorPad_prm.vue";
import dtModal from "./thermistorPad_prdtm.vue";
import drModal from "./thermistorPad_prdrm.vue";
import rdModal from "./thermistorPad_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, rdModal },    

    setup() {
        const lineName = "Thermistor Pad"  //설비 변경시 필수 교체        
        const dbTableName = "thermistorpadpr";  //searchData에 있는 값도 바꿔야 함 
        const dtTableName = "thermistorpaddt";  //searchData에 있는 값도 바꿔야 함
        const drTableName = "thermistorpaddr";  //searchData에 있는 값도 바꿔야 함        
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK Total','OK A', 'OK B', 'OK C', 'Verification',
                         'NG', "FPCA Damage", "Thermistor Damage", 'NG Rate', 'Remark', 'Leader',
                         'Operator Name A', 'Operator Name B', 'Operator Name C', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part        

        //Repeat Data part
        const showrdModal = ref(false);     
   
        //Current Time part
        
        const searchDate = ref({ year: '', month: '', day: '', });
        const sendDate = async () => {const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]; return {sendDateData}; };            

        const calculateInputAndNgRate = (ok_a, ok_b, ok_c, ng, ) => {
          const input = ok_a + ok_b + ok_c + ng;          
          const oktotal = ok_a + ok_b + ok_c;          
          let ngRate = 0;          
          if (ng !== 0) { ngRate = ((ng / input) * 100).toFixed(1); }          
          return { input, oktotal, ngRate, };
        };

        const item = computed(() => {
          return prTableData.data.map(d => { const { input, oktotal, ngRate, } = calculateInputAndNgRate(d.ok_a, d.ok_b, d.ok_c, d.ng,);
          return { ...d, input, oktotal, ngRate, }; });
        });        

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();            

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };

            prTableData.data = [];
            dtTableData.data = [];
            drTableData.data = [];            

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'thermistorpadpr':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                  case 'thermistorpaddt':
                    dtTableData.data = res.data;
                    break;
                  case 'thermistorpaddr':
                    drTableData.data = res.data;
                    break;  
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName);
            searchData(dtTableName);
            searchData(drTableName);            
            settingCall();
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let shiftSums = {
            1: { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0 },           
            2: { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0 },           
          };

          let modelSums = {
            "EVO_2P": { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0 },                        
            "EVO_3P": { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0 },           
          };

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].oktotal += row.oktotal;
              shiftSums[row.shift].ok_a += row.ok_a;
              shiftSums[row.shift].ok_b += row.ok_b;
              shiftSums[row.shift].ok_c += row.ok_c;
              shiftSums[row.shift].verification += row.verification;
              shiftSums[row.shift].ng += row.ng;    
              shiftSums[row.shift].ng_fpcadamage += row.ng_fpcadamage;
              shiftSums[row.shift].ng_thermistordamage += row.ng_thermistordamage;
              }

            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
              modelSums[row.model].target += row.target;
              modelSums[row.model].input += row.input;
              modelSums[row.model].oktotal += row.oktotal;
              modelSums[row.model].ok_a += row.ok_a;
              modelSums[row.model].ok_b += row.ok_b;
              modelSums[row.model].ok_c += row.ok_c;
              modelSums[row.model].verification += row.verification;
              modelSums[row.model].ng += row.ng;    
              modelSums[row.model].ng_fpcadamage += row.ng_fpcadamage;           
              modelSums[row.model].ng_thermistordamage += row.ng_thermistordamage;    
            }
          }
          return { shiftSums, modelSums, };
        });

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error download excel data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });
          const wsDt = XLSX.utils.json_to_sheet(dtTableData.data, { header: dtHeaders.value });
          const wsDr = XLSX.utils.json_to_sheet(drTableData.data, { header: drHeaders.value });          

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "PR Data");
          XLSX.utils.book_append_sheet(wb, wsDt, "DT Data");
          XLSX.utils.book_append_sheet(wb, wsDr, "DR Data");          

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "data.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 dtTableName, drTableName, sendDate,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped>
  .act { display: flex; justify-content: space-between; padding: 10px 10px 5px 5px;}
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .modalButtonContainer { display: inline-flex;}
  .button { padding: 5px 5px; background-color: #3498db; color: #fff;  border: none;  border-radius: 4px;
            font-size: 14px; font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .qwallButton { padding: 5px 5px; background-color: #00eb91; color: #000000; border: none;  border-radius: 4px;
                 font-size: 14px; font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchButton { padding: 5px 5px; background-color: #94a5b1; color: #000000; border: none; border-radius: 4px;
                  font-size: 14px; font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton { padding: 5px 5px; background-color: #10a802; color: #fff;  border: none;  border-radius: 4px;
                 font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton:hover, .searchButton:hover, .qwallButton:hover, .button:hover { background-color: #2980b9;}    
  .searchBar { display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;  margin-bottom: 20px; margin-left: 20px;}
  .searchBar > *:not(:last-child) { margin-right: 5px; }
  .searchBar select, .searchBar input { width : 80px; margin-right: 5px; padding : 5px; }
  label { font-size: 14px; font-weight: 600; margin-right: 8px; }
  .sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}
  td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
  th { background-color: rgb(230, 230, 230); border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
  .tables { display: flex; flex-direction: column; align-items: stretch;  padding-left : 20px; padding-right : 20px;}
  table { width: 80%; }
  #dailyReport td { text-align: left; }
  .custom-table { font-size: 10px; width: 80%;  overflow-x: auto;  white-space: nowrap; }
  .model-Front { background-color: #a0eefc;}
  .model-Rear { background-color: #9887e4; }
  .ok { background-color: #94b7f8; font-weight: bold;}
  .ng { background-color: #fa7979; font-weight: bold;}
  .zero-value_ng { background-color: #fa7979;  color: #fa7979; }
  .ng_type { background-color: #fdb08d; }
  .zero-value_ngtype { background-color: #fdb08d; color: #fdb08d;}
  .fn {background-color: #ff72d5;}
  .zero-value_fn {background-color: #ff72d5; color: #ff72d5; }
  .fn_type { background-color: #ff9bcd; }
  .zero-value_fntype { background-color: #ff9bcd; color: #ff9bcd; }
  .verify { background-color: #ddf505; }
  .zero-value_verify { background-color: #ddf505; color: #ddf505; }
  .qwall { background-color: #66b179; }
  .shift1 { background-color: rgb(174, 240, 147); }
  .shift2 { background-color: rgba(248, 250, 145, 0.979); }

</style>