<template>
    <div class="container">        
      <div class="search">
        <form @submit.prevent="sendDate()">
        <div class="horizontal-form">                
          <div class="start">
              <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
              <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
              <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
          </div>
          <div class="separator">~</div>
          <div class="end">
              <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
              <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
              <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
          </div>
          <div class="searchTd">
              <button type="submit" @click="callSearchData">Search</button>                        
          </div>
          <div class="searchTd">
              <button class="excelButton" @click="prdownloadExcel">Production Excel</button>                                              
          </div>
          <div class="searchTd">
              <button class="excelButton" @click="dtdownloadExcel">Downtime Excel</button>     
          </div>
          <div class="searchTd">
              <button class="excelButton" @click="qwdownloadExcel">Q-Wall Excel</button>     
          </div>
        </div>   
        </form>
      </div>    
      <B>TR2 SMT</B>
      <table>
        <tr><th v-for="(header, index) in tr2smt_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr2smtpr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr2smtpr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr2smtpr_modelSums['2P12S'].ok - sums.tr2smtqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['2P12S'],sums.tr2smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2smtpr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr2smtpr_modelSums['2P12S'].ng + sums.tr2smtqw_modelSums['2P12S'].ng) / (sums.tr2smtpr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2smtpr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr2smtdt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.tr2smtpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr2smtpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr2smtpr_modelSums['3P8S'].ok - sums.tr2smtqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['3P8S'],sums.tr2smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2smtpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr2smtpr_modelSums['3P8S'].ng + sums.tr2smtqw_modelSums['3P8S'].ng) / (sums.tr2smtpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2smtpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr2smtdt_modelSums['3P8S'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 2P</td>          
          <td>{{ sums.tr2smtpr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.tr2smtpr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.tr2smtpr_modelSums['EVO_2P'].ok - sums.tr2smtqw_modelSums['EVO_2P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_2P'],sums.tr2smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2smtpr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.tr2smtpr_modelSums['EVO_2P'].ng + sums.tr2smtqw_modelSums['EVO_2P'].ng) / (sums.tr2smtpr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2smtpr_modelSums['EVO_2P'].work_minute}}</td>            
          <td>{{ sums.tr2smtdt_modelSums['EVO_2P'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr2smtpr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr2smtpr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr2smtpr_modelSums['EVO_3P'].ok - sums.tr2smtqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_modelSums['EVO_3P'],sums.tr2smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2smtpr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr2smtpr_modelSums['EVO_3P'].ng + sums.tr2smtqw_modelSums['EVO_3P'].ng) / (sums.tr2smtpr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2smtpr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr2smtdt_modelSums['EVO_3P'].time}}</td>         
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr2smtpr_Sums.target }}</td>
          <td>{{ sums.tr2smtpr_Sums.input }}</td>
          <td class="ok">{{ sums.tr2smtpr_Sums.ok - sums.tr2smtqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2smtpr_Sums,sums.tr2smtqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2smtpr_Sums.input === 0 ? 0 : (((sums.tr2smtpr_Sums.ng + sums.tr2smtqw_Sums.ng) / (sums.tr2smtpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2smtpr_Sums.work_minute}}</td>            
          <td>{{ sums.tr2smtdt_Sums.time}}</td>         
        </tr>        
      </table>
      <B>TR2 Crimping</B>
      <table>
        <tr><th v-for="(header, index) in tr2crimping_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr2crimpingpr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr2crimpingpr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr2crimpingpr_modelSums['2P12S'].ok - sums.tr2crimpingqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['2P12S'],sums.tr2crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2crimpingpr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr2crimpingpr_modelSums['2P12S'].ng + sums.tr2crimpingqw_modelSums['2P12S'].ng) / (sums.tr2crimpingpr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2crimpingpr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr2crimpingdt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.tr2crimpingpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr2crimpingpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr2crimpingpr_modelSums['3P8S'].ok - sums.tr2crimpingqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['3P8S'],sums.tr2crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2crimpingpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr2crimpingpr_modelSums['3P8S'].ng + sums.tr2crimpingqw_modelSums['3P8S'].ng) / (sums.tr2crimpingpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2crimpingpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr2crimpingdt_modelSums['3P8S'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 2P</td>          
          <td>{{ sums.tr2crimpingpr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.tr2crimpingpr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.tr2crimpingpr_modelSums['EVO_2P'].ok - sums.tr2crimpingqw_modelSums['EVO_2P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_2P'],sums.tr2crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2crimpingpr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.tr2crimpingpr_modelSums['EVO_2P'].ng + sums.tr2crimpingqw_modelSums['EVO_2P'].ng) / (sums.tr2crimpingpr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2crimpingpr_modelSums['EVO_2P'].work_minute}}</td>            
          <td>{{ sums.tr2crimpingdt_modelSums['EVO_2P'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr2crimpingpr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr2crimpingpr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr2crimpingpr_modelSums['EVO_3P'].ok - sums.tr2crimpingqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_modelSums['EVO_3P'],sums.tr2crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2crimpingpr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr2crimpingpr_modelSums['EVO_3P'].ng + sums.tr2crimpingqw_modelSums['EVO_3P'].ng) / (sums.tr2crimpingpr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2crimpingpr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr2crimpingdt_modelSums['EVO_3P'].time}}</td>          
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr2crimpingpr_Sums.target }}</td>
          <td>{{ sums.tr2crimpingpr_Sums.input }}</td>
          <td class="ok">{{ sums.tr2crimpingpr_Sums.ok - sums.tr2crimpingqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2crimpingpr_Sums,sums.tr2crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2crimpingpr_Sums.input === 0 ? 0 : (((sums.tr2crimpingpr_Sums.ng + sums.tr2crimpingqw_Sums.ng) / (sums.tr2crimpingpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2crimpingpr_Sums.work_minute}}</td>            
          <td>{{ sums.tr2crimpingdt_Sums.time}}</td>         
        </tr>        
      </table>     
      <B>TR2 FPCA</B>
      <table>
        <tr><th v-for="(header, index) in tr2fpca_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr2fpcapr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr2fpcapr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr2fpcapr_modelSums['2P12S'].ok - sums.tr2fpcaqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['2P12S'],sums.tr2fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2fpcapr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr2fpcapr_modelSums['2P12S'].ng + sums.tr2fpcaqw_modelSums['2P12S'].ng) / (sums.tr2fpcapr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2fpcapr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr2fpcadt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.tr2fpcapr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr2fpcapr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr2fpcapr_modelSums['3P8S'].ok - sums.tr2fpcaqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['3P8S'],sums.tr2fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2fpcapr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr2fpcapr_modelSums['3P8S'].ng + sums.tr2fpcaqw_modelSums['3P8S'].ng) / (sums.tr2fpcapr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2fpcapr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr2fpcadt_modelSums['3P8S'].time}}</td>        
        </tr>
        <tr>
          <td>EVO 2P</td>          
          <td>{{ sums.tr2fpcapr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.tr2fpcapr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.tr2fpcapr_modelSums['EVO_2P'].ok - sums.tr2fpcaqw_modelSums['EVO_2P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_2P'],sums.tr2fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2fpcapr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.tr2fpcapr_modelSums['EVO_2P'].ng + sums.tr2fpcaqw_modelSums['EVO_2P'].ng) / (sums.tr2fpcapr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2fpcapr_modelSums['EVO_2P'].work_minute}}</td>            
          <td>{{ sums.tr2fpcadt_modelSums['EVO_2P'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr2fpcapr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr2fpcapr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr2fpcapr_modelSums['EVO_3P'].ok - sums.tr2fpcaqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_modelSums['EVO_3P'],sums.tr2fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr2fpcapr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr2fpcapr_modelSums['EVO_3P'].ng + sums.tr2fpcaqw_modelSums['EVO_3P'].ng) / (sums.tr2fpcapr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2fpcapr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr2fpcadt_modelSums['EVO_3P'].time}}</td>         
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr2fpcapr_Sums.target }}</td>
          <td>{{ sums.tr2fpcapr_Sums.input }}</td>
          <td class="ok">{{ sums.tr2fpcapr_Sums.ok - sums.tr2fpcaqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2fpcapr_Sums,sums.tr2fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>          
          <td class="ng">{{ sums.tr2fpcapr_Sums.input === 0 ? 0 : (((sums.tr2fpcapr_Sums.ng + sums.tr2fpcaqw_Sums.ng) / (sums.tr2fpcapr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2fpcapr_Sums.work_minute}}</td>            
          <td>{{ sums.tr2fpcadt_Sums.time}}</td>         
        </tr>        
      </table>
      <B>TR2 BFA 3P</B>
      <table>
        <tr><th v-for="(header, index) in tr2bfa3p_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>3P8S</td>          
          <td>{{ sums.tr2bfa3ppr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr2bfa3ppr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr2bfa3ppr_modelSums['3P8S'].ok - sums.tr2bfa3pqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P8S'],sums.tr2bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2bfa3ppr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr2bfa3ppr_modelSums['3P8S'].ng + sums.tr2bfa3pqw_modelSums['3P8S'].ng) / (sums.tr2bfa3ppr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa3ppr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr2bfa3pdt_modelSums['3P8S'].time}}</td>   
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr2bfa3ppr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr2bfa3ppr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr2bfa3ppr_modelSums['EVO_3P'].ok - sums.tr2bfa3pqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['EVO_3P'],sums.tr2bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2bfa3ppr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr2bfa3ppr_modelSums['EVO_3P'].ng + sums.tr2bfa3pqw_modelSums['EVO_3P'].ng) / (sums.tr2bfa3ppr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa3ppr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr2bfa3pdt_modelSums['EVO_3P'].time}}</td>    
        </tr>
        <tr>
          <td>3P GEN.1</td>          
          <td>{{ sums.tr2bfa3ppr_modelSums['3P_GEN.1'].target }}</td>
          <td>{{ sums.tr2bfa3ppr_modelSums['3P_GEN.1'].input }}</td>
          <td class="ok">{{ sums.tr2bfa3ppr_modelSums['3P_GEN.1'].ok - sums.tr2bfa3pqw_modelSums['3P_GEN.1'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_modelSums['3P_GEN.1'],sums.tr2bfa3pqw_modelSums['3P_GEN.1']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2bfa3ppr_modelSums['3P_GEN.1'].input === 0 ? 0 : (((sums.tr2bfa3ppr_modelSums['3P_GEN.1'].ng + sums.tr2bfa3pqw_modelSums['3P_GEN.1'].ng) / (sums.tr2bfa3ppr_modelSums['3P_GEN.1'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa3ppr_modelSums['3P_GEN.1'].work_minute}}</td>            
          <td>{{ sums.tr2bfa3pdt_modelSums['3P_GEN.1'].time}}</td>    
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr2bfa3ppr_Sums.target }}</td>
          <td>{{ sums.tr2bfa3ppr_Sums.input }}</td>
          <td class="ok">{{ sums.tr2bfa3ppr_Sums.ok - sums.tr2bfa3pqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa3ppr_Sums,sums.tr2bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>          
          <td class="ng">{{ sums.tr2bfa3ppr_Sums.input === 0 ? 0 : (((sums.tr2bfa3ppr_Sums.ng + sums.tr2bfa3pqw_Sums.ng) / (sums.tr2bfa3ppr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa3ppr_Sums.work_minute}}</td>            
          <td>{{ sums.tr2bfa3pdt_Sums.time}}</td>         
        </tr>        
      </table>  
      <B>TR3 SMT</B>
      <table>
        <tr><th v-for="(header, index) in tr3smt_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr3smtpr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr3smtpr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr3smtpr_modelSums['2P12S'].ok - sums.tr3smtqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['2P12S'],sums.tr3smtqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3smtpr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr3smtpr_modelSums['2P12S'].ng + sums.tr3smtqw_modelSums['2P12S'].ng) / (sums.tr3smtpr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3smtpr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr3smtdt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.tr3smtpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr3smtpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr3smtpr_modelSums['3P8S'].ok - sums.tr3smtqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['3P8S'],sums.tr3smtqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3smtpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr3smtpr_modelSums['3P8S'].ng + sums.tr3smtqw_modelSums['3P8S'].ng) / (sums.tr3smtpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3smtpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr3smtdt_modelSums['3P8S'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 2P</td>          
          <td>{{ sums.tr3smtpr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.tr3smtpr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.tr3smtpr_modelSums['EVO_2P'].ok - sums.tr3smtqw_modelSums['EVO_2P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_2P'],sums.tr3smtqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3smtpr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.tr3smtpr_modelSums['EVO_2P'].ng + sums.tr3smtqw_modelSums['EVO_2P'].ng) / (sums.tr3smtpr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3smtpr_modelSums['EVO_2P'].work_minute}}</td>            
          <td>{{ sums.tr3smtdt_modelSums['EVO_2P'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr3smtpr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr3smtpr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr3smtpr_modelSums['EVO_3P'].ok - sums.tr3smtqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_modelSums['EVO_3P'],sums.tr3smtqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3smtpr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr3smtpr_modelSums['EVO_3P'].ng + sums.tr3smtqw_modelSums['EVO_3P'].ng) / (sums.tr3smtpr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3smtpr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr3smtdt_modelSums['EVO_3P'].time}}</td>         
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr3smtpr_Sums.target }}</td>
          <td>{{ sums.tr3smtpr_Sums.input }}</td>
          <td class="ok">{{ sums.tr3smtpr_Sums.ok - sums.tr3smtqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3smtpr_Sums,sums.tr3smtqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3smtpr_Sums.input === 0 ? 0 : (((sums.tr3smtpr_Sums.ng + sums.tr3smtqw_Sums.ng) / (sums.tr3smtpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3smtpr_Sums.work_minute}}</td>            
          <td>{{ sums.tr3smtdt_Sums.time}}</td>         
        </tr>        
      </table>
      <B>TR3 Crimping</B>
      <table>
        <tr><th v-for="(header, index) in tr3crimping_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr3crimpingpr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr3crimpingpr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr3crimpingpr_modelSums['2P12S'].ok - sums.tr3crimpingqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['2P12S'],sums.tr3crimpingqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3crimpingpr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr3crimpingpr_modelSums['2P12S'].ng + sums.tr3crimpingqw_modelSums['2P12S'].ng) / (sums.tr3crimpingpr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3crimpingpr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr3crimpingdt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.tr3crimpingpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr3crimpingpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr3crimpingpr_modelSums['3P8S'].ok - sums.tr3crimpingqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['3P8S'],sums.tr3crimpingqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3crimpingpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr3crimpingpr_modelSums['3P8S'].ng + sums.tr3crimpingqw_modelSums['3P8S'].ng) / (sums.tr3crimpingpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3crimpingpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr3crimpingdt_modelSums['3P8S'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 2P</td>          
          <td>{{ sums.tr3crimpingpr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.tr3crimpingpr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.tr3crimpingpr_modelSums['EVO_2P'].ok - sums.tr3crimpingqw_modelSums['EVO_2P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_2P'],sums.tr3crimpingqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3crimpingpr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.tr3crimpingpr_modelSums['EVO_2P'].ng + sums.tr3crimpingqw_modelSums['EVO_2P'].ng) / (sums.tr3crimpingpr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3crimpingpr_modelSums['EVO_2P'].work_minute}}</td>            
          <td>{{ sums.tr3crimpingdt_modelSums['EVO_2P'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr3crimpingpr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr3crimpingpr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr3crimpingpr_modelSums['EVO_3P'].ok - sums.tr3crimpingqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_modelSums['EVO_3P'],sums.tr3crimpingqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3crimpingpr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr3crimpingpr_modelSums['EVO_3P'].ng + sums.tr3crimpingqw_modelSums['EVO_3P'].ng) / (sums.tr3crimpingpr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3crimpingpr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr3crimpingdt_modelSums['EVO_3P'].time}}</td>          
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr3crimpingpr_Sums.target }}</td>
          <td>{{ sums.tr3crimpingpr_Sums.input }}</td>
          <td class="ok">{{ sums.tr3crimpingpr_Sums.ok - sums.tr3crimpingqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_caoiheight, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_caoishift, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_machineerror, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_weldplate, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3crimpingpr_Sums,sums.tr3crimpingqw_Sums].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3crimpingpr_Sums.input === 0 ? 0 : (((sums.tr3crimpingpr_Sums.ng + sums.tr3crimpingqw_Sums.ng) / (sums.tr3crimpingpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3crimpingpr_Sums.work_minute}}</td>            
          <td>{{ sums.tr3crimpingdt_Sums.time}}</td>         
        </tr>        
      </table>     
      <B>TR3 FPCA</B>
      <table>
        <tr><th v-for="(header, index) in tr3fpca_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr3fpcapr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr3fpcapr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr3fpcapr_modelSums['2P12S'].ok - sums.tr3fpcaqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['2P12S'],sums.tr3fpcaqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3fpcapr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr3fpcapr_modelSums['2P12S'].ng + sums.tr3fpcaqw_modelSums['2P12S'].ng) / (sums.tr3fpcapr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3fpcapr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr3fpcadt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.tr3fpcapr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr3fpcapr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr3fpcapr_modelSums['3P8S'].ok - sums.tr3fpcaqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['3P8S'],sums.tr3fpcaqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3fpcapr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr3fpcapr_modelSums['3P8S'].ng + sums.tr3fpcaqw_modelSums['3P8S'].ng) / (sums.tr3fpcapr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3fpcapr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr3fpcadt_modelSums['3P8S'].time}}</td>        
        </tr>
        <tr>
          <td>EVO 2P</td>          
          <td>{{ sums.tr3fpcapr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.tr3fpcapr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.tr3fpcapr_modelSums['EVO_2P'].ok - sums.tr3fpcaqw_modelSums['EVO_2P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_2P'],sums.tr3fpcaqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3fpcapr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.tr3fpcapr_modelSums['EVO_2P'].ng + sums.tr3fpcaqw_modelSums['EVO_2P'].ng) / (sums.tr3fpcapr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3fpcapr_modelSums['EVO_2P'].work_minute}}</td>            
          <td>{{ sums.tr3fpcadt_modelSums['EVO_2P'].time}}</td>         
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr3fpcapr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr3fpcapr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr3fpcapr_modelSums['EVO_3P'].ok - sums.tr3fpcaqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_modelSums['EVO_3P'],sums.tr3fpcaqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng">{{ sums.tr3fpcapr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr3fpcapr_modelSums['EVO_3P'].ng + sums.tr3fpcaqw_modelSums['EVO_3P'].ng) / (sums.tr3fpcapr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3fpcapr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr3fpcadt_modelSums['EVO_3P'].time}}</td>         
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr3fpcapr_Sums.target }}</td>
          <td>{{ sums.tr3fpcapr_Sums.input }}</td>
          <td class="ok">{{ sums.tr3fpcapr_Sums.ok - sums.tr3fpcaqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toomuchacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toolessacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_bubbleinacrylic, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_acrylicpeeloff, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_holesinsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toomuchsolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_toolesssolder, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_qrnotreadable, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_contamination, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3fpcapr_Sums,sums.tr3fpcaqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>          
          <td class="ng">{{ sums.tr3fpcapr_Sums.input === 0 ? 0 : (((sums.tr3fpcapr_Sums.ng + sums.tr3fpcaqw_Sums.ng) / (sums.tr3fpcapr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3fpcapr_Sums.work_minute}}</td>            
          <td>{{ sums.tr3fpcadt_Sums.time}}</td>         
        </tr>        
      </table>
      <B>EVO 1(TR3 BFA 2P)</B>
      <table>
        <tr><th v-for="(header, index) in tr3bfa2p_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr3bfa2ppr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr3bfa2ppr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr3bfa2ppr_modelSums['2P12S'].ok - sums.tr3bfa2pqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['2P12S'],sums.tr3bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3bfa2ppr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr3bfa2ppr_modelSums['2P12S'].ng + sums.tr3bfa2pqw_modelSums['2P12S'].ng) / (sums.tr3bfa2ppr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3bfa2ppr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr3bfa2pdt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr3bfa2ppr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr3bfa2ppr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr3bfa2ppr_modelSums['EVO_3P'].ok - sums.tr3bfa2pqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_modelSums['EVO_3P'],sums.tr3bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3bfa2ppr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr3bfa2ppr_modelSums['EVO_3P'].ng + sums.tr3bfa2pqw_modelSums['EVO_3P'].ng) / (sums.tr3bfa2ppr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3bfa2ppr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr3bfa2pdt_modelSums['EVO_3P'].time}}</td>    
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr3bfa2ppr_Sums.target }}</td>
          <td>{{ sums.tr3bfa2ppr_Sums.input }}</td>
          <td class="ok">{{ sums.tr3bfa2ppr_Sums.ok - sums.tr3bfa2pqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa2ppr_Sums,sums.tr3bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>          
          <td class="ng">{{ sums.tr3bfa2ppr_Sums.input === 0 ? 0 : (((sums.tr3bfa2ppr_Sums.ng + sums.tr3bfa2pqw_Sums.ng) / (sums.tr3bfa2ppr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3bfa2ppr_Sums.work_minute}}</td>            
          <td>{{ sums.tr3bfa2pdt_Sums.time}}</td>         
        </tr>        
      </table>       
      <B>EVO 2(TR3 BFA 3P)</B>
      <table>
        <tr><th v-for="(header, index) in tr3bfa3p_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>3P8S</td>          
          <td>{{ sums.tr3bfa3ppr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.tr3bfa3ppr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.tr3bfa3ppr_modelSums['3P8S'].ok - sums.tr3bfa3pqw_modelSums['3P8S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['3P8S'],sums.tr3bfa3pqw_modelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3bfa3ppr_modelSums['3P8S'].input === 0 ? 0 : (((sums.tr3bfa3ppr_modelSums['3P8S'].ng + sums.tr3bfa3pqw_modelSums['3P8S'].ng) / (sums.tr3bfa3ppr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3bfa3ppr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.tr3bfa3pdt_modelSums['3P8S'].time}}</td>   
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr3bfa3ppr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr3bfa3ppr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr3bfa3ppr_modelSums['EVO_3P'].ok - sums.tr3bfa3pqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_modelSums['EVO_3P'],sums.tr3bfa3pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr3bfa3ppr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr3bfa3ppr_modelSums['EVO_3P'].ng + sums.tr3bfa3pqw_modelSums['EVO_3P'].ng) / (sums.tr3bfa3ppr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3bfa3ppr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr3bfa3pdt_modelSums['EVO_3P'].time}}</td>    
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr3bfa3ppr_Sums.target }}</td>
          <td>{{ sums.tr3bfa3ppr_Sums.input }}</td>
          <td class="ok">{{ sums.tr3bfa3ppr_Sums.ok - sums.tr3bfa3pqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr3bfa3ppr_Sums,sums.tr3bfa3pqw_Sums].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>          
          <td class="ng">{{ sums.tr3bfa3ppr_Sums.input === 0 ? 0 : (((sums.tr3bfa3ppr_Sums.ng + sums.tr3bfa3pqw_Sums.ng) / (sums.tr3bfa3ppr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr3bfa3ppr_Sums.work_minute}}</td>            
          <td>{{ sums.tr3bfa3pdt_Sums.time}}</td>         
        </tr>        
      </table>
      <B>EVO 3(TR2 BFA 2P)</B>
      <table>
        <tr><th v-for="(header, index) in tr2bfa2p_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>2P12S</td>          
          <td>{{ sums.tr2bfa2ppr_modelSums['2P12S'].target }}</td>
          <td>{{ sums.tr2bfa2ppr_modelSums['2P12S'].input }}</td>
          <td class="ok">{{ sums.tr2bfa2ppr_modelSums['2P12S'].ok - sums.tr2bfa2pqw_modelSums['2P12S'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P12S'],sums.tr2bfa2pqw_modelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2bfa2ppr_modelSums['2P12S'].input === 0 ? 0 : (((sums.tr2bfa2ppr_modelSums['2P12S'].ng + sums.tr2bfa2pqw_modelSums['2P12S'].ng) / (sums.tr2bfa2ppr_modelSums['2P12S'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa2ppr_modelSums['2P12S'].work_minute}}</td>            
          <td>{{ sums.tr2bfa2pdt_modelSums['2P12S'].time}}</td>   
        </tr>
        <tr>
          <td>2P GEN.1</td>          
          <td>{{ sums.tr2bfa2ppr_modelSums['2P_GEN.1'].target }}</td>
          <td>{{ sums.tr2bfa2ppr_modelSums['2P_GEN.1'].input }}</td>
          <td class="ok">{{ sums.tr2bfa2ppr_modelSums['2P_GEN.1'].ok - sums.tr2bfa2pqw_modelSums['2P_GEN.1'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['2P_GEN.1'],sums.tr2bfa2pqw_modelSums['2P_GEN.1']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2bfa2ppr_modelSums['2P_GEN.1'].input === 0 ? 0 : (((sums.tr2bfa2ppr_modelSums['2P_GEN.1'].ng + sums.tr2bfa2pqw_modelSums['2P_GEN.1'].ng) / (sums.tr2bfa2ppr_modelSums['2P_GEN.1'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa2ppr_modelSums['2P_GEN.1'].work_minute}}</td>            
          <td>{{ sums.tr2bfa2pdt_modelSums['2P_GEN.1'].time}}</td>    
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.tr2bfa2ppr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.tr2bfa2ppr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.tr2bfa2ppr_modelSums['EVO_3P'].ok - sums.tr2bfa2pqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_modelSums['EVO_3P'],sums.tr2bfa2pqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.tr2bfa2ppr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.tr2bfa2ppr_modelSums['EVO_3P'].ng + sums.tr2bfa2pqw_modelSums['EVO_3P'].ng) / (sums.tr2bfa2ppr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa2ppr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.tr2bfa2pdt_modelSums['EVO_3P'].time}}</td>    
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.tr2bfa2ppr_Sums.target }}</td>
          <td>{{ sums.tr2bfa2ppr_Sums.input }}</td>
          <td class="ok">{{ sums.tr2bfa2ppr_Sums.ok - sums.tr2bfa2pqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.tr2bfa2ppr_Sums,sums.tr2bfa2pqw_Sums].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>          
          <td class="ng">{{ sums.tr2bfa2ppr_Sums.input === 0 ? 0 : (((sums.tr2bfa2ppr_Sums.ng + sums.tr2bfa2pqw_Sums.ng) / (sums.tr2bfa2ppr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.tr2bfa2ppr_Sums.work_minute}}</td>            
          <td>{{ sums.tr2bfa2pdt_Sums.time}}</td>         
        </tr>        
      </table>  
      <B>EVO Hybrid</B>
      <table>
        <tr><th v-for="(header, index) in evo_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>EVO 2P</td>          
          <td>{{ sums.evopr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.evopr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.evopr_modelSums['EVO_2P'].ok - sums.evoqw_modelSums['EVO_2P'].ng }}</td>         
          <td class="ng">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_2P'],sums.evoqw_modelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.evopr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.evopr_modelSums['EVO_2P'].ng + sums.evoqw_modelSums['EVO_2P'].ng) / (sums.evopr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.evopr_modelSums['EVO_2P'].work_minute}}</td>            
          <td>{{ sums.evodt_modelSums['EVO_2P'].time}}</td>   
        </tr>
        <tr>
          <td>EVO 3P</td>          
          <td>{{ sums.evopr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.evopr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.evopr_modelSums['EVO_3P'].ok - sums.evoqw_modelSums['EVO_3P'].ng }}</td>         
          <td class="ng">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_modelSums['EVO_3P'],sums.evoqw_modelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>
          <td class="ng">{{ sums.evopr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.evopr_modelSums['EVO_3P'].ng + sums.evoqw_modelSums['EVO_3P'].ng) / (sums.evopr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.evopr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.evodt_modelSums['EVO_3P'].time}}</td>    
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.evopr_Sums.target }}</td>
          <td>{{ sums.evopr_Sums.input }}</td>
          <td class="ok">{{ sums.evopr_Sums.ok - sums.evoqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.evopr_Sums,sums.evoqw_Sums].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>          
          <td class="ng">{{ sums.evopr_Sums.input === 0 ? 0 : (((sums.evopr_Sums.ng + sums.evoqw_Sums.ng) / (sums.evopr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.evopr_Sums.work_minute}}</td>            
          <td>{{ sums.evodt_Sums.time}}</td>         
        </tr>        
      </table>
      <B>EV2020 60kW</B>
      <table>
        <tr><th v-for="(header, index) in ev2020_60fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>
          <td>60kW Front</td>          
          <td>{{ sums.ev2020_60frpr_Sums.target }}</td>
          <td>{{ sums.ev2020_60frpr_Sums.input }}</td>
          <td class="ok">{{ sums.ev2020_60frpr_Sums.ok - sums.ev2020_60frqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>              
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums,sums.ev2020_60frqw_Sums].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>   
          <td class="ng">{{ sums.ev2020_60frpr_Sums.input === 0 ? 0 : (((sums.ev2020_60frpr_Sums.ng + sums.ev2020_60frqw_Sums.ng) / (sums.ev2020_60frpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_60frpr_Sums.work_minute}}</td>            
          <td>{{ sums.ev2020_60frdt_Sums.time}}</td>         
        </tr> 
        <tr>
          <td>60kW Rear</td>          
          <td>{{ sums.ev2020_60rrpr_Sums.target }}</td>
          <td>{{ sums.ev2020_60rrpr_Sums.input }}</td>
          <td class="ok">{{ sums.ev2020_60rrpr_Sums.ok - sums.ev2020_60rrqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>              
          <td class="ng_type">{{ [ sums.ev2020_60rrpr_Sums,sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>  
          <td class="ng">{{ sums.ev2020_60rrpr_Sums.input === 0 ? 0 : (((sums.ev2020_60rrpr_Sums.ng + sums.ev2020_60rrqw_Sums.ng) / (sums.ev2020_60rrpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_60rrpr_Sums.work_minute}}</td>            
          <td>{{ sums.ev2020_60rrdt_Sums.time}}</td>         
        </tr>     
        <tr>
          <td>Total</td>          
          <td>{{ sums.ev2020_60frpr_Sums.target + sums.ev2020_60rrpr_Sums.target }}</td>
          <td>{{ sums.ev2020_60frpr_Sums.input + sums.ev2020_60rrpr_Sums.input }}</td>
          <td class="ok">{{ sums.ev2020_60frpr_Sums.ok + sums.ev2020_60rrpr_Sums.ok - sums.ev2020_60frqw_Sums.ng - sums.ev2020_60rrqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>              
          <td class="ng_type">{{ [ sums.ev2020_60frpr_Sums, sums.ev2020_60rrpr_Sums, sums.ev2020_60frqw_Sums, sums.ev2020_60rrqw_Sums].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>              
          <td class="ng">{{ (sums.ev2020_60frpr_Sums.input + sums.ev2020_60rrpr_Sums.input) === 0 ? 0 : (((sums.ev2020_60frpr_Sums.ng + sums.ev2020_60rrpr_Sums.ng + sums.ev2020_60frqw_Sums.ng + sums.ev2020_60rrqw_Sums.ng) / (sums.ev2020_60frpr_Sums.input + sums.ev2020_60rrpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_60frpr_Sums.work_minute + sums.ev2020_60rrpr_Sums.work_minute}}</td>            
          <td>{{ sums.ev2020_60frdt_Sums.time + sums.ev2020_60rrdt_Sums.time}}</td>         
        </tr>               
      </table>   
      <B>EV2020 40/60kW</B>
      <table>
        <tr><th v-for="(header, index) in ev2020_4060fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>40kW Front</td>          
          <td>{{ sums.ev2020_4060frpr_modelSums['40kW Front'].target }}</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['40kW Front'].input }}</td>
          <td class="ok">{{ sums.ev2020_4060frpr_modelSums['40kW Front'].ok - sums.ev2020_4060frqw_modelSums['40kW Front'].ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060frqw_modelSums['40kW Front']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>
          <td class="ng">{{ sums.ev2020_4060frpr_modelSums['40kW Front'].input === 0 ? 0 : (((sums.ev2020_4060frpr_modelSums['40kW Front'].ng + sums.ev2020_4060frqw_modelSums['40kW Front'].ng) / (sums.ev2020_4060frpr_modelSums['40kW Front'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['40kW Front'].work_minute}}</td>            
          <td>{{ sums.ev2020_4060frdt_modelSums['40kW Front'].time}}</td>   
        </tr>
        <tr>      
          <td>40kW Rear</td>          
          <td>{{ sums.ev2020_4060rrpr_modelSums['40kW Rear'].target }}</td>
          <td>{{ sums.ev2020_4060rrpr_modelSums['40kW Rear'].input }}</td>
          <td class="ok">{{ sums.ev2020_4060rrpr_modelSums['40kW Rear'].ok - sums.ev2020_4060rrqw_modelSums['40kW Rear'].ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>
          <td class="ng">{{ sums.ev2020_4060rrpr_modelSums['40kW Rear'].input === 0 ? 0 : (((sums.ev2020_4060rrpr_modelSums['40kW Rear'].ng + sums.ev2020_4060rrqw_modelSums['40kW Rear'].ng) / (sums.ev2020_4060rrpr_modelSums['40kW Rear'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_4060rrpr_modelSums['40kW Rear'].work_minute}}</td>            
          <td>{{ sums.ev2020_4060rrdt_modelSums['40kW Rear'].time}}</td>   
        </tr>
        <tr>
          <td>40kW Total</td>          
          <td>{{ sums.ev2020_4060frpr_modelSums['40kW Front'].target + sums.ev2020_4060rrpr_modelSums['40kW Rear'].target }}</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['40kW Front'].input + sums.ev2020_4060rrpr_modelSums['40kW Rear'].input }}</td>
          <td class="ok">{{ sums.ev2020_4060frpr_modelSums['40kW Front'].ok + sums.ev2020_4060rrpr_modelSums['40kW Rear'].ok - sums.ev2020_4060frqw_modelSums['40kW Front'].ng - sums.ev2020_4060rrqw_modelSums['40kW Rear'].ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['40kW Front'],sums.ev2020_4060rrpr_modelSums['40kW Rear'],sums.ev2020_4060frqw_modelSums['40kW Front'],sums.ev2020_4060rrqw_modelSums['40kW Rear']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>
          <td class="ng">{{ sums.ev2020_4060frpr_modelSums['40kW Front'].input + sums.ev2020_4060rrpr_modelSums['40kW Rear'].input === 0 ? 0 : (((sums.ev2020_4060frpr_modelSums['40kW Front'].ng + sums.ev2020_4060rrpr_modelSums['40kW Rear'].ng + sums.ev2020_4060frqw_modelSums['40kW Front'].ng + sums.ev2020_4060rrqw_modelSums['40kW Rear'].ng) / (sums.ev2020_4060frpr_modelSums['40kW Front'].input + sums.ev2020_4060rrpr_modelSums['40kW Rear'].input ) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['40kW Front'].work_minute + sums.ev2020_4060rrpr_modelSums['40kW Rear'].work_minute}}</td>            
          <td>{{ sums.ev2020_4060frdt_modelSums['40kW Front'].time + sums.ev2020_4060rrdt_modelSums['40kW Rear'].time}}</td>        
        </tr>  
        <tr>      
          <td>60kW Front</td>          
          <td>{{ sums.ev2020_4060frpr_modelSums['60kW Front'].target }}</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['60kW Front'].input }}</td>
          <td class="ok">{{ sums.ev2020_4060frpr_modelSums['60kW Front'].ok - sums.ev2020_4060frqw_modelSums['60kW Front'].ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060frqw_modelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>
          <td class="ng">{{ sums.ev2020_4060frpr_modelSums['60kW Front'].input === 0 ? 0 : (((sums.ev2020_4060frpr_modelSums['60kW Front'].ng + sums.ev2020_4060frqw_modelSums['60kW Front'].ng) / (sums.ev2020_4060frpr_modelSums['60kW Front'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['60kW Front'].work_minute}}</td>            
          <td>{{ sums.ev2020_4060frdt_modelSums['60kW Front'].time}}</td>   
        </tr>
        <tr>      
          <td>60kW Rear</td>          
          <td>{{ sums.ev2020_4060rrpr_modelSums['60kW Rear'].target }}</td>
          <td>{{ sums.ev2020_4060rrpr_modelSums['60kW Rear'].input }}</td>
          <td class="ok">{{ sums.ev2020_4060rrpr_modelSums['60kW Rear'].ok - sums.ev2020_4060rrqw_modelSums['60kW Rear'].ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>
          <td class="ng">{{ sums.ev2020_4060rrpr_modelSums['60kW Rear'].input === 0 ? 0 : (((sums.ev2020_4060rrpr_modelSums['60kW Rear'].ng + sums.ev2020_4060rrqw_modelSums['60kW Rear'].ng) / (sums.ev2020_4060rrpr_modelSums['60kW Rear'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_4060rrpr_modelSums['60kW Rear'].work_minute}}</td>            
          <td>{{ sums.ev2020_4060rrdt_modelSums['60kW Rear'].time}}</td>   
        </tr>
        <tr>
          <td>60kW Total</td>          
          <td>{{ sums.ev2020_4060frpr_modelSums['60kW Front'].target + sums.ev2020_4060rrpr_modelSums['60kW Rear'].target }}</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['60kW Front'].input + sums.ev2020_4060rrpr_modelSums['60kW Rear'].input }}</td>
          <td class="ok">{{ sums.ev2020_4060frpr_modelSums['60kW Front'].ok + sums.ev2020_4060rrpr_modelSums['60kW Rear'].ok - sums.ev2020_4060frqw_modelSums['60kW Front'].ng - sums.ev2020_4060rrqw_modelSums['60kW Rear'].ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_modelSums['60kW Front'],sums.ev2020_4060rrpr_modelSums['60kW Rear'],sums.ev2020_4060frqw_modelSums['60kW Front'],sums.ev2020_4060rrqw_modelSums['60kW Rear']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>
          <td class="ng">{{ sums.ev2020_4060frpr_modelSums['60kW Front'].input + sums.ev2020_4060rrpr_modelSums['60kW Rear'].input === 0 ? 0 : (((sums.ev2020_4060frpr_modelSums['60kW Front'].ng + sums.ev2020_4060rrpr_modelSums['60kW Rear'].ng + sums.ev2020_4060frqw_modelSums['60kW Front'].ng + sums.ev2020_4060rrqw_modelSums['60kW Rear'].ng) / (sums.ev2020_4060frpr_modelSums['60kW Front'].input + sums.ev2020_4060rrpr_modelSums['60kW Rear'].input ) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_4060frpr_modelSums['60kW Front'].work_minute + sums.ev2020_4060rrpr_modelSums['60kW Rear'].work_minute}}</td>            
          <td>{{ sums.ev2020_4060frdt_modelSums['60kW Front'].time + sums.ev2020_4060rrdt_modelSums['60kW Rear'].time}}</td>        
        </tr>              
        <tr>
          <td>Total</td>          
          <td>{{ sums.ev2020_4060frpr_Sums.target + sums.ev2020_4060rrpr_Sums.target }}</td>
          <td>{{ sums.ev2020_4060frpr_Sums.input + sums.ev2020_4060rrpr_Sums.input}}</td>
          <td class="ok">{{ sums.ev2020_4060frpr_Sums.ok + sums.ev2020_4060rrpr_Sums.ok - sums.ev2020_4060frqw_Sums.ng - sums.ev2020_4060rrqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>          
          <td class="ng_type">{{ [ sums.ev2020_4060frpr_Sums,sums.ev2020_4060rrpr_Sums,sums.ev2020_4060frqw_Sums,sums.ev2020_4060rrqw_Sums].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>          
          <td class="ng">{{ (sums.ev2020_4060frpr_Sums.input + sums.ev2020_4060rrpr_Sums.input) === 0 ? 0 : (((sums.ev2020_4060frpr_Sums.ng + sums.ev2020_4060rrpr_Sums.ng + sums.ev2020_4060frqw_Sums.ng+ sums.ev2020_4060rrqw_Sums.ng) / (sums.ev2020_4060frpr_Sums.input + sums.ev2020_4060rrpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.ev2020_4060frpr_Sums.work_minute + sums.ev2020_4060rrpr_Sums.work_minute}}</td>            
          <td>{{ sums.ev2020_4060frdt_Sums.time + sums.ev2020_4060rrdt_Sums.time}}</td>         
        </tr>        
      </table>    
      <B>FORD #1 Front</B>
      <table>
        <tr><th v-for="(header, index) in ford1fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>4P8S</td>          
          <td>{{ sums.ford1frpr_modelSums['4P8S'].target }}</td>
          <td>{{ sums.ford1frpr_modelSums['4P8S'].input }}</td>
          <td class="ok">{{ sums.ford1frpr_modelSums['4P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford1frpr_modelSums['4P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1frpr_modelSums['4P8S'].input === 0 ? 0 : (((sums.ford1frpr_modelSums['4P8S'].ng) / (sums.ford1frpr_modelSums['4P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1frpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['4P8S'].fn_middleinspsum + sums.ford1frpr_modelSums['4P8S'].fn_aoingsum + sums.ford1frpr_modelSums['4P8S'].fn_coatingsum + sums.ford1frpr_modelSums['4P8S'].fn_eolsum + sums.ford1frpr_modelSums['4P8S'].fn_lqcsum + sums.ford1frpr_modelSums['4P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1frpr_modelSums['4P8S'].input === 0 ? 0 : ((sums.ford1frpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['4P8S'].fn_middleinspsum + sums.ford1frpr_modelSums['4P8S'].fn_aoingsum + sums.ford1frpr_modelSums['4P8S'].fn_coatingsum + sums.ford1frpr_modelSums['4P8S'].fn_eolsum + sums.ford1frpr_modelSums['4P8S'].fn_lqcsum + sums.ford1frpr_modelSums['4P8S'].fn_finalinspsum)/sums.ford1frpr_modelSums['4P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1frpr_modelSums['4P8S'].work_minute}}</td>            
          <td>{{ sums.ford1frdt_modelSums['4P8S'].time}}</td>   
        </tr>
        <tr>
          <td>4P7S</td>          
          <td>{{ sums.ford1frpr_modelSums['4P7S'].target }}</td>
          <td>{{ sums.ford1frpr_modelSums['4P7S'].input }}</td>
          <td class="ok">{{ sums.ford1frpr_modelSums['4P7S'].ok}}</td>         
          <td class="ng">{{ sums.ford1frpr_modelSums['4P7S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P7S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P7S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P7S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P7S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P7S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['4P7S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1frpr_modelSums['4P7S'].input === 0 ? 0 : (((sums.ford1frpr_modelSums['4P7S'].ng) / (sums.ford1frpr_modelSums['4P7S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1frpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['4P7S'].fn_middleinspsum + sums.ford1frpr_modelSums['4P7S'].fn_aoingsum + sums.ford1frpr_modelSums['4P7S'].fn_coatingsum + sums.ford1frpr_modelSums['4P7S'].fn_eolsum + sums.ford1frpr_modelSums['4P7S'].fn_lqcsum + sums.ford1frpr_modelSums['4P7S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P7S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P7S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P7S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P7S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P7S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P7S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['4P7S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1frpr_modelSums['4P7S'].input === 0 ? 0 : ((sums.ford1frpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['4P7S'].fn_middleinspsum + sums.ford1frpr_modelSums['4P7S'].fn_aoingsum + sums.ford1frpr_modelSums['4P7S'].fn_coatingsum + sums.ford1frpr_modelSums['4P7S'].fn_eolsum + sums.ford1frpr_modelSums['4P7S'].fn_lqcsum + sums.ford1frpr_modelSums['4P7S'].fn_finalinspsum)/sums.ford1frpr_modelSums['4P7S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1frpr_modelSums['4P7S'].work_minute}}</td>            
          <td>{{ sums.ford1frdt_modelSums['4P7S'].time}}</td> 
        </tr>
        <tr>
          <td>3P10S</td>          
          <td>{{ sums.ford1frpr_modelSums['3P10S'].target }}</td>
          <td>{{ sums.ford1frpr_modelSums['3P10S'].input }}</td>
          <td class="ok">{{ sums.ford1frpr_modelSums['3P10S'].ok}}</td>         
          <td class="ng">{{ sums.ford1frpr_modelSums['3P10S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P10S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P10S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P10S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P10S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P10S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P10S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1frpr_modelSums['3P10S'].input === 0 ? 0 : (((sums.ford1frpr_modelSums['3P10S'].ng) / (sums.ford1frpr_modelSums['3P10S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1frpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['3P10S'].fn_middleinspsum + sums.ford1frpr_modelSums['3P10S'].fn_aoingsum + sums.ford1frpr_modelSums['3P10S'].fn_coatingsum + sums.ford1frpr_modelSums['3P10S'].fn_eolsum + sums.ford1frpr_modelSums['3P10S'].fn_lqcsum + sums.ford1frpr_modelSums['3P10S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P10S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P10S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P10S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P10S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P10S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P10S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P10S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1frpr_modelSums['3P10S'].input === 0 ? 0 : ((sums.ford1frpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['3P10S'].fn_middleinspsum + sums.ford1frpr_modelSums['3P10S'].fn_aoingsum + sums.ford1frpr_modelSums['3P10S'].fn_coatingsum + sums.ford1frpr_modelSums['3P10S'].fn_eolsum + sums.ford1frpr_modelSums['3P10S'].fn_lqcsum + sums.ford1frpr_modelSums['3P10S'].fn_finalinspsum)/sums.ford1frpr_modelSums['3P10S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1frpr_modelSums['3P10S'].work_minute}}</td>            
          <td>{{ sums.ford1frdt_modelSums['3P10S'].time}}</td>     
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.ford1frpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.ford1frpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.ford1frpr_modelSums['3P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford1frpr_modelSums['3P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1frpr_modelSums['3P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1frpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.ford1frpr_modelSums['3P8S'].ng) / (sums.ford1frpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1frpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['3P8S'].fn_middleinspsum + sums.ford1frpr_modelSums['3P8S'].fn_aoingsum + sums.ford1frpr_modelSums['3P8S'].fn_coatingsum + sums.ford1frpr_modelSums['3P8S'].fn_eolsum + sums.ford1frpr_modelSums['3P8S'].fn_lqcsum + sums.ford1frpr_modelSums['3P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_modelSums['3P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1frpr_modelSums['3P8S'].input === 0 ? 0 : ((sums.ford1frpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford1frpr_modelSums['3P8S'].fn_middleinspsum + sums.ford1frpr_modelSums['3P8S'].fn_aoingsum + sums.ford1frpr_modelSums['3P8S'].fn_coatingsum + sums.ford1frpr_modelSums['3P8S'].fn_eolsum + sums.ford1frpr_modelSums['3P8S'].fn_lqcsum + sums.ford1frpr_modelSums['3P8S'].fn_finalinspsum)/sums.ford1frpr_modelSums['3P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1frpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.ford1frdt_modelSums['3P8S'].time}}</td>     
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.ford1frpr_Sums.target }}</td>
          <td>{{ sums.ford1frpr_Sums.input }}</td>
          <td class="ok">{{ sums.ford1frpr_Sums.ok }}</td>         
          <td class="ng">{{ sums.ford1frpr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.ford1frpr_Sums.ng_lasermarkingsum }}</td>
          <td class="ng_type">{{ sums.ford1frpr_Sums.ng_middleinspsum }}</td>
          <td class="ng_type">{{ sums.ford1frpr_Sums.ng_aoingsum }}</td>
          <td class="ng_type">{{ sums.ford1frpr_Sums.ng_coatingngsum }}</td>
          <td class="ng_type">{{ sums.ford1frpr_Sums.ng_eolngsum }}</td>
          <td class="ng_type">{{ sums.ford1frpr_Sums.ng_lqcngsum }}</td>
          <td class="ng_type">{{ sums.ford1frpr_Sums.ng_finalinspngsum }}</td>          
          <td class="ng">{{ sums.ford1frpr_Sums.input === 0 ? 0 : (((sums.ford1frpr_Sums.ng) / (sums.ford1frpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1frpr_Sums.fn_lasermarkingsum + sums.ford1frpr_Sums.fn_middleinspsum + sums.ford1frpr_Sums.fn_aoingsum + sums.ford1frpr_Sums.fn_coatingsum + sums.ford1frpr_Sums.fn_eolsum + sums.ford1frpr_Sums.fn_lqcsum + sums.ford1frpr_Sums.fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_Sums.fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_Sums.fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_Sums.fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_Sums.fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_Sums.fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_Sums.fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1frpr_Sums.fn_finalinspsum }}</td>          
          <td class="fn">{{ sums.ford1frpr_Sums.input === 0 ? 0 : ((sums.ford1frpr_Sums.fn_lasermarkingsum + sums.ford1frpr_Sums.fn_middleinspsum + sums.ford1frpr_Sums.fn_aoingsum + sums.ford1frpr_Sums.fn_coatingsum + sums.ford1frpr_Sums.fn_eolsum + sums.ford1frpr_Sums.fn_lqcsum + sums.ford1frpr_Sums.fn_finalinspsum )/sums.ford1frpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.ford1frpr_Sums.work_minute}}</td>            
          <td>{{ sums.ford1frdt_Sums.time}}</td>         
        </tr>        
      </table>     
      <B>FORD #1 Rear</B>
      <table>
        <tr><th v-for="(header, index) in ford1fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>4P8S</td>          
          <td>{{ sums.ford1rrpr_modelSums['4P8S'].target }}</td>
          <td>{{ sums.ford1rrpr_modelSums['4P8S'].input }}</td>
          <td class="ok">{{ sums.ford1rrpr_modelSums['4P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford1rrpr_modelSums['4P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1rrpr_modelSums['4P8S'].input === 0 ? 0 : (((sums.ford1rrpr_modelSums['4P8S'].ng) / (sums.ford1rrpr_modelSums['4P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1rrpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['4P8S'].fn_middleinspsum + sums.ford1rrpr_modelSums['4P8S'].fn_aoingsum + sums.ford1rrpr_modelSums['4P8S'].fn_coatingsum + sums.ford1rrpr_modelSums['4P8S'].fn_eolsum + sums.ford1rrpr_modelSums['4P8S'].fn_lqcsum + sums.ford1rrpr_modelSums['4P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1rrpr_modelSums['4P8S'].input === 0 ? 0 : ((sums.ford1rrpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['4P8S'].fn_middleinspsum + sums.ford1rrpr_modelSums['4P8S'].fn_aoingsum + sums.ford1rrpr_modelSums['4P8S'].fn_coatingsum + sums.ford1rrpr_modelSums['4P8S'].fn_eolsum + sums.ford1rrpr_modelSums['4P8S'].fn_lqcsum + sums.ford1rrpr_modelSums['4P8S'].fn_finalinspsum)/sums.ford1rrpr_modelSums['4P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1rrpr_modelSums['4P8S'].work_minute}}</td>            
          <td>{{ sums.ford1rrdt_modelSums['4P8S'].time}}</td>   
        </tr>
        <tr>
          <td>4P7S</td>          
          <td>{{ sums.ford1rrpr_modelSums['4P7S'].target }}</td>
          <td>{{ sums.ford1rrpr_modelSums['4P7S'].input }}</td>
          <td class="ok">{{ sums.ford1rrpr_modelSums['4P7S'].ok}}</td>         
          <td class="ng">{{ sums.ford1rrpr_modelSums['4P7S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P7S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P7S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P7S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P7S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P7S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['4P7S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1rrpr_modelSums['4P7S'].input === 0 ? 0 : (((sums.ford1rrpr_modelSums['4P7S'].ng) / (sums.ford1rrpr_modelSums['4P7S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1rrpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['4P7S'].fn_middleinspsum + sums.ford1rrpr_modelSums['4P7S'].fn_aoingsum + sums.ford1rrpr_modelSums['4P7S'].fn_coatingsum + sums.ford1rrpr_modelSums['4P7S'].fn_eolsum + sums.ford1rrpr_modelSums['4P7S'].fn_lqcsum + sums.ford1rrpr_modelSums['4P7S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P7S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P7S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P7S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P7S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P7S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P7S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['4P7S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1rrpr_modelSums['4P7S'].input === 0 ? 0 : ((sums.ford1rrpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['4P7S'].fn_middleinspsum + sums.ford1rrpr_modelSums['4P7S'].fn_aoingsum + sums.ford1rrpr_modelSums['4P7S'].fn_coatingsum + sums.ford1rrpr_modelSums['4P7S'].fn_eolsum + sums.ford1rrpr_modelSums['4P7S'].fn_lqcsum + sums.ford1rrpr_modelSums['4P7S'].fn_finalinspsum)/sums.ford1rrpr_modelSums['4P7S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1rrpr_modelSums['4P7S'].work_minute}}</td>            
          <td>{{ sums.ford1rrdt_modelSums['4P7S'].time}}</td> 
        </tr>
        <tr>
          <td>3P10S</td>          
          <td>{{ sums.ford1rrpr_modelSums['3P10S'].target }}</td>
          <td>{{ sums.ford1rrpr_modelSums['3P10S'].input }}</td>
          <td class="ok">{{ sums.ford1rrpr_modelSums['3P10S'].ok}}</td>         
          <td class="ng">{{ sums.ford1rrpr_modelSums['3P10S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P10S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P10S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P10S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P10S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P10S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P10S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1rrpr_modelSums['3P10S'].input === 0 ? 0 : (((sums.ford1rrpr_modelSums['3P10S'].ng) / (sums.ford1rrpr_modelSums['3P10S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1rrpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['3P10S'].fn_middleinspsum + sums.ford1rrpr_modelSums['3P10S'].fn_aoingsum + sums.ford1rrpr_modelSums['3P10S'].fn_coatingsum + sums.ford1rrpr_modelSums['3P10S'].fn_eolsum + sums.ford1rrpr_modelSums['3P10S'].fn_lqcsum + sums.ford1rrpr_modelSums['3P10S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P10S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P10S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P10S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P10S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P10S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P10S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P10S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1rrpr_modelSums['3P10S'].input === 0 ? 0 : ((sums.ford1rrpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['3P10S'].fn_middleinspsum + sums.ford1rrpr_modelSums['3P10S'].fn_aoingsum + sums.ford1rrpr_modelSums['3P10S'].fn_coatingsum + sums.ford1rrpr_modelSums['3P10S'].fn_eolsum + sums.ford1rrpr_modelSums['3P10S'].fn_lqcsum + sums.ford1rrpr_modelSums['3P10S'].fn_finalinspsum)/sums.ford1rrpr_modelSums['3P10S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1rrpr_modelSums['3P10S'].work_minute}}</td>            
          <td>{{ sums.ford1rrdt_modelSums['3P10S'].time}}</td>     
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.ford1rrpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.ford1rrpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.ford1rrpr_modelSums['3P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford1rrpr_modelSums['3P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford1rrpr_modelSums['3P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford1rrpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.ford1rrpr_modelSums['3P8S'].ng) / (sums.ford1rrpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1rrpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['3P8S'].fn_middleinspsum + sums.ford1rrpr_modelSums['3P8S'].fn_aoingsum + sums.ford1rrpr_modelSums['3P8S'].fn_coatingsum + sums.ford1rrpr_modelSums['3P8S'].fn_eolsum + sums.ford1rrpr_modelSums['3P8S'].fn_lqcsum + sums.ford1rrpr_modelSums['3P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_modelSums['3P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford1rrpr_modelSums['3P8S'].input === 0 ? 0 : ((sums.ford1rrpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford1rrpr_modelSums['3P8S'].fn_middleinspsum + sums.ford1rrpr_modelSums['3P8S'].fn_aoingsum + sums.ford1rrpr_modelSums['3P8S'].fn_coatingsum + sums.ford1rrpr_modelSums['3P8S'].fn_eolsum + sums.ford1rrpr_modelSums['3P8S'].fn_lqcsum + sums.ford1rrpr_modelSums['3P8S'].fn_finalinspsum)/sums.ford1rrpr_modelSums['3P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford1rrpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.ford1rrdt_modelSums['3P8S'].time}}</td>     
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.ford1rrpr_Sums.target }}</td>
          <td>{{ sums.ford1rrpr_Sums.input }}</td>
          <td class="ok">{{ sums.ford1rrpr_Sums.ok }}</td>         
          <td class="ng">{{ sums.ford1rrpr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.ford1rrpr_Sums.ng_lasermarkingsum }}</td>
          <td class="ng_type">{{ sums.ford1rrpr_Sums.ng_middleinspsum }}</td>
          <td class="ng_type">{{ sums.ford1rrpr_Sums.ng_aoingsum }}</td>
          <td class="ng_type">{{ sums.ford1rrpr_Sums.ng_coatingngsum }}</td>
          <td class="ng_type">{{ sums.ford1rrpr_Sums.ng_eolngsum }}</td>
          <td class="ng_type">{{ sums.ford1rrpr_Sums.ng_lqcngsum }}</td>
          <td class="ng_type">{{ sums.ford1rrpr_Sums.ng_finalinspngsum }}</td>          
          <td class="ng">{{ sums.ford1rrpr_Sums.input === 0 ? 0 : (((sums.ford1rrpr_Sums.ng) / (sums.ford1rrpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford1rrpr_Sums.fn_lasermarkingsum + sums.ford1rrpr_Sums.fn_middleinspsum + sums.ford1rrpr_Sums.fn_aoingsum + sums.ford1rrpr_Sums.fn_coatingsum + sums.ford1rrpr_Sums.fn_eolsum + sums.ford1rrpr_Sums.fn_lqcsum + sums.ford1rrpr_Sums.fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_Sums.fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_Sums.fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_Sums.fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_Sums.fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_Sums.fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_Sums.fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford1rrpr_Sums.fn_finalinspsum }}</td>          
          <td class="fn">{{ sums.ford1rrpr_Sums.input === 0 ? 0 : ((sums.ford1rrpr_Sums.fn_lasermarkingsum + sums.ford1rrpr_Sums.fn_middleinspsum + sums.ford1rrpr_Sums.fn_aoingsum + sums.ford1rrpr_Sums.fn_coatingsum + sums.ford1rrpr_Sums.fn_eolsum + sums.ford1rrpr_Sums.fn_lqcsum + sums.ford1rrpr_Sums.fn_finalinspsum )/sums.ford1rrpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.ford1rrpr_Sums.work_minute}}</td>            
          <td>{{ sums.ford1rrdt_Sums.time}}</td>         
        </tr>        
      </table>     
      <B>FORD #2 Front</B>
      <table>
        <tr><th v-for="(header, index) in ford2fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>4P8S</td>          
          <td>{{ sums.ford2frpr_modelSums['4P8S'].target }}</td>
          <td>{{ sums.ford2frpr_modelSums['4P8S'].input }}</td>
          <td class="ok">{{ sums.ford2frpr_modelSums['4P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford2frpr_modelSums['4P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2frpr_modelSums['4P8S'].input === 0 ? 0 : (((sums.ford2frpr_modelSums['4P8S'].ng) / (sums.ford2frpr_modelSums['4P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2frpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['4P8S'].fn_middleinspsum + sums.ford2frpr_modelSums['4P8S'].fn_aoingsum + sums.ford2frpr_modelSums['4P8S'].fn_coatingsum + sums.ford2frpr_modelSums['4P8S'].fn_eolsum + sums.ford2frpr_modelSums['4P8S'].fn_lqcsum + sums.ford2frpr_modelSums['4P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2frpr_modelSums['4P8S'].input === 0 ? 0 : ((sums.ford2frpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['4P8S'].fn_middleinspsum + sums.ford2frpr_modelSums['4P8S'].fn_aoingsum + sums.ford2frpr_modelSums['4P8S'].fn_coatingsum + sums.ford2frpr_modelSums['4P8S'].fn_eolsum + sums.ford2frpr_modelSums['4P8S'].fn_lqcsum + sums.ford2frpr_modelSums['4P8S'].fn_finalinspsum)/sums.ford2frpr_modelSums['4P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2frpr_modelSums['4P8S'].work_minute}}</td>            
          <td>{{ sums.ford2frdt_modelSums['4P8S'].time}}</td>   
        </tr>
        <tr>
          <td>4P7S</td>          
          <td>{{ sums.ford2frpr_modelSums['4P7S'].target }}</td>
          <td>{{ sums.ford2frpr_modelSums['4P7S'].input }}</td>
          <td class="ok">{{ sums.ford2frpr_modelSums['4P7S'].ok}}</td>         
          <td class="ng">{{ sums.ford2frpr_modelSums['4P7S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P7S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P7S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P7S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P7S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P7S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['4P7S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2frpr_modelSums['4P7S'].input === 0 ? 0 : (((sums.ford2frpr_modelSums['4P7S'].ng) / (sums.ford2frpr_modelSums['4P7S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2frpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['4P7S'].fn_middleinspsum + sums.ford2frpr_modelSums['4P7S'].fn_aoingsum + sums.ford2frpr_modelSums['4P7S'].fn_coatingsum + sums.ford2frpr_modelSums['4P7S'].fn_eolsum + sums.ford2frpr_modelSums['4P7S'].fn_lqcsum + sums.ford2frpr_modelSums['4P7S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P7S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P7S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P7S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P7S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P7S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P7S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['4P7S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2frpr_modelSums['4P7S'].input === 0 ? 0 : ((sums.ford2frpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['4P7S'].fn_middleinspsum + sums.ford2frpr_modelSums['4P7S'].fn_aoingsum + sums.ford2frpr_modelSums['4P7S'].fn_coatingsum + sums.ford2frpr_modelSums['4P7S'].fn_eolsum + sums.ford2frpr_modelSums['4P7S'].fn_lqcsum + sums.ford2frpr_modelSums['4P7S'].fn_finalinspsum)/sums.ford2frpr_modelSums['4P7S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2frpr_modelSums['4P7S'].work_minute}}</td>            
          <td>{{ sums.ford2frdt_modelSums['4P7S'].time}}</td> 
        </tr>
        <tr>
          <td>3P10S</td>          
          <td>{{ sums.ford2frpr_modelSums['3P10S'].target }}</td>
          <td>{{ sums.ford2frpr_modelSums['3P10S'].input }}</td>
          <td class="ok">{{ sums.ford2frpr_modelSums['3P10S'].ok}}</td>         
          <td class="ng">{{ sums.ford2frpr_modelSums['3P10S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P10S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P10S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P10S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P10S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P10S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P10S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2frpr_modelSums['3P10S'].input === 0 ? 0 : (((sums.ford2frpr_modelSums['3P10S'].ng) / (sums.ford2frpr_modelSums['3P10S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2frpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['3P10S'].fn_middleinspsum + sums.ford2frpr_modelSums['3P10S'].fn_aoingsum + sums.ford2frpr_modelSums['3P10S'].fn_coatingsum + sums.ford2frpr_modelSums['3P10S'].fn_eolsum + sums.ford2frpr_modelSums['3P10S'].fn_lqcsum + sums.ford2frpr_modelSums['3P10S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P10S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P10S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P10S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P10S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P10S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P10S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P10S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2frpr_modelSums['3P10S'].input === 0 ? 0 : ((sums.ford2frpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['3P10S'].fn_middleinspsum + sums.ford2frpr_modelSums['3P10S'].fn_aoingsum + sums.ford2frpr_modelSums['3P10S'].fn_coatingsum + sums.ford2frpr_modelSums['3P10S'].fn_eolsum + sums.ford2frpr_modelSums['3P10S'].fn_lqcsum + sums.ford2frpr_modelSums['3P10S'].fn_finalinspsum)/sums.ford2frpr_modelSums['3P10S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2frpr_modelSums['3P10S'].work_minute}}</td>            
          <td>{{ sums.ford2frdt_modelSums['3P10S'].time}}</td>     
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.ford2frpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.ford2frpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.ford2frpr_modelSums['3P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford2frpr_modelSums['3P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2frpr_modelSums['3P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2frpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.ford2frpr_modelSums['3P8S'].ng) / (sums.ford2frpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2frpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['3P8S'].fn_middleinspsum + sums.ford2frpr_modelSums['3P8S'].fn_aoingsum + sums.ford2frpr_modelSums['3P8S'].fn_coatingsum + sums.ford2frpr_modelSums['3P8S'].fn_eolsum + sums.ford2frpr_modelSums['3P8S'].fn_lqcsum + sums.ford2frpr_modelSums['3P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_modelSums['3P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2frpr_modelSums['3P8S'].input === 0 ? 0 : ((sums.ford2frpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford2frpr_modelSums['3P8S'].fn_middleinspsum + sums.ford2frpr_modelSums['3P8S'].fn_aoingsum + sums.ford2frpr_modelSums['3P8S'].fn_coatingsum + sums.ford2frpr_modelSums['3P8S'].fn_eolsum + sums.ford2frpr_modelSums['3P8S'].fn_lqcsum + sums.ford2frpr_modelSums['3P8S'].fn_finalinspsum)/sums.ford2frpr_modelSums['3P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2frpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.ford2frdt_modelSums['3P8S'].time}}</td>     
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.ford2frpr_Sums.target }}</td>
          <td>{{ sums.ford2frpr_Sums.input }}</td>
          <td class="ok">{{ sums.ford2frpr_Sums.ok }}</td>         
          <td class="ng">{{ sums.ford2frpr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.ford2frpr_Sums.ng_lasermarkingsum }}</td>
          <td class="ng_type">{{ sums.ford2frpr_Sums.ng_middleinspsum }}</td>
          <td class="ng_type">{{ sums.ford2frpr_Sums.ng_aoingsum }}</td>
          <td class="ng_type">{{ sums.ford2frpr_Sums.ng_coatingngsum }}</td>
          <td class="ng_type">{{ sums.ford2frpr_Sums.ng_eolngsum }}</td>
          <td class="ng_type">{{ sums.ford2frpr_Sums.ng_lqcngsum }}</td>
          <td class="ng_type">{{ sums.ford2frpr_Sums.ng_finalinspngsum }}</td>          
          <td class="ng">{{ sums.ford2frpr_Sums.input === 0 ? 0 : (((sums.ford2frpr_Sums.ng) / (sums.ford2frpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2frpr_Sums.fn_lasermarkingsum + sums.ford2frpr_Sums.fn_middleinspsum + sums.ford2frpr_Sums.fn_aoingsum + sums.ford2frpr_Sums.fn_coatingsum + sums.ford2frpr_Sums.fn_eolsum + sums.ford2frpr_Sums.fn_lqcsum + sums.ford2frpr_Sums.fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_Sums.fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_Sums.fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_Sums.fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_Sums.fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_Sums.fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_Sums.fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2frpr_Sums.fn_finalinspsum }}</td>          
          <td class="fn">{{ sums.ford2frpr_Sums.input === 0 ? 0 : ((sums.ford2frpr_Sums.fn_lasermarkingsum + sums.ford2frpr_Sums.fn_middleinspsum + sums.ford2frpr_Sums.fn_aoingsum + sums.ford2frpr_Sums.fn_coatingsum + sums.ford2frpr_Sums.fn_eolsum + sums.ford2frpr_Sums.fn_lqcsum + sums.ford2frpr_Sums.fn_finalinspsum )/sums.ford2frpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.ford2frpr_Sums.work_minute}}</td>            
          <td>{{ sums.ford2frdt_Sums.time}}</td>         
        </tr>        
      </table>       
      <B>FORD #2 Rear</B>
      <table>
        <tr><th v-for="(header, index) in ford2fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>4P8S</td>          
          <td>{{ sums.ford2rrpr_modelSums['4P8S'].target }}</td>
          <td>{{ sums.ford2rrpr_modelSums['4P8S'].input }}</td>
          <td class="ok">{{ sums.ford2rrpr_modelSums['4P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford2rrpr_modelSums['4P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2rrpr_modelSums['4P8S'].input === 0 ? 0 : (((sums.ford2rrpr_modelSums['4P8S'].ng) / (sums.ford2rrpr_modelSums['4P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2rrpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['4P8S'].fn_middleinspsum + sums.ford2rrpr_modelSums['4P8S'].fn_aoingsum + sums.ford2rrpr_modelSums['4P8S'].fn_coatingsum + sums.ford2rrpr_modelSums['4P8S'].fn_eolsum + sums.ford2rrpr_modelSums['4P8S'].fn_lqcsum + sums.ford2rrpr_modelSums['4P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2rrpr_modelSums['4P8S'].input === 0 ? 0 : ((sums.ford2rrpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['4P8S'].fn_middleinspsum + sums.ford2rrpr_modelSums['4P8S'].fn_aoingsum + sums.ford2rrpr_modelSums['4P8S'].fn_coatingsum + sums.ford2rrpr_modelSums['4P8S'].fn_eolsum + sums.ford2rrpr_modelSums['4P8S'].fn_lqcsum + sums.ford2rrpr_modelSums['4P8S'].fn_finalinspsum)/sums.ford2rrpr_modelSums['4P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2rrpr_modelSums['4P8S'].work_minute}}</td>            
          <td>{{ sums.ford2rrdt_modelSums['4P8S'].time}}</td>   
        </tr>
        <tr>
          <td>4P7S</td>          
          <td>{{ sums.ford2rrpr_modelSums['4P7S'].target }}</td>
          <td>{{ sums.ford2rrpr_modelSums['4P7S'].input }}</td>
          <td class="ok">{{ sums.ford2rrpr_modelSums['4P7S'].ok}}</td>         
          <td class="ng">{{ sums.ford2rrpr_modelSums['4P7S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P7S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P7S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P7S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P7S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P7S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['4P7S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2rrpr_modelSums['4P7S'].input === 0 ? 0 : (((sums.ford2rrpr_modelSums['4P7S'].ng) / (sums.ford2rrpr_modelSums['4P7S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2rrpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['4P7S'].fn_middleinspsum + sums.ford2rrpr_modelSums['4P7S'].fn_aoingsum + sums.ford2rrpr_modelSums['4P7S'].fn_coatingsum + sums.ford2rrpr_modelSums['4P7S'].fn_eolsum + sums.ford2rrpr_modelSums['4P7S'].fn_lqcsum + sums.ford2rrpr_modelSums['4P7S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P7S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P7S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P7S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P7S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P7S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P7S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['4P7S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2rrpr_modelSums['4P7S'].input === 0 ? 0 : ((sums.ford2rrpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['4P7S'].fn_middleinspsum + sums.ford2rrpr_modelSums['4P7S'].fn_aoingsum + sums.ford2rrpr_modelSums['4P7S'].fn_coatingsum + sums.ford2rrpr_modelSums['4P7S'].fn_eolsum + sums.ford2rrpr_modelSums['4P7S'].fn_lqcsum + sums.ford2rrpr_modelSums['4P7S'].fn_finalinspsum)/sums.ford2rrpr_modelSums['4P7S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2rrpr_modelSums['4P7S'].work_minute}}</td>            
          <td>{{ sums.ford2rrdt_modelSums['4P7S'].time}}</td> 
        </tr>
        <tr>
          <td>3P10S</td>          
          <td>{{ sums.ford2rrpr_modelSums['3P10S'].target }}</td>
          <td>{{ sums.ford2rrpr_modelSums['3P10S'].input }}</td>
          <td class="ok">{{ sums.ford2rrpr_modelSums['3P10S'].ok}}</td>         
          <td class="ng">{{ sums.ford2rrpr_modelSums['3P10S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P10S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P10S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P10S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P10S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P10S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P10S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2rrpr_modelSums['3P10S'].input === 0 ? 0 : (((sums.ford2rrpr_modelSums['3P10S'].ng) / (sums.ford2rrpr_modelSums['3P10S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2rrpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['3P10S'].fn_middleinspsum + sums.ford2rrpr_modelSums['3P10S'].fn_aoingsum + sums.ford2rrpr_modelSums['3P10S'].fn_coatingsum + sums.ford2rrpr_modelSums['3P10S'].fn_eolsum + sums.ford2rrpr_modelSums['3P10S'].fn_lqcsum + sums.ford2rrpr_modelSums['3P10S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P10S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P10S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P10S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P10S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P10S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P10S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P10S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2rrpr_modelSums['3P10S'].input === 0 ? 0 : ((sums.ford2rrpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['3P10S'].fn_middleinspsum + sums.ford2rrpr_modelSums['3P10S'].fn_aoingsum + sums.ford2rrpr_modelSums['3P10S'].fn_coatingsum + sums.ford2rrpr_modelSums['3P10S'].fn_eolsum + sums.ford2rrpr_modelSums['3P10S'].fn_lqcsum + sums.ford2rrpr_modelSums['3P10S'].fn_finalinspsum)/sums.ford2rrpr_modelSums['3P10S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2rrpr_modelSums['3P10S'].work_minute}}</td>            
          <td>{{ sums.ford2rrdt_modelSums['3P10S'].time}}</td>     
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.ford2rrpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.ford2rrpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.ford2rrpr_modelSums['3P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford2rrpr_modelSums['3P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford2rrpr_modelSums['3P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford2rrpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.ford2rrpr_modelSums['3P8S'].ng) / (sums.ford2rrpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2rrpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['3P8S'].fn_middleinspsum + sums.ford2rrpr_modelSums['3P8S'].fn_aoingsum + sums.ford2rrpr_modelSums['3P8S'].fn_coatingsum + sums.ford2rrpr_modelSums['3P8S'].fn_eolsum + sums.ford2rrpr_modelSums['3P8S'].fn_lqcsum + sums.ford2rrpr_modelSums['3P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_modelSums['3P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford2rrpr_modelSums['3P8S'].input === 0 ? 0 : ((sums.ford2rrpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford2rrpr_modelSums['3P8S'].fn_middleinspsum + sums.ford2rrpr_modelSums['3P8S'].fn_aoingsum + sums.ford2rrpr_modelSums['3P8S'].fn_coatingsum + sums.ford2rrpr_modelSums['3P8S'].fn_eolsum + sums.ford2rrpr_modelSums['3P8S'].fn_lqcsum + sums.ford2rrpr_modelSums['3P8S'].fn_finalinspsum)/sums.ford2rrpr_modelSums['3P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford2rrpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.ford2rrdt_modelSums['3P8S'].time}}</td>     
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.ford2rrpr_Sums.target }}</td>
          <td>{{ sums.ford2rrpr_Sums.input }}</td>
          <td class="ok">{{ sums.ford2rrpr_Sums.ok }}</td>         
          <td class="ng">{{ sums.ford2rrpr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.ford2rrpr_Sums.ng_lasermarkingsum }}</td>
          <td class="ng_type">{{ sums.ford2rrpr_Sums.ng_middleinspsum }}</td>
          <td class="ng_type">{{ sums.ford2rrpr_Sums.ng_aoingsum }}</td>
          <td class="ng_type">{{ sums.ford2rrpr_Sums.ng_coatingngsum }}</td>
          <td class="ng_type">{{ sums.ford2rrpr_Sums.ng_eolngsum }}</td>
          <td class="ng_type">{{ sums.ford2rrpr_Sums.ng_lqcngsum }}</td>
          <td class="ng_type">{{ sums.ford2rrpr_Sums.ng_finalinspngsum }}</td>          
          <td class="ng">{{ sums.ford2rrpr_Sums.input === 0 ? 0 : (((sums.ford2rrpr_Sums.ng) / (sums.ford2rrpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford2rrpr_Sums.fn_lasermarkingsum + sums.ford2rrpr_Sums.fn_middleinspsum + sums.ford2rrpr_Sums.fn_aoingsum + sums.ford2rrpr_Sums.fn_coatingsum + sums.ford2rrpr_Sums.fn_eolsum + sums.ford2rrpr_Sums.fn_lqcsum + sums.ford2rrpr_Sums.fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_Sums.fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_Sums.fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_Sums.fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_Sums.fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_Sums.fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_Sums.fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford2rrpr_Sums.fn_finalinspsum }}</td>          
          <td class="fn">{{ sums.ford2rrpr_Sums.input === 0 ? 0 : ((sums.ford2rrpr_Sums.fn_lasermarkingsum + sums.ford2rrpr_Sums.fn_middleinspsum + sums.ford2rrpr_Sums.fn_aoingsum + sums.ford2rrpr_Sums.fn_coatingsum + sums.ford2rrpr_Sums.fn_eolsum + sums.ford2rrpr_Sums.fn_lqcsum + sums.ford2rrpr_Sums.fn_finalinspsum )/sums.ford2rrpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.ford2rrpr_Sums.work_minute}}</td>            
          <td>{{ sums.ford2rrdt_Sums.time}}</td>         
        </tr>        
      </table>     
      <B>FORD #3 Front</B>
      <table>
        <tr><th v-for="(header, index) in ford3fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>4P8S</td>          
          <td>{{ sums.ford3frpr_modelSums['4P8S'].target }}</td>
          <td>{{ sums.ford3frpr_modelSums['4P8S'].input }}</td>
          <td class="ok">{{ sums.ford3frpr_modelSums['4P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford3frpr_modelSums['4P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3frpr_modelSums['4P8S'].input === 0 ? 0 : (((sums.ford3frpr_modelSums['4P8S'].ng) / (sums.ford3frpr_modelSums['4P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3frpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['4P8S'].fn_middleinspsum + sums.ford3frpr_modelSums['4P8S'].fn_aoingsum + sums.ford3frpr_modelSums['4P8S'].fn_coatingsum + sums.ford3frpr_modelSums['4P8S'].fn_eolsum + sums.ford3frpr_modelSums['4P8S'].fn_lqcsum + sums.ford3frpr_modelSums['4P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3frpr_modelSums['4P8S'].input === 0 ? 0 : ((sums.ford3frpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['4P8S'].fn_middleinspsum + sums.ford3frpr_modelSums['4P8S'].fn_aoingsum + sums.ford3frpr_modelSums['4P8S'].fn_coatingsum + sums.ford3frpr_modelSums['4P8S'].fn_eolsum + sums.ford3frpr_modelSums['4P8S'].fn_lqcsum + sums.ford3frpr_modelSums['4P8S'].fn_finalinspsum)/sums.ford3frpr_modelSums['4P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3frpr_modelSums['4P8S'].work_minute}}</td>            
          <td>{{ sums.ford3frdt_modelSums['4P8S'].time}}</td>   
        </tr>
        <tr>
          <td>4P7S</td>          
          <td>{{ sums.ford3frpr_modelSums['4P7S'].target }}</td>
          <td>{{ sums.ford3frpr_modelSums['4P7S'].input }}</td>
          <td class="ok">{{ sums.ford3frpr_modelSums['4P7S'].ok}}</td>         
          <td class="ng">{{ sums.ford3frpr_modelSums['4P7S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P7S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P7S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P7S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P7S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P7S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['4P7S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3frpr_modelSums['4P7S'].input === 0 ? 0 : (((sums.ford3frpr_modelSums['4P7S'].ng) / (sums.ford3frpr_modelSums['4P7S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3frpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['4P7S'].fn_middleinspsum + sums.ford3frpr_modelSums['4P7S'].fn_aoingsum + sums.ford3frpr_modelSums['4P7S'].fn_coatingsum + sums.ford3frpr_modelSums['4P7S'].fn_eolsum + sums.ford3frpr_modelSums['4P7S'].fn_lqcsum + sums.ford3frpr_modelSums['4P7S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P7S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P7S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P7S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P7S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P7S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P7S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['4P7S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3frpr_modelSums['4P7S'].input === 0 ? 0 : ((sums.ford3frpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['4P7S'].fn_middleinspsum + sums.ford3frpr_modelSums['4P7S'].fn_aoingsum + sums.ford3frpr_modelSums['4P7S'].fn_coatingsum + sums.ford3frpr_modelSums['4P7S'].fn_eolsum + sums.ford3frpr_modelSums['4P7S'].fn_lqcsum + sums.ford3frpr_modelSums['4P7S'].fn_finalinspsum)/sums.ford3frpr_modelSums['4P7S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3frpr_modelSums['4P7S'].work_minute}}</td>            
          <td>{{ sums.ford3frdt_modelSums['4P7S'].time}}</td> 
        </tr>
        <tr>
          <td>3P10S</td>          
          <td>{{ sums.ford3frpr_modelSums['3P10S'].target }}</td>
          <td>{{ sums.ford3frpr_modelSums['3P10S'].input }}</td>
          <td class="ok">{{ sums.ford3frpr_modelSums['3P10S'].ok}}</td>         
          <td class="ng">{{ sums.ford3frpr_modelSums['3P10S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P10S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P10S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P10S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P10S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P10S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P10S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3frpr_modelSums['3P10S'].input === 0 ? 0 : (((sums.ford3frpr_modelSums['3P10S'].ng) / (sums.ford3frpr_modelSums['3P10S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3frpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['3P10S'].fn_middleinspsum + sums.ford3frpr_modelSums['3P10S'].fn_aoingsum + sums.ford3frpr_modelSums['3P10S'].fn_coatingsum + sums.ford3frpr_modelSums['3P10S'].fn_eolsum + sums.ford3frpr_modelSums['3P10S'].fn_lqcsum + sums.ford3frpr_modelSums['3P10S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P10S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P10S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P10S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P10S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P10S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P10S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P10S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3frpr_modelSums['3P10S'].input === 0 ? 0 : ((sums.ford3frpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['3P10S'].fn_middleinspsum + sums.ford3frpr_modelSums['3P10S'].fn_aoingsum + sums.ford3frpr_modelSums['3P10S'].fn_coatingsum + sums.ford3frpr_modelSums['3P10S'].fn_eolsum + sums.ford3frpr_modelSums['3P10S'].fn_lqcsum + sums.ford3frpr_modelSums['3P10S'].fn_finalinspsum)/sums.ford3frpr_modelSums['3P10S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3frpr_modelSums['3P10S'].work_minute}}</td>            
          <td>{{ sums.ford3frdt_modelSums['3P10S'].time}}</td>     
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.ford3frpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.ford3frpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.ford3frpr_modelSums['3P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford3frpr_modelSums['3P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3frpr_modelSums['3P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3frpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.ford3frpr_modelSums['3P8S'].ng) / (sums.ford3frpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3frpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['3P8S'].fn_middleinspsum + sums.ford3frpr_modelSums['3P8S'].fn_aoingsum + sums.ford3frpr_modelSums['3P8S'].fn_coatingsum + sums.ford3frpr_modelSums['3P8S'].fn_eolsum + sums.ford3frpr_modelSums['3P8S'].fn_lqcsum + sums.ford3frpr_modelSums['3P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_modelSums['3P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3frpr_modelSums['3P8S'].input === 0 ? 0 : ((sums.ford3frpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford3frpr_modelSums['3P8S'].fn_middleinspsum + sums.ford3frpr_modelSums['3P8S'].fn_aoingsum + sums.ford3frpr_modelSums['3P8S'].fn_coatingsum + sums.ford3frpr_modelSums['3P8S'].fn_eolsum + sums.ford3frpr_modelSums['3P8S'].fn_lqcsum + sums.ford3frpr_modelSums['3P8S'].fn_finalinspsum)/sums.ford3frpr_modelSums['3P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3frpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.ford3frdt_modelSums['3P8S'].time}}</td>     
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.ford3frpr_Sums.target }}</td>
          <td>{{ sums.ford3frpr_Sums.input }}</td>
          <td class="ok">{{ sums.ford3frpr_Sums.ok }}</td>         
          <td class="ng">{{ sums.ford3frpr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.ford3frpr_Sums.ng_lasermarkingsum }}</td>
          <td class="ng_type">{{ sums.ford3frpr_Sums.ng_middleinspsum }}</td>
          <td class="ng_type">{{ sums.ford3frpr_Sums.ng_aoingsum }}</td>
          <td class="ng_type">{{ sums.ford3frpr_Sums.ng_coatingngsum }}</td>
          <td class="ng_type">{{ sums.ford3frpr_Sums.ng_eolngsum }}</td>
          <td class="ng_type">{{ sums.ford3frpr_Sums.ng_lqcngsum }}</td>
          <td class="ng_type">{{ sums.ford3frpr_Sums.ng_finalinspngsum }}</td>          
          <td class="ng">{{ sums.ford3frpr_Sums.input === 0 ? 0 : (((sums.ford3frpr_Sums.ng) / (sums.ford3frpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3frpr_Sums.fn_lasermarkingsum + sums.ford3frpr_Sums.fn_middleinspsum + sums.ford3frpr_Sums.fn_aoingsum + sums.ford3frpr_Sums.fn_coatingsum + sums.ford3frpr_Sums.fn_eolsum + sums.ford3frpr_Sums.fn_lqcsum + sums.ford3frpr_Sums.fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_Sums.fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_Sums.fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_Sums.fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_Sums.fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_Sums.fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_Sums.fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3frpr_Sums.fn_finalinspsum }}</td>          
          <td class="fn">{{ sums.ford3frpr_Sums.input === 0 ? 0 : ((sums.ford3frpr_Sums.fn_lasermarkingsum + sums.ford3frpr_Sums.fn_middleinspsum + sums.ford3frpr_Sums.fn_aoingsum + sums.ford3frpr_Sums.fn_coatingsum + sums.ford3frpr_Sums.fn_eolsum + sums.ford3frpr_Sums.fn_lqcsum + sums.ford3frpr_Sums.fn_finalinspsum )/sums.ford3frpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.ford3frpr_Sums.work_minute}}</td>            
          <td>{{ sums.ford3frdt_Sums.time}}</td>         
        </tr>        
      </table>        
      <B>FORD #3 Rear</B>
      <table>
        <tr><th v-for="(header, index) in ford3fr_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>4P8S</td>          
          <td>{{ sums.ford3rrpr_modelSums['4P8S'].target }}</td>
          <td>{{ sums.ford3rrpr_modelSums['4P8S'].input }}</td>
          <td class="ok">{{ sums.ford3rrpr_modelSums['4P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford3rrpr_modelSums['4P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3rrpr_modelSums['4P8S'].input === 0 ? 0 : (((sums.ford3rrpr_modelSums['4P8S'].ng) / (sums.ford3rrpr_modelSums['4P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3rrpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['4P8S'].fn_middleinspsum + sums.ford3rrpr_modelSums['4P8S'].fn_aoingsum + sums.ford3rrpr_modelSums['4P8S'].fn_coatingsum + sums.ford3rrpr_modelSums['4P8S'].fn_eolsum + sums.ford3rrpr_modelSums['4P8S'].fn_lqcsum + sums.ford3rrpr_modelSums['4P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3rrpr_modelSums['4P8S'].input === 0 ? 0 : ((sums.ford3rrpr_modelSums['4P8S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['4P8S'].fn_middleinspsum + sums.ford3rrpr_modelSums['4P8S'].fn_aoingsum + sums.ford3rrpr_modelSums['4P8S'].fn_coatingsum + sums.ford3rrpr_modelSums['4P8S'].fn_eolsum + sums.ford3rrpr_modelSums['4P8S'].fn_lqcsum + sums.ford3rrpr_modelSums['4P8S'].fn_finalinspsum)/sums.ford3rrpr_modelSums['4P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3rrpr_modelSums['4P8S'].work_minute}}</td>            
          <td>{{ sums.ford3rrdt_modelSums['4P8S'].time}}</td>   
        </tr>
        <tr>
          <td>4P7S</td>          
          <td>{{ sums.ford3rrpr_modelSums['4P7S'].target }}</td>
          <td>{{ sums.ford3rrpr_modelSums['4P7S'].input }}</td>
          <td class="ok">{{ sums.ford3rrpr_modelSums['4P7S'].ok}}</td>         
          <td class="ng">{{ sums.ford3rrpr_modelSums['4P7S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P7S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P7S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P7S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P7S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P7S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P7S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['4P7S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3rrpr_modelSums['4P7S'].input === 0 ? 0 : (((sums.ford3rrpr_modelSums['4P7S'].ng) / (sums.ford3rrpr_modelSums['4P7S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3rrpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['4P7S'].fn_middleinspsum + sums.ford3rrpr_modelSums['4P7S'].fn_aoingsum + sums.ford3rrpr_modelSums['4P7S'].fn_coatingsum + sums.ford3rrpr_modelSums['4P7S'].fn_eolsum + sums.ford3rrpr_modelSums['4P7S'].fn_lqcsum + sums.ford3rrpr_modelSums['4P7S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P7S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P7S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P7S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P7S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P7S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P7S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['4P7S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3rrpr_modelSums['4P7S'].input === 0 ? 0 : ((sums.ford3rrpr_modelSums['4P7S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['4P7S'].fn_middleinspsum + sums.ford3rrpr_modelSums['4P7S'].fn_aoingsum + sums.ford3rrpr_modelSums['4P7S'].fn_coatingsum + sums.ford3rrpr_modelSums['4P7S'].fn_eolsum + sums.ford3rrpr_modelSums['4P7S'].fn_lqcsum + sums.ford3rrpr_modelSums['4P7S'].fn_finalinspsum)/sums.ford3rrpr_modelSums['4P7S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3rrpr_modelSums['4P7S'].work_minute}}</td>            
          <td>{{ sums.ford3rrdt_modelSums['4P7S'].time}}</td> 
        </tr>
        <tr>
          <td>3P10S</td>          
          <td>{{ sums.ford3rrpr_modelSums['3P10S'].target }}</td>
          <td>{{ sums.ford3rrpr_modelSums['3P10S'].input }}</td>
          <td class="ok">{{ sums.ford3rrpr_modelSums['3P10S'].ok}}</td>         
          <td class="ng">{{ sums.ford3rrpr_modelSums['3P10S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P10S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P10S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P10S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P10S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P10S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P10S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P10S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3rrpr_modelSums['3P10S'].input === 0 ? 0 : (((sums.ford3rrpr_modelSums['3P10S'].ng) / (sums.ford3rrpr_modelSums['3P10S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3rrpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['3P10S'].fn_middleinspsum + sums.ford3rrpr_modelSums['3P10S'].fn_aoingsum + sums.ford3rrpr_modelSums['3P10S'].fn_coatingsum + sums.ford3rrpr_modelSums['3P10S'].fn_eolsum + sums.ford3rrpr_modelSums['3P10S'].fn_lqcsum + sums.ford3rrpr_modelSums['3P10S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P10S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P10S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P10S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P10S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P10S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P10S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P10S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3rrpr_modelSums['3P10S'].input === 0 ? 0 : ((sums.ford3rrpr_modelSums['3P10S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['3P10S'].fn_middleinspsum + sums.ford3rrpr_modelSums['3P10S'].fn_aoingsum + sums.ford3rrpr_modelSums['3P10S'].fn_coatingsum + sums.ford3rrpr_modelSums['3P10S'].fn_eolsum + sums.ford3rrpr_modelSums['3P10S'].fn_lqcsum + sums.ford3rrpr_modelSums['3P10S'].fn_finalinspsum)/sums.ford3rrpr_modelSums['3P10S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3rrpr_modelSums['3P10S'].work_minute}}</td>            
          <td>{{ sums.ford3rrdt_modelSums['3P10S'].time}}</td>     
        </tr>
        <tr>
          <td>3P8S</td>          
          <td>{{ sums.ford3rrpr_modelSums['3P8S'].target }}</td>
          <td>{{ sums.ford3rrpr_modelSums['3P8S'].input }}</td>
          <td class="ok">{{ sums.ford3rrpr_modelSums['3P8S'].ok}}</td>         
          <td class="ng">{{ sums.ford3rrpr_modelSums['3P8S'].ng}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P8S'].ng_lasermarkingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P8S'].ng_middleinspsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P8S'].ng_aoingsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P8S'].ng_coatingngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P8S'].ng_eolngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P8S'].ng_lqcngsum}}</td>
          <td class="ng_type">{{ sums.ford3rrpr_modelSums['3P8S'].ng_finalinspngsum}}</td>          
          <td class="ng">{{ sums.ford3rrpr_modelSums['3P8S'].input === 0 ? 0 : (((sums.ford3rrpr_modelSums['3P8S'].ng) / (sums.ford3rrpr_modelSums['3P8S'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3rrpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['3P8S'].fn_middleinspsum + sums.ford3rrpr_modelSums['3P8S'].fn_aoingsum + sums.ford3rrpr_modelSums['3P8S'].fn_coatingsum + sums.ford3rrpr_modelSums['3P8S'].fn_eolsum + sums.ford3rrpr_modelSums['3P8S'].fn_lqcsum + sums.ford3rrpr_modelSums['3P8S'].fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P8S'].fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P8S'].fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P8S'].fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P8S'].fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P8S'].fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P8S'].fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_modelSums['3P8S'].fn_finalinspsum }}</td>  
          <td class="fn">{{ sums.ford3rrpr_modelSums['3P8S'].input === 0 ? 0 : ((sums.ford3rrpr_modelSums['3P8S'].fn_lasermarkingsum + sums.ford3rrpr_modelSums['3P8S'].fn_middleinspsum + sums.ford3rrpr_modelSums['3P8S'].fn_aoingsum + sums.ford3rrpr_modelSums['3P8S'].fn_coatingsum + sums.ford3rrpr_modelSums['3P8S'].fn_eolsum + sums.ford3rrpr_modelSums['3P8S'].fn_lqcsum + sums.ford3rrpr_modelSums['3P8S'].fn_finalinspsum)/sums.ford3rrpr_modelSums['3P8S'].input).toFixed(1) }}%</td>
          <td>{{ sums.ford3rrpr_modelSums['3P8S'].work_minute}}</td>            
          <td>{{ sums.ford3rrdt_modelSums['3P8S'].time}}</td>     
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.ford3rrpr_Sums.target }}</td>
          <td>{{ sums.ford3rrpr_Sums.input }}</td>
          <td class="ok">{{ sums.ford3rrpr_Sums.ok }}</td>         
          <td class="ng">{{ sums.ford3rrpr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.ford3rrpr_Sums.ng_lasermarkingsum }}</td>
          <td class="ng_type">{{ sums.ford3rrpr_Sums.ng_middleinspsum }}</td>
          <td class="ng_type">{{ sums.ford3rrpr_Sums.ng_aoingsum }}</td>
          <td class="ng_type">{{ sums.ford3rrpr_Sums.ng_coatingngsum }}</td>
          <td class="ng_type">{{ sums.ford3rrpr_Sums.ng_eolngsum }}</td>
          <td class="ng_type">{{ sums.ford3rrpr_Sums.ng_lqcngsum }}</td>
          <td class="ng_type">{{ sums.ford3rrpr_Sums.ng_finalinspngsum }}</td>          
          <td class="ng">{{ sums.ford3rrpr_Sums.input === 0 ? 0 : (((sums.ford3rrpr_Sums.ng) / (sums.ford3rrpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.ford3rrpr_Sums.fn_lasermarkingsum + sums.ford3rrpr_Sums.fn_middleinspsum + sums.ford3rrpr_Sums.fn_aoingsum + sums.ford3rrpr_Sums.fn_coatingsum + sums.ford3rrpr_Sums.fn_eolsum + sums.ford3rrpr_Sums.fn_lqcsum + sums.ford3rrpr_Sums.fn_finalinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_Sums.fn_lasermarkingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_Sums.fn_middleinspsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_Sums.fn_aoingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_Sums.fn_coatingsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_Sums.fn_eolsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_Sums.fn_lqcsum }}</td>
          <td class="fn_type">{{ sums.ford3rrpr_Sums.fn_finalinspsum }}</td>          
          <td class="fn">{{ sums.ford3rrpr_Sums.input === 0 ? 0 : ((sums.ford3rrpr_Sums.fn_lasermarkingsum + sums.ford3rrpr_Sums.fn_middleinspsum + sums.ford3rrpr_Sums.fn_aoingsum + sums.ford3rrpr_Sums.fn_coatingsum + sums.ford3rrpr_Sums.fn_eolsum + sums.ford3rrpr_Sums.fn_lqcsum + sums.ford3rrpr_Sums.fn_finalinspsum )/sums.ford3rrpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.ford3rrpr_Sums.work_minute}}</td>            
          <td>{{ sums.ford3rrdt_Sums.time}}</td>         
        </tr>        
      </table>      
      <B>FCA #1</B>
      <table>
        <tr><th v-for="(header, index) in fca1_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>Front</td>          
          <td>{{ sums.fca1pr_modelSums['Front'].target }}</td>
          <td>{{ sums.fca1pr_modelSums['Front'].input }}</td>
          <td class="ok">{{ sums.fca1pr_modelSums['Front'].ok - sums.fca1qw_modelSums['Front'].ng }}</td>         
          <td class="ng">{{ [ sums.fca1pr_modelSums['Front'],sums.fca1qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Front'],sums.fca1qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Front'],sums.fca1qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Front'],sums.fca1qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_aoi, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Front'],sums.fca1qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Front'],sums.fca1qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Front'],sums.fca1qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.fca1pr_modelSums['Front'].input === 0 ? 0 : (((sums.fca1pr_modelSums['Front'].ng + sums.fca1qw_modelSums['Front'].ng) / (sums.fca1pr_modelSums['Front'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca1pr_modelSums['Front'].fn_assembly + sums.fca1pr_modelSums['Front'].fn_soldering + sums.fca1pr_modelSums['Front'].fn_aoi + sums.fca1pr_modelSums['Front'].fn_coating + sums.fca1pr_modelSums['Front'].fn_eol + sums.fca1pr_modelSums['Front'].fn_final  }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Front'].fn_assembly }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Front'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Front'].fn_aoi }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Front'].fn_coating }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Front'].fn_eol }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Front'].fn_final }}</td>
          <td class="fn">{{ sums.fca1pr_modelSums['Front'].input === 0 ? 0 : ((sums.fca1pr_modelSums['Front'].fn_assembly + sums.fca1pr_modelSums['Front'].fn_soldering + sums.fca1pr_modelSums['Front'].fn_aoi + sums.fca1pr_modelSums['Front'].fn_coating + sums.fca1pr_modelSums['Front'].fn_eol + sums.fca1pr_modelSums['Front'].fn_final)/sums.fca1pr_modelSums['Front'].input).toFixed(1) }}%</td>
          <td>{{ sums.fca1pr_modelSums['Front'].work_minute}}</td>            
          <td>{{ sums.fca1dt_modelSums['Front'].time}}</td>   
        </tr>
        <tr>
          <td>Rear</td>          
          <td>{{ sums.fca1pr_modelSums['Rear'].target }}</td>
          <td>{{ sums.fca1pr_modelSums['Rear'].input }}</td>
          <td class="ok">{{ sums.fca1pr_modelSums['Rear'].ok - sums.fca1qw_modelSums['Rear'].ng }}</td>         
          <td class="ng">{{ [ sums.fca1pr_modelSums['Rear'],sums.fca1qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Rear'],sums.fca1qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Rear'],sums.fca1qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Rear'],sums.fca1qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_aoi, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Rear'],sums.fca1qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Rear'],sums.fca1qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_modelSums['Rear'],sums.fca1qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.fca1pr_modelSums['Rear'].input === 0 ? 0 : (((sums.fca1pr_modelSums['Rear'].ng + sums.fca1qw_modelSums['Rear'].ng) / (sums.fca1pr_modelSums['Rear'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca1pr_modelSums['Rear'].fn_assembly + sums.fca1pr_modelSums['Rear'].fn_soldering + sums.fca1pr_modelSums['Rear'].fn_aoi + sums.fca1pr_modelSums['Rear'].fn_coating + sums.fca1pr_modelSums['Rear'].fn_eol + sums.fca1pr_modelSums['Rear'].fn_final  }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Rear'].fn_assembly }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Rear'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Rear'].fn_aoi }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Rear'].fn_coating }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Rear'].fn_eol }}</td>
          <td class="fn_type">{{ sums.fca1pr_modelSums['Rear'].fn_final }}</td>
          <td class="fn">{{ sums.fca1pr_modelSums['Rear'].input === 0 ? 0 : ((sums.fca1pr_modelSums['Rear'].fn_assembly + sums.fca1pr_modelSums['Rear'].fn_soldering + sums.fca1pr_modelSums['Rear'].fn_aoi + sums.fca1pr_modelSums['Rear'].fn_coating + sums.fca1pr_modelSums['Rear'].fn_eol + sums.fca1pr_modelSums['Rear'].fn_final)/sums.fca1pr_modelSums['Rear'].input).toFixed(1) }}%</td>
          <td>{{ sums.fca1pr_modelSums['Rear'].work_minute}}</td>            
          <td>{{ sums.fca1dt_modelSums['Rear'].time}}</td>   
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.fca1pr_Sums.target }}</td>
          <td>{{ sums.fca1pr_Sums.input }}</td>
          <td class="ok">{{ sums.fca1pr_Sums.ok - sums.fca1qw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.fca1pr_Sums,sums.fca1qw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_Sums,sums.fca1qw_Sums].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_Sums,sums.fca1qw_Sums].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_Sums,sums.fca1qw_Sums].reduce((sum, obj) => sum + obj.ng_aoi, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_Sums,sums.fca1qw_Sums].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_Sums,sums.fca1qw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca1pr_Sums,sums.fca1qw_Sums].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.fca1pr_Sums.input === 0 ? 0 : (((sums.fca1pr_Sums.ng + sums.fca1qw_Sums.ng) / (sums.fca1pr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca1pr_Sums.fn_assembly + sums.fca1pr_Sums.fn_soldering + sums.fca1pr_Sums.fn_aoi + sums.fca1pr_Sums.fn_coating + sums.fca1pr_Sums.fn_eol + sums.fca1pr_Sums.fn_final }}</td>
          <td class="fn_type">{{ sums.fca1pr_Sums.fn_assembly }}</td>
          <td class="fn_type">{{ sums.fca1pr_Sums.fn_soldering }}</td>
          <td class="fn_type">{{ sums.fca1pr_Sums.fn_aoi }}</td>
          <td class="fn_type">{{ sums.fca1pr_Sums.fn_coating }}</td>
          <td class="fn_type">{{ sums.fca1pr_Sums.fn_eol }}</td>
          <td class="fn_type">{{ sums.fca1pr_Sums.fn_final }}</td>
          <td class="fn">{{ sums.fca1pr_Sums.input === 0 ? 0 : ((sums.fca1pr_Sums.fn_assembly + sums.fca1pr_Sums.fn_soldering + sums.fca1pr_Sums.fn_aoi + sums.fca1pr_Sums.fn_coating + sums.fca1pr_Sums.fn_eol + sums.fca1pr_Sums.fn_final)/sums.fca1pr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.fca1pr_Sums.work_minute}}</td>            
          <td>{{ sums.fca1dt_Sums.time}}</td>         
        </tr>        
      </table>  
      <B>FCA #2</B>
      <table>
        <tr><th v-for="(header, index) in fca2_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>Front</td>          
          <td>{{ sums.fca2pr_modelSums['Front'].target }}</td>
          <td>{{ sums.fca2pr_modelSums['Front'].input }}</td>
          <td class="ok">{{ sums.fca2pr_modelSums['Front'].ok - sums.fca2qw_modelSums['Front'].ng }}</td>         
          <td class="ng">{{ [ sums.fca2pr_modelSums['Front'],sums.fca2qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Front'],sums.fca2qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Front'],sums.fca2qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Front'],sums.fca2qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_aoi, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Front'],sums.fca2qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Front'],sums.fca2qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Front'],sums.fca2qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.fca2pr_modelSums['Front'].input === 0 ? 0 : (((sums.fca2pr_modelSums['Front'].ng + sums.fca2qw_modelSums['Front'].ng) / (sums.fca2pr_modelSums['Front'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca2pr_modelSums['Front'].fn_assembly + sums.fca2pr_modelSums['Front'].fn_soldering + sums.fca2pr_modelSums['Front'].fn_aoi + sums.fca2pr_modelSums['Front'].fn_coating + sums.fca2pr_modelSums['Front'].fn_eol + sums.fca2pr_modelSums['Front'].fn_final  }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Front'].fn_assembly }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Front'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Front'].fn_aoi }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Front'].fn_coating }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Front'].fn_eol }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Front'].fn_final }}</td>
          <td class="fn">{{ sums.fca2pr_modelSums['Front'].input === 0 ? 0 : ((sums.fca2pr_modelSums['Front'].fn_assembly + sums.fca2pr_modelSums['Front'].fn_soldering + sums.fca2pr_modelSums['Front'].fn_aoi + sums.fca2pr_modelSums['Front'].fn_coating + sums.fca2pr_modelSums['Front'].fn_eol + sums.fca2pr_modelSums['Front'].fn_final)/sums.fca2pr_modelSums['Front'].input).toFixed(1) }}%</td>
          <td>{{ sums.fca2pr_modelSums['Front'].work_minute}}</td>            
          <td>{{ sums.fca2dt_modelSums['Front'].time}}</td>   
        </tr>
        <tr>
          <td>Rear</td>          
          <td>{{ sums.fca2pr_modelSums['Rear'].target }}</td>
          <td>{{ sums.fca2pr_modelSums['Rear'].input }}</td>
          <td class="ok">{{ sums.fca2pr_modelSums['Rear'].ok - sums.fca2qw_modelSums['Rear'].ng }}</td>         
          <td class="ng">{{ [ sums.fca2pr_modelSums['Rear'],sums.fca2qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Rear'],sums.fca2qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Rear'],sums.fca2qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Rear'],sums.fca2qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_aoi, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Rear'],sums.fca2qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Rear'],sums.fca2qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_modelSums['Rear'],sums.fca2qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.fca2pr_modelSums['Rear'].input === 0 ? 0 : (((sums.fca2pr_modelSums['Rear'].ng + sums.fca2qw_modelSums['Rear'].ng) / (sums.fca2pr_modelSums['Rear'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca2pr_modelSums['Rear'].fn_assembly + sums.fca2pr_modelSums['Rear'].fn_soldering + sums.fca2pr_modelSums['Rear'].fn_aoi + sums.fca2pr_modelSums['Rear'].fn_coating + sums.fca2pr_modelSums['Rear'].fn_eol + sums.fca2pr_modelSums['Rear'].fn_final  }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Rear'].fn_assembly }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Rear'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Rear'].fn_aoi }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Rear'].fn_coating }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Rear'].fn_eol }}</td>
          <td class="fn_type">{{ sums.fca2pr_modelSums['Rear'].fn_final }}</td>
          <td class="fn">{{ sums.fca2pr_modelSums['Rear'].input === 0 ? 0 : ((sums.fca2pr_modelSums['Rear'].fn_assembly + sums.fca2pr_modelSums['Rear'].fn_soldering + sums.fca2pr_modelSums['Rear'].fn_aoi + sums.fca2pr_modelSums['Rear'].fn_coating + sums.fca2pr_modelSums['Rear'].fn_eol + sums.fca2pr_modelSums['Rear'].fn_final)/sums.fca2pr_modelSums['Rear'].input).toFixed(1) }}%</td>
          <td>{{ sums.fca2pr_modelSums['Rear'].work_minute}}</td>            
          <td>{{ sums.fca2dt_modelSums['Rear'].time}}</td>   
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.fca2pr_Sums.target }}</td>
          <td>{{ sums.fca2pr_Sums.input }}</td>
          <td class="ok">{{ sums.fca2pr_Sums.ok - sums.fca2qw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.fca2pr_Sums,sums.fca2qw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_Sums,sums.fca2qw_Sums].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_Sums,sums.fca2qw_Sums].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_Sums,sums.fca2qw_Sums].reduce((sum, obj) => sum + obj.ng_aoi, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_Sums,sums.fca2qw_Sums].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_Sums,sums.fca2qw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca2pr_Sums,sums.fca2qw_Sums].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.fca2pr_Sums.input === 0 ? 0 : (((sums.fca2pr_Sums.ng + sums.fca2qw_Sums.ng) / (sums.fca2pr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca2pr_Sums.fn_assembly + sums.fca2pr_Sums.fn_soldering + sums.fca2pr_Sums.fn_aoi + sums.fca2pr_Sums.fn_coating + sums.fca2pr_Sums.fn_eol + sums.fca2pr_Sums.fn_final }}</td>
          <td class="fn_type">{{ sums.fca2pr_Sums.fn_assembly }}</td>
          <td class="fn_type">{{ sums.fca2pr_Sums.fn_soldering }}</td>
          <td class="fn_type">{{ sums.fca2pr_Sums.fn_aoi }}</td>
          <td class="fn_type">{{ sums.fca2pr_Sums.fn_coating }}</td>
          <td class="fn_type">{{ sums.fca2pr_Sums.fn_eol }}</td>
          <td class="fn_type">{{ sums.fca2pr_Sums.fn_final }}</td>
          <td class="fn">{{ sums.fca2pr_Sums.input === 0 ? 0 : ((sums.fca2pr_Sums.fn_assembly + sums.fca2pr_Sums.fn_soldering + sums.fca2pr_Sums.fn_aoi + sums.fca2pr_Sums.fn_coating + sums.fca2pr_Sums.fn_eol + sums.fca2pr_Sums.fn_final)/sums.fca2pr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.fca2pr_Sums.work_minute}}</td>            
          <td>{{ sums.fca2dt_Sums.time}}</td>         
        </tr>        
      </table> 
      <B>BT6</B>
      <table>
        <tr><th v-for="(header, index) in bt6_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>Front</td>          
          <td>{{ sums.bt6pr_modelSums['Front'].target }}</td>
          <td>{{ sums.bt6pr_modelSums['Front'].input }}</td>
          <td class="ok">{{ sums.bt6pr_modelSums['Front'].ok - sums.bt6qw_modelSums['Front'].ng }}</td>         
          <td class="ng">{{ [ sums.bt6pr_modelSums['Front'],sums.bt6qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Front'],sums.bt6qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Front'],sums.bt6qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Front'],sums.bt6qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_flatness, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Front'],sums.bt6qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Front'],sums.bt6qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_vision, 0) }}</td>          
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Front'],sums.bt6qw_modelSums['Front']].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.bt6pr_modelSums['Front'].input === 0 ? 0 : (((sums.bt6pr_modelSums['Front'].ng + sums.bt6qw_modelSums['Front'].ng) / (sums.bt6pr_modelSums['Front'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.bt6pr_modelSums['Front'].fn_assembly + sums.bt6pr_modelSums['Front'].fn_soldering + sums.bt6pr_modelSums['Front'].fn_flatness + sums.bt6pr_modelSums['Front'].fn_vision + sums.bt6pr_modelSums['Front'].fn_eol + sums.bt6pr_modelSums['Front'].fn_aoi  }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Front'].fn_assembly }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Front'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Front'].fn_flatness }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Front'].fn_eol }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Front'].fn_vision }}</td>          
          <td class="fn_type">{{ sums.bt6pr_modelSums['Front'].fn_aoi }}</td>
          <td class="fn">{{ sums.bt6pr_modelSums['Front'].input === 0 ? 0 : ((sums.bt6pr_modelSums['Front'].fn_assembly + sums.bt6pr_modelSums['Front'].fn_soldering + sums.bt6pr_modelSums['Front'].fn_flatness + sums.bt6pr_modelSums['Front'].fn_vision + sums.bt6pr_modelSums['Front'].fn_eol + sums.bt6pr_modelSums['Front'].fn_aoi)/sums.bt6pr_modelSums['Front'].input).toFixed(1) }}%</td>
          <td>{{ sums.bt6pr_modelSums['Front'].work_minute}}</td>            
          <td>{{ sums.bt6dt_modelSums['Front'].time}}</td>   
        </tr>
        <tr>
          <td>Rear</td>          
          <td>{{ sums.bt6pr_modelSums['Rear'].target }}</td>
          <td>{{ sums.bt6pr_modelSums['Rear'].input }}</td>
          <td class="ok">{{ sums.bt6pr_modelSums['Rear'].ok - sums.bt6qw_modelSums['Rear'].ng }}</td>         
          <td class="ng">{{ [ sums.bt6pr_modelSums['Rear'],sums.bt6qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Rear'],sums.bt6qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Rear'],sums.bt6qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Rear'],sums.bt6qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_flatness, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Rear'],sums.bt6qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Rear'],sums.bt6qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_vision, 0) }}</td>          
          <td class="ng_type">{{ [ sums.bt6pr_modelSums['Rear'],sums.bt6qw_modelSums['Rear']].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.bt6pr_modelSums['Rear'].input === 0 ? 0 : (((sums.bt6pr_modelSums['Rear'].ng + sums.bt6qw_modelSums['Rear'].ng) / (sums.bt6pr_modelSums['Rear'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.bt6pr_modelSums['Rear'].fn_assembly + sums.bt6pr_modelSums['Rear'].fn_soldering + sums.bt6pr_modelSums['Rear'].fn_flatness + sums.bt6pr_modelSums['Rear'].fn_vision + sums.bt6pr_modelSums['Rear'].fn_eol + sums.bt6pr_modelSums['Rear'].fn_aoi  }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Rear'].fn_assembly }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Rear'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Rear'].fn_flatness }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Rear'].fn_eol }}</td>
          <td class="fn_type">{{ sums.bt6pr_modelSums['Rear'].fn_vision }}</td>          
          <td class="fn_type">{{ sums.bt6pr_modelSums['Rear'].fn_aoi }}</td>
          <td class="fn">{{ sums.bt6pr_modelSums['Rear'].input === 0 ? 0 : ((sums.bt6pr_modelSums['Rear'].fn_assembly + sums.bt6pr_modelSums['Rear'].fn_soldering + sums.bt6pr_modelSums['Rear'].fn_flatness + sums.bt6pr_modelSums['Rear'].fn_vision + sums.bt6pr_modelSums['Rear'].fn_eol + sums.bt6pr_modelSums['Rear'].fn_aoi)/sums.bt6pr_modelSums['Rear'].input).toFixed(1) }}%</td>
          <td>{{ sums.bt6pr_modelSums['Rear'].work_minute}}</td>            
          <td>{{ sums.bt6dt_modelSums['Rear'].time}}</td>   
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.bt6pr_Sums.target }}</td>
          <td>{{ sums.bt6pr_Sums.input }}</td>
          <td class="ok">{{ sums.bt6pr_Sums.ok - sums.bt6qw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.bt6pr_Sums,sums.bt6qw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_Sums,sums.bt6qw_Sums].reduce((sum, obj) => sum + obj.ng_assembly, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_Sums,sums.bt6qw_Sums].reduce((sum, obj) => sum + obj.ng_soldering, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_Sums,sums.bt6qw_Sums].reduce((sum, obj) => sum + obj.ng_flatness, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_Sums,sums.bt6qw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.bt6pr_Sums,sums.bt6qw_Sums].reduce((sum, obj) => sum + obj.ng_vision, 0) }}</td>          
          <td class="ng_type">{{ [ sums.bt6pr_Sums,sums.bt6qw_Sums].reduce((sum, obj) => sum + obj.ng_final, 0) }}</td>
          <td class="ng">{{ sums.bt6pr_Sums.input === 0 ? 0 : (((sums.bt6pr_Sums.ng + sums.bt6qw_Sums.ng) / (sums.bt6pr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.bt6pr_Sums.fn_assembly + sums.bt6pr_Sums.fn_soldering + sums.bt6pr_Sums.fn_flatness + sums.bt6pr_Sums.fn_vision + sums.bt6pr_Sums.fn_eol + sums.bt6pr_Sums.fn_aoi }}</td>
          <td class="fn_type">{{ sums.bt6pr_Sums.fn_assembly }}</td>
          <td class="fn_type">{{ sums.bt6pr_Sums.fn_soldering }}</td>
          <td class="fn_type">{{ sums.bt6pr_Sums.fn_flatness }}</td>
          <td class="fn_type">{{ sums.bt6pr_Sums.fn_eol }}</td>
          <td class="fn_type">{{ sums.bt6pr_Sums.fn_vision }}</td>          
          <td class="fn_type">{{ sums.bt6pr_Sums.fn_aoi }}</td>
          <td class="fn">{{ sums.bt6pr_Sums.input === 0 ? 0 : ((sums.bt6pr_Sums.fn_assembly + sums.bt6pr_Sums.fn_soldering + sums.bt6pr_Sums.fn_flatness + sums.bt6pr_Sums.fn_vision + sums.bt6pr_Sums.fn_eol + sums.bt6pr_Sums.fn_aoi)/sums.bt6pr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.bt6pr_Sums.work_minute}}</td>            
          <td>{{ sums.bt6dt_Sums.time}}</td>         
        </tr>        
      </table>     
      <B>BMW 12V</B>
      <table>
        <tr><th v-for="(header, index) in bmw12v_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>F40 12V</td>    
          <td>{{ sums.bmw12vpr_modelSums['F40 12V'].target }}</td>
          <td>{{ sums.bmw12vpr_modelSums['F40 12V'].input }}</td>
          <td class="ok">{{ sums.bmw12vpr_modelSums['F40 12V'].ok - sums.bmw12vqw_modelSums['F40 12V'].ng }}</td>         
          <td class="ng">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>          
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['F40 12V'],sums.bmw12vqw_modelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>
          <td class="ng">{{ sums.bmw12vpr_modelSums['F40 12V'].input === 0 ? 0 : (((sums.bmw12vpr_modelSums['F40 12V'].ng + sums.bmw12vqw_modelSums['F40 12V'].ng) / (sums.bmw12vpr_modelSums['F40 12V'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.bmw12vpr_modelSums['F40 12V'].fn_soldering + sums.bmw12vpr_modelSums['F40 12V'].fn_coating + sums.bmw12vpr_modelSums['F40 12V'].fn_vision + sums.bmw12vpr_modelSums['F40 12V'].fn_eol }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['F40 12V'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['F40 12V'].fn_coating }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['F40 12V'].fn_eol }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['F40 12V'].fn_vision }}</td>                    
          <td class="fn">{{ sums.bmw12vpr_modelSums['F40 12V'].input === 0 ? 0 : ((sums.bmw12vpr_modelSums['F40 12V'].fn_soldering + sums.bmw12vpr_modelSums['F40 12V'].fn_coating + sums.bmw12vpr_modelSums['F40 12V'].fn_vision + sums.bmw12vpr_modelSums['F40 12V'].fn_eol)/sums.bmw12vpr_modelSums['F40 12V'].input).toFixed(1) }}%</td>
          <td>{{ sums.bmw12vpr_modelSums['F40 12V'].work_minute}}</td>            
          <td>{{ sums.bmw12vdt_modelSums['F40 12V'].time}}</td>   
        </tr>
        <tr>
          <td>HAF</td>          
          <td>{{ sums.bmw12vpr_modelSums['HAF'].target }}</td>
          <td>{{ sums.bmw12vpr_modelSums['HAF'].input }}</td>
          <td class="ok">{{ sums.bmw12vpr_modelSums['HAF'].ok - sums.bmw12vqw_modelSums['HAF'].ng }}</td>         
          <td class="ng">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>          
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_modelSums['HAF'],sums.bmw12vqw_modelSums['HAF']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>
          <td class="ng">{{ sums.bmw12vpr_modelSums['HAF'].input === 0 ? 0 : (((sums.bmw12vpr_modelSums['HAF'].ng + sums.bmw12vqw_modelSums['HAF'].ng) / (sums.bmw12vpr_modelSums['HAF'].input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.bmw12vpr_modelSums['HAF'].fn_soldering + sums.bmw12vpr_modelSums['HAF'].fn_coating + sums.bmw12vpr_modelSums['HAF'].fn_vision + sums.bmw12vpr_modelSums['HAF'].fn_eol }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['HAF'].fn_soldering }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['HAF'].fn_coating }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['HAF'].fn_eol }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_modelSums['HAF'].fn_vision }}</td>                    
          <td class="fn">{{ sums.bmw12vpr_modelSums['HAF'].input === 0 ? 0 : ((sums.bmw12vpr_modelSums['HAF'].fn_soldering + sums.bmw12vpr_modelSums['HAF'].fn_coating + sums.bmw12vpr_modelSums['HAF'].fn_vision + sums.bmw12vpr_modelSums['HAF'].fn_eol)/sums.bmw12vpr_modelSums['HAF'].input).toFixed(1) }}%</td>
          <td>{{ sums.bmw12vpr_modelSums['HAF'].work_minute}}</td>            
          <td>{{ sums.bmw12vdt_modelSums['HAF'].time}}</td>   
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.bmw12vpr_Sums.target }}</td>
          <td>{{ sums.bmw12vpr_Sums.input }}</td>
          <td class="ok">{{ sums.bmw12vpr_Sums.ok - sums.bmw12vqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>          
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.bmw12vpr_Sums,sums.bmw12vqw_Sums].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>         
          <td class="ng">{{ sums.bmw12vpr_Sums.input === 0 ? 0 : (((sums.bmw12vpr_Sums.ng + sums.bmw12vqw_Sums.ng) / (sums.bmw12vpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.bmw12vpr_Sums.fn_soldering + sums.bmw12vpr_Sums.fn_coating + sums.bmw12vpr_Sums.fn_vision + sums.bmw12vpr_Sums.fn_eol }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_Sums.fn_soldering }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_Sums.fn_coating }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_Sums.fn_eol }}</td>
          <td class="fn_type">{{ sums.bmw12vpr_Sums.fn_vision }}</td>                    
          <td class="fn">{{ sums.bmw12vpr_Sums.input === 0 ? 0 : ((sums.bmw12vpr_Sums.fn_soldering + sums.bmw12vpr_Sums.fn_coating + sums.bmw12vpr_Sums.fn_vision + sums.bmw12vpr_Sums.fn_eol)/sums.bmw12vpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.bmw12vpr_Sums.work_minute}}</td>            
          <td>{{ sums.bmw12vdt_Sums.time}}</td>         
        </tr>        
      </table>    
      <B>BMW 48V</B>
      <table>
        <tr><th v-for="(header, index) in bmw48v_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>ICB</td>    
          <td>{{ sums.bmw48vpr_modelSums['ICB'].target }}</td>
          <td>{{ sums.bmw48vpr_modelSums['ICB'].input }}</td>
          <td class="ok_type">{{ sums.bmw48vpr_modelSums['ICB'].ok_busbarassembly - sums.bmw48vqw_modelSums['ICB'].ng_busbarassembly }}</td>
          <td class="ng_type">{{ sums.bmw48vpr_modelSums['ICB'].ng_busbarassembly + sums.bmw48vqw_modelSums['ICB'].ng_busbarassembly }}</td>
          <td class="ok_type">{{ sums.bmw48vpr_modelSums['ICB'].ok_pcs - sums.bmw48vqw_modelSums['ICB'].ng_pcs }}</td>
          <td class="ng_type">{{ sums.bmw48vpr_modelSums['ICB'].ng_pcs + sums.bmw48vqw_modelSums['ICB'].ng_pcs }}</td>
          <td class="ok_type">{{ sums.bmw48vpr_modelSums['ICB'].ok_leaktest - sums.bmw48vqw_modelSums['ICB'].ng_leaktest }}</td>
          <td class="ng_type">{{ sums.bmw48vpr_modelSums['ICB'].ng_leaktest + sums.bmw48vqw_modelSums['ICB'].ng_leaktest }}</td>
          <td class="ok_type">{{ sums.bmw48vpr_modelSums['ICB'].ok_harnessassembly - sums.bmw48vqw_modelSums['ICB'].ng_harnessassembly }}</td>
          <td class="ng_type">{{ sums.bmw48vpr_modelSums['ICB'].ng_harnessassembly + sums.bmw48vqw_modelSums['ICB'].ng_harnessassembly }}</td>
          <td class="ok_type">{{ sums.bmw48vpr_modelSums['ICB'].ok_eol - sums.bmw48vqw_modelSums['ICB'].ng_eol }}</td>
          <td class="ng_type">{{ sums.bmw48vpr_modelSums['ICB'].ng_eol + sums.bmw48vqw_modelSums['ICB'].ng_eol }}</td>
          <td class="ok">{{ sums.bmw48vpr_modelSums['ICB'].ok - sums.bmw48vqw_modelSums['ICB'].ng_final }}</td>
          <td class="ng_type">{{ sums.bmw48vpr_modelSums['ICB'].ng_final + sums.bmw48vqw_modelSums['ICB'].ng_final }}</td>
          <td class="ng">{{ sums.bmw48vpr_modelSums['ICB'].ng + sums.bmw48vqw_modelSums['ICB'].ng }}</td>             
          <td class="ng">{{ sums.bmw48vpr_modelSums['ICB'].input === 0 ? 0 : (((sums.bmw48vpr_modelSums['ICB'].ng + sums.bmw48vqw_modelSums['ICB'].ng) / (sums.bmw48vpr_modelSums['ICB'].input) * 100).toFixed(1)) }}%</td>          
          <td>{{ sums.bmw48vpr_modelSums['ICB'].work_minute}}</td>            
          <td>{{ sums.bmw48vdt_modelSums['ICB'].time}}</td>   
        </tr>        
        <tr>
          <td>Total</td>          
          <td>{{ sums.bmw48vpr_Sums.target }}</td>
          <td>{{ sums.bmw48vpr_Sums.input }}</td>
          <td class='ok_type'>{{ sums.bmw48vpr_Sums.ok_busbarassembly - sums.bmw48vqw_Sums.ng_busbarassembly }}</td>
          <td class='ng_type'>{{ sums.bmw48vpr_Sums.ng_busbarassembly + sums.bmw48vqw_Sums.ng_busbarassembly }}</td>
          <td class='ok_type'>{{ sums.bmw48vpr_Sums.ok_pcs - sums.bmw48vqw_Sums.ng_pcs }}</td>
          <td class='ng_type'>{{ sums.bmw48vpr_Sums.ng_pcs + sums.bmw48vqw_Sums.ng_pcs }}</td>
          <td class='ok_type'>{{ sums.bmw48vpr_Sums.ok_leaktest - sums.bmw48vqw_Sums.ng_leaktest }}</td>
          <td class='ng_type'>{{ sums.bmw48vpr_Sums.ng_leaktest + sums.bmw48vqw_Sums.ng_leaktest }}</td>
          <td class='ok_type'>{{ sums.bmw48vpr_Sums.ok_harnessassembly - sums.bmw48vqw_Sums.ng_harnessassembly }}</td>
          <td class='ng_type'>{{ sums.bmw48vpr_Sums.ng_harnessassembly + sums.bmw48vqw_Sums.ng_harnessassembly }}</td>
          <td class='ok_type'>{{ sums.bmw48vpr_Sums.ok_eol - sums.bmw48vqw_Sums.ng_eol }}</td>
          <td class='ng_type'>{{ sums.bmw48vpr_Sums.ng_eol + sums.bmw48vqw_Sums.ng_eol }}</td>
          <td class='ok'>{{ sums.bmw48vpr_Sums.ok - sums.bmw48vqw_Sums.ng_final }}</td>
          <td class='ng_type'>{{ sums.bmw48vpr_Sums.ng_final + sums.bmw48vqw_Sums.ng_final }}</td>
          <td class='ng'>{{ sums.bmw48vpr_Sums.ng + sums.bmw48vqw_Sums.ng }}</td>      
          <td class="ng">{{ sums.bmw48vpr_Sums.input === 0 ? 0 : (((sums.bmw48vpr_Sums.ng + sums.bmw48vqw_Sums.ng) / (sums.bmw48vpr_Sums.input) * 100).toFixed(1)) }}%</td>         
          <td>{{ sums.bmw48vpr_Sums.work_minute}}</td>            
          <td>{{ sums.bmw48vdt_Sums.time}}</td>         
        </tr>        
      </table>  
      <B>FCA Flexible</B>
      <table>
        <tr><th v-for="(header, index) in fca_flex_bc_headers" :key="index">{{ header }}</th></tr>   
        <tr>
          <td>Busbar Carrier</td>          
          <td>{{ sums.fca_flex_bcpr_Sums.target }}</td>
          <td>{{ sums.fca_flex_bcpr_Sums.input }}</td>
          <td class="ok">{{ sums.fca_flex_bcpr_Sums.ok - sums.fca_flex_bcqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.fca_flex_bcpr_Sums,sums.fca_flex_bcqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_bcpr_Sums,sums.fca_flex_bcqw_Sums].reduce((sum, obj) => sum + obj.ng_brokenbracket, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_bcpr_Sums,sums.fca_flex_bcqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedbusbar, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_bcpr_Sums,sums.fca_flex_bcqw_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_bcpr_Sums,sums.fca_flex_bcqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedscrew, 0) }}</td>          
          <td class="ng">{{ sums.fca_flex_bcpr_Sums.input === 0 ? 0 : (((sums.fca_flex_bcpr_Sums.ng + sums.fca_flex_bcqw_Sums.ng) / (sums.fca_flex_bcpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca_flex_bcpr_Sums.fn_brokenbracket + sums.fca_flex_bcpr_Sums.fn_damagedbusbar + sums.fca_flex_bcpr_Sums.fn_visiblecopper + sums.fca_flex_bcpr_Sums.fn_damagedscrew }}</td>
          <td class="fn_type">{{ sums.fca_flex_bcpr_Sums.fn_brokenbracket }}</td>
          <td class="fn_type">{{ sums.fca_flex_bcpr_Sums.fn_damagedbusbar }}</td>
          <td class="fn_type">{{ sums.fca_flex_bcpr_Sums.fn_visiblecopper }}</td>
          <td class="fn_type">{{ sums.fca_flex_bcpr_Sums.fn_damagedscrew }}</td>          
          <td class="fn">{{ sums.fca_flex_bcpr_Sums.input === 0 ? 0 : ((sums.fca_flex_bcpr_Sums.fn_brokenbracket + sums.fca_flex_bcpr_Sums.fn_damagedbusbar + sums.fca_flex_bcpr_Sums.fn_visiblecopper + sums.fca_flex_bcpr_Sums.fn_damagedscrew)/sums.fca_flex_bcpr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.fca_flex_bcpr_Sums.work_minute}}</td>            
          <td>{{ sums.fca_flex_bcdt_Sums.time}}</td>         
        </tr>        
      </table>      
      <table>
        <tr><th v-for="(header, index) in fca_flex_po_headers" :key="index">{{ header }}</th></tr>   
        <tr>
          <td>Positive</td>          
          <td>{{ sums.fca_flex_popr_Sums.target }}</td>
          <td>{{ sums.fca_flex_popr_Sums.input }}</td>
          <td class="ok">{{ sums.fca_flex_popr_Sums.ok - sums.fca_flex_poqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.fca_flex_popr_Sums,sums.fca_flex_poqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_popr_Sums,sums.fca_flex_poqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_popr_Sums,sums.fca_flex_poqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_popr_Sums,sums.fca_flex_poqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_popr_Sums,sums.fca_flex_poqw_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.fca_flex_popr_Sums.input === 0 ? 0 : (((sums.fca_flex_popr_Sums.ng + sums.fca_flex_poqw_Sums.ng) / (sums.fca_flex_popr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca_flex_popr_Sums.fn_wa_klip + sums.fca_flex_popr_Sums.fn_wa_orangetape + sums.fca_flex_popr_Sums.fn_wa_blacktape + sums.fca_flex_popr_Sums.fn_wa_siliconcap + sums.fca_flex_popr_Sums.fn_wa_capplus + sums.fca_flex_popr_Sums.fn_wa_bandcable + sums.fca_flex_popr_Sums.fn_lack_klip + sums.fca_flex_popr_Sums.fn_lack_orangetape + sums.fca_flex_popr_Sums.fn_lack_blacktape + sums.fca_flex_popr_Sums.fn_lack_siliconcap + sums.fca_flex_popr_Sums.fn_lack_capplus + sums.fca_flex_popr_Sums.fn_lack_bandcable }}</td>
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_wa_siliconcap }}</td>          
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type"></td>       
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_wa_bandcable }}</td>     
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_lack_siliconcap }}</td>          
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_lack_capplus }}</td>          
          <td class="fn_type"></td>       
          <td class="fn_type">{{ sums.fca_flex_popr_Sums.fn_lack_bandcable }}</td>             
          <td class="fn">{{ sums.fca_flex_popr_Sums.input === 0 ? 0 : ((sums.fca_flex_popr_Sums.fn_wa_klip + sums.fca_flex_popr_Sums.fn_wa_orangetape + sums.fca_flex_popr_Sums.fn_wa_blacktape + sums.fca_flex_popr_Sums.fn_wa_siliconcap + sums.fca_flex_popr_Sums.fn_wa_capplus + sums.fca_flex_popr_Sums.fn_wa_bandcable + sums.fca_flex_popr_Sums.fn_lack_klip + sums.fca_flex_popr_Sums.fn_lack_orangetape + sums.fca_flex_popr_Sums.fn_lack_blacktape + sums.fca_flex_popr_Sums.fn_lack_siliconcap + sums.fca_flex_popr_Sums.fn_lack_capplus + sums.fca_flex_popr_Sums.fn_lack_bandcable)/sums.fca_flex_popr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.fca_flex_popr_Sums.work_minute}}</td>            
          <td>{{ sums.fca_flex_podt_Sums.time}}</td>         
        </tr> 
        <tr>
          <td>6+7-</td>          
          <td>{{ sums.fca_flex_67pr_Sums.target }}</td>
          <td>{{ sums.fca_flex_67pr_Sums.input }}</td>
          <td class="ok">{{ sums.fca_flex_67pr_Sums.ok - sums.fca_flex_67qw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.fca_flex_67pr_Sums,sums.fca_flex_67qw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_67pr_Sums,sums.fca_flex_67qw_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_67pr_Sums,sums.fca_flex_67qw_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_67pr_Sums,sums.fca_flex_67qw_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_67pr_Sums,sums.fca_flex_67qw_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.fca_flex_67pr_Sums.input === 0 ? 0 : (((sums.fca_flex_67pr_Sums.ng + sums.fca_flex_67qw_Sums.ng) / (sums.fca_flex_67pr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca_flex_67pr_Sums.fn_wa_klip + sums.fca_flex_67pr_Sums.fn_wa_orangetape + sums.fca_flex_67pr_Sums.fn_wa_blacktape + sums.fca_flex_67pr_Sums.fn_wa_capplus + sums.fca_flex_67pr_Sums.fn_wa_capminus + sums.fca_flex_67pr_Sums.fn_lack_klip + sums.fca_flex_67pr_Sums.fn_lack_orangetape + sums.fca_flex_67pr_Sums.fn_lack_blacktape + sums.fca_flex_67pr_Sums.fn_lack_capplus + sums.fca_flex_67pr_Sums.fn_lack_capminus }}</td>
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_wa_capminus }}</td>       
          <td class="fn_type"></td>     
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_lack_capplus }}</td>          
          <td class="fn_type">{{ sums.fca_flex_67pr_Sums.fn_lack_capminus }}</td>       
          <td class="fn_type"></td>       
          <td class="fn">{{ sums.fca_flex_67pr_Sums.input === 0 ? 0 : ((sums.fca_flex_67pr_Sums.fn_wa_klip + sums.fca_flex_67pr_Sums.fn_wa_orangetape + sums.fca_flex_67pr_Sums.fn_wa_blacktape + sums.fca_flex_67pr_Sums.fn_wa_capplus + sums.fca_flex_67pr_Sums.fn_wa_capminus + sums.fca_flex_67pr_Sums.fn_lack_klip + sums.fca_flex_67pr_Sums.fn_lack_orangetape + sums.fca_flex_67pr_Sums.fn_lack_blacktape + sums.fca_flex_67pr_Sums.fn_lack_capplus + sums.fca_flex_67pr_Sums.fn_lack_capminus)/sums.fca_flex_67pr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.fca_flex_67pr_Sums.work_minute}}</td>            
          <td>{{ sums.fca_flex_67dt_Sums.time}}</td>         
        </tr> 
        <tr>
          <td>Negative</td>          
          <td>{{ sums.fca_flex_nepr_Sums.target }}</td>
          <td>{{ sums.fca_flex_nepr_Sums.input }}</td>
          <td class="ok">{{ sums.fca_flex_nepr_Sums.ok - sums.fca_flex_neqw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.fca_flex_nepr_Sums,sums.fca_flex_neqw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_nepr_Sums,sums.fca_flex_neqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_nepr_Sums,sums.fca_flex_neqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_nepr_Sums,sums.fca_flex_neqw_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_nepr_Sums,sums.fca_flex_neqw_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.fca_flex_nepr_Sums.input === 0 ? 0 : (((sums.fca_flex_nepr_Sums.ng + sums.fca_flex_neqw_Sums.ng) / (sums.fca_flex_nepr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca_flex_nepr_Sums.fn_wa_klip + sums.fca_flex_nepr_Sums.fn_wa_orangetape + sums.fca_flex_nepr_Sums.fn_wa_blacktape + sums.fca_flex_nepr_Sums.fn_wa_siliconcap + sums.fca_flex_nepr_Sums.fn_wa_capminus + sums.fca_flex_nepr_Sums.fn_lack_klip + sums.fca_flex_nepr_Sums.fn_lack_orangetape + sums.fca_flex_nepr_Sums.fn_lack_blacktape + sums.fca_flex_nepr_Sums.fn_lack_siliconcap + sums.fca_flex_nepr_Sums.fn_lack_capminus }}</td>
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_wa_siliconcap }}</td>          
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_wa_capminus }}</td>       
          <td class="fn_type"></td>     
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_lack_siliconcap }}</td>          
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.fca_flex_nepr_Sums.fn_lack_capminus }}</td>       
          <td class="fn_type"></td>       
          <td class="fn">{{ sums.fca_flex_nepr_Sums.input === 0 ? 0 : ((sums.fca_flex_nepr_Sums.fn_wa_klip + sums.fca_flex_nepr_Sums.fn_wa_orangetape + sums.fca_flex_nepr_Sums.fn_wa_blacktape + sums.fca_flex_nepr_Sums.fn_wa_siliconcap + sums.fca_flex_nepr_Sums.fn_wa_capminus + sums.fca_flex_nepr_Sums.fn_lack_klip + sums.fca_flex_nepr_Sums.fn_lack_orangetape + sums.fca_flex_nepr_Sums.fn_lack_blacktape + sums.fca_flex_nepr_Sums.fn_lack_siliconcap + sums.fca_flex_nepr_Sums.fn_lack_capminus)/sums.fca_flex_nepr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.fca_flex_nepr_Sums.work_minute}}</td>            
          <td>{{ sums.fca_flex_nedt_Sums.time}}</td>         
        </tr>
        <tr>
        <td>4-5+</td>          
          <td>{{ sums.fca_flex_45pr_Sums.target }}</td>
          <td>{{ sums.fca_flex_45pr_Sums.input }}</td>
          <td class="ok">{{ sums.fca_flex_45pr_Sums.ok - sums.fca_flex_45qw_Sums.ng }}</td>         
          <td class="ng">{{ [ sums.fca_flex_45pr_Sums,sums.fca_flex_45qw_Sums].reduce((sum, obj) => sum + obj.ng, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_45pr_Sums,sums.fca_flex_45qw_Sums].reduce((sum, obj) => sum + obj.ng_damagedcap, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_45pr_Sums,sums.fca_flex_45qw_Sums].reduce((sum, obj) => sum + obj.ng_damagedflex, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_45pr_Sums,sums.fca_flex_45qw_Sums].reduce((sum, obj) => sum + obj.ng_damagedtape, 0) }}</td>
          <td class="ng_type">{{ [ sums.fca_flex_45pr_Sums,sums.fca_flex_45qw_Sums].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td>          
          <td class="ng">{{ sums.fca_flex_45pr_Sums.input === 0 ? 0 : (((sums.fca_flex_45pr_Sums.ng + sums.fca_flex_45qw_Sums.ng) / (sums.fca_flex_45pr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td class="fn">{{ sums.fca_flex_45pr_Sums.fn_wa_klip + sums.fca_flex_45pr_Sums.fn_wa_orangetape + sums.fca_flex_45pr_Sums.fn_wa_blacktape + sums.fca_flex_45pr_Sums.fn_wa_capplus + sums.fca_flex_45pr_Sums.fn_wa_capminus + sums.fca_flex_45pr_Sums.fn_lack_klip + sums.fca_flex_45pr_Sums.fn_lack_orangetape + sums.fca_flex_45pr_Sums.fn_lack_blacktape + sums.fca_flex_45pr_Sums.fn_lack_capplus + sums.fca_flex_45pr_Sums.fn_lack_capminus }}</td>
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_wa_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_wa_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_wa_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_wa_capminus }}</td>       
          <td class="fn_type"></td>     
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_lack_klip }}</td>
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_lack_orangetape }}</td>
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_lack_blacktape }}</td>
          <td class="fn_type"></td>          
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_wa_capplus }}</td>          
          <td class="fn_type">{{ sums.fca_flex_45pr_Sums.fn_lack_capminus }}</td>       
          <td class="fn_type"></td>       
          <td class="fn">{{ sums.fca_flex_45pr_Sums.input === 0 ? 0 : ((sums.fca_flex_45pr_Sums.fn_wa_klip + sums.fca_flex_45pr_Sums.fn_wa_orangetape + sums.fca_flex_45pr_Sums.fn_wa_blacktape + sums.fca_flex_45pr_Sums.fn_wa_capplus + sums.fca_flex_45pr_Sums.fn_wa_capminus + sums.fca_flex_45pr_Sums.fn_lack_klip + sums.fca_flex_45pr_Sums.fn_lack_orangetape + sums.fca_flex_45pr_Sums.fn_lack_blacktape + sums.fca_flex_45pr_Sums.fn_lack_capplus + sums.fca_flex_45pr_Sums.fn_lack_capminus)/sums.fca_flex_45pr_Sums.input).toFixed(1)}}%</td>
          <td>{{ sums.fca_flex_45pr_Sums.work_minute}}</td>            
          <td>{{ sums.fca_flex_45dt_Sums.time}}</td>         
        </tr>                  
      </table>   
      <B>Thermistor Tape</B>
      <table>
        <tr><th v-for="(header, index) in thermistortape_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>EVO 2P</td>          
          <td>{{ sums.thermistortapepr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.thermistortapepr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_2P'].oktotal }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_2P'].ok_a }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_2P'].ok_b }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_2P'].ok_c }}</td>
          <td class="ng">{{ sums.thermistortapepr_modelSums['EVO_2P'].ng }}</td>
          <td class="ng_type">{{ sums.thermistortapepr_modelSums['EVO_2P'].ng_coverdamage }}</td>
          <td class="ng">{{ sums.thermistortapepr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.thermistortapepr_modelSums['EVO_2P'].ng) / (sums.thermistortapepr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.thermistortapepr_modelSums['EVO_2P'].work_minute}}</td>   
          <td>{{ sums.thermistortapedt_modelSums['EVO_2P'].time}}</td> 
        </tr>
        <tr>      
          <td>EVO 3P</td>          
          <td>{{ sums.thermistortapepr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.thermistortapepr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_3P'].oktotal }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_3P'].ok_a }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_3P'].ok_b }}</td>
          <td class="ok">{{ sums.thermistortapepr_modelSums['EVO_3P'].ok_c }}</td>
          <td class="ng">{{ sums.thermistortapepr_modelSums['EVO_3P'].ng }}</td>
          <td class="ng_type">{{ sums.thermistortapepr_modelSums['EVO_3P'].ng_coverdamage }}</td>
          <td class="ng">{{ sums.thermistortapepr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.thermistortapepr_modelSums['EVO_3P'].ng) / (sums.thermistortapepr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.thermistortapepr_modelSums['EVO_3P'].work_minute}}</td>            
          <td>{{ sums.thermistortapedt_modelSums['EVO_3P'].time}}</td>
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.thermistortapepr_Sums.target }}</td>
          <td>{{ sums.thermistortapepr_Sums.input }}</td>
          <td class="ok">{{ sums.thermistortapepr_Sums.oktotal }}</td>
          <td class="ok">{{ sums.thermistortapepr_Sums.ok_a }}</td>
          <td class="ok">{{ sums.thermistortapepr_Sums.ok_b }}</td>
          <td class="ok">{{ sums.thermistortapepr_Sums.ok_c }}</td>
          <td class="ng">{{ sums.thermistortapepr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.thermistortapepr_Sums.ng_coverdamage }}</td>
          <td class="ng">{{ sums.thermistortapepr_Sums.input === 0 ? 0 : (((sums.thermistortapepr_Sums.ng) / (sums.thermistortapepr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.thermistortapepr_Sums.work_minute}}</td>            
          <td>{{ sums.thermistortapedt_Sums.time}}</td>                  
        </tr>        
      </table> 
      <B>Thermistor Pad</B>
      <table>
        <tr><th v-for="(header, index) in thermistorpad_headers" :key="index">{{ header }}</th></tr>   
        <tr>      
          <td>EVO 2P</td>          
           <td>{{ sums.thermistorpadpr_modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.thermistorpadpr_modelSums['EVO_2P'].input }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_2P'].oktotal }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_2P'].ok_a }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_2P'].ok_b }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_2P'].ok_c }}</td>
          <td class="ng">{{ sums.thermistorpadpr_modelSums['EVO_2P'].ng }}</td>
          <td class="ng_type">{{ sums.thermistorpadpr_modelSums['EVO_2P'].ng_fpcadamage }}</td>
          <td class="ng_type">{{ sums.thermistorpadpr_modelSums['EVO_2P'].ng_thermistordamage }}</td>
          <td class="ng">{{ sums.thermistorpadpr_modelSums['EVO_2P'].input === 0 ? 0 : (((sums.thermistorpadpr_modelSums['EVO_2P'].ng) / (sums.thermistorpadpr_modelSums['EVO_2P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.thermistorpadpr_modelSums['EVO_2P'].work_minute}}</td>          
          <td>{{ sums.thermistorpaddt_modelSums['EVO_2P'].time}}</td>
        </tr>
        <tr>      
          <td>EVO 3P</td>          
          <td>{{ sums.thermistorpadpr_modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.thermistorpadpr_modelSums['EVO_3P'].input }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_3P'].oktotal }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_3P'].ok_a }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_3P'].ok_b }}</td>
          <td class="ok">{{ sums.thermistorpadpr_modelSums['EVO_3P'].ok_c }}</td>
          <td class="ng">{{ sums.thermistorpadpr_modelSums['EVO_3P'].ng }}</td>
          <td class="ng_type">{{ sums.thermistorpadpr_modelSums['EVO_3P'].ng_fpcadamage }}</td>
          <td class="ng_type">{{ sums.thermistorpadpr_modelSums['EVO_3P'].ng_thermistordamage }}</td>
          <td class="ng">{{ sums.thermistorpadpr_modelSums['EVO_3P'].input === 0 ? 0 : (((sums.thermistorpadpr_modelSums['EVO_3P'].ng) / (sums.thermistorpadpr_modelSums['EVO_3P'].input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.thermistorpadpr_modelSums['EVO_3P'].work_minute}}</td>      
          <td>{{ sums.thermistorpaddt_modelSums['EVO_3P'].time}}</td> 
        </tr>
        <tr>
          <td>Total</td>          
          <td>{{ sums.thermistorpadpr_Sums.target }}</td>
          <td>{{ sums.thermistorpadpr_Sums.input }}</td>
          <td class="ok">{{ sums.thermistorpadpr_Sums.oktotal }}</td>
          <td class="ok">{{ sums.thermistorpadpr_Sums.ok_a }}</td>
          <td class="ok">{{ sums.thermistorpadpr_Sums.ok_b }}</td>
          <td class="ok">{{ sums.thermistorpadpr_Sums.ok_c }}</td>
          <td class="ng">{{ sums.thermistorpadpr_Sums.ng }}</td>
          <td class="ng_type">{{ sums.thermistorpadpr_Sums.ng_fpcadamage }}</td>
          <td class="ng_type">{{ sums.thermistorpadpr_Sums.ng_thermistordamage }}</td>
          <td class="ng">{{ sums.thermistorpadpr_Sums.input === 0 ? 0 : (((sums.thermistorpadpr_Sums.ng) / (sums.thermistorpadpr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.thermistorpadpr_Sums.work_minute}}</td>            
          <td>{{ sums.thermistorpaddt_Sums.time}}</td>                  
        </tr>        
      </table>
      <B>Bending EVO</B>
      <table>
        <tr><th v-for="(header, index) in bendingevo_headers" :key="index">{{ header }}</th></tr>  
        <tr>
          <td>Total</td>          
          <td>{{ sums.bendingevopr_Sums.target }}</td>
          <td>{{ sums.bendingevopr_Sums.input }}</td>
          <td class="ok">{{ sums.bendingevopr_Sums.oktotal }}</td>
          <td class="ok">{{ sums.bendingevopr_Sums.ok_a }}</td>
          <td class="ok">{{ sums.bendingevopr_Sums.ok_b }}</td>
          <td class="ok">{{ sums.bendingevopr_Sums.ok_c }}</td>
          <td class="ng">{{ sums.bendingevopr_Sums.ngtotal }}</td>
          <td class="ng">{{ sums.bendingevopr_Sums.ng_3p }}</td>
          <td class="ng_type">{{ sums.bendingevopr_Sums.ng_3p_fpcadamage }}</td>
          <td class="ng_type">{{ sums.bendingevopr_Sums.ng_3p_coating }}</td>
          <td class="ng_type">{{ sums.bendingevopr_Sums.ng_3p_connector }}</td>
          <td class="ng">{{ sums.bendingevopr_Sums.ng_2p }}</td>
          <td class="ng_type">{{ sums.bendingevopr_Sums.ng_2p_fpcadamage }}</td>
          <td class="ng_type">{{ sums.bendingevopr_Sums.ng_2p_coating }}</td>
          <td class="ng_type">{{ sums.bendingevopr_Sums.ng_2p_connector }}</td>
          <td class="ng">{{ sums.bendingevopr_Sums.input === 0 ? 0 : (((sums.bendingevopr_Sums.ngtotal) / (sums.bendingevopr_Sums.input) * 100).toFixed(1)) }}%</td>
          <td>{{ sums.bendingevopr_Sums.work_minute}}</td>            
          <td>{{ sums.bendingevodt_Sums.time}}</td>                  
        </tr>        
      </table>                 
              
                     
      <!--  Target 요소(tact time 등) 필요시 부르는 방법 -->
        <!--  callTarget('TR3 SMT').tacttime  ( )안에 설비 이름을 넣고, 점 뒤에 필요한것을 치면 된다. -->
    </div>
  </template>

<script>

import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

export default {
  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();

    //각 설비별 데이터 베이스 table 이름을 입력해야 됨
    const targetTableName = "target"; 
    const tr2smt_dbTableName = 'tr2smtpr';
    const tr2smt_dtTableName = 'tr2smtdt';
    const tr2smt_qwTableName = 'tr2smtqw';
    const tr2crimping_dbTableName = 'tr2crimpingpr';
    const tr2crimping_dtTableName = 'tr2crimpingdt';
    const tr2crimping_qwTableName = 'tr2crimpingqw';
    const tr2fpca_dbTableName = 'tr2fpcapr';
    const tr2fpca_dtTableName = 'tr2fpcadt';
    const tr2fpca_qwTableName = 'tr2fpcaqw';
    const tr2bfa2p_dbTableName = 'tr2bfa2ppr';
    const tr2bfa2p_dtTableName = 'tr2bfa2pdt';
    const tr2bfa2p_qwTableName = 'tr2bfa2pqw';
    const tr2bfa3p_dbTableName = 'tr2bfa3ppr';
    const tr2bfa3p_dtTableName = 'tr2bfa3pdt';
    const tr2bfa3p_qwTableName = 'tr2bfa3pqw';
    const tr3smt_dbTableName = 'tr3smtpr';
    const tr3smt_dtTableName = 'tr3smtdt';
    const tr3smt_qwTableName = 'tr3smtqw';
    const tr3crimping_dbTableName = 'tr3crimpingpr';
    const tr3crimping_dtTableName = 'tr3crimpingdt';
    const tr3crimping_qwTableName = 'tr3crimpingqw';
    const tr3fpca_dbTableName = 'tr3fpcapr';
    const tr3fpca_dtTableName = 'tr3fpcadt';
    const tr3fpca_qwTableName = 'tr3fpcaqw';
    const tr3bfa2p_dbTableName = 'tr3bfa2ppr';
    const tr3bfa2p_dtTableName = 'tr3bfa2pdt';
    const tr3bfa2p_qwTableName = 'tr3bfa2pqw';
    const tr3bfa3p_dbTableName = 'tr3bfa3ppr';
    const tr3bfa3p_dtTableName = 'tr3bfa3pdt';
    const tr3bfa3p_qwTableName = 'tr3bfa3pqw';
    const evo_dbTableName = 'evopr';
    const evo_dtTableName = 'evodt';
    const evo_qwTableName = 'evoqw';
    const ev2020_60fr_dbTableName = 'ev2020_60frpr';
    const ev2020_60fr_dtTableName = 'ev2020_60frdt';
    const ev2020_60fr_qwTableName = 'ev2020_60frqw';
    const ev2020_60rr_dbTableName = 'ev2020_60rrpr';
    const ev2020_60rr_dtTableName = 'ev2020_60rrdt';
    const ev2020_60rr_qwTableName = 'ev2020_60rrqw';
    const ev2020_4060fr_dbTableName = 'ev2020_4060frpr';
    const ev2020_4060fr_dtTableName = 'ev2020_4060frdt';
    const ev2020_4060fr_qwTableName = 'ev2020_4060frqw';
    const ev2020_4060rr_dbTableName = 'ev2020_4060rrpr';
    const ev2020_4060rr_dtTableName = 'ev2020_4060rrdt';
    const ev2020_4060rr_qwTableName = 'ev2020_4060rrqw';
    const ford1fr_dbTableName = 'ford1frpr';
    const ford1fr_dtTableName = 'ford1frdt';
    const ford1fr_qwTableName = 'ford1frqw';
    const ford1rr_dbTableName = 'ford1rrpr';
    const ford1rr_dtTableName = 'ford1rrdt';
    const ford1rr_qwTableName = 'ford1rrqw';
    const ford2fr_dbTableName = 'ford2frpr';
    const ford2fr_dtTableName = 'ford2frdt';
    const ford2fr_qwTableName = 'ford2frqw';
    const ford2rr_dbTableName = 'ford2rrpr';
    const ford2rr_dtTableName = 'ford2rrdt';
    const ford2rr_qwTableName = 'ford2rrqw';
    const ford3fr_dbTableName = 'ford3frpr';
    const ford3fr_dtTableName = 'ford3frdt';
    const ford3fr_qwTableName = 'ford3frqw';
    const ford3rr_dbTableName = 'ford3rrpr';
    const ford3rr_dtTableName = 'ford3rrdt';
    const ford3rr_qwTableName = 'ford3rrqw';
    const fca1_dbTableName = 'fca1pr';
    const fca1_dtTableName = 'fca1dt';
    const fca1_qwTableName = 'fca1qw';
    const fca2_dbTableName = 'fca2pr';
    const fca2_dtTableName = 'fca2dt';
    const fca2_qwTableName = 'fca2qw';
    const bt6_dbTableName = 'bt6pr';
    const bt6_dtTableName = 'bt6dt';
    const bt6_qwTableName = 'bt6qw';
    const bmw12v_dbTableName = 'bmw12vpr';
    const bmw12v_dtTableName = 'bmw12vdt';
    const bmw12v_qwTableName = 'bmw12vqw';
    const bmw48v_dbTableName = 'bmw48vpr';
    const bmw48v_dtTableName = 'bmw48vdt';
    const bmw48v_qwTableName = 'bmw48vqw';
    const fca_flex_bc_dbTableName = 'fca_flex_bcpr';    
    const fca_flex_bc_dtTableName = 'fca_flex_bcdt';
    const fca_flex_bc_qwTableName = 'fca_flex_bcqw';
    const fca_flex_po_dbTableName = 'fca_flex_popr';    
    const fca_flex_po_dtTableName = 'fca_flex_podt';
    const fca_flex_po_qwTableName = 'fca_flex_poqw';
    const fca_flex_67_dbTableName = 'fca_flex_67pr';    
    const fca_flex_67_dtTableName = 'fca_flex_67dt';
    const fca_flex_67_qwTableName = 'fca_flex_67qw';
    const fca_flex_ne_dbTableName = 'fca_flex_nepr';    
    const fca_flex_ne_dtTableName = 'fca_flex_nedt';
    const fca_flex_ne_qwTableName = 'fca_flex_neqw';
    const fca_flex_45_dbTableName = 'fca_flex_45pr';    
    const fca_flex_45_dtTableName = 'fca_flex_45dt';
    const fca_flex_45_qwTableName = 'fca_flex_45qw';
    const thermistortape_dbTableName = 'thermistortapepr'
    const thermistortape_dtTableName = 'thermistortapedt'
    const thermistorpad_dbTableName = 'thermistorpadpr'
    const thermistorpad_dtTableName = 'thermistorpaddt'
    const bendingevo_dbTableName = 'bendingevopr'
    const bendingevo_dtTableName = 'bendingevodt'

    //각 설비별 header를 입력해야 됨
    const tr2smt_headers = ['Model', 'Target', 'Input', 'OK', 'NG', 'Fuse', 'Thermistor', 'Fiducial', 'QR Code', 'Burned', 'Black Fuse', 'Others', 'NG rate', 'Work time', 'Down Time'];
    const tr2crimping_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "C-AOI Height", "C-AOI Shift", 'Assembly', 'Machine Error', 'Weld plate', 'FPCB', 'ETC', 'NG Rate', 'Work time', 'Down Time'];
    const tr2fpca_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "FPCB Damage", "Too Much Acrylic", 'Too Less Acrylic', 'Bubble in Acrylic', 'Acryl Peel off', 'Holes in Solder', 'Too Much Solder', 'Too Less Solder', 'QR not Readable', 'EOL', 'Contamination', 'Others', 'NG Rate', 'Work time', 'Down Time'];
    const tr2bfa2p_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time'];
    const tr2bfa3p_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL Real', 'Coating', 'Cover Damage', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time'];
    const tr3smt_headers = ['Model', 'Target', 'Input', 'OK', 'NG', 'Fuse', 'Thermistor', 'Fiducial', 'QR Code', 'Burned', 'Black Fuse', 'Others', 'NG rate', 'Work time', 'Down Time'];
    const tr3crimping_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "C-AOI Height", "C-AOI Shift", 'Assembly', 'Machine Error', 'Weld plate', 'FPCB', 'ETC', 'NG Rate', 'Work time', 'Down Time'];
    const tr3fpca_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "FPCB Damage", "Too Much Acrylic", 'Too Less Acrylic', 'Bubble in Acrylic', 'Acryl Peel off', 'Holes in Solder', 'Too Much Solder', 'Too Less Solder', 'QR not Readable', 'EOL', 'Contamination', 'Others', 'NG Rate', 'Work time', 'Down Time'];
    const tr3bfa2p_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time'];
    const tr3bfa3p_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time'];
    const evo_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time'];
    const ev2020_60fr_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly", "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection', 'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Work time', 'Down Time'];
    const ev2020_4060fr_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly", "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection', 'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Work time', 'Down Time'];
    const ford1fr_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time'];
    const ford2fr_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time'];
    const ford3fr_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time'];
    const fca1_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Assembly", "Soldering", "AOI", "Coating", "EOL", 'Final', 'NG Rate', 'Fake Sum', 'Fake Assembly', 'Fake Soldering', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time'];
    const fca2_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Assembly", "Soldering", "AOI", "Coating", "EOL", 'Final', 'NG Rate', 'Fake Sum', 'Fake Assembly', 'Fake Soldering', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time'];
    const bt6_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Assembly", "Soldering", "Flatness", "EOL", "Vision", 'Final', 'NG Rate', 'Fake Sum', 'Fake Assembly', 'Fake Soldering', 'Fake Flatness', 'Fake EOL', 'Fake Vision', 'Fake AOI', 'Fake NG Rate', 'Work time', 'Down Time'];
    const bmw12v_headers = ['Model', 'Target', 'Input', 'OK', 'NG', "Lack of Components", "Coating too much", "Coating too less", 'Coating bubbles', 'Soldering too much', 'Soldering too less', 'Soldering balls', 'Lack of barcode', 'Damage barcode', 'Busbar scraches', 'Dirty busbar', 'Damage PCB', 'Dirty housing', 'Damage housing', 'EOL', 'OQC', 'NG Rate', 'Fake Sum', 'Fake Soldering', 'Fake Coating', 'Fake EOL', 'Fake Vision', 'Fake NG Rate', 'Work time', 'Down Time'];
    const bmw48v_headers = ['Model', 'Target', 'Input', 'OK Busbar assembly', 'NG Busbar assembly', 'OK PCS', "NG PCS", "OK Leak Test", 'NG Leak Test', 'OK Harness assembly', 'NG Harness assembly', 'OK EOL', 'NG EOL', 'OK Final', 'NG Final', 'NG SUM', 'NG Rate', 'Work time', 'Down Time'];   
    const fca_flex_bc_headers = ['Model', 'Target', 'Input', 'OK', 'NG', 'Broken Bracket', 'Damaged Busbar', 'Visible Copper', "Damaged Screw", "NG Rate", 'Fake Sum', 'Fake Broken Bracket', 'Fake Damaged Busbar', 'Fake Visible Copper', 'Fake Damaged Screw', 'Fake NG Rate', 'Work time', 'Down Time'];   
    const fca_flex_po_headers = ['Model', 'Target', 'Input', 'OK', 'NG', 'Damaged Cap', 'Damaged Flex', 'Damaged Tape', "Visible Copper", "NG Rate", 'Fake Sum', 'Assembly KLIP', 'Assembly Orange Tape', 'Assembly Black Tape', 'Assembly Silicon Cap', 'Assembly CAP +', 'Assembly CAP -', 'Assembly Band Cable', 'Lack KLIP', 'Lack Orange Tape', 'Lack Black Tape', 'Lack Silicon Cap', 'Lack CAP +', 'Lack CAP -', 'Lack Band Cable', 'Fake NG Rate', 'Work time', 'Down Time'];   
    const thermistortape_headers = ['Model', 'Target', 'Input', 'OK Total', 'OK A', 'OK B', 'OK C', 'NG', 'Cover Damage', "NG Rate", 'Work time', 'Down Time'];   
    const thermistorpad_headers = ['Model', 'Target', 'Input', 'OK Total', 'OK A', 'OK B', 'OK C', 'NG', 'FPCA Damage', 'Thermistor Damage', "NG Rate", 'Work time', 'Down Time'];   
    const bendingevo_headers = ['Model', 'Target', 'Input', 'OK Total', 'OK 3P H', 'OK 3P 2', 'OK 2P', 'NG Total', 'NG 3P', '3P FPCA Damage', '3P Coating', '3P Acrylic inside connector', 'NG 2P', '2P FPCA Damage', '2P Coating', '2P Acrylic inside connector', "NG Rate", 'Work time', 'Down Time'];   
  

    //각 설비별 Data를 받아올 함수 명을 입력해야 됨
    const targetList = reactive({ data: [], })
    const tr2smt_prTableData = reactive({ data: [] });
    const tr2smt_dtTableData = reactive({ data: [] });
    const tr2smt_qwTableData = reactive({ data: [] });
    const tr2crimping_prTableData = reactive({ data: [] });
    const tr2crimping_dtTableData = reactive({ data: [] });
    const tr2crimping_qwTableData = reactive({ data: [] });
    const tr2fpca_prTableData = reactive({ data: [] });
    const tr2fpca_dtTableData = reactive({ data: [] });
    const tr2fpca_qwTableData = reactive({ data: [] });
    const tr2bfa2p_prTableData = reactive({ data: [] });
    const tr2bfa2p_dtTableData = reactive({ data: [] });
    const tr2bfa2p_qwTableData = reactive({ data: [] });
    const tr2bfa3p_prTableData = reactive({ data: [] });
    const tr2bfa3p_dtTableData = reactive({ data: [] });
    const tr2bfa3p_qwTableData = reactive({ data: [] });
    const tr3smt_prTableData = reactive({ data: [] });
    const tr3smt_dtTableData = reactive({ data: [] });
    const tr3smt_qwTableData = reactive({ data: [] });
    const tr3crimping_prTableData = reactive({ data: [] });
    const tr3crimping_dtTableData = reactive({ data: [] });
    const tr3crimping_qwTableData = reactive({ data: [] });
    const tr3fpca_prTableData = reactive({ data: [] });
    const tr3fpca_dtTableData = reactive({ data: [] });
    const tr3fpca_qwTableData = reactive({ data: [] });
    const tr3bfa2p_prTableData = reactive({ data: [] });
    const tr3bfa2p_dtTableData = reactive({ data: [] });
    const tr3bfa2p_qwTableData = reactive({ data: [] });
    const tr3bfa3p_prTableData = reactive({ data: [] });
    const tr3bfa3p_dtTableData = reactive({ data: [] });
    const tr3bfa3p_qwTableData = reactive({ data: [] });
    const evo_prTableData = reactive({ data: [] });
    const evo_dtTableData = reactive({ data: [] });
    const evo_qwTableData = reactive({ data: [] });
    const ev2020_60fr_prTableData = reactive({ data: [] });
    const ev2020_60fr_dtTableData = reactive({ data: [] });
    const ev2020_60fr_qwTableData = reactive({ data: [] });
    const ev2020_60rr_prTableData = reactive({ data: [] });
    const ev2020_60rr_dtTableData = reactive({ data: [] });
    const ev2020_60rr_qwTableData = reactive({ data: [] });
    const ev2020_4060fr_prTableData = reactive({ data: [] });
    const ev2020_4060fr_dtTableData = reactive({ data: [] });
    const ev2020_4060fr_qwTableData = reactive({ data: [] });
    const ev2020_4060rr_prTableData = reactive({ data: [] });
    const ev2020_4060rr_dtTableData = reactive({ data: [] });
    const ev2020_4060rr_qwTableData = reactive({ data: [] });
    const ford1fr_prTableData = reactive({ data: [] });
    const ford1fr_dtTableData = reactive({ data: [] });
    const ford1fr_qwTableData = reactive({ data: [] });
    const ford1rr_prTableData = reactive({ data: [] });
    const ford1rr_dtTableData = reactive({ data: [] });
    const ford1rr_qwTableData = reactive({ data: [] });
    const ford2fr_prTableData = reactive({ data: [] });
    const ford2fr_dtTableData = reactive({ data: [] });
    const ford2fr_qwTableData = reactive({ data: [] });
    const ford2rr_prTableData = reactive({ data: [] });
    const ford2rr_dtTableData = reactive({ data: [] });
    const ford2rr_qwTableData = reactive({ data: [] });
    const ford3fr_prTableData = reactive({ data: [] });
    const ford3fr_dtTableData = reactive({ data: [] });
    const ford3fr_qwTableData = reactive({ data: [] });
    const ford3rr_prTableData = reactive({ data: [] });
    const ford3rr_dtTableData = reactive({ data: [] });
    const ford3rr_qwTableData = reactive({ data: [] });
    const fca1_prTableData = reactive({ data: [] });
    const fca1_dtTableData = reactive({ data: [] });
    const fca1_qwTableData = reactive({ data: [] });
    const fca2_prTableData = reactive({ data: [] });
    const fca2_dtTableData = reactive({ data: [] });
    const fca2_qwTableData = reactive({ data: [] });
    const bt6_prTableData = reactive({ data: [] });
    const bt6_dtTableData = reactive({ data: [] });
    const bt6_qwTableData = reactive({ data: [] });
    const bmw12v_prTableData = reactive({ data: [] });
    const bmw12v_dtTableData = reactive({ data: [] });
    const bmw12v_qwTableData = reactive({ data: [] });
    const bmw48v_prTableData = reactive({ data: [] });
    const bmw48v_dtTableData = reactive({ data: [] });
    const bmw48v_qwTableData = reactive({ data: [] });
    const fca_flex_bc_prTableData = reactive({ data: [] });
    const fca_flex_bc_dtTableData = reactive({ data: [] });
    const fca_flex_bc_qwTableData = reactive({ data: [] });
    const fca_flex_po_prTableData = reactive({ data: [] });
    const fca_flex_po_dtTableData = reactive({ data: [] });
    const fca_flex_po_qwTableData = reactive({ data: [] });
    const fca_flex_67_prTableData = reactive({ data: [] });
    const fca_flex_67_dtTableData = reactive({ data: [] });
    const fca_flex_67_qwTableData = reactive({ data: [] });
    const fca_flex_ne_prTableData = reactive({ data: [] });
    const fca_flex_ne_dtTableData = reactive({ data: [] });
    const fca_flex_ne_qwTableData = reactive({ data: [] });
    const fca_flex_45_prTableData = reactive({ data: [] });
    const fca_flex_45_dtTableData = reactive({ data: [] });
    const fca_flex_45_qwTableData = reactive({ data: [] });
    const thermistortape_prTableData = reactive({ data: [] });
    const thermistortape_dtTableData = reactive({ data: [] });
    const thermistorpad_prTableData = reactive({ data: [] });
    const thermistorpad_dtTableData = reactive({ data: [] });
    const bendingevo_prTableData = reactive({ data: [] });
    const bendingevo_dtTableData = reactive({ data: [] });

    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value]; return {sendDateData};};     
    const searchData = async (tableName) => {
        const sendDateData = await sendDate();
        console.log('Requesting data with:', {
            tablename: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endtYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        });
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        };  

        targetList.data = [];
        tr2smt_prTableData.data = [];
        tr2smt_dtTableData.data = [];
        tr2smt_qwTableData.data = [];
        tr2crimping_prTableData.data = [];
        tr2crimping_dtTableData.data = [];
        tr2crimping_qwTableData.data = [];
        tr2fpca_prTableData.data = [];
        tr2fpca_dtTableData.data = [];
        tr2fpca_qwTableData.data = [];
        tr2bfa2p_prTableData.data = [];
        tr2bfa2p_dtTableData.data = [];
        tr2bfa2p_qwTableData.data = [];
        tr2bfa3p_prTableData.data = [];
        tr2bfa3p_dtTableData.data = [];
        tr2bfa3p_qwTableData.data = [];
        tr3smt_prTableData.data = [];
        tr3smt_dtTableData.data = [];
        tr3smt_qwTableData.data = [];
        tr3crimping_prTableData.data = [];
        tr3crimping_dtTableData.data = [];
        tr3crimping_qwTableData.data = [];
        tr3fpca_prTableData.data = [];
        tr3fpca_dtTableData.data = [];
        tr3fpca_qwTableData.data = [];
        tr3bfa2p_prTableData.data = [];
        tr3bfa2p_dtTableData.data = [];
        tr3bfa2p_qwTableData.data = [];
        tr3bfa3p_prTableData.data = [];
        tr3bfa3p_dtTableData.data = [];
        tr3bfa3p_qwTableData.data = [];
        evo_prTableData.data = [];
        evo_dtTableData.data = [];
        evo_qwTableData.data = [];
        ev2020_60fr_prTableData.data = [];
        ev2020_60fr_dtTableData.data = [];
        ev2020_60fr_qwTableData.data = [];
        ev2020_60rr_prTableData.data = [];
        ev2020_60rr_dtTableData.data = [];
        ev2020_60rr_qwTableData.data = [];
        ev2020_4060fr_prTableData.data = [];
        ev2020_4060fr_dtTableData.data = [];
        ev2020_4060fr_qwTableData.data = [];
        ev2020_4060rr_prTableData.data = [];
        ev2020_4060rr_dtTableData.data = [];
        ev2020_4060rr_qwTableData.data = [];
        ford1fr_prTableData.data = [];
        ford1fr_dtTableData.data = [];
        ford1fr_qwTableData.data = [];
        ford1rr_prTableData.data = [];
        ford1rr_dtTableData.data = [];
        ford1rr_qwTableData.data = [];
        ford2fr_prTableData.data = [];
        ford2fr_dtTableData.data = [];
        ford2fr_qwTableData.data = [];
        ford2rr_prTableData.data = [];
        ford2rr_dtTableData.data = [];
        ford2rr_qwTableData.data = [];
        ford3fr_prTableData.data = [];
        ford3fr_dtTableData.data = [];
        ford3fr_qwTableData.data = [];
        ford3rr_prTableData.data = [];
        ford3rr_dtTableData.data = [];
        ford3rr_qwTableData.data = [];
        fca1_prTableData.data = [];
        fca1_dtTableData.data = [];
        fca1_qwTableData.data = [];
        fca2_prTableData.data = [];
        fca2_dtTableData.data = [];
        fca2_qwTableData.data = [];
        bt6_prTableData.data = [];
        bt6_dtTableData.data = [];
        bt6_qwTableData.data = [];
        bmw12v_prTableData.data = [];
        bmw12v_dtTableData.data = [];
        bmw12v_qwTableData.data = [];
        bmw48v_prTableData.data = [];
        bmw48v_dtTableData.data = [];
        bmw48v_qwTableData.data = [];
        fca_flex_bc_prTableData.data = [];
        fca_flex_bc_dtTableData.data = [];
        fca_flex_bc_qwTableData.data = [];
        fca_flex_po_prTableData.data = [];
        fca_flex_po_dtTableData.data = [];
        fca_flex_po_qwTableData.data = [];
        fca_flex_67_prTableData.data = [];
        fca_flex_67_dtTableData.data = [];
        fca_flex_67_qwTableData.data = [];
        fca_flex_ne_prTableData.data = [];
        fca_flex_ne_dtTableData.data = [];
        fca_flex_ne_qwTableData.data = [];
        fca_flex_45_prTableData.data = [];
        fca_flex_45_dtTableData.data = [];
        fca_flex_45_qwTableData.data = [];
        thermistortape_prTableData.data = [];
        thermistortape_dtTableData.data = [];
        thermistorpad_prTableData.data = [];
        thermistorpad_dtTableData.data = [];
        bendingevo_prTableData.data = [];
        bendingevo_dtTableData.data = [];

      console.log('Requesting data with:', formData);
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => {          
          switch (tableName) {
            //각 설비별로 DB table 이름과 변수 이름을 입력해야 됨
            case 'tr2smtpr': tr2smt_prTableData.data = res.data; break;
            case 'tr2smtdt': tr2smt_dtTableData.data = res.data; break;                          
            case 'tr2smtqw': tr2smt_qwTableData.data = res.data; break;          
            case 'tr2crimpingpr': tr2crimping_prTableData.data = res.data; break;
            case 'tr2crimpingdt': tr2crimping_dtTableData.data = res.data; break;                          
            case 'tr2crimpingqw': tr2crimping_qwTableData.data = res.data; break;
            case 'tr2fpcapr': tr2fpca_prTableData.data = res.data; break;
            case 'tr2fpcadt': tr2fpca_dtTableData.data = res.data; break;                          
            case 'tr2fpcaqw': tr2fpca_qwTableData.data = res.data; break;
            case 'tr2bfa2ppr': tr2bfa2p_prTableData.data = res.data; break;
            case 'tr2bfa2pdt': tr2bfa2p_dtTableData.data = res.data; break;                          
            case 'tr2bfa2pqw': tr2bfa2p_qwTableData.data = res.data; break;
            case 'tr2bfa3ppr': tr2bfa3p_prTableData.data = res.data; break;
            case 'tr2bfa3pdt': tr2bfa3p_dtTableData.data = res.data; break;                          
            case 'tr2bfa3pqw': tr2bfa3p_qwTableData.data = res.data; break;   
            case 'tr3smtpr': tr3smt_prTableData.data = res.data; break;
            case 'tr3smtdt': tr3smt_dtTableData.data = res.data; break;                          
            case 'tr3smtqw': tr3smt_qwTableData.data = res.data; break;          
            case 'tr3crimpingpr': tr3crimping_prTableData.data = res.data; break;
            case 'tr3crimpingdt': tr3crimping_dtTableData.data = res.data; break;                          
            case 'tr3crimpingqw': tr3crimping_qwTableData.data = res.data; break;
            case 'tr3fpcapr': tr3fpca_prTableData.data = res.data; break;
            case 'tr3fpcadt': tr3fpca_dtTableData.data = res.data; break;                          
            case 'tr3fpcaqw': tr3fpca_qwTableData.data = res.data; break;
            case 'tr3bfa2ppr': tr3bfa2p_prTableData.data = res.data; break;
            case 'tr3bfa2pdt': tr3bfa2p_dtTableData.data = res.data; break;                          
            case 'tr3bfa2pqw': tr3bfa2p_qwTableData.data = res.data; break;
            case 'tr3bfa3ppr': tr3bfa3p_prTableData.data = res.data; break;
            case 'tr3bfa3pdt': tr3bfa3p_dtTableData.data = res.data; break;                          
            case 'tr3bfa3pqw': tr3bfa3p_qwTableData.data = res.data; break;   
            case 'evopr': evo_prTableData.data = res.data; break;
            case 'evodt': evo_dtTableData.data = res.data; break;                          
            case 'evoqw': evo_qwTableData.data = res.data; break;   
            case 'ev2020_60frpr': ev2020_60fr_prTableData.data = res.data; break;
            case 'ev2020_60frdt': ev2020_60fr_dtTableData.data = res.data; break;                          
            case 'ev2020_60frqw': ev2020_60fr_qwTableData.data = res.data; break;   
            case 'ev2020_60rrpr': ev2020_60rr_prTableData.data = res.data; break;
            case 'ev2020_60rrdt': ev2020_60rr_dtTableData.data = res.data; break;                          
            case 'ev2020_60rrqw': ev2020_60rr_qwTableData.data = res.data; break;     
            case 'ev2020_4060frpr': ev2020_4060fr_prTableData.data = res.data; break;
            case 'ev2020_4060frdt': ev2020_4060fr_dtTableData.data = res.data; break;                          
            case 'ev2020_4060frqw': ev2020_4060fr_qwTableData.data = res.data; break;   
            case 'ev2020_4060rrpr': ev2020_4060rr_prTableData.data = res.data; break;
            case 'ev2020_4060rrdt': ev2020_4060rr_dtTableData.data = res.data; break;                          
            case 'ev2020_4060rrqw': ev2020_4060rr_qwTableData.data = res.data; break;            
            case 'ford1frpr': ford1fr_prTableData.data = res.data; break;
            case 'ford1frdt': ford1fr_dtTableData.data = res.data; break;                          
            case 'ford1frqw': ford1fr_qwTableData.data = res.data; break;      
            case 'ford1rrpr': ford1rr_prTableData.data = res.data; break;
            case 'ford1rrdt': ford1rr_dtTableData.data = res.data; break;                          
            case 'ford1rrqw': ford1rr_qwTableData.data = res.data; break;    
            case 'ford2frpr': ford2fr_prTableData.data = res.data; break;
            case 'ford2frdt': ford2fr_dtTableData.data = res.data; break;                          
            case 'ford2frqw': ford2fr_qwTableData.data = res.data; break;    
            case 'ford2rrpr': ford2rr_prTableData.data = res.data; break;
            case 'ford2rrdt': ford2rr_dtTableData.data = res.data; break;                          
            case 'ford2rrqw': ford2rr_qwTableData.data = res.data; break;    
            case 'ford3frpr': ford3fr_prTableData.data = res.data; break;
            case 'ford3frdt': ford3fr_dtTableData.data = res.data; break;                          
            case 'ford3frqw': ford3fr_qwTableData.data = res.data; break;    
            case 'ford3rrpr': ford3rr_prTableData.data = res.data; break;
            case 'ford3rrdt': ford3rr_dtTableData.data = res.data; break;                          
            case 'ford3rrqw': ford3rr_qwTableData.data = res.data; break;  
            case 'fca1pr': fca1_prTableData.data = res.data; break;
            case 'fca1dt': fca1_dtTableData.data = res.data; break;                          
            case 'fca1qw': fca1_qwTableData.data = res.data; break;    
            case 'fca2pr': fca2_prTableData.data = res.data; break;
            case 'fca2dt': fca2_dtTableData.data = res.data; break;                          
            case 'fca2qw': fca2_qwTableData.data = res.data; break;   
            case 'bt6pr': bt6_prTableData.data = res.data; break;
            case 'bt6dt': bt6_dtTableData.data = res.data; break;                          
            case 'bt6qw': bt6_qwTableData.data = res.data; break;     
            case 'bmw12vpr': bmw12v_prTableData.data = res.data; break;
            case 'bmw12vdt': bmw12v_dtTableData.data = res.data; break;                          
            case 'bmw12vqw': bmw12v_qwTableData.data = res.data; break;    
            case 'bmw48vpr': bmw48v_prTableData.data = res.data; break;
            case 'bmw48vdt': bmw48v_dtTableData.data = res.data; break;                          
            case 'bmw48vqw': bmw48v_qwTableData.data = res.data; break;    
            case 'fca_flex_bcpr': fca_flex_bc_prTableData.data = res.data; break;
            case 'fca_flex_bcdt': fca_flex_bc_dtTableData.data = res.data; break;                          
            case 'fca_flex_bcqw': fca_flex_bc_qwTableData.data = res.data; break;    
            case 'fca_flex_popr': fca_flex_po_prTableData.data = res.data; break;
            case 'fca_flex_podt': fca_flex_po_dtTableData.data = res.data; break;                          
            case 'fca_flex_poqw': fca_flex_po_qwTableData.data = res.data; break;    
            case 'fca_flex_67pr': fca_flex_67_prTableData.data = res.data; break;
            case 'fca_flex_67dt': fca_flex_67_dtTableData.data = res.data; break;                          
            case 'fca_flex_67qw': fca_flex_67_qwTableData.data = res.data; break;    
            case 'fca_flex_nepr': fca_flex_ne_prTableData.data = res.data; break;
            case 'fca_flex_nedt': fca_flex_ne_dtTableData.data = res.data; break;                          
            case 'fca_flex_neqw': fca_flex_ne_qwTableData.data = res.data; break;    
            case 'fca_flex_45pr': fca_flex_45_prTableData.data = res.data; break;
            case 'fca_flex_45dt': fca_flex_45_dtTableData.data = res.data; break;                          
            case 'fca_flex_45qw': fca_flex_45_qwTableData.data = res.data; break;
            case 'thermistortapepr': thermistortape_prTableData.data = res.data; break;    
            case 'thermistortapedt': thermistortape_dtTableData.data = res.data; break;    
            case 'thermistorpadpr': thermistorpad_prTableData.data = res.data; break;    
            case 'thermistorpaddt': thermistorpad_dtTableData.data = res.data; break;    
            case 'bendingevopr': bendingevo_prTableData.data = res.data; break;    
            case 'bendingevodt': bendingevo_dtTableData.data = res.data; break;    
          }
        });
    };

    const targetSearchData = async () => { //여기서는 사용 안함
          axios.get("/api/targetList").then((res) => {
          console.log("Response from /api/target:", res.data);
          targetList.data = res.data
          })
          .catch((error) => {
            console.error("Error fetching target data:", error.message);
          });
      }

    const callTarget = (lineName) => {return targetItem.value[lineName] || {};}; //여기서는 사용 안함

    //각 설비별 Data를 호출하는 것을 입력해야 됨
    
    const tableNames = [  
      tr2smt_dbTableName, tr2smt_dtTableName, tr2smt_qwTableName, 
      tr2crimping_dbTableName, tr2crimping_dtTableName, tr2crimping_qwTableName,
      tr2fpca_dbTableName, tr2fpca_dtTableName, tr2fpca_qwTableName,
      tr2bfa2p_dbTableName, tr2bfa2p_dtTableName, tr2bfa2p_qwTableName,
      tr2bfa3p_dbTableName, tr2bfa3p_dtTableName, tr2bfa3p_qwTableName,
      tr3smt_dbTableName, tr3smt_dtTableName, tr3smt_qwTableName,
      tr3crimping_dbTableName, tr3crimping_dtTableName, tr3crimping_qwTableName,
      tr3fpca_dbTableName, tr3fpca_dtTableName, tr3fpca_qwTableName,
      tr3bfa2p_dbTableName, tr3bfa2p_dtTableName, tr3bfa2p_qwTableName,
      tr3bfa3p_dbTableName, tr3bfa3p_dtTableName, tr3bfa3p_qwTableName,
      evo_dbTableName, evo_dtTableName, evo_qwTableName,
      ev2020_60fr_dbTableName, ev2020_60fr_dtTableName, ev2020_60fr_qwTableName,
      ev2020_60rr_dbTableName, ev2020_60rr_dtTableName, ev2020_60rr_qwTableName,
      ev2020_4060fr_dbTableName, ev2020_4060fr_dtTableName, ev2020_4060fr_qwTableName,
      ev2020_4060rr_dbTableName, ev2020_4060rr_dtTableName, ev2020_4060rr_qwTableName,
      ford1fr_dbTableName, ford1fr_dtTableName, ford1fr_qwTableName,
      ford1rr_dbTableName, ford1rr_dtTableName, ford1rr_qwTableName,
      ford2fr_dbTableName, ford2fr_dtTableName, ford2fr_qwTableName,
      ford2rr_dbTableName, ford2rr_dtTableName, ford2rr_qwTableName,
      ford3fr_dbTableName, ford3fr_dtTableName, ford3fr_qwTableName,
      ford3rr_dbTableName, ford3rr_dtTableName, ford3rr_qwTableName,
      fca1_dbTableName, fca1_dtTableName, fca1_qwTableName,
      fca2_dbTableName, fca2_dtTableName, fca2_qwTableName,
      bt6_dbTableName, bt6_dtTableName, bt6_qwTableName,
      bmw12v_dbTableName, bmw12v_dtTableName, bmw12v_qwTableName,
      bmw48v_dbTableName, bmw48v_dtTableName, bmw48v_qwTableName,
      fca_flex_bc_dbTableName, fca_flex_bc_dtTableName, fca_flex_bc_qwTableName,
      fca_flex_po_dbTableName, fca_flex_po_dtTableName, fca_flex_po_qwTableName,
      fca_flex_67_dbTableName, fca_flex_67_dtTableName, fca_flex_67_qwTableName,
      fca_flex_ne_dbTableName, fca_flex_ne_dtTableName, fca_flex_ne_qwTableName,
      fca_flex_45_dbTableName, fca_flex_45_dtTableName, fca_flex_45_qwTableName,
      thermistortape_dbTableName, thermistortape_dtTableName,
      thermistorpad_dbTableName, thermistorpad_dtTableName,
      bendingevo_dbTableName, bendingevo_dtTableName,
    ];

    const settingData = reactive({ data: [], });      

    const callSearchData = async () => {         
          const tableName = 'setting';
          const formData = { tableName: tableName };

          try {
              const res = await axios.get('/api/targetList', { params: formData });
              settingData.data = res.data;

              const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));  
              const isReportEnabled = reportSetting && reportSetting.report === 1;
              if (!isReportEnabled) {
                  alert("Report is currently disabled.");
                  return;
              }

              targetSearchData();
              for (const tableName of tableNames) {
                try {
                  await searchData(tableName);
                } catch (error) {
                  console.error(`Error loading data from ${tableName}:`, error);      
                }
              }                    
          
          } catch (error) {
              console.error("Error download excel data:", error.message);
          }
    };     

    const calculateInputAndNgRate = (ok, ng) => { const input = ok + ng; return { input }; };
    const thermistorTapePadOkNg = (ok_a, ok_b, ok_c, ng, ) => { const input = ok_a + ok_b + ok_c + ng; const oktotal = ok_a + ok_b + ok_c; return { input, oktotal,};};
    const bendingEvoOkNg = (ok_a, ok_b, ok_c, ng_3p, ng_2p ) => {
      const input = ok_a + ok_b + ok_c + ng_3p + ng_2p;  const oktotal = ok_a + ok_b + ok_c; const ngtotal = ng_3p + ng_2p;  return { input, oktotal, ngtotal, };
    };

    //각 설비별로 item 이름과 변수 이름을 입력해 줘야 함
    const targetItem = computed(() => {const items = {}; targetList.data.forEach((d) => {items[d.line_name] = d;});return items;});
    const tr2smt_item = computed(() => { return tr2smt_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr2smt_dtItem = computed(() => {return tr2smt_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2smt_qwItem = computed(() => {return tr2smt_qwTableData.data.map(d => {return { ...d,};});});    
    const tr2crimping_item = computed(() => { return tr2crimping_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr2crimping_dtItem = computed(() => {return tr2crimping_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2crimping_qwItem = computed(() => {return tr2crimping_qwTableData.data.map(d => {return { ...d,};});});    
    const tr2fpca_item = computed(() => { return tr2fpca_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr2fpca_dtItem = computed(() => {return tr2fpca_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2fpca_qwItem = computed(() => {return tr2fpca_qwTableData.data.map(d => {return { ...d,};});});
    const tr2bfa2p_item = computed(() => { return tr2bfa2p_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr2bfa2p_dtItem = computed(() => {return tr2bfa2p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2bfa2p_qwItem = computed(() => {return tr2bfa2p_qwTableData.data.map(d => {return { ...d,};});});
    const tr2bfa3p_item = computed(() => { return tr2bfa3p_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr2bfa3p_dtItem = computed(() => {return tr2bfa3p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2bfa3p_qwItem = computed(() => {return tr2bfa3p_qwTableData.data.map(d => {return { ...d,};});}); 
    const tr3smt_item = computed(() => { return tr3smt_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr3smt_dtItem = computed(() => {return tr3smt_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3smt_qwItem = computed(() => {return tr3smt_qwTableData.data.map(d => {return { ...d,};});});    
    const tr3crimping_item = computed(() => { return tr3crimping_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr3crimping_dtItem = computed(() => {return tr3crimping_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3crimping_qwItem = computed(() => {return tr3crimping_qwTableData.data.map(d => {return { ...d,};});});    
    const tr3fpca_item = computed(() => { return tr3fpca_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr3fpca_dtItem = computed(() => {return tr3fpca_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3fpca_qwItem = computed(() => {return tr3fpca_qwTableData.data.map(d => {return { ...d,};});});
    const tr3bfa2p_item = computed(() => { return tr3bfa2p_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr3bfa2p_dtItem = computed(() => {return tr3bfa2p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3bfa2p_qwItem = computed(() => {return tr3bfa2p_qwTableData.data.map(d => {return { ...d,};});});
    const tr3bfa3p_item = computed(() => { return tr3bfa3p_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const tr3bfa3p_dtItem = computed(() => {return tr3bfa3p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3bfa3p_qwItem = computed(() => {return tr3bfa3p_qwTableData.data.map(d => {return { ...d,};});});    
    const evo_item = computed(() => { return evo_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const evo_dtItem = computed(() => {return evo_dtTableData.data.map(d => {return { ...d,};});});    
    const evo_qwItem = computed(() => {return evo_qwTableData.data.map(d => {return { ...d,};});});    
    const ev2020_60fr_item = computed(() => { return ev2020_60fr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ev2020_60fr_dtItem = computed(() => {return ev2020_60fr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_60fr_qwItem = computed(() => {return ev2020_60fr_qwTableData.data.map(d => {return { ...d,};});});    
    const ev2020_60rr_item = computed(() => { return ev2020_60rr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ev2020_60rr_dtItem = computed(() => {return ev2020_60rr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_60rr_qwItem = computed(() => {return ev2020_60rr_qwTableData.data.map(d => {return { ...d,};});});    
    const ev2020_4060fr_item = computed(() => { return ev2020_4060fr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ev2020_4060fr_dtItem = computed(() => {return ev2020_4060fr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_4060fr_qwItem = computed(() => {return ev2020_4060fr_qwTableData.data.map(d => {return { ...d,};});});    
    const ev2020_4060rr_item = computed(() => { return ev2020_4060rr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ev2020_4060rr_dtItem = computed(() => {return ev2020_4060rr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_4060rr_qwItem = computed(() => {return ev2020_4060rr_qwTableData.data.map(d => {return { ...d,};});});    
    const ford1fr_item = computed(() => { return ford1fr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ford1fr_dtItem = computed(() => {return ford1fr_dtTableData.data.map(d => {return { ...d,};});});    
    //const ford1fr_qwItem = computed(() => {return ford1fr_qwTableData.data.map(d => {return { ...d,};});});    
    const ford1rr_item = computed(() => { return ford1rr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ford1rr_dtItem = computed(() => {return ford1rr_dtTableData.data.map(d => {return { ...d,};});});    
    //const ford1rr_qwItem = computed(() => {return ford1rr_qwTableData.data.map(d => {return { ...d,};});});    
    const ford2fr_item = computed(() => { return ford2fr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ford2fr_dtItem = computed(() => {return ford2fr_dtTableData.data.map(d => {return { ...d,};});});    
    //const ford2fr_qwItem = computed(() => {return ford2fr_qwTableData.data.map(d => {return { ...d,};});});    
    const ford2rr_item = computed(() => { return ford2rr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ford2rr_dtItem = computed(() => {return ford2rr_dtTableData.data.map(d => {return { ...d,};});});    
    //const ford2rr_qwItem = computed(() => {return ford2rr_qwTableData.data.map(d => {return { ...d,};});});    
    const ford3fr_item = computed(() => { return ford3fr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ford3fr_dtItem = computed(() => {return ford3fr_dtTableData.data.map(d => {return { ...d,};});});    
    //const ford3fr_qwItem = computed(() => {return ford3fr_qwTableData.data.map(d => {return { ...d,};});});    
    const ford3rr_item = computed(() => { return ford3rr_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const ford3rr_dtItem = computed(() => {return ford3rr_dtTableData.data.map(d => {return { ...d,};});});    
    //const ford3rr_qwItem = computed(() => {return ford3rr_qwTableData.data.map(d => {return { ...d,};});});    
    const fca1_item = computed(() => { return fca1_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const fca1_dtItem = computed(() => {return fca1_dtTableData.data.map(d => {return { ...d,};});});    
    const fca1_qwItem = computed(() => {return fca1_qwTableData.data.map(d => {return { ...d,};});});    
    const fca2_item = computed(() => { return fca2_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const fca2_dtItem = computed(() => {return fca2_dtTableData.data.map(d => {return { ...d,};});});    
    const fca2_qwItem = computed(() => {return fca2_qwTableData.data.map(d => {return { ...d,};});});    
    const bt6_item = computed(() => { return bt6_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const bt6_dtItem = computed(() => {return bt6_dtTableData.data.map(d => {return { ...d,};});});    
    const bt6_qwItem = computed(() => {return bt6_qwTableData.data.map(d => {return { ...d,};});});    
    const bmw12v_item = computed(() => { return bmw12v_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const bmw12v_dtItem = computed(() => {return bmw12v_dtTableData.data.map(d => {return { ...d,};});});    
    const bmw12v_qwItem = computed(() => {return bmw12v_qwTableData.data.map(d => {return { ...d,};});});    
    const bmw48v_item = computed(() => { return bmw48v_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const bmw48v_dtItem = computed(() => {return bmw48v_dtTableData.data.map(d => {return { ...d,};});});    
    const bmw48v_qwItem = computed(() => {return bmw48v_qwTableData.data.map(d => {return { ...d,};});}); 
    const fca_flex_bc_item = computed(() => { return fca_flex_bc_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const fca_flex_bc_dtItem = computed(() => {return fca_flex_bc_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_bc_qwItem = computed(() => {return fca_flex_bc_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_po_item = computed(() => { return fca_flex_po_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const fca_flex_po_dtItem = computed(() => {return fca_flex_po_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_po_qwItem = computed(() => {return fca_flex_po_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_67_item = computed(() => { return fca_flex_67_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const fca_flex_67_dtItem = computed(() => {return fca_flex_67_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_67_qwItem = computed(() => {return fca_flex_67_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_ne_item = computed(() => { return fca_flex_ne_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const fca_flex_ne_dtItem = computed(() => {return fca_flex_ne_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_ne_qwItem = computed(() => {return fca_flex_ne_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_45_item = computed(() => { return fca_flex_45_prTableData.data.map(d => { const { input } = calculateInputAndNgRate(d.ok, d.ng); return { ...d, input }; });});
    const fca_flex_45_dtItem = computed(() => {return fca_flex_45_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_45_qwItem = computed(() => {return fca_flex_45_qwTableData.data.map(d => {return { ...d,};});});  
    const thermistortape_item = computed(() => { return thermistortape_prTableData.data.map(d => { const { input, oktotal } = thermistorTapePadOkNg(d.ok_a, d.ok_b, d.ok_c, d.ng); return { ...d, input, oktotal }; });});
    const thermistortape_dtItem = computed(() => { return thermistortape_dtTableData.data.map(d => {return { ...d,};});});
    const thermistorpad_item = computed(() => { return thermistorpad_prTableData.data.map(d => { const { input, oktotal } = thermistorTapePadOkNg(d.ok_a, d.ok_b, d.ok_c, d.ng); return { ...d, input, oktotal }; });});
    const thermistorpad_dtItem = computed(() => { return thermistorpad_dtTableData.data.map(d => {return { ...d,};});});
    const bendingevo_item = computed(() => { return bendingevo_prTableData.data.map(d => { const { input, oktotal, ngtotal } = bendingEvoOkNg(d.ok_a, d.ok_b, d.ok_c, d.ng_3p, d.ng_2p); return { ...d, input, oktotal, ngtotal }; });});
    const bendingevo_dtItem = computed(() => { return bendingevo_dtTableData.data.map(d => {return { ...d,};});});

    const sums = computed(() => {
        //각 설비별 "let 함수 이름"을 만들고 모델 이름과 가져올 Data를 초기화 해야 함
        //TR2 SMT
        let tr2smtpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0};  
        let tr2smtpr_modelSums = {
            "2P12S": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},    
            "EVO_2P": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},    
            "EVO_3P": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},               
        };
        let tr2smtdt_Sums = { time : 0 }
        let tr2smtdt_modelSums = { "2P12S": { time: 0}, "3P8S": { time: 0}, "EVO_2P": { time: 0}, "EVO_3P": { time: 0},};
        let tr2smtqw_Sums = {ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0}
        let tr2smtqw_modelSums = {
            "2P12S": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0},    
            "3P8S": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0}, 
            "EVO_2P": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0},    
            "EVO_3P": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0},     
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr2smt_item.value) {            
            tr2smtpr_Sums.target += row.target;
            tr2smtpr_Sums.input += row.input;
            tr2smtpr_Sums.ok += row.ok;
            tr2smtpr_Sums.ng += row.ng;
            tr2smtpr_Sums.ng_fuse += row.ng_fuse;
            tr2smtpr_Sums.ng_thermistor += row.ng_thermistor;
            tr2smtpr_Sums.ng_fiducial += row.ng_fiducial;
            tr2smtpr_Sums.ng_qrcode += row.ng_qrcode;
            tr2smtpr_Sums.ng_burned += row.ng_burned;
            tr2smtpr_Sums.ng_blackfuse += row.ng_blackfuse;
            tr2smtpr_Sums.ng_others += row.ng_others;
            tr2smtpr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr2smt_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr2smtpr_modelSums, row.model)) {
                tr2smtpr_modelSums[row.model].target += row.target;
                tr2smtpr_modelSums[row.model].input += row.input;
                tr2smtpr_modelSums[row.model].ok += row.ok;
                tr2smtpr_modelSums[row.model].ng += row.ng;
                tr2smtpr_modelSums[row.model].ng_fuse += row.ng_fuse;
                tr2smtpr_modelSums[row.model].ng_thermistor += row.ng_thermistor;
                tr2smtpr_modelSums[row.model].ng_fiducial += row.ng_fiducial;
                tr2smtpr_modelSums[row.model].ng_qrcode += row.ng_qrcode;
                tr2smtpr_modelSums[row.model].ng_burned += row.ng_burned;
                tr2smtpr_modelSums[row.model].ng_blackfuse += row.ng_blackfuse;
                tr2smtpr_modelSums[row.model].ng_others += row.ng_others;
                tr2smtpr_modelSums[row.model].work_minute += row.work_minute;
            }
        }

        for (const row of tr2smt_dtItem.value) { tr2smtdt_Sums.time += row.time;  }
        for (const row of tr2smt_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2smtdt_modelSums, row.model)) {
                tr2smtdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr2smt_qwItem.value) {
            tr2smtqw_Sums.ok += row.ok;                
            tr2smtqw_Sums.ng += row.ng;
            tr2smtqw_Sums.ng_fuse += row.ng_fuse; 
            tr2smtqw_Sums.ng_thermistor += row.ng_thermistor;
            tr2smtqw_Sums.ng_fiducial += row.ng_fiducial;
            tr2smtqw_Sums.ng_qrcode += row.ng_qrcode;
            tr2smtqw_Sums.ng_burned += row.ng_burned;
            tr2smtqw_Sums.ng_blackfuse += row.ng_blackfuse;
            tr2smtqw_Sums.ng_others += row.ng_others;   
        
        }
        for (const row of tr2smt_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2smtqw_modelSums, row.model)) {
                tr2smtqw_modelSums[row.model].ok += row.ok;                
                tr2smtqw_modelSums[row.model].ng += row.ng;
                tr2smtqw_modelSums[row.model].ng_fuse += row.ng_fuse; 
                tr2smtqw_modelSums[row.model].ng_thermistor += row.ng_thermistor;
                tr2smtqw_modelSums[row.model].ng_fiducial += row.ng_fiducial;
                tr2smtqw_modelSums[row.model].ng_qrcode += row.ng_qrcode;
                tr2smtqw_modelSums[row.model].ng_burned += row.ng_burned;
                tr2smtqw_modelSums[row.model].ng_blackfuse += row.ng_blackfuse;
                tr2smtqw_modelSums[row.model].ng_others += row.ng_others;                
            }
        }
        //TR2 Crimping
        let tr2crimpingpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0 };  
        let tr2crimpingpr_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},    
            "EVO_2P": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},    
            "EVO_3P": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},               
        };
        let tr2crimpingdt_Sums = { time : 0 }
        let tr2crimpingdt_modelSums = { "2P12S": { time: 0}, "3P8S": { time: 0}, "EVO_2P": { time: 0}, "EVO_3P": { time: 0},};
        let tr2crimpingqw_Sums = { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, }
        let tr2crimpingqw_modelSums = {
            "2P12S": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, },    
            "3P8S": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, }, 
            "EVO_2P": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, },    
            "EVO_3P": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, },     
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr2crimping_item.value) {            
            tr2crimpingpr_Sums.target += row.target;
            tr2crimpingpr_Sums.input += row.input;
            tr2crimpingpr_Sums.ok += row.ok;
            tr2crimpingpr_Sums.ng += row.ng;
            tr2crimpingpr_Sums.ng_caoiheight += row.ng_caoiheight;
            tr2crimpingpr_Sums.ng_caoishift += row.ng_caoishift;
            tr2crimpingpr_Sums.ng_assembly += row.ng_assembly;
            tr2crimpingpr_Sums.ng_machineerror += row.ng_machineerror;
            tr2crimpingpr_Sums.ng_weldplate += row.ng_weldplate;
            tr2crimpingpr_Sums.ng_fpcb += row.ng_fpcb;
            tr2crimpingpr_Sums.ng_etc += row.ng_etc;
            tr2crimpingpr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr2crimping_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr2crimpingpr_modelSums, row.model)) {
                tr2crimpingpr_modelSums[row.model].target += row.target;
                tr2crimpingpr_modelSums[row.model].input += row.input;
                tr2crimpingpr_modelSums[row.model].ok += row.ok;
                tr2crimpingpr_modelSums[row.model].ng += row.ng;                     
                tr2crimpingpr_modelSums[row.model].ng_caoiheight += row.ng_caoiheight;
                tr2crimpingpr_modelSums[row.model].ng_caoishift += row.ng_caoishift;
                tr2crimpingpr_modelSums[row.model].ng_assembly += row.ng_assembly;
                tr2crimpingpr_modelSums[row.model].ng_machineerror += row.ng_machineerror;
                tr2crimpingpr_modelSums[row.model].ng_weldplate += row.ng_weldplate;
                tr2crimpingpr_modelSums[row.model].ng_fpcb += row.ng_fpcb;
                tr2crimpingpr_modelSums[row.model].ng_etc += row.ng_etc;
                tr2crimpingpr_modelSums[row.model].work_minute += row.work_minute;
            }
        }
        for (const row of tr2crimping_dtItem.value) { tr2crimpingdt_Sums.time += row.time;  }
        for (const row of tr2crimping_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2crimpingdt_modelSums, row.model)) {
                tr2crimpingdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr2crimping_qwItem.value) {
            tr2crimpingqw_Sums.ok += row.ok;
            tr2crimpingqw_Sums.ng += row.ng;
            tr2crimpingqw_Sums.ng_caoiheight += row.ng_caoiheight;
            tr2crimpingqw_Sums.ng_caoishift += row.ng_caoishift;
            tr2crimpingqw_Sums.ng_assembly += row.ng_assembly;
            tr2crimpingqw_Sums.ng_machineerror += row.ng_machineerror;
            tr2crimpingqw_Sums.ng_weldplate += row.ng_weldplate;
            tr2crimpingqw_Sums.ng_fpcb += row.ng_fpcb;
            tr2crimpingqw_Sums.ng_etc += row.ng_etc;
            tr2crimpingqw_Sums.work_minute += row.work_minute;
        
        }
        for (const row of tr2crimping_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2crimpingqw_modelSums, row.model)) {
                tr2crimpingqw_modelSums[row.model].ok += row.ok;
                tr2crimpingqw_modelSums[row.model].ng += row.ng;                     
                tr2crimpingqw_modelSums[row.model].ng_caoiheight += row.ng_caoiheight;
                tr2crimpingqw_modelSums[row.model].ng_caoishift += row.ng_caoishift;
                tr2crimpingqw_modelSums[row.model].ng_assembly += row.ng_assembly;
                tr2crimpingqw_modelSums[row.model].ng_machineerror += row.ng_machineerror;
                tr2crimpingqw_modelSums[row.model].ng_weldplate += row.ng_weldplate;
                tr2crimpingqw_modelSums[row.model].ng_fpcb += row.ng_fpcb;
                tr2crimpingqw_modelSums[row.model].ng_etc += row.ng_etc;
                tr2crimpingqw_modelSums[row.model].work_minute += row.work_minute;           
            }
        }
        //TR2 FPCA        
        let tr2fpcapr_Sums = { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0 };  
        let tr2fpcapr_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},    
            "EVO_2P": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},    
            "EVO_3P": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},               
        };
        let tr2fpcadt_Sums = { time : 0 }
        let tr2fpcadt_modelSums = { "2P12S": { time: 0}, "3P8S": { time: 0}, "EVO_2P": { time: 0}, "EVO_3P": { time: 0},};
        let tr2fpcaqw_Sums = { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, }
        let tr2fpcaqw_modelSums = {
            "2P12S": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, },    
            "3P8S": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, }, 
            "EVO_2P": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, },    
            "EVO_3P": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, },     
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr2fpca_item.value) {            
            tr2fpcapr_Sums.target += row.target;
            tr2fpcapr_Sums.input += row.input;
            tr2fpcapr_Sums.ok += row.ok;
            tr2fpcapr_Sums.ve_acrylicverify += row.ve_acrylicverify;
            tr2fpcapr_Sums.ng += row.ng;
            tr2fpcapr_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr2fpcapr_Sums.ng_toomuchacrylic += row.ng_toomuchacrylic;
            tr2fpcapr_Sums.ng_toolessacrylic += row.ng_toolessacrylic;
            tr2fpcapr_Sums.ng_bubbleinacrylic += row.ng_bubbleinacrylic;
            tr2fpcapr_Sums.ng_acrylicpeeloff += row.ng_acrylicpeeloff;
            tr2fpcapr_Sums.ng_holesinsolder += row.ng_holesinsolder;
            tr2fpcapr_Sums.ng_toomuchsolder += row.ng_toomuchsolder;
            tr2fpcapr_Sums.ng_toolesssolder += row.ng_toolesssolder;
            tr2fpcapr_Sums.ng_qrnotreadable += row.ng_qrnotreadable;
            tr2fpcapr_Sums.ng_eolreal += row.ng_eolreal;
            tr2fpcapr_Sums.ng_contamination += row.ng_contamination;
            tr2fpcapr_Sums.ng_others += row.ng_others;
            tr2fpcapr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr2fpca_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr2fpcapr_modelSums, row.model)) {
                tr2fpcapr_modelSums[row.model].target += row.target;
                tr2fpcapr_modelSums[row.model].input += row.input;
                tr2fpcapr_modelSums[row.model].ok += row.ok;
                tr2fpcapr_modelSums[row.model].ve_acrylicverify += row.ve_acrylicverify;
                tr2fpcapr_modelSums[row.model].ng += row.ng;
                tr2fpcapr_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr2fpcapr_modelSums[row.model].ng_toomuchacrylic += row.ng_toomuchacrylic;
                tr2fpcapr_modelSums[row.model].ng_toolessacrylic += row.ng_toolessacrylic;
                tr2fpcapr_modelSums[row.model].ng_bubbleinacrylic += row.ng_bubbleinacrylic;
                tr2fpcapr_modelSums[row.model].ng_acrylicpeeloff += row.ng_acrylicpeeloff;
                tr2fpcapr_modelSums[row.model].ng_holesinsolder += row.ng_holesinsolder;
                tr2fpcapr_modelSums[row.model].ng_toomuchsolder += row.ng_toomuchsolder;
                tr2fpcapr_modelSums[row.model].ng_toolesssolder += row.ng_toolesssolder;
                tr2fpcapr_modelSums[row.model].ng_qrnotreadable += row.ng_qrnotreadable;
                tr2fpcapr_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr2fpcapr_modelSums[row.model].ng_contamination += row.ng_contamination;
                tr2fpcapr_modelSums[row.model].ng_others += row.ng_others;
                tr2fpcapr_modelSums[row.model].work_minute += row.work_minute;                
            }
        }
        for (const row of tr2fpca_dtItem.value) { tr2fpcadt_Sums.time += row.time;  }
        for (const row of tr2fpca_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2fpcadt_modelSums, row.model)) {
                tr2fpcadt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr2fpca_qwItem.value) {
            tr2fpcaqw_Sums.ok += row.ok;
            tr2fpcaqw_Sums.ve_acrylicverify += row.ve_acrylicverify;
            tr2fpcaqw_Sums.ng += row.ng;
            tr2fpcaqw_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr2fpcaqw_Sums.ng_toomuchacrylic += row.ng_toomuchacrylic;
            tr2fpcaqw_Sums.ng_toolessacrylic += row.ng_toolessacrylic;
            tr2fpcaqw_Sums.ng_bubbleinacrylic += row.ng_bubbleinacrylic;
            tr2fpcaqw_Sums.ng_acrylicpeeloff += row.ng_acrylicpeeloff;
            tr2fpcaqw_Sums.ng_holesinsolder += row.ng_holesinsolder;
            tr2fpcaqw_Sums.ng_toomuchsolder += row.ng_toomuchsolder;
            tr2fpcaqw_Sums.ng_toolesssolder += row.ng_toolesssolder;
            tr2fpcaqw_Sums.ng_qrnotreadable += row.ng_qrnotreadable;
            tr2fpcaqw_Sums.ng_eolreal += row.ng_eolreal;
            tr2fpcaqw_Sums.ng_contamination += row.ng_contamination;
            tr2fpcaqw_Sums.ng_others += row.ng_others;
            tr2fpcaqw_Sums.work_minute += row.work_minute;
        
        }
        for (const row of tr2fpca_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2fpcaqw_modelSums, row.model)) {
                tr2fpcaqw_modelSums[row.model].ok += row.ok;
                tr2fpcaqw_modelSums[row.model].ve_acrylicverify += row.ve_acrylicverify;
                tr2fpcaqw_modelSums[row.model].ng += row.ng;
                tr2fpcaqw_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr2fpcaqw_modelSums[row.model].ng_toomuchacrylic += row.ng_toomuchacrylic;
                tr2fpcaqw_modelSums[row.model].ng_toolessacrylic += row.ng_toolessacrylic;
                tr2fpcaqw_modelSums[row.model].ng_bubbleinacrylic += row.ng_bubbleinacrylic;
                tr2fpcaqw_modelSums[row.model].ng_acrylicpeeloff += row.ng_acrylicpeeloff;
                tr2fpcaqw_modelSums[row.model].ng_holesinsolder += row.ng_holesinsolder;
                tr2fpcaqw_modelSums[row.model].ng_toomuchsolder += row.ng_toomuchsolder;
                tr2fpcaqw_modelSums[row.model].ng_toolesssolder += row.ng_toolesssolder;
                tr2fpcaqw_modelSums[row.model].ng_qrnotreadable += row.ng_qrnotreadable;
                tr2fpcaqw_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr2fpcaqw_modelSums[row.model].ng_contamination += row.ng_contamination;
                tr2fpcaqw_modelSums[row.model].ng_others += row.ng_others;
                tr2fpcaqw_modelSums[row.model].work_minute += row.work_minute;              
            }
        }
        //EVO 3(TR2 BFA 2P)
        let tr2bfa2ppr_Sums = { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 };  
        let tr2bfa2ppr_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0,ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_3P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0,ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0},                             
            "2P_GEN.1": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0,ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0},                             
        };
        let tr2bfa2pdt_Sums = { time : 0 }
        let tr2bfa2pdt_modelSums = { "2P12S": { time: 0}, "EVO_3P": { time: 0}, "2P_GEN.1": { time: 0}, };
        let tr2bfa2pqw_Sums = { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, }
        let tr2bfa2pqw_modelSums = {
            "2P12S": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },    
            "EVO_3P": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },                
            "2P_GEN.1": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },                
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr2bfa2p_item.value) {            
            tr2bfa2ppr_Sums.target += row.target;
            tr2bfa2ppr_Sums.input += row.input;
            tr2bfa2ppr_Sums.ok += row.ok;
            tr2bfa2ppr_Sums.vesum += row.vesum;
            tr2bfa2ppr_Sums.ve_laserwelding += row.ve_laserwelding;
            tr2bfa2ppr_Sums.ve_fpcbdamage += row.ve_fpcbdamage;
            tr2bfa2ppr_Sums.ng += row.ng;
            tr2bfa2ppr_Sums.ng_heatstacking += row.ng_heatstacking;
            tr2bfa2ppr_Sums.ng_busbardamage += row.ng_busbardamage;
            tr2bfa2ppr_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr2bfa2ppr_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr2bfa2ppr_Sums.ng_weldingng += row.ng_weldingng;
            tr2bfa2ppr_Sums.ng_finalslot += row.ng_finalslot;
            tr2bfa2ppr_Sums.ng_framedamage += row.ng_framedamage;
            tr2bfa2ppr_Sums.ng_eolreal += row.ng_eolreal;
            tr2bfa2ppr_Sums.ng_eol2real += row.ng_eol2real;
            tr2bfa2ppr_Sums.ng_coating += row.ng_coating;
            tr2bfa2ppr_Sums.ng_coverhook += row.ng_coverhook;
            tr2bfa2ppr_Sums.ng_coverdamage += row.ng_coverdamage;
            tr2bfa2ppr_Sums.ng_connector += row.ng_connector;
            tr2bfa2ppr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr2bfa2ppr_Sums.ng_etc += row.ng_etc;
            tr2bfa2ppr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr2bfa2p_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr2bfa2ppr_modelSums, row.model)) {
                tr2bfa2ppr_modelSums[row.model].target += row.target;
                tr2bfa2ppr_modelSums[row.model].input += row.input;
                tr2bfa2ppr_modelSums[row.model].ok += row.ok;
                tr2bfa2ppr_modelSums[row.model].vesum += row.vesum;
                tr2bfa2ppr_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr2bfa2ppr_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr2bfa2ppr_modelSums[row.model].ng += row.ng;
                tr2bfa2ppr_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr2bfa2ppr_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr2bfa2ppr_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr2bfa2ppr_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr2bfa2ppr_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr2bfa2ppr_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr2bfa2ppr_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr2bfa2ppr_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr2bfa2ppr_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                tr2bfa2ppr_modelSums[row.model].ng_coating += row.ng_coating;
                tr2bfa2ppr_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                tr2bfa2ppr_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr2bfa2ppr_modelSums[row.model].ng_connector += row.ng_connector;
                tr2bfa2ppr_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr2bfa2ppr_modelSums[row.model].ng_etc += row.ng_etc;
                tr2bfa2ppr_modelSums[row.model].work_minute += row.work_minute;                            
            }
        }
        for (const row of tr2bfa2p_dtItem.value) { tr2bfa2pdt_Sums.time += row.time;  }
        for (const row of tr2bfa2p_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2bfa2pdt_modelSums, row.model)) {
                tr2bfa2pdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr2bfa2p_qwItem.value) {
            tr2bfa2pqw_Sums.target += row.target;
            tr2bfa2pqw_Sums.input += row.input;
            tr2bfa2pqw_Sums.ok += row.ok;
            tr2bfa2pqw_Sums.vesum += row.vesum;            
            tr2bfa2pqw_Sums.ng += row.ng;
            tr2bfa2pqw_Sums.ng_heatstacking += row.ng_heatstacking;
            tr2bfa2pqw_Sums.ng_busbardamage += row.ng_busbardamage;
            tr2bfa2pqw_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr2bfa2pqw_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr2bfa2pqw_Sums.ng_weldingng += row.ng_weldingng;
            tr2bfa2pqw_Sums.ng_finalslot += row.ng_finalslot;
            tr2bfa2pqw_Sums.ng_framedamage += row.ng_framedamage;
            tr2bfa2pqw_Sums.ng_eolreal += row.ng_eolreal;
            tr2bfa2pqw_Sums.ng_eol2real += row.ng_eol2real;
            tr2bfa2pqw_Sums.ng_coating += row.ng_coating;
            tr2bfa2pqw_Sums.ng_coverhook += row.ng_coverhook;
            tr2bfa2pqw_Sums.ng_coverdamage += row.ng_coverdamage;
            tr2bfa2pqw_Sums.ng_connector += row.ng_connector;
            tr2bfa2pqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr2bfa2pqw_Sums.ng_etc += row.ng_etc;
            tr2bfa2pqw_Sums.work_minute += row.work_minute;        
        }
        for (const row of tr2bfa2p_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2bfa2pqw_modelSums, row.model)) {
                tr2bfa2pqw_modelSums[row.model].target += row.target;
                tr2bfa2pqw_modelSums[row.model].input += row.input;
                tr2bfa2pqw_modelSums[row.model].ok += row.ok;
                tr2bfa2pqw_modelSums[row.model].vesum += row.vesum;
                tr2bfa2pqw_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr2bfa2pqw_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr2bfa2pqw_modelSums[row.model].ng += row.ng;
                tr2bfa2pqw_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr2bfa2pqw_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr2bfa2pqw_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr2bfa2pqw_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr2bfa2pqw_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr2bfa2pqw_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr2bfa2pqw_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr2bfa2pqw_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr2bfa2pqw_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                tr2bfa2pqw_modelSums[row.model].ng_coating += row.ng_coating;
                tr2bfa2pqw_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                tr2bfa2pqw_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr2bfa2pqw_modelSums[row.model].ng_connector += row.ng_connector;
                tr2bfa2pqw_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr2bfa2pqw_modelSums[row.model].ng_etc += row.ng_etc;
                tr2bfa2pqw_modelSums[row.model].work_minute += row.work_minute;                                     
            }
        }

        //TR2 BFA 3P
        let tr2bfa3ppr_Sums = { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 };  
        let tr2bfa3ppr_modelSums = {   
            "3P8S": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_3P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0},                             
            "3P_GEN.1": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0},                             
        };
        let tr2bfa3pdt_Sums = { time : 0 }
        let tr2bfa3pdt_modelSums = { "3P8S": { time: 0}, "EVO_3P": { time: 0}, "3P_GEN.1": { time: 0}, };
        let tr2bfa3pqw_Sums = { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0, }
        let tr2bfa3pqw_modelSums = {
            "3P8S": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },    
            "EVO_3P": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },                
            "3P_GEN.1": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_coating: 0, ng_coverdamage: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },                
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr2bfa3p_item.value) {            
            tr2bfa3ppr_Sums.target += row.target;
            tr2bfa3ppr_Sums.input += row.input;
            tr2bfa3ppr_Sums.ok += row.ok;
            tr2bfa3ppr_Sums.vesum += row.vesum;
            tr2bfa3ppr_Sums.ve_laserwelding += row.ve_laserwelding;
            tr2bfa3ppr_Sums.ve_fpcbdamage += row.ve_fpcbdamage;
            tr2bfa3ppr_Sums.ng += row.ng;
            tr2bfa3ppr_Sums.ng_heatstacking += row.ng_heatstacking;
            tr2bfa3ppr_Sums.ng_busbardamage += row.ng_busbardamage;
            tr2bfa3ppr_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr2bfa3ppr_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr2bfa3ppr_Sums.ng_weldingng += row.ng_weldingng;
            tr2bfa3ppr_Sums.ng_finalslot += row.ng_finalslot;
            tr2bfa3ppr_Sums.ng_framedamage += row.ng_framedamage;
            tr2bfa3ppr_Sums.ng_eolreal += row.ng_eolreal;
            tr2bfa3ppr_Sums.ng_coating += row.ng_coating;
            tr2bfa3ppr_Sums.ng_coverdamage += row.ng_coverdamage;
            tr2bfa3ppr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr2bfa3ppr_Sums.ng_etc += row.ng_etc;
            tr2bfa3ppr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr2bfa3p_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr2bfa3ppr_modelSums, row.model)) {
                tr2bfa3ppr_modelSums[row.model].target += row.target;
                tr2bfa3ppr_modelSums[row.model].input += row.input;
                tr2bfa3ppr_modelSums[row.model].ok += row.ok;
                tr2bfa3ppr_modelSums[row.model].vesum += row.vesum;
                tr2bfa3ppr_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr2bfa3ppr_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr2bfa3ppr_modelSums[row.model].ng += row.ng;
                tr2bfa3ppr_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr2bfa3ppr_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr2bfa3ppr_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr2bfa3ppr_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr2bfa3ppr_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr2bfa3ppr_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr2bfa3ppr_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr2bfa3ppr_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr2bfa3ppr_modelSums[row.model].ng_coating += row.ng_coating;
                tr2bfa3ppr_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr2bfa3ppr_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr2bfa3ppr_modelSums[row.model].ng_etc += row.ng_etc;
                tr2bfa3ppr_modelSums[row.model].work_minute += row.work_minute;                            
            }
        }
        for (const row of tr2bfa3p_dtItem.value) { tr2bfa3pdt_Sums.time += row.time;  }
        for (const row of tr2bfa3p_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2bfa3pdt_modelSums, row.model)) {
                tr2bfa3pdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr2bfa3p_qwItem.value) {
            tr2bfa3pqw_Sums.target += row.target;
            tr2bfa3pqw_Sums.input += row.input;
            tr2bfa3pqw_Sums.ok += row.ok;
            tr2bfa3pqw_Sums.vesum += row.vesum;            
            tr2bfa3pqw_Sums.ng += row.ng;
            tr2bfa3pqw_Sums.ng_heatstacking += row.ng_heatstacking;
            tr2bfa3pqw_Sums.ng_busbardamage += row.ng_busbardamage;
            tr2bfa3pqw_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr2bfa3pqw_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr2bfa3pqw_Sums.ng_weldingng += row.ng_weldingng;
            tr2bfa3pqw_Sums.ng_finalslot += row.ng_finalslot;
            tr2bfa3pqw_Sums.ng_framedamage += row.ng_framedamage;
            tr2bfa3pqw_Sums.ng_eolreal += row.ng_eolreal;
            tr2bfa3pqw_Sums.ng_coating += row.ng_coating;
            tr2bfa3pqw_Sums.ng_coverdamage += row.ng_coverdamage;
            tr2bfa3pqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr2bfa3pqw_Sums.ng_etc += row.ng_etc;
            tr2bfa3pqw_Sums.work_minute += row.work_minute;        
        }
        for (const row of tr2bfa3p_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr2bfa3pqw_modelSums, row.model)) {
                tr2bfa3pqw_modelSums[row.model].target += row.target;
                tr2bfa3pqw_modelSums[row.model].input += row.input;
                tr2bfa3pqw_modelSums[row.model].ok += row.ok;
                tr2bfa3pqw_modelSums[row.model].vesum += row.vesum;
                tr2bfa3pqw_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr2bfa3pqw_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr2bfa3pqw_modelSums[row.model].ng += row.ng;
                tr2bfa3pqw_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr2bfa3pqw_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr2bfa3pqw_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr2bfa3pqw_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr2bfa3pqw_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr2bfa3pqw_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr2bfa3pqw_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr2bfa3pqw_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr2bfa3pqw_modelSums[row.model].ng_coating += row.ng_coating;
                tr2bfa3pqw_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr2bfa3pqw_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr2bfa3pqw_modelSums[row.model].ng_etc += row.ng_etc;
                tr2bfa3pqw_modelSums[row.model].work_minute += row.work_minute;                                     
            }
        }
        //TR3 SMT
        let tr3smtpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0};  
        let tr3smtpr_modelSums = {
            "2P12S": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},    
            "EVO_2P": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},    
            "EVO_3P": { target: 0, input: 0, ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0, work_minute: 0},               
        };
        let tr3smtdt_Sums = { time : 0 }
        let tr3smtdt_modelSums = { "2P12S": { time: 0}, "3P8S": { time: 0}, "EVO_2P": { time: 0}, "EVO_3P": { time: 0},};
        let tr3smtqw_Sums = {ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0}
        let tr3smtqw_modelSums = {
            "2P12S": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0},    
            "3P8S": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0}, 
            "EVO_2P": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0},    
            "EVO_3P": { ok: 0, ng: 0, ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0},     
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr3smt_item.value) {            
            tr3smtpr_Sums.target += row.target;
            tr3smtpr_Sums.input += row.input;
            tr3smtpr_Sums.ok += row.ok;
            tr3smtpr_Sums.ng += row.ng;
            tr3smtpr_Sums.ng_fuse += row.ng_fuse;
            tr3smtpr_Sums.ng_thermistor += row.ng_thermistor;
            tr3smtpr_Sums.ng_fiducial += row.ng_fiducial;
            tr3smtpr_Sums.ng_qrcode += row.ng_qrcode;
            tr3smtpr_Sums.ng_burned += row.ng_burned;
            tr3smtpr_Sums.ng_blackfuse += row.ng_blackfuse;
            tr3smtpr_Sums.ng_others += row.ng_others;
            tr3smtpr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr3smt_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr3smtpr_modelSums, row.model)) {
                tr3smtpr_modelSums[row.model].target += row.target;
                tr3smtpr_modelSums[row.model].input += row.input;
                tr3smtpr_modelSums[row.model].ok += row.ok;
                tr3smtpr_modelSums[row.model].ng += row.ng;
                tr3smtpr_modelSums[row.model].ng_fuse += row.ng_fuse;
                tr3smtpr_modelSums[row.model].ng_thermistor += row.ng_thermistor;
                tr3smtpr_modelSums[row.model].ng_fiducial += row.ng_fiducial;
                tr3smtpr_modelSums[row.model].ng_qrcode += row.ng_qrcode;
                tr3smtpr_modelSums[row.model].ng_burned += row.ng_burned;
                tr3smtpr_modelSums[row.model].ng_blackfuse += row.ng_blackfuse;
                tr3smtpr_modelSums[row.model].ng_others += row.ng_others;
                tr3smtpr_modelSums[row.model].work_minute += row.work_minute;
            }
        }

        for (const row of tr3smt_dtItem.value) { tr3smtdt_Sums.time += row.time;  }
        for (const row of tr3smt_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3smtdt_modelSums, row.model)) {
                tr3smtdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr3smt_qwItem.value) {
            tr3smtqw_Sums.ok += row.ok;                
            tr3smtqw_Sums.ng += row.ng;
            tr3smtqw_Sums.ng_fuse += row.ng_fuse; 
            tr3smtqw_Sums.ng_thermistor += row.ng_thermistor;
            tr3smtqw_Sums.ng_fiducial += row.ng_fiducial;
            tr3smtqw_Sums.ng_qrcode += row.ng_qrcode;
            tr3smtqw_Sums.ng_burned += row.ng_burned;
            tr3smtqw_Sums.ng_blackfuse += row.ng_blackfuse;
            tr3smtqw_Sums.ng_others += row.ng_others;   
        
        }
        for (const row of tr3smt_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3smtqw_modelSums, row.model)) {
                tr3smtqw_modelSums[row.model].ok += row.ok;                
                tr3smtqw_modelSums[row.model].ng += row.ng;
                tr3smtqw_modelSums[row.model].ng_fuse += row.ng_fuse; 
                tr3smtqw_modelSums[row.model].ng_thermistor += row.ng_thermistor;
                tr3smtqw_modelSums[row.model].ng_fiducial += row.ng_fiducial;
                tr3smtqw_modelSums[row.model].ng_qrcode += row.ng_qrcode;
                tr3smtqw_modelSums[row.model].ng_burned += row.ng_burned;
                tr3smtqw_modelSums[row.model].ng_blackfuse += row.ng_blackfuse;
                tr3smtqw_modelSums[row.model].ng_others += row.ng_others;                
            }
        }
        //TR3 Crimping
        let tr3crimpingpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0 };  
        let tr3crimpingpr_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},    
            "EVO_2P": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},    
            "EVO_3P": { target: 0, input: 0, ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, work_minute: 0},               
        };
        let tr3crimpingdt_Sums = { time : 0 }
        let tr3crimpingdt_modelSums = { "2P12S": { time: 0}, "3P8S": { time: 0}, "EVO_2P": { time: 0}, "EVO_3P": { time: 0},};
        let tr3crimpingqw_Sums = { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, }
        let tr3crimpingqw_modelSums = {
            "2P12S": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, },    
            "3P8S": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, }, 
            "EVO_2P": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, },    
            "EVO_3P": { ok: 0, ng: 0, ng_caoiheight: 0, ng_caoishift: 0, ng_assembly: 0, ng_machineerror: 0, ng_weldplate: 0, ng_fpcb: 0, ng_etc: 0, },     
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr3crimping_item.value) {            
            tr3crimpingpr_Sums.target += row.target;
            tr3crimpingpr_Sums.input += row.input;
            tr3crimpingpr_Sums.ok += row.ok;
            tr3crimpingpr_Sums.ng += row.ng;
            tr3crimpingpr_Sums.ng_caoiheight += row.ng_caoiheight;
            tr3crimpingpr_Sums.ng_caoishift += row.ng_caoishift;
            tr3crimpingpr_Sums.ng_assembly += row.ng_assembly;
            tr3crimpingpr_Sums.ng_machineerror += row.ng_machineerror;
            tr3crimpingpr_Sums.ng_weldplate += row.ng_weldplate;
            tr3crimpingpr_Sums.ng_fpcb += row.ng_fpcb;
            tr3crimpingpr_Sums.ng_etc += row.ng_etc;
            tr3crimpingpr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr3crimping_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr3crimpingpr_modelSums, row.model)) {
                tr3crimpingpr_modelSums[row.model].target += row.target;
                tr3crimpingpr_modelSums[row.model].input += row.input;
                tr3crimpingpr_modelSums[row.model].ok += row.ok;
                tr3crimpingpr_modelSums[row.model].ng += row.ng;                     
                tr3crimpingpr_modelSums[row.model].ng_caoiheight += row.ng_caoiheight;
                tr3crimpingpr_modelSums[row.model].ng_caoishift += row.ng_caoishift;
                tr3crimpingpr_modelSums[row.model].ng_assembly += row.ng_assembly;
                tr3crimpingpr_modelSums[row.model].ng_machineerror += row.ng_machineerror;
                tr3crimpingpr_modelSums[row.model].ng_weldplate += row.ng_weldplate;
                tr3crimpingpr_modelSums[row.model].ng_fpcb += row.ng_fpcb;
                tr3crimpingpr_modelSums[row.model].ng_etc += row.ng_etc;
                tr3crimpingpr_modelSums[row.model].work_minute += row.work_minute;
            }
        }
        for (const row of tr3crimping_dtItem.value) { tr3crimpingdt_Sums.time += row.time;  }
        for (const row of tr3crimping_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3crimpingdt_modelSums, row.model)) {
                tr3crimpingdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr3crimping_qwItem.value) {
            tr3crimpingqw_Sums.ok += row.ok;
            tr3crimpingqw_Sums.ng += row.ng;
            tr3crimpingqw_Sums.ng_caoiheight += row.ng_caoiheight;
            tr3crimpingqw_Sums.ng_caoishift += row.ng_caoishift;
            tr3crimpingqw_Sums.ng_assembly += row.ng_assembly;
            tr3crimpingqw_Sums.ng_machineerror += row.ng_machineerror;
            tr3crimpingqw_Sums.ng_weldplate += row.ng_weldplate;
            tr3crimpingqw_Sums.ng_fpcb += row.ng_fpcb;
            tr3crimpingqw_Sums.ng_etc += row.ng_etc;
            tr3crimpingqw_Sums.work_minute += row.work_minute;
        
        }
        for (const row of tr3crimping_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3crimpingqw_modelSums, row.model)) {
                tr3crimpingqw_modelSums[row.model].ok += row.ok;
                tr3crimpingqw_modelSums[row.model].ng += row.ng;                     
                tr3crimpingqw_modelSums[row.model].ng_caoiheight += row.ng_caoiheight;
                tr3crimpingqw_modelSums[row.model].ng_caoishift += row.ng_caoishift;
                tr3crimpingqw_modelSums[row.model].ng_assembly += row.ng_assembly;
                tr3crimpingqw_modelSums[row.model].ng_machineerror += row.ng_machineerror;
                tr3crimpingqw_modelSums[row.model].ng_weldplate += row.ng_weldplate;
                tr3crimpingqw_modelSums[row.model].ng_fpcb += row.ng_fpcb;
                tr3crimpingqw_modelSums[row.model].ng_etc += row.ng_etc;
                tr3crimpingqw_modelSums[row.model].work_minute += row.work_minute;           
            }
        }
        //TR3 FPCA        
        let tr3fpcapr_Sums = { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0 };  
        let tr3fpcapr_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},    
            "EVO_2P": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},    
            "EVO_3P": { target: 0, input: 0, ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, work_minute: 0},               
        };
        let tr3fpcadt_Sums = { time : 0 }
        let tr3fpcadt_modelSums = { "2P12S": { time: 0}, "3P8S": { time: 0}, "EVO_2P": { time: 0}, "EVO_3P": { time: 0},};
        let tr3fpcaqw_Sums = { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, }
        let tr3fpcaqw_modelSums = {
            "2P12S": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, },    
            "3P8S": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, }, 
            "EVO_2P": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, },    
            "EVO_3P": { ok: 0, ve_acrylicverify: 0, ng: 0, ng_fpcbdamage: 0, ng_toomuchacrylic: 0, ng_toolessacrylic: 0, ng_bubbleinacrylic: 0, ng_acrylicpeeloff: 0, ng_holesinsolder: 0, ng_toomuchsolder: 0, ng_toolesssolder: 0, ng_qrnotreadable: 0, ng_eolreal: 0, ng_contamination: 0, ng_others:0, },     
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr3fpca_item.value) {            
            tr3fpcapr_Sums.target += row.target;
            tr3fpcapr_Sums.input += row.input;
            tr3fpcapr_Sums.ok += row.ok;
            tr3fpcapr_Sums.ve_acrylicverify += row.ve_acrylicverify;
            tr3fpcapr_Sums.ng += row.ng;
            tr3fpcapr_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr3fpcapr_Sums.ng_toomuchacrylic += row.ng_toomuchacrylic;
            tr3fpcapr_Sums.ng_toolessacrylic += row.ng_toolessacrylic;
            tr3fpcapr_Sums.ng_bubbleinacrylic += row.ng_bubbleinacrylic;
            tr3fpcapr_Sums.ng_acrylicpeeloff += row.ng_acrylicpeeloff;
            tr3fpcapr_Sums.ng_holesinsolder += row.ng_holesinsolder;
            tr3fpcapr_Sums.ng_toomuchsolder += row.ng_toomuchsolder;
            tr3fpcapr_Sums.ng_toolesssolder += row.ng_toolesssolder;
            tr3fpcapr_Sums.ng_qrnotreadable += row.ng_qrnotreadable;
            tr3fpcapr_Sums.ng_eolreal += row.ng_eolreal;
            tr3fpcapr_Sums.ng_contamination += row.ng_contamination;
            tr3fpcapr_Sums.ng_others += row.ng_others;
            tr3fpcapr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr3fpca_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr3fpcapr_modelSums, row.model)) {
                tr3fpcapr_modelSums[row.model].target += row.target;
                tr3fpcapr_modelSums[row.model].input += row.input;
                tr3fpcapr_modelSums[row.model].ok += row.ok;
                tr3fpcapr_modelSums[row.model].ve_acrylicverify += row.ve_acrylicverify;
                tr3fpcapr_modelSums[row.model].ng += row.ng;
                tr3fpcapr_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr3fpcapr_modelSums[row.model].ng_toomuchacrylic += row.ng_toomuchacrylic;
                tr3fpcapr_modelSums[row.model].ng_toolessacrylic += row.ng_toolessacrylic;
                tr3fpcapr_modelSums[row.model].ng_bubbleinacrylic += row.ng_bubbleinacrylic;
                tr3fpcapr_modelSums[row.model].ng_acrylicpeeloff += row.ng_acrylicpeeloff;
                tr3fpcapr_modelSums[row.model].ng_holesinsolder += row.ng_holesinsolder;
                tr3fpcapr_modelSums[row.model].ng_toomuchsolder += row.ng_toomuchsolder;
                tr3fpcapr_modelSums[row.model].ng_toolesssolder += row.ng_toolesssolder;
                tr3fpcapr_modelSums[row.model].ng_qrnotreadable += row.ng_qrnotreadable;
                tr3fpcapr_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr3fpcapr_modelSums[row.model].ng_contamination += row.ng_contamination;
                tr3fpcapr_modelSums[row.model].ng_others += row.ng_others;
                tr3fpcapr_modelSums[row.model].work_minute += row.work_minute;                
            }
        }
        for (const row of tr3fpca_dtItem.value) { tr3fpcadt_Sums.time += row.time;  }
        for (const row of tr3fpca_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3fpcadt_modelSums, row.model)) {
                tr3fpcadt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr3fpca_qwItem.value) {
            tr3fpcaqw_Sums.ok += row.ok;
            tr3fpcaqw_Sums.ve_acrylicverify += row.ve_acrylicverify;
            tr3fpcaqw_Sums.ng += row.ng;
            tr3fpcaqw_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr3fpcaqw_Sums.ng_toomuchacrylic += row.ng_toomuchacrylic;
            tr3fpcaqw_Sums.ng_toolessacrylic += row.ng_toolessacrylic;
            tr3fpcaqw_Sums.ng_bubbleinacrylic += row.ng_bubbleinacrylic;
            tr3fpcaqw_Sums.ng_acrylicpeeloff += row.ng_acrylicpeeloff;
            tr3fpcaqw_Sums.ng_holesinsolder += row.ng_holesinsolder;
            tr3fpcaqw_Sums.ng_toomuchsolder += row.ng_toomuchsolder;
            tr3fpcaqw_Sums.ng_toolesssolder += row.ng_toolesssolder;
            tr3fpcaqw_Sums.ng_qrnotreadable += row.ng_qrnotreadable;
            tr3fpcaqw_Sums.ng_eolreal += row.ng_eolreal;
            tr3fpcaqw_Sums.ng_contamination += row.ng_contamination;
            tr3fpcaqw_Sums.ng_others += row.ng_others;
            tr3fpcaqw_Sums.work_minute += row.work_minute;
        
        }
        for (const row of tr3fpca_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3fpcaqw_modelSums, row.model)) {
                tr3fpcaqw_modelSums[row.model].ok += row.ok;
                tr3fpcaqw_modelSums[row.model].ve_acrylicverify += row.ve_acrylicverify;
                tr3fpcaqw_modelSums[row.model].ng += row.ng;
                tr3fpcaqw_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr3fpcaqw_modelSums[row.model].ng_toomuchacrylic += row.ng_toomuchacrylic;
                tr3fpcaqw_modelSums[row.model].ng_toolessacrylic += row.ng_toolessacrylic;
                tr3fpcaqw_modelSums[row.model].ng_bubbleinacrylic += row.ng_bubbleinacrylic;
                tr3fpcaqw_modelSums[row.model].ng_acrylicpeeloff += row.ng_acrylicpeeloff;
                tr3fpcaqw_modelSums[row.model].ng_holesinsolder += row.ng_holesinsolder;
                tr3fpcaqw_modelSums[row.model].ng_toomuchsolder += row.ng_toomuchsolder;
                tr3fpcaqw_modelSums[row.model].ng_toolesssolder += row.ng_toolesssolder;
                tr3fpcaqw_modelSums[row.model].ng_qrnotreadable += row.ng_qrnotreadable;
                tr3fpcaqw_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr3fpcaqw_modelSums[row.model].ng_contamination += row.ng_contamination;
                tr3fpcaqw_modelSums[row.model].ng_others += row.ng_others;
                tr3fpcaqw_modelSums[row.model].work_minute += row.work_minute;              
            }
        }
        //EVO 1
        let tr3bfa2ppr_Sums = { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 };  
        let tr3bfa2ppr_modelSums = {   
            "2P12S": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_3P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0},                             
        };
        let tr3bfa2pdt_Sums = { time : 0 }
        let tr3bfa2pdt_modelSums = { "2P12S": { time: 0}, "EVO_3P": { time: 0}, };
        let tr3bfa2pqw_Sums = { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, }
        let tr3bfa2pqw_modelSums = {
            "2P12S": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },    
            "EVO_3P": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },                
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr3bfa2p_item.value) {            
            tr3bfa2ppr_Sums.target += row.target;
            tr3bfa2ppr_Sums.input += row.input;
            tr3bfa2ppr_Sums.ok += row.ok;
            tr3bfa2ppr_Sums.vesum += row.vesum;
            tr3bfa2ppr_Sums.ve_laserwelding += row.ve_laserwelding;
            tr3bfa2ppr_Sums.ve_fpcbdamage += row.ve_fpcbdamage;
            tr3bfa2ppr_Sums.ng += row.ng;
            tr3bfa2ppr_Sums.ng_heatstacking += row.ng_heatstacking;
            tr3bfa2ppr_Sums.ng_busbardamage += row.ng_busbardamage;
            tr3bfa2ppr_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr3bfa2ppr_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr3bfa2ppr_Sums.ng_weldingng += row.ng_weldingng;
            tr3bfa2ppr_Sums.ng_finalslot += row.ng_finalslot;
            tr3bfa2ppr_Sums.ng_framedamage += row.ng_framedamage;
            tr3bfa2ppr_Sums.ng_eolreal += row.ng_eolreal;
            tr3bfa2ppr_Sums.ng_eol2real += row.ng_eol2real;
            tr3bfa2ppr_Sums.ng_coating += row.ng_coating;
            tr3bfa2ppr_Sums.ng_coverhook += row.ng_coverhook;
            tr3bfa2ppr_Sums.ng_coverdamage += row.ng_coverdamage;
            tr3bfa2ppr_Sums.ng_connector += row.ng_connector;
            tr3bfa2ppr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr3bfa2ppr_Sums.ng_etc += row.ng_etc;
            tr3bfa2ppr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr3bfa2p_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr3bfa2ppr_modelSums, row.model)) {
                tr3bfa2ppr_modelSums[row.model].target += row.target;
                tr3bfa2ppr_modelSums[row.model].input += row.input;
                tr3bfa2ppr_modelSums[row.model].ok += row.ok;
                tr3bfa2ppr_modelSums[row.model].vesum += row.vesum;
                tr3bfa2ppr_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr3bfa2ppr_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr3bfa2ppr_modelSums[row.model].ng += row.ng;
                tr3bfa2ppr_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr3bfa2ppr_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr3bfa2ppr_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr3bfa2ppr_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr3bfa2ppr_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr3bfa2ppr_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr3bfa2ppr_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr3bfa2ppr_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr3bfa2ppr_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                tr3bfa2ppr_modelSums[row.model].ng_coating += row.ng_coating;
                tr3bfa2ppr_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                tr3bfa2ppr_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr3bfa2ppr_modelSums[row.model].ng_connector += row.ng_connector;
                tr3bfa2ppr_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr3bfa2ppr_modelSums[row.model].ng_etc += row.ng_etc;
                tr3bfa2ppr_modelSums[row.model].work_minute += row.work_minute;                            
            }
        }
        for (const row of tr3bfa2p_dtItem.value) { tr3bfa2pdt_Sums.time += row.time;  }
        for (const row of tr3bfa2p_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3bfa2pdt_modelSums, row.model)) {
                tr3bfa2pdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr3bfa2p_qwItem.value) {
            tr3bfa2pqw_Sums.target += row.target;
            tr3bfa2pqw_Sums.input += row.input;
            tr3bfa2pqw_Sums.ok += row.ok;
            tr3bfa2pqw_Sums.vesum += row.vesum;            
            tr3bfa2pqw_Sums.ng += row.ng;
            tr3bfa2pqw_Sums.ng_heatstacking += row.ng_heatstacking;
            tr3bfa2pqw_Sums.ng_busbardamage += row.ng_busbardamage;
            tr3bfa2pqw_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr3bfa2pqw_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr3bfa2pqw_Sums.ng_weldingng += row.ng_weldingng;
            tr3bfa2pqw_Sums.ng_finalslot += row.ng_finalslot;
            tr3bfa2pqw_Sums.ng_framedamage += row.ng_framedamage;
            tr3bfa2pqw_Sums.ng_eolreal += row.ng_eolreal;
            tr3bfa2pqw_Sums.ng_eol2real += row.ng_eol2real;
            tr3bfa2pqw_Sums.ng_coating += row.ng_coating;
            tr3bfa2pqw_Sums.ng_coverhook += row.ng_coverhook;
            tr3bfa2pqw_Sums.ng_coverdamage += row.ng_coverdamage;
            tr3bfa2pqw_Sums.ng_connector += row.ng_connector;
            tr3bfa2pqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr3bfa2pqw_Sums.ng_etc += row.ng_etc;
            tr3bfa2pqw_Sums.work_minute += row.work_minute;        
        }
        for (const row of tr3bfa2p_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3bfa2pqw_modelSums, row.model)) {
                tr3bfa2pqw_modelSums[row.model].target += row.target;
                tr3bfa2pqw_modelSums[row.model].input += row.input;
                tr3bfa2pqw_modelSums[row.model].ok += row.ok;
                tr3bfa2pqw_modelSums[row.model].vesum += row.vesum;
                tr3bfa2pqw_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr3bfa2pqw_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr3bfa2pqw_modelSums[row.model].ng += row.ng;
                tr3bfa2pqw_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr3bfa2pqw_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr3bfa2pqw_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr3bfa2pqw_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr3bfa2pqw_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr3bfa2pqw_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr3bfa2pqw_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr3bfa2pqw_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr3bfa2pqw_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                tr3bfa2pqw_modelSums[row.model].ng_coating += row.ng_coating;
                tr3bfa2pqw_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                tr3bfa2pqw_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr3bfa2pqw_modelSums[row.model].ng_connector += row.ng_conncetor;
                tr3bfa2pqw_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr3bfa2pqw_modelSums[row.model].ng_etc += row.ng_etc;
                tr3bfa2pqw_modelSums[row.model].work_minute += row.work_minute;                                     
            }
        }

        //EVO 2
        let tr3bfa3ppr_Sums = { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 };  
        let tr3bfa3ppr_modelSums = {   
            "3P8S": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_3P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0},                             
        };
        let tr3bfa3pdt_Sums = { time : 0 }
        let tr3bfa3pdt_modelSums = { "3P8S": { time: 0}, "EVO_3P": { time: 0}, };
        let tr3bfa3pqw_Sums = { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, }
        let tr3bfa3pqw_modelSums = {
            "3P8S": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },    
            "EVO_3P": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },                
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of tr3bfa3p_item.value) {            
            tr3bfa3ppr_Sums.target += row.target;
            tr3bfa3ppr_Sums.input += row.input;
            tr3bfa3ppr_Sums.ok += row.ok;
            tr3bfa3ppr_Sums.vesum += row.vesum;
            tr3bfa3ppr_Sums.ve_laserwelding += row.ve_laserwelding;
            tr3bfa3ppr_Sums.ve_fpcbdamage += row.ve_fpcbdamage;
            tr3bfa3ppr_Sums.ng += row.ng;
            tr3bfa3ppr_Sums.ng_heatstacking += row.ng_heatstacking;
            tr3bfa3ppr_Sums.ng_busbardamage += row.ng_busbardamage;
            tr3bfa3ppr_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr3bfa3ppr_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr3bfa3ppr_Sums.ng_weldingng += row.ng_weldingng;
            tr3bfa3ppr_Sums.ng_finalslot += row.ng_finalslot;
            tr3bfa3ppr_Sums.ng_framedamage += row.ng_framedamage;
            tr3bfa3ppr_Sums.ng_eolreal += row.ng_eolreal;
            tr3bfa3ppr_Sums.ng_eol2real += row.ng_eol2real;
            tr3bfa3ppr_Sums.ng_coating += row.ng_coating;
            tr3bfa3ppr_Sums.ng_coverhook += row.ng_coverhook;
            tr3bfa3ppr_Sums.ng_coverdamage += row.ng_coverdamage;
            tr3bfa3ppr_Sums.ng_connector += row.ng_connector;
            tr3bfa3ppr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr3bfa3ppr_Sums.ng_etc += row.ng_etc;
            tr3bfa3ppr_Sums.work_minute += row.work_minute;
          }     

        for (const row of tr3bfa3p_item.value) {
            if (Object.prototype.hasOwnProperty.call(tr3bfa3ppr_modelSums, row.model)) {
                tr3bfa3ppr_modelSums[row.model].target += row.target;
                tr3bfa3ppr_modelSums[row.model].input += row.input;
                tr3bfa3ppr_modelSums[row.model].ok += row.ok;
                tr3bfa3ppr_modelSums[row.model].vesum += row.vesum;
                tr3bfa3ppr_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr3bfa3ppr_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr3bfa3ppr_modelSums[row.model].ng += row.ng;
                tr3bfa3ppr_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr3bfa3ppr_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr3bfa3ppr_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr3bfa3ppr_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr3bfa3ppr_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr3bfa3ppr_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr3bfa3ppr_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr3bfa3ppr_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr3bfa3ppr_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                tr3bfa3ppr_modelSums[row.model].ng_coating += row.ng_coating;
                tr3bfa3ppr_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                tr3bfa3ppr_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr3bfa3ppr_modelSums[row.model].ng_connector += row.ng_connector;
                tr3bfa3ppr_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr3bfa3ppr_modelSums[row.model].ng_etc += row.ng_etc;
                tr3bfa3ppr_modelSums[row.model].work_minute += row.work_minute;                            
            }
        }
        for (const row of tr3bfa3p_dtItem.value) { tr3bfa3pdt_Sums.time += row.time;  }
        for (const row of tr3bfa3p_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3bfa3pdt_modelSums, row.model)) {
                tr3bfa3pdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of tr3bfa3p_qwItem.value) {
            tr3bfa3pqw_Sums.target += row.target;
            tr3bfa3pqw_Sums.input += row.input;
            tr3bfa3pqw_Sums.ok += row.ok;
            tr3bfa3pqw_Sums.vesum += row.vesum;            
            tr3bfa3pqw_Sums.ng += row.ng;
            tr3bfa3pqw_Sums.ng_heatstacking += row.ng_heatstacking;
            tr3bfa3pqw_Sums.ng_busbardamage += row.ng_busbardamage;
            tr3bfa3pqw_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            tr3bfa3pqw_Sums.ng_weldlineposition += row.ng_weldlineposition;
            tr3bfa3pqw_Sums.ng_weldingng += row.ng_weldingng;
            tr3bfa3pqw_Sums.ng_finalslot += row.ng_finalslot;
            tr3bfa3pqw_Sums.ng_framedamage += row.ng_framedamage;
            tr3bfa3pqw_Sums.ng_eolreal += row.ng_eolreal;
            tr3bfa3pqw_Sums.ng_eol2real += row.ng_eol2real;
            tr3bfa3pqw_Sums.ng_coating += row.ng_coating;
            tr3bfa3pqw_Sums.ng_coverhook += row.ng_coverhook;
            tr3bfa3pqw_Sums.ng_coverdamage += row.ng_coverdamage;
            tr3bfa3pqw_Sums.ng_connector += row.ng_connector;
            tr3bfa3pqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            tr3bfa3pqw_Sums.ng_etc += row.ng_etc;
            tr3bfa3pqw_Sums.work_minute += row.work_minute;        
        }
        for (const row of tr3bfa3p_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(tr3bfa3pqw_modelSums, row.model)) {
                tr3bfa3pqw_modelSums[row.model].target += row.target;
                tr3bfa3pqw_modelSums[row.model].input += row.input;
                tr3bfa3pqw_modelSums[row.model].ok += row.ok;
                tr3bfa3pqw_modelSums[row.model].vesum += row.vesum;
                tr3bfa3pqw_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                tr3bfa3pqw_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                tr3bfa3pqw_modelSums[row.model].ng += row.ng;
                tr3bfa3pqw_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                tr3bfa3pqw_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                tr3bfa3pqw_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                tr3bfa3pqw_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                tr3bfa3pqw_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                tr3bfa3pqw_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                tr3bfa3pqw_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                tr3bfa3pqw_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                tr3bfa3pqw_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                tr3bfa3pqw_modelSums[row.model].ng_coating += row.ng_coating;
                tr3bfa3pqw_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                tr3bfa3pqw_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                tr3bfa3pqw_modelSums[row.model].ng_connector += row.ng_connector;
                tr3bfa3pqw_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                tr3bfa3pqw_modelSums[row.model].ng_etc += row.ng_etc;
                tr3bfa3pqw_modelSums[row.model].work_minute += row.work_minute;                                     
            }
        }
        //EVO Hybrid
        let evopr_Sums = { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 };  
        let evopr_modelSums = {   
            "EVO_2P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0 },                
            "EVO_3P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, work_minute: 0},                             
        };
        let evodt_Sums = { time : 0 }
        let evodt_modelSums = { "EVO_2P": { time: 0}, "EVO_3P": { time: 0}, };
        let evoqw_Sums = { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, }
        let evoqw_modelSums = {
            "EVO_2P": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },    
            "EVO_3P": { ok: 0, vesum: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0, ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,  },                
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of evo_item.value) {            
            evopr_Sums.target += row.target;
            evopr_Sums.input += row.input;
            evopr_Sums.ok += row.ok;
            evopr_Sums.vesum += row.vesum;
            evopr_Sums.ve_laserwelding += row.ve_laserwelding;
            evopr_Sums.ve_fpcbdamage += row.ve_fpcbdamage;
            evopr_Sums.ng += row.ng;
            evopr_Sums.ng_heatstacking += row.ng_heatstacking;
            evopr_Sums.ng_busbardamage += row.ng_busbardamage;
            evopr_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            evopr_Sums.ng_weldlineposition += row.ng_weldlineposition;
            evopr_Sums.ng_weldingng += row.ng_weldingng;
            evopr_Sums.ng_finalslot += row.ng_finalslot;
            evopr_Sums.ng_framedamage += row.ng_framedamage;
            evopr_Sums.ng_eolreal += row.ng_eolreal;
            evopr_Sums.ng_eol2real += row.ng_eol2real;
            evopr_Sums.ng_coating += row.ng_coating;
            evopr_Sums.ng_coverhook += row.ng_coverhook;
            evopr_Sums.ng_coverdamage += row.ng_coverdamage;
            evopr_Sums.ng_connector += row.ng_connector;
            evopr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            evopr_Sums.ng_etc += row.ng_etc;
            evopr_Sums.work_minute += row.work_minute;
          }     

        for (const row of evo_item.value) {
            if (Object.prototype.hasOwnProperty.call(evopr_modelSums, row.model)) {
                evopr_modelSums[row.model].target += row.target;
                evopr_modelSums[row.model].input += row.input;
                evopr_modelSums[row.model].ok += row.ok;
                evopr_modelSums[row.model].vesum += row.vesum;
                evopr_modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
                evopr_modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
                evopr_modelSums[row.model].ng += row.ng;
                evopr_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                evopr_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                evopr_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                evopr_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                evopr_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                evopr_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                evopr_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                evopr_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                evopr_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                evopr_modelSums[row.model].ng_coating += row.ng_coating;
                evopr_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                evopr_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                evopr_modelSums[row.model].ng_connector += row.ng_connector;
                evopr_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                evopr_modelSums[row.model].ng_etc += row.ng_etc;
                evopr_modelSums[row.model].work_minute += row.work_minute;                            
            }
        }
        for (const row of evo_dtItem.value) { evodt_Sums.time += row.time;  }
        for (const row of evo_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(evodt_modelSums, row.model)) {
                evodt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of evo_qwItem.value) {
            evoqw_Sums.target += row.target;
            evoqw_Sums.input += row.input;
            evoqw_Sums.ok += row.ok;
            evoqw_Sums.vesum += row.vesum;            
            evoqw_Sums.ng += row.ng;
            evoqw_Sums.ng_heatstacking += row.ng_heatstacking;
            evoqw_Sums.ng_busbardamage += row.ng_busbardamage;
            evoqw_Sums.ng_fpcbdamage += row.ng_fpcbdamage;
            evoqw_Sums.ng_weldlineposition += row.ng_weldlineposition;
            evoqw_Sums.ng_weldingng += row.ng_weldingng;
            evoqw_Sums.ng_finalslot += row.ng_finalslot;
            evoqw_Sums.ng_framedamage += row.ng_framedamage;
            evoqw_Sums.ng_eolreal += row.ng_eolreal;
            evoqw_Sums.ng_eol2real += row.ng_eol2real;
            evoqw_Sums.ng_coating += row.ng_coating;
            evoqw_Sums.ng_coverhook += row.ng_coverhook;
            evoqw_Sums.ng_coverdamage += row.ng_coverdamage;
            evoqw_Sums.ng_connector += row.ng_connector;
            evoqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;
            evoqw_Sums.ng_etc += row.ng_etc;
            evoqw_Sums.work_minute += row.work_minute;        
        }
        for (const row of evo_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(evoqw_modelSums, row.model)) {
                evoqw_modelSums[row.model].target += row.target;
                evoqw_modelSums[row.model].input += row.input;
                evoqw_modelSums[row.model].ok += row.ok;
                evoqw_modelSums[row.model].vesum += row.vesum;                
                evoqw_modelSums[row.model].ng += row.ng;
                evoqw_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                evoqw_modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
                evoqw_modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
                evoqw_modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
                evoqw_modelSums[row.model].ng_weldingng += row.ng_weldingng;
                evoqw_modelSums[row.model].ng_finalslot += row.ng_finalslot;
                evoqw_modelSums[row.model].ng_framedamage += row.ng_framedamage;
                evoqw_modelSums[row.model].ng_eolreal += row.ng_eolreal;
                evoqw_modelSums[row.model].ng_eol2real += row.ng_eol2real;
                evoqw_modelSums[row.model].ng_coating += row.ng_coating;
                evoqw_modelSums[row.model].ng_coverhook += row.ng_coverhook;
                evoqw_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
                evoqw_modelSums[row.model].ng_connector += row.ng_connector;
                evoqw_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
                evoqw_modelSums[row.model].ng_etc += row.ng_etc;
                evoqw_modelSums[row.model].work_minute += row.work_minute;                                     
            }
        }

        //EV2020 60 Front
        let ev2020_60frpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0 };          
        let ev2020_60frdt_Sums = { time : 0 }        
        let ev2020_60frqw_Sums = { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, }        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ev2020_60fr_item.value) {            
            ev2020_60frpr_Sums.target += row.target;
            ev2020_60frpr_Sums.input += row.input;
            ev2020_60frpr_Sums.ok += row.ok;            
            ev2020_60frpr_Sums.ng += row.ng;
            ev2020_60frpr_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_60frpr_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_60frpr_Sums.ng_eol += row.ng_eol;
            ev2020_60frpr_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_60frpr_Sums.ng_slot += row.ng_slot;
            ev2020_60frpr_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_60frpr_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_60frpr_Sums.ng_others += row.ng_others;
            ev2020_60frpr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_60frpr_Sums.ng_test += row.ng_test;   
            ev2020_60frpr_Sums.work_minute += row.work_minute;
          }     
        for (const row of ev2020_60fr_dtItem.value) { ev2020_60frdt_Sums.time += row.time;  }
        for (const row of ev2020_60fr_qwItem.value) {
            ev2020_60frqw_Sums.target += row.target;
            ev2020_60frqw_Sums.input += row.input;
            ev2020_60frqw_Sums.ok += row.ok;            
            ev2020_60frqw_Sums.ng += row.ng;
            ev2020_60frqw_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_60frqw_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_60frqw_Sums.ng_eol += row.ng_eol;
            ev2020_60frqw_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_60frqw_Sums.ng_slot += row.ng_slot;
            ev2020_60frqw_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_60frqw_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_60frqw_Sums.ng_others += row.ng_others;
            ev2020_60frqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_60frqw_Sums.ng_test += row.ng_test;    
            ev2020_60frqw_Sums.work_minute += row.work_minute;  
        }

        //EV2020 60 Rear
        let ev2020_60rrpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0 };          
        let ev2020_60rrdt_Sums = { time : 0 }        
        let ev2020_60rrqw_Sums = { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, }        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ev2020_60rr_item.value) {            
            ev2020_60rrpr_Sums.target += row.target;
            ev2020_60rrpr_Sums.input += row.input;
            ev2020_60rrpr_Sums.ok += row.ok;            
            ev2020_60rrpr_Sums.ng += row.ng;
            ev2020_60rrpr_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_60rrpr_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_60rrpr_Sums.ng_eol += row.ng_eol;
            ev2020_60rrpr_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_60rrpr_Sums.ng_slot += row.ng_slot;
            ev2020_60rrpr_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_60rrpr_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_60rrpr_Sums.ng_others += row.ng_others;
            ev2020_60rrpr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_60rrpr_Sums.ng_test += row.ng_test;   
            ev2020_60rrpr_Sums.work_minute += row.work_minute;
          }     
        for (const row of ev2020_60rr_dtItem.value) { ev2020_60rrdt_Sums.time += row.time;  }
        for (const row of ev2020_60rr_qwItem.value) {
            ev2020_60rrqw_Sums.target += row.target;
            ev2020_60rrqw_Sums.input += row.input;
            ev2020_60rrqw_Sums.ok += row.ok;            
            ev2020_60rrqw_Sums.ng += row.ng;
            ev2020_60rrqw_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_60rrqw_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_60rrqw_Sums.ng_eol += row.ng_eol;
            ev2020_60rrqw_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_60rrqw_Sums.ng_slot += row.ng_slot;
            ev2020_60rrqw_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_60rrqw_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_60rrqw_Sums.ng_others += row.ng_others;
            ev2020_60rrqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_60rrqw_Sums.ng_test += row.ng_test;     
            ev2020_60rrqw_Sums.work_minute += row.work_minute;  
        }

        //EV2020 4060 Front
        let ev2020_4060frpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0 };  
        let ev2020_4060frpr_modelSums = {   
            "40kW Front": { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0 },                
            "60kW Front": { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0},                             
        };
        let ev2020_4060frdt_Sums = { time : 0 }
        let ev2020_4060frdt_modelSums = { "40kW Front": { time: 0}, "60kW Front": { time: 0}, };
        let ev2020_4060frqw_Sums = { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, }
        let ev2020_4060frqw_modelSums = {
            "40kW Front": { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, },    
            "60kW Front": { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, },                
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ev2020_4060fr_item.value) {            
            ev2020_4060frpr_Sums.target += row.target;
            ev2020_4060frpr_Sums.input += row.input;
            ev2020_4060frpr_Sums.ok += row.ok;            
            ev2020_4060frpr_Sums.ng += row.ng;
            ev2020_4060frpr_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_4060frpr_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_4060frpr_Sums.ng_eol += row.ng_eol;
            ev2020_4060frpr_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_4060frpr_Sums.ng_slot += row.ng_slot;
            ev2020_4060frpr_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_4060frpr_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_4060frpr_Sums.ng_others += row.ng_others;
            ev2020_4060frpr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_4060frpr_Sums.ng_test += row.ng_test;            
            ev2020_4060frpr_Sums.work_minute += row.work_minute;
          }     

        for (const row of ev2020_4060fr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ev2020_4060frpr_modelSums, row.model)) {
                ev2020_4060frpr_modelSums[row.model].target += row.target;
                ev2020_4060frpr_modelSums[row.model].input += row.input;
                ev2020_4060frpr_modelSums[row.model].ok += row.ok;            
                ev2020_4060frpr_modelSums[row.model].ng += row.ng;
                ev2020_4060frpr_modelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
                ev2020_4060frpr_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                ev2020_4060frpr_modelSums[row.model].ng_eol += row.ng_eol;
                ev2020_4060frpr_modelSums[row.model].ng_laserwelding += row.ng_laserwelding;
                ev2020_4060frpr_modelSums[row.model].ng_slot += row.ng_slot;
                ev2020_4060frpr_modelSums[row.model].ng_holepositionjig += row.ng_holepositionjig;
                ev2020_4060frpr_modelSums[row.model].ng_finalinspection += row.ng_finalinspection;
                ev2020_4060frpr_modelSums[row.model].ng_others += row.ng_others;
                ev2020_4060frpr_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;            
                ev2020_4060frpr_modelSums[row.model].ng_test += row.ng_test;  
                ev2020_4060frpr_modelSums[row.model].work_minute += row.work_minute;                        
            }
        }
        for (const row of ev2020_4060fr_dtItem.value) { ev2020_4060frdt_Sums.time += row.time;  }
        for (const row of ev2020_4060fr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ev2020_4060frdt_modelSums, row.model)) {
                ev2020_4060frdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of ev2020_4060fr_qwItem.value) {
            ev2020_4060frqw_Sums.target += row.target;
            ev2020_4060frqw_Sums.input += row.input;
            ev2020_4060frqw_Sums.ok += row.ok;            
            ev2020_4060frqw_Sums.ng += row.ng;
            ev2020_4060frqw_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_4060frqw_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_4060frqw_Sums.ng_eol += row.ng_eol;
            ev2020_4060frqw_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_4060frqw_Sums.ng_slot += row.ng_slot;
            ev2020_4060frqw_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_4060frqw_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_4060frqw_Sums.ng_others += row.ng_others;
            ev2020_4060frqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_4060frqw_Sums.ng_test += row.ng_test;  
            ev2020_4060frqw_Sums.work_minute += row.work_minute; 
        }
        for (const row of ev2020_4060fr_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(ev2020_4060frqw_modelSums, row.model)) {            
                ev2020_4060frqw_modelSums[row.model].ok += row.ok;            
                ev2020_4060frqw_modelSums[row.model].ng += row.ng;
                ev2020_4060frqw_modelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
                ev2020_4060frqw_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                ev2020_4060frqw_modelSums[row.model].ng_eol += row.ng_eol;
                ev2020_4060frqw_modelSums[row.model].ng_laserwelding += row.ng_laserwelding;
                ev2020_4060frqw_modelSums[row.model].ng_slot += row.ng_slot;
                ev2020_4060frqw_modelSums[row.model].ng_holepositionjig += row.ng_holepositionjig;
                ev2020_4060frqw_modelSums[row.model].ng_finalinspection += row.ng_finalinspection;
                ev2020_4060frqw_modelSums[row.model].ng_others += row.ng_others;
                ev2020_4060frqw_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;            
                ev2020_4060frqw_modelSums[row.model].ng_test += row.ng_test;
            }
        }

        //EV2020 4060 Rear
        let ev2020_4060rrpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0 };  
        let ev2020_4060rrpr_modelSums = {   
            "40kW Rear": { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0 },                
            "60kW Rear": { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, work_minute: 0},                             
        };
        let ev2020_4060rrdt_Sums = { time : 0 }
        let ev2020_4060rrdt_modelSums = { "40kW Rear": { time: 0}, "60kW Rear": { time: 0}, };
        let ev2020_4060rrqw_Sums = { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, }
        let ev2020_4060rrqw_modelSums = {
            "40kW Rear": { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, },    
            "60kW Rear": { ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0, ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, ng_weldingpeeltest: 0, ng_test: 0, },                
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ev2020_4060rr_item.value) {            
            ev2020_4060rrpr_Sums.target += row.target;
            ev2020_4060rrpr_Sums.input += row.input;
            ev2020_4060rrpr_Sums.ok += row.ok;            
            ev2020_4060rrpr_Sums.ng += row.ng;
            ev2020_4060rrpr_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_4060rrpr_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_4060rrpr_Sums.ng_eol += row.ng_eol;
            ev2020_4060rrpr_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_4060rrpr_Sums.ng_slot += row.ng_slot;
            ev2020_4060rrpr_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_4060rrpr_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_4060rrpr_Sums.ng_others += row.ng_others;
            ev2020_4060rrpr_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_4060rrpr_Sums.ng_test += row.ng_test;    
            ev2020_4060rrpr_Sums.work_minute += row.work_minute;
          }     

        for (const row of ev2020_4060rr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ev2020_4060rrpr_modelSums, row.model)) {
                ev2020_4060rrpr_modelSums[row.model].target += row.target;
                ev2020_4060rrpr_modelSums[row.model].input += row.input;
                ev2020_4060rrpr_modelSums[row.model].ok += row.ok;            
                ev2020_4060rrpr_modelSums[row.model].ng += row.ng;
                ev2020_4060rrpr_modelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
                ev2020_4060rrpr_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                ev2020_4060rrpr_modelSums[row.model].ng_eol += row.ng_eol;
                ev2020_4060rrpr_modelSums[row.model].ng_laserwelding += row.ng_laserwelding;
                ev2020_4060rrpr_modelSums[row.model].ng_slot += row.ng_slot;
                ev2020_4060rrpr_modelSums[row.model].ng_holepositionjig += row.ng_holepositionjig;
                ev2020_4060rrpr_modelSums[row.model].ng_finalinspection += row.ng_finalinspection;
                ev2020_4060rrpr_modelSums[row.model].ng_others += row.ng_others;
                ev2020_4060rrpr_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;            
                ev2020_4060rrpr_modelSums[row.model].ng_test += row.ng_test;     
                ev2020_4060rrpr_modelSums[row.model].work_minute += row.work_minute;                        
            }
        }
        for (const row of ev2020_4060rr_dtItem.value) { ev2020_4060rrdt_Sums.time += row.time;  }
        for (const row of ev2020_4060rr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ev2020_4060rrdt_modelSums, row.model)) {
                ev2020_4060rrdt_modelSums[row.model].time += row.time;                
            }
        }

        for (const row of ev2020_4060rr_qwItem.value) {
            ev2020_4060rrqw_Sums.target += row.target;
            ev2020_4060rrqw_Sums.input += row.input;
            ev2020_4060rrqw_Sums.ok += row.ok;            
            ev2020_4060rrqw_Sums.ng += row.ng;
            ev2020_4060rrqw_Sums.ng_busbarassembly += row.ng_busbarassembly;
            ev2020_4060rrqw_Sums.ng_heatstacking += row.ng_heatstacking;
            ev2020_4060rrqw_Sums.ng_eol += row.ng_eol;
            ev2020_4060rrqw_Sums.ng_laserwelding += row.ng_laserwelding;
            ev2020_4060rrqw_Sums.ng_slot += row.ng_slot;
            ev2020_4060rrqw_Sums.ng_holepositionjig += row.ng_holepositionjig;
            ev2020_4060rrqw_Sums.ng_finalinspection += row.ng_finalinspection;
            ev2020_4060rrqw_Sums.ng_others += row.ng_others;
            ev2020_4060rrqw_Sums.ng_weldingpeeltest += row.ng_weldingpeeltest;            
            ev2020_4060rrqw_Sums.ng_test += row.ng_test;    
            ev2020_4060rrqw_Sums.work_minute += row.work_minute; 
        }
        for (const row of ev2020_4060rr_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(ev2020_4060rrqw_modelSums, row.model)) {
                ev2020_4060rrqw_modelSums[row.model].ok += row.ok;            
                ev2020_4060rrqw_modelSums[row.model].ng += row.ng;
                ev2020_4060rrqw_modelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
                ev2020_4060rrqw_modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
                ev2020_4060rrqw_modelSums[row.model].ng_eol += row.ng_eol;
                ev2020_4060rrqw_modelSums[row.model].ng_laserwelding += row.ng_laserwelding;
                ev2020_4060rrqw_modelSums[row.model].ng_slot += row.ng_slot;
                ev2020_4060rrqw_modelSums[row.model].ng_holepositionjig += row.ng_holepositionjig;
                ev2020_4060rrqw_modelSums[row.model].ng_finalinspection += row.ng_finalinspection;
                ev2020_4060rrqw_modelSums[row.model].ng_others += row.ng_others;
                ev2020_4060rrqw_modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;                            
                ev2020_4060rrqw_modelSums[row.model].ng_test += row.ng_test;    
            }
        }
        //Ford #1 Front
        let ford1frpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0};  
        let ford1frpr_modelSums = {        
            "4P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "4P7S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P10S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},               
        };
        let ford1frdt_Sums = { time : 0 }
        let ford1frdt_modelSums = { "4P8S": { time: 0}, "4P7S": { time: 0}, "3P10S": { time: 0}, "3P8S": { time: 0},};        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ford1fr_item.value) {            
            ford1frpr_Sums.target += row.target;
            ford1frpr_Sums.input += row.input;
            ford1frpr_Sums.ok += row.ok;
            ford1frpr_Sums.ng += row.ng;
            ford1frpr_Sums.ng_lasermarkingsum += row.ng_lasermarkingsum ;
            ford1frpr_Sums.ng_middleinspsum += row.ng_middleinspsum ;
            ford1frpr_Sums.ng_aoingsum += row.ng_aoingsum;
            ford1frpr_Sums.ng_coatingngsum += row.ng_coatingngsum;
            ford1frpr_Sums.ng_eolngsum += row.ng_eolngsum;
            ford1frpr_Sums.ng_lqcngsum += row.ng_lqcngsum;
            ford1frpr_Sums.ng_finalinspngsum += row.ng_finalinspngsum;
            ford1frpr_Sums.fn_lasermarkingsum += row.fn_lasermarkingsum;
            ford1frpr_Sums.fn_middleinspsum += row.fn_middleinspsum;
            ford1frpr_Sums.fn_aoingsum += row.fn_aoingsum;
            ford1frpr_Sums.fn_coatingsum += row.fn_coatingsum;
            ford1frpr_Sums.fn_eolsum += row.fn_eolsum;
            ford1frpr_Sums.fn_lqcsum += row.fn_lqcsum;
            ford1frpr_Sums.fn_finalinspsum += row.fn_finalinspsum;            
            ford1frpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of ford1fr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ford1frpr_modelSums, row.model)) {
              ford1frpr_modelSums[row.model].target += row.target;
              ford1frpr_modelSums[row.model].input += row.input;
              ford1frpr_modelSums[row.model].ok += row.ok;
              ford1frpr_modelSums[row.model].ng += row.ng;
              ford1frpr_modelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum ;
              ford1frpr_modelSums[row.model].ng_middleinspsum += row.ng_middleinspsum ;
              ford1frpr_modelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              ford1frpr_modelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              ford1frpr_modelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              ford1frpr_modelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              ford1frpr_modelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              ford1frpr_modelSums[row.model].fn_lasermarkingsum += row.fn_lasermarkingsum;
              ford1frpr_modelSums[row.model].fn_middleinspsum += row.fn_middleinspsum;
              ford1frpr_modelSums[row.model].fn_aoingsum += row.fn_aoingsum;
              ford1frpr_modelSums[row.model].fn_coatingsum += row.fn_coatingsum;
              ford1frpr_modelSums[row.model].fn_eolsum += row.fn_eolsum;
              ford1frpr_modelSums[row.model].fn_lqcsum += row.fn_lqcsum;
              ford1frpr_modelSums[row.model].fn_finalinspsum += row.fn_finalinspsum;            
              ford1frpr_modelSums[row.model].work_minute += row.work_minute;              
            }
        }

        for (const row of ford1fr_dtItem.value) { ford1frdt_Sums.time += row.time;  }
        for (const row of ford1fr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ford1frdt_modelSums, row.model)) {
                ford1frdt_modelSums[row.model].time += row.time;                
            }
        }

        //Ford #1 rear
        let ford1rrpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0};  
        let ford1rrpr_modelSums = {        
            "4P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "4P7S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P10S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},               
        };
        let ford1rrdt_Sums = { time : 0 }
        let ford1rrdt_modelSums = { "4P8S": { time: 0}, "4P7S": { time: 0}, "3P10S": { time: 0}, "3P8S": { time: 0},};        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ford1rr_item.value) {            
            ford1rrpr_Sums.target += row.target;
            ford1rrpr_Sums.input += row.input;
            ford1rrpr_Sums.ok += row.ok;
            ford1rrpr_Sums.ng += row.ng;
            ford1rrpr_Sums.ng_lasermarkingsum += row.ng_lasermarkingsum ;
            ford1rrpr_Sums.ng_middleinspsum += row.ng_middleinspsum ;
            ford1rrpr_Sums.ng_aoingsum += row.ng_aoingsum;
            ford1rrpr_Sums.ng_coatingngsum += row.ng_coatingngsum;
            ford1rrpr_Sums.ng_eolngsum += row.ng_eolngsum;
            ford1rrpr_Sums.ng_lqcngsum += row.ng_lqcngsum;
            ford1rrpr_Sums.ng_finalinspngsum += row.ng_finalinspngsum;
            ford1rrpr_Sums.fn_lasermarkingsum += row.fn_lasermarkingsum;
            ford1rrpr_Sums.fn_middleinspsum += row.fn_middleinspsum;
            ford1rrpr_Sums.fn_aoingsum += row.fn_aoingsum;
            ford1rrpr_Sums.fn_coatingsum += row.fn_coatingsum;
            ford1rrpr_Sums.fn_eolsum += row.fn_eolsum;
            ford1rrpr_Sums.fn_lqcsum += row.fn_lqcsum;
            ford1rrpr_Sums.fn_finalinspsum += row.fn_finalinspsum;            
            ford1rrpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of ford1rr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ford1rrpr_modelSums, row.model)) {
              ford1rrpr_modelSums[row.model].target += row.target;
              ford1rrpr_modelSums[row.model].input += row.input;
              ford1rrpr_modelSums[row.model].ok += row.ok;
              ford1rrpr_modelSums[row.model].ng += row.ng;
              ford1rrpr_modelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum ;
              ford1rrpr_modelSums[row.model].ng_middleinspsum += row.ng_middleinspsum ;
              ford1rrpr_modelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              ford1rrpr_modelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              ford1rrpr_modelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              ford1rrpr_modelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              ford1rrpr_modelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              ford1rrpr_modelSums[row.model].fn_lasermarkingsum += row.fn_lasermarkingsum;
              ford1rrpr_modelSums[row.model].fn_middleinspsum += row.fn_middleinspsum;
              ford1rrpr_modelSums[row.model].fn_aoingsum += row.fn_aoingsum;
              ford1rrpr_modelSums[row.model].fn_coatingsum += row.fn_coatingsum;
              ford1rrpr_modelSums[row.model].fn_eolsum += row.fn_eolsum;
              ford1rrpr_modelSums[row.model].fn_lqcsum += row.fn_lqcsum;
              ford1rrpr_modelSums[row.model].fn_finalinspsum += row.fn_finalinspsum;            
              ford1rrpr_modelSums[row.model].work_minute += row.work_minute;              
            }
        }

        for (const row of ford1rr_dtItem.value) { ford1rrdt_Sums.time += row.time;  }
        for (const row of ford1rr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ford1rrdt_modelSums, row.model)) {
                ford1rrdt_modelSums[row.model].time += row.time;                
            }
        }
        
        //Ford #2 Front
        let ford2frpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0};  
        let ford2frpr_modelSums = {        
            "4P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "4P7S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P10S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},               
        };
        let ford2frdt_Sums = { time : 0 }
        let ford2frdt_modelSums = { "4P8S": { time: 0}, "4P7S": { time: 0}, "3P10S": { time: 0}, "3P8S": { time: 0},};        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ford2fr_item.value) {            
            ford2frpr_Sums.target += row.target;
            ford2frpr_Sums.input += row.input;
            ford2frpr_Sums.ok += row.ok;
            ford2frpr_Sums.ng += row.ng;
            ford2frpr_Sums.ng_lasermarkingsum += row.ng_lasermarkingsum ;
            ford2frpr_Sums.ng_middleinspsum += row.ng_middleinspsum ;
            ford2frpr_Sums.ng_aoingsum += row.ng_aoingsum;
            ford2frpr_Sums.ng_coatingngsum += row.ng_coatingngsum;
            ford2frpr_Sums.ng_eolngsum += row.ng_eolngsum;
            ford2frpr_Sums.ng_lqcngsum += row.ng_lqcngsum;
            ford2frpr_Sums.ng_finalinspngsum += row.ng_finalinspngsum;
            ford2frpr_Sums.fn_lasermarkingsum += row.fn_lasermarkingsum;
            ford2frpr_Sums.fn_middleinspsum += row.fn_middleinspsum;
            ford2frpr_Sums.fn_aoingsum += row.fn_aoingsum;
            ford2frpr_Sums.fn_coatingsum += row.fn_coatingsum;
            ford2frpr_Sums.fn_eolsum += row.fn_eolsum;
            ford2frpr_Sums.fn_lqcsum += row.fn_lqcsum;
            ford2frpr_Sums.fn_finalinspsum += row.fn_finalinspsum;            
            ford2frpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of ford2fr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ford2frpr_modelSums, row.model)) {
              ford2frpr_modelSums[row.model].target += row.target;
              ford2frpr_modelSums[row.model].input += row.input;
              ford2frpr_modelSums[row.model].ok += row.ok;
              ford2frpr_modelSums[row.model].ng += row.ng;
              ford2frpr_modelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum ;
              ford2frpr_modelSums[row.model].ng_middleinspsum += row.ng_middleinspsum ;
              ford2frpr_modelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              ford2frpr_modelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              ford2frpr_modelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              ford2frpr_modelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              ford2frpr_modelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              ford2frpr_modelSums[row.model].fn_lasermarkingsum += row.fn_lasermarkingsum;
              ford2frpr_modelSums[row.model].fn_middleinspsum += row.fn_middleinspsum;
              ford2frpr_modelSums[row.model].fn_aoingsum += row.fn_aoingsum;
              ford2frpr_modelSums[row.model].fn_coatingsum += row.fn_coatingsum;
              ford2frpr_modelSums[row.model].fn_eolsum += row.fn_eolsum;
              ford2frpr_modelSums[row.model].fn_lqcsum += row.fn_lqcsum;
              ford2frpr_modelSums[row.model].fn_finalinspsum += row.fn_finalinspsum;            
              ford2frpr_modelSums[row.model].work_minute += row.work_minute;              
            }
        }

        for (const row of ford2fr_dtItem.value) { ford2frdt_Sums.time += row.time;  }
        for (const row of ford2fr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ford2frdt_modelSums, row.model)) {
                ford2frdt_modelSums[row.model].time += row.time;                
            }
        }
        
        //Ford #2 rear
        let ford2rrpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0};  
        let ford2rrpr_modelSums = {        
            "4P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "4P7S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P10S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},               
        };
        let ford2rrdt_Sums = { time : 0 }
        let ford2rrdt_modelSums = { "4P8S": { time: 0}, "4P7S": { time: 0}, "3P10S": { time: 0}, "3P8S": { time: 0},};        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ford2rr_item.value) {            
            ford2rrpr_Sums.target += row.target;
            ford2rrpr_Sums.input += row.input;
            ford2rrpr_Sums.ok += row.ok;
            ford2rrpr_Sums.ng += row.ng;
            ford2rrpr_Sums.ng_lasermarkingsum += row.ng_lasermarkingsum ;
            ford2rrpr_Sums.ng_middleinspsum += row.ng_middleinspsum ;
            ford2rrpr_Sums.ng_aoingsum += row.ng_aoingsum;
            ford2rrpr_Sums.ng_coatingngsum += row.ng_coatingngsum;
            ford2rrpr_Sums.ng_eolngsum += row.ng_eolngsum;
            ford2rrpr_Sums.ng_lqcngsum += row.ng_lqcngsum;
            ford2rrpr_Sums.ng_finalinspngsum += row.ng_finalinspngsum;
            ford2rrpr_Sums.fn_lasermarkingsum += row.fn_lasermarkingsum;
            ford2rrpr_Sums.fn_middleinspsum += row.fn_middleinspsum;
            ford2rrpr_Sums.fn_aoingsum += row.fn_aoingsum;
            ford2rrpr_Sums.fn_coatingsum += row.fn_coatingsum;
            ford2rrpr_Sums.fn_eolsum += row.fn_eolsum;
            ford2rrpr_Sums.fn_lqcsum += row.fn_lqcsum;
            ford2rrpr_Sums.fn_finalinspsum += row.fn_finalinspsum;            
            ford2rrpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of ford2rr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ford2rrpr_modelSums, row.model)) {
              ford2rrpr_modelSums[row.model].target += row.target;
              ford2rrpr_modelSums[row.model].input += row.input;
              ford2rrpr_modelSums[row.model].ok += row.ok;
              ford2rrpr_modelSums[row.model].ng += row.ng;
              ford2rrpr_modelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum ;
              ford2rrpr_modelSums[row.model].ng_middleinspsum += row.ng_middleinspsum ;
              ford2rrpr_modelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              ford2rrpr_modelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              ford2rrpr_modelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              ford2rrpr_modelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              ford2rrpr_modelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              ford2rrpr_modelSums[row.model].fn_lasermarkingsum += row.fn_lasermarkingsum;
              ford2rrpr_modelSums[row.model].fn_middleinspsum += row.fn_middleinspsum;
              ford2rrpr_modelSums[row.model].fn_aoingsum += row.fn_aoingsum;
              ford2rrpr_modelSums[row.model].fn_coatingsum += row.fn_coatingsum;
              ford2rrpr_modelSums[row.model].fn_eolsum += row.fn_eolsum;
              ford2rrpr_modelSums[row.model].fn_lqcsum += row.fn_lqcsum;
              ford2rrpr_modelSums[row.model].fn_finalinspsum += row.fn_finalinspsum;            
              ford2rrpr_modelSums[row.model].work_minute += row.work_minute;              
            }
        }

        for (const row of ford2rr_dtItem.value) { ford2rrdt_Sums.time += row.time;  }
        for (const row of ford2rr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ford2rrdt_modelSums, row.model)) {
                ford2rrdt_modelSums[row.model].time += row.time;                
            }
        }
        
        //Ford #3 Front
        let ford3frpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0};  
        let ford3frpr_modelSums = {        
            "4P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "4P7S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P10S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},               
        };
        let ford3frdt_Sums = { time : 0 }
        let ford3frdt_modelSums = { "4P8S": { time: 0}, "4P7S": { time: 0}, "3P10S": { time: 0}, "3P8S": { time: 0},};        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ford3fr_item.value) {            
            ford3frpr_Sums.target += row.target;
            ford3frpr_Sums.input += row.input;
            ford3frpr_Sums.ok += row.ok;
            ford3frpr_Sums.ng += row.ng;
            ford3frpr_Sums.ng_lasermarkingsum += row.ng_lasermarkingsum ;
            ford3frpr_Sums.ng_middleinspsum += row.ng_middleinspsum ;
            ford3frpr_Sums.ng_aoingsum += row.ng_aoingsum;
            ford3frpr_Sums.ng_coatingngsum += row.ng_coatingngsum;
            ford3frpr_Sums.ng_eolngsum += row.ng_eolngsum;
            ford3frpr_Sums.ng_lqcngsum += row.ng_lqcngsum;
            ford3frpr_Sums.ng_finalinspngsum += row.ng_finalinspngsum;
            ford3frpr_Sums.fn_lasermarkingsum += row.fn_lasermarkingsum;
            ford3frpr_Sums.fn_middleinspsum += row.fn_middleinspsum;
            ford3frpr_Sums.fn_aoingsum += row.fn_aoingsum;
            ford3frpr_Sums.fn_coatingsum += row.fn_coatingsum;
            ford3frpr_Sums.fn_eolsum += row.fn_eolsum;
            ford3frpr_Sums.fn_lqcsum += row.fn_lqcsum;
            ford3frpr_Sums.fn_finalinspsum += row.fn_finalinspsum;            
            ford3frpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of ford3fr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ford3frpr_modelSums, row.model)) {
              ford3frpr_modelSums[row.model].target += row.target;
              ford3frpr_modelSums[row.model].input += row.input;
              ford3frpr_modelSums[row.model].ok += row.ok;
              ford3frpr_modelSums[row.model].ng += row.ng;
              ford3frpr_modelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum ;
              ford3frpr_modelSums[row.model].ng_middleinspsum += row.ng_middleinspsum ;
              ford3frpr_modelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              ford3frpr_modelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              ford3frpr_modelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              ford3frpr_modelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              ford3frpr_modelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              ford3frpr_modelSums[row.model].fn_lasermarkingsum += row.fn_lasermarkingsum;
              ford3frpr_modelSums[row.model].fn_middleinspsum += row.fn_middleinspsum;
              ford3frpr_modelSums[row.model].fn_aoingsum += row.fn_aoingsum;
              ford3frpr_modelSums[row.model].fn_coatingsum += row.fn_coatingsum;
              ford3frpr_modelSums[row.model].fn_eolsum += row.fn_eolsum;
              ford3frpr_modelSums[row.model].fn_lqcsum += row.fn_lqcsum;
              ford3frpr_modelSums[row.model].fn_finalinspsum += row.fn_finalinspsum;            
              ford3frpr_modelSums[row.model].work_minute += row.work_minute;              
            }
        }

        for (const row of ford3fr_dtItem.value) { ford3frdt_Sums.time += row.time;  }
        for (const row of ford3fr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ford3frdt_modelSums, row.model)) {
                ford3frdt_modelSums[row.model].time += row.time;                
            }
        }
        
        //Ford #3 rear
        let ford3rrpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0};  
        let ford3rrpr_modelSums = {        
            "4P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "4P7S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P10S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},    
            "3P8S": { target: 0, input: 0, ok: 0, ng: 0, ng_lasermarkingsum: 0, ng_middleinspsum: 0, ng_aoingsum: 0, ng_coatingngsum: 0, ng_eolngsum: 0, ng_lqcngsum:0, ng_finalinspngsum: 0, fn_lasermarkingsum: 0, fn_middleinspsum: 0, fn_aoingsum: 0, fn_coatingsum: 0, fn_eolsum: 0, fn_lqcsum: 0, fn_finalinspsum: 0, work_minute: 0},               
        };
        let ford3rrdt_Sums = { time : 0 }
        let ford3rrdt_modelSums = { "4P8S": { time: 0}, "4P7S": { time: 0}, "3P10S": { time: 0}, "3P8S": { time: 0},};        
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of ford3rr_item.value) {            
            ford3rrpr_Sums.target += row.target;
            ford3rrpr_Sums.input += row.input;
            ford3rrpr_Sums.ok += row.ok;
            ford3rrpr_Sums.ng += row.ng;
            ford3rrpr_Sums.ng_lasermarkingsum += row.ng_lasermarkingsum ;
            ford3rrpr_Sums.ng_middleinspsum += row.ng_middleinspsum ;
            ford3rrpr_Sums.ng_aoingsum += row.ng_aoingsum;
            ford3rrpr_Sums.ng_coatingngsum += row.ng_coatingngsum;
            ford3rrpr_Sums.ng_eolngsum += row.ng_eolngsum;
            ford3rrpr_Sums.ng_lqcngsum += row.ng_lqcngsum;
            ford3rrpr_Sums.ng_finalinspngsum += row.ng_finalinspngsum;
            ford3rrpr_Sums.fn_lasermarkingsum += row.fn_lasermarkingsum;
            ford3rrpr_Sums.fn_middleinspsum += row.fn_middleinspsum;
            ford3rrpr_Sums.fn_aoingsum += row.fn_aoingsum;
            ford3rrpr_Sums.fn_coatingsum += row.fn_coatingsum;
            ford3rrpr_Sums.fn_eolsum += row.fn_eolsum;
            ford3rrpr_Sums.fn_lqcsum += row.fn_lqcsum;
            ford3rrpr_Sums.fn_finalinspsum += row.fn_finalinspsum;            
            ford3rrpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of ford3rr_item.value) {
            if (Object.prototype.hasOwnProperty.call(ford3rrpr_modelSums, row.model)) {
              ford3rrpr_modelSums[row.model].target += row.target;
              ford3rrpr_modelSums[row.model].input += row.input;
              ford3rrpr_modelSums[row.model].ok += row.ok;
              ford3rrpr_modelSums[row.model].ng += row.ng;
              ford3rrpr_modelSums[row.model].ng_lasermarkingsum += row.ng_lasermarkingsum ;
              ford3rrpr_modelSums[row.model].ng_middleinspsum += row.ng_middleinspsum ;
              ford3rrpr_modelSums[row.model].ng_aoingsum += row.ng_aoingsum;
              ford3rrpr_modelSums[row.model].ng_coatingngsum += row.ng_coatingngsum;
              ford3rrpr_modelSums[row.model].ng_eolngsum += row.ng_eolngsum;
              ford3rrpr_modelSums[row.model].ng_lqcngsum += row.ng_lqcngsum;
              ford3rrpr_modelSums[row.model].ng_finalinspngsum += row.ng_finalinspngsum;
              ford3rrpr_modelSums[row.model].fn_lasermarkingsum += row.fn_lasermarkingsum;
              ford3rrpr_modelSums[row.model].fn_middleinspsum += row.fn_middleinspsum;
              ford3rrpr_modelSums[row.model].fn_aoingsum += row.fn_aoingsum;
              ford3rrpr_modelSums[row.model].fn_coatingsum += row.fn_coatingsum;
              ford3rrpr_modelSums[row.model].fn_eolsum += row.fn_eolsum;
              ford3rrpr_modelSums[row.model].fn_lqcsum += row.fn_lqcsum;
              ford3rrpr_modelSums[row.model].fn_finalinspsum += row.fn_finalinspsum;            
              ford3rrpr_modelSums[row.model].work_minute += row.work_minute;              
            }
        }

        for (const row of ford3rr_dtItem.value) { ford3rrdt_Sums.time += row.time;  }
        for (const row of ford3rr_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(ford3rrdt_modelSums, row.model)) {
                ford3rrdt_modelSums[row.model].time += row.time;                
            }
        }        

        //FCA #1
        let fca1pr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_aoi: 0, fn_coating: 0, fn_eol: 0, fn_final: 0, work_minute: 0};  
        let fca1pr_modelSums = {        
            "Front": { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_aoi: 0, fn_coating: 0, fn_eol: 0, fn_final: 0, work_minute: 0},    
            "Rear": { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_aoi: 0, fn_coating: 0, fn_eol: 0, fn_final: 0, work_minute: 0},                
        };
        let fca1dt_Sums = { time : 0 }
        let fca1dt_modelSums = { "Front": { time: 0}, "Rear": { time: 0}, };
        let fca1qw_Sums = {ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0,}
        let fca1qw_modelSums = {
            "Front": { ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0,},    
            "Rear": { ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0,},             
        };
        
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of fca1_item.value) {            
            fca1pr_Sums.target += row.target;
            fca1pr_Sums.input += row.input;
            fca1pr_Sums.ok += row.ok;
            fca1pr_Sums.ng += row.ng;
            fca1pr_Sums.ng_assembly += row.ng_assembly;
            fca1pr_Sums.ng_soldering += row.ng_soldering;
            fca1pr_Sums.ng_aoi += row.ng_aoi;
            fca1pr_Sums.ng_coating += row.ng_coating;
            fca1pr_Sums.ng_eol += row.ng_eol;
            fca1pr_Sums.ng_final += row.ng_final;
            fca1pr_Sums.fn_assembly += row.fn_assembly;
            fca1pr_Sums.fn_soldering += row.fn_soldering;
            fca1pr_Sums.fn_aoi += row.fn_aoi;
            fca1pr_Sums.fn_coating += row.fn_coating;
            fca1pr_Sums.fn_eol += row.fn_eol;
            fca1pr_Sums.fn_final += row.fn_final;
            fca1pr_Sums.work_minute += row.work_minute;
          }             
        for (const row of fca1_item.value) {
            if (Object.prototype.hasOwnProperty.call(fca1pr_modelSums, row.model)) {
                fca1pr_modelSums[row.model].target += row.target;
                fca1pr_modelSums[row.model].input += row.input;
                fca1pr_modelSums[row.model].ok += row.ok;
                fca1pr_modelSums[row.model].ng += row.ng;
                fca1pr_modelSums[row.model].ng_assembly += row.ng_assembly;
                fca1pr_modelSums[row.model].ng_soldering += row.ng_soldering;
                fca1pr_modelSums[row.model].ng_aoi += row.ng_aoi;
                fca1pr_modelSums[row.model].ng_coating += row.ng_coating;
                fca1pr_modelSums[row.model].ng_eol += row.ng_eol;
                fca1pr_modelSums[row.model].ng_final += row.ng_final;
                fca1pr_modelSums[row.model].fn_assembly += row.fn_assembly;
                fca1pr_modelSums[row.model].fn_soldering += row.fn_soldering;
                fca1pr_modelSums[row.model].fn_aoi += row.fn_aoi;
                fca1pr_modelSums[row.model].fn_coating += row.fn_coating;
                fca1pr_modelSums[row.model].fn_eol += row.fn_eol;
                fca1pr_modelSums[row.model].fn_final += row.fn_final;
                fca1pr_modelSums[row.model].work_minute += row.work_minute;                
            }
        }

        for (const row of fca1_dtItem.value) { fca1dt_Sums.time += row.time;  }
        for (const row of fca1_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(fca1dt_modelSums, row.model)) {
                fca1dt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of fca1_qwItem.value) {
            fca1qw_Sums.ok += row.ok;
            fca1qw_Sums.ng += row.ng;
            fca1qw_Sums.ng_assembly += row.ng_assembly;
            fca1qw_Sums.ng_soldering += row.ng_soldering;
            fca1qw_Sums.ng_aoi += row.ng_aoi;
            fca1qw_Sums.ng_coating += row.ng_coating;
            fca1qw_Sums.ng_eol += row.ng_eol;
            fca1qw_Sums.ng_final += row.ng_final;
        }
        for (const row of fca1_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(fca1qw_modelSums, row.model)) {
                fca1qw_modelSums[row.model].ok += row.ok;
                fca1qw_modelSums[row.model].ng += row.ng;
                fca1qw_modelSums[row.model].ng_assembly += row.ng_assembly;
                fca1qw_modelSums[row.model].ng_soldering += row.ng_soldering;
                fca1qw_modelSums[row.model].ng_aoi += row.ng_aoi;
                fca1qw_modelSums[row.model].ng_coating += row.ng_coating;
                fca1qw_modelSums[row.model].ng_eol += row.ng_eol;
                fca1qw_modelSums[row.model].ng_final += row.ng_final;          
            }
        } 
        //FCA #2
        let fca2pr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_aoi: 0, fn_coating: 0, fn_eol: 0, fn_final: 0, work_minute: 0};  
        let fca2pr_modelSums = {        
            "Front": { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_aoi: 0, fn_coating: 0, fn_eol: 0, fn_final: 0, work_minute: 0},    
            "Rear": { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_aoi: 0, fn_coating: 0, fn_eol: 0, fn_final: 0, work_minute: 0},                
        };
        let fca2dt_Sums = { time : 0 }
        let fca2dt_modelSums = { "Front": { time: 0}, "Rear": { time: 0}, };
        let fca2qw_Sums = {ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0,}
        let fca2qw_modelSums = {
            "Front": { ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0,},    
            "Rear": { ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_aoi: 0, ng_coating: 0, ng_eol: 0, ng_final: 0,},             
        };     
        //각 item 변수 별로 더할 Data들을 만들어야 함
        for (const row of fca2_item.value) {            
            fca2pr_Sums.target += row.target;
            fca2pr_Sums.input += row.input;
            fca2pr_Sums.ok += row.ok;
            fca2pr_Sums.ng += row.ng;
            fca2pr_Sums.ng_assembly += row.ng_assembly;
            fca2pr_Sums.ng_soldering += row.ng_soldering;
            fca2pr_Sums.ng_aoi += row.ng_aoi;
            fca2pr_Sums.ng_coating += row.ng_coating;
            fca2pr_Sums.ng_eol += row.ng_eol;
            fca2pr_Sums.ng_final += row.ng_final;
            fca2pr_Sums.fn_assembly += row.fn_assembly;
            fca2pr_Sums.fn_soldering += row.fn_soldering;
            fca2pr_Sums.fn_aoi += row.fn_aoi;
            fca2pr_Sums.fn_coating += row.fn_coating;
            fca2pr_Sums.fn_eol += row.fn_eol;
            fca2pr_Sums.fn_final += row.fn_final;
            fca2pr_Sums.work_minute += row.work_minute;
          }             
        for (const row of fca2_item.value) {
            if (Object.prototype.hasOwnProperty.call(fca2pr_modelSums, row.model)) {
                fca2pr_modelSums[row.model].target += row.target;
                fca2pr_modelSums[row.model].input += row.input;
                fca2pr_modelSums[row.model].ok += row.ok;
                fca2pr_modelSums[row.model].ng += row.ng;
                fca2pr_modelSums[row.model].ng_assembly += row.ng_assembly;
                fca2pr_modelSums[row.model].ng_soldering += row.ng_soldering;
                fca2pr_modelSums[row.model].ng_aoi += row.ng_aoi;
                fca2pr_modelSums[row.model].ng_coating += row.ng_coating;
                fca2pr_modelSums[row.model].ng_eol += row.ng_eol;
                fca2pr_modelSums[row.model].ng_final += row.ng_final;
                fca2pr_modelSums[row.model].fn_assembly += row.fn_assembly;
                fca2pr_modelSums[row.model].fn_soldering += row.fn_soldering;
                fca2pr_modelSums[row.model].fn_aoi += row.fn_aoi;
                fca2pr_modelSums[row.model].fn_coating += row.fn_coating;
                fca2pr_modelSums[row.model].fn_eol += row.fn_eol;
                fca2pr_modelSums[row.model].fn_final += row.fn_final;
                fca2pr_modelSums[row.model].work_minute += row.work_minute;                
            }
        }

        for (const row of fca2_dtItem.value) { fca2dt_Sums.time += row.time;  }
        for (const row of fca2_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(fca2dt_modelSums, row.model)) {
                fca2dt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of fca2_qwItem.value) {
            fca2qw_Sums.ok += row.ok;
            fca2qw_Sums.ng += row.ng;
            fca2qw_Sums.ng_assembly += row.ng_assembly;
            fca2qw_Sums.ng_soldering += row.ng_soldering;
            fca2qw_Sums.ng_aoi += row.ng_aoi;
            fca2qw_Sums.ng_coating += row.ng_coating;
            fca2qw_Sums.ng_eol += row.ng_eol;
            fca2qw_Sums.ng_final += row.ng_final;
        }
        for (const row of fca2_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(fca2qw_modelSums, row.model)) {
                fca2qw_modelSums[row.model].ok += row.ok;
                fca2qw_modelSums[row.model].ng += row.ng;
                fca2qw_modelSums[row.model].ng_assembly += row.ng_assembly;
                fca2qw_modelSums[row.model].ng_soldering += row.ng_soldering;
                fca2qw_modelSums[row.model].ng_aoi += row.ng_aoi;
                fca2qw_modelSums[row.model].ng_coating += row.ng_coating;
                fca2qw_modelSums[row.model].ng_eol += row.ng_eol;
                fca2qw_modelSums[row.model].ng_final += row.ng_final;          
            }
        }    
        //BT6
        let bt6pr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_flatness: 0, ng_vision: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_flatness: 0, fn_vision: 0, fn_eol: 0, fn_aoi: 0, work_minute: 0};  
        let bt6pr_modelSums = {        
            "Front": { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_flatness: 0, ng_vision: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_flatness: 0, fn_vision: 0, fn_eol: 0, fn_aoi: 0, work_minute: 0},    
            "Rear": { target: 0, input: 0, ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_flatness: 0, ng_vision: 0, ng_eol: 0, ng_final: 0, fn_assembly: 0, fn_soldering: 0, fn_flatness: 0, fn_vision: 0, fn_eol: 0, fn_aoi: 0, work_minute: 0},                
        };
        let bt6dt_Sums = { time : 0 }
        let bt6dt_modelSums = { "Front": { time: 0}, "Rear": { time: 0}, };
        let bt6qw_Sums = {ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_flatness: 0, ng_vision: 0, ng_eol: 0, ng_final: 0,}
        let bt6qw_modelSums = {
            "Front": { ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_flatness: 0, ng_vision: 0, ng_eol: 0, ng_final: 0,},    
            "Rear": { ok: 0, ng: 0, ng_assembly: 0, ng_soldering: 0, ng_flatness: 0, ng_vision: 0, ng_eol: 0, ng_final: 0,},             
        };
        for (const row of bt6_item.value) {            
            bt6pr_Sums.target += row.target;
            bt6pr_Sums.input += row.input;
            bt6pr_Sums.ok += row.ok;
            bt6pr_Sums.ng += row.ng;
            bt6pr_Sums.ng_assembly += row.ng_assembly;
            bt6pr_Sums.ng_soldering += row.ng_soldering;
            bt6pr_Sums.ng_flatness += row.ng_flatness;
            bt6pr_Sums.ng_eol += row.ng_eol;
            bt6pr_Sums.ng_vision += row.ng_vision;            
            bt6pr_Sums.ng_final += row.ng_final;
            bt6pr_Sums.fn_assembly += row.fn_assembly;
            bt6pr_Sums.fn_soldering += row.fn_soldering;
            bt6pr_Sums.fn_flatness += row.fn_flatness;
            bt6pr_Sums.fn_eol += row.fn_eol;
            bt6pr_Sums.fn_vision += row.fn_vision;            
            bt6pr_Sums.fn_aoi += row.fn_aoi;
            bt6pr_Sums.work_minute += row.work_minute;
          }             
        for (const row of bt6_item.value) {
            if (Object.prototype.hasOwnProperty.call(bt6pr_modelSums, row.model)) {
                bt6pr_modelSums[row.model].target += row.target;
                bt6pr_modelSums[row.model].input += row.input;
                bt6pr_modelSums[row.model].ok += row.ok;
                bt6pr_modelSums[row.model].ng += row.ng;
                bt6pr_modelSums[row.model].ng_assembly += row.ng_assembly;
                bt6pr_modelSums[row.model].ng_soldering += row.ng_soldering;
                bt6pr_modelSums[row.model].ng_flatness += row.ng_flatness;
                bt6pr_modelSums[row.model].ng_eol += row.ng_eol;
                bt6pr_modelSums[row.model].ng_vision += row.ng_vision;                
                bt6pr_modelSums[row.model].ng_final += row.ng_final;
                bt6pr_modelSums[row.model].fn_assembly += row.fn_assembly;
                bt6pr_modelSums[row.model].fn_soldering += row.fn_soldering;
                bt6pr_modelSums[row.model].fn_flatness += row.fn_flatness;
                bt6pr_modelSums[row.model].fn_eol += row.fn_eol;
                bt6pr_modelSums[row.model].fn_vision += row.fn_vision;                
                bt6pr_modelSums[row.model].fn_aoi += row.fn_aoi;
                bt6pr_modelSums[row.model].work_minute += row.work_minute;                
            }
        }

        for (const row of bt6_dtItem.value) { bt6dt_Sums.time += row.time;  }
        for (const row of bt6_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(bt6dt_modelSums, row.model)) {
                bt6dt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of bt6_qwItem.value) {
            bt6qw_Sums.ok += row.ok;
            bt6qw_Sums.ng += row.ng;
            bt6qw_Sums.ng_assembly += row.ng_assembly;
            bt6qw_Sums.ng_soldering += row.ng_soldering;
            bt6qw_Sums.ng_flatness += row.ng_flatness;
            bt6qw_Sums.ng_eol += row.ng_eol;
            bt6qw_Sums.ng_vision += row.ng_vision;            
            bt6qw_Sums.ng_final += row.ng_final;
        }
        for (const row of bt6_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(bt6qw_modelSums, row.model)) {
                bt6qw_modelSums[row.model].ok += row.ok;
                bt6qw_modelSums[row.model].ng += row.ng;
                bt6qw_modelSums[row.model].ng_assembly += row.ng_assembly;
                bt6qw_modelSums[row.model].ng_soldering += row.ng_soldering;
                bt6qw_modelSums[row.model].ng_flatness += row.ng_flatness;
                bt6qw_modelSums[row.model].ng_eol += row.ng_eol;
                bt6qw_modelSums[row.model].ng_vision += row.ng_vision;                
                bt6qw_modelSums[row.model].ng_final += row.ng_final;          
            }
        }  
        //BMW 12V
        let bmw12vpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0, fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0, work_minute: 0};  
        let bmw12vpr_modelSums = {        
            "F40 12V": { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0, fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0, work_minute: 0},    
            "HAF": { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0, fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0, work_minute: 0},                
        };
        let bmw12vdt_Sums = { time : 0 }
        let bmw12vdt_modelSums = { "F40 12V": { time: 0}, "HAF": { time: 0}, };
        let bmw12vqw_Sums = {ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,}
        let bmw12vqw_modelSums = {
            "F40 12V": { ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,},    
            "HAF": { ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,},             
        };
        for (const row of bmw12v_item.value) {      
            bmw12vpr_Sums.target += row.target;
            bmw12vpr_Sums.input += row.input;
            bmw12vpr_Sums.ok += row.ok;
            bmw12vpr_Sums.ng += row.ng;    
            bmw12vpr_Sums.ng_lackofcomponents += row.ng_lackofcomponents;
            bmw12vpr_Sums.ng_coatingtoomuch += row.ng_coatingtoomuch;
            bmw12vpr_Sums.ng_coatingtooless += row.ng_coatingtooless;
            bmw12vpr_Sums.ng_coatingbubbles += row.ng_coatingbubbles;
            bmw12vpr_Sums.ng_solderingtoomuch += row.ng_solderingtoomuch;
            bmw12vpr_Sums.ng_solderingtooless += row.ng_solderingtooless;
            bmw12vpr_Sums.ng_solderingballs += row.ng_solderingballs;
            bmw12vpr_Sums.ng_lackofbarcode += row.ng_lackofbarcode;
            bmw12vpr_Sums.ng_damagebarcode += row.ng_damagebarcode;
            bmw12vpr_Sums.ng_busbarscraches += row.ng_busbarscraches;
            bmw12vpr_Sums.ng_dirtybusbar += row.ng_dirtybusbar;
            bmw12vpr_Sums.ng_damagepcb += row.ng_damagepcb;
            bmw12vpr_Sums.ng_dirtyhousing += row.ng_dirtyhousing;
            bmw12vpr_Sums.ng_damagehousing += row.ng_damagehousing;
            bmw12vpr_Sums.ng_eol += row.ng_eol;
            bmw12vpr_Sums.ng_oqc += row.ng_oqc;              
            bmw12vpr_Sums.fn_soldering += row.fn_soldering;
            bmw12vpr_Sums.fn_coating += row.fn_coating;
            bmw12vpr_Sums.fn_eol += row.fn_eol;               
            bmw12vpr_Sums.fn_vision += row.fn_vision;
            bmw12vpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of bmw12v_item.value) {
            if (Object.prototype.hasOwnProperty.call(bmw12vpr_modelSums, row.model)) {
                bmw12vpr_modelSums[row.model].target += row.target;
                bmw12vpr_modelSums[row.model].input += row.input;
                bmw12vpr_modelSums[row.model].ok += row.ok;
                bmw12vpr_modelSums[row.model].ng += row.ng;    
                bmw12vpr_modelSums[row.model].ng_lackofcomponents += row.ng_lackofcomponents;
                bmw12vpr_modelSums[row.model].ng_coatingtoomuch += row.ng_coatingtoomuch;
                bmw12vpr_modelSums[row.model].ng_coatingtooless += row.ng_coatingtooless;
                bmw12vpr_modelSums[row.model].ng_coatingbubbles += row.ng_coatingbubbles;
                bmw12vpr_modelSums[row.model].ng_solderingtoomuch += row.ng_solderingtoomuch;
                bmw12vpr_modelSums[row.model].ng_solderingtooless += row.ng_solderingtooless;
                bmw12vpr_modelSums[row.model].ng_solderingballs += row.ng_solderingballs;
                bmw12vpr_modelSums[row.model].ng_lackofbarcode += row.ng_lackofbarcode;
                bmw12vpr_modelSums[row.model].ng_damagebarcode += row.ng_damagebarcode;
                bmw12vpr_modelSums[row.model].ng_busbarscraches += row.ng_busbarscraches;
                bmw12vpr_modelSums[row.model].ng_dirtybusbar += row.ng_dirtybusbar;
                bmw12vpr_modelSums[row.model].ng_damagepcb += row.ng_damagepcb;
                bmw12vpr_modelSums[row.model].ng_dirtyhousing += row.ng_dirtyhousing;
                bmw12vpr_modelSums[row.model].ng_damagehousing += row.ng_damagehousing;
                bmw12vpr_modelSums[row.model].ng_eol += row.ng_eol;
                bmw12vpr_modelSums[row.model].ng_oqc += row.ng_oqc;              
                bmw12vpr_modelSums[row.model].fn_soldering += row.fn_soldering;
                bmw12vpr_modelSums[row.model].fn_coating += row.fn_coating;
                bmw12vpr_modelSums[row.model].fn_eol += row.fn_eol;               
                bmw12vpr_modelSums[row.model].fn_vision += row.fn_vision;
                bmw12vpr_modelSums[row.model].work_minute += row.work_minute;                
            }
        }

        for (const row of bmw12v_dtItem.value) { bmw12vdt_Sums.time += row.time;  }
        for (const row of bmw12v_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(bmw12vdt_modelSums, row.model)) {
                bmw12vdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of bmw12v_qwItem.value) {
            bmw12vqw_Sums.ok += row.ok;
            bmw12vqw_Sums.ng += row.ng;
            bmw12vqw_Sums.ng_lackofcomponents += row.ng_lackofcomponents;
            bmw12vqw_Sums.ng_coatingtoomuch += row.ng_coatingtoomuch;
            bmw12vqw_Sums.ng_coatingtooless += row.ng_coatingtooless;
            bmw12vqw_Sums.ng_coatingbubbles += row.ng_coatingbubbles;
            bmw12vqw_Sums.ng_solderingtoomuch += row.ng_solderingtoomuch;
            bmw12vqw_Sums.ng_solderingtooless += row.ng_solderingtooless;
            bmw12vqw_Sums.ng_solderingballs += row.ng_solderingballs;
            bmw12vqw_Sums.ng_lackofbarcode += row.ng_lackofbarcode;
            bmw12vqw_Sums.ng_damagebarcode += row.ng_damagebarcode;
            bmw12vqw_Sums.ng_busbarscraches += row.ng_busbarscraches;
            bmw12vqw_Sums.ng_dirtybusbar += row.ng_dirtybusbar;
            bmw12vqw_Sums.ng_damagepcb += row.ng_damagepcb;
            bmw12vqw_Sums.ng_dirtyhousing += row.ng_dirtyhousing;
            bmw12vqw_Sums.ng_damagehousing += row.ng_damagehousing;
            bmw12vqw_Sums.ng_eol += row.ng_eol;
            bmw12vqw_Sums.ng_oqc += row.ng_oqc;  
        }
        for (const row of bmw12v_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(bmw12vqw_modelSums, row.model)) {
                bmw12vqw_modelSums[row.model].ok += row.ok;
                bmw12vqw_modelSums[row.model].ng += row.ng;
                bmw12vqw_modelSums[row.model].ng_lackofcomponents += row.ng_lackofcomponents;
                bmw12vqw_modelSums[row.model].ng_coatingtoomuch += row.ng_coatingtoomuch;
                bmw12vqw_modelSums[row.model].ng_coatingtooless += row.ng_coatingtooless;
                bmw12vqw_modelSums[row.model].ng_coatingbubbles += row.ng_coatingbubbles;
                bmw12vqw_modelSums[row.model].ng_solderingtoomuch += row.ng_solderingtoomuch;
                bmw12vqw_modelSums[row.model].ng_solderingtooless += row.ng_solderingtooless;
                bmw12vqw_modelSums[row.model].ng_solderingballs += row.ng_solderingballs;
                bmw12vqw_modelSums[row.model].ng_lackofbarcode += row.ng_lackofbarcode;
                bmw12vqw_modelSums[row.model].ng_damagebarcode += row.ng_damagebarcode;
                bmw12vqw_modelSums[row.model].ng_busbarscraches += row.ng_busbarscraches;
                bmw12vqw_modelSums[row.model].ng_dirtybusbar += row.ng_dirtybusbar;
                bmw12vqw_modelSums[row.model].ng_damagepcb += row.ng_damagepcb;
                bmw12vqw_modelSums[row.model].ng_dirtyhousing += row.ng_dirtyhousing;
                bmw12vqw_modelSums[row.model].ng_damagehousing += row.ng_damagehousing;
                bmw12vqw_modelSums[row.model].ng_eol += row.ng_eol;
                bmw12vqw_modelSums[row.model].ng_oqc += row.ng_oqc;       
            }
        }  
        //BMW 48V
        let bmw48vpr_Sums = { target: 0, input: 0, ok_busbarassembly: 0, ng_busbarassembly: 0, ok_pcs: 0, ng_pcs: 0, ok_leaktest: 0, ng_leaktest: 0, ok_harnessassembly: 0, ng_harnessassembly: 0, ok_eol: 0, ng_eol: 0, ok: 0, ng_final: 0, ng: 0, work_minute: 0};  
        let bmw48vpr_modelSums = {        
            "ICB": { target: 0, input: 0, ok_busbarassembly: 0, ng_busbarassembly: 0, ok_pcs: 0, ng_pcs: 0, ok_leaktest: 0, ng_leaktest: 0, ok_harnessassembly: 0, ng_harnessassembly: 0, ok_eol: 0, ng_eol: 0, ok: 0, ng_final: 0, ng: 0, work_minute: 0},    
        };
        let bmw48vdt_Sums = { time : 0 }
        let bmw48vdt_modelSums = { "ICB": { time: 0}, };
        let bmw48vqw_Sums = { ng_busbarassembly: 0, ng_pcs: 0, ng_leaktest: 0, ng_harnessassembly: 0, ng_eol: 0, ng_final: 0, ng: 0, }
        let bmw48vqw_modelSums = {
            "ICB": { ng_busbarassembly: 0, ng_pcs: 0, ng_leaktest: 0, ng_harnessassembly: 0, ng_eol: 0, ng_final: 0, ng: 0, },                
        };
        for (const row of bmw48v_item.value) {    
            bmw48vpr_Sums.target += row.target;
            bmw48vpr_Sums.input += row.input;                  
            bmw48vpr_Sums.ok_busbarassembly += row.ok_busbarassembly;
            bmw48vpr_Sums.ng_busbarassembly += row.ng_busbarassembly;
            bmw48vpr_Sums.ok_pcs += row.ok_pcs;
            bmw48vpr_Sums.ng_pcs += row.ng_pcs;
            bmw48vpr_Sums.ok_leaktest += row.ok_leaktest;
            bmw48vpr_Sums.ng_leaktest += row.ng_leaktest;
            bmw48vpr_Sums.ok_harnessassembly += row.ok_harnessassembly;
            bmw48vpr_Sums.ng_harnessassembly += row.ng_harnessassembly;
            bmw48vpr_Sums.ok_eol += row.ok_eol;
            bmw48vpr_Sums.ng_eol += row.ng_eol;
            bmw48vpr_Sums.ok += row.ok;
            bmw48vpr_Sums.ng_final += row.ng_final;
            bmw48vpr_Sums.ng += row.ng;                
            bmw48vpr_Sums.work_minute += row.work_minute;
          }             
        for (const row of bmw48v_item.value) {
            if (Object.prototype.hasOwnProperty.call(bmw48vpr_modelSums, row.model)) {
                bmw48vpr_modelSums[row.model].target += row.target;
                bmw48vpr_modelSums[row.model].input += row.input;                  
                bmw48vpr_modelSums[row.model].ok_busbarassembly += row.ok_busbarassembly;
                bmw48vpr_modelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
                bmw48vpr_modelSums[row.model].ok_pcs += row.ok_pcs;
                bmw48vpr_modelSums[row.model].ng_pcs += row.ng_pcs;
                bmw48vpr_modelSums[row.model].ok_leaktest += row.ok_leaktest;
                bmw48vpr_modelSums[row.model].ng_leaktest += row.ng_leaktest;
                bmw48vpr_modelSums[row.model].ok_harnessassembly += row.ok_harnessassembly;
                bmw48vpr_modelSums[row.model].ng_harnessassembly += row.ng_harnessassembly;
                bmw48vpr_modelSums[row.model].ok_eol += row.ok_eol;
                bmw48vpr_modelSums[row.model].ng_eol += row.ng_eol;
                bmw48vpr_modelSums[row.model].ok += row.ok;
                bmw48vpr_modelSums[row.model].ng_final += row.ng_final;
                bmw48vpr_modelSums[row.model].ng += row.ng;                
                bmw48vpr_modelSums[row.model].work_minute += row.work_minute;                  
            }
        }

        for (const row of bmw48v_dtItem.value) { bmw48vdt_Sums.time += row.time;  }
        for (const row of bmw48v_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(bmw48vdt_modelSums, row.model)) {
                bmw48vdt_modelSums[row.model].time += row.time;                
            }
        }
        for (const row of bmw48v_qwItem.value) {                
            bmw48vqw_Sums.ng_busbarassembly += row.ng_busbarassembly;
            bmw48vqw_Sums.ng_pcs += row.ng_pcs;
            bmw48vqw_Sums.ng_leaktest += row.ng_leaktest;
            bmw48vqw_Sums.ng_harnessassembly += row.ng_harnessassembly;
            bmw48vqw_Sums.ng_eol += row.ng_eol;
            bmw48vqw_Sums.ng_final += row.ng_final;
            bmw48vqw_Sums.ng += row.ng;              
        }
        for (const row of bmw48v_qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(bmw48vqw_modelSums, row.model)) {
                bmw48vqw_modelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
                bmw48vqw_modelSums[row.model].ng_pcs += row.ng_pcs;
                bmw48vqw_modelSums[row.model].ng_leaktest += row.ng_leaktest;
                bmw48vqw_modelSums[row.model].ng_harnessassembly += row.ng_harnessassembly;
                bmw48vqw_modelSums[row.model].ng_eol += row.ng_eol;
                bmw48vqw_modelSums[row.model].ng_final += row.ng_final;
                bmw48vqw_modelSums[row.model].ng += row.ng;                  
            }
        }      
        //FCA Flexible busbar carrier
        let fca_flex_bcpr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_brokenbracket: 0, ng_damagedbusbar: 0, ng_visiblecopper: 0, ng_damagedscrew: 0, fn_brokenbracket: 0, fn_damagedbusbar: 0, fn_visiblecopper: 0, fn_damagedscrew: 0, work_minute: 0};          
        let fca_flex_bcdt_Sums = { time : 0 }        
        let fca_flex_bcqw_Sums = {ok: 0, ng: 0, ng_brokenbracket: 0, ng_damagedbusbar: 0, ng_visiblecopper: 0, ng_damagedscrew: 0,}
        for (const row of fca_flex_bc_item.value) {            
            fca_flex_bcpr_Sums.target += row.target;
            fca_flex_bcpr_Sums.input += row.input;
            fca_flex_bcpr_Sums.ok += row.ok;
            fca_flex_bcpr_Sums.ng += row.ng;
            fca_flex_bcpr_Sums.ng_brokenbracket += row.ng_brokenbracket;
            fca_flex_bcpr_Sums.ng_damagedbusbar += row.ng_damagedbusbar;
            fca_flex_bcpr_Sums.ng_visiblecopper += row.ng_visiblecopper;
            fca_flex_bcpr_Sums.ng_damagedscrew += row.ng_damagedscrew;
            fca_flex_bcpr_Sums.fn_brokenbracket += row.fn_brokenbracket;
            fca_flex_bcpr_Sums.fn_damagedbusbar += row.fn_damagedbusbar;
            fca_flex_bcpr_Sums.fn_visiblecopper += row.fn_visiblecopper;
            fca_flex_bcpr_Sums.fn_damagedscrew += row.fn_damagedscrew;            
            fca_flex_bcpr_Sums.work_minute += row.work_minute;
          }         

        for (const row of fca_flex_bc_dtItem.value) { fca_flex_bcdt_Sums.time += row.time;  }
        for (const row of fca_flex_bc_qwItem.value) {
            fca_flex_bcqw_Sums.ok += row.ok;
            fca_flex_bcqw_Sums.ng += row.ng;
            fca_flex_bcqw_Sums.ng_brokenbracket += row.ng_brokenbracket;
            fca_flex_bcqw_Sums.ng_damagedbusbar += row.ng_damagedbusbar;
            fca_flex_bcqw_Sums.ng_visiblecopper += row.ng_visiblecopper;
            fca_flex_bcqw_Sums.ng_damagedscrew += row.ng_damagedscrew;
        }
        //FCA Flexible Positive
        let fca_flex_popr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0, fn_wa_capplus: 0, fn_wa_bandcable: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_siliconcap: 0, fn_lack_capplus: 0, fn_lack_bandcable: 0, work_minute: 0};          
        let fca_flex_podt_Sums = { time : 0 }        
        let fca_flex_poqw_Sums = { ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0,}
        for (const row of fca_flex_po_item.value) {            
            fca_flex_popr_Sums.target += row.target;
            fca_flex_popr_Sums.input += row.input;
            fca_flex_popr_Sums.ok += row.ok;
            fca_flex_popr_Sums.ng += row.ng;
            fca_flex_popr_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_popr_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_popr_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_popr_Sums.ng_visiblecopper += row.ng_visiblecopper;
            fca_flex_popr_Sums.fn_wa_klip += row.fn_wa_klip;
            fca_flex_popr_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            fca_flex_popr_Sums.fn_wa_blacktape += row.fn_wa_blacktape;
            fca_flex_popr_Sums.fn_wa_siliconcap += row.fn_wa_siliconcap;
            fca_flex_popr_Sums.fn_wa_capplus += row.fn_wa_capplus;
            fca_flex_popr_Sums.fn_wa_bandcable += row.fn_wa_bandcable;
            fca_flex_popr_Sums.fn_lack_klip += row.fn_lack_klip;
            fca_flex_popr_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            fca_flex_popr_Sums.fn_lack_blacktape += row.fn_lack_blacktape;
            fca_flex_popr_Sums.fn_lack_siliconcap += row.fn_lack_siliconcap;
            fca_flex_popr_Sums.fn_lack_capplus += row.fn_lack_capplus;
            fca_flex_popr_Sums.fn_lack_bandcable += row.fn_lack_bandcable;            
            fca_flex_popr_Sums.work_minute += row.work_minute;
          }      
        for (const row of fca_flex_po_dtItem.value) { fca_flex_podt_Sums.time += row.time;  }
        for (const row of fca_flex_po_qwItem.value) {
            fca_flex_poqw_Sums.ok += row.ok;
            fca_flex_poqw_Sums.ng += row.ng;
            fca_flex_poqw_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_poqw_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_poqw_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_poqw_Sums.ng_visiblecopper += row.ng_visiblecopper;
        }
        //FCA Flexible 6+7-
        let fca_flex_67pr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_capplus: 0, fn_wa_capminus: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_capplus: 0, fn_lack_capminus: 0, work_minute: 0};          
        let fca_flex_67dt_Sums = { time : 0 }        
        let fca_flex_67qw_Sums = { ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0,}
        for (const row of fca_flex_67_item.value) {            
            fca_flex_67pr_Sums.target += row.target;
            fca_flex_67pr_Sums.input += row.input;
            fca_flex_67pr_Sums.ok += row.ok;
            fca_flex_67pr_Sums.ng += row.ng;
            fca_flex_67pr_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_67pr_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_67pr_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_67pr_Sums.ng_visiblecopper += row.ng_visiblecopper;
            fca_flex_67pr_Sums.fn_wa_klip += row.fn_wa_klip;
            fca_flex_67pr_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            fca_flex_67pr_Sums.fn_wa_blacktape += row.fn_wa_blacktape;            
            fca_flex_67pr_Sums.fn_wa_capplus += row.fn_wa_capplus;
            fca_flex_67pr_Sums.fn_wa_capminus += row.fn_wa_capminus;
            fca_flex_67pr_Sums.fn_lack_klip += row.fn_lack_klip;
            fca_flex_67pr_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            fca_flex_67pr_Sums.fn_lack_blacktape += row.fn_lack_blacktape;            
            fca_flex_67pr_Sums.fn_lack_capplus += row.fn_lack_capplus;
            fca_flex_67pr_Sums.fn_lack_capminus += row.fn_lack_capminus;            
            fca_flex_67pr_Sums.work_minute += row.work_minute;
          }      
        for (const row of fca_flex_67_dtItem.value) { fca_flex_67dt_Sums.time += row.time;  }
        for (const row of fca_flex_67_qwItem.value) {
            fca_flex_67qw_Sums.ok += row.ok;
            fca_flex_67qw_Sums.ng += row.ng;
            fca_flex_67qw_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_67qw_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_67qw_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_67qw_Sums.ng_visiblecopper += row.ng_visiblecopper;
        }
        //FCA Flexible Negative
        let fca_flex_nepr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_siliconcap: 0, fn_wa_capminus: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_siliconcap: 0, fn_lack_capminus: 0, work_minute: 0};          
        let fca_flex_nedt_Sums = { time : 0 }        
        let fca_flex_neqw_Sums = { ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0,}
        for (const row of fca_flex_ne_item.value) {            
            fca_flex_nepr_Sums.target += row.target;
            fca_flex_nepr_Sums.input += row.input;
            fca_flex_nepr_Sums.ok += row.ok;
            fca_flex_nepr_Sums.ng += row.ng;
            fca_flex_nepr_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_nepr_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_nepr_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_nepr_Sums.ng_visiblecopper += row.ng_visiblecopper;
            fca_flex_nepr_Sums.fn_wa_klip += row.fn_wa_klip;
            fca_flex_nepr_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            fca_flex_nepr_Sums.fn_wa_blacktape += row.fn_wa_blacktape;            
            fca_flex_nepr_Sums.fn_wa_siliconcap += row.fn_wa_siliconcap;
            fca_flex_nepr_Sums.fn_wa_capminus += row.fn_wa_capminus;
            fca_flex_nepr_Sums.fn_lack_klip += row.fn_lack_klip;
            fca_flex_nepr_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            fca_flex_nepr_Sums.fn_lack_blacktape += row.fn_lack_blacktape;            
            fca_flex_nepr_Sums.fn_lack_siliconcap += row.fn_lack_siliconcap;
            fca_flex_nepr_Sums.fn_lack_capminus += row.fn_lack_capminus;            
            fca_flex_nepr_Sums.work_minute += row.work_minute;
          }      
        for (const row of fca_flex_ne_dtItem.value) { fca_flex_nedt_Sums.time += row.time;  }
        for (const row of fca_flex_ne_qwItem.value) {
            fca_flex_neqw_Sums.ok += row.ok;
            fca_flex_neqw_Sums.ng += row.ng;
            fca_flex_neqw_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_neqw_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_neqw_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_neqw_Sums.ng_visiblecopper += row.ng_visiblecopper;
        }
        //FCA Flexible 4-5+
        let fca_flex_45pr_Sums = { target: 0, input: 0, ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0, fn_wa_klip: 0, fn_wa_orangetape: 0, fn_wa_blacktape: 0, fn_wa_capplus: 0, fn_wa_capminus: 0, fn_lack_klip: 0, fn_lack_orangetape: 0, fn_lack_blacktape: 0, fn_lack_capplus: 0, fn_lack_capminus: 0, work_minute: 0};          
        let fca_flex_45dt_Sums = { time : 0 }        
        let fca_flex_45qw_Sums = { ok: 0, ng: 0, ng_damagedcap: 0, ng_damagedflex: 0, ng_damagedtape: 0, ng_visiblecopper: 0,}
        for (const row of fca_flex_45_item.value) {            
            fca_flex_45pr_Sums.target += row.target;
            fca_flex_45pr_Sums.input += row.input;
            fca_flex_45pr_Sums.ok += row.ok;
            fca_flex_45pr_Sums.ng += row.ng;
            fca_flex_45pr_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_45pr_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_45pr_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_45pr_Sums.ng_visiblecopper += row.ng_visiblecopper;
            fca_flex_45pr_Sums.fn_wa_klip += row.fn_wa_klip;
            fca_flex_45pr_Sums.fn_wa_orangetape += row.fn_wa_orangetape;
            fca_flex_45pr_Sums.fn_wa_blacktape += row.fn_wa_blacktape;            
            fca_flex_45pr_Sums.fn_wa_capplus += row.fn_wa_capplus;
            fca_flex_45pr_Sums.fn_wa_capminus += row.fn_wa_capminus;
            fca_flex_45pr_Sums.fn_lack_klip += row.fn_lack_klip;
            fca_flex_45pr_Sums.fn_lack_orangetape += row.fn_lack_orangetape;
            fca_flex_45pr_Sums.fn_lack_blacktape += row.fn_lack_blacktape;            
            fca_flex_45pr_Sums.fn_lack_capplus += row.fn_lack_capplus;
            fca_flex_45pr_Sums.fn_lack_capminus += row.fn_lack_capminus;            
            fca_flex_45pr_Sums.work_minute += row.work_minute;
          }      
        for (const row of fca_flex_45_dtItem.value) { fca_flex_45dt_Sums.time += row.time;  }
        for (const row of fca_flex_45_qwItem.value) {
            fca_flex_45qw_Sums.ok += row.ok;
            fca_flex_45qw_Sums.ng += row.ng;
            fca_flex_45qw_Sums.ng_damagedcap += row.ng_damagedcap;
            fca_flex_45qw_Sums.ng_damagedflex += row.ng_damagedflex;            
            fca_flex_45qw_Sums.ng_damagedtape += row.ng_damagedtape;
            fca_flex_45qw_Sums.ng_visiblecopper += row.ng_visiblecopper;
        }
        //Thermisotr Tape
        let thermistortapepr_Sums = { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, ng: 0, ng_coverdamage: 0, work_minute: 0};          
        let thermistortapepr_modelSums = {
            "EVO_2P": { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, ng: 0, ng_coverdamage: 0, work_minute: 0 },              
            "EVO_3P": { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, ng: 0, ng_coverdamage: 0, work_minute: 0 }, 
        };
        let thermistortapedt_Sums = { time : 0 };          
        let thermistortapedt_modelSums = { "EVO_2P": { time : 0 }, "EVO_3P": { time : 0 },};          

          for (const row of thermistortape_item.value) {            
              thermistortapepr_Sums.target += row.target;
              thermistortapepr_Sums.input += row.input;
              thermistortapepr_Sums.oktotal += row.oktotal;
              thermistortapepr_Sums.ok_a += row.ok_a;
              thermistortapepr_Sums.ok_b += row.ok_b;
              thermistortapepr_Sums.ok_c += row.ok_c;              
              thermistortapepr_Sums.ng += row.ng;    
              thermistortapepr_Sums.ng_coverdamage += row.ng_coverdamage;
              thermistortapepr_Sums.work_minute += row.work_minute;
          }
          for (const row of thermistortape_item.value) {
            if (Object.prototype.hasOwnProperty.call(thermistortapepr_modelSums, row.model)) {
              thermistortapepr_modelSums[row.model].target += row.target;
              thermistortapepr_modelSums[row.model].input += row.input;
              thermistortapepr_modelSums[row.model].oktotal += row.oktotal;
              thermistortapepr_modelSums[row.model].ok_a += row.ok_a;
              thermistortapepr_modelSums[row.model].ok_b += row.ok_b;
              thermistortapepr_modelSums[row.model].ok_c += row.ok_c;              
              thermistortapepr_modelSums[row.model].ng += row.ng;
              thermistortapepr_modelSums[row.model].ng_coverdamage += row.ng_coverdamage;           
              thermistortapepr_modelSums[row.model].work_minute += row.work_minute;     
            }
          }
          for (const row of thermistortape_dtItem.value) { thermistortapedt_Sums.time += row.time;  }
          for (const row of thermistortape_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(thermistortapedt_modelSums, row.model)) {
              thermistortapedt_modelSums[row.model].time += row.time;
            }
          }
        //Thermisotr Pad
        let thermistorpadpr_Sums = { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0, work_minute: 0};          
        let thermistorpadpr_modelSums = {
            "EVO_2P": { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0, work_minute: 0 },              
            "EVO_3P": { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0, work_minute: 0 }, 
        };
        let thermistorpaddt_Sums = { time : 0 };          
        let thermistorpaddt_modelSums = { "EVO_2P": { time : 0 }, "EVO_3P": { time : 0 },};          

          for (const row of thermistorpad_item.value) {            
              thermistorpadpr_Sums.target += row.target;
              thermistorpadpr_Sums.input += row.input;
              thermistorpadpr_Sums.oktotal += row.oktotal;
              thermistorpadpr_Sums.ok_a += row.ok_a;
              thermistorpadpr_Sums.ok_b += row.ok_b;
              thermistorpadpr_Sums.ok_c += row.ok_c;              
              thermistorpadpr_Sums.ng += row.ng;    
              thermistorpadpr_Sums.ng_fpcadamage += row.ng_fpcadamage;
              thermistorpadpr_Sums.ng_thermistordamage += row.ng_thermistordamage;
              thermistorpadpr_Sums.work_minute += row.work_minute;
          }
          for (const row of thermistorpad_item.value) {
            if (Object.prototype.hasOwnProperty.call(thermistorpadpr_modelSums, row.model)) {
              thermistorpadpr_modelSums[row.model].target += row.target;
              thermistorpadpr_modelSums[row.model].input += row.input;
              thermistorpadpr_modelSums[row.model].oktotal += row.oktotal;
              thermistorpadpr_modelSums[row.model].ok_a += row.ok_a;
              thermistorpadpr_modelSums[row.model].ok_b += row.ok_b;
              thermistorpadpr_modelSums[row.model].ok_c += row.ok_c;              
              thermistorpadpr_modelSums[row.model].ng += row.ng;
              thermistorpadpr_modelSums[row.model].ng_fpcadamage += row.ng_fpcadamage;           
              thermistorpadpr_modelSums[row.model].ng_thermistordamage += row.ng_thermistordamage;           
              thermistorpadpr_modelSums[row.model].work_minute += row.work_minute;           
            }
          }
          for (const row of thermistorpad_dtItem.value) { thermistorpaddt_Sums.time += row.time;  }
          for (const row of thermistorpad_dtItem.value) {
            if (Object.prototype.hasOwnProperty.call(thermistorpaddt_modelSums, row.model)) {
              thermistorpaddt_modelSums[row.model].time += row.time;
            }
          }
        //Bending EVO
        let bendingevopr_Sums = { target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, ngtotal: 0,
                                  ng_3p: 0, ng_3p_fpcadamage: 0, ng_3p_coating: 0, ng_3p_connector: 0,
                                  ng_2p: 0, ng_2p_fpcadamage: 0, ng_2p_coating: 0, ng_2p_connector: 0, work_minute: 0};                  
        let bendingevodt_Sums = { time : 0 };                  

          for (const row of bendingevo_item.value) {   
              bendingevopr_Sums.target += row.target;
              bendingevopr_Sums.input += row.input;
              bendingevopr_Sums.oktotal += row.oktotal;
              bendingevopr_Sums.ok_a += row.ok_a;
              bendingevopr_Sums.ok_b += row.ok_b;
              bendingevopr_Sums.ok_c += row.ok_c;              
              bendingevopr_Sums.ngtotal += row.ngtotal;
              bendingevopr_Sums.ng_3p += row.ng_3p;    
              bendingevopr_Sums.ng_3p_fpcadamage += row.ng_3p_fpcadamage;
              bendingevopr_Sums.ng_3p_coating += row.ng_3p_coating;
              bendingevopr_Sums.ng_3p_connector += row.ng_3p_connector;
              bendingevopr_Sums.ng_2p += row.ng_2p;    
              bendingevopr_Sums.ng_2p_fpcadamage += row.ng_2p_fpcadamage;
              bendingevopr_Sums.ng_2p_coating += row.ng_2p_coating;
              bendingevopr_Sums.ng_2p_connector += row.ng_2p_connector;            
              bendingevopr_Sums.work_minute += row.work_minute;                       
          }
          for (const row of bendingevo_dtItem.value) { bendingevodt_Sums.time += row.time;  }

        //SUMS return 각 변수별로 return 입력
        return {
                tr2smtpr_modelSums, tr2smtdt_modelSums, tr2smtqw_modelSums, tr2smtpr_Sums, tr2smtdt_Sums, tr2smtqw_Sums,
                tr2crimpingpr_modelSums, tr2crimpingdt_modelSums, tr2crimpingqw_modelSums, tr2crimpingpr_Sums, tr2crimpingdt_Sums, tr2crimpingqw_Sums,
                tr2fpcapr_modelSums, tr2fpcadt_modelSums, tr2fpcaqw_modelSums, tr2fpcapr_Sums, tr2fpcadt_Sums, tr2fpcaqw_Sums,
                tr2bfa2ppr_modelSums, tr2bfa2pdt_modelSums, tr2bfa2pqw_modelSums, tr2bfa2ppr_Sums, tr2bfa2pdt_Sums, tr2bfa2pqw_Sums,
                tr2bfa3ppr_modelSums, tr2bfa3pdt_modelSums, tr2bfa3pqw_modelSums, tr2bfa3ppr_Sums, tr2bfa3pdt_Sums, tr2bfa3pqw_Sums,
                tr3smtpr_modelSums, tr3smtdt_modelSums, tr3smtqw_modelSums, tr3smtpr_Sums, tr3smtdt_Sums, tr3smtqw_Sums,
                tr3crimpingpr_modelSums, tr3crimpingdt_modelSums, tr3crimpingqw_modelSums, tr3crimpingpr_Sums, tr3crimpingdt_Sums, tr3crimpingqw_Sums,
                tr3fpcapr_modelSums, tr3fpcadt_modelSums, tr3fpcaqw_modelSums, tr3fpcapr_Sums, tr3fpcadt_Sums, tr3fpcaqw_Sums,
                tr3bfa2ppr_modelSums, tr3bfa2pdt_modelSums, tr3bfa2pqw_modelSums, tr3bfa2ppr_Sums, tr3bfa2pdt_Sums, tr3bfa2pqw_Sums,
                tr3bfa3ppr_modelSums, tr3bfa3pdt_modelSums, tr3bfa3pqw_modelSums, tr3bfa3ppr_Sums, tr3bfa3pdt_Sums, tr3bfa3pqw_Sums,
                evopr_modelSums, evodt_modelSums, evoqw_modelSums, evopr_Sums, evodt_Sums, evoqw_Sums,
                ev2020_60frpr_Sums, ev2020_60frdt_Sums, ev2020_60frqw_Sums,
                ev2020_60rrpr_Sums, ev2020_60rrdt_Sums, ev2020_60rrqw_Sums,
                ev2020_4060frpr_modelSums, ev2020_4060frdt_modelSums, ev2020_4060frqw_modelSums, ev2020_4060frpr_Sums, ev2020_4060frdt_Sums, ev2020_4060frqw_Sums,
                ev2020_4060rrpr_modelSums, ev2020_4060rrdt_modelSums, ev2020_4060rrqw_modelSums, ev2020_4060rrpr_Sums, ev2020_4060rrdt_Sums, ev2020_4060rrqw_Sums,
                ford1frpr_modelSums, ford1frdt_modelSums, ford1frpr_Sums, ford1frdt_Sums, 
                ford1rrpr_modelSums, ford1rrdt_modelSums, ford1rrpr_Sums, ford1rrdt_Sums, 
                ford2frpr_modelSums, ford2frdt_modelSums, ford2frpr_Sums, ford2frdt_Sums, 
                ford2rrpr_modelSums, ford2rrdt_modelSums, ford2rrpr_Sums, ford2rrdt_Sums, 
                ford3frpr_modelSums, ford3frdt_modelSums, ford3frpr_Sums, ford3frdt_Sums, 
                ford3rrpr_modelSums, ford3rrdt_modelSums, ford3rrpr_Sums, ford3rrdt_Sums, 
                fca1pr_modelSums, fca1dt_modelSums, fca1qw_modelSums, fca1pr_Sums, fca1dt_Sums, fca1qw_Sums,
                fca2pr_modelSums, fca2dt_modelSums, fca2qw_modelSums, fca2pr_Sums, fca2dt_Sums, fca2qw_Sums,
                bt6pr_modelSums, bt6dt_modelSums, bt6qw_modelSums, bt6pr_Sums, bt6dt_Sums, bt6qw_Sums,
                bmw12vpr_modelSums, bmw12vdt_modelSums, bmw12vqw_modelSums, bmw12vpr_Sums, bmw12vdt_Sums, bmw12vqw_Sums,
                bmw48vpr_modelSums, bmw48vdt_modelSums, bmw48vqw_modelSums, bmw48vpr_Sums, bmw48vdt_Sums, bmw48vqw_Sums,
                fca_flex_bcpr_Sums, fca_flex_bcdt_Sums, fca_flex_bcqw_Sums,
                fca_flex_popr_Sums, fca_flex_podt_Sums, fca_flex_poqw_Sums,
                fca_flex_67pr_Sums, fca_flex_67dt_Sums, fca_flex_67qw_Sums,
                fca_flex_nepr_Sums, fca_flex_nedt_Sums, fca_flex_neqw_Sums,
                fca_flex_45pr_Sums, fca_flex_45dt_Sums, fca_flex_45qw_Sums,
                thermistortapepr_Sums, thermistortapepr_modelSums, thermistortapedt_Sums, thermistortapedt_modelSums,
                thermistorpadpr_Sums, thermistorpadpr_modelSums, thermistorpaddt_Sums, thermistorpaddt_modelSums,
                bendingevopr_Sums, bendingevodt_Sums,
        };
    });   

       //설비 추가시 엑셀 다운로드 부분 열어서 수정해야 됨
       const prdownloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          //각 Table 이름을 다 쳐줘야 한다. sheet로 나뉘어서 저장됨 
          //희한하게 header 부분은 본 vue 파일에 있는 각 설비의 headers 부분을 pr,dt,qw에 똑같이 넣으면 잘 나온다.         
          const tr2smt_wsPr = XLSX.utils.json_to_sheet(tr2smt_prTableData.data, { header: tr2smt_headers.value });          
          const tr2crimping_wsPr = XLSX.utils.json_to_sheet(tr2crimping_prTableData.data, { header: tr2crimping_headers.value });          
          const tr2fpca_wsPr = XLSX.utils.json_to_sheet(tr2fpca_prTableData.data, { header: tr2fpca_headers.value });          
          const tr2bfa2p_wsPr = XLSX.utils.json_to_sheet(tr2bfa2p_prTableData.data, { header: tr2bfa2p_headers.value });       
          const tr2bfa3p_wsPr = XLSX.utils.json_to_sheet(tr2bfa3p_prTableData.data, { header: tr2bfa3p_headers.value });       
          const tr3smt_wsPr = XLSX.utils.json_to_sheet(tr3smt_prTableData.data, { header: tr3smt_headers.value });          
          const tr3crimping_wsPr = XLSX.utils.json_to_sheet(tr3crimping_prTableData.data, { header: tr3crimping_headers.value });          
          const tr3fpca_wsPr = XLSX.utils.json_to_sheet(tr3fpca_prTableData.data, { header: tr3fpca_headers.value });          
          const tr3bfa2p_wsPr = XLSX.utils.json_to_sheet(tr3bfa2p_prTableData.data, { header: tr3bfa2p_headers.value });       
          const tr3bfa3p_wsPr = XLSX.utils.json_to_sheet(tr3bfa3p_prTableData.data, { header: tr3bfa3p_headers.value });       
          const evo_wsPr = XLSX.utils.json_to_sheet(evo_prTableData.data, { header: evo_headers.value });       
          const ev2020_60fr_wsPr = XLSX.utils.json_to_sheet(ev2020_60fr_prTableData.data, { header: ev2020_60fr_headers.value });       
          const ev2020_60rr_wsPr = XLSX.utils.json_to_sheet(ev2020_60rr_prTableData.data, { header: ev2020_60fr_headers.value });       
          const ev2020_4060fr_wsPr = XLSX.utils.json_to_sheet(ev2020_4060fr_prTableData.data, { header: ev2020_4060fr_headers.value });       
          const ev2020_4060rr_wsPr = XLSX.utils.json_to_sheet(ev2020_4060rr_prTableData.data, { header: ev2020_4060fr_headers.value });       
          const ford1fr_wsPr = XLSX.utils.json_to_sheet(ford1fr_prTableData.data, { header: ford1fr_headers.value });       
          const ford1rr_wsPr = XLSX.utils.json_to_sheet(ford1rr_prTableData.data, { header: ford1fr_headers.value });       
          const ford2fr_wsPr = XLSX.utils.json_to_sheet(ford2fr_prTableData.data, { header: ford2fr_headers.value });       
          const ford2rr_wsPr = XLSX.utils.json_to_sheet(ford2rr_prTableData.data, { header: ford2fr_headers.value });       
          const ford3fr_wsPr = XLSX.utils.json_to_sheet(ford3fr_prTableData.data, { header: ford3fr_headers.value });       
          const ford3rr_wsPr = XLSX.utils.json_to_sheet(ford3rr_prTableData.data, { header: ford3fr_headers.value });       
          const fca1_wsPr = XLSX.utils.json_to_sheet(fca1_prTableData.data, { header: fca1_headers.value });       
          const fca2_wsPr = XLSX.utils.json_to_sheet(fca2_prTableData.data, { header: fca2_headers.value });       
          const bt6_wsPr = XLSX.utils.json_to_sheet(bt6_prTableData.data, { header: bt6_headers.value });       
          const bmw12v_wsPr = XLSX.utils.json_to_sheet(bmw12v_prTableData.data, { header: bmw12v_headers.value });       
          const bmw48v_wsPr = XLSX.utils.json_to_sheet(bmw48v_prTableData.data, { header: bmw48v_headers.value });       
          const fca_flex_bc_wsPr = XLSX.utils.json_to_sheet(fca_flex_bc_prTableData.data, { header: fca_flex_bc_headers.value });
          const fca_flex_po_wsPr = XLSX.utils.json_to_sheet(fca_flex_po_prTableData.data, { header: fca_flex_po_headers.value });
          const fca_flex_67_wsPr = XLSX.utils.json_to_sheet(fca_flex_67_prTableData.data, { header: fca_flex_po_headers.value });
          const fca_flex_ne_wsPr = XLSX.utils.json_to_sheet(fca_flex_ne_prTableData.data, { header: fca_flex_po_headers.value });
          const fca_flex_45_wsPr = XLSX.utils.json_to_sheet(fca_flex_45_prTableData.data, { header: fca_flex_po_headers.value });
          const thermistortape_wsPr = XLSX.utils.json_to_sheet(thermistortape_prTableData.data, { header: thermistortape_headers.value });
          const thermistorpad_wsPr = XLSX.utils.json_to_sheet(thermistorpad_prTableData.data, { header: thermistorpad_headers.value });
          const bendingevo_wsPr = XLSX.utils.json_to_sheet(bendingevo_prTableData.data, { header: bendingevo_headers.value });

          //각 sheet 이름을 다 쳐줘야 한다
          XLSX.utils.book_append_sheet(wb, tr2smt_wsPr, "tr2smt_PR Data");          
          XLSX.utils.book_append_sheet(wb, tr2crimping_wsPr, "tr2crimping_PR Data");    
          XLSX.utils.book_append_sheet(wb, tr2fpca_wsPr, "tr2fpca_PR Data");   
          XLSX.utils.book_append_sheet(wb, tr2bfa2p_wsPr, "tr2bfa2p_PR Data");   
          XLSX.utils.book_append_sheet(wb, tr2bfa3p_wsPr, "tr2bfa3p_PR Data");   
          XLSX.utils.book_append_sheet(wb, tr3smt_wsPr, "tr3smt_PR Data");          
          XLSX.utils.book_append_sheet(wb, tr3crimping_wsPr, "tr3crimping_PR Data");    
          XLSX.utils.book_append_sheet(wb, tr3fpca_wsPr, "tr3fpca_PR Data");   
          XLSX.utils.book_append_sheet(wb, tr3bfa2p_wsPr, "evo1_PR Data");   
          XLSX.utils.book_append_sheet(wb, tr3bfa3p_wsPr, "evo2_PR Data");   
          XLSX.utils.book_append_sheet(wb, evo_wsPr, "evohybrid_PR Data");   
          XLSX.utils.book_append_sheet(wb, ev2020_60fr_wsPr, "ev2020_60fr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ev2020_60rr_wsPr, "ev2020_60rr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ev2020_4060fr_wsPr, "ev2020_4060fr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ev2020_4060rr_wsPr, "ev2020_4060rr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ford1fr_wsPr, "ford1fr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ford1rr_wsPr, "ford1rr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ford2fr_wsPr, "ford2fr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ford2rr_wsPr, "ford2rr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ford3fr_wsPr, "ford3fr_PR Data");  
          XLSX.utils.book_append_sheet(wb, ford3rr_wsPr, "ford3rr_PR Data");  
          XLSX.utils.book_append_sheet(wb, fca1_wsPr, "fca1_PR Data");  
          XLSX.utils.book_append_sheet(wb, fca2_wsPr, "fca2_PR Data");  
          XLSX.utils.book_append_sheet(wb, bt6_wsPr, "bt6_PR Data");  
          XLSX.utils.book_append_sheet(wb, bmw12v_wsPr, "bmw12v_PR Data");  
          XLSX.utils.book_append_sheet(wb, bmw48v_wsPr, "bmw48v_PR Data");  
          XLSX.utils.book_append_sheet(wb, fca_flex_bc_wsPr, "fca_flex_bc_PR Data");  
          XLSX.utils.book_append_sheet(wb, fca_flex_po_wsPr, "fca_flex_po_PR Data");  
          XLSX.utils.book_append_sheet(wb, fca_flex_67_wsPr, "fca_flex_67_PR Data");  
          XLSX.utils.book_append_sheet(wb, fca_flex_ne_wsPr, "fca_flex_ne_PR Data");  
          XLSX.utils.book_append_sheet(wb, fca_flex_45_wsPr, "fca_flex_45_PR Data");  
          XLSX.utils.book_append_sheet(wb, thermistortape_wsPr, "thermistor_tape_PR Data");  
          XLSX.utils.book_append_sheet(wb, thermistorpad_wsPr, "thermistor_pad_PR Data");  
          XLSX.utils.book_append_sheet(wb, bendingevo_wsPr, "bendingevo_PR Data");  
          
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "Production_data.xlsx");
        };
        //설비 추가시 엑셀 다운로드 부분 열어서 수정해야 됨
       const dtdownloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          //각 Table 이름을 다 쳐줘야 한다. sheet로 나뉘어서 저장됨 
          //희한하게 header 부분은 본 vue 파일에 있는 각 설비의 headers 부분을 pr,dt,qw에 똑같이 넣으면 잘 나온다.   
          const tr2smt_wsDt = XLSX.utils.json_to_sheet(tr2smt_dtTableData.data, { header: tr2smt_headers.value });                   
          const tr2crimping_wsDt = XLSX.utils.json_to_sheet(tr2crimping_dtTableData.data, { header: tr2crimping_headers.value });  
          const tr2fpca_wsDt = XLSX.utils.json_to_sheet(tr2fpca_dtTableData.data, { header: tr2fpca_headers.value });  
          const tr2bfa2p_wsDt = XLSX.utils.json_to_sheet(tr2bfa2p_dtTableData.data, { header: tr2bfa2p_headers.value });  
          const tr2bfa3p_wsDt = XLSX.utils.json_to_sheet(tr2bfa3p_dtTableData.data, { header: tr2bfa3p_headers.value });                  
          const tr3smt_wsDt = XLSX.utils.json_to_sheet(tr3smt_dtTableData.data, { header: tr3smt_headers.value });                   
          const tr3crimping_wsDt = XLSX.utils.json_to_sheet(tr3crimping_dtTableData.data, { header: tr3crimping_headers.value });  
          const tr3fpca_wsDt = XLSX.utils.json_to_sheet(tr3fpca_dtTableData.data, { header: tr3fpca_headers.value });  
          const tr3bfa2p_wsDt = XLSX.utils.json_to_sheet(tr3bfa2p_dtTableData.data, { header: tr3bfa2p_headers.value });  
          const tr3bfa3p_wsDt = XLSX.utils.json_to_sheet(tr3bfa3p_dtTableData.data, { header: tr3bfa3p_headers.value });  
          const evo_wsDt = XLSX.utils.json_to_sheet(evo_dtTableData.data, { header: evo_headers.value });  
          const ev2020_60fr_wsDt = XLSX.utils.json_to_sheet(ev2020_60fr_dtTableData.data, { header: ev2020_60fr_headers.value });  
          const ev2020_60rr_wsDt = XLSX.utils.json_to_sheet(ev2020_60rr_dtTableData.data, { header: ev2020_60fr_headers.value });  
          const ev2020_4060fr_wsDt = XLSX.utils.json_to_sheet(ev2020_4060fr_dtTableData.data, { header: ev2020_4060fr_headers.value });  
          const ev2020_4060rr_wsDt = XLSX.utils.json_to_sheet(ev2020_4060rr_dtTableData.data, { header: ev2020_4060fr_headers.value });  
          const ford1fr_wsDt = XLSX.utils.json_to_sheet(ford1fr_dtTableData.data, { header: ford1fr_headers.value });  
          const ford1rr_wsDt = XLSX.utils.json_to_sheet(ford1rr_dtTableData.data, { header: ford1fr_headers.value });  
          const ford2fr_wsDt = XLSX.utils.json_to_sheet(ford2fr_dtTableData.data, { header: ford2fr_headers.value });  
          const ford2rr_wsDt = XLSX.utils.json_to_sheet(ford2rr_dtTableData.data, { header: ford2fr_headers.value });  
          const ford3fr_wsDt = XLSX.utils.json_to_sheet(ford3fr_dtTableData.data, { header: ford3fr_headers.value });  
          const ford3rr_wsDt = XLSX.utils.json_to_sheet(ford3rr_dtTableData.data, { header: ford3fr_headers.value });  
          const fca1_wsDt = XLSX.utils.json_to_sheet(fca1_dtTableData.data, { header: fca1_headers.value });  
          const fca2_wsDt = XLSX.utils.json_to_sheet(fca2_dtTableData.data, { header: fca2_headers.value });  
          const bt6_wsDt = XLSX.utils.json_to_sheet(bt6_dtTableData.data, { header: bt6_headers.value });  
          const bmw12v_wsDt = XLSX.utils.json_to_sheet(bmw12v_dtTableData.data, { header: bmw12v_headers.value });  
          const bmw48v_wsDt = XLSX.utils.json_to_sheet(bmw48v_dtTableData.data, { header: bmw48v_headers.value });  
          const fca_flex_bc_wsDt = XLSX.utils.json_to_sheet(fca_flex_bc_dtTableData.data, { header: fca_flex_bc_headers.value });  
          const fca_flex_po_wsDt = XLSX.utils.json_to_sheet(fca_flex_po_dtTableData.data, { header: fca_flex_po_headers.value });  
          const fca_flex_67_wsDt = XLSX.utils.json_to_sheet(fca_flex_67_dtTableData.data, { header: fca_flex_po_headers.value });  
          const fca_flex_ne_wsDt = XLSX.utils.json_to_sheet(fca_flex_ne_dtTableData.data, { header: fca_flex_po_headers.value });  
          const fca_flex_45_wsDt = XLSX.utils.json_to_sheet(fca_flex_45_dtTableData.data, { header: fca_flex_po_headers.value });  
          const thermistortape_wsDt = XLSX.utils.json_to_sheet(thermistortape_dtTableData.data, { header: thermistortape_headers.value });
          const thermistorpad_wsDt = XLSX.utils.json_to_sheet(thermistorpad_dtTableData.data, { header: thermistorpad_headers.value });
          const bendingevo_wsDt = XLSX.utils.json_to_sheet(bendingevo_dtTableData.data, { header: bendingevo_headers.value });

          //각 sheet 이름을 다 쳐줘야 한다   
          XLSX.utils.book_append_sheet(wb, tr2smt_wsDt, "tr2smt_DT Data");       
          XLSX.utils.book_append_sheet(wb, tr2crimping_wsDt, "tr2crimping_DT Data");       
          XLSX.utils.book_append_sheet(wb, tr2fpca_wsDt, "tr2fpca_DT Data");   
          XLSX.utils.book_append_sheet(wb, tr2bfa2p_wsDt, "tr2bfa2p_DT Data");   
          XLSX.utils.book_append_sheet(wb, tr2bfa3p_wsDt, "tr2bfa3p_DT Data");  
          XLSX.utils.book_append_sheet(wb, tr3smt_wsDt, "tr3smt_DT Data");       
          XLSX.utils.book_append_sheet(wb, tr3crimping_wsDt, "tr3crimping_DT Data");       
          XLSX.utils.book_append_sheet(wb, tr3fpca_wsDt, "tr3fpca_DT Data");   
          XLSX.utils.book_append_sheet(wb, tr3bfa2p_wsDt, "evo1_DT Data");   
          XLSX.utils.book_append_sheet(wb, tr3bfa3p_wsDt, "evo2_DT Data");           
          XLSX.utils.book_append_sheet(wb, evo_wsDt, "evohybrid_DT Data");           
          XLSX.utils.book_append_sheet(wb, ev2020_60fr_wsDt, "ev2020_60fr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ev2020_60rr_wsDt, "ev2020_60rr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ev2020_4060fr_wsDt, "ev2020_4060fr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ev2020_4060rr_wsDt, "ev2020_4060rr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ford1fr_wsDt, "ford1fr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ford1rr_wsDt, "ford1rr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ford2fr_wsDt, "ford2fr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ford2rr_wsDt, "ford2rr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ford3fr_wsDt, "ford3fr_DT Data");  
          XLSX.utils.book_append_sheet(wb, ford3rr_wsDt, "ford3rr_DT Data");  
          XLSX.utils.book_append_sheet(wb, fca1_wsDt, "fca1_DT Data");  
          XLSX.utils.book_append_sheet(wb, fca2_wsDt, "fca2_DT Data");  
          XLSX.utils.book_append_sheet(wb, bt6_wsDt, "bt6_DT Data");  
          XLSX.utils.book_append_sheet(wb, bmw12v_wsDt, "bmw12v_DT Data");  
          XLSX.utils.book_append_sheet(wb, bmw48v_wsDt, "bmw48v_DT Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_bc_wsDt, "fca_flex_bc_DT Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_po_wsDt, "fca_flex_po_DT Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_67_wsDt, "fca_flex_67_DT Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_ne_wsDt, "fca_flex_ne_DT Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_45_wsDt, "fca_flex_45_DT Data");
          XLSX.utils.book_append_sheet(wb, thermistortape_wsDt, "thermistor_tape_DT Data");  
          XLSX.utils.book_append_sheet(wb, thermistorpad_wsDt, "thermistor_pad_DT Data");  
          XLSX.utils.book_append_sheet(wb, bendingevo_wsDt, "bendingevo_DT Data");  
       
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });
     
          saveAs(blob, "Downtime_data.xlsx");
        };
        //설비 추가시 엑셀 다운로드 부분 열어서 수정해야 됨 
       const qwdownloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          //각 Table 이름을 다 쳐줘야 한다. sheet로 나뉘어서 저장됨 
          //희한하게 header 부분은 본 vue 파일에 있는 각 설비의 headers 부분을 pr,dt,qw에 똑같이 넣으면 잘 나온다.   
          const tr2smt_wsQw = XLSX.utils.json_to_sheet(tr2smt_qwTableData.data, { header: tr2smt_headers.value });
          const tr2crimping_wsQw = XLSX.utils.json_to_sheet(tr2crimping_qwTableData.data, { header: tr2crimping_headers.value });
          const tr2fpca_wsQw = XLSX.utils.json_to_sheet(tr2fpca_qwTableData.data, { header: tr2fpca_headers.value });
          const tr2bfa2p_wsQw = XLSX.utils.json_to_sheet(tr2bfa2p_qwTableData.data, { header: tr2bfa2p_headers.value });
          const tr2bfa3p_wsQw = XLSX.utils.json_to_sheet(tr2bfa3p_qwTableData.data, { header: tr2bfa3p_headers.value });               
          const tr3smt_wsQw = XLSX.utils.json_to_sheet(tr3smt_qwTableData.data, { header: tr3smt_headers.value });
          const tr3crimping_wsQw = XLSX.utils.json_to_sheet(tr3crimping_qwTableData.data, { header: tr3crimping_headers.value });
          const tr3fpca_wsQw = XLSX.utils.json_to_sheet(tr3fpca_qwTableData.data, { header: tr3fpca_headers.value });
          const tr3bfa2p_wsQw = XLSX.utils.json_to_sheet(tr3bfa2p_qwTableData.data, { header: tr3bfa2p_headers.value });
          const tr3bfa3p_wsQw = XLSX.utils.json_to_sheet(tr3bfa3p_qwTableData.data, { header: tr3bfa3p_headers.value });
          const evo_wsQw = XLSX.utils.json_to_sheet(evo_qwTableData.data, { header: evo_headers.value });
          const ev2020_60fr_wsQw = XLSX.utils.json_to_sheet(ev2020_60fr_qwTableData.data, { header: ev2020_60fr_headers.value });
          const ev2020_60rr_wsQw = XLSX.utils.json_to_sheet(ev2020_60rr_qwTableData.data, { header: ev2020_60fr_headers.value });
          const ev2020_4060fr_wsQw = XLSX.utils.json_to_sheet(ev2020_4060fr_qwTableData.data, { header: ev2020_4060fr_headers.value });
          const ev2020_4060rr_wsQw = XLSX.utils.json_to_sheet(ev2020_4060rr_qwTableData.data, { header: ev2020_4060fr_headers.value });
          const ford1fr_wsQw = XLSX.utils.json_to_sheet(ford1fr_qwTableData.data, { header: ford1fr_headers.value });
          const ford1rr_wsQw = XLSX.utils.json_to_sheet(ford1rr_qwTableData.data, { header: ford1fr_headers.value });
          const ford2fr_wsQw = XLSX.utils.json_to_sheet(ford2fr_qwTableData.data, { header: ford2fr_headers.value });
          const ford2rr_wsQw = XLSX.utils.json_to_sheet(ford2rr_qwTableData.data, { header: ford2fr_headers.value });
          const ford3fr_wsQw = XLSX.utils.json_to_sheet(ford3fr_qwTableData.data, { header: ford3fr_headers.value });
          const ford3rr_wsQw = XLSX.utils.json_to_sheet(ford3rr_qwTableData.data, { header: ford3fr_headers.value });
          const fca1_wsQw = XLSX.utils.json_to_sheet(fca1_qwTableData.data, { header: fca1_headers.value });
          const fca2_wsQw = XLSX.utils.json_to_sheet(fca2_qwTableData.data, { header: fca2_headers.value });
          const bt6_wsQw = XLSX.utils.json_to_sheet(bt6_qwTableData.data, { header: bt6_headers.value });
          const bmw12v_wsQw = XLSX.utils.json_to_sheet(bmw12v_qwTableData.data, { header: bmw12v_headers.value });
          const bmw48v_wsQw = XLSX.utils.json_to_sheet(bmw48v_qwTableData.data, { header: bmw48v_headers.value });
          const fca_flex_bc_wsQw = XLSX.utils.json_to_sheet(fca_flex_bc_qwTableData.data, { header: fca_flex_bc_headers.value });
          const fca_flex_po_wsQw = XLSX.utils.json_to_sheet(fca_flex_po_qwTableData.data, { header: fca_flex_po_headers.value });
          const fca_flex_67_wsQw = XLSX.utils.json_to_sheet(fca_flex_67_qwTableData.data, { header: fca_flex_po_headers.value });
          const fca_flex_ne_wsQw = XLSX.utils.json_to_sheet(fca_flex_ne_qwTableData.data, { header: fca_flex_po_headers.value });
          const fca_flex_45_wsQw = XLSX.utils.json_to_sheet(fca_flex_45_qwTableData.data, { header: fca_flex_po_headers.value });

          //각 sheet 이름을 다 쳐줘야 한다      
          XLSX.utils.book_append_sheet(wb, tr2smt_wsQw, "tr2smt_QW Data");
          XLSX.utils.book_append_sheet(wb, tr2crimping_wsQw, "tr2crimping_QW Data");
          XLSX.utils.book_append_sheet(wb, tr2fpca_wsQw, "tr2fpca_QW Data");
          XLSX.utils.book_append_sheet(wb, tr2bfa2p_wsQw, "tr2bfa2p_QW Data");
          XLSX.utils.book_append_sheet(wb, tr2bfa3p_wsQw, "tr2bfa3p_QW Data");   
          XLSX.utils.book_append_sheet(wb, tr3smt_wsQw, "tr3smt_QW Data");
          XLSX.utils.book_append_sheet(wb, tr3crimping_wsQw, "tr3crimping_QW Data");
          XLSX.utils.book_append_sheet(wb, tr3fpca_wsQw, "tr3fpca_QW Data");
          XLSX.utils.book_append_sheet(wb, tr3bfa2p_wsQw, "evo1_QW Data");
          XLSX.utils.book_append_sheet(wb, tr3bfa3p_wsQw, "evo2_QW Data");
          XLSX.utils.book_append_sheet(wb, evo_wsQw, "evohybrid_QW Data");
          XLSX.utils.book_append_sheet(wb, ev2020_60fr_wsQw, "ev2020_60fr_QW Data");
          XLSX.utils.book_append_sheet(wb, ev2020_60rr_wsQw, "ev2020_60rr_QW Data");
          XLSX.utils.book_append_sheet(wb, ev2020_4060fr_wsQw, "ev2020_4060fr_QW Data");
          XLSX.utils.book_append_sheet(wb, ev2020_4060rr_wsQw, "ev2020_4060rr_QW Data");
          XLSX.utils.book_append_sheet(wb, ford1fr_wsQw, "ford1fr_QW Data");
          XLSX.utils.book_append_sheet(wb, ford1rr_wsQw, "ford1rr_QW Data");
          XLSX.utils.book_append_sheet(wb, ford2fr_wsQw, "ford2fr_QW Data");
          XLSX.utils.book_append_sheet(wb, ford2rr_wsQw, "ford2rr_QW Data");
          XLSX.utils.book_append_sheet(wb, ford3fr_wsQw, "ford3fr_QW Data");
          XLSX.utils.book_append_sheet(wb, ford3rr_wsQw, "ford3rr_QW Data");
          XLSX.utils.book_append_sheet(wb, fca1_wsQw, "fca1_QW Data");
          XLSX.utils.book_append_sheet(wb, fca2_wsQw, "fca2_QW Data");
          XLSX.utils.book_append_sheet(wb, bt6_wsQw, "bt6_QW Data");
          XLSX.utils.book_append_sheet(wb, bmw12v_wsQw, "bmw12v_QW Data");
          XLSX.utils.book_append_sheet(wb, bmw48v_wsQw, "bmw48v_QW Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_bc_wsQw, "fca_flex_bc_QW Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_po_wsQw, "fca_flex_po_QW Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_67_wsQw, "fca_flex_67_QW Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_ne_wsQw, "fca_flex_ne_QW Data");
          XLSX.utils.book_append_sheet(wb, fca_flex_45_wsQw, "fca_flex_45_QW Data");
          
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });
          // Save the file
          saveAs(blob, "Qwall_data.xlsx");
        };

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay,
      targetItem, targetList, targetTableName, callTarget,
      searchData, callSearchData, sendDate, sums, prdownloadExcel, dtdownloadExcel, qwdownloadExcel,
      //설비가 늘어나면 headers를 return해야 함
      tr2smt_headers, tr2crimping_headers, tr2fpca_headers, tr2bfa2p_headers, tr2bfa3p_headers,
      tr3smt_headers, tr3crimping_headers, tr3fpca_headers, tr3bfa2p_headers, tr3bfa3p_headers, 
      evo_headers, ev2020_60fr_headers, ev2020_4060fr_headers, 
      ford1fr_headers, ford2fr_headers, ford3fr_headers,
      fca1_headers, fca2_headers, 
      bt6_headers,
      bmw12v_headers,
      bmw48v_headers,
      fca_flex_bc_headers,
      fca_flex_po_headers,
      thermistortape_headers,
      thermistorpad_headers,
      bendingevo_headers
    };
  },
};

</script>

<style scoped>
   * {
    box-sizing: border-box;
  }

  .container {
    margin-top: 0px;
    padding-top: 0px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .search {
  position: relative;
  background-color: #ffffff; /* Set a background color for better visibility */  
  padding: 0px; /* Add some padding for better appearance */
  height: auto;
}
  .start,
  .end {
    display: flex;
    flex-direction: row;    
    border: 0px;
  }

  .start input,
  .end input {
    margin-right: 5px;
  }

  .separator {
    font-size: 24px;
    margin: 0 10px;
    border: 0px;
  }
  .searchTd {    
    border: 0px;
  }

  input::placeholder {
    color: gray;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100px;
  }

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
 .excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}
  .excelButton:hover,
  button:hover {
    background-color: #2980b9;
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  th,
  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 8px;
    font-size: 12px;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #e6e6e6;
  }
  .ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ok_type {
  background-color: #d7e4fc; /* Adjust the color as needed */
  font-weight: bold;
}
.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style> 