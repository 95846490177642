<template>
    <div class="maxpowerdb">            
        <h class="pagetitle">Each Line Woker List</h><br>
        <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">
                <div class="searchtarget">
                    <B>Line :</B>
                    <select id="line" v-model="formInputs.line">
                        <option v-for="line in uniqueLines" :key="line">{{ line }}</option>
                        <option>Packing</option><option>Production Logistic</option><option>Maintenance</option><option></option>
                    </select>   
                </div>
                <div class="searchtarget">
                    <B>Company :</B>
                    <select class="searchtargetselect" v-model="oscompany" placeholder="OS Company">
                        <option>MAX POWER</option><option>MEGA STAFF</option><option>ZYX</option><option>Inkatom</option>
                        <option>LSEVP</option><option></option>
                    </select>
                </div>
                <div class="searchTd">
                    <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
                </div>
                <div class="searchTd">
                    <button class="addButton" @click="showModal = true">+ Add</button>                            
                </div>       
                <div class="searchTd">                          
                    <button class="excelDownload" @click="downExcel">Down Load</button>   
                </div>                                                    
            </div>      
        </form>   
        </div>     
        <div class="table">
            <table>
                <tr>
                    <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                </tr>                                    
                    <tr v-for="row in item" :key="row.id" >
                    <td>{{ row.line }}</td>
                    <td>{{ row.station }}</td>
                    <td>{{ row.startyear }}-{{ row.startmonth }}-{{ row.startday }}</td>
                    <td>{{ row.oscompany }}</td>                    
                    <td>{{ row.employeenumber }}</td>                    
                    <td>{{ row.position }}</td>   
                    <td>{{ row.name }}</td>
                    <td>{{ row.surname }}</td>                                               
                    <td><button class="modifyButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>                    
                    <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>                                        
                </tr>
            </table>                        
        </div>        
        <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>                                     
        <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                             
    </div>
</template>

<script>
import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import { deleteRow, downloadExcel, fetchLine } from "../../utils.js"; 

import Modal from "./lineWorkerModal.vue";
import modifyModal from "./lineWorkerListModify.vue";

export default {  

    methods: {
        openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },    

        canClick(row) {            
            const currentUser = this.$route.meta.currentUser;     
            if (!currentUser) { return false; }            
            if (row.confirm === "Confirmed") {                
                return currentUser.id === 1 || currentUser.id === 2;
            }             
            else {              
                switch (row.oscompany) {
                    case "MAX POWER":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 8 || currentUser.id === 21 || currentUser.id === 22 || currentUser.id === 17 || currentUser.id === 32;
                    case "MEGA STAFF":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 9 || currentUser.id === 23 || currentUser.id === 24 || currentUser.id === 17 || currentUser.id === 32;
                    case "ZYX":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 10 || currentUser.id === 25 || currentUser.id === 26 || currentUser.id === 17 || currentUser.id === 32;
                    case "INKATOM":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 19 || currentUser.id === 17 || currentUser.id === 32;
                    default:
                        return false;
                }
            }
        },
        downExcel() { downloadExcel(this.item, "LineWorkerList.xlsx"); },
        delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    },

    components: { Modal, modifyModal },         

    setup() {        
        const formInputs = reactive({ line: "" }); const oscompany = ref();
        const dbTableName = 'lineworkerlist';   
        const uniqueLines = ref([]);    
        const showMoModal = ref(false); const showModal = ref(false);
        const headers = ['Line', 'Station', 'Work Start Date', 'OS Company', 'Employee Number', 'Position', 'Name', 'Surname', 'Modify', 'DEL',];
        const dbData = reactive({ data: [], });    
        
        const sendDate = async () => {const sendDateData = [formInputs.line, oscompany.value]; return {sendDateData};};     
        const searchData = async (tableName) => {
            const sendDateData = await sendDate();
            const formData = {        
                tableName: tableName,
                line: sendDateData.sendDateData[0],
                oscompany: sendDateData.sendDateData[1],
            };  
            console.log('Requesting data with:', formData);
            axios.get('/api/targetList', { params: {...formData },})            
                .then((res) => {
                    dbData.data = res.data.sort((a, b) => {    
                        if (a.line < b.line) return -1; if (a.line > b.line) return 1;                        
                        if (a.station < b.station) return -1; if (a.line > b.line) return 1;   
                        if (a.oscompany < b.oscompany) return -1; if (a.oscompany > b.oscompany) return 1;                        
                        if (a.name < b.name) return -1; if (a.name > b.name) return 1;
                        return 0;
                    });
                });
        }

        const callSearchData = async () => { searchData(dbTableName); };
        
        const item = computed(() => {return dbData.data.map(d => {return { ...d,};});});      

        const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();

        return { headers, item, callSearchData, dbTableName,  showMoModal, showModal, uniqueLines, formInputs,
                 oscompany, sendDate, }        
    }}

</script>

<style scoped>     
    .searchtarget {border: 0px; width:350px }
    select {margin-left: 10px;}
    .searchtargetselect {width : 200px}
    .modifyButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none;
                border-radius: 4px; font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px;
                margin-left: 5px; margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .table { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
    }
    .excelDownload :hover { background-color: #009625; }  

    .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .searchButton { padding: 5px 5px; background-color: #bbbbbb; color: #000000; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .delButton {
        padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
        font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
    .start,.end { display: flex; flex-direction: row; border: 0px; }
    .start input,.end input { margin-right: 5px;}
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }
    .searchTd { border: 0px; }
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; }
    input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }
    .horizontal-form { margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   

</style>