<template>
    <div class="fcaflexbc">            
      <div class="act">               
         <h class="pagetitle">FCA Flexible Busbar Carrier</h>
         <div class="modalButtonContainer">
         <button class="button" @click="showModal = true">+ Result</button>
         <button class="button" @click="showdtModal = true">+ Downtime</button>
         <button class="button" @click="showdrModal = true">+ Daily Report</button>
         <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>    
         <button class="button" @click="showrdModal = true">+ Repeat Data</button>   
        </div>
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>     
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>                
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCellValue(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Broken bracket'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_brokenbracket === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_brokenbracket', $event)">{{ row.ng_brokenbracket }}</td>
                <td v-if="header === 'Damaged busbar'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagedbusbar === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagedbusbar', $event)">{{ row.ng_damagedbusbar }}</td>
                <td v-if="header === 'Visible copper'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_visiblecopper === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_visiblecopper', $event)">{{ row.ng_visiblecopper }}</td>                
                <td v-if="header === 'Damaged screw'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagedscrew === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagedscrew', $event)">{{ row.ng_damagedscrew }}</td>                
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> <!--need to make cal-->
                <td v-if="header === 'Fake Broken bracket'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_brokenbracket === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_brokenbracket', $event)">{{ row.fn_brokenbracket }}</td> 
                <td v-if="header === 'Fake Damaged busbar'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_damagedbusbar === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_damagedbusbar', $event)">{{ row.fn_damagedbusbar }}</td>
                <td v-if="header === 'Fake Visible copper'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_visiblecopper === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_visiblecopper', $event)">{{ row.fn_visiblecopper }}</td>
                <td v-if="header === 'Fake Damaged screw'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_damagedscrew === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_damagedscrew', $event)">{{ row.fn_damagedscrew }}</td>
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> <!--need to make cal-->
                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCellValue(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCellValue(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCellValue(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCellValue(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1 Subtotal</td>          
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_brokenbracket }}</td>
          <td>{{ sums.shiftSums[1].ng_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[1].ng_visiblecopper }}</td>
          <td>{{ sums.shiftSums[1].ng_damagedscrew }}</td>          
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ shift1FnTotal }}</td>
          <td>{{ sums.shiftSums[1].fn_brokenbracket }}</td>
          <td>{{ sums.shiftSums[1].fn_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[1].fn_visiblecopper }}</td>
          <td>{{ sums.shiftSums[1].fn_damagedscrew }}</td>          
          <td>{{ (( shift1FnTotal )/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2 Subtotal</td>          
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_brokenbracket }}</td>
          <td>{{ sums.shiftSums[2].ng_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[2].ng_visiblecopper }}</td>
          <td>{{ sums.shiftSums[2].ng_damagedscrew }}</td>          
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ shift2FnTotal }}</td>
          <td>{{ sums.shiftSums[2].fn_brokenbracket }}</td>
          <td>{{ sums.shiftSums[2].fn_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[2].fn_visiblecopper }}</td>
          <td>{{ sums.shiftSums[2].fn_damagedscrew }}</td>          
          <td>{{ (( shift2FnTotal )/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>    
        <tr>
         <th colspan="2">Busbar Carrier Total</th>         
          <td>{{ sums.prtotalSums.target }}</td>
          <td>{{ sums.prtotalSums.input  }}</td>
          <td class="ok" >{{ sums.prtotalSums.ok - sums.qwtotalSums.ng }}</td>
          <td class="ng" >{{ sums.prtotalSums.ng + sums.qwtotalSums.ng }}</td>
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_brokenbracket, 0) }}</td> <!-- obj.ng_assembly 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_damagedbusbar, 0) }}</td> 
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td> 
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_damagedscrew, 0) }}</td>           
          <td class="ng">{{ ((sums.prtotalSums.ng + sums.qwtotalSums.ng ) / (sums.prtotalSums.input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ fnTotal }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_brokenbracket  }}</td>               
          <td class="fn_type">{{ sums.prtotalSums.fn_damagedbusbar  }}</td>               
          <td class="fn_type">{{ sums.prtotalSums.fn_visiblecopper  }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_damagedscrew  }}</td>          
          <td class="fn">{{ ((fnTotal)/(sums.prtotalSums.input ) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader --> 
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>                      
            <td v-if="header === 'Target'" :key="index" ></td>
            <td v-if="header === 'Input'" :key="index" ></td>
            <td v-if="header === 'OK'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Broken bracket'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_brokenbracket', $event)">{{ qw.ng_brokenbracket }}</td>
            <td v-if="header === 'Damaged busbar'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_damagedbusbar', $event)">{{ qw.ng_damagedbusbar }}</td>
            <td v-if="header === 'Visible copper'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_visiblecopper', $event)">{{ qw.ng_visiblecopper }}</td>
            <td v-if="header === 'Damaged screw'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_damagedscrew', $event)">{{ qw.ng_damagedscrew }}</td>            
          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCellValue(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>               
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>      
      <dtModal  v-if="showdtModal" @onClose="showdtModal = false; callSearchData();"/>         
      <drModal  v-if="showdrModal" @onClose="showdrModal = false; callSearchData();"/> 
      <qwModal  v-if="showqwModal" @onClose="showqwModal = false; callSearchData();"/> 
      <rdModal  v-if="showrdModal" @onClose="showrdModal = false; callSearchData();"/> 
  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./fca_flex_bc_prm.vue";
import dtModal from "./fca_flex_bc_prdtm.vue";
import drModal from "./fca_flex_bc_prdrm.vue";
import qwModal from "./fca_flex_bc_qwm.vue";
import rdModal from "./fca_flex_bc_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },    

    setup() {
        const lineName = "fca_flex_bc"  //설비 변경시 필수 교체        
        const dbTableName = "fca_flex_bcpr";  //searchData에 있는 값도 바꿔야 함 
        const dtTableName = "fca_flex_bcdt";  //searchData에 있는 값도 바꿔야 함
        const drTableName = "fca_flex_bcdr";  //searchData에 있는 값도 바꿔야 함
        const qwTableName = "fca_flex_bcqw";  //searchData에 있는 값도 바꿔야 함
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Target', 'Input', 'OK', 'NG', "Broken bracket", "Damaged busbar",
                         "Visible copper", 'Damaged screw', 'NG Rate', 'Fake Sum', "Fake Broken bracket", "Fake Damaged busbar",
                         "Fake Visible copper", 'Fake Damaged screw', 'Fake NG Rate', 'Remark', 'Work Order', 'Supervisor', 
                         'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], }); 
        
        //Repeat Data part
        const showrdModal = ref(false);  
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, fn_brokenbracket, fn_damagedbusbar, fn_visiblecopper, fn_damagedscrew, ) => {
          const input = ok + ng;
          const fakeng = fn_brokenbracket + fn_damagedbusbar + fn_visiblecopper + fn_damagedscrew
          let ngRate = 0;
          let fn_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          return { input, ngRate, fakeng, fn_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(d.ok, d.ng, d.fn_brokenbracket, d.fn_damagedbusbar, d.fn_visiblecopper, d.fn_damagedscrew);
            return { ...d, input, ngRate, fakeng, fn_ngRate, }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
            console.log('Requesting data with:', {
              tablename: tableName,
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
            });

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };

            prTableData.data = [];
            dtTableData.data = [];
            drTableData.data = [];
            qwTableData.data = [];

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'fca_flex_bcpr':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                  case 'fca_flex_bcdt':
                    dtTableData.data = res.data;
                    break;
                  case 'fca_flex_bcdr':
                    drTableData.data = res.data;
                    break;
                  case 'fca_flex_bcqw':
                    qwTableData.data = res.data;
                    break;
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName);
            searchData(dtTableName);
            searchData(drTableName);
            searchData(qwTableName);
            settingCall();
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let prtotalSums = { target: 0, input: 0, ok: 0, ng: 0, ng_brokenbracket: 0, ng_damagedbusbar: 0, ng_visiblecopper: 0,
                  ng_damagedscrew: 0, fn_brokenbracket: 0, fn_damagedbusbar: 0, fn_visiblecopper: 0, fn_damagedscrew: 0 };
          let shiftSums = {
            1: { target: 0, input: 0, ok: 0, ng: 0, ng_brokenbracket: 0, ng_damagedbusbar: 0, ng_visiblecopper: 0,
                  ng_damagedscrew: 0, fn_brokenbracket: 0, fn_damagedbusbar: 0, fn_visiblecopper: 0, fn_damagedscrew: 0 }, 
          
            2: { target: 0, input: 0, ok: 0, ng: 0, ng_brokenbracket: 0, ng_damagedbusbar: 0, ng_visiblecopper: 0,
                  ng_damagedscrew: 0, fn_brokenbracket: 0, fn_damagedbusbar: 0, fn_visiblecopper: 0, fn_damagedscrew: 0 },
          };
          let qwtotalSums = { ok: 0, ng: 0, ng_brokenbracket: 0, ng_damagedbusbar: 0, ng_visiblecopper: 0, ng_damagedscrew: 0, };          


          for (const row of item.value) {
              prtotalSums.target += row.target;
              prtotalSums.input += row.input;
              prtotalSums.ok += row.ok;
              prtotalSums.ng += row.ng;
              prtotalSums.ng_brokenbracket += row.ng_brokenbracket;
              prtotalSums.ng_damagedbusbar += row.ng_damagedbusbar;
              prtotalSums.ng_visiblecopper += row.ng_visiblecopper;
              prtotalSums.ng_damagedscrew += row.ng_damagedscrew;
              prtotalSums.fn_brokenbracket += row.fn_brokenbracket;
              prtotalSums.fn_damagedbusbar += row.fn_damagedbusbar;
              prtotalSums.fn_visiblecopper += row.fn_visiblecopper;
              prtotalSums.fn_damagedscrew += row.fn_damagedscrew;        
          }

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].ok += row.ok;
              shiftSums[row.shift].ng += row.ng;
              shiftSums[row.shift].ng_brokenbracket += row.ng_brokenbracket;
              shiftSums[row.shift].ng_damagedbusbar += row.ng_damagedbusbar;
              shiftSums[row.shift].ng_visiblecopper += row.ng_visiblecopper;
              shiftSums[row.shift].ng_damagedscrew += row.ng_damagedscrew;
              shiftSums[row.shift].fn_brokenbracket += row.fn_brokenbracket;
              shiftSums[row.shift].fn_damagedbusbar += row.fn_damagedbusbar;
              shiftSums[row.shift].fn_visiblecopper += row.fn_visiblecopper;
              shiftSums[row.shift].fn_damagedscrew += row.fn_damagedscrew;                 
              }
          }

          for (const row of qwItem.value) {            
              qwtotalSums.ok += row.ok;
              qwtotalSums.ng += row.ng;
              qwtotalSums.ng_brokenbracket += row.ng_brokenbracket;
              qwtotalSums.ng_damagedbusbar += row.ng_damagedbusbar;
              qwtotalSums.ng_visiblecopper += row.ng_visiblecopper;
              qwtotalSums.ng_damagedscrew += row.ng_damagedscrew;                
          }     

          return { prtotalSums, shiftSums, qwtotalSums };
        });

        const fnTotal = computed(() => {
          return (
            sums.value.prtotalSums.fn_brokenbracket + sums.value.prtotalSums.fn_damagedbusbar +
            sums.value.prtotalSums.fn_visiblecopper + sums.value.prtotalSums.fn_damagedscrew
          );
        });

        const shift1FnTotal = computed(() => {
          return (
            sums.value.shiftSums[1].fn_brokenbracket + sums.value.shiftSums[1].fn_damagedbusbar +
            sums.value.shiftSums[1].fn_visiblecopper + sums.value.shiftSums[1].fn_damagedscrew
          );
        });

        const shift2FnTotal = computed(() => {
          return (
            sums.value.shiftSums[2].fn_brokenbracket + sums.value.shiftSums[2].fn_damagedbusbar +
            sums.value.shiftSums[2].fn_visiblecopper + sums.value.shiftSums[2].fn_damagedscrew
          );
        });

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error download excel data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });
          const wsDt = XLSX.utils.json_to_sheet(dtTableData.data, { header: dtHeaders.value });
          const wsDr = XLSX.utils.json_to_sheet(drTableData.data, { header: drHeaders.value });
          const wsQw = XLSX.utils.json_to_sheet(qwTableData.data, { header: headers.value });

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "PR Data");
          XLSX.utils.book_append_sheet(wb, wsDt, "DT Data");
          XLSX.utils.book_append_sheet(wb, wsDr, "DR Data");
          XLSX.utils.book_append_sheet(wb, wsQw, "QW Data");

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "data.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate, fnTotal, shift1FnTotal, shift2FnTotal,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}

.modalButtonContainer {
  display: inline-flex;
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .qwallButton {
    padding: 5px 5px;
    background-color: #00eb91;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, .qwallButton:hover, 
.button:hover {
    background-color: #2980b9;
  }

  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input
  {
  width : 80px;  
  margin-right: 5px;
  padding : 5px;
 }

label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}

#dailyReport td {
  text-align: left;
}

.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
}

.model-Front {
  background-color: #a0eefc; /* Adjust the color as needed for 2P12S */
}

.model-Rear {
  background-color: #9887e4; /* Adjust the color as needed for 3P8S */
}

.ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.zero-value_ng {
  background-color: #fa7979; 
  color: #fa7979; 
}

.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.zero-value_ngtype {
  background-color: #fdb08d; 
  color: #fdb08d;
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.zero-value_fn {
  background-color: #ff72d5; 
  color: #ff72d5; 
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}

.zero-value_fntype {
  background-color: #ff9bcd; 
  color: #ff9bcd; 
}

.qwall {
  background-color: #66b179;  
}

.shift1 { background-color: rgb(174, 240, 147); }
.shift2 { background-color: rgba(248, 250, 145, 0.979); }

</style>