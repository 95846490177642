<template>
  <div class="planning">    
    <div class="act">  
      <form class="searchBar">      
      <button class="nav-button" @click.prevent="fetchDataForPrevWeek">Previous week</button>
      <span id="week-number">{{ currentDateAndWeek }}</span>
      <button class="nav-button" @click.prevent="fetchDataForNextWeek">Next week</button>
      <button class="downLoadButton" @click="downloadExcel">Excel Download</button> 
      <div class="excelFile right-align">
        {{ fileName }}
        <label for="file-input" class="custom-file-upload">Choose file</label>
        <input id="file-input" type="file" @change="onFileChange" accept=".xlsx, .xls" style="display: none" />
        <button class="listuploadbutton" @click.prevent="uploadExcel('weekplan')">Plan Upload</button>                
      </div>
      </form>
    </div>
    <div class="tables">
      <table id="weekplanning" class="custom-table">
        <tr>
          <th>Factory</th>
          <th>Line</th>
          <th>Shift</th>
          <th id="mon">MON<br>{{ weekDates[0].toLocaleDateString() }}</th>
          <th id="tue">TUE<br>{{ weekDates[1].toLocaleDateString() }}</th>
          <th id="wed">WED<br>{{ weekDates[2].toLocaleDateString() }}</th>
          <th id="thu">THU<br>{{ weekDates[3].toLocaleDateString() }}</th>
          <th id="fri">FRI<br>{{ weekDates[4].toLocaleDateString() }}</th>
          <th id="sat">SAT<br>{{ weekDates[5].toLocaleDateString() }}</th>
          <th id="sun">SUN<br>{{ weekDates[6].toLocaleDateString() }}</th>
        </tr>
        <tr> <!--BMW 48V Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('BMW 48V')"><B>BMW 48V</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ bmw48vdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(bmw48vdayQty[day])"> {{ bmw48vdayQty[day].value }}</button><br>
            Work Order : {{ bmw48vdayWo[day].value }}<br>
            L: {{ bmw48vdayLeader[day].value }} / T: {{ bmw48vdayTechnician[day].value }} / O: {{ bmw48vdayOperator[day].value }} / Q: {{ bmw48vdayQuality[day].value }}<br>
            Notice : {{ bmw48vdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--BMW 48V Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ bmw48vnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(bmw48vnightQty[day])"> {{ bmw48vnightQty[day].value }}</button><br>
            Work Order : {{ bmw48vnightWo[day].value }}<br>
            L: {{ bmw48vnightLeader[day].value }} / T: {{ bmw48vnightTechnician[day].value }} / O: {{ bmw48vnightOperator[day].value }} / Q: {{ bmw48vnightQuality[day].value }}<br>
            Notice : {{ bmw48vnightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--BMW 12V Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('BMW 12V')"><B>BMW 12V</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ bmw12vdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(bmw12vdayQty[day])"> {{ bmw12vdayQty[day].value }}</button><br>
            Work Order : {{ bmw12vdayWo[day].value }}<br>
            L: {{ bmw12vdayLeader[day].value }} / T: {{ bmw12vdayTechnician[day].value }} / O: {{ bmw12vdayOperator[day].value }} / Q: {{ bmw12vdayQuality[day].value }}<br>
            Notice : {{ bmw12vdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--BMW 12V Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ bmw12vnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(bmw12vnightQty[day])"> {{ bmw12vnightQty[day].value }}</button><br>
            Work Order : {{ bmw12vnightWo[day].value }}<br>
            L: {{ bmw12vnightLeader[day].value }} / T: {{ bmw12vnightTechnician[day].value }} / O: {{ bmw12vnightOperator[day].value }} / Q: {{ bmw12vnightQuality[day].value }}<br>
            Notice : {{ bmw12vnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--FCA 1 Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('FCA 1')"><B>FCA 1</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ fca1dayModel[day].value }}<br>
            QTY: <button @click="openEditModal(fca1dayQty[day])"> {{ fca1dayQty[day].value }}</button><br>
            Work Order : {{ fca1dayWo[day].value }}<br>
            L: {{ fca1dayLeader[day].value }} / T: {{ fca1dayTechnician[day].value }} / O: {{ fca1dayOperator[day].value }} / Q: {{ fca1dayQuality[day].value }}<br>
            Notice : {{ fca1dayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA 1 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ fca1nightModel[day].value }}<br>
            QTY: <button @click="openEditModal(fca1nightQty[day])"> {{ fca1nightQty[day].value }}</button><br>
            Work Order : {{ fca1nightWo[day].value }}<br>
            L: {{ fca1nightLeader[day].value }} / T: {{ fca1nightTechnician[day].value }} / O: {{ fca1nightOperator[day].value }} / Q: {{ fca1nightQuality[day].value }}<br>
            Notice : {{ fca1nightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA 2 Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('FCA 2')"><B>FCA 2</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ fca2dayModel[day].value }}<br>
            QTY: <button @click="openEditModal(fca2dayQty[day])"> {{ fca2dayQty[day].value }}</button><br>
            Work Order : {{ fca2dayWo[day].value }}<br>
            L: {{ fca2dayLeader[day].value }} / T: {{ fca2dayTechnician[day].value }} / O: {{ fca2dayOperator[day].value }} / Q: {{ fca2dayQuality[day].value }}<br>
            Notice : {{ fca2dayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA 2 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ fca2nightModel[day].value }}<br>
            QTY: <button @click="openEditModal(fca2nightQty[day])"> {{ fca2nightQty[day].value }}</button><br>
            Work Order : {{ fca2nightWo[day].value }}<br>
            L: {{ fca2nightLeader[day].value }} / T: {{ fca2nightTechnician[day].value }} / O: {{ fca2nightOperator[day].value }} / Q: {{ fca2nightQuality[day].value }}<br>
            Notice : {{ fca2nightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX PO Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('FCA FLEX PO')"><B>FCA FLEX PO</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ fcaflexpodayModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflexpodayQty[day])"> {{ fcaflexpodayQty[day].value }}</button><br>
            Work Order : {{ fcaflexpodayWo[day].value }}<br>
            L: {{ fcaflexpodayLeader[day].value }} / T: {{ fcaflexpodayTechnician[day].value }} / O: {{ fcaflexpodayOperator[day].value }} / Q: {{ fcaflexpodayQuality[day].value }}<br>
            Notice : {{ fcaflexpodayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX PO Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ fcaflexponightModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflexponightQty[day])"> {{ fcaflexponightQty[day].value }}</button><br>
            Work Order : {{ fcaflexponightWo[day].value }}<br>
            L: {{ fcaflexponightLeader[day].value }} / T: {{ fcaflexponightTechnician[day].value }} / O: {{ fcaflexponightOperator[day].value }} / Q: {{ fcaflexponightQuality[day].value }}<br>
            Notice : {{ fcaflexponightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX NE Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('FCA FLEX NE')"><B>FCA FLEX NE</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ fcaflexnedayModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflexnedayQty[day])"> {{ fcaflexnedayQty[day].value }}</button><br>
            Work Order : {{ fcaflexnedayWo[day].value }}<br>
            L: {{ fcaflexnedayLeader[day].value }} / T: {{ fcaflexnedayTechnician[day].value }} / O: {{ fcaflexnedayOperator[day].value }} / Q: {{ fcaflexnedayQuality[day].value }}<br>
            Notice : {{ fcaflexnedayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX NE Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ fcaflexnenightModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflexnenightQty[day])"> {{ fcaflexnenightQty[day].value }}</button><br>
            Work Order : {{ fcaflexnenightWo[day].value }}<br>
            L: {{ fcaflexnenightLeader[day].value }} / T: {{ fcaflexnenightTechnician[day].value }} / O: {{ fcaflexnenightOperator[day].value }} / Q: {{ fcaflexnenightQuality[day].value }}<br>
            Notice : {{ fcaflexnenightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX BC Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('FCA FLEX BC')"><B>FCA FLEX BC</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ fcaflexbcdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflexbcdayQty[day])"> {{ fcaflexbcdayQty[day].value }}</button><br>
            Work Order : {{ fcaflexbcdayWo[day].value }}<br>
            L: {{ fcaflexbcdayLeader[day].value }} / T: {{ fcaflexbcdayTechnician[day].value }} / O: {{ fcaflexbcdayOperator[day].value }} / Q: {{ fcaflexbcdayQuality[day].value }}<br>
            Notice : {{ fcaflexbcdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX BC Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ fcaflexbcnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflexbcnightQty[day])"> {{ fcaflexbcnightQty[day].value }}</button><br>
            Work Order : {{ fcaflexbcnightWo[day].value }}<br>
            L: {{ fcaflexbcnightLeader[day].value }} / T: {{ fcaflexbcnightTechnician[day].value }} / O: {{ fcaflexbcnightOperator[day].value }} / Q: {{ fcaflexbcnightQuality[day].value }}<br>
            Notice : {{ fcaflexbcnightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX 45 Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('FCA FLEX 45')"><B>FCA FLEX 45</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ fcaflex45dayModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflex45dayQty[day])"> {{ fcaflex45dayQty[day].value }}</button><br>
            Work Order : {{ fcaflex45dayWo[day].value }}<br>
            L: {{ fcaflex45dayLeader[day].value }} / T: {{ fcaflex45dayTechnician[day].value }} / O: {{ fcaflex45dayOperator[day].value }} / Q: {{ fcaflex45dayQuality[day].value }}<br>
            Notice : {{ fcaflex45dayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX 45 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ fcaflex45nightModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflex45nightQty[day])"> {{ fcaflex45nightQty[day].value }}</button><br>
            Work Order : {{ fcaflex45nightWo[day].value }}<br>
            L: {{ fcaflex45nightLeader[day].value }} / T: {{ fcaflex45nightTechnician[day].value }} / O: {{ fcaflex45nightOperator[day].value }} / Q: {{ fcaflex45nightQuality[day].value }}<br>
            Notice : {{ fcaflex45nightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX 67 Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('FCA FLEX 67')"><B>FCA FLEX 67</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ fcaflex67dayModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflex67dayQty[day])"> {{ fcaflex67dayQty[day].value }}</button><br>
            Work Order : {{ fcaflex67dayWo[day].value }}<br>
            L: {{ fcaflex67dayLeader[day].value }} / T: {{ fcaflex67dayTechnician[day].value }} / O: {{ fcaflex67dayOperator[day].value }} / Q: {{ fcaflex67dayQuality[day].value }}<br>
            Notice : {{ fcaflex67dayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--FCA FLEX 67 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ fcaflex67nightModel[day].value }}<br>
            QTY: <button @click="openEditModal(fcaflex67nightQty[day])"> {{ fcaflex67nightQty[day].value }}</button><br>
            Work Order : {{ fcaflex67nightWo[day].value }}<br>
            L: {{ fcaflex67nightLeader[day].value }} / T: {{ fcaflex67nightTechnician[day].value }} / O: {{ fcaflex67nightOperator[day].value }} / Q: {{ fcaflex67nightQuality[day].value }}<br>
            Notice : {{ fcaflex67nightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--BT6 Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('BT6')"><B>BT6</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ bt6dayModel[day].value }}<br>
            QTY: <button @click="openEditModal(bt6dayQty[day])"> {{ bt6dayQty[day].value }}</button><br>
            Work Order : {{ bt6dayWo[day].value }}<br>
            L: {{ bt6dayLeader[day].value }} / T: {{ bt6dayTechnician[day].value }} / O: {{ bt6dayOperator[day].value }} / Q: {{ bt6dayQuality[day].value }}<br>
            Notice : {{ bt6dayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--BT6 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ bt6nightModel[day].value }}<br>
            QTY: <button @click="openEditModal(bt6nightQty[day])"> {{ bt6nightQty[day].value }}</button><br>
            Work Order : {{ bt6nightWo[day].value }}<br>
            L: {{ bt6nightLeader[day].value }} / T: {{ bt6nightTechnician[day].value }} / O: {{ bt6nightOperator[day].value }} / Q: {{ bt6nightQuality[day].value }}<br>
            Notice : {{ bt6nightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 1 Front Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Ford 1 Front')"><B>Ford 1 Front</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ford1frdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford1frdayQty[day])"> {{ ford1frdayQty[day].value }}</button><br>
            Work Order : {{ ford1frdayWo[day].value }}<br>
            L: {{ ford1frdayLeader[day].value }} / T: {{ ford1frdayTechnician[day].value }} / O: {{ ford1frdayOperator[day].value }} / Q: {{ ford1frdayQuality[day].value }}<br>
            Notice : {{ ford1frdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 1 Front Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ford1frnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford1frnightQty[day])"> {{ ford1frnightQty[day].value }}</button><br>
            Work Order : {{ ford1frnightWo[day].value }}<br>
            L: {{ ford1frnightLeader[day].value }} / T: {{ ford1frnightTechnician[day].value }} / O: {{ ford1frnightOperator[day].value }} / Q: {{ ford1frnightQuality[day].value }}<br>
            Notice : {{ ford1frnightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 1 Rear Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Ford 1 Rear')"><B>Ford 1 Rear</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ford1rrdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford1rrdayQty[day])"> {{ ford1rrdayQty[day].value }}</button><br>
            Work Order : {{ ford1rrdayWo[day].value }}<br>
            L: {{ ford1rrdayLeader[day].value }} / T: {{ ford1rrdayTechnician[day].value }} / O: {{ ford1rrdayOperator[day].value }} / Q: {{ ford1rrdayQuality[day].value }}<br>
            Notice : {{ ford1rrdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 1 Rear Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ford1rrnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford1rrnightQty[day])"> {{ ford1rrnightQty[day].value }}</button><br>
            Work Order : {{ ford1rrnightWo[day].value }}<br>
            L: {{ ford1rrnightLeader[day].value }} / T: {{ ford1rrnightTechnician[day].value }} / O: {{ ford1rrnightOperator[day].value }} / Q: {{ ford1rrnightQuality[day].value }}<br>
            Notice : {{ ford1rrnightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 2 Front Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Ford 2 Front')"><B>Ford 2 Front</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ford2frdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford2frdayQty[day])"> {{ ford2frdayQty[day].value }}</button><br>
            Work Order : {{ ford2frdayWo[day].value }}<br>
            L: {{ ford2frdayLeader[day].value }} / T: {{ ford2frdayTechnician[day].value }} / O: {{ ford2frdayOperator[day].value }} / Q: {{ ford2frdayQuality[day].value }}<br>
            Notice : {{ ford2frdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 2 Front Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ford2frnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford2frnightQty[day])"> {{ ford2frnightQty[day].value }}</button><br>
            Work Order : {{ ford2frnightWo[day].value }}<br>
            L: {{ ford2frnightLeader[day].value }} / T: {{ ford2frnightTechnician[day].value }} / O: {{ ford2frnightOperator[day].value }} / Q: {{ ford2frnightQuality[day].value }}<br>
            Notice : {{ ford2frnightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 2 Rear Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Ford 2 Rear')"><B>Ford 2 Rear</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ford2rrdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford2rrdayQty[day])"> {{ ford2rrdayQty[day].value }}</button><br>
            Work Order : {{ ford2rrdayWo[day].value }}<br>
            L: {{ ford2rrdayLeader[day].value }} / T: {{ ford2rrdayTechnician[day].value }} / O: {{ ford2rrdayOperator[day].value }} / Q: {{ ford2rrdayQuality[day].value }}<br>
            Notice : {{ ford2rrdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 2 Rear Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ford2rrnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford2rrnightQty[day])"> {{ ford2rrnightQty[day].value }}</button><br>
            Work Order : {{ ford2rrnightWo[day].value }}<br>
            L: {{ ford2rrnightLeader[day].value }} / T: {{ ford2rrnightTechnician[day].value }} / O: {{ ford2rrnightOperator[day].value }} / Q: {{ ford2rrnightQuality[day].value }}<br>
            Notice : {{ ford2rrnightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 3 Front Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Ford 3 Front')"><B>Ford 3 Front</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ford3frdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford3frdayQty[day])"> {{ ford3frdayQty[day].value }}</button><br>
            Work Order : {{ ford3frdayWo[day].value }}<br>
            L: {{ ford3frdayLeader[day].value }} / T: {{ ford3frdayTechnician[day].value }} / O: {{ ford3frdayOperator[day].value }} / Q: {{ ford3frdayQuality[day].value }}<br>
            Notice : {{ ford3frdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 3 Front Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ford3frnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford3frnightQty[day])"> {{ ford3frnightQty[day].value }}</button><br>
            Work Order : {{ ford3frnightWo[day].value }}<br>
            L: {{ ford3frnightLeader[day].value }} / T: {{ ford3frnightTechnician[day].value }} / O: {{ ford3frnightOperator[day].value }} / Q: {{ ford3frnightQuality[day].value }}<br>
            Notice : {{ ford3frnightNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 3 Rear Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Ford 3 Rear')"><B>Ford 3 Rear</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ford3rrdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford3rrdayQty[day])"> {{ ford3rrdayQty[day].value }}</button><br>
            Work Order : {{ ford3rrdayWo[day].value }}<br>
            L: {{ ford3rrdayLeader[day].value }} / T: {{ ford3rrdayTechnician[day].value }} / O: {{ ford3rrdayOperator[day].value }} / Q: {{ ford3rrdayQuality[day].value }}<br>
            Notice : {{ ford3rrdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Ford 3 Rear Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ford3rrnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ford3rrnightQty[day])"> {{ ford3rrnightQty[day].value }}</button><br>
            Work Order : {{ ford3rrnightWo[day].value }}<br>
            L: {{ ford3rrnightLeader[day].value }} / T: {{ ford3rrnightTechnician[day].value }} / O: {{ ford3rrnightOperator[day].value }} / Q: {{ ford3rrnightQuality[day].value }}<br>
            Notice : {{ ford3rrnightNotice[day].value }}
          </td>
        </tr>         
        <tr> <!--TR2 SMT Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR2 SMT')"><B>TR2 SMT</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr2smtdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2smtdayQty[day])"> {{ tr2smtdayQty[day].value }}</button><br>
            Work Order : {{ tr2smtdayWo[day].value }}<br>
            L: {{ tr2smtdayLeader[day].value }} / T: {{ tr2smtdayTechnician[day].value }} / O: {{ tr2smtdayOperator[day].value }} / Q: {{ tr2smtdayQuality[day].value }}<br>
            Notice : {{ tr2smtdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR2 SMT Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr2smtnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2smtnightQty[day])"> {{ tr2smtnightQty[day].value }}</button><br>
            Work Order : {{ tr2smtnightWo[day].value }}<br>
            L: {{ tr2smtnightLeader[day].value }} / T: {{ tr2smtnightTechnician[day].value }} / O: {{ tr2smtnightOperator[day].value }} / Q: {{ tr2smtnightQuality[day].value }}<br>
            Notice : {{ tr2smtnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--1st Semi Check Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('1st Semi Check')"><B>1st Semi Check</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ semicheck1stdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(semicheck1stdayQty[day])"> {{ semicheck1stdayQty[day].value }}</button><br>
            Work Order : {{ semicheck1stdayWo[day].value }}<br>
            L: {{ semicheck1stdayLeader[day].value }} / T: {{ semicheck1stdayTechnician[day].value }} / O: {{ semicheck1stdayOperator[day].value }} / Q: {{ semicheck1stdayQuality[day].value }}<br>
            Notice : {{ semicheck1stdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--1st Semi Check Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ semicheck1stnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(semicheck1stnightQty[day])"> {{ semicheck1stnightQty[day].value }}</button><br>
            Work Order : {{ semicheck1stnightWo[day].value }}<br>
            L: {{ semicheck1stnightLeader[day].value }} / T: {{ semicheck1stnightTechnician[day].value }} / O: {{ semicheck1stnightOperator[day].value }} / Q: {{ semicheck1stnightQuality[day].value }}<br>
            Notice : {{ semicheck1stnightNotice[day].value }}
          </td>
        </tr>               
        <tr> <!--TR2 Crimping Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR2 Crimping')"><B>TR2 Crimping</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr2crimpingdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2crimpingdayQty[day])"> {{ tr2crimpingdayQty[day].value }}</button><br>
            Work Order : {{ tr2crimpingdayWo[day].value }}<br>
            L: {{ tr2crimpingdayLeader[day].value }} / T: {{ tr2crimpingdayTechnician[day].value }} / O: {{ tr2crimpingdayOperator[day].value }} / Q: {{ tr2crimpingdayQuality[day].value }}<br>
            Notice : {{ tr2crimpingdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR2 Crimping Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr2crimpingnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2crimpingnightQty[day])"> {{ tr2crimpingnightQty[day].value }}</button><br>
            Work Order : {{ tr2crimpingnightWo[day].value }}<br>
            L: {{ tr2crimpingnightLeader[day].value }} / T: {{ tr2crimpingnightTechnician[day].value }} / O: {{ tr2crimpingnightOperator[day].value }} / Q: {{ tr2crimpingnightQuality[day].value }}<br>
            Notice : {{ tr2crimpingnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--TR2 FPCA Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR2 FPCA')"><B>TR2 FPCA</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr2fpcadayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2fpcadayQty[day])"> {{ tr2fpcadayQty[day].value }}</button><br>
            Work Order : {{ tr2fpcadayWo[day].value }}<br>
            L: {{ tr2fpcadayLeader[day].value }} / T: {{ tr2fpcadayTechnician[day].value }} / O: {{ tr2fpcadayOperator[day].value }} / Q: {{ tr2fpcadayQuality[day].value }}<br>
            Notice : {{ tr2fpcadayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR2 FPCA Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr2fpcanightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2fpcanightQty[day])"> {{ tr2fpcanightQty[day].value }}</button><br>
            Work Order : {{ tr2fpcanightWo[day].value }}<br>
            L: {{ tr2fpcanightLeader[day].value }} / T: {{ tr2fpcanightTechnician[day].value }} / O: {{ tr2fpcanightOperator[day].value }} / Q: {{ tr2fpcanightQuality[day].value }}<br>
            Notice : {{ tr2fpcanightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--TR2 BFA 2P Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR2 BFA 2P')"><B>TR2 BFA 2P</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr2bfa2pdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2bfa2pdayQty[day])"> {{ tr2bfa2pdayQty[day].value }}</button><br>
            Work Order : {{ tr2bfa2pdayWo[day].value }}<br>
            L: {{ tr2bfa2pdayLeader[day].value }} / T: {{ tr2bfa2pdayTechnician[day].value }} / O: {{ tr2bfa2pdayOperator[day].value }} / Q: {{ tr2bfa2pdayQuality[day].value }}<br>
            Notice : {{ tr2bfa2pdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR2 BFA 2P Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr2bfa2pnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2bfa2pnightQty[day])"> {{ tr2bfa2pnightQty[day].value }}</button><br>
            Work Order : {{ tr2bfa2pnightWo[day].value }}<br>
            L: {{ tr2bfa2pnightLeader[day].value }} / T: {{ tr2bfa2pnightTechnician[day].value }} / O: {{ tr2bfa2pnightOperator[day].value }} / Q: {{ tr2bfa2pnightQuality[day].value }}<br>
            Notice : {{ tr2bfa2pnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--TR2 BFA 3P Day-->
          <td rowspan="2"><B>1</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR2 BFA 3P')"><B>TR2 BFA 3P</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr2bfa3pdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2bfa3pdayQty[day])"> {{ tr2bfa3pdayQty[day].value }}</button><br>
            Work Order : {{ tr2bfa3pdayWo[day].value }}<br>
            L: {{ tr2bfa3pdayLeader[day].value }} / T: {{ tr2bfa3pdayTechnician[day].value }} / O: {{ tr2bfa3pdayOperator[day].value }} / Q: {{ tr2bfa3pdayQuality[day].value }}<br>
            Notice : {{ tr2bfa3pdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR2 BFA 3P Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr2bfa3pnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr2bfa3pnightQty[day])"> {{ tr2bfa3pnightQty[day].value }}</button><br>
            Work Order : {{ tr2bfa3pnightWo[day].value }}<br>
            L: {{ tr2bfa3pnightLeader[day].value }} / T: {{ tr2bfa3pnightTechnician[day].value }} / O: {{ tr2bfa3pnightOperator[day].value }} / Q: {{ tr2bfa3pnightQuality[day].value }}<br>
            Notice : {{ tr2bfa3pnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--TR3 SMT Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR3 SMT')"><B>TR3 SMT</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr3smtdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3smtdayQty[day])"> {{ tr3smtdayQty[day].value }}</button><br>
            Work Order : {{ tr3smtdayWo[day].value }}<br>
            L: {{ tr3smtdayLeader[day].value }} / T: {{ tr3smtdayTechnician[day].value }} / O: {{ tr3smtdayOperator[day].value }} / Q: {{ tr3smtdayQuality[day].value }}<br>
            Notice : {{ tr3smtdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR3 SMT Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr3smtnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3smtnightQty[day])"> {{ tr3smtnightQty[day].value }}</button><br>
            Work Order : {{ tr3smtnightWo[day].value }}<br>
            L: {{ tr3smtnightLeader[day].value }} / T: {{ tr3smtnightTechnician[day].value }} / O: {{ tr3smtnightOperator[day].value }} / Q: {{ tr3smtnightQuality[day].value }}<br>
            Notice : {{ tr3smtnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--2nd Semi Check Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('2dn Semi check')"><B>2nd Semi Check</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ semicheck2nddayModel[day].value }}<br>
            QTY: <button @click="openEditModal(semicheck2nddayQty[day])"> {{ semicheck2nddayQty[day].value }}</button><br>
            Work Order : {{ semicheck2nddayWo[day].value }}<br>
            L: {{ semicheck2nddayLeader[day].value }} / T: {{ semicheck2nddayTechnician[day].value }} / O: {{ semicheck2nddayOperator[day].value }} / Q: {{ semicheck2nddayQuality[day].value }}<br>
            Notice : {{ semicheck2nddayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--2nd Semi Check Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ semicheck2ndnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(semicheck2ndnightQty[day])"> {{ semicheck2ndnightQty[day].value }}</button><br>
            Work Order : {{ semicheck2ndnightWo[day].value }}<br>
            L: {{ semicheck2ndnightLeader[day].value }} / T: {{ semicheck2ndnightTechnician[day].value }} / O: {{ semicheck2ndnightOperator[day].value }} / Q: {{ semicheck2ndnightQuality[day].value }}<br>
            Notice : {{ semicheck2ndnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--TR3 Crimping Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR3 Crimping')"><B>TR3 Crimping</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr3crimpingdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3crimpingdayQty[day])"> {{ tr3crimpingdayQty[day].value }}</button><br>
            Work Order : {{ tr3crimpingdayWo[day].value }}<br>
            L: {{ tr3crimpingdayLeader[day].value }} / T: {{ tr3crimpingdayTechnician[day].value }} / O: {{ tr3crimpingdayOperator[day].value }} / Q: {{ tr3crimpingdayQuality[day].value }}<br>
            Notice : {{ tr3crimpingdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR3 Crimping Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr3crimpingnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3crimpingnightQty[day])"> {{ tr3crimpingnightQty[day].value }}</button><br>
            Work Order : {{ tr3crimpingnightWo[day].value }}<br>
            L: {{ tr3crimpingnightLeader[day].value }} / T: {{ tr3crimpingnightTechnician[day].value }} / O: {{ tr3crimpingnightOperator[day].value }} / Q: {{ tr3crimpingnightQuality[day].value }}<br>
            Notice : {{ tr3crimpingnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--TR3 FPCA Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('TR3 FPCA')"><B>TR3 FPCA</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr3fpcadayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3fpcadayQty[day])"> {{ tr3fpcadayQty[day].value }}</button><br>
            Work Order : {{ tr3fpcadayWo[day].value }}<br>
            L: {{ tr3fpcadayLeader[day].value }} / T: {{ tr3fpcadayTechnician[day].value }} / O: {{ tr3fpcadayOperator[day].value }} / Q: {{ tr3fpcadayQuality[day].value }}<br>
            Notice : {{ tr3fpcadayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--TR3 FPCA Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr3fpcanightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3fpcanightQty[day])"> {{ tr3fpcanightQty[day].value }}</button><br>
            Work Order : {{ tr3fpcanightWo[day].value }}<br>
            L: {{ tr3fpcanightLeader[day].value }} / T: {{ tr3fpcanightTechnician[day].value }} / O: {{ tr3fpcanightOperator[day].value }} / Q: {{ tr3fpcanightQuality[day].value }}<br>
            Notice : {{ tr3fpcanightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--EVO 1 Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EVO 1')"><B>EVO 1</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr3bfa2pdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3bfa2pdayQty[day])"> {{ tr3bfa2pdayQty[day].value }}</button><br>
            Work Order : {{ tr3bfa2pdayWo[day].value }}<br>
            L: {{ tr3bfa2pdayLeader[day].value }} / T: {{ tr3bfa2pdayTechnician[day].value }} / O: {{ tr3bfa2pdayOperator[day].value }} / Q: {{ tr3bfa2pdayQuality[day].value }}<br>
            Notice : {{ tr3bfa2pdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EVO 1 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr3bfa2pnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3bfa2pnightQty[day])"> {{ tr3bfa2pnightQty[day].value }}</button><br>
            Work Order : {{ tr3bfa2pnightWo[day].value }}<br>
            L: {{ tr3bfa2pnightLeader[day].value }} / T: {{ tr3bfa2pnightTechnician[day].value }} / O: {{ tr3bfa2pnightOperator[day].value }} / Q: {{ tr3bfa2pnightQuality[day].value }}<br>
            Notice : {{ tr3bfa2pnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--EVO 2 Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EVO 2')"><B>EVO 2</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ tr3bfa3pdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3bfa3pdayQty[day])"> {{ tr3bfa3pdayQty[day].value }}</button><br>
            Work Order : {{ tr3bfa3pdayWo[day].value }}<br>
            L: {{ tr3bfa3pdayLeader[day].value }} / T: {{ tr3bfa3pdayTechnician[day].value }} / O: {{ tr3bfa3pdayOperator[day].value }} / Q: {{ tr3bfa3pdayQuality[day].value }}<br>
            Notice : {{ tr3bfa3pdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EVO 2 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ tr3bfa3pnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(tr3bfa3pnightQty[day])"> {{ tr3bfa3pnightQty[day].value }}</button><br>
            Work Order : {{ tr3bfa3pnightWo[day].value }}<br>
            L: {{ tr3bfa3pnightLeader[day].value }} / T: {{ tr3bfa3pnightTechnician[day].value }} / O: {{ tr3bfa3pnightOperator[day].value }} / Q: {{ tr3bfa3pnightQuality[day].value }}<br>
            Notice : {{ tr3bfa3pnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--EVO Hybrid Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EVO Hybrid')"><B>EVO Hybrid</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ evodayModel[day].value }}<br>
            QTY: <button @click="openEditModal(evodayQty[day])"> {{ evodayQty[day].value }}</button><br>
            Work Order : {{ evodayWo[day].value }}<br>
            L: {{ evodayLeader[day].value }} / T: {{ evodayTechnician[day].value }} / O: {{ evodayOperator[day].value }} / Q: {{ evodayQuality[day].value }}<br>
            Notice : {{ evodayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EVO Hybrid Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ evonightModel[day].value }}<br>
            QTY: <button @click="openEditModal(evonightQty[day])"> {{ evonightQty[day].value }}</button><br>
            Work Order : {{ evonightWo[day].value }}<br>
            L: {{ evonightLeader[day].value }} / T: {{ evonightTechnician[day].value }} / O: {{ evonightOperator[day].value }} / Q: {{ evonightQuality[day].value }}<br>
            Notice : {{ evonightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--Bending 1 Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Bending 1')"><B>Bending 1</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ bending1dayModel[day].value }}<br>
            QTY: <button @click="openEditModal(bending1dayQty[day])"> {{ bending1dayQty[day].value }}</button><br>
            Work Order : {{ bending1dayWo[day].value }}<br>
            L: {{ bending1dayLeader[day].value }} / T: {{ bending1dayTechnician[day].value }} / O: {{ bending1dayOperator[day].value }} / Q: {{ bending1dayQuality[day].value }}<br>
            Notice : {{ bending1dayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Bending 1 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ bending1nightModel[day].value }}<br>
            QTY: <button @click="openEditModal(bending1nightQty[day])"> {{ bending1nightQty[day].value }}</button><br>
            Work Order : {{ bending1nightWo[day].value }}<br>
            L: {{ bending1nightLeader[day].value }} / T: {{ bending1nightTechnician[day].value }} / O: {{ bending1nightOperator[day].value }} / Q: {{ bending1nightQuality[day].value }}<br>
            Notice : {{ bending1nightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--Bending 2 Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('Bending 2')"><B>Bending 2</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ bending2dayModel[day].value }}<br>
            QTY: <button @click="openEditModal(bending2dayQty[day])"> {{ bending2dayQty[day].value }}</button><br>
            Work Order : {{ bending2dayWo[day].value }}<br>
            L: {{ bending2dayLeader[day].value }} / T: {{ bending2dayTechnician[day].value }} / O: {{ bending2dayOperator[day].value }} / Q: {{ bending2dayQuality[day].value }}<br>
            Notice : {{ bending2dayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--Bending 2 Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ bending2nightModel[day].value }}<br>
            QTY: <button @click="openEditModal(bending2nightQty[day])"> {{ bending2nightQty[day].value }}</button><br>
            Work Order : {{ bending2nightWo[day].value }}<br>
            L: {{ bending2nightLeader[day].value }} / T: {{ bending2nightTechnician[day].value }} / O: {{ bending2nightOperator[day].value }} / Q: {{ bending2nightQuality[day].value }}<br>
            Notice : {{ bending2nightNotice[day].value }}
          </td>
        </tr>  
        <tr> <!--EV2020 4060kW Front Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EV2020 4060kW Front')"><B>EV2020 4060kW Front</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ev2020_4060frdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_4060frdayQty[day])"> {{ ev2020_4060frdayQty[day].value }}</button><br>
            Work Order : {{ ev2020_4060frdayWo[day].value }}<br>
            L: {{ ev2020_4060frdayLeader[day].value }} / T: {{ ev2020_4060frdayTechnician[day].value }} / O: {{ ev2020_4060frdayOperator[day].value }} / Q: {{ ev2020_4060frdayQuality[day].value }}<br>
            Notice : {{ ev2020_4060frdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EV2020 4060kW Front Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ev2020_4060frnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_4060frnightQty[day])"> {{ ev2020_4060frnightQty[day].value }}</button><br>
            Work Order : {{ ev2020_4060frnightWo[day].value }}<br>
            L: {{ ev2020_4060frnightLeader[day].value }} / T: {{ ev2020_4060frnightTechnician[day].value }} / O: {{ ev2020_4060frnightOperator[day].value }} / Q: {{ ev2020_4060frnightQuality[day].value }}<br>
            Notice : {{ ev2020_4060frnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--EV2020 4060kW Rear Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EV2020 4060kW Rear')"><B>EV2020 4060kW Rear</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ev2020_4060rrdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_4060rrdayQty[day])"> {{ ev2020_4060rrdayQty[day].value }}</button><br>
            Work Order : {{ ev2020_4060rrdayWo[day].value }}<br>
            L: {{ ev2020_4060rrdayLeader[day].value }} / T: {{ ev2020_4060rrdayTechnician[day].value }} / O: {{ ev2020_4060rrdayOperator[day].value }} / Q: {{ ev2020_4060rrdayQuality[day].value }}<br>
            Notice : {{ ev2020_4060rrdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EV2020 4060kW Rear Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ev2020_4060rrnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_4060rrnightQty[day])"> {{ ev2020_4060rrnightQty[day].value }}</button><br>
            Work Order : {{ ev2020_4060rrnightWo[day].value }}<br>
            L: {{ ev2020_4060rrnightLeader[day].value }} / T: {{ ev2020_4060rrnightTechnician[day].value }} / O: {{ ev2020_4060rrnightOperator[day].value }} / Q: {{ ev2020_4060rrnightQuality[day].value }}<br>
            Notice : {{ ev2020_4060rrnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--EV2020 60kW Front Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EV2020 60kW Front')"><B>EV2020 60kW Front</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ev2020_60frdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_60frdayQty[day])"> {{ ev2020_60frdayQty[day].value }}</button><br>
            Work Order : {{ ev2020_60frdayWo[day].value }}<br>
            L: {{ ev2020_60frdayLeader[day].value }} / T: {{ ev2020_60frdayTechnician[day].value }} / O: {{ ev2020_60frdayOperator[day].value }} / Q: {{ ev2020_60frdayQuality[day].value }}<br>
            Notice : {{ ev2020_60frdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EV2020 60kW Front Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ev2020_60frnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_60frnightQty[day])"> {{ ev2020_60frnightQty[day].value }}</button><br>
            Work Order : {{ ev2020_60frnightWo[day].value }}<br>
            L: {{ ev2020_60frnightLeader[day].value }} / T: {{ ev2020_60frnightTechnician[day].value }} / O: {{ ev2020_60frnightOperator[day].value }} / Q: {{ ev2020_60frnightQuality[day].value }}<br>
            Notice : {{ ev2020_60frnightNotice[day].value }}
          </td>
        </tr>
        <tr> <!--EV2020 60kW Rear Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EV2020 60kW Rear')"><B>EV2020 60kW Rear</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ev2020_60rrdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_60rrdayQty[day])"> {{ ev2020_60rrdayQty[day].value }}</button><br>
            Work Order : {{ ev2020_60rrdayWo[day].value }}<br>
            L: {{ ev2020_60rrdayLeader[day].value }} / T: {{ ev2020_60rrdayTechnician[day].value }} / O: {{ ev2020_60rrdayOperator[day].value }} / Q: {{ ev2020_60rrdayQuality[day].value }}<br>
            Notice : {{ ev2020_60rrdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EV2020 60kW Rear Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ev2020_60rrnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_60rrnightQty[day])"> {{ ev2020_60rrnightQty[day].value }}</button><br>
            Work Order : {{ ev2020_60rrnightWo[day].value }}<br>
            L: {{ ev2020_60rrnightLeader[day].value }} / T: {{ ev2020_60rrnightTechnician[day].value }} / O: {{ ev2020_60rrnightOperator[day].value }} / Q: {{ ev2020_60rrnightQuality[day].value }}<br>
            Notice : {{ ev2020_60rrnightNotice[day].value }}
          </td>
        </tr>    
        <tr> <!--EV2020 Packing Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EV2020 Packing')"><B>EV2020 Packing</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ev2020_packingdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_packingdayQty[day])"> {{ ev2020_packingdayQty[day].value }}</button><br>
            Work Order : {{ ev2020_packingdayWo[day].value }}<br>
            L: {{ ev2020_packingdayLeader[day].value }} / T: {{ ev2020_packingdayTechnician[day].value }} / O: {{ ev2020_packingdayOperator[day].value }} / Q: {{ ev2020_packingdayQuality[day].value }}<br>
            Notice : {{ ev2020_packingdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EV2020 Packing Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ev2020_packingnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_packingnightQty[day])"> {{ ev2020_packingnightQty[day].value }}</button><br>
            Work Order : {{ ev2020_packingnightWo[day].value }}<br>
            L: {{ ev2020_packingnightLeader[day].value }} / T: {{ ev2020_packingnightTechnician[day].value }} / O: {{ ev2020_packingnightOperator[day].value }} / Q: {{ ev2020_packingnightQuality[day].value }}<br>
            Notice : {{ ev2020_packingnightNotice[day].value }}
          </td>
        </tr>      
        <tr> <!--EV2020 FFC Day-->
          <td rowspan="2"><B>2</B></td><td rowspan="2"><button class="modalbutton" @click="showModal('EV2020 Packing')"><B>EV2020 FFC</B></button></td>
          <td class="daybase"><B>Day</B></td>
          <td v-for="day in days" :key="day" class="day">
            Model : {{ ev2020_ffcdayModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_ffcdayQty[day])"> {{ ev2020_ffcdayQty[day].value }}</button><br>
            Work Order : {{ ev2020_ffcdayWo[day].value }}<br>
            L: {{ ev2020_ffcdayLeader[day].value }} / T: {{ ev2020_ffcdayTechnician[day].value }} / O: {{ ev2020_ffcdayOperator[day].value }} / Q: {{ ev2020_ffcdayQuality[day].value }}<br>
            Notice : {{ ev2020_ffcdayNotice[day].value }}
          </td>
        </tr> 
        <tr> <!--EV2020 FFC Night-->
          <td class="nightbase"><B>Night</B></td>
          <td v-for="day in days" :key="day" class="night">
            Model : {{ ev2020_ffcnightModel[day].value }}<br>
            QTY: <button @click="openEditModal(ev2020_ffcnightQty[day])"> {{ ev2020_ffcnightQty[day].value }}</button><br>
            Work Order : {{ ev2020_ffcnightWo[day].value }}<br>
            L: {{ ev2020_ffcnightLeader[day].value }} / T: {{ ev2020_ffcnightTechnician[day].value }} / O: {{ ev2020_ffcnightOperator[day].value }} / Q: {{ ev2020_ffcnightQuality[day].value }}<br>
            Notice : {{ ev2020_ffcnightNotice[day].value }}
          </td>
        </tr>             
      </table>                      
    </div>       
    <Modal v-if="isModalVisible" :selectedLine="selectedLine" @onClose="isModalVisible = false; callSearchData();" />
    <editModal v-if="show_edit_Modal" :row-id="selectedRowId" @onClose="show_edit_Modal = false; callSearchData();" ></editModal>
  </div>  
</template>

<script>
import { ref, computed, reactive, onMounted } from "vue";
import axios from "axios";
import Modal from "./planning_modal.vue";
import editModal from "./editModal.vue";
import readXlsxFile from 'read-excel-file';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';


export default {  
  components: { Modal, editModal },   
  methods: {
    openEditModal(data) {      
      this.selectedRowId = data.id;
      this.show_edit_Modal = true;      
    },
    fetchDataForPrevWeek() {
      this.prevWeek();
      this.callSearchData();
    },  
    fetchDataForNextWeek() {
      this.nextWeek();
      this.callSearchData();
    }, 
    onFileChange(event) {
        this.excelFile = event.target.files ? event.target.files[0] : null;
        this.fileName = this.excelFile ? this.excelFile.name : "";
    },
    async uploadExcel(tableName) {
      
      if (!this.excelFile) {
          alert('Please select an Excel file.');
          return;
      }
      try {
          const rows = await readXlsxFile(this.excelFile);
          const columns = rows[0];
          const data = rows.slice(1);

          console.log('column :', columns.join(', '));
          data.forEach((row, index) => {
          console.log(`value row${index + 1} :`, row.join(', '));
          });
          
          const response = await axios.post('/api/upload-excel', {
          data: rows,
          tableName: tableName,
          });

          console.log(response);
          alert('File uploaded successfully.');
      } catch (error) {
          console.error(error);
          alert('Error uploading the file.');
      }
    },         
  },
  data() {
    return {
      show_edit_Modal: false,
      selectedRowId: null,
      fileName: "",  
    };
  },
  setup() {
    const dbTableName = "weekplan";
    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const headers = ['year', 'month', 'day', 'factory', 'line', 'shift', 'model', 'code', 'planquantity', 'workorder', 'leader', 
                 'technician', 'operator', 'qualityworker', 'notice'];
    const currentDate = ref(new Date());
    const currentDateAndWeek = computed(() => {
      const weekNumber = getWeekNumber(currentDate.value);
      return `Week ${weekNumber}`;
    });
    const weekDates = computed(() => {
      const weekStart = new Date(currentDate.value);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);

      const dates = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(weekStart);
        date.setDate(weekStart.getDate() + i);
        dates.push(date);
      }

      return dates;
    });
    const prevWeek = () => {
      currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() - 7));
    };
    const nextWeek = () => {
      currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() + 7));
    };
    function getWeekNumber(date) {
      const startOfYear = new Date(date.getFullYear(), 0, 1);
      const weekNumber = Math.ceil((((date - startOfYear) / 86400000) + startOfYear.getDay() + 1) / 7);
      return weekNumber;
    }
    const isModalVisible = ref(false);
    const selectedLine = ref('');    
    const showModal = (lineName) => {
      selectedLine.value = lineName;
      isModalVisible.value = true;
    };
    const closeModal = () => {
      isModalVisible.value = false;
    };

    const bmw48vday_db = reactive({ data: [], });
    const bmw48vnight_db = reactive({ data: [], });
    const bmw12vday_db = reactive({ data: [], });
    const bmw12vnight_db = reactive({ data: [], });
    const fca1day_db = reactive({ data: [], });
    const fca1night_db = reactive({ data: [], });
    const fca2day_db = reactive({ data: [], });
    const fca2night_db = reactive({ data: [], });
    const fcaflexpoday_db = reactive({ data: [], });
    const fcaflexponight_db = reactive({ data: [], });
    const fcaflexneday_db = reactive({ data: [], });
    const fcaflexnenight_db = reactive({ data: [], });
    const fcaflexbcday_db = reactive({ data: [], });
    const fcaflexbcnight_db = reactive({ data: [], });
    const fcaflex45day_db = reactive({ data: [], });
    const fcaflex45night_db = reactive({ data: [], });
    const fcaflex67day_db = reactive({ data: [], });
    const fcaflex67night_db = reactive({ data: [], });
    const bt6day_db = reactive({ data: [], });
    const bt6night_db = reactive({ data: [], });
    const ford1frday_db = reactive({ data: [], });
    const ford1frnight_db = reactive({ data: [], });
    const ford1rrday_db = reactive({ data: [], });
    const ford1rrnight_db = reactive({ data: [], });
    const ford2frday_db = reactive({ data: [], });
    const ford2frnight_db = reactive({ data: [], });
    const ford2rrday_db = reactive({ data: [], });
    const ford2rrnight_db = reactive({ data: [], });
    const ford3frday_db = reactive({ data: [], });
    const ford3frnight_db = reactive({ data: [], });
    const ford3rrday_db = reactive({ data: [], });
    const ford3rrnight_db = reactive({ data: [], });
    const tr2smtday_db = reactive({ data: [], });
    const tr2smtnight_db = reactive({ data: [], });
    const tr2crimpingday_db = reactive({ data: [], });
    const tr2crimpingnight_db = reactive({ data: [], });
    const tr2fpcaday_db = reactive({ data: [], });
    const tr2fpcanight_db = reactive({ data: [], });
    const tr2bfa2pday_db = reactive({ data: [], });
    const tr2bfa2pnight_db = reactive({ data: [], });
    const tr2bfa3pday_db = reactive({ data: [], });
    const tr2bfa3pnight_db = reactive({ data: [], });    
    const tr3smtday_db = reactive({ data: [], });
    const tr3smtnight_db = reactive({ data: [], });
    const tr3crimpingday_db = reactive({ data: [], });
    const tr3crimpingnight_db = reactive({ data: [], });
    const tr3fpcaday_db = reactive({ data: [], });
    const tr3fpcanight_db = reactive({ data: [], });
    const tr3bfa2pday_db = reactive({ data: [], });
    const tr3bfa2pnight_db = reactive({ data: [], });
    const tr3bfa3pday_db = reactive({ data: [], });
    const tr3bfa3pnight_db = reactive({ data: [], });    
    const evoday_db = reactive({ data: [], });
    const evonight_db = reactive({ data: [], });    
    
    const bending1day_db = reactive({ data: [], });
    const bending1night_db = reactive({ data: [], });   
    const bending2day_db = reactive({ data: [], });
    const bending2night_db = reactive({ data: [], });   

    const ev2020_4060frday_db = reactive({ data: [], });
    const ev2020_4060frnight_db = reactive({ data: [], });    
    const ev2020_4060rrday_db = reactive({ data: [], });
    const ev2020_4060rrnight_db = reactive({ data: [], });    
    const ev2020_60frday_db = reactive({ data: [], });
    const ev2020_60frnight_db = reactive({ data: [], });    
    const ev2020_60rrday_db = reactive({ data: [], });
    const ev2020_60rrnight_db = reactive({ data: [], });   

    const ev2020_packingday_db = reactive({ data: [], });
    const ev2020_packingnight_db = reactive({ data: [], });   
    const ev2020_ffcday_db = reactive({ data: [], });
    const ev2020_ffcnight_db = reactive({ data: [], });  

    const semicheck1stday_db = reactive({ data: [], });
    const semicheck1stnight_db = reactive({ data: [], });    
    const semicheck2ndday_db = reactive({ data: [], });
    const semicheck2ndnight_db = reactive({ data: [], });    
    const totalData = reactive({ data: [] }); 

    const db = {
      "BMW 48V Day": bmw48vday_db,
      "BMW 48V Night": bmw48vnight_db,
      "BMW 12V Day": bmw12vday_db,
      "BMW 12V Night": bmw12vnight_db,
      "FCA 1 Day": fca1day_db,
      "FCA 1 Night": fca1night_db,
      "FCA 2 Day": fca2day_db,
      "FCA 2 Night": fca2night_db,
      "FCA FLEX PO Day": fcaflexpoday_db,
      "FCA FLEX PO Night": fcaflexponight_db,
      "FCA FLEX NE Day": fcaflexneday_db,
      "FCA FLEX NE Night": fcaflexnenight_db,
      "FCA FLEX BC Day": fcaflexbcday_db,
      "FCA FLEX BC Night": fcaflexbcnight_db,
      "FCA FLEX 45 Day": fcaflex45day_db,
      "FCA FLEX 45 Night": fcaflex45night_db,
      "FCA FLEX 67 Day": fcaflex67day_db,
      "FCA FLEX 67 Night": fcaflex67night_db,
      "BT6 Day": bt6day_db,
      "BT6 Night": bt6night_db,
      "Ford 1 Front Day": ford1frday_db,
      "Ford 1 Front Night": ford1frnight_db,
      "Ford 1 Rear Day": ford1rrday_db,
      "Ford 1 Rear Night": ford1rrnight_db,
      "Ford 2 Front Day": ford2frday_db,
      "Ford 2 Front Night": ford2frnight_db,
      "Ford 2 Rear Day": ford2rrday_db,
      "Ford 2 Rear Night": ford2rrnight_db,
      "Ford 3 Front Day": ford3frday_db,
      "Ford 3 Front Night": ford3frnight_db,
      "Ford 3 Rear Day": ford3rrday_db,
      "Ford 3 Rear Night": ford3rrnight_db,  
      "TR2 SMT Day": tr2smtday_db,
      "TR2 SMT Night": tr2smtnight_db,
      "TR2 Crimping Day": tr2crimpingday_db,
      "TR2 Crimping Night": tr2crimpingnight_db,
      "TR2 FPCA Day": tr2fpcaday_db,
      "TR2 FPCA Night": tr2fpcanight_db,
      "TR2 BFA 2P Day": tr2bfa2pday_db,
      "TR2 BFA 2P Night": tr2bfa2pnight_db,
      "TR2 BFA 3P Day": tr2bfa3pday_db,
      "TR2 BFA 3P Night": tr2bfa3pnight_db,
      "TR3 SMT Day": tr3smtday_db,
      "TR3 SMT Night": tr3smtnight_db,
      "TR3 Crimping Day": tr3crimpingday_db,
      "TR3 Crimping Night": tr3crimpingnight_db,
      "TR3 FPCA Day": tr3fpcaday_db,
      "TR3 FPCA Night": tr3fpcanight_db,
      "EVO 1 Day": tr3bfa2pday_db,
      "EVO 1 Night": tr3bfa2pnight_db,
      "EVO 2 Day": tr3bfa3pday_db,
      "EVO 2 Night": tr3bfa3pnight_db,
      "EVO Hybrid Day": evoday_db,
      "EVO Hybrid Night": evonight_db,      
      "Bending 1 Day": bending1day_db,
      "Bending 1 Night": bending1night_db,  
      "Bending 2 Day": bending2day_db,
      "Bending 2 Night": bending2night_db,  
      "EV2020 4060kW Front Day": ev2020_4060frday_db,
      "EV2020 4060kW Front Night": ev2020_4060frnight_db,
      "EV2020 4060kW Rear Day": ev2020_4060rrday_db,
      "EV2020 4060kW Rear Night": ev2020_4060rrnight_db,
      "EV2020 60kW Front Day": ev2020_60frday_db,
      "EV2020 60kW Front Night": ev2020_60frnight_db,
      "EV2020 60kW Rear Day": ev2020_60rrday_db,
      "EV2020 60kW Rear Night": ev2020_60rrnight_db,
      "EV2020 Packing Day": ev2020_packingday_db,
      "EV2020 Packing Night": ev2020_packingnight_db,
      "EV2020 FFC Day": ev2020_ffcday_db,
      "EV2020 FFC Night": ev2020_ffcnight_db,
      "1st Semi Check Day": semicheck1stday_db,
      "1st Semi Check Night": semicheck1stnight_db,
      "2nd Semi Check Day": semicheck2ndday_db,
      "2nd Semi Check Night": semicheck2ndnight_db,
    };

    const getDayKey = (dayIndex) => { switch (dayIndex) { case 1: return 'mon'; case 2: return 'tue'; case 3: return 'wed'; case 4: return 'thu'; case 5: return 'fri'; case 6: return 'sat'; case 0: return 'sun'; default: return null; }};
      
    const getItemByDayAndLine = (day, line) => {
      return computed(() => {
        if (!db[day]) {
          return [];
        }
        const filteredData = db[day].filter(d => d.line === line);        
        return filteredData.map(d => {
          return { ...d };
        });
      });
    };  
    
    const searchData = async (tableName, line, shift) => {
        const startWeek = weekDates.value[0];
        const endWeek = weekDates.value[weekDates.value.length - 1];

        const formData = {
          tableName: tableName,
          startYear: startWeek.getFullYear(),
          startMonth: startWeek.getMonth() + 1,
          startDay: startWeek.getDate(),
          endYear: endWeek.getFullYear(),
          endMonth: endWeek.getMonth() + 1,
          endDay: endWeek.getDate(),
          line: line,
          shift: shift
        };

        axios.get('/api/searchPeriod', { params: formData })
          .then((res) => {
            const key = `${line} ${shift}`;

            if (db[key]) {
              db[key].data = res.data.sort((a, b) => {
                if (a.year !== b.year) {
                  return a.year - b.year;
                } else if (a.month !== b.month) {
                  return a.month - b.month;
                } else {
                  return a.day - b.day;
                }
              });              
            }
          });
      }; 

      const searchDataForExcel = async (tableName) => {
        const startWeek = weekDates.value[0];
        const endWeek = weekDates.value[weekDates.value.length - 1];
        const formData = {        
            tableName: tableName,
            startYear: startWeek.getFullYear(),
            startMonth: startWeek.getMonth() + 1,
            startDay: startWeek.getDate(),
            endYear: endWeek.getFullYear(),
            endMonth: endWeek.getMonth() + 1,
            endDay: endWeek.getDate(),
        };

        totalData.data = [];

        axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { totalData.data = res.data; });        
      };

    function createItem(db, itemName) {
      return computed(() => {
        const items = { mon: { id: null, value: '' },
          tue: { id: null, value: '' },
          wed: { id: null, value: '' },
          thu: { id: null, value: '' },
          fri: { id: null, value: '' },
          sat: { id: null, value: '' },
          sun: { id: null, value: '' },
        };

        for (const item of db.data) {
          const dayKey = getDayKey(new Date(item.year, item.month - 1, item.day).getDay());
          if (dayKey) {
            items[dayKey].value = item[itemName];
            items[dayKey].id = item.id;
          }
        }
        return items;
      });
    }
    const bmw48vdayModel = createItem(bmw48vday_db, 'model');
    const bmw48vdayQty = createItem(bmw48vday_db, 'planquantity');
    const bmw48vdayWo = createItem(bmw48vday_db, 'workorder');
    const bmw48vdayLeader = createItem(bmw48vday_db, 'leader');
    const bmw48vdayTechnician = createItem(bmw48vday_db, 'technician');
    const bmw48vdayOperator = createItem(bmw48vday_db, 'operator');
    const bmw48vdayQuality = createItem(bmw48vday_db, 'qualityworker');
    const bmw48vdayNotice = createItem(bmw48vday_db, 'notice');
    const bmw48vnightModel = createItem(bmw48vnight_db, 'model');
    const bmw48vnightQty = createItem(bmw48vnight_db, 'planquantity');
    const bmw48vnightWo = createItem(bmw48vnight_db, 'workorder');
    const bmw48vnightLeader = createItem(bmw48vnight_db, 'leader');
    const bmw48vnightTechnician = createItem(bmw48vnight_db, 'technician');
    const bmw48vnightOperator = createItem(bmw48vnight_db, 'operator');
    const bmw48vnightQuality = createItem(bmw48vnight_db, 'qualityworker');
    const bmw48vnightNotice = createItem(bmw48vnight_db, 'notice');

    const bmw12vdayModel = createItem(bmw12vday_db, 'model');
    const bmw12vdayQty = createItem(bmw12vday_db, 'planquantity');
    const bmw12vdayWo = createItem(bmw12vday_db, 'workorder');
    const bmw12vdayLeader = createItem(bmw12vday_db, 'leader');
    const bmw12vdayTechnician = createItem(bmw12vday_db, 'technician');
    const bmw12vdayOperator = createItem(bmw12vday_db, 'operator');
    const bmw12vdayQuality = createItem(bmw12vday_db, 'qualityworker');
    const bmw12vdayNotice = createItem(bmw12vday_db, 'notice');
    const bmw12vnightModel = createItem(bmw12vnight_db, 'model');
    const bmw12vnightQty = createItem(bmw12vnight_db, 'planquantity');
    const bmw12vnightWo = createItem(bmw12vnight_db, 'workorder');
    const bmw12vnightLeader = createItem(bmw12vnight_db, 'leader');
    const bmw12vnightTechnician = createItem(bmw12vnight_db, 'technician');
    const bmw12vnightOperator = createItem(bmw12vnight_db, 'operator');
    const bmw12vnightQuality = createItem(bmw12vnight_db, 'qualityworker');
    const bmw12vnightNotice = createItem(bmw12vnight_db, 'notice');

    const fca1dayModel = createItem(fca1day_db, 'model');
    const fca1dayQty = createItem(fca1day_db, 'planquantity');
    const fca1dayWo = createItem(fca1day_db, 'workorder');
    const fca1dayLeader = createItem(fca1day_db, 'leader');
    const fca1dayTechnician = createItem(fca1day_db, 'technician');
    const fca1dayOperator = createItem(fca1day_db, 'operator');
    const fca1dayQuality = createItem(fca1day_db, 'qualityworker');
    const fca1dayNotice = createItem(fca1day_db, 'notice');
    const fca1nightModel = createItem(fca1night_db, 'model');
    const fca1nightQty = createItem(fca1night_db, 'planquantity');
    const fca1nightWo = createItem(fca1night_db, 'workorder');
    const fca1nightLeader = createItem(fca1night_db, 'leader');
    const fca1nightTechnician = createItem(fca1night_db, 'technician');
    const fca1nightOperator = createItem(fca1night_db, 'operator');
    const fca1nightQuality = createItem(fca1night_db, 'qualityworker');
    const fca1nightNotice = createItem(fca1night_db, 'notice');

    const fca2dayModel = createItem(fca2day_db, 'model');
    const fca2dayQty = createItem(fca2day_db, 'planquantity');
    const fca2dayWo = createItem(fca2day_db, 'workorder');
    const fca2dayLeader = createItem(fca2day_db, 'leader');
    const fca2dayTechnician = createItem(fca2day_db, 'technician');
    const fca2dayOperator = createItem(fca2day_db, 'operator');
    const fca2dayQuality = createItem(fca2day_db, 'qualityworker');
    const fca2dayNotice = createItem(fca2day_db, 'notice');
    const fca2nightModel = createItem(fca2night_db, 'model');
    const fca2nightQty = createItem(fca2night_db, 'planquantity');
    const fca2nightWo = createItem(fca2night_db, 'workorder');
    const fca2nightLeader = createItem(fca2night_db, 'leader');
    const fca2nightTechnician = createItem(fca2night_db, 'technician');
    const fca2nightOperator = createItem(fca2night_db, 'operator');
    const fca2nightQuality = createItem(fca2night_db, 'qualityworker');
    const fca2nightNotice = createItem(fca2night_db, 'notice');

    const fcaflexpodayModel = createItem(fcaflexpoday_db, 'model');
    const fcaflexpodayQty = createItem(fcaflexpoday_db, 'planquantity');
    const fcaflexpodayWo = createItem(fcaflexpoday_db, 'workorder');
    const fcaflexpodayLeader = createItem(fcaflexpoday_db, 'leader');
    const fcaflexpodayTechnician = createItem(fcaflexpoday_db, 'technician');
    const fcaflexpodayOperator = createItem(fcaflexpoday_db, 'operator');
    const fcaflexpodayQuality = createItem(fcaflexpoday_db, 'qualityworker');
    const fcaflexpodayNotice = createItem(fcaflexpoday_db, 'notice');
    const fcaflexponightModel = createItem(fcaflexponight_db, 'model');
    const fcaflexponightQty = createItem(fcaflexponight_db, 'planquantity');
    const fcaflexponightWo = createItem(fcaflexponight_db, 'workorder');
    const fcaflexponightLeader = createItem(fcaflexponight_db, 'leader');
    const fcaflexponightTechnician = createItem(fcaflexponight_db, 'technician');
    const fcaflexponightOperator = createItem(fcaflexponight_db, 'operator');
    const fcaflexponightQuality = createItem(fcaflexponight_db, 'qualityworker');
    const fcaflexponightNotice = createItem(fcaflexponight_db, 'notice');

    const fcaflexnedayModel = createItem(fcaflexneday_db, 'model');
    const fcaflexnedayQty = createItem(fcaflexneday_db, 'planquantity');
    const fcaflexnedayWo = createItem(fcaflexneday_db, 'workorder');
    const fcaflexnedayLeader = createItem(fcaflexneday_db, 'leader');
    const fcaflexnedayTechnician = createItem(fcaflexneday_db, 'technician');
    const fcaflexnedayOperator = createItem(fcaflexneday_db, 'operator');
    const fcaflexnedayQuality = createItem(fcaflexneday_db, 'qualityworker');
    const fcaflexnedayNotice = createItem(fcaflexneday_db, 'notice');
    const fcaflexnenightModel = createItem(fcaflexnenight_db, 'model');
    const fcaflexnenightQty = createItem(fcaflexnenight_db, 'planquantity');
    const fcaflexnenightWo = createItem(fcaflexnenight_db, 'workorder');
    const fcaflexnenightLeader = createItem(fcaflexnenight_db, 'leader');
    const fcaflexnenightTechnician = createItem(fcaflexnenight_db, 'technician');
    const fcaflexnenightOperator = createItem(fcaflexnenight_db, 'operator');
    const fcaflexnenightQuality = createItem(fcaflexnenight_db, 'qualityworker');
    const fcaflexnenightNotice = createItem(fcaflexnenight_db, 'notice');

    const fcaflexbcdayModel = createItem(fcaflexbcday_db, 'model');
    const fcaflexbcdayQty = createItem(fcaflexbcday_db, 'planquantity');
    const fcaflexbcdayWo = createItem(fcaflexbcday_db, 'workorder');
    const fcaflexbcdayLeader = createItem(fcaflexbcday_db, 'leader');
    const fcaflexbcdayTechnician = createItem(fcaflexbcday_db, 'technician');
    const fcaflexbcdayOperator = createItem(fcaflexbcday_db, 'operator');
    const fcaflexbcdayQuality = createItem(fcaflexbcday_db, 'qualityworker');
    const fcaflexbcdayNotice = createItem(fcaflexbcday_db, 'notice');
    const fcaflexbcnightModel = createItem(fcaflexbcnight_db, 'model');
    const fcaflexbcnightQty = createItem(fcaflexbcnight_db, 'planquantity');
    const fcaflexbcnightWo = createItem(fcaflexbcnight_db, 'workorder');
    const fcaflexbcnightLeader = createItem(fcaflexbcnight_db, 'leader');
    const fcaflexbcnightTechnician = createItem(fcaflexbcnight_db, 'technician');
    const fcaflexbcnightOperator = createItem(fcaflexbcnight_db, 'operator');
    const fcaflexbcnightQuality = createItem(fcaflexbcnight_db, 'qualityworker');
    const fcaflexbcnightNotice = createItem(fcaflexbcnight_db, 'notice');

    const fcaflex45dayModel = createItem(fcaflex45day_db, 'model');
    const fcaflex45dayQty = createItem(fcaflex45day_db, 'planquantity');
    const fcaflex45dayWo = createItem(fcaflex45day_db, 'workorder');
    const fcaflex45dayLeader = createItem(fcaflex45day_db, 'leader');
    const fcaflex45dayTechnician = createItem(fcaflex45day_db, 'technician');
    const fcaflex45dayOperator = createItem(fcaflex45day_db, 'operator');
    const fcaflex45dayQuality = createItem(fcaflex45day_db, 'qualityworker');
    const fcaflex45dayNotice = createItem(fcaflex45day_db, 'notice');
    const fcaflex45nightModel = createItem(fcaflex45night_db, 'model');
    const fcaflex45nightQty = createItem(fcaflex45night_db, 'planquantity');
    const fcaflex45nightWo = createItem(fcaflex45night_db, 'workorder');
    const fcaflex45nightLeader = createItem(fcaflex45night_db, 'leader');
    const fcaflex45nightTechnician = createItem(fcaflex45night_db, 'technician');
    const fcaflex45nightOperator = createItem(fcaflex45night_db, 'operator');
    const fcaflex45nightQuality = createItem(fcaflex45night_db, 'qualityworker');
    const fcaflex45nightNotice = createItem(fcaflex45night_db, 'notice');

    const fcaflex67dayModel = createItem(fcaflex67day_db, 'model');
    const fcaflex67dayQty = createItem(fcaflex67day_db, 'planquantity');
    const fcaflex67dayWo = createItem(fcaflex67day_db, 'workorder');
    const fcaflex67dayLeader = createItem(fcaflex67day_db, 'leader');
    const fcaflex67dayTechnician = createItem(fcaflex67day_db, 'technician');
    const fcaflex67dayOperator = createItem(fcaflex67day_db, 'operator');
    const fcaflex67dayQuality = createItem(fcaflex67day_db, 'qualityworker');
    const fcaflex67dayNotice = createItem(fcaflex67day_db, 'notice');
    const fcaflex67nightModel = createItem(fcaflex67night_db, 'model');
    const fcaflex67nightQty = createItem(fcaflex67night_db, 'planquantity');
    const fcaflex67nightWo = createItem(fcaflex67night_db, 'workorder');
    const fcaflex67nightLeader = createItem(fcaflex67night_db, 'leader');
    const fcaflex67nightTechnician = createItem(fcaflex67night_db, 'technician');
    const fcaflex67nightOperator = createItem(fcaflex67night_db, 'operator');
    const fcaflex67nightQuality = createItem(fcaflex67night_db, 'qualityworker');
    const fcaflex67nightNotice = createItem(fcaflex67night_db, 'notice');

    const bt6dayModel = createItem(bt6day_db, 'model');
    const bt6dayQty = createItem(bt6day_db, 'planquantity');
    const bt6dayWo = createItem(bt6day_db, 'workorder');
    const bt6dayLeader = createItem(bt6day_db, 'leader');
    const bt6dayTechnician = createItem(bt6day_db, 'technician');
    const bt6dayOperator = createItem(bt6day_db, 'operator');
    const bt6dayQuality = createItem(bt6day_db, 'qualityworker');
    const bt6dayNotice = createItem(bt6day_db, 'notice');
    const bt6nightModel = createItem(bt6night_db, 'model');
    const bt6nightQty = createItem(bt6night_db, 'planquantity');
    const bt6nightWo = createItem(bt6night_db, 'workorder');
    const bt6nightLeader = createItem(bt6night_db, 'leader');
    const bt6nightTechnician = createItem(bt6night_db, 'technician');
    const bt6nightOperator = createItem(bt6night_db, 'operator');
    const bt6nightQuality = createItem(bt6night_db, 'qualityworker');
    const bt6nightNotice = createItem(bt6night_db, 'notice');

    const ford1frdayModel = createItem(ford1frday_db, 'model');
    const ford1frdayQty = createItem(ford1frday_db, 'planquantity');
    const ford1frdayWo = createItem(ford1frday_db, 'workorder');
    const ford1frdayLeader = createItem(ford1frday_db, 'leader');
    const ford1frdayTechnician = createItem(ford1frday_db, 'technician');
    const ford1frdayOperator = createItem(ford1frday_db, 'operator');
    const ford1frdayQuality = createItem(ford1frday_db, 'qualityworker');
    const ford1frdayNotice = createItem(ford1frday_db, 'notice');
    const ford1frnightModel = createItem(ford1frnight_db, 'model');
    const ford1frnightQty = createItem(ford1frnight_db, 'planquantity');
    const ford1frnightWo = createItem(ford1frnight_db, 'workorder');
    const ford1frnightLeader = createItem(ford1frnight_db, 'leader');
    const ford1frnightTechnician = createItem(ford1frnight_db, 'technician');
    const ford1frnightOperator = createItem(ford1frnight_db, 'operator');
    const ford1frnightQuality = createItem(ford1frnight_db, 'qualityworker');
    const ford1frnightNotice = createItem(ford1frnight_db, 'notice');

    const ford1rrdayModel = createItem(ford1rrday_db, 'model');
    const ford1rrdayQty = createItem(ford1rrday_db, 'planquantity');
    const ford1rrdayWo = createItem(ford1rrday_db, 'workorder');
    const ford1rrdayLeader = createItem(ford1rrday_db, 'leader');
    const ford1rrdayTechnician = createItem(ford1rrday_db, 'technician');
    const ford1rrdayOperator = createItem(ford1rrday_db, 'operator');
    const ford1rrdayQuality = createItem(ford1rrday_db, 'qualityworker');
    const ford1rrdayNotice = createItem(ford1rrday_db, 'notice');
    const ford1rrnightModel = createItem(ford1rrnight_db, 'model');
    const ford1rrnightQty = createItem(ford1rrnight_db, 'planquantity');
    const ford1rrnightWo = createItem(ford1rrnight_db, 'workorder');
    const ford1rrnightLeader = createItem(ford1rrnight_db, 'leader');
    const ford1rrnightTechnician = createItem(ford1rrnight_db, 'technician');
    const ford1rrnightOperator = createItem(ford1rrnight_db, 'operator');
    const ford1rrnightQuality = createItem(ford1rrnight_db, 'qualityworker');
    const ford1rrnightNotice = createItem(ford1rrnight_db, 'notice');

    const ford2frdayModel = createItem(ford2frday_db, 'model');
    const ford2frdayQty = createItem(ford2frday_db, 'planquantity');
    const ford2frdayWo = createItem(ford2frday_db, 'workorder');
    const ford2frdayLeader = createItem(ford2frday_db, 'leader');
    const ford2frdayTechnician = createItem(ford2frday_db, 'technician');
    const ford2frdayOperator = createItem(ford2frday_db, 'operator');
    const ford2frdayQuality = createItem(ford2frday_db, 'qualityworker');
    const ford2frdayNotice = createItem(ford2frday_db, 'notice');
    const ford2frnightModel = createItem(ford2frnight_db, 'model');
    const ford2frnightQty = createItem(ford2frnight_db, 'planquantity');
    const ford2frnightWo = createItem(ford2frnight_db, 'workorder');
    const ford2frnightLeader = createItem(ford2frnight_db, 'leader');
    const ford2frnightTechnician = createItem(ford2frnight_db, 'technician');
    const ford2frnightOperator = createItem(ford2frnight_db, 'operator');
    const ford2frnightQuality = createItem(ford2frnight_db, 'qualityworker');
    const ford2frnightNotice = createItem(ford2frnight_db, 'notice');

    const ford2rrdayModel = createItem(ford2rrday_db, 'model');
    const ford2rrdayQty = createItem(ford2rrday_db, 'planquantity');
    const ford2rrdayWo = createItem(ford2rrday_db, 'workorder');
    const ford2rrdayLeader = createItem(ford2rrday_db, 'leader');
    const ford2rrdayTechnician = createItem(ford2rrday_db, 'technician');
    const ford2rrdayOperator = createItem(ford2rrday_db, 'operator');
    const ford2rrdayQuality = createItem(ford2rrday_db, 'qualityworker');
    const ford2rrdayNotice = createItem(ford2rrday_db, 'notice');
    const ford2rrnightModel = createItem(ford2rrnight_db, 'model');
    const ford2rrnightQty = createItem(ford2rrnight_db, 'planquantity');
    const ford2rrnightWo = createItem(ford2rrnight_db, 'workorder');
    const ford2rrnightLeader = createItem(ford2rrnight_db, 'leader');
    const ford2rrnightTechnician = createItem(ford2rrnight_db, 'technician');
    const ford2rrnightOperator = createItem(ford2rrnight_db, 'operator');
    const ford2rrnightQuality = createItem(ford2rrnight_db, 'qualityworker');
    const ford2rrnightNotice = createItem(ford2rrnight_db, 'notice');

    const ford3frdayModel = createItem(ford3frday_db, 'model');
    const ford3frdayQty = createItem(ford3frday_db, 'planquantity');
    const ford3frdayWo = createItem(ford3frday_db, 'workorder');
    const ford3frdayLeader = createItem(ford3frday_db, 'leader');
    const ford3frdayTechnician = createItem(ford3frday_db, 'technician');
    const ford3frdayOperator = createItem(ford3frday_db, 'operator');
    const ford3frdayQuality = createItem(ford3frday_db, 'qualityworker');
    const ford3frdayNotice = createItem(ford3frday_db, 'notice');
    const ford3frnightModel = createItem(ford3frnight_db, 'model');
    const ford3frnightQty = createItem(ford3frnight_db, 'planquantity');
    const ford3frnightWo = createItem(ford3frnight_db, 'workorder');
    const ford3frnightLeader = createItem(ford3frnight_db, 'leader');
    const ford3frnightTechnician = createItem(ford3frnight_db, 'technician');
    const ford3frnightOperator = createItem(ford3frnight_db, 'operator');
    const ford3frnightQuality = createItem(ford3frnight_db, 'qualityworker');
    const ford3frnightNotice = createItem(ford3frnight_db, 'notice');

    const ford3rrdayModel = createItem(ford3rrday_db, 'model');
    const ford3rrdayQty = createItem(ford3rrday_db, 'planquantity');
    const ford3rrdayWo = createItem(ford3rrday_db, 'workorder');
    const ford3rrdayLeader = createItem(ford3rrday_db, 'leader');
    const ford3rrdayTechnician = createItem(ford3rrday_db, 'technician');
    const ford3rrdayOperator = createItem(ford3rrday_db, 'operator');
    const ford3rrdayQuality = createItem(ford3rrday_db, 'qualityworker');
    const ford3rrdayNotice = createItem(ford3rrday_db, 'notice');
    const ford3rrnightModel = createItem(ford3rrnight_db, 'model');
    const ford3rrnightQty = createItem(ford3rrnight_db, 'planquantity');
    const ford3rrnightWo = createItem(ford3rrnight_db, 'workorder');
    const ford3rrnightLeader = createItem(ford3rrnight_db, 'leader');
    const ford3rrnightTechnician = createItem(ford3rrnight_db, 'technician');
    const ford3rrnightOperator = createItem(ford3rrnight_db, 'operator');
    const ford3rrnightQuality = createItem(ford3rrnight_db, 'qualityworker');
    const ford3rrnightNotice = createItem(ford3rrnight_db, 'notice');

    const tr2smtdayModel = createItem(tr2smtday_db, 'model');
    const tr2smtdayQty = createItem(tr2smtday_db, 'planquantity');
    const tr2smtdayWo = createItem(tr2smtday_db, 'workorder');
    const tr2smtdayLeader = createItem(tr2smtday_db, 'leader');
    const tr2smtdayTechnician = createItem(tr2smtday_db, 'technician');
    const tr2smtdayOperator = createItem(tr2smtday_db, 'operator');
    const tr2smtdayQuality = createItem(tr2smtday_db, 'qualityworker');
    const tr2smtdayNotice = createItem(tr2smtday_db, 'notice');
    const tr2smtnightModel = createItem(tr2smtnight_db, 'model');
    const tr2smtnightQty = createItem(tr2smtnight_db, 'planquantity');
    const tr2smtnightWo = createItem(tr2smtnight_db, 'workorder');
    const tr2smtnightLeader = createItem(tr2smtnight_db, 'leader');
    const tr2smtnightTechnician = createItem(tr2smtnight_db, 'technician');
    const tr2smtnightOperator = createItem(tr2smtnight_db, 'operator');
    const tr2smtnightQuality = createItem(tr2smtnight_db, 'qualityworker');
    const tr2smtnightNotice = createItem(tr2smtnight_db, 'notice');

    const tr2crimpingdayModel = createItem(tr2crimpingday_db, 'model');
    const tr2crimpingdayQty = createItem(tr2crimpingday_db, 'planquantity');
    const tr2crimpingdayWo = createItem(tr2crimpingday_db, 'workorder');
    const tr2crimpingdayLeader = createItem(tr2crimpingday_db, 'leader');
    const tr2crimpingdayTechnician = createItem(tr2crimpingday_db, 'technician');
    const tr2crimpingdayOperator = createItem(tr2crimpingday_db, 'operator');
    const tr2crimpingdayQuality = createItem(tr2crimpingday_db, 'qualityworker');
    const tr2crimpingdayNotice = createItem(tr2crimpingday_db, 'notice');
    const tr2crimpingnightModel = createItem(tr2crimpingnight_db, 'model');
    const tr2crimpingnightQty = createItem(tr2crimpingnight_db, 'planquantity');
    const tr2crimpingnightWo = createItem(tr2crimpingnight_db, 'workorder');
    const tr2crimpingnightLeader = createItem(tr2crimpingnight_db, 'leader');
    const tr2crimpingnightTechnician = createItem(tr2crimpingnight_db, 'technician');
    const tr2crimpingnightOperator = createItem(tr2crimpingnight_db, 'operator');
    const tr2crimpingnightQuality = createItem(tr2crimpingnight_db, 'qualityworker');
    const tr2crimpingnightNotice = createItem(tr2crimpingnight_db, 'notice');

    const tr2fpcadayModel = createItem(tr2fpcaday_db, 'model');
    const tr2fpcadayQty = createItem(tr2fpcaday_db, 'planquantity');
    const tr2fpcadayWo = createItem(tr2fpcaday_db, 'workorder');
    const tr2fpcadayLeader = createItem(tr2fpcaday_db, 'leader');
    const tr2fpcadayTechnician = createItem(tr2fpcaday_db, 'technician');
    const tr2fpcadayOperator = createItem(tr2fpcaday_db, 'operator');
    const tr2fpcadayQuality = createItem(tr2fpcaday_db, 'qualityworker');
    const tr2fpcadayNotice = createItem(tr2fpcaday_db, 'notice');
    const tr2fpcanightModel = createItem(tr2fpcanight_db, 'model');
    const tr2fpcanightQty = createItem(tr2fpcanight_db, 'planquantity');
    const tr2fpcanightWo = createItem(tr2fpcanight_db, 'workorder');
    const tr2fpcanightLeader = createItem(tr2fpcanight_db, 'leader');
    const tr2fpcanightTechnician = createItem(tr2fpcanight_db, 'technician');
    const tr2fpcanightOperator = createItem(tr2fpcanight_db, 'operator');
    const tr2fpcanightQuality = createItem(tr2fpcanight_db, 'qualityworker');
    const tr2fpcanightNotice = createItem(tr2fpcanight_db, 'notice');

    const tr2bfa2pdayModel = createItem(tr2bfa2pday_db, 'model');
    const tr2bfa2pdayQty = createItem(tr2bfa2pday_db, 'planquantity');
    const tr2bfa2pdayWo = createItem(tr2bfa2pday_db, 'workorder');
    const tr2bfa2pdayLeader = createItem(tr2bfa2pday_db, 'leader');
    const tr2bfa2pdayTechnician = createItem(tr2bfa2pday_db, 'technician');
    const tr2bfa2pdayOperator = createItem(tr2bfa2pday_db, 'operator');
    const tr2bfa2pdayQuality = createItem(tr2bfa2pday_db, 'qualityworker');
    const tr2bfa2pdayNotice = createItem(tr2bfa2pday_db, 'notice');
    const tr2bfa2pnightModel = createItem(tr2bfa2pnight_db, 'model');
    const tr2bfa2pnightQty = createItem(tr2bfa2pnight_db, 'planquantity');
    const tr2bfa2pnightWo = createItem(tr2bfa2pnight_db, 'workorder');
    const tr2bfa2pnightLeader = createItem(tr2bfa2pnight_db, 'leader');
    const tr2bfa2pnightTechnician = createItem(tr2bfa2pnight_db, 'technician');
    const tr2bfa2pnightOperator = createItem(tr2bfa2pnight_db, 'operator');
    const tr2bfa2pnightQuality = createItem(tr2bfa2pnight_db, 'qualityworker');
    const tr2bfa2pnightNotice = createItem(tr2bfa2pnight_db, 'notice');
    
    const tr2bfa3pdayModel = createItem(tr2bfa3pday_db, 'model');
    const tr2bfa3pdayQty = createItem(tr2bfa3pday_db, 'planquantity');
    const tr2bfa3pdayWo = createItem(tr2bfa3pday_db, 'workorder');
    const tr2bfa3pdayLeader = createItem(tr2bfa3pday_db, 'leader');
    const tr2bfa3pdayTechnician = createItem(tr2bfa3pday_db, 'technician');
    const tr2bfa3pdayOperator = createItem(tr2bfa3pday_db, 'operator');
    const tr2bfa3pdayQuality = createItem(tr2bfa3pday_db, 'qualityworker');
    const tr2bfa3pdayNotice = createItem(tr2bfa3pday_db, 'notice');
    const tr2bfa3pnightModel = createItem(tr2bfa3pnight_db, 'model');
    const tr2bfa3pnightQty = createItem(tr2bfa3pnight_db, 'planquantity');
    const tr2bfa3pnightWo = createItem(tr2bfa3pnight_db, 'workorder');
    const tr2bfa3pnightLeader = createItem(tr2bfa3pnight_db, 'leader');
    const tr2bfa3pnightTechnician = createItem(tr2bfa3pnight_db, 'technician');
    const tr2bfa3pnightOperator = createItem(tr2bfa3pnight_db, 'operator');
    const tr2bfa3pnightQuality = createItem(tr2bfa3pnight_db, 'qualityworker');
    const tr2bfa3pnightNotice = createItem(tr2bfa3pnight_db, 'notice');

    const tr3smtdayModel = createItem(tr3smtday_db, 'model');
    const tr3smtdayQty = createItem(tr3smtday_db, 'planquantity');
    const tr3smtdayWo = createItem(tr3smtday_db, 'workorder');
    const tr3smtdayLeader = createItem(tr3smtday_db, 'leader');
    const tr3smtdayTechnician = createItem(tr3smtday_db, 'technician');
    const tr3smtdayOperator = createItem(tr3smtday_db, 'operator');
    const tr3smtdayQuality = createItem(tr3smtday_db, 'qualityworker');
    const tr3smtdayNotice = createItem(tr3smtday_db, 'notice');
    const tr3smtnightModel = createItem(tr3smtnight_db, 'model');
    const tr3smtnightQty = createItem(tr3smtnight_db, 'planquantity');
    const tr3smtnightWo = createItem(tr3smtnight_db, 'workorder');
    const tr3smtnightLeader = createItem(tr3smtnight_db, 'leader');
    const tr3smtnightTechnician = createItem(tr3smtnight_db, 'technician');
    const tr3smtnightOperator = createItem(tr3smtnight_db, 'operator');
    const tr3smtnightQuality = createItem(tr3smtnight_db, 'qualityworker');
    const tr3smtnightNotice = createItem(tr3smtnight_db, 'notice');

    const tr3crimpingdayModel = createItem(tr3crimpingday_db, 'model');
    const tr3crimpingdayQty = createItem(tr3crimpingday_db, 'planquantity');
    const tr3crimpingdayWo = createItem(tr3crimpingday_db, 'workorder');
    const tr3crimpingdayLeader = createItem(tr3crimpingday_db, 'leader');
    const tr3crimpingdayTechnician = createItem(tr3crimpingday_db, 'technician');
    const tr3crimpingdayOperator = createItem(tr3crimpingday_db, 'operator');
    const tr3crimpingdayQuality = createItem(tr3crimpingday_db, 'qualityworker');
    const tr3crimpingdayNotice = createItem(tr3crimpingday_db, 'notice');
    const tr3crimpingnightModel = createItem(tr3crimpingnight_db, 'model');
    const tr3crimpingnightQty = createItem(tr3crimpingnight_db, 'planquantity');
    const tr3crimpingnightWo = createItem(tr3crimpingnight_db, 'workorder');
    const tr3crimpingnightLeader = createItem(tr3crimpingnight_db, 'leader');
    const tr3crimpingnightTechnician = createItem(tr3crimpingnight_db, 'technician');
    const tr3crimpingnightOperator = createItem(tr3crimpingnight_db, 'operator');
    const tr3crimpingnightQuality = createItem(tr3crimpingnight_db, 'qualityworker');
    const tr3crimpingnightNotice = createItem(tr3crimpingnight_db, 'notice');

    const tr3fpcadayModel = createItem(tr3fpcaday_db, 'model');
    const tr3fpcadayQty = createItem(tr3fpcaday_db, 'planquantity');
    const tr3fpcadayWo = createItem(tr3fpcaday_db, 'workorder');
    const tr3fpcadayLeader = createItem(tr3fpcaday_db, 'leader');
    const tr3fpcadayTechnician = createItem(tr3fpcaday_db, 'technician');
    const tr3fpcadayOperator = createItem(tr3fpcaday_db, 'operator');
    const tr3fpcadayQuality = createItem(tr3fpcaday_db, 'qualityworker');
    const tr3fpcadayNotice = createItem(tr3fpcaday_db, 'notice');
    const tr3fpcanightModel = createItem(tr3fpcanight_db, 'model');
    const tr3fpcanightQty = createItem(tr3fpcanight_db, 'planquantity');
    const tr3fpcanightWo = createItem(tr3fpcanight_db, 'workorder');
    const tr3fpcanightLeader = createItem(tr3fpcanight_db, 'leader');
    const tr3fpcanightTechnician = createItem(tr3fpcanight_db, 'technician');
    const tr3fpcanightOperator = createItem(tr3fpcanight_db, 'operator');
    const tr3fpcanightQuality = createItem(tr3fpcanight_db, 'qualityworker');
    const tr3fpcanightNotice = createItem(tr3fpcanight_db, 'notice');

    const tr3bfa2pdayModel = createItem(tr3bfa2pday_db, 'model');
    const tr3bfa2pdayQty = createItem(tr3bfa2pday_db, 'planquantity');
    const tr3bfa2pdayWo = createItem(tr3bfa2pday_db, 'workorder');
    const tr3bfa2pdayLeader = createItem(tr3bfa2pday_db, 'leader');
    const tr3bfa2pdayTechnician = createItem(tr3bfa2pday_db, 'technician');
    const tr3bfa2pdayOperator = createItem(tr3bfa2pday_db, 'operator');
    const tr3bfa2pdayQuality = createItem(tr3bfa2pday_db, 'qualityworker');
    const tr3bfa2pdayNotice = createItem(tr3bfa2pday_db, 'notice');
    const tr3bfa2pnightModel = createItem(tr3bfa2pnight_db, 'model');
    const tr3bfa2pnightQty = createItem(tr3bfa2pnight_db, 'planquantity');
    const tr3bfa2pnightWo = createItem(tr3bfa2pnight_db, 'workorder');
    const tr3bfa2pnightLeader = createItem(tr3bfa2pnight_db, 'leader');
    const tr3bfa2pnightTechnician = createItem(tr3bfa2pnight_db, 'technician');
    const tr3bfa2pnightOperator = createItem(tr3bfa2pnight_db, 'operator');
    const tr3bfa2pnightQuality = createItem(tr3bfa2pnight_db, 'qualityworker');
    const tr3bfa2pnightNotice = createItem(tr3bfa2pnight_db, 'notice');
    
    const tr3bfa3pdayModel = createItem(tr3bfa3pday_db, 'model');
    const tr3bfa3pdayQty = createItem(tr3bfa3pday_db, 'planquantity');
    const tr3bfa3pdayWo = createItem(tr3bfa3pday_db, 'workorder');
    const tr3bfa3pdayLeader = createItem(tr3bfa3pday_db, 'leader');
    const tr3bfa3pdayTechnician = createItem(tr3bfa3pday_db, 'technician');
    const tr3bfa3pdayOperator = createItem(tr3bfa3pday_db, 'operator');
    const tr3bfa3pdayQuality = createItem(tr3bfa3pday_db, 'qualityworker');
    const tr3bfa3pdayNotice = createItem(tr3bfa3pday_db, 'notice');
    const tr3bfa3pnightModel = createItem(tr3bfa3pnight_db, 'model');
    const tr3bfa3pnightQty = createItem(tr3bfa3pnight_db, 'planquantity');
    const tr3bfa3pnightWo = createItem(tr3bfa3pnight_db, 'workorder');
    const tr3bfa3pnightLeader = createItem(tr3bfa3pnight_db, 'leader');
    const tr3bfa3pnightTechnician = createItem(tr3bfa3pnight_db, 'technician');
    const tr3bfa3pnightOperator = createItem(tr3bfa3pnight_db, 'operator');
    const tr3bfa3pnightQuality = createItem(tr3bfa3pnight_db, 'qualityworker');
    const tr3bfa3pnightNotice = createItem(tr3bfa3pnight_db, 'notice');

    const evodayModel = createItem(evoday_db, 'model');
    const evodayQty = createItem(evoday_db, 'planquantity');
    const evodayWo = createItem(evoday_db, 'workorder');
    const evodayLeader = createItem(evoday_db, 'leader');
    const evodayTechnician = createItem(evoday_db, 'technician');
    const evodayOperator = createItem(evoday_db, 'operator');
    const evodayQuality = createItem(evoday_db, 'qualityworker');
    const evodayNotice = createItem(evoday_db, 'notice');
    const evonightModel = createItem(evonight_db, 'model');
    const evonightQty = createItem(evonight_db, 'planquantity');
    const evonightWo = createItem(evonight_db, 'workorder');
    const evonightLeader = createItem(evonight_db, 'leader');
    const evonightTechnician = createItem(evonight_db, 'technician');
    const evonightOperator = createItem(evonight_db, 'operator');
    const evonightQuality = createItem(evonight_db, 'qualityworker');
    const evonightNotice = createItem(evonight_db, 'notice');

    const bending1dayModel = createItem(bending1day_db, 'model');
    const bending1dayQty = createItem(bending1day_db, 'planquantity');
    const bending1dayWo = createItem(bending1day_db, 'workorder');
    const bending1dayLeader = createItem(bending1day_db, 'leader');
    const bending1dayTechnician = createItem(bending1day_db, 'technician');
    const bending1dayOperator = createItem(bending1day_db, 'operator');
    const bending1dayQuality = createItem(bending1day_db, 'qualityworker');
    const bending1dayNotice = createItem(bending1day_db, 'notice');
    const bending1nightModel = createItem(bending1night_db, 'model');
    const bending1nightQty = createItem(bending1night_db, 'planquantity');
    const bending1nightWo = createItem(bending1night_db, 'workorder');
    const bending1nightLeader = createItem(bending1night_db, 'leader');
    const bending1nightTechnician = createItem(bending1night_db, 'technician');
    const bending1nightOperator = createItem(bending1night_db, 'operator');
    const bending1nightQuality = createItem(bending1night_db, 'qualityworker');
    const bending1nightNotice = createItem(bending1night_db, 'notice');

    const bending2dayModel = createItem(bending2day_db, 'model');
    const bending2dayQty = createItem(bending2day_db, 'planquantity');
    const bending2dayWo = createItem(bending2day_db, 'workorder');
    const bending2dayLeader = createItem(bending2day_db, 'leader');
    const bending2dayTechnician = createItem(bending2day_db, 'technician');
    const bending2dayOperator = createItem(bending2day_db, 'operator');
    const bending2dayQuality = createItem(bending2day_db, 'qualityworker');
    const bending2dayNotice = createItem(bending2day_db, 'notice');
    const bending2nightModel = createItem(bending2night_db, 'model');
    const bending2nightQty = createItem(bending2night_db, 'planquantity');
    const bending2nightWo = createItem(bending2night_db, 'workorder');
    const bending2nightLeader = createItem(bending2night_db, 'leader');
    const bending2nightTechnician = createItem(bending2night_db, 'technician');
    const bending2nightOperator = createItem(bending2night_db, 'operator');
    const bending2nightQuality = createItem(bending2night_db, 'qualityworker');
    const bending2nightNotice = createItem(bending2night_db, 'notice');

    const ev2020_4060frdayModel = createItem(ev2020_4060frday_db, 'model');
    const ev2020_4060frdayQty = createItem(ev2020_4060frday_db, 'planquantity');
    const ev2020_4060frdayWo = createItem(ev2020_4060frday_db, 'workorder');
    const ev2020_4060frdayLeader = createItem(ev2020_4060frday_db, 'leader');
    const ev2020_4060frdayTechnician = createItem(ev2020_4060frday_db, 'technician');
    const ev2020_4060frdayOperator = createItem(ev2020_4060frday_db, 'operator');
    const ev2020_4060frdayQuality = createItem(ev2020_4060frday_db, 'qualityworker');
    const ev2020_4060frdayNotice = createItem(ev2020_4060frday_db, 'notice');
    const ev2020_4060frnightModel = createItem(ev2020_4060frnight_db, 'model');
    const ev2020_4060frnightQty = createItem(ev2020_4060frnight_db, 'planquantity');
    const ev2020_4060frnightWo = createItem(ev2020_4060frnight_db, 'workorder');
    const ev2020_4060frnightLeader = createItem(ev2020_4060frnight_db, 'leader');
    const ev2020_4060frnightTechnician = createItem(ev2020_4060frnight_db, 'technician');
    const ev2020_4060frnightOperator = createItem(ev2020_4060frnight_db, 'operator');
    const ev2020_4060frnightQuality = createItem(ev2020_4060frnight_db, 'qualityworker');
    const ev2020_4060frnightNotice = createItem(ev2020_4060frnight_db, 'notice');
    
    const ev2020_4060rrdayModel = createItem(ev2020_4060rrday_db, 'model');
    const ev2020_4060rrdayQty = createItem(ev2020_4060rrday_db, 'planquantity');
    const ev2020_4060rrdayWo = createItem(ev2020_4060rrday_db, 'workorder');
    const ev2020_4060rrdayLeader = createItem(ev2020_4060rrday_db, 'leader');
    const ev2020_4060rrdayTechnician = createItem(ev2020_4060rrday_db, 'technician');
    const ev2020_4060rrdayOperator = createItem(ev2020_4060rrday_db, 'operator');
    const ev2020_4060rrdayQuality = createItem(ev2020_4060rrday_db, 'qualityworker');
    const ev2020_4060rrdayNotice = createItem(ev2020_4060rrday_db, 'notice');
    const ev2020_4060rrnightModel = createItem(ev2020_4060rrnight_db, 'model');
    const ev2020_4060rrnightQty = createItem(ev2020_4060rrnight_db, 'planquantity');
    const ev2020_4060rrnightWo = createItem(ev2020_4060rrnight_db, 'workorder');
    const ev2020_4060rrnightLeader = createItem(ev2020_4060rrnight_db, 'leader');
    const ev2020_4060rrnightTechnician = createItem(ev2020_4060rrnight_db, 'technician');
    const ev2020_4060rrnightOperator = createItem(ev2020_4060rrnight_db, 'operator');
    const ev2020_4060rrnightQuality = createItem(ev2020_4060rrnight_db, 'qualityworker');
    const ev2020_4060rrnightNotice = createItem(ev2020_4060rrnight_db, 'notice');

    const ev2020_60frdayModel = createItem(ev2020_60frday_db, 'model');
    const ev2020_60frdayQty = createItem(ev2020_60frday_db, 'planquantity');
    const ev2020_60frdayWo = createItem(ev2020_60frday_db, 'workorder');
    const ev2020_60frdayLeader = createItem(ev2020_60frday_db, 'leader');
    const ev2020_60frdayTechnician = createItem(ev2020_60frday_db, 'technician');
    const ev2020_60frdayOperator = createItem(ev2020_60frday_db, 'operator');
    const ev2020_60frdayQuality = createItem(ev2020_60frday_db, 'qualityworker');
    const ev2020_60frdayNotice = createItem(ev2020_60frday_db, 'notice');
    const ev2020_60frnightModel = createItem(ev2020_60frnight_db, 'model');
    const ev2020_60frnightQty = createItem(ev2020_60frnight_db, 'planquantity');
    const ev2020_60frnightWo = createItem(ev2020_60frnight_db, 'workorder');
    const ev2020_60frnightLeader = createItem(ev2020_60frnight_db, 'leader');
    const ev2020_60frnightTechnician = createItem(ev2020_60frnight_db, 'technician');
    const ev2020_60frnightOperator = createItem(ev2020_60frnight_db, 'operator');
    const ev2020_60frnightQuality = createItem(ev2020_60frnight_db, 'qualityworker');
    const ev2020_60frnightNotice = createItem(ev2020_60frnight_db, 'notice');

    const ev2020_60rrdayModel = createItem(ev2020_60rrday_db, 'model');
    const ev2020_60rrdayQty = createItem(ev2020_60rrday_db, 'planquantity');
    const ev2020_60rrdayWo = createItem(ev2020_60rrday_db, 'workorder');
    const ev2020_60rrdayLeader = createItem(ev2020_60rrday_db, 'leader');
    const ev2020_60rrdayTechnician = createItem(ev2020_60rrday_db, 'technician');
    const ev2020_60rrdayOperator = createItem(ev2020_60rrday_db, 'operator');
    const ev2020_60rrdayQuality = createItem(ev2020_60rrday_db, 'qualityworker');
    const ev2020_60rrdayNotice = createItem(ev2020_60rrday_db, 'notice');
    const ev2020_60rrnightModel = createItem(ev2020_60rrnight_db, 'model');
    const ev2020_60rrnightQty = createItem(ev2020_60rrnight_db, 'planquantity');
    const ev2020_60rrnightWo = createItem(ev2020_60rrnight_db, 'workorder');
    const ev2020_60rrnightLeader = createItem(ev2020_60rrnight_db, 'leader');
    const ev2020_60rrnightTechnician = createItem(ev2020_60rrnight_db, 'technician');
    const ev2020_60rrnightOperator = createItem(ev2020_60rrnight_db, 'operator');
    const ev2020_60rrnightQuality = createItem(ev2020_60rrnight_db, 'qualityworker');
    const ev2020_60rrnightNotice = createItem(ev2020_60rrnight_db, 'notice');

    const ev2020_packingdayModel = createItem(ev2020_packingday_db, 'model');
    const ev2020_packingdayQty = createItem(ev2020_packingday_db, 'planquantity');
    const ev2020_packingdayWo = createItem(ev2020_packingday_db, 'workorder');
    const ev2020_packingdayLeader = createItem(ev2020_packingday_db, 'leader');
    const ev2020_packingdayTechnician = createItem(ev2020_packingday_db, 'technician');
    const ev2020_packingdayOperator = createItem(ev2020_packingday_db, 'operator');
    const ev2020_packingdayQuality = createItem(ev2020_packingday_db, 'qualityworker');
    const ev2020_packingdayNotice = createItem(ev2020_packingday_db, 'notice');
    const ev2020_packingnightModel = createItem(ev2020_packingnight_db, 'model');
    const ev2020_packingnightQty = createItem(ev2020_packingnight_db, 'planquantity');
    const ev2020_packingnightWo = createItem(ev2020_packingnight_db, 'workorder');
    const ev2020_packingnightLeader = createItem(ev2020_packingnight_db, 'leader');
    const ev2020_packingnightTechnician = createItem(ev2020_packingnight_db, 'technician');
    const ev2020_packingnightOperator = createItem(ev2020_packingnight_db, 'operator');
    const ev2020_packingnightQuality = createItem(ev2020_packingnight_db, 'qualityworker');
    const ev2020_packingnightNotice = createItem(ev2020_packingnight_db, 'notice');

    const ev2020_ffcdayModel = createItem(ev2020_ffcday_db, 'model');
    const ev2020_ffcdayQty = createItem(ev2020_ffcday_db, 'planquantity');
    const ev2020_ffcdayWo = createItem(ev2020_ffcday_db, 'workorder');
    const ev2020_ffcdayLeader = createItem(ev2020_ffcday_db, 'leader');
    const ev2020_ffcdayTechnician = createItem(ev2020_ffcday_db, 'technician');
    const ev2020_ffcdayOperator = createItem(ev2020_ffcday_db, 'operator');
    const ev2020_ffcdayQuality = createItem(ev2020_ffcday_db, 'qualityworker');
    const ev2020_ffcdayNotice = createItem(ev2020_ffcday_db, 'notice');
    const ev2020_ffcnightModel = createItem(ev2020_ffcnight_db, 'model');
    const ev2020_ffcnightQty = createItem(ev2020_ffcnight_db, 'planquantity');
    const ev2020_ffcnightWo = createItem(ev2020_ffcnight_db, 'workorder');
    const ev2020_ffcnightLeader = createItem(ev2020_ffcnight_db, 'leader');
    const ev2020_ffcnightTechnician = createItem(ev2020_ffcnight_db, 'technician');
    const ev2020_ffcnightOperator = createItem(ev2020_ffcnight_db, 'operator');
    const ev2020_ffcnightQuality = createItem(ev2020_ffcnight_db, 'qualityworker');
    const ev2020_ffcnightNotice = createItem(ev2020_ffcnight_db, 'notice');

    const semicheck1stdayModel = createItem(semicheck1stday_db, 'model');
    const semicheck1stdayQty = createItem(semicheck1stday_db, 'planquantity');
    const semicheck1stdayWo = createItem(semicheck1stday_db, 'workorder');
    const semicheck1stdayLeader = createItem(semicheck1stday_db, 'leader');
    const semicheck1stdayTechnician = createItem(semicheck1stday_db, 'technician');
    const semicheck1stdayOperator = createItem(semicheck1stday_db, 'operator');
    const semicheck1stdayQuality = createItem(semicheck1stday_db, 'qualityworker');
    const semicheck1stdayNotice = createItem(semicheck1stday_db, 'notice');
    const semicheck1stnightModel = createItem(semicheck1stnight_db, 'model');
    const semicheck1stnightQty = createItem(semicheck1stnight_db, 'planquantity');
    const semicheck1stnightWo = createItem(semicheck1stnight_db, 'workorder');
    const semicheck1stnightLeader = createItem(semicheck1stnight_db, 'leader');
    const semicheck1stnightTechnician = createItem(semicheck1stnight_db, 'technician');
    const semicheck1stnightOperator = createItem(semicheck1stnight_db, 'operator');
    const semicheck1stnightQuality = createItem(semicheck1stnight_db, 'qualityworker');
    const semicheck1stnightNotice = createItem(semicheck1stnight_db, 'notice');

    const semicheck2nddayModel = createItem(semicheck2ndday_db, 'model');
    const semicheck2nddayQty = createItem(semicheck2ndday_db, 'planquantity');
    const semicheck2nddayWo = createItem(semicheck2ndday_db, 'workorder');
    const semicheck2nddayLeader = createItem(semicheck2ndday_db, 'leader');
    const semicheck2nddayTechnician = createItem(semicheck2ndday_db, 'technician');
    const semicheck2nddayOperator = createItem(semicheck2ndday_db, 'operator');
    const semicheck2nddayQuality = createItem(semicheck2ndday_db, 'qualityworker');
    const semicheck2nddayNotice = createItem(semicheck2ndday_db, 'notice');
    const semicheck2ndnightModel = createItem(semicheck2ndnight_db, 'model');
    const semicheck2ndnightQty = createItem(semicheck2ndnight_db, 'planquantity');
    const semicheck2ndnightWo = createItem(semicheck2ndnight_db, 'workorder');
    const semicheck2ndnightLeader = createItem(semicheck2ndnight_db, 'leader');
    const semicheck2ndnightTechnician = createItem(semicheck2ndnight_db, 'technician');
    const semicheck2ndnightOperator = createItem(semicheck2ndnight_db, 'operator');
    const semicheck2ndnightQuality = createItem(semicheck2ndnight_db, 'qualityworker');
    const semicheck2ndnightNotice = createItem(semicheck2ndnight_db, 'notice');

    const settingData = reactive({ data: [], });      

    const callSearchData = async () => {         
      const tableName = 'setting';
      const formData = { tableName: tableName };

      try {
          const res = await axios.get('/api/targetList', { params: formData });
          settingData.data = res.data;

          const planningSetting = settingData.data.find(s => Object.hasOwn(s, 'planning'));  
          const isPlanningEnabled = planningSetting && planningSetting.planning === 1;
          if (!isPlanningEnabled) {
              alert("Planning is currently disabled.");
              return;
          }

          searchData(dbTableName, 'BMW 48V', 'Day');   
          searchData(dbTableName, 'BMW 48V', 'Night');   
          searchData(dbTableName, 'BMW 12V', 'Day');   
          searchData(dbTableName, 'BMW 12V', 'Night');   
          searchData(dbTableName, 'FCA 1', 'Day');   
          searchData(dbTableName, 'FCA 1', 'Night');   
          searchData(dbTableName, 'FCA 2', 'Day');   
          searchData(dbTableName, 'FCA 2', 'Night');   
          searchData(dbTableName, 'FCA FLEX PO', 'Day');   
          searchData(dbTableName, 'FCA FLEX PO', 'Night');   
          searchData(dbTableName, 'FCA FLEX NE', 'Day');   
          searchData(dbTableName, 'FCA FLEX NE', 'Night');   
          searchData(dbTableName, 'FCA FLEX BC', 'Day');   
          searchData(dbTableName, 'FCA FLEX BC', 'Night');   
          searchData(dbTableName, 'FCA FLEX 45', 'Day');   
          searchData(dbTableName, 'FCA FLEX 45', 'Night');   
          searchData(dbTableName, 'FCA FLEX 67', 'Day');   
          searchData(dbTableName, 'FCA FLEX 67', 'Night');   
          searchData(dbTableName, 'BT6', 'Day');   
          searchData(dbTableName, 'BT6', 'Night');   
          searchData(dbTableName, 'Ford 1 Front', 'Day');   
          searchData(dbTableName, 'Ford 1 Front', 'Night');   
          searchData(dbTableName, 'Ford 1 Rear', 'Day');   
          searchData(dbTableName, 'Ford 1 Rear', 'Night');   
          searchData(dbTableName, 'Ford 2 Front', 'Day');   
          searchData(dbTableName, 'Ford 2 Front', 'Night');   
          searchData(dbTableName, 'Ford 2 Rear', 'Day');   
          searchData(dbTableName, 'Ford 2 Rear', 'Night');   
          searchData(dbTableName, 'Ford 3 Front', 'Day');   
          searchData(dbTableName, 'Ford 3 Front', 'Night');   
          searchData(dbTableName, 'Ford 3 Rear', 'Day');   
          searchData(dbTableName, 'Ford 3 Rear', 'Night');   
          searchData(dbTableName, 'TR2 SMT', 'Day');   
          searchData(dbTableName, 'TR2 SMT', 'Night');   
          searchData(dbTableName, 'TR2 Crimping', 'Day');   
          searchData(dbTableName, 'TR2 Crimping', 'Night');   
          searchData(dbTableName, 'TR2 FPCA', 'Day');   
          searchData(dbTableName, 'TR2 FPCA', 'Night');   
          searchData(dbTableName, 'TR2 BFA 2P', 'Day');   
          searchData(dbTableName, 'TR2 BFA 2P', 'Night');   
          searchData(dbTableName, 'TR2 BFA 3P', 'Day');   
          searchData(dbTableName, 'TR2 BFA 3P', 'Night');   
          searchData(dbTableName, 'TR3 SMT', 'Day');   
          searchData(dbTableName, 'TR3 SMT', 'Night');   
          searchData(dbTableName, 'TR3 Crimping', 'Day');   
          searchData(dbTableName, 'TR3 Crimping', 'Night');   
          searchData(dbTableName, 'TR3 FPCA', 'Day');   
          searchData(dbTableName, 'TR3 FPCA', 'Night');   
          searchData(dbTableName, 'EVO 1', 'Day');   
          searchData(dbTableName, 'EVO 1', 'Night');   
          searchData(dbTableName, 'EVO 2', 'Day');   
          searchData(dbTableName, 'EVO 2', 'Night');   
          searchData(dbTableName, 'EVO Hybrid', 'Day');   
          searchData(dbTableName, 'EVO Hybrid', 'Night');  
          searchData(dbTableName, 'Bending 1', 'Day');
          searchData(dbTableName, 'Bending 1', 'Night');
          searchData(dbTableName, 'Bending 2', 'Day');
          searchData(dbTableName, 'Bending 2', 'Night');
          searchData(dbTableName, 'EVO Hybrid', 'Night');   
          searchData(dbTableName, 'EV2020 4060kW Front', 'Day');   
          searchData(dbTableName, 'EV2020 4060kW Front', 'Night');   
          searchData(dbTableName, 'EV2020 4060kW Rear', 'Day');   
          searchData(dbTableName, 'EV2020 4060kW Rear', 'Night');   
          searchData(dbTableName, 'EV2020 60kW Front', 'Day');   
          searchData(dbTableName, 'EV2020 60kW Front', 'Night');   
          searchData(dbTableName, 'EV2020 60kW Rear', 'Day');   
          searchData(dbTableName, 'EV2020 60kW Rear', 'Night');    
          searchData(dbTableName, 'EV2020 Packing', 'Day');   
          searchData(dbTableName, 'EV2020 Packing', 'Night');    
          searchData(dbTableName, 'EV2020 FFC', 'Day');   
          searchData(dbTableName, 'EV2020 FFC', 'Night');  
          searchData(dbTableName, '1st Semi Check', 'Day');   
          searchData(dbTableName, '1st Semi Check', 'Night');    
          searchData(dbTableName, '2nd Semi Check', 'Day');   
          searchData(dbTableName, '2nd Semi Check', 'Night');    
          searchDataForExcel(dbTableName);                          
      
      } catch (error) {
          console.error("Error attendance:", error.message);
      }
    };    
    
    onMounted(() => { callSearchData(); });

    const downloadExcel = () => {
      const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
      const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
      if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
      const wb = XLSX.utils.book_new();

      // Create worksheets for each table
      const wsPr = XLSX.utils.json_to_sheet(totalData.data, { header: headers.value });          

      // Append worksheets to the workbook with custom sheet names
      XLSX.utils.book_append_sheet(wb, wsPr, "Weekplan");          

      // Write the workbook and create a Blob
      const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      // Save the file
      saveAs(blob, "Weekplan.xlsx");
    };

    return {     
      getItemByDayAndLine, searchData, days, prevWeek, nextWeek, currentDateAndWeek, weekDates, showModal, selectedLine, closeModal, isModalVisible, editModal, callSearchData, searchDataForExcel, downloadExcel,

      bmw48vdayModel, bmw48vdayQty, bmw48vdayWo, bmw48vdayLeader, bmw48vdayTechnician, bmw48vdayOperator, bmw48vdayQuality, bmw48vdayNotice,
      bmw48vnightModel, bmw48vnightQty, bmw48vnightWo, bmw48vnightLeader, bmw48vnightTechnician, bmw48vnightOperator, bmw48vnightQuality, bmw48vnightNotice,
      bmw12vdayModel, bmw12vdayQty, bmw12vdayWo, bmw12vdayLeader, bmw12vdayTechnician, bmw12vdayOperator, bmw12vdayQuality, bmw12vdayNotice,
      bmw12vnightModel, bmw12vnightQty, bmw12vnightWo, bmw12vnightLeader, bmw12vnightTechnician, bmw12vnightOperator, bmw12vnightQuality, bmw12vnightNotice,
      fca1dayModel, fca1dayQty, fca1dayWo, fca1dayLeader, fca1dayTechnician, fca1dayOperator, fca1dayQuality, fca1dayNotice,
      fca1nightModel, fca1nightQty, fca1nightWo, fca1nightLeader, fca1nightTechnician, fca1nightOperator, fca1nightQuality, fca1nightNotice,
      fca2dayModel, fca2dayQty, fca2dayWo, fca2dayLeader, fca2dayTechnician, fca2dayOperator, fca2dayQuality, fca2dayNotice,
      fca2nightModel, fca2nightQty, fca2nightWo, fca2nightLeader, fca2nightTechnician, fca2nightOperator, fca2nightQuality, fca2nightNotice,
      fcaflexpodayModel, fcaflexpodayQty, fcaflexpodayWo, fcaflexpodayLeader, fcaflexpodayTechnician, fcaflexpodayOperator, fcaflexpodayQuality, fcaflexpodayNotice,
      fcaflexponightModel, fcaflexponightQty, fcaflexponightWo, fcaflexponightLeader, fcaflexponightTechnician, fcaflexponightOperator, fcaflexponightQuality, fcaflexponightNotice,
      fcaflexnedayModel, fcaflexnedayQty, fcaflexnedayWo, fcaflexnedayLeader, fcaflexnedayTechnician, fcaflexnedayOperator, fcaflexnedayQuality, fcaflexnedayNotice,
      fcaflexnenightModel, fcaflexnenightQty, fcaflexnenightWo, fcaflexnenightLeader, fcaflexnenightTechnician, fcaflexnenightOperator, fcaflexnenightQuality, fcaflexnenightNotice,
      fcaflexbcdayModel, fcaflexbcdayQty, fcaflexbcdayWo, fcaflexbcdayLeader, fcaflexbcdayTechnician, fcaflexbcdayOperator, fcaflexbcdayQuality, fcaflexbcdayNotice,
      fcaflexbcnightModel, fcaflexbcnightQty, fcaflexbcnightWo, fcaflexbcnightLeader, fcaflexbcnightTechnician, fcaflexbcnightOperator, fcaflexbcnightQuality, fcaflexbcnightNotice,
      fcaflex45dayModel, fcaflex45dayQty, fcaflex45dayWo, fcaflex45dayLeader, fcaflex45dayTechnician, fcaflex45dayOperator, fcaflex45dayQuality, fcaflex45dayNotice,
      fcaflex45nightModel, fcaflex45nightQty, fcaflex45nightWo, fcaflex45nightLeader, fcaflex45nightTechnician, fcaflex45nightOperator, fcaflex45nightQuality, fcaflex45nightNotice,
      fcaflex67dayModel, fcaflex67dayQty, fcaflex67dayWo, fcaflex67dayLeader, fcaflex67dayTechnician, fcaflex67dayOperator, fcaflex67dayQuality, fcaflex67dayNotice,
      fcaflex67nightModel, fcaflex67nightQty, fcaflex67nightWo, fcaflex67nightLeader, fcaflex67nightTechnician, fcaflex67nightOperator, fcaflex67nightQuality, fcaflex67nightNotice,
      bt6dayModel, bt6dayQty, bt6dayWo, bt6dayLeader, bt6dayTechnician, bt6dayOperator, bt6dayQuality, bt6dayNotice,
      bt6nightModel, bt6nightQty, bt6nightWo, bt6nightLeader, bt6nightTechnician, bt6nightOperator, bt6nightQuality, bt6nightNotice,
      ford1frdayModel, ford1frdayQty, ford1frdayWo, ford1frdayLeader, ford1frdayTechnician, ford1frdayOperator, ford1frdayQuality, ford1frdayNotice,
      ford1frnightModel, ford1frnightQty, ford1frnightWo, ford1frnightLeader, ford1frnightTechnician, ford1frnightOperator, ford1frnightQuality, ford1frnightNotice,
      ford1rrdayModel, ford1rrdayQty, ford1rrdayWo, ford1rrdayLeader, ford1rrdayTechnician, ford1rrdayOperator, ford1rrdayQuality, ford1rrdayNotice,
      ford1rrnightModel, ford1rrnightQty, ford1rrnightWo, ford1rrnightLeader, ford1rrnightTechnician, ford1rrnightOperator, ford1rrnightQuality, ford1rrnightNotice,
      ford2frdayModel, ford2frdayQty, ford2frdayWo, ford2frdayLeader, ford2frdayTechnician, ford2frdayOperator, ford2frdayQuality, ford2frdayNotice,
      ford2frnightModel, ford2frnightQty, ford2frnightWo, ford2frnightLeader, ford2frnightTechnician, ford2frnightOperator, ford2frnightQuality, ford2frnightNotice,
      ford2rrdayModel, ford2rrdayQty, ford2rrdayWo, ford2rrdayLeader, ford2rrdayTechnician, ford2rrdayOperator, ford2rrdayQuality, ford2rrdayNotice,
      ford2rrnightModel, ford2rrnightQty, ford2rrnightWo, ford2rrnightLeader, ford2rrnightTechnician, ford2rrnightOperator, ford2rrnightQuality, ford2rrnightNotice,
      ford3frdayModel, ford3frdayQty, ford3frdayWo, ford3frdayLeader, ford3frdayTechnician, ford3frdayOperator, ford3frdayQuality, ford3frdayNotice,
      ford3frnightModel, ford3frnightQty, ford3frnightWo, ford3frnightLeader, ford3frnightTechnician, ford3frnightOperator, ford3frnightQuality, ford3frnightNotice,
      ford3rrdayModel, ford3rrdayQty, ford3rrdayWo, ford3rrdayLeader, ford3rrdayTechnician, ford3rrdayOperator, ford3rrdayQuality, ford3rrdayNotice,
      ford3rrnightModel, ford3rrnightQty, ford3rrnightWo, ford3rrnightLeader, ford3rrnightTechnician, ford3rrnightOperator, ford3rrnightQuality, ford3rrnightNotice,
      tr2smtdayModel, tr2smtdayQty, tr2smtdayWo, tr2smtdayLeader, tr2smtdayTechnician, tr2smtdayOperator, tr2smtdayQuality, tr2smtdayNotice,
      tr2smtnightModel, tr2smtnightQty, tr2smtnightWo, tr2smtnightLeader, tr2smtnightTechnician, tr2smtnightOperator, tr2smtnightQuality, tr2smtnightNotice,
      tr2crimpingdayModel, tr2crimpingdayQty, tr2crimpingdayWo, tr2crimpingdayLeader, tr2crimpingdayTechnician, tr2crimpingdayOperator, tr2crimpingdayQuality, tr2crimpingdayNotice,
      tr2crimpingnightModel, tr2crimpingnightQty, tr2crimpingnightWo, tr2crimpingnightLeader, tr2crimpingnightTechnician, tr2crimpingnightOperator, tr2crimpingnightQuality, tr2crimpingnightNotice,
      tr2fpcadayModel, tr2fpcadayQty, tr2fpcadayWo, tr2fpcadayLeader, tr2fpcadayTechnician, tr2fpcadayOperator, tr2fpcadayQuality, tr2fpcadayNotice,
      tr2fpcanightModel, tr2fpcanightQty, tr2fpcanightWo, tr2fpcanightLeader, tr2fpcanightTechnician, tr2fpcanightOperator, tr2fpcanightQuality, tr2fpcanightNotice,      
      tr2bfa2pdayModel, tr2bfa2pdayQty, tr2bfa2pdayWo, tr2bfa2pdayLeader, tr2bfa2pdayTechnician, tr2bfa2pdayOperator, tr2bfa2pdayQuality, tr2bfa2pdayNotice,
      tr2bfa2pnightModel, tr2bfa2pnightQty, tr2bfa2pnightWo, tr2bfa2pnightLeader, tr2bfa2pnightTechnician, tr2bfa2pnightOperator, tr2bfa2pnightQuality, tr2bfa2pnightNotice,
      tr2bfa3pdayModel, tr2bfa3pdayQty, tr2bfa3pdayWo, tr2bfa3pdayLeader, tr2bfa3pdayTechnician, tr2bfa3pdayOperator, tr2bfa3pdayQuality, tr2bfa3pdayNotice,
      tr2bfa3pnightModel, tr2bfa3pnightQty, tr2bfa3pnightWo, tr2bfa3pnightLeader, tr2bfa3pnightTechnician, tr2bfa3pnightOperator, tr2bfa3pnightQuality, tr2bfa3pnightNotice,

      tr3smtdayModel, tr3smtdayQty, tr3smtdayWo, tr3smtdayLeader, tr3smtdayTechnician, tr3smtdayOperator, tr3smtdayQuality, tr3smtdayNotice,
      tr3smtnightModel, tr3smtnightQty, tr3smtnightWo, tr3smtnightLeader, tr3smtnightTechnician, tr3smtnightOperator, tr3smtnightQuality, tr3smtnightNotice,
      tr3crimpingdayModel, tr3crimpingdayQty, tr3crimpingdayWo, tr3crimpingdayLeader, tr3crimpingdayTechnician, tr3crimpingdayOperator, tr3crimpingdayQuality, tr3crimpingdayNotice,
      tr3crimpingnightModel, tr3crimpingnightQty, tr3crimpingnightWo, tr3crimpingnightLeader, tr3crimpingnightTechnician, tr3crimpingnightOperator, tr3crimpingnightQuality, tr3crimpingnightNotice,
      tr3fpcadayModel, tr3fpcadayQty, tr3fpcadayWo, tr3fpcadayLeader, tr3fpcadayTechnician, tr3fpcadayOperator, tr3fpcadayQuality, tr3fpcadayNotice,
      tr3fpcanightModel, tr3fpcanightQty, tr3fpcanightWo, tr3fpcanightLeader, tr3fpcanightTechnician, tr3fpcanightOperator, tr3fpcanightQuality, tr3fpcanightNotice,      
      tr3bfa2pdayModel, tr3bfa2pdayQty, tr3bfa2pdayWo, tr3bfa2pdayLeader, tr3bfa2pdayTechnician, tr3bfa2pdayOperator, tr3bfa2pdayQuality, tr3bfa2pdayNotice,
      tr3bfa2pnightModel, tr3bfa2pnightQty, tr3bfa2pnightWo, tr3bfa2pnightLeader, tr3bfa2pnightTechnician, tr3bfa2pnightOperator, tr3bfa2pnightQuality, tr3bfa2pnightNotice,
      tr3bfa3pdayModel, tr3bfa3pdayQty, tr3bfa3pdayWo, tr3bfa3pdayLeader, tr3bfa3pdayTechnician, tr3bfa3pdayOperator, tr3bfa3pdayQuality, tr3bfa3pdayNotice,
      tr3bfa3pnightModel, tr3bfa3pnightQty, tr3bfa3pnightWo, tr3bfa3pnightLeader, tr3bfa3pnightTechnician, tr3bfa3pnightOperator, tr3bfa3pnightQuality, tr3bfa3pnightNotice,
      evodayModel, evodayQty, evodayWo, evodayLeader, evodayTechnician, evodayOperator, evodayQuality, evodayNotice,
      evonightModel, evonightQty, evonightWo, evonightLeader, evonightTechnician, evonightOperator, evonightQuality, evonightNotice,

      bending1dayModel, bending1dayQty, bending1dayWo, bending1dayLeader, bending1dayTechnician, bending1dayOperator, bending1dayQuality, bending1dayNotice,
      bending1nightModel, bending1nightQty, bending1nightWo, bending1nightLeader, bending1nightTechnician, bending1nightOperator, bending1nightQuality, bending1nightNotice,
      bending2dayModel, bending2dayQty, bending2dayWo, bending2dayLeader, bending2dayTechnician, bending2dayOperator, bending2dayQuality, bending2dayNotice,
      bending2nightModel, bending2nightQty, bending2nightWo, bending2nightLeader, bending2nightTechnician, bending2nightOperator, bending2nightQuality, bending2nightNotice,

      ev2020_4060frdayModel, ev2020_4060frdayQty, ev2020_4060frdayWo, ev2020_4060frdayLeader, ev2020_4060frdayTechnician, ev2020_4060frdayOperator, ev2020_4060frdayQuality, ev2020_4060frdayNotice,
      ev2020_4060frnightModel, ev2020_4060frnightQty, ev2020_4060frnightWo, ev2020_4060frnightLeader, ev2020_4060frnightTechnician, ev2020_4060frnightOperator, ev2020_4060frnightQuality, ev2020_4060frnightNotice,
      ev2020_4060rrdayModel, ev2020_4060rrdayQty, ev2020_4060rrdayWo, ev2020_4060rrdayLeader, ev2020_4060rrdayTechnician, ev2020_4060rrdayOperator, ev2020_4060rrdayQuality, ev2020_4060rrdayNotice,
      ev2020_4060rrnightModel, ev2020_4060rrnightQty, ev2020_4060rrnightWo, ev2020_4060rrnightLeader, ev2020_4060rrnightTechnician, ev2020_4060rrnightOperator, ev2020_4060rrnightQuality, ev2020_4060rrnightNotice,
      ev2020_60frdayModel, ev2020_60frdayQty, ev2020_60frdayWo, ev2020_60frdayLeader, ev2020_60frdayTechnician, ev2020_60frdayOperator, ev2020_60frdayQuality, ev2020_60frdayNotice,
      ev2020_60frnightModel, ev2020_60frnightQty, ev2020_60frnightWo, ev2020_60frnightLeader, ev2020_60frnightTechnician, ev2020_60frnightOperator, ev2020_60frnightQuality, ev2020_60frnightNotice,
      ev2020_60rrdayModel, ev2020_60rrdayQty, ev2020_60rrdayWo, ev2020_60rrdayLeader, ev2020_60rrdayTechnician, ev2020_60rrdayOperator, ev2020_60rrdayQuality, ev2020_60rrdayNotice,
      ev2020_60rrnightModel, ev2020_60rrnightQty, ev2020_60rrnightWo, ev2020_60rrnightLeader, ev2020_60rrnightTechnician, ev2020_60rrnightOperator, ev2020_60rrnightQuality, ev2020_60rrnightNotice,

      ev2020_packingdayModel, ev2020_packingdayQty, ev2020_packingdayWo, ev2020_packingdayLeader, ev2020_packingdayTechnician, ev2020_packingdayOperator, ev2020_packingdayQuality, ev2020_packingdayNotice,
      ev2020_packingnightModel, ev2020_packingnightQty, ev2020_packingnightWo, ev2020_packingnightLeader, ev2020_packingnightTechnician, ev2020_packingnightOperator, ev2020_packingnightQuality, ev2020_packingnightNotice,
      ev2020_ffcdayModel, ev2020_ffcdayQty, ev2020_ffcdayWo, ev2020_ffcdayLeader, ev2020_ffcdayTechnician, ev2020_ffcdayOperator, ev2020_ffcdayQuality, ev2020_ffcdayNotice,
      ev2020_ffcnightModel, ev2020_ffcnightQty, ev2020_ffcnightWo, ev2020_ffcnightLeader, ev2020_ffcnightTechnician, ev2020_ffcnightOperator, ev2020_ffcnightQuality, ev2020_ffcnightNotice,

      semicheck1stdayModel, semicheck1stdayQty, semicheck1stdayWo, semicheck1stdayLeader, semicheck1stdayTechnician, semicheck1stdayOperator, semicheck1stdayQuality, semicheck1stdayNotice,
      semicheck1stnightModel, semicheck1stnightQty, semicheck1stnightWo, semicheck1stnightLeader, semicheck1stnightTechnician, semicheck1stnightOperator, semicheck1stnightQuality, semicheck1stnightNotice,

      semicheck2nddayModel, semicheck2nddayQty, semicheck2nddayWo, semicheck2nddayLeader, semicheck2nddayTechnician, semicheck2nddayOperator, semicheck2nddayQuality, semicheck2nddayNotice,
      semicheck2ndnightModel, semicheck2ndnightQty, semicheck2ndnightWo, semicheck2ndnightLeader, semicheck2ndnightTechnician, semicheck2ndnightOperator, semicheck2ndnightQuality, semicheck2ndnightNotice,

    };
  }
};

</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;  
  margin-left : 20px;
}
.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 0.1rem 0.5rem;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #0056b3;
}

button {
  background-color: #9cfca1;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 0.1rem 0.5rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #65af7b;
}

#week-number {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 1rem;
}

th {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}

td {
    background-color: rgb(255, 255, 255) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 10px;     
}
.tables {
  display: flex; flex-direction: column; align-items: stretch;
  padding-left: 20px; padding-right: 20px; height: 700px; /* Adjust this value according to your needs */
  overflow: auto;
} 
.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
} 

.day{
  background-color: rgb(250, 245, 171) ;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.night{
  background-color: rgb(181, 221, 240) ; 
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}
.daybase{
  background-color: rgb(250, 245, 171) ;
}

.nightbase{
  background-color: rgb(181, 221, 240) ;
}

.searchBar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px;
    margin-left: 20px;    
  }
.excelFile {
  display: flex;
  align-items: center;  
  justify-content: flex-end;
  width: 700px;
  padding: 0 10px;  
  margin-bottom: 0px;
}

.right-align {
  margin-left: auto;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #347003;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;  
  margin-right : 10px;
  margin-left : 10px;
}

.custom-file-upload:hover {
  background-color: #009625;
}

.listuploadbutton {
  padding: 5px 5px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;   
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;        
  transition: background-color 0.3s;        
}
.modalbutton{
  padding: 5px 5px;
  background-color: #b4b4b4;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;   
  font-weight: 600;
  margin-left: 0px;
  cursor: pointer;        
  transition: background-color 0.3s;        
}

.downLoadButton {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #347003;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;  
  margin-right : 10px;
  margin-left : 20px;
}
.downLoadButton:hover {
  background-color: #009625;
}

</style>