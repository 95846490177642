<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Annual Maintenance</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>
            <td>
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="formInputs.year"  />
                  <input type="text" id="month" v-model="formInputs.month" />
                  <input type="text" id="day" v-model="formInputs.day" />                  
                </div> 
            </div>  
            <div class="form-group">
                <label for="line">Line:</label>
                <select id="line" v-model="formInputs.line">
                  <option v-for="line in uniqueLines" :key="line">{{ line }}</option>   
                </select>              
              </div>  
              <div class="form-group">
              <label for="factory">Cycle:</label>
              <div>
                <input class="radio" type="radio" id="type" value="Monthly" v-model="formInputs.maincycle">
                <label for="monthly">Monthly</label>
                <input class="radio"  type="radio" id="type" value="Quartely" v-model="formInputs.maincycle">
                <label for="quartely">Quartetly</label>
                <input class="radio"  type="radio" id="type" value="Half yearly" v-model="formInputs.maincycle">
                <label for="half yearly">Half Yearly</label>
                <input class="radio"  type="radio" id="type" value="Yearly" v-model="formInputs.maincycle">
                <label for="yearly">Yearly</label>                
              </div>
            </div>               
            <div class="form-group">
              <label for="type">Plan/Actual:</label>
              <select type="text" id="type" v-model="formInputs.type">
                <option>Plan</option><option>Actual</option>
              </select>
            </div>
            <div class="form-group">
              <label for="technician">Technician Name:</label>
              <input id="technician" v-model="formInputs.technician" /> 
            </div>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="contents">Contents:</label>
              <textarea id="contents" v-model="formInputs.contents" rows="4" cols="25"></textarea>              
            </div> 
            <div class="form-group">
              <label for="plandate">Plan date:</label>
              <input id="plandate" v-model="formInputs.plandate" /> 
            </div>   
          </td>
            </tr>
            </table>       
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            <button class="DEL" type="submit" @click="deleteRow(dbTableName, formInputs.id)">DEL</button>            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';   
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "maintenanceannual"; 
        const uniqueLines = ref([]);  
        const saveStatus = ref(''); 
        const isProcessing = ref(false);                  
        const formInputs = reactive({id: "", year: "", month: "", day: "", line: "", maincycle: "", type: "", technician: "", contents: "", plandate: "" });

        const submitForm = async () => {  

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            line: 'line',
            maincycle: 'maincycle',            
            type: 'type',
            technician: 'technician',
            contents: 'contents',  
            plandate: 'plandate'          
          };

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: formInputs.year,
              month: formInputs.month,
              day: formInputs.day,
              line: formInputs.line,
              maincycle: formInputs.maincycle,
              type: formInputs.type,
              technician: formInputs.technician,             
              contents: formInputs.contents, 
              plandate: formInputs.plandate             
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData);      
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }            
              
         closeModal();
        };
        const closeModal = () => {
          emit("onClose");
        };  
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];
              
              formInputs.id =rowData.id,
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,
              formInputs.line = rowData.line,
              formInputs.maincycle = rowData.maincycle,
              formInputs.type = rowData.type,
              formInputs.technician = rowData.technician,
              formInputs.contents = rowData.contents,
              formInputs.plandate = rowData.plandate              

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

          const deleteRow = async (tableName, rowId) => {
            // Show a confirmation window and store the user's choice
            const userConfirmed = confirm("Are you sure you want to delete this row?");

            // If the user clicks "OK", proceed with the deletion
            if (userConfirmed) {
            try {
                await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });
                closeModal();
            } catch (error) {
                console.error("Error deleting row:", error);
            }
            }
        };  
        
        const fetchLine = () => {
          const tableName = 'machinedata';         
          console.log("fetchmaterialname called with materialcode:", formInputs.line);
          axios
            .get('/api/where2', { params: { table_name: tableName } })
            .then((res) => {
              console.log('Response from /api/targetList:', res.data);       

              // Set the fetched materialname to formInputs.materialname
              if (res.data && res.data.length > 0) {
                const lines = res.data.map(item => item.line);
                uniqueLines.value = [...new Set(lines)];  // 중복 값을 제거                
              } 
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };  
        fetchLine();
          

      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing, uniqueLines, deleteRow, };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   

.modal-wrapper { width: 100%;  max-width: 900px; }

.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}

.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

.modal-header h3 { font-size: 1.2rem; }

.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

label { font-weight: bold; }

input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

input[type="number"] { width: 100%;}  

.readWindow[readonly] { background-color: #f0f0f0; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s; font-weight: bold;
}

button[type="submit"]:hover { background-color: #45a049; }

table { table-layout: fixed; width: 100%; }

td { width: 20%; vertical-align: top; padding: 0 15px; }

.time-inputs { display: flex; gap: 5px; }
.time-inputs input[type="number"] { width: 50px; }
.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 50px; size: 8px; }

.radio { margin-left: 10px; margin-right: 5px; }
.DEL {background-color: #d7d7d7 !important; color: black !important;}
</style>