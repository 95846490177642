export  const columnMapping = {
    year: 'year', 
    month: 'month', 
    day: 'day', 
    line: 'line', 
    shift: 'shift',
    time: 'time', 
    model: 'model',
    process: 'process',
    itemvalues: 'itemvalues', 
    judgement: 'judgement',
    technician: 'technician', 
    remark: 'remark',    
};