<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Spare Modify</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>
            <td>     
            <div class="form-group">
              <label for="evpcode">EVP Code:</label>
              <div>
                <input type="text" id="evpcode" v-model="formInputs.evpcode" />
              </div>
            </div>  
            <div class="form-group">
              <label for="name">Item Name:</label>
              <div>
                <input type="text" id="name" v-model="formInputs.name" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="specification">Specification:</label>
              <div>
                <input type="text" id="specification" v-model="formInputs.specification" />                  
              </div>
            </div>  
            <div class="form-group">
              <label for="maker">Maker:</label>
              <div>
                <input type="text" id="maker" v-model="formInputs.maker" />
              </div>
            </div>  
            <div class="form-group">
              <label for="purchase">Purchase(on going):</label>
              <div>
                <select type="text" id="purchase" v-model="formInputs.purchase">   
                  <option>purchasing</option><option></option>
                </select>               
              </div>
            </div>    
            </td>
            <td>
            <div class="form-group">
              <label for="qty">Quantity:</label>
              <div>
                <input type="number" id="qty" v-model="formInputs.qty" />                  
              </div>
            </div>  
            <div class="form-group">
              <label for="limitqty">Limit Quantity:</label>
              <div>
                <input type="number" id="limitqty" v-model="formInputs.limitqty" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="factory">Factory:</label>
              <div>
                <input class="radio" type="radio" id="1st" value="1" v-model="formInputs.factory">
                <label for="1st">1st</label>
                <input class="radio"  type="radio" id="2nd" value="2" v-model="formInputs.factory">
                <label for="2nd">2nd</label>
              </div>
            </div>   
            <div class="form-group">
              <label for="rack">Rack Number:</label>
              <div>
                <input type="text" id="rack" v-model="formInputs.rack" />         
              </div>
            </div> 
            <div class="form-group">
              <label for="row">P (Row):</label>
              <div>
                <input type="text" id="row" v-model="formInputs.row_rack" />         
              </div>
            </div> 
            <div class="form-group">
              <label for="col">K (Col):</label>
              <div>
                <input type="text" id="col" v-model="formInputs.col" />         
              </div>
            </div>    
            </td>
            <td>
              <div class="form-group">
              <label for="line">Line:</label>
              <div>
                <select type="text" id="line" v-model="formInputs.line">
                  <option>SMT</option><option>Crimping</option><option>FPCA</option>
                  <option>EVO</option><option>EV2020</option>
                  <option>Ford</option><option>BMW 48V</option><option>BMW 12V</option>
                  <option></option>                  
                </select>
              </div>
            </div> 
            <div class="form-group">
              <label for="costscore">Cost Score (1~5):</label>
              <div>
                <input type="number" id="costscore" v-model="formInputs.costscore" />                  
              </div>
            </div>  
            <div class="form-group">
              <label for="deliveryscore">Delivery Score (1~5):</label>
              <div>
                <input type="number" id="deliveryscore" v-model="formInputs.deliveryscore" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="stopmachinescore">Stop Machine Score (1~5):</label>
              <div>
                <input type="number" id="stopmachinescore" v-model="formInputs.stopmachinescore" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="cost">Unit Cost(PLN):</label>
              <div>
                <input type="text" id="cost" v-model="formInputs.cost" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="code">Part Code(base on supplier):</label>
              <div>
                <input type="text" id="code" v-model="formInputs.code" />                  
              </div>
            </div>                          
            <div class="form-group">
              <label for="remark">Remark:</label>
              <div>
                <input type="text" id="remark" v-model="formInputs.remark" />
              </div>
            </div>    
            <div class="form-group">
              <label for="rowno">Row Number:</label>
              <div>
                <input type="number" id="rowno" v-model="formInputs.row_no" />
              </div>
            </div>              
          </td>
            </tr>
            </table> 
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}     
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';   
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "sparelist";     
        const saveStatus = ref(''); 
        const isProcessing = ref(false);                
        const formInputs = reactive({
          evpcode: "", name: "", specification: "", maker: "", qty: "", factory: "", rack: "", row_rack: "", col: "", line: "",
          limitqty: "", costscore: "", deliveryscore: "", stopmachinescore: "", cost: "", code: "", remark: "", purchase: "", row_no: ""
        });

        const submitForm = async () => {
          
          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {
            evpcode: 'evpcode',
            name: 'name',            
            specification: 'specification',
            maker: 'maker',
            qty: 'qty',
            factory: 'factory',
            rack: 'rack',
            row_rack: 'row_rack',
            col: 'col',
            line: 'line',
            limitqty: 'limitqty',
            costscore: 'costscore',
            deliveryscore: 'deliveryscore',
            stopmachinescore: 'stopmachinescore',
            cost: 'cost',
            code: 'code',            
            remark: 'remark', 
            purchase: 'purchase',
            row_no: 'row_no'
          };

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              evpcode: formInputs.evpcode,
              name: formInputs.name,            
              specification: formInputs.specification,
              maker: formInputs.maker,
              qty: formInputs.qty,
              factory: formInputs.factory,
              rack: formInputs.rack,
              row_rack: formInputs.row_rack,
              col: formInputs.col,
              line: formInputs.line,
              limitqty: formInputs.limitqty,
              costscore: formInputs.costscore,
              deliveryscore: formInputs.deliveryscore,
              stopmachinescore: formInputs.stopmachinescore,
              cost: formInputs.cost,
              code: formInputs.code,
              remark: formInputs.remark, 
              purchase: formInputs.purchase, 
              row_no: formInputs.row_no, 
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData);    
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }         
              
         closeModal();
        };
        const closeModal = () => {
          emit("onClose");
        };  
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id,
              formInputs.evpcode = rowData.evpcode,
              formInputs.name = rowData.name,            
              formInputs.specification = rowData.specification,
              formInputs.maker = rowData.maker,
              formInputs.qty = rowData.qty,
              formInputs.factory = rowData.factory,
              formInputs.rack = rowData.rack,
              formInputs.row_rack = rowData.row_rack,
              formInputs.col = rowData.col,
              formInputs.line = rowData.line,
              formInputs.limitqty = rowData.limitqty,
              formInputs.costscore = rowData.costscore,
              formInputs.deliveryscore = rowData.deliveryscore,
              formInputs.stopmachinescore = rowData.stopmachinescore,
              formInputs.cost = rowData.cost,
              formInputs.code = rowData.code,
              formInputs.remark = rowData.remark,
              formInputs.purchase = rowData.purchase   
              formInputs.row_no = rowData.row_no   

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   

.modal-wrapper { width: 100%;  max-width: 900px; }

.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}

.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

.modal-header h3 { font-size: 1.2rem; }

.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

label { font-weight: bold; }

input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

input[type="number"] { width: 100%;}  

.readWindow[readonly] { background-color: #f0f0f0; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s;
}

button[type="submit"]:hover { background-color: #45a049; }

table { table-layout: fixed; width: 100%; }

td { width: 20%; vertical-align: top; padding: 0 15px; }

.time-inputs { display: flex; gap: 5px; }
.time-inputs input[type="number"] { width: 50px; }
.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 50px; size: 8px; }

.ng-dropdown-container { position: relative; display: inline-block; }
.ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
.ng-dropdown-menu { 
  position: absolute; padding: 4px;
  left: 0; top: 100%; z-index: 10; 
  background-color: white; border: 1px solid #ccc;  
}
.ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
.ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

.ngmaintype{ max-width: 120px; min-width: 120px;}
.radio { margin-left: 10px; margin-right: 5px; }
</style>