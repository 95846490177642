<template>
  <div class="planning">    
    <div class="act">  
      <form class="searchBar">      
        <button class="nav-button" @click.prevent="fetchDataForPrevWeek">Previous week</button>
        <span id="week-number">{{ currentDateAndWeek }}</span>
        <button class="nav-button" @click.prevent="fetchDataForNextWeek">Next week</button>      
      </form>
    </div>
    <button class="modalbutton" @click="showModal = true">Plan and Result Write</button>    
    <label for="file-input" class="custom-file-upload">Choose file</label>                
    <input id="file-input" type="file" @change="onFileChange" accept=".xlsx, .xls" style="display: none" />
    <span class="fileName">{{ fileName }}</span>
    <button class="excelbutton" @click="uploadExcel('shippingallproject')">Excel Upload</button>
    <br>

    <div class="tables">      
      <table class="custom-table">
        <tr class="sticky-header">
          <th colspan="2">{{ currentDateAndWeek }}</th>           
          <th id="mon" colspan="2">MON&nbsp;{{ weekDates[0].toLocaleDateString() }}</th>
          <th id="tue" colspan="2">TUE&nbsp;{{ weekDates[1].toLocaleDateString() }}</th>
          <th id="wed" colspan="2">WED&nbsp;{{ weekDates[2].toLocaleDateString() }}</th>
          <th id="thu" colspan="2">THU&nbsp;{{ weekDates[3].toLocaleDateString() }}</th>
          <th id="fri" colspan="2">FRI&nbsp;{{ weekDates[4].toLocaleDateString() }}</th>
          <th id="sat" colspan="2">SAT&nbsp;{{ weekDates[5].toLocaleDateString() }}</th>
          <th id="sun" colspan="2">SUN&nbsp;{{ weekDates[6].toLocaleDateString() }}</th>
          <th id="summary" colspan="3">Summary</th>
        </tr>                
        <tr>
          <th colspan="2">Category</th><th>Plan</th><th>Result</th><th>Plan</th><th>Result</th><th>Plan</th><th>Result</th><th>Plan</th><th>Result</th><th>Plan</th><th>Result</th><th>Plan</th><th>Result</th><th>Plan</th><th>Result</th><th>Plan</th><th>Result</th><th>Balance</th>
        </tr>
        <tr> <!--Total -->
          <td class='total' colspan="2">TOTAL</td>
          <td class='total' :class="{ 'zero-value-total': totalPlans.mon.value === 0 }">{{ totalPlans.mon }}</td><td class='total' :class="{ 'zero-value-total': totalResults.mon.value === 0 }">{{ totalResults.mon }}</td>
          <td class='total' :class="{ 'zero-value-total': totalPlans.tue.value === 0 }">{{ totalPlans.tue }}</td><td class='total' :class="{ 'zero-value-total': totalResults.tue.value === 0 }">{{ totalResults.tue }}</td>
          <td class='total' :class="{ 'zero-value-total': totalPlans.wed.value === 0 }">{{ totalPlans.wed }}</td><td class='total' :class="{ 'zero-value-total': totalResults.wed.value === 0 }">{{ totalResults.wed }}</td>
          <td class='total' :class="{ 'zero-value-total': totalPlans.thu.value === 0 }">{{ totalPlans.thu }}</td><td class='total' :class="{ 'zero-value-total': totalResults.thu.value === 0 }">{{ totalResults.thu }}</td>
          <td class='total' :class="{ 'zero-value-total': totalPlans.fri.value === 0 }">{{ totalPlans.fri }}</td><td class='total' :class="{ 'zero-value-total': totalResults.fri.value === 0 }">{{ totalResults.fri }}</td>
          <td class='total' :class="{ 'zero-value-total': totalPlans.sat.value === 0 }">{{ totalPlans.sat }}</td><td class='total' :class="{ 'zero-value-total': totalResults.sat.value === 0 }">{{ totalResults.sat }}</td>
          <td class='total' :class="{ 'zero-value-total': totalPlans.sun.value === 0 }">{{ totalPlans.sun }}</td><td class='total' :class="{ 'zero-value-total': totalResults.sun.value === 0 }">{{ totalResults.sun }}</td>
          <td class='total' :class="{ 'zero-value-total': totalWeekPlanSum === 0 }">{{ totalWeekPlanSum }}</td>
          <td class='total' :class="{ 'zero-value-total': totalWeekResultSum === 0 }">{{ totalWeekResultSum }}</td>
          <td class='total' :class="{ 'zero-value-total': (totalWeekResultSum - totalWeekPlanSum) === 0, 'total-red': (totalWeekResultSum - totalWeekPlanSum ) < 0 }" >{{totalWeekResultSum - totalWeekPlanSum}}</td>
        </tr>

        <tr> <!--BMW -->
          <td class='project' rowspan="5">BMW</td>
          <td class='subTotal'>Sub Total</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwPlans.mon.value === 0 }">{{bmwPlans.mon}}</td><td class='subTotal' :class="{ 'zero-value-sub': bmwResults.mon.value === 0 }">{{bmwResults.mon}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwPlans.tue.value === 0 }">{{bmwPlans.tue}}</td><td class='subTotal' :class="{ 'zero-value-sub': bmwResults.tue.value === 0 }">{{bmwResults.tue}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwPlans.wed.value === 0 }">{{bmwPlans.wed}}</td><td class='subTotal' :class="{ 'zero-value-sub': bmwResults.wed.value === 0 }">{{bmwResults.wed}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwPlans.thu.value === 0 }">{{bmwPlans.thu}}</td><td class='subTotal' :class="{ 'zero-value-sub': bmwResults.thu.value === 0 }">{{bmwResults.thu}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwPlans.fri.value === 0 }">{{bmwPlans.fri}}</td><td class='subTotal' :class="{ 'zero-value-sub': bmwResults.fri.value === 0 }">{{bmwResults.fri}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwPlans.sat.value === 0 }">{{bmwPlans.sat}}</td><td class='subTotal' :class="{ 'zero-value-sub': bmwResults.sat.value === 0 }">{{bmwResults.sat}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwPlans.sun.value === 0 }">{{bmwPlans.sun}}</td><td class='subTotal' :class="{ 'zero-value-sub': bmwResults.sat.value === 0 }">{{bmwResults.sun}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwWeekPlanSum === 0 }">{{bmwWeekPlanSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': bmwWeekResultSum === 0 }">{{bmwWeekResultSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': (bmwWeekResultSum - bmwWeekPlanSum) === 0, 'subTotal-red': (bmwWeekResultSum - bmwWeekPlanSum ) < 0 }" >{{bmwWeekResultSum - bmwWeekPlanSum}}</td>
        </tr>
        <tr>          
          <td>BMW 12V F40 12V</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, bmw12vPlanf40[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw12vPlanf40[day].value === 0 }">{{ bmw12vPlanf40[day].value }}</td>
            <td @click="editCellValue(dbTableName, bmw12vResultf40[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw12vResultf40[day].value === 0 }">{{ bmw12vResultf40[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': bmw12vf40WeekPlan === 0 }">{{bmw12vf40WeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': bmw12vf40WeekResult === 0 }">{{bmw12vf40WeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (bmw12vf40WeekResult - bmw12vf40WeekPlan) === 0, 'redColor': (bmw12vf40WeekResult - bmw12vf40WeekPlan) < 0 }">{{bmw12vf40WeekResult - bmw12vf40WeekPlan}}</td>
        </tr>
        <tr>
          <td>BMW 12V HAF</td>
          <template v-for="day in days" :key="day" >            
            <td @click="editCellValue(dbTableName, bmw12vPlanhaf[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw12vPlanhaf[day].value === 0 }">{{ bmw12vPlanhaf[day].value }}</td>
            <td @click="editCellValue(dbTableName, bmw12vResulthaf[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw12vResulthaf[day].value === 0 }">{{ bmw12vResulthaf[day].value }}</td>
          </template> 
          <td class='sum' :class="{ 'zero-value-sum': bmw12vhafWeekPlan === 0 }">{{bmw12vhafWeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': bmw12vhafWeekResult === 0 }">{{bmw12vhafWeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (bmw12vhafWeekResult - bmw12vhafWeekPlan) === 0, 'redColor': (bmw12vhafWeekResult - bmw12vhafWeekPlan) < 0 }">{{bmw12vhafWeekResult - bmw12vhafWeekPlan}}</td>
        </tr>
        <tr>
          <td>BMW 48V GEN1.5 AI</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, bmw48vPlanAi[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw48vPlanAi[day].value === 0 }">{{ bmw48vPlanAi[day].value }}</td>
            <td @click="editCellValue(dbTableName, bmw48vResultAi[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw48vResultAi[day].value === 0 }">{{ bmw48vResultAi[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': bmw48vAiWeekPlan === 0 }">{{bmw48vAiWeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': bmw48vAiWeekResult === 0 }">{{bmw48vAiWeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (bmw48vAiWeekResult - bmw48vAiWeekPlan) === 0, 'redColor': (bmw48vAiWeekResult - bmw48vAiWeekPlan) < 0 }">{{bmw48vAiWeekResult - bmw48vAiWeekPlan}}</td>
        </tr>
        <tr>          
          <td>BMW 48V GEN1.5 AJ</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, bmw48vPlanAj[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw48vPlanAj[day].value === 0 }">{{ bmw48vPlanAj[day].value }}</td>
            <td @click="editCellValue(dbTableName, bmw48vResultAj[day].id, 'quantity', $event)" :class="{ 'zero-value': bmw48vResultAj[day].value === 0 }">{{ bmw48vResultAj[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': bmw48vAjWeekPlan === 0 }">{{bmw48vAjWeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': bmw48vAjWeekResult === 0 }">{{bmw48vAjWeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (bmw48vAjWeekResult - bmw48vAjWeekPlan) === 0, 'redColor': (bmw48vAjWeekResult - bmw48vAjWeekPlan) < 0 }">{{bmw48vAjWeekResult - bmw48vAjWeekPlan}}</td>   
        </tr>

        <tr> <!--FCA -->
          <td class='project' rowspan="9">FCA</td>
          <td class='subTotal'>Sub Total</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaPlans.mon.value === 0 }">{{fcaPlans.mon}}</td><td class='subTotal' :class="{ 'zero-value-sub': fcaResults.mon.value === 0 }">{{fcaResults.mon}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaPlans.tue.value === 0 }">{{fcaPlans.tue}}</td><td class='subTotal' :class="{ 'zero-value-sub': fcaResults.tue.value === 0 }">{{fcaResults.tue}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaPlans.wed.value === 0 }">{{fcaPlans.wed}}</td><td class='subTotal' :class="{ 'zero-value-sub': fcaResults.wed.value === 0 }">{{fcaResults.wed}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaPlans.thu.value === 0 }">{{fcaPlans.thu}}</td><td class='subTotal' :class="{ 'zero-value-sub': fcaResults.thu.value === 0 }">{{fcaResults.thu}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaPlans.fri.value === 0 }">{{fcaPlans.fri}}</td><td class='subTotal' :class="{ 'zero-value-sub': fcaResults.fri.value === 0 }">{{fcaResults.fri}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaPlans.sat.value === 0 }">{{fcaPlans.sat}}</td><td class='subTotal' :class="{ 'zero-value-sub': fcaResults.sat.value === 0 }">{{fcaResults.sat}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaPlans.sun.value === 0 }">{{fcaPlans.sun}}</td><td class='subTotal' :class="{ 'zero-value-sub': fcaResults.sat.value === 0 }">{{fcaResults.sun}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaWeekPlanSum === 0 }">{{fcaWeekPlanSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fcaWeekResultSum === 0 }">{{fcaWeekResultSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': (fcaWeekResultSum - fcaWeekPlanSum) === 0, 'subTotal-red': (fcaWeekResultSum - fcaWeekPlanSum ) < 0 }" >{{fcaWeekResultSum - fcaWeekPlanSum}}</td>
        </tr>
        <tr>          
          <td>FCA Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlanFront[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlanFront[day].value === 0 }">{{ fcaPlanFront[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResultFront[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResultFront[day].value === 0 }">{{ fcaResultFront[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fcaFrontWeekPlan === 0 }">{{fcaFrontWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fcaFrontWeekResult === 0 }">{{fcaFrontWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fcaFrontWeekResult - fcaFrontWeekPlan) === 0, 'redColor': (fcaFrontWeekResult - fcaFrontWeekPlan) < 0 }">{{fcaFrontWeekResult - fcaFrontWeekPlan}}</td>
        </tr>
        <tr>          
          <td>FCA Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlanRear[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlanRear[day].value === 0 }">{{ fcaPlanRear[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResultRear[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResultRear[day].value === 0 }">{{ fcaResultRear[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fcaRearWeekPlan === 0 }">{{fcaRearWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fcaRearWeekResult === 0 }">{{fcaRearWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fcaRearWeekResult - fcaRearWeekPlan) === 0, 'redColor': (fcaRearWeekResult - fcaRearWeekPlan) < 0 }">{{fcaRearWeekResult - fcaRearWeekPlan}}</td>
        </tr>
        <tr>
          <td>FCA Flexi POS</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlanPos[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlanPos[day].value === 0 }">{{ fcaPlanPos[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResultPos[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResultPos[day].value === 0 }">{{ fcaResultPos[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': fcaPosWeekPlan === 0 }">{{fcaPosWeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': fcaPosWeekResult === 0 }">{{fcaPosWeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (fcaPosWeekResult - fcaPosWeekPlan) === 0, 'redColor': (fcaPosWeekResult - fcaPosWeekPlan) < 0 }">{{fcaPosWeekResult - fcaPosWeekPlan}}</td>
        </tr>
        <tr>
          <td>FCA Flexi NEG</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlanNeg[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlanNeg[day].value === 0 }">{{ fcaPlanNeg[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResultNeg[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResultNeg[day].value === 0 }">{{ fcaResultNeg[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': fcaNegWeekPlan === 0 }">{{fcaNegWeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': fcaNegWeekResult === 0 }">{{fcaNegWeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (fcaNegWeekResult - fcaNegWeekPlan) === 0, 'redColor': (fcaNegWeekResult - fcaNegWeekPlan) < 0 }">{{fcaNegWeekResult - fcaNegWeekPlan}}</td>
        </tr>  
        <tr>
          <td>FCA Flexi 4-5+</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlan45[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlan45[day].value === 0 }">{{ fcaPlan45[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResult45[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResult45[day].value === 0 }">{{ fcaResult45[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': fca45WeekPlan === 0 }">{{fca45WeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': fca45WeekResult === 0 }">{{fca45WeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (fca45WeekResult - fca45WeekPlan) === 0, 'redColor': (fca45WeekResult - fca45WeekPlan) < 0 }">{{fca45WeekResult - fca45WeekPlan}}</td>
        </tr>   
        <tr>
          <td>FCA Flexi 6+7-</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlan67[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlan67[day].value === 0 }">{{ fcaPlan67[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResult67[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResult67[day].value === 0 }">{{ fcaResult67[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': fca67WeekPlan === 0 }">{{fca67WeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': fca67WeekResult === 0 }">{{fca67WeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (fca67WeekResult - fca67WeekPlan) === 0, 'redColor': (fca67WeekResult - fca67WeekPlan) < 0 }">{{fca67WeekResult - fca67WeekPlan}}</td>
        </tr>   
        <tr>          
          <td>FCA Flexi BSBR$CARR</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlanBc[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlanBc[day].value === 0 }">{{ fcaPlanBc[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResultBc[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResultBc[day].value === 0 }">{{ fcaResultBc[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': fcaBcWeekPlan === 0 }">{{fcaBcWeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': fcaBcWeekResult === 0 }">{{fcaBcWeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (fcaBcWeekResult - fcaBcWeekPlan) === 0, 'redColor': (fcaBcWeekResult - fcaBcWeekPlan) < 0 }">{{fcaBcWeekResult - fcaBcWeekPlan}}</td>
        </tr>   
        <tr>          
          <td>FCA Flexi BSBR_A</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fcaPlanBa[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaPlanBa[day].value === 0 }">{{ fcaPlanBa[day].value }}</td>
            <td @click="editCellValue(dbTableName, fcaResultBa[day].id, 'quantity', $event)" :class="{ 'zero-value': fcaResultBa[day].value === 0 }">{{ fcaResultBa[day].value }}</td>
          </template>  
          <td class='sum' :class="{ 'zero-value-sum': fcaBaWeekPlan === 0 }">{{fcaBaWeekPlan}}</td>
          <td class='sum' :class="{ 'zero-value-sum': fcaBaWeekResult === 0 }">{{fcaBaWeekResult}}</td>
          <td class='sum' :class="{ 'zero-value-sum': (fcaBaWeekResult - fcaBaWeekPlan) === 0, 'redColor': (fcaBaWeekResult - fcaBaWeekPlan) < 0 }">{{fcaBaWeekResult - fcaBaWeekPlan}}</td>
        </tr>    
        
        <tr> <!--Ford -->
          <td class='project' rowspan="9">Ford</td>
          <td class='subTotal'>Sub Total</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordPlans.mon.value === 0 }">{{fordPlans.mon}}</td><td class='subTotal' :class="{ 'zero-value-sub': fordResults.mon.value === 0 }">{{fordResults.mon}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordPlans.tue.value === 0 }">{{fordPlans.tue}}</td><td class='subTotal' :class="{ 'zero-value-sub': fordResults.tue.value === 0 }">{{fordResults.tue}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordPlans.wed.value === 0 }">{{fordPlans.wed}}</td><td class='subTotal' :class="{ 'zero-value-sub': fordResults.wed.value === 0 }">{{fordResults.wed}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordPlans.thu.value === 0 }">{{fordPlans.thu}}</td><td class='subTotal' :class="{ 'zero-value-sub': fordResults.thu.value === 0 }">{{fordResults.thu}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordPlans.fri.value === 0 }">{{fordPlans.fri}}</td><td class='subTotal' :class="{ 'zero-value-sub': fordResults.fri.value === 0 }">{{fordResults.fri}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordPlans.sat.value === 0 }">{{fordPlans.sat}}</td><td class='subTotal' :class="{ 'zero-value-sub': fordResults.sat.value === 0 }">{{fordResults.sat}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordPlans.sun.value === 0 }">{{fordPlans.sun}}</td><td class='subTotal' :class="{ 'zero-value-sub': fordResults.sat.value === 0 }">{{fordResults.sun}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordWeekPlanSum === 0 }">{{fordWeekPlanSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': fordWeekResultSum === 0 }">{{fordWeekResultSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': (fordWeekResultSum - fordWeekPlanSum) === 0, 'subTotal-red': (fordWeekResultSum - fordWeekPlanSum ) < 0 }" >{{fordWeekResultSum -fordWeekPlanSum}}</td>
        </tr>
        <tr>          
          <td>Ford 4P8S Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanFr4p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanFr4p8s[day].value === 0 }">{{ fordPlanFr4p8s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultFr4p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultFr4p8s[day].value === 0 }">{{ fordResultFr4p8s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordFr4p8sWeekPlan === 0 }">{{fordFr4p8sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordFr4p8sWeekResult === 0 }">{{fordFr4p8sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordFr4p8sWeekResult - fordFr4p8sWeekPlan) === 0, 'redColor': (fordFr4p8sWeekResult - fordFr4p8sWeekPlan) < 0 }">{{fordFr4p8sWeekResult - fordFr4p8sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>Ford 4P8S Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanRr4p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanRr4p8s[day].value === 0 }">{{ fordPlanRr4p8s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultRr4p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultRr4p8s[day].value === 0 }">{{ fordResultRr4p8s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordRr4p8sWeekPlan === 0 }">{{fordRr4p8sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordRr4p8sWeekResult === 0 }">{{fordRr4p8sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordRr4p8sWeekResult - fordRr4p8sWeekPlan) === 0, 'redColor': (fordRr4p8sWeekResult - fordRr4p8sWeekPlan) < 0 }">{{fordRr4p8sWeekResult - fordRr4p8sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>Ford 4P7S Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanFr4p7s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanFr4p7s[day].value === 0 }">{{ fordPlanFr4p7s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultFr4p7s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultFr4p7s[day].value === 0 }">{{ fordResultFr4p7s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordFr4p7sWeekPlan === 0 }">{{fordFr4p7sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordFr4p7sWeekResult === 0 }">{{fordFr4p7sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordFr4p7sWeekResult - fordFr4p7sWeekPlan) === 0, 'redColor': (fordFr4p7sWeekResult - fordFr4p7sWeekPlan) < 0 }">{{fordFr4p7sWeekResult - fordFr4p7sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>Ford 4P7S Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanRr4p7s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanRr4p7s[day].value === 0 }">{{ fordPlanRr4p7s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultRr4p7s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultRr4p7s[day].value === 0 }">{{ fordResultRr4p7s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordRr4p7sWeekPlan === 0 }">{{fordRr4p7sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordRr4p7sWeekResult === 0 }">{{fordRr4p7sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordRr4p7sWeekResult - fordRr4p7sWeekPlan) === 0, 'redColor': (fordRr4p7sWeekResult - fordRr4p7sWeekPlan) < 0 }">{{fordRr4p7sWeekResult - fordRr4p7sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>Ford 3P10S Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanFr3p10s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanFr3p10s[day].value === 0 }">{{ fordPlanFr3p10s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultFr3p10s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultFr3p10s[day].value === 0 }">{{ fordResultFr3p10s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordFr3p10sWeekPlan === 0 }">{{fordFr3p10sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordFr3p10sWeekResult === 0 }">{{fordFr3p10sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordFr3p10sWeekResult - fordFr3p10sWeekPlan) === 0, 'redColor': (fordFr3p10sWeekResult - fordFr3p10sWeekPlan) < 0 }">{{fordFr3p10sWeekResult - fordFr3p10sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>Ford 3P10S Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanRr3p10s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanRr3p10s[day].value === 0 }">{{ fordPlanRr3p10s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultRr3p10s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultRr3p10s[day].value === 0 }">{{ fordResultRr3p10s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordRr3p10sWeekPlan === 0 }">{{fordRr3p10sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordRr3p10sWeekResult === 0 }">{{fordRr3p10sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordRr3p10sWeekResult - fordRr3p10sWeekPlan) === 0, 'redColor': (fordRr3p10sWeekResult - fordRr3p10sWeekPlan) < 0 }">{{fordRr3p10sWeekResult - fordRr3p10sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>Ford 3P8S Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanFr3p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanFr3p8s[day].value === 0 }">{{ fordPlanFr3p8s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultFr3p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultFr3p8s[day].value === 0 }">{{ fordResultFr3p8s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordFr3p8sWeekPlan === 0 }">{{fordFr3p8sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordFr3p8sWeekResult === 0 }">{{fordFr3p8sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordFr3p8sWeekResult - fordFr3p8sWeekPlan) === 0, 'redColor': (fordFr3p8sWeekResult - fordFr3p8sWeekPlan) < 0 }">{{fordFr3p8sWeekResult - fordFr3p8sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>Ford 3P8S Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, fordPlanRr3p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordPlanRr3p8s[day].value === 0 }">{{ fordPlanRr3p8s[day].value }}</td>
            <td @click="editCellValue(dbTableName, fordResultRr3p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': fordResultRr3p8s[day].value === 0 }">{{ fordResultRr3p8s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': fordRr3p8sWeekPlan === 0 }">{{fordRr3p8sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': fordRr3p8sWeekResult === 0 }">{{fordRr3p8sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (fordRr3p8sWeekResult - fordRr3p8sWeekPlan) === 0, 'redColor': (fordRr3p8sWeekResult - fordRr3p8sWeekPlan) < 0 }">{{fordRr3p8sWeekResult - fordRr3p8sWeekPlan}}</td>
        </tr>

        <tr> <!--Renault -->
          <td class='project' rowspan="8">Renault</td>
          <td class='subTotal'>Sub Total</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultPlans.mon.value === 0 }">{{renaultPlans.mon}}</td><td class='subTotal' :class="{ 'zero-value-sub': renaultResults.mon.value === 0 }">{{renaultResults.mon}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultPlans.tue.value === 0 }">{{renaultPlans.tue}}</td><td class='subTotal' :class="{ 'zero-value-sub': renaultResults.tue.value === 0 }">{{renaultResults.tue}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultPlans.wed.value === 0 }">{{renaultPlans.wed}}</td><td class='subTotal' :class="{ 'zero-value-sub': renaultResults.wed.value === 0 }">{{renaultResults.wed}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultPlans.thu.value === 0 }">{{renaultPlans.thu}}</td><td class='subTotal' :class="{ 'zero-value-sub': renaultResults.thu.value === 0 }">{{renaultResults.thu}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultPlans.fri.value === 0 }">{{renaultPlans.fri}}</td><td class='subTotal' :class="{ 'zero-value-sub': renaultResults.fri.value === 0 }">{{renaultResults.fri}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultPlans.sat.value === 0 }">{{renaultPlans.sat}}</td><td class='subTotal' :class="{ 'zero-value-sub': renaultResults.sat.value === 0 }">{{renaultResults.sat}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultPlans.sun.value === 0 }">{{renaultPlans.sun}}</td><td class='subTotal' :class="{ 'zero-value-sub': renaultResults.sat.value === 0 }">{{renaultResults.sun}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultWeekPlanSum === 0 }">{{renaultWeekPlanSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': renaultWeekResultSum === 0 }">{{renaultWeekResultSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': (renaultWeekResultSum - renaultWeekPlanSum) === 0, 'subTotal-red': (renaultWeekResultSum - renaultWeekPlanSum ) < 0 }" >{{renaultWeekResultSum -renaultWeekPlanSum}}</td>
        </tr>
        <tr>          
          <td>BT6 Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, renaultPlanBt6Fr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultPlanBt6Fr[day].value === 0 }">{{ renaultPlanBt6Fr[day].value }}</td>
            <td @click="editCellValue(dbTableName, renaultResultBt6Fr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultResultBt6Fr[day].value === 0 }">{{ renaultResultBt6Fr[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': renaultBt6FrWeekPlan === 0 }">{{renaultBt6FrWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': renaultBt6FrWeekResult === 0 }">{{renaultBt6FrWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (renaultBt6FrWeekResult - renaultBt6FrWeekPlan) === 0, 'redColor': (renaultBt6FrWeekResult - renaultBt6FrWeekPlan) < 0 }">{{renaultBt6FrWeekResult - renaultBt6FrWeekPlan}}</td>
        </tr>
        <tr>          
          <td>BT6 Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, renaultPlanBt6Rr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultPlanBt6Rr[day].value === 0 }">{{ renaultPlanBt6Rr[day].value }}</td>
            <td @click="editCellValue(dbTableName, renaultResultBt6Rr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultResultBt6Rr[day].value === 0 }">{{ renaultResultBt6Rr[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': renaultBt6RrWeekPlan === 0 }">{{renaultBt6RrWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': renaultBt6RrWeekResult === 0 }">{{renaultBt6RrWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (renaultBt6RrWeekResult - renaultBt6RrWeekPlan) === 0, 'redColor': (renaultBt6RrWeekResult - renaultBt6RrWeekPlan) < 0 }">{{renaultBt6RrWeekResult - renaultBt6RrWeekPlan}}</td>
        </tr>
        <tr>
        <td>EV2020 60kw Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, renaultPlanEv60Fr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultPlanEv60Fr[day].value === 0 }">{{ renaultPlanEv60Fr[day].value }}</td>
            <td @click="editCellValue(dbTableName, renaultResultEv60Fr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultResultEv60Fr[day].value === 0 }">{{ renaultResultEv60Fr[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv60FrWeekPlan === 0 }">{{renaultEv60FrWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv60FrWeekResult === 0 }">{{renaultEv60FrWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (renaultEv60FrWeekResult - renaultEv60FrWeekPlan) === 0, 'redColor': (renaultEv60FrWeekResult - renaultEv60FrWeekPlan) < 0 }">{{renaultEv60FrWeekResult - renaultEv60FrWeekPlan}}</td>
        </tr>
        <tr>
        <td>EV2020 60kw Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, renaultPlanEv60Rr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultPlanEv60Rr[day].value === 0 }">{{ renaultPlanEv60Rr[day].value }}</td>
            <td @click="editCellValue(dbTableName, renaultResultEv60Rr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultResultEv60Rr[day].value === 0 }">{{ renaultResultEv60Rr[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv60RrWeekPlan === 0 }">{{renaultEv60RrWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv60RrWeekResult === 0 }">{{renaultEv60RrWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (renaultEv60RrWeekResult - renaultEv60RrWeekPlan) === 0, 'redColor': (renaultEv60RrWeekResult - renaultEv60RrWeekPlan) < 0 }">{{renaultEv60RrWeekResult - renaultEv60RrWeekPlan}}</td>
        </tr>
        <tr>
        <td>EV2020 40kw Front</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, renaultPlanEv40Fr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultPlanEv40Fr[day].value === 0 }">{{ renaultPlanEv40Fr[day].value }}</td>
            <td @click="editCellValue(dbTableName, renaultResultEv40Fr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultResultEv40Fr[day].value === 0 }">{{ renaultResultEv40Fr[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv40FrWeekPlan === 0 }">{{renaultEv40FrWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv40FrWeekResult === 0 }">{{renaultEv40FrWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (renaultEv40FrWeekResult - renaultEv40FrWeekPlan) === 0, 'redColor': (renaultEv40FrWeekResult - renaultEv40FrWeekPlan) < 0 }">{{renaultEv40FrWeekResult - renaultEv40FrWeekPlan}}</td>
        </tr>
        <tr>
        <td>EV2020 40kw Rear</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, renaultPlanEv40Rr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultPlanEv40Rr[day].value === 0 }">{{ renaultPlanEv40Rr[day].value }}</td>
            <td @click="editCellValue(dbTableName, renaultResultEv40Rr[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultResultEv40Rr[day].value === 0 }">{{ renaultResultEv40Rr[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv40RrWeekPlan === 0 }">{{renaultEv40RrWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': renaultEv40RrWeekResult === 0 }">{{renaultEv40RrWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (renaultEv40RrWeekResult - renaultEv40RrWeekPlan) === 0, 'redColor': (renaultEv40RrWeekResult - renaultEv40RrWeekPlan) < 0 }">{{renaultEv40RrWeekResult - renaultEv40RrWeekPlan}}</td>
        </tr>
        <tr>
        <td>EV2020 FFC_ASSY</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, renaultPlanEvFfc[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultPlanEvFfc[day].value === 0 }">{{ renaultPlanEvFfc[day].value }}</td>
            <td @click="editCellValue(dbTableName, renaultResultEvFfc[day].id, 'quantity', $event)" :class="{ 'zero-value': renaultResultEvFfc[day].value === 0 }">{{ renaultResultEvFfc[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': renaultEvFfcWeekPlan === 0 }">{{renaultEvFfcWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': renaultEvFfcWeekResult === 0 }">{{renaultEvFfcWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (renaultEvFfcWeekResult - renaultEvFfcWeekPlan) === 0, 'redColor': (renaultEvFfcWeekResult - renaultEvFfcWeekPlan) < 0 }">{{renaultEvFfcWeekResult - renaultEvFfcWeekPlan}}</td>
        </tr>

        <tr> <!--VW -->
          <td class='project' rowspan="5">VW</td>
          <td class='subTotal'>Sub Total</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwPlans.mon.value === 0 }">{{vwPlans.mon}}</td><td class='subTotal' :class="{ 'zero-value-sub': vwResults.mon.value === 0 }">{{vwResults.mon}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwPlans.tue.value === 0 }">{{vwPlans.tue}}</td><td class='subTotal' :class="{ 'zero-value-sub': vwResults.tue.value === 0 }">{{vwResults.tue}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwPlans.wed.value === 0 }">{{vwPlans.wed}}</td><td class='subTotal' :class="{ 'zero-value-sub': vwResults.wed.value === 0 }">{{vwResults.wed}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwPlans.thu.value === 0 }">{{vwPlans.thu}}</td><td class='subTotal' :class="{ 'zero-value-sub': vwResults.thu.value === 0 }">{{vwResults.thu}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwPlans.fri.value === 0 }">{{vwPlans.fri}}</td><td class='subTotal' :class="{ 'zero-value-sub': vwResults.fri.value === 0 }">{{vwResults.fri}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwPlans.sat.value === 0 }">{{vwPlans.sat}}</td><td class='subTotal' :class="{ 'zero-value-sub': vwResults.sat.value === 0 }">{{vwResults.sat}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwPlans.sun.value === 0 }">{{vwPlans.sun}}</td><td class='subTotal' :class="{ 'zero-value-sub': vwResults.sat.value === 0 }">{{vwResults.sun}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwWeekPlanSum === 0 }">{{vwWeekPlanSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': vwWeekResultSum === 0 }">{{vwWeekResultSum}}</td>
          <td class='subTotal' :class="{ 'zero-value-sub': (vwWeekResultSum - vwWeekPlanSum) === 0, 'subTotal-red': (vwWeekResultSum - vwWeekPlanSum ) < 0 }" >{{vwWeekResultSum -vwWeekPlanSum}}</td>
        </tr>
        <tr>          
          <td>GEN1 2P12S</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, vwPlanGen12p12s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwPlanGen12p12s[day].value === 0 }">{{ vwPlanGen12p12s[day].value }}</td>
            <td @click="editCellValue(dbTableName, vwResultGen12p12s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwResultGen12p12s[day].value === 0 }">{{ vwResultGen12p12s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': vwGen12p12sWeekPlan === 0 }">{{vwGen12p12sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': vwGen12p12sWeekResult === 0 }">{{vwGen12p12sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (vwGen12p12sWeekResult - vwGen12p12sWeekPlan) === 0, 'redColor': (vwGen12p12sWeekResult - vwGen12p12sWeekPlan) < 0 }">{{vwGen12p12sWeekResult - vwGen12p12sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>GEN1 3P8S</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, vwPlanGen13p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwPlanGen13p8s[day].value === 0 }">{{ vwPlanGen13p8s[day].value }}</td>
            <td @click="editCellValue(dbTableName, vwResultGen13p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwResultGen13p8s[day].value === 0 }">{{ vwResultGen13p8s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': vwGen13p8sWeekPlan === 0 }">{{vwGen13p8sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': vwGen13p8sWeekResult === 0 }">{{vwGen13p8sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (vwGen13p8sWeekResult - vwGen13p8sWeekPlan) === 0, 'redColor': (vwGen13p8sWeekResult - vwGen13p8sWeekPlan) < 0 }">{{vwGen13p8sWeekResult - vwGen13p8sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>EVO 2P12S</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, vwPlanEvo2p12s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwPlanEvo2p12s[day].value === 0 }">{{ vwPlanEvo2p12s[day].value }}</td>
            <td @click="editCellValue(dbTableName, vwResultEvo2p12s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwResultEvo2p12s[day].value === 0 }">{{ vwResultEvo2p12s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': vwEvo2p12sWeekPlan === 0 }">{{vwEvo2p12sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': vwEvo2p12sWeekResult === 0 }">{{vwEvo2p12sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (vwEvo2p12sWeekResult - vwEvo2p12sWeekPlan) === 0, 'redColor': (vwEvo2p12sWeekResult - vwEvo2p12sWeekPlan) < 0 }">{{vwEvo2p12sWeekResult - vwEvo2p12sWeekPlan}}</td>
        </tr>
        <tr>          
          <td>EVO 3P8S</td>
          <template v-for="day in days" :key="day">
            <td @click="editCellValue(dbTableName, vwPlanEvo3p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwPlanEvo3p8s[day].value === 0 }">{{ vwPlanEvo3p8s[day].value }}</td>
            <td @click="editCellValue(dbTableName, vwResultEvo3p8s[day].id, 'quantity', $event)" :class="{ 'zero-value': vwResultEvo3p8s[day].value === 0 }">{{ vwResultEvo3p8s[day].value }}</td>
          </template>
            <td class='sum' :class="{ 'zero-value-sum': vwEvo3p8sWeekPlan === 0 }">{{vwEvo3p8sWeekPlan}}</td>
            <td class='sum' :class="{ 'zero-value-sum': vwEvo3p8sWeekResult === 0 }">{{vwEvo3p8sWeekResult}}</td>
            <td class='sum' :class="{ 'zero-value-sum': (vwEvo3p8sWeekResult - vwEvo3p8sWeekPlan) === 0, 'redColor': (vwEvo3p8sWeekResult - vwEvo3p8sWeekPlan) < 0 }">{{vwEvo3p8sWeekResult - vwEvo3p8sWeekPlan}}</td>
        </tr>   
      </table><br>
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>     
  </div>  
</template>

<script>
import { ref, computed, reactive, onMounted } from "vue";
import axios from "axios";
import readXlsxFile from 'read-excel-file';
import Modal from "./allProjectModal.vue";

export default {    
  components: { Modal, },   
  data() { return { excelFile: null, fileName: "", days : ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']};},  
  methods: {
    fetchDataForPrevWeek() { this.prevWeek(); this.callSearchData(); },  
    fetchDataForNextWeek() { this.nextWeek(); this.callSearchData(); },      
    onFileChange(event) {
      this.excelFile = event.target.files ? event.target.files[0] : null;
      this.fileName = this.excelFile ? this.excelFile.name : "";
      },   
    async uploadExcel(tableName) {
      if (!this.excelFile) {
          alert('Please select an Excel file.');
          return;
      }

      try {
          const rows = await readXlsxFile(this.excelFile);
          const columns = rows[0];
          const data = rows.slice(1);

          console.log('column :', columns.join(', '));
          data.forEach((row, index) => {
          console.log(`value row${index + 1} :`, row.join(', '));
          });

          console.log('Sending data to API:', { data: rows, tableName: tableName });
          const response = await axios.post('/api/upload-excel', {
          data: rows,
          tableName: tableName,
          });

          console.log(response);
          alert('File uploaded successfully.');
          
      } catch (error) {
          console.error(error);
          alert('Error uploading the file.');
      }
    }, 
  },
  setup() {    
    const showModal = ref(false);
    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const currentDate = ref(new Date());
    const currentDateAndWeek = computed(() => { const weekNumber = getWeekNumber(currentDate.value); return `Week ${weekNumber}`; });
    const weekDates = computed(() => {
      const weekStart = new Date(currentDate.value);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);
      const dates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(weekStart);
        date.setDate(weekStart.getDate() + i);
        dates.push(date);
      }
      return dates;
    });
    const prevWeek = () => { currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() - 7));};
    const nextWeek = () => { currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() + 7));};
    function getWeekNumber(date) {
      const startOfYear = new Date(date.getFullYear(), 0, 1);
      const weekNumber = Math.ceil((((date - startOfYear) / 86400000) + startOfYear.getDay() + 1) / 7);
      return weekNumber;
    }   
    const getDayKey = (dayIndex) => { switch (dayIndex) { case 1: return 'mon'; case 2: return 'tue'; case 3: return 'wed'; case 4: return 'thu'; case 5: return 'fri'; case 6: return 'sat'; case 0: return 'sun'; default: return null; }};
    
    const dbTableName = 'shippingallproject'; const shippingData = reactive({ data: [] });  

    const searchData = async (tableName) => {
        const startWeek = weekDates.value[0];
        const endWeek = weekDates.value[weekDates.value.length - 1];

        const formData = {
          tableName: tableName,
          startYear: startWeek.getFullYear(),
          startMonth: startWeek.getMonth() + 1,
          startDay: startWeek.getDate(),
          endYear: endWeek.getFullYear(),
          endMonth: endWeek.getMonth() + 1,
          endDay: endWeek.getDate(),          
        };

        axios.get('/api/searchPeriod', { params: formData })
          .then((res) => {
            switch (tableName) {               
             case 'shippingallproject' : shippingData.data = res.data.sort((a, b) => { if (a.year !== b.year) {return a.year - b.year;} else if (a.month !== b.month) { return a.month - b.month;} else {return a.day - b.day;}}); 
             filterLatestData();  
             break;             
            }
          });                    
    }; 

    const filterLatestData = () => {
        const latestDataMap = shippingData.data.reduce((acc, item) => {
            const key = `${item.year}-${item.month}-${item.day}-${item.model}-${item.type}`;                
            
            if (!acc[key] || acc[key].id < item.id) {
                acc[key] = item;
            }
            return acc;
        }, {});            

        shippingData.data = Object.values(latestDataMap);
    };

    const settingData = reactive({ data: [], });  

    const callSearchData = async () => {         
      const tableName = 'setting';
      const formData = { tableName: tableName };

      try {
          const res = await axios.get('/api/targetList', { params: formData });
          settingData.data = res.data;

          const shippingSetting = settingData.data.find(s => Object.hasOwn(s, 'shipping'));  
          const isShippingEnabled = shippingSetting && shippingSetting.shipping === 1;
          if (!isShippingEnabled) {
              alert("Shipping is currently disabled.");
              return;
          }

          searchData(dbTableName);

        } catch (error) {
          console.error("Error attendance:", error.message);
      }
    };       
    
    onMounted(() => { callSearchData() });    

    function createItem(db, itemName, type = null, model = null ) {
      return computed(() => {
        const items = {
          mon: { id: null, value: 0 },
          tue: { id: null, value: 0 },
          wed: { id: null, value: 0 },
          thu: { id: null, value: 0 },
          fri: { id: null, value: 0 },
          sat: { id: null, value: 0 },
          sun: { id: null, value: 0 },
        };

        for (const item of db.data) {
          // 모델과 타입이 제공되었는지 확인하고, itemName이 존재하는지도 확인
          if (((model === null || item.model === model) && 
              (type === null || item.type === type)) &&
              item[itemName] != null) {
            const dayKey = getDayKey(new Date(item.year, item.month - 1, item.day).getDay());
            if (dayKey) {
              items[dayKey].value += item[itemName]; // 값을 집계
              items[dayKey].id = item.id; // 필요에 따라 이 부분을 조정하십시오
            }
          }
        }
        return items;
      });
    }

    const bmw12vPlanf40 = createItem(shippingData, 'quantity', 'Plan', 'BMW 12V F40' );
    const bmw12vPlanhaf = createItem(shippingData, 'quantity', 'Plan', 'BMW 12V HAF');
    const bmw48vPlanAi = createItem(shippingData, 'quantity', 'Plan', 'BMW 48V GEN1.5 AI');    
    const bmw48vPlanAj = createItem(shippingData, 'quantity', 'Plan', 'BMW 48V GEN1.5 AJ');

    const fcaPlanFront = createItem(shippingData, 'quantity', 'Plan', 'FCA Front' );
    const fcaPlanRear = createItem(shippingData, 'quantity', 'Plan', 'FCA Rear');
    const fcaPlanPos = createItem(shippingData, 'quantity', 'Plan', 'FCA Flexible POS');    
    const fcaPlanNeg = createItem(shippingData, 'quantity', 'Plan', 'FCA Flexible NEG');
    const fcaPlan45 = createItem(shippingData, 'quantity', 'Plan', 'FCA Flexible 4-5+');
    const fcaPlan67 = createItem(shippingData, 'quantity', 'Plan', 'FCA Flexible 6+7-');
    const fcaPlanBc = createItem(shippingData, 'quantity', 'Plan', 'FCA Flexible BSBR&CARR');
    const fcaPlanBa = createItem(shippingData, 'quantity', 'Plan', 'FCA Flexible BSBR_A');

    const fordPlanFr4p8s = createItem(shippingData, 'quantity', 'Plan', 'Front 4P8S');
    const fordPlanRr4p8s = createItem(shippingData, 'quantity', 'Plan', 'Rear 4P8S');
    const fordPlanFr4p7s = createItem(shippingData, 'quantity', 'Plan', 'Front 4P7S');
    const fordPlanRr4p7s = createItem(shippingData, 'quantity', 'Plan', 'Rear 4P7S');
    const fordPlanFr3p10s = createItem(shippingData, 'quantity', 'Plan', 'Front 3P10S');
    const fordPlanRr3p10s = createItem(shippingData, 'quantity', 'Plan', 'Rear 3P10S');
    const fordPlanFr3p8s = createItem(shippingData, 'quantity', 'Plan', 'Front 3P8S');
    const fordPlanRr3p8s = createItem(shippingData, 'quantity', 'Plan', 'Rear 3P8S');

    const renaultPlanBt6Fr = createItem(shippingData, 'quantity', 'Plan', 'BT6 Front');
    const renaultPlanBt6Rr = createItem(shippingData, 'quantity', 'Plan', 'BT6 Rear');
    const renaultPlanEv60Fr = createItem(shippingData, 'quantity', 'Plan', 'EV2020 60kW Front');
    const renaultPlanEv60Rr = createItem(shippingData, 'quantity', 'Plan', 'EV2020 60kW Rear');    
    const renaultPlanEv40Fr = createItem(shippingData, 'quantity', 'Plan', 'EV2020 40kW Front');
    const renaultPlanEv40Rr = createItem(shippingData, 'quantity', 'Plan', 'EV2020 40kW Rear');
    const renaultPlanEvFfc = createItem(shippingData, 'quantity', 'Plan', 'EV2020 FFC_ASSY');

    const vwPlanGen12p12s = createItem(shippingData, 'quantity', 'Plan', 'GEN1 2P12S');
    const vwPlanGen13p8s = createItem(shippingData, 'quantity', 'Plan', 'GEN1 3P8S');
    const vwPlanEvo2p12s = createItem(shippingData, 'quantity', 'Plan', 'EVO 2P12S');
    const vwPlanEvo3p8s = createItem(shippingData, 'quantity', 'Plan', 'EVO 3P8S');
    
    const bmw12vResultf40 = createItem(shippingData, 'quantity', 'Result', 'BMW 12V F40');
    const bmw12vResulthaf = createItem(shippingData, 'quantity', 'Result', 'BMW 12V HAF');
    const bmw48vResultAi = createItem(shippingData, 'quantity', 'Result', 'BMW 48V GEN1.5 AI');    
    const bmw48vResultAj = createItem(shippingData, 'quantity', 'Result', 'BMW 48V GEN1.5 AJ');

    const fcaResultFront = createItem(shippingData, 'quantity', 'Result', 'FCA Front' );
    const fcaResultRear = createItem(shippingData, 'quantity', 'Result', 'FCA Rear');
    const fcaResultPos = createItem(shippingData, 'quantity', 'Result', 'FCA Flexible POS');    
    const fcaResultNeg = createItem(shippingData, 'quantity', 'Result', 'FCA Flexible NEG');
    const fcaResult45 = createItem(shippingData, 'quantity', 'Result', 'FCA Flexible 4-5+');
    const fcaResult67 = createItem(shippingData, 'quantity', 'Result', 'FCA Flexible 6+7-');
    const fcaResultBc = createItem(shippingData, 'quantity', 'Result', 'FCA Flexible BSBR&CARR');
    const fcaResultBa = createItem(shippingData, 'quantity', 'Result', 'FCA Flexible BSBR_A');

    const fordResultFr4p8s = createItem(shippingData, 'quantity', 'Result', 'Front 4P8S');
    const fordResultRr4p8s = createItem(shippingData, 'quantity', 'Result', 'Rear 4P8S');
    const fordResultFr4p7s = createItem(shippingData, 'quantity', 'Result', 'Front 4P7S');
    const fordResultRr4p7s = createItem(shippingData, 'quantity', 'Result', 'Rear 4P7S');
    const fordResultFr3p10s = createItem(shippingData, 'quantity', 'Result', 'Front 3P10S');
    const fordResultRr3p10s = createItem(shippingData, 'quantity', 'Result', 'Rear 3P10S');
    const fordResultFr3p8s = createItem(shippingData, 'quantity', 'Result', 'Front 3P8S');
    const fordResultRr3p8s = createItem(shippingData, 'quantity', 'Result', 'Rear 3P8S');

    const renaultResultBt6Fr = createItem(shippingData, 'quantity', 'Result', 'BT6 Front');
    const renaultResultBt6Rr = createItem(shippingData, 'quantity', 'Result', 'BT6 Rear');
    const renaultResultEv60Fr = createItem(shippingData, 'quantity', 'Result', 'EV2020 60kW Front');
    const renaultResultEv60Rr = createItem(shippingData, 'quantity', 'Result', 'EV2020 60kW Rear');    
    const renaultResultEv40Fr = createItem(shippingData, 'quantity', 'Result', 'EV2020 40kW Front');
    const renaultResultEv40Rr = createItem(shippingData, 'quantity', 'Result', 'EV2020 40kW Rear');
    const renaultResultEvFfc = createItem(shippingData, 'quantity', 'Result', 'EV2020 FFC_ASSY');

    const vwResultGen12p12s = createItem(shippingData, 'quantity', 'Result', 'GEN1 2P12S');
    const vwResultGen13p8s = createItem(shippingData, 'quantity', 'Result', 'GEN1 3P8S');
    const vwResultEvo2p12s = createItem(shippingData, 'quantity', 'Result', 'EVO 2P12S');
    const vwResultEvo3p8s = createItem(shippingData, 'quantity', 'Result', 'EVO 3P8S');


    const calculateWeekPlan = (planData) => { return computed(() => { return Object.values(planData.value).reduce((total, day) => total + day.value, 0);});};

    const bmw12vf40WeekPlan = calculateWeekPlan(bmw12vPlanf40);
    const bmw12vhafWeekPlan = calculateWeekPlan(bmw12vPlanhaf);
    const bmw48vAiWeekPlan = calculateWeekPlan(bmw48vPlanAi);
    const bmw48vAjWeekPlan = calculateWeekPlan(bmw48vPlanAj);
    const bmwWeekPlanSum = computed(() => { return bmw12vf40WeekPlan.value + bmw12vhafWeekPlan.value + bmw48vAiWeekPlan.value + bmw48vAjWeekPlan.value; });
    
    const fcaFrontWeekPlan = calculateWeekPlan(fcaPlanFront);
    const fcaRearWeekPlan = calculateWeekPlan(fcaPlanRear);
    const fcaPosWeekPlan = calculateWeekPlan(fcaPlanPos);
    const fcaNegWeekPlan = calculateWeekPlan(fcaPlanNeg);
    const fca45WeekPlan = calculateWeekPlan(fcaPlan45);
    const fca67WeekPlan = calculateWeekPlan(fcaPlan67);
    const fcaBcWeekPlan = calculateWeekPlan(fcaPlanBc);
    const fcaBaWeekPlan = calculateWeekPlan(fcaPlanBa);
    const fcaWeekPlanSum = computed(() => { return fcaFrontWeekPlan.value + fcaRearWeekPlan.value + fcaPosWeekPlan.value + fcaNegWeekPlan.value
                                            + fca45WeekPlan.value + fca67WeekPlan.value + fcaBcWeekPlan.value + fcaBaWeekPlan.value; });

    const fordFr4p8sWeekPlan = calculateWeekPlan(fordPlanFr4p8s);
    const fordRr4p8sWeekPlan = calculateWeekPlan(fordPlanRr4p8s);
    const fordFr4p7sWeekPlan = calculateWeekPlan(fordPlanFr4p7s);
    const fordRr4p7sWeekPlan = calculateWeekPlan(fordPlanRr4p7s);
    const fordFr3p10sWeekPlan = calculateWeekPlan(fordPlanFr3p10s);
    const fordRr3p10sWeekPlan = calculateWeekPlan(fordPlanRr3p10s);
    const fordFr3p8sWeekPlan = calculateWeekPlan(fordPlanFr3p8s);
    const fordRr3p8sWeekPlan = calculateWeekPlan(fordPlanRr3p8s);
    const fordWeekPlanSum = computed(() => { return fordFr4p8sWeekPlan.value + fordRr4p8sWeekPlan.value + fordFr4p7sWeekPlan.value + fordRr4p7sWeekPlan.value
                                            + fordFr3p10sWeekPlan.value + fordRr3p10sWeekPlan.value + fordFr3p8sWeekPlan.value + fordRr3p8sWeekPlan.value; });

    const renaultBt6FrWeekPlan = calculateWeekPlan(renaultPlanBt6Fr);
    const renaultBt6RrWeekPlan = calculateWeekPlan(renaultPlanBt6Rr);
    const renaultEv60FrWeekPlan = calculateWeekPlan(renaultPlanEv60Fr);
    const renaultEv60RrWeekPlan = calculateWeekPlan(renaultPlanEv60Rr);
    const renaultEv40FrWeekPlan = calculateWeekPlan(renaultPlanEv40Fr);
    const renaultEv40RrWeekPlan = calculateWeekPlan(renaultPlanEv40Rr);
    const renaultEvFfcWeekPlan = calculateWeekPlan(renaultPlanEvFfc);
    const renaultWeekPlanSum = computed(() => { return renaultBt6FrWeekPlan.value + renaultBt6RrWeekPlan.value + renaultEv60FrWeekPlan.value + renaultEv60RrWeekPlan.value
                                            + renaultEv40FrWeekPlan.value + renaultEv40RrWeekPlan.value + renaultEvFfcWeekPlan.value; });

    const vwGen12p12sWeekPlan = calculateWeekPlan(vwPlanGen12p12s);
    const vwGen13p8sWeekPlan = calculateWeekPlan(vwPlanGen13p8s);
    const vwEvo2p12sWeekPlan = calculateWeekPlan(vwPlanEvo2p12s);
    const vwEvo3p8sWeekPlan = calculateWeekPlan(vwPlanEvo3p8s);
    const vwWeekPlanSum = computed(() => { return vwGen12p12sWeekPlan.value + vwGen13p8sWeekPlan.value + vwEvo2p12sWeekPlan.value + vwEvo3p8sWeekPlan.value; });

    const bmw12vf40WeekResult = calculateWeekPlan(bmw12vResultf40);
    const bmw12vhafWeekResult = calculateWeekPlan(bmw12vResulthaf);
    const bmw48vAiWeekResult = calculateWeekPlan(bmw48vResultAi);
    const bmw48vAjWeekResult = calculateWeekPlan(bmw48vResultAj);
    const bmwWeekResultSum = computed(() => { return bmw12vf40WeekResult.value + bmw12vhafWeekResult.value + bmw48vAiWeekResult.value + bmw48vAjWeekResult.value; });

    const fcaFrontWeekResult = calculateWeekPlan(fcaResultFront);
    const fcaRearWeekResult = calculateWeekPlan(fcaResultRear);
    const fcaPosWeekResult = calculateWeekPlan(fcaResultPos);
    const fcaNegWeekResult = calculateWeekPlan(fcaResultNeg);
    const fca45WeekResult = calculateWeekPlan(fcaResult45);
    const fca67WeekResult = calculateWeekPlan(fcaResult67);
    const fcaBcWeekResult = calculateWeekPlan(fcaResultBc);
    const fcaBaWeekResult = calculateWeekPlan(fcaResultBa);
    const fcaWeekResultSum = computed(() => { return fcaFrontWeekResult.value + fcaRearWeekResult.value + fcaPosWeekResult.value + fcaNegWeekResult.value
                                            + fca45WeekResult.value + fca67WeekResult.value + fcaBcWeekResult.value + fcaBaWeekResult.value; });

    const fordFr4p8sWeekResult = calculateWeekPlan(fordResultFr4p8s);
    const fordRr4p8sWeekResult = calculateWeekPlan(fordResultRr4p8s);
    const fordFr4p7sWeekResult = calculateWeekPlan(fordResultFr4p7s);
    const fordRr4p7sWeekResult = calculateWeekPlan(fordResultRr4p7s);
    const fordFr3p10sWeekResult = calculateWeekPlan(fordResultFr3p10s);
    const fordRr3p10sWeekResult = calculateWeekPlan(fordResultRr3p10s);
    const fordFr3p8sWeekResult = calculateWeekPlan(fordResultFr3p8s);
    const fordRr3p8sWeekResult = calculateWeekPlan(fordResultRr3p8s);
    const fordWeekResultSum = computed(() => { return fordFr4p8sWeekResult.value + fordRr4p8sWeekResult.value + fordFr4p7sWeekResult.value + fordRr4p7sWeekResult.value
                                            + fordFr3p10sWeekResult.value + fordRr3p10sWeekResult.value + fordFr3p8sWeekResult.value + fordRr3p8sWeekResult.value; });

    const renaultBt6FrWeekResult = calculateWeekPlan(renaultResultBt6Fr);
    const renaultBt6RrWeekResult = calculateWeekPlan(renaultResultBt6Rr);
    const renaultEv60FrWeekResult = calculateWeekPlan(renaultResultEv60Fr);
    const renaultEv60RrWeekResult = calculateWeekPlan(renaultResultEv60Rr);
    const renaultEv40FrWeekResult = calculateWeekPlan(renaultResultEv40Fr);
    const renaultEv40RrWeekResult = calculateWeekPlan(renaultResultEv40Rr);
    const renaultEvFfcWeekResult = calculateWeekPlan(renaultResultEvFfc);
    const renaultWeekResultSum = computed(() => { return renaultBt6FrWeekResult.value + renaultBt6RrWeekResult.value + renaultEv60FrWeekResult.value + renaultEv60RrWeekResult.value
                                            + renaultEv40FrWeekResult.value + renaultEv40RrWeekResult.value + renaultEvFfcWeekResult.value; });

    const vwGen12p12sWeekResult = calculateWeekPlan(vwResultGen12p12s);
    const vwGen13p8sWeekResult = calculateWeekPlan(vwResultGen13p8s);
    const vwEvo2p12sWeekResult = calculateWeekPlan(vwResultEvo2p12s);
    const vwEvo3p8sWeekResult = calculateWeekPlan(vwResultEvo3p8s);
    const vwWeekResultSum = computed(() => { return vwGen12p12sWeekResult.value + vwGen13p8sWeekResult.value + vwEvo2p12sWeekResult.value + vwEvo3p8sWeekResult.value; });

    const bmwPlans = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return bmw12vPlanf40.value[day].value + bmw12vPlanhaf.value[day].value + bmw48vPlanAi.value[day].value + bmw48vPlanAj.value[day].value;
      });
      return acc;
    }, {});
    const fcaPlans = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return fcaPlanFront.value[day].value + fcaPlanRear.value[day].value + fcaPlanPos.value[day].value + fcaPlanNeg.value[day].value + fcaPlan45.value[day].value + fcaPlan67.value[day].value + fcaPlanBc.value[day].value + fcaPlanBa.value[day].value;
      });
      return acc;
    }, {});
    const fordPlans = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return fordPlanFr4p8s.value[day].value + fordPlanRr4p8s.value[day].value + fordPlanFr4p7s.value[day].value + fordPlanRr4p7s.value[day].value + fordPlanFr3p10s.value[day].value + fordPlanRr3p10s.value[day].value + fordPlanFr3p8s.value[day].value + fordPlanRr3p8s.value[day].value;
      });
      return acc;
    }, {});
    const renaultPlans = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return renaultPlanBt6Fr.value[day].value + renaultPlanBt6Rr.value[day].value + renaultPlanEv60Fr.value[day].value + renaultPlanEv60Rr.value[day].value + renaultPlanEv40Fr.value[day].value + renaultPlanEv40Rr.value[day].value + renaultPlanEvFfc.value[day].value;
      });
      return acc;
    }, {});
    const vwPlans = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return vwPlanGen12p12s.value[day].value + vwPlanGen13p8s.value[day].value + vwPlanEvo2p12s.value[day].value + vwPlanEvo3p8s.value[day].value;
      });
      return acc;
    }, {});

    const bmwResults = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return bmw12vResultf40.value[day].value + bmw12vResulthaf.value[day].value + bmw48vResultAi.value[day].value + bmw48vResultAj.value[day].value;
      });
      return acc;
    }, {});
    const fcaResults = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return fcaResultFront.value[day].value + fcaResultRear.value[day].value + fcaResultPos.value[day].value + fcaResultNeg.value[day].value + fcaResult45.value[day].value + fcaResult67.value[day].value + fcaResultBc.value[day].value + fcaResultBa.value[day].value;
      });
      return acc;
    }, {});
    const fordResults = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return fordResultFr4p8s.value[day].value + fordResultRr4p8s.value[day].value + fordResultFr4p7s.value[day].value + fordResultRr4p7s.value[day].value + fordResultFr3p10s.value[day].value + fordResultRr3p10s.value[day].value + fordResultFr3p8s.value[day].value + fordResultRr3p8s.value[day].value;
      });
      return acc;
    }, {});
    const renaultResults = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return renaultResultBt6Fr.value[day].value + renaultResultBt6Rr.value[day].value + renaultResultEv60Fr.value[day].value + renaultResultEv60Rr.value[day].value + renaultResultEv40Fr.value[day].value + renaultResultEv40Rr.value[day].value + renaultResultEvFfc.value[day].value;
      });
      return acc;
    }, {});
    const vwResults = days.reduce((acc, day) => {
      acc[day] = computed(() => {
        return vwResultGen12p12s.value[day].value + vwResultGen13p8s.value[day].value + vwResultEvo2p12s.value[day].value + vwResultEvo3p8s.value[day].value;
      });
      return acc;
    }, {});

    const totalPlans = {}; const totalResults = {};

    days.forEach(day => {
      totalPlans[day] = computed(() => {
        return bmwPlans[day].value + fcaPlans[day].value + fordPlans[day].value + renaultPlans[day].value + vwPlans[day].value;
      });
    });
    days.forEach(day => {
      totalResults[day] = computed(() => {
        return bmwResults[day].value + fcaResults[day].value + fordResults[day].value + renaultResults[day].value + vwResults[day].value;
      });
    });

    const totalWeekPlanSum = computed(() => {
      return bmwWeekPlanSum.value + fcaWeekPlanSum.value + fordWeekPlanSum.value + renaultWeekPlanSum.value + vwWeekPlanSum.value;
    });
    const totalWeekResultSum = computed(() => {
      return bmwWeekResultSum.value + fcaWeekResultSum.value + fordWeekResultSum.value + renaultWeekResultSum.value + vwWeekResultSum.value;
    });

    const editCellValue = async (tableName, id, header, event) => {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

        if (newValue !== null && newValue !== currentValue) {
          // Update the value in the database and update the tableData
          try {
            // Replace input value with the desired value
            await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

            // Refresh the data by calling searchData()
            searchData(tableName);
          } catch (error) {
            console.error("Error updating cell value:", error);
          }
        }
    };    

    return {     
      searchData, prevWeek, nextWeek, currentDateAndWeek, weekDates, callSearchData, showModal, shippingData, editCellValue, dbTableName,

      bmw12vPlanf40, bmw12vPlanhaf, bmw48vPlanAi, bmw48vPlanAj,
      fcaPlanFront, fcaPlanRear, fcaPlanPos, fcaPlanNeg, fcaPlan45, fcaPlan67, fcaPlanBc, fcaPlanBa,
      fordPlanFr4p8s, fordPlanRr4p8s, fordPlanFr4p7s, fordPlanRr4p7s, fordPlanFr3p10s, fordPlanRr3p10s, fordPlanFr3p8s, fordPlanRr3p8s,
      renaultPlanBt6Fr, renaultPlanBt6Rr, renaultPlanEv60Fr, renaultPlanEv60Rr, renaultPlanEv40Fr, renaultPlanEv40Rr, renaultPlanEvFfc,
      vwPlanGen12p12s, vwPlanGen13p8s, vwPlanEvo2p12s, vwPlanEvo3p8s,
      bmw12vResultf40, bmw12vResulthaf, bmw48vResultAi, bmw48vResultAj,
      fcaResultFront, fcaResultRear, fcaResultPos, fcaResultNeg, fcaResult45, fcaResult67, fcaResultBc, fcaResultBa,
      fordResultFr4p8s, fordResultRr4p8s, fordResultFr4p7s, fordResultRr4p7s, fordResultFr3p10s, fordResultRr3p10s, fordResultFr3p8s, fordResultRr3p8s,
      renaultResultBt6Fr, renaultResultBt6Rr, renaultResultEv60Fr, renaultResultEv60Rr, renaultResultEv40Fr, renaultResultEv40Rr, renaultResultEvFfc,
      vwResultGen12p12s, vwResultGen13p8s, vwResultEvo2p12s, vwResultEvo3p8s, 

      bmwPlans, fcaPlans, fordPlans, renaultPlans, vwPlans, bmwResults, fcaResults, fordResults, renaultResults, vwResults,

      bmw12vf40WeekPlan, bmw12vhafWeekPlan, bmw48vAiWeekPlan, bmw48vAjWeekPlan, fcaFrontWeekPlan, fcaRearWeekPlan, fcaPosWeekPlan, fcaNegWeekPlan,
      fca45WeekPlan, fca67WeekPlan, fcaBcWeekPlan, fcaBaWeekPlan, fordFr4p8sWeekPlan, fordRr4p8sWeekPlan, fordFr4p7sWeekPlan, fordRr4p7sWeekPlan,
      fordFr3p10sWeekPlan, fordRr3p10sWeekPlan, fordFr3p8sWeekPlan, fordRr3p8sWeekPlan, renaultBt6FrWeekPlan, renaultBt6RrWeekPlan, renaultEv60FrWeekPlan,
      renaultEv60RrWeekPlan, renaultEv40FrWeekPlan, renaultEv40RrWeekPlan, renaultEvFfcWeekPlan, vwGen12p12sWeekPlan, vwGen13p8sWeekPlan, vwEvo2p12sWeekPlan,
      vwEvo3p8sWeekPlan, bmw12vf40WeekResult, bmw12vhafWeekResult, bmw48vAiWeekResult, bmw48vAjWeekResult, fcaFrontWeekResult, fcaRearWeekResult, fcaPosWeekResult,
      fcaNegWeekResult, fca45WeekResult, fca67WeekResult, fcaBcWeekResult, fcaBaWeekResult, fordFr4p8sWeekResult, fordRr4p8sWeekResult, fordFr4p7sWeekResult,
      fordRr4p7sWeekResult, fordFr3p10sWeekResult, fordRr3p10sWeekResult, fordFr3p8sWeekResult, fordRr3p8sWeekResult, renaultBt6FrWeekResult, renaultBt6RrWeekResult,
      renaultEv60FrWeekResult, renaultEv60RrWeekResult, renaultEv40FrWeekResult, renaultEv40RrWeekResult, renaultEvFfcWeekResult, vwGen12p12sWeekResult, vwGen13p8sWeekResult,
      vwEvo2p12sWeekResult, vwEvo3p8sWeekResult, 

      bmwWeekPlanSum, fcaWeekPlanSum, fordWeekPlanSum, renaultWeekPlanSum, vwWeekPlanSum, bmwWeekResultSum, fcaWeekResultSum, fordWeekResultSum, renaultWeekResultSum, vwWeekResultSum,
      totalPlans, totalResults, totalWeekPlanSum, totalWeekResultSum
  };
  }
};

</script>

<style scoped> 
  .act {
    display: flex; justify-content: space-between; align-items: center;
    margin-bottom: 1rem; margin-left : 20px;
  }
  .searchBar { 
    display: flex; flex-wrap: nowrap; justify-content: flex-start;  align-items: center;
    margin-bottom: 0px; margin-left: 20px;
  }
  .nav-button {
    background-color: #007bff;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px;  margin-right : 10px;
  }
  .nav-button:hover { background-color: #9babf3; }

  .tables { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; }
  .custom-table { font-size: 15px; width: 80%; overflow-x: auto; white-space: nowrap; }

  th {
      z-index: 10; background-color: rgb(194, 194, 250); border : 1px solid black;        
      text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px; font-weight: 900; 
  }
  td {
      background-color: rgb(255, 255, 255) ;
      border : 1px solid black; text-align : center;
      padding-left : 10px; padding-right : 10px;
      font-size : 15px; font-weight: 400;    
  }
  .difference { background-color: rgb(139, 166, 253); }  

  .custom-file-upload {
    display: inline-block;  padding: 6px 12px;  cursor: pointer;
    background-color: #347003;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }
  .custom-file-upload:hover { background-color: #009625; }

  .modalbutton {
    background-color: #f3b226;  color: rgb(0, 0, 0);
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 20px; margin-bottom : 10px;
  }
  .modalbutton:hover { background-color: #f0c981; }
  .excelbutton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 20px; margin-bottom : 10px;
  }
  .excelbutton:hover { background-color: #009625; }

  .project { background-color: #ffffff; color: #000000; font-weight: 900; font-size: 25px;}   
  .zero-value { background-color: #ffffff; color: #ffffff; }    
  .subTotal { background-color: #cccccc; color: #000000; font-weight: 900; }   
  .subTotal-red { background-color: #cccccc; color: rgb(231, 28, 28); font-weight: 900; } 
  .zero-value-sub { background-color: #cccccc; color: #cccccc; }  
  .sum { background-color: #f3e8c0; color: #000000; font-weight: 400;}   
  .zero-value-sum { background-color: #f3e8c0; color: #f3e8c0; }   
  .redColor { background-color: #f3e8c0; color: rgb(231, 28, 28); font-weight: 400;}
  .total { background-color: #f4b144; color: #000000; font-weight: 900; }   
  .total-red { background-color: #f4b144; color: rgb(231, 28, 28); font-weight: 900; }   
  .zero-value-total { background-color: #f4b144; color: #f4b144; }  

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 1;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }
</style>