<template>
    <div class="container">        
      <div class="RequestLog">
        <h class="pagetitle">Scrap Log</h>        
        <form @submit.prevent="sendDate()" class="searchBar">
          <table>  
              <tr>
                <td class="start">
                    <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
                    <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
                    <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
                </td>
                <td class="separator">~</td>
                <td class="end">
                    <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
                    <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
                    <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
                </td>
                <td class="factory">
                    <select type="text" id="factoryselect" v-model="factory" placeholder="Factory">
                      <option>1</option><option>2</option><option></option>
                    </select>                    
                </td>
                <td class="searchTd">
                    <button type="submit" @click="callSearchData">Search</button>                        
                </td>
                <td class="searchTd">
                    <button class="excelButton" @click="Excel">Excel</button>                                              
                </td>
              </tr>
          </table>      
        </form>
      </div>            
      <div class="tables">
        <b>Product Scarp</b>
        <table class="productLog">
          <tr>          
            <th>Year</th><th>Month</th><th>Day</th><th>Factory</th><th>Product Code</th><th>Product Name</th><th>Quantity</th><th>Unit</th><th>Work Order</th><th>Remark</th><th>DEL</th>
          </tr>          
          <tr v-for="row in prItem" :key="row.id">              
            <td class="list">{{ row.year }}</td>    
            <td class="list">{{ row.month }}</td>    
            <td class="list">{{ row.day }}</td>
            <td class="list">{{ row.factory }}</td>
            <td class="list">{{ row.productcode }}</td>
            <td class="list">{{ row.productname }}</td>
            <td class="list">{{ row.quantity }}</td>
            <td class="list">{{ row.unit }}</td>  
            <td class="list" @click="editCell(prTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td>
            <td class="list">{{ row.remark }}</td> 
            <td class="list"><button :class="['del', { disabledButton: !canClick() }]" :disabled="!canClick(row)" type="submit" @click="delRow(prTableName, row.id)">DEL</button></td>       
          </tr>
        </table>   
        <br> 
        <b>Material Scarp</b>
        <table class="materialLog">
          <tr>          
            <th>Year</th><th>Month</th><th>Day</th><th>Factory</th><th>Material Code</th><th>Material Name</th><th>Quantity</th><th>Unit</th><th>Work Order</th><th>DEL</th><th>SAP</th><th>Real</th><th>Remark</th><th>Pure Copper</th><th>Coating Copper</th>
          </tr>          
          <tr v-for="row in maItem" :key="row.id">              
            <td class="list">{{ row.year }}</td>    
            <td class="list">{{ row.month }}</td>    
            <td class="list">{{ row.day }}</td>
            <td class="list">{{ row.factory }}</td>
            <td class="list">{{ row.materialcode }}</td>
            <td class="list">{{ row.materialname }}</td>
            <td class="list">{{ row.quantity }}</td>
            <td class="list">{{ row.unit }}</td>  
            <td class="list"  @click="editCell(maTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td>           
            <td class="list"><button :class="['del', { disabledButton: !canClick() }]" :disabled="!canClick(row)" type="submit" @click="delRow(maTableName, row.id)">DEL</button></td>
            <td class="list" :class="{ 'gray-background': row.sap == 'Done' }"><button class="confirmButton" :class="{ 'disabledButton': row.sap == 'Done' || !canConfirm() }" type="submit" @click="handleClickSAP(row)" :disabled="!canConfirm()">SAP</button></td>
            <td class="list" :class="{ 'gray-background': row.realscrap == 'Done' }"><button class="confirmButton" :class="{ 'disabledButton': row.realscrap == 'Done' || !canConfirm() }" type="submit" @click="handleClickReal(row)" :disabled="!canConfirm()">Real</button></td>  
            <td class="list">{{ row.remark }}</td>
            <td class="list">{{ row.purecopper }}</td>  
            <td class="list">{{ row.coatingcopper }}</td>  
          </tr>
        </table> 
        <br> 
        <b>Copper Weight</b>
        <table class="copperWeight">
          <tr><th>Pure Copper (unit: g)</th><th>Coating Copper (unit: g)</th></tr>
          <tr>
            <td>{{ totalPureCopper }}</td>
            <td>{{ totalCoatingCopper }}</td>
          </tr>          
        </table>   
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { deleteRow, editCellValue,} from "../utils.js"; 

export default {
  data() {
    return {                 
      columnMapping: {            
        year: 'year', month: 'month', day: 'day', factory: 'factory',
        materialcode: 'materialcode', materialname: 'materialname',
        quantity: 'quantity', unit: 'unit', workorder: 'workorder',
        remark: 'remark', sap: 'sap', realscrap: 'realscrap',            
      }
    }
  },

  methods: {
    handleClickSAP(row) { this.confirmSendSAP(row); this.callSearchData(); },
    handleClickReal(row) { this.confirmSendReal(row); this.callSearchData(); },    
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    },  
    canConfirm() {                    
      const currentUser = this.$route.meta.currentUser;
      const isUserValid = currentUser && (currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 4 || currentUser.id === 12 || currentUser.id === 13 || currentUser.id === 17 || currentUser.id === 18 );         
      return isUserValid;
    },
    async confirmSendSAP(row) {  
      if (this.canConfirm()) {
      console.log('confirmSend called with row:', row);      
      const formData = {
        id: row.id,
        tableName: 'scrapmateriallog',
        columnMapping: this.columnMapping,
        values: {
          year: row.year,
          month: row.month,
          day: row.day,                                                
          factory: row.factory,                             
          materialcode: row.materialcode,            
          materialname: row.materialname,    
          quantity: row.quantity,
          unit: row.unit,
          workorder: row.workorder,
          remark: row.remark,
          sap: "Done",
          realscrap: row.realscrap,            
        }
      };

      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

        if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
        }

        formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
        console.log('formData:', formData);
            console.log('Mapping:', this.columnMapping);
        try {
            const response = await axios.put(`/api/materialput/${row.id}`, formData);
            console.log('Server response:', response);
            console.log('Data updated successfully');          
          } catch (error) {
            console.error('Error updating data:', error);
          }
      }
    },

    async confirmSendReal(row) {  
      if (this.canConfirm()) {
      console.log('confirmSend called with row:', row);      
      const formData = {
        id: row.id,
        tableName: 'scrapmateriallog',
        columnMapping: this.columnMapping,
        values: {
          year: row.year,
          month: row.month,
          day: row.day,                                                
          factory: row.factory,                             
          materialcode: row.materialcode,            
          materialname: row.materialname,    
          quantity: row.quantity,
          unit: row.unit,
          workorder: row.workorder,
          remark: row.remark,
          sap: row.sap,
          realscrap: "Done",            
        }
      };

      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

        if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
        }

        formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
        console.log('formData:', formData);
            console.log('Mapping:', this.columnMapping);
        try {
            const response = await axios.put(`/api/materialput/${row.id}`, formData);
            console.log('Server response:', response);
            console.log('Data updated successfully');          
          } catch (error) {
            console.error('Error updating data:', error);
          }
      }
    },  
    canClick() {      
      const currentUser = this.$route.meta.currentUser;
      if (currentUser) {          
          return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 17 || currentUser.id === 18;
      } else {
          return false;
      }
    },
  },
  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const factory = ref();
    const prTableName = 'scrapproductlog';
    const maTableName = 'scrapmateriallog';
    const mlTableName = "materialList";        
    const prHeader = [ 'Year', 'Month', 'Day', 'Product Code', 'Product Name', 'Quantity', 'Unit' ];
    const maHeader = [ 'Year', 'Month', 'Day', 'Material Code', 'Material Name', 'Quantity', 'Unit' ];
    
    //각 설비별 Data를 받아올 함수 명을 입력해야 됨
    const prTableData = reactive({ data: [], });      
    const maTableData = reactive({ data: [], });   
    const materialList = reactive({ data: [], })   

    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, factory.value]; return {sendDateData};};     
    const prSearchData = async (tableName) => {
        const sendDateData = await sendDate();
        console.log('Requesting data with:', {
            tablename: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endtYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
            factory: sendDateData.sendDateData[6],
        });
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
            factory: sendDateData.sendDateData[6],
        };  
      console.log('Requesting data with:', formData);
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { prTableData.data = res.data; });
    };  

    const maSearchData = async (tableName) => {
        const sendDateData = await sendDate();
        console.log('Requesting data with:', {
            tablename: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endtYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
            factory: sendDateData.sendDateData[6],
        });
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
            factory: sendDateData.sendDateData[6],
        };  
      console.log('Requesting data with:', formData);
      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { maTableData.data = res.data; });
    };  

    const materialListSearchData = async () => {
      const formData = { tableName: mlTableName, };            

      axios.get('/api/targetList', { params: formData })
        .then((res) => {                
          materialList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
          })
        .catch((error) => {
          console.error("Error fetching target data:", error.message);
        });
      };
 
    const callSearchData = () => {    
      prSearchData(prTableName); 
      maSearchData(maTableName); 
      materialListSearchData(mlTableName);
      settingCall();      
    };   
 
    const prItem = computed(() => {return prTableData.data.map(d => {return { ...d,};});});        
    
    const maItem = computed(() => {
      return maTableData.data.map(d => {
        const materialInfo = materialList.data.find(m => m.materialcode === d.materialcode && m.copperweight);
        if (!materialInfo) return { ...d, coatingcopper: null, purecopper: null };

        const quantityMultiplied = d.quantity * materialInfo.copperweight;
        const isCoated = materialInfo.coating && materialInfo.coating.trim() !== '';

        return {
          ...d,
          coatingcopper: isCoated ? quantityMultiplied : null,
          purecopper: !isCoated ? quantityMultiplied : null
        };
      });
    });

    const totalPureCopper = computed(() => {
      const total = maItem.value.reduce((acc, item) => acc + (item.purecopper || 0), 0);      
      return total !== 0 ? total.toFixed(2) : total.toString();
    });

    const totalCoatingCopper = computed(() => {
      const total = maItem.value.reduce((acc, item) => acc + (item.coatingcopper || 0), 0);      
      return total !== 0 ? total.toFixed(2) : total.toString();
    });
    const settingData = reactive({ data: [], });        

    const settingCall = async () => {         
        const tableName ='setting';
        const formData = { tableName: tableName };
        axios.get('/api/targetList', { params: formData })
        .then((res) => { settingData.data = res.data; })
        .catch((error) => { console.error("Error download excel data:", error.message); });
    };
      
    const Excel = () => {
      const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
      const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
      if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
      const wb = XLSX.utils.book_new();
      
      const request_wsPr = XLSX.utils.json_to_sheet(prItem.value, { header: prHeader.value });          
      const request_wsMa = XLSX.utils.json_to_sheet(maItem.value, { header: maHeader.value });          

      XLSX.utils.book_append_sheet(wb, request_wsPr, "Product");          
      XLSX.utils.book_append_sheet(wb, request_wsMa, "Material");      
      
      const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      saveAs(blob, "Scrap Log.xlsx");
    };     

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay, factory,
      prTableName, maTableName, prItem, maItem, materialList,
      prSearchData, maSearchData, materialListSearchData, callSearchData, sendDate, Excel,   
      totalPureCopper, totalCoatingCopper
    };
  },
};
</script>

<style scoped>
   * { box-sizing: border-box; }
  .container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: auto; margin-right: auto;}
  .start,.end { display: flex; flex-direction: row; border: 0px;}
  .factory {  border: 0px;}
  .start input,.end input, .factory select { margin-right: 5px; }
  .separator { font-size: 24px; margin: 0 10px; border: 0px; }
  .searchTd { border: 0px; }

  input::placeholder { color: gray; }

  label { font-size: 14px; font-weight: 600; margin-right: 8px; }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px; border: 1px solid #ccc;
    border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px;
  }

  button {
    padding: 5px 5px; background-color: #3498db; color: #fff;
    border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer; transition: background-color 0.3s;
  }
 .excelButton {
  padding: 5px 5px; background-color: #347003; color: #fff;
  border: none; border-radius: 4px; font-size: 15px; font-weight: 600;
  cursor: pointer; transition: background-color 0.3s;
  }
  .excelButton:hover{ background-color: #009625; }
  button:hover { background-color: #2980b9; }
  .del {
    padding: 4px 4px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 10px; font-weight: 600; cursor: pointer; transition: background-color 0.3s;
  }
  .RequestLog{ width: 700px; }
  .searchBar {
    display: flex; flex-wrap: nowrap; justify-content: flex-start;
    align-items: center; margin-bottom: 20px; margin-left: 20px;
  }
  .searchBar > *:not(:last-child) { margin-right: 5px; }
  .searchBar select,.searchBar input { width : 100px; margin-right: 5px; padding : 5px; }
  label { font-size: 14px; font-weight: 600; margin-right: 8px; }

  td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }

  th {
      background-color: rgb(230, 230, 230) ;
      border : 1px solid black; text-align : center;
      padding-left : 10px; padding-right : 10px; font-size : 12px; 
  }
  .tables { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; }

  table { width: 80%; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .disabledButton { background-color: gray !important; cursor: not-allowed !important; }
  .confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
  }
  .gray-background { background-color: gray;}  
</style>