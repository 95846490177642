<template>
  <div class="container">        
    <div class="RequestLog">
      <h class="pagetitle">Arduino Log</h>        
      <form @submit.prevent="sendDate()" class="searchBar">
          <table>  
              <tr>
                  <td class="start">
                      <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
                      <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
                      <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
                  </td>
                  <td class="separator">~</td>
                  <td class="end">
                      <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
                      <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
                      <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
                  </td>  
                  <td class="searchTd">
                      <button type="submit" @click="callSearchData">Search</button>                      
                  </td>
                  <td class="searchTd">
                      <button class="excelDownload" @click="downExcel">Excel</button>                                              
                  </td>
              </tr>
          </table>      
      </form>
    </div>            
    <div class="tables">
        <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
        <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers"> 
              <td v-if="header === 'Year'" :key="index" >{{ row.year}}</td>
              <td v-if="header === 'Month'" :key="index" >{{ row.month }}</td>
              <td v-if="header === 'Day'" :key="index" >{{ row.day }}</td>
              <td v-if="header === 'Hour'" :key="index" >{{ row.hour }}</td>
              <td v-if="header === 'Minute'" :key="index" >{{ row.minute }}</td>
              <td v-if="header === 'Second'" :key="index" >{{ row.second }}</td>
              <td v-if="header === 'Value 1'" :key="index" >{{ row.value1 }}</td>
              <td v-if="header === 'Value 2'" :key="index" >{{ row.value2 }}</td>
              <td v-if="header === 'Value 3'" :key="index" >{{ row.value3 }}</td>     
            </template>              
        </tr>
  </table>    
  </div>
</div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import { downloadExcel } from "../utils.js"; 

export default {

data() {
  return {    
    columnMapping: {            
      year: 'year', month: 'month', day: 'day', fromlocation: 'fromlocation',
      tolocation: 'tolocation', materialcode: 'materialcode',
      materialname: 'materialname', unit: 'unit', quantity: 'quantity', 
      batch: 'batch', confirm: 'confirm', sap: 'sap' , remark: 'remark'
    }
  }
},

methods: {
  downExcel() { downloadExcel(this.item, "Arduino_Log.xlsx"); },  
},

setup() {
  const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();

  const dbTableName = 'arduinolog';  
  const headers = ['Year', 'Month','Day', 'Hour', 'Minute', 'Second', 'Value 1', 'Value 2', 'Value 3' ];    
  const tableData = reactive({ data: [], });      

  const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value]; return {sendDateData};};     
  const searchData = async (tableName) => {
      const sendDateData = await sendDate();
      console.log('Requesting data with:', {
          tablename: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endtYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
      });
      const formData = {        
          tableName: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
      };  
    console.log('Requesting data with:', formData);
    axios.get('/api/searchPeriod', { params: formData })
      .then((res) => { tableData.data = res.data; });
  };    
  
  const callSearchData = () => { searchData(dbTableName); };
  
  const item = computed(() => {return tableData.data.map(d => {return { ...d,};});});     

  return {
    startYear, startMonth, startDay, endYear, endMonth, endDay, 
    headers, dbTableName, item,
    searchData, callSearchData, sendDate,   
  };
},
};
</script>

<style scoped>
 * { box-sizing: border-box;}

.container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: auto; margin-right: auto;}
.start,.end { display: flex; flex-direction: row; border: 0px;}
.materialcode {border: 0px;}
.start input,.end input,.materialcode input { margin-right: 5px;}

.separator { font-size: 24px; margin: 0 10px; border: 0px;}
.searchTd { border: 0px;}

input::placeholder { color: gray;}

label { font-size: 14px; font-weight: 600; margin-right: 8px;}

select,input[type="number"],input[type="text"] {
  padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
  font-size: 14px; width: 100%; max-width: 120px;
} 

button {
  padding: 5px 5px; background-color: #3498db;
  color: #fff; border: none;
  border-radius: 4px; font-size: 14px;
  font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}
.del {
  padding: 4px 4px;
  background-color: #bfbebe;
  color: #000000; border: 10px; border-color: #000000;
  font-size: 10px; font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}

button:hover { background-color: #2980b9;}

.excelDownload {
  background-color: #347003;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
  padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
}
.excelDownload :hover { background-color: #009625; }  

.RequestLog{ width: 500px;}
.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 20px; margin-left: 20px; 
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }

.searchBar select,.searchBar input{ width : 120px; margin-right: 5px; padding : 5px; } 

label { font-size: 14px; font-weight: 600; margin-right: 8px; }

td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }

th { 
  position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ;
  border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; 
}
.tables {
  display: flex; flex-direction: column; align-items: stretch;
  padding-left : 20px; padding-right : 20px;
}

table { width: 80%; }
.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; }
.disabledButton { background-color: gray !important; cursor: not-allowed !important; }
.confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.gray-background { background-color: gray;}  

</style>