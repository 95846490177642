<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Daily Report</h3>          
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group">
              <label for="id">ID(Use when chaning data):</label>
              <input type="number" id="id" v-model="formInputs.id" />                
            </div>            
            <div class="form-group date-time-group">
                <label>Comment Date:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div>
              </div>
            <div class="form-group">
              <label for="line_name">Line Name:</label>
              <select type="text" id="line_name" v-model="formInputs.line_name">
                <option>BMW48V</option><option>BMW12V</option><option>FCA 1</option><option>FCA 2</option>
                <option>FCA FLEX BC</option><option>FCA FLEX PO</option><option>FCA FLEX 67</option>
                <option>FCA FLEX NE</option><option>FCA FLEX 45</option>
                <option>BT6</option><option>Ford 1 Front</option><option>Ford 1 Rear</option>
                <option>Ford 2 Front</option><option>Ford 2 Rear</option><option>Ford 3 Front</option><option>Ford 3 Rear</option>
                <option>TR2 SMT</option><option>TR2 Crimping</option><option>TR2 FPCA</option><option>TR2 BFA 2P</option><option>TR2 BFA 3P</option>
                <option>TR3 SMT</option><option>TR3 Crimping</option><option>TR3 FPCA</option><option>EVO 1</option><option>EVO 2</option>
                <option>EVO Hybrid</option><option>EV2020 60FR</option><option>EV2020 60RR</option>
                <option>EV2020 4060FR</option><option>EV2020 4060RR</option>              
              </select>
            </div>                                  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="problem">Problem:</label>
              <textarea id="problem" v-model="formInputs.problem" rows="4" cols="25"></textarea>
            </div>                 
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="countermeasure">Countermeasure:</label>
              <textarea id="countermeasure" v-model="formInputs.countermeasure" rows="4" cols="25"></textarea>
            </div>                 
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="button" id="save" @click="submitForm" >Save</button>
            <button type="button" id="modify" @click="modifyForm" >Modify</button>
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "dailyreportcomment"                  

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          id: "",
          line_name: "",          
          problem: "",
          countermeasure: "",
        });
        // -끝-   

      
      //년.월.일을 지금 시간으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
       const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate() -1 ;

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝

        //input, select에 있는 id 이름과 database table 이름을 맞추는거, 
        //그래서 필히 id 이름이 database table 이름과 동일해야 함
        //시작 
        const getColumnMapping = () => {
          const inputs = document.querySelectorAll("input, select, textarea");
          const filteredInputs = Array.from(inputs).filter(input => input.id !== 'id');
          const mapping = {};

          filteredInputs.forEach(input => {
              let inputId = input.id;             
              if (!mapping[inputId]) { mapping[inputId] = input.id; }
            });  
            return mapping;
        };
        //끝 

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day) {
            alert("Please fill in all the fields: year, month, and day.");
            return;
          }
          const columnMapping = getColumnMapping();

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              line_name: formInputs.line_name,
              problem: formInputs.problem,              
              countermeasure: formInputs.countermeasure,              
            }
         };         
          await axios.post('/api/post', formData);         
          closeModal();
        };

        const modifyForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day) {
            alert("Please fill in all the fields: year, month, and day.");
            return;
          }
          const columnMapping = getColumnMapping();
          const formData = {
            id: formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {              
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,              
              line_name: formInputs.line_name,
              problem: formInputs.problem,              
              countermeasure: formInputs.countermeasure,              
            }
         }; 
         const id = formInputs.id;
              if (!id) {
                alert("ID is missing");
                return;
              }              
          await axios.put(`/api/materialput/${id}`, formData);          
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    



  
      return { searchDate, dbTableName, formInputs, submitForm, closeModal, modifyForm };

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 


};
  
</script>
    
<style scoped>
    
    /* Reset */
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper {
      width: 100%;
      max-width: 400px;
    }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 20px;
      max-height: 80vh;
      overflow-y: auto;
    }
    
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
  
    .modal-header h3 {
      font-size: 1.5rem;
    }
  
    .close-button {
      background-color: transparent;
      border: none;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
    }
  
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 15px;
    }
  
    label {
      font-weight: bold;
    }
  
    input, textarea, select {
      font-size: 1rem;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  
    input[type="number"] {
      width: 100%;
    }
  
    .time-inputs {
      display: flex;
      gap: 5px;
    }
  
    .time-inputs input[type="number"] {
      width: 50px;
    }
  
    .readWindow[readonly] {
      background-color: #f0f0f0;
    }
  
    button[type="button"] {
      background-color: #4CAF50;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      transition-duration: 0.4s;
    }
  
    button[type="button"]:hover {
      background-color: #45a049;
    } 
    .date-time-group {
  display: flex;
  align-items: left;
}

.date-time-inputs {
  display: flex;
  gap: 5px;
}

.date-time-inputs input {
  width: 85px;
  size: 8px;
}
</style>