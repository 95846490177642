<template>
    <div class="maxpowerdb">            
        <h class="pagetitle">
            Attendance Search All
            <span class="duplicate" v-if="duplicatedRows.length > 0">Duplicate</span>
        </h>
        <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">            
                <div class="start">
                    <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                    <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                    <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
                </div>
                <div class="separator">~</div>
                <div class="end">
                    <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                    <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                    <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
                </div>
                <div class="searchtarget">
                    Team :
                    <select class="searchtargetselect" v-model="team" placeholder="Team">
                        <option>Production 1st</option><option>Production 2nd</option><option>QA</option><option>Warehouse</option><option>Inkatom</option>
                        <option></option>
                    </select>
                </div>
                <div class="searchtarget">
                    OS Company :
                    <select class="searchtargetselect" v-model="oscompany" placeholder="OS Company">
                        <option>MAX POWER</option><option>MEGA STAFF</option><option>ZYX</option><option>Inkatom</option>
                        <option></option>
                    </select>
                </div>
                <div class="searchTd">
                    <button type="submit" @click="callSearchData">Search</button>                        
                </div>                    
                <div class="searchTd">
                    <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
                </div>            
            </div>      
        </form>   
        </div>     
        <div class="table">
            <table>
                <tr>
                    <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                </tr>                    
                <!--<tr v-for="row in item" :key="row.id" :class="{ 'duplicated-row': isDuplicated(row.id) }">-->
                    <tr v-for="row in item" :key="row.id" :class="{ 'duplicated-row': isDuplicated(row.id), 'duplicate-ok': !isDuplicated(row.id) && isDuplicateOk(row.duplicateconfirm) }">
                    <td>{{ row.factory }}</td>
                    <td>{{ row.team }}</td>
                    <td>{{ row.year }}</td>
                    <td>{{ row.month }}</td>
                    <td>{{ row.day }}</td>
                    <td>{{ row.shift }}</td>
                    <td>{{ row.oscompany }}</td>
                    <td>{{ row.employeenumber }}</td>                    
                    <td>{{ row.name }}</td>
                    <td>{{ row.surname }}</td>
                    <td>{{ row.line }}</td>
                    <td>{{ row.process }}</td>
                    <td>{{ row.position }}</td>
                    <td>{{ row.start_time }}</td>
                    <td>{{ row.end_time }}</td>                    
                    <td>{{ row.worktime }}</td>
                    <td>{{ row.remark }}</td>                    
                    <td><button class="sendButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>

                    <td><button class="duplicateConfirm" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="duplicateClick(row)">Confirm</button></td>
                    <td><button class="duplicateCancel" :class="{ disabledButton: !canConfirm() }"  type="submit"  @click="duplicateCancelClick(row)" :disabled="!canConfirm()">Cancel</button> </td>
                    <td>{{ row.duplicateconfirm }}</td>   

                    <td><button class="confirmButton" :class="{ disabledButton: !canConfirm() }"  type="submit"  @click="handleClick(row)" :disabled="!canConfirm()">Confirm</button> </td>                  
                    <td><button class="cancelButton" :class="{ disabledButton: !canConfirm() }"  type="submit"  @click="cancelClick(row)" :disabled="!canConfirm()">Cancel</button> </td>
                    <td><button :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>                    
                    <td>{{ row.confirm }}</td>
                </tr>
            </table>                        
        </div>
        <div class="countTable">
            <table>
                <tr><th>shift</th><th>Line</th><th>Process</th><th>Position</th><th>Count</th></tr>
                <tr v-for="row in countData" :key="row.shift + row.line + row.process + row.position">
                    <td>{{ row.shift }}</td><td>{{ row.line }}</td><td>{{ row.process }}</td><td>{{ row.position }}</td><td>{{ row.count }}</td>
                </tr>
            </table>
        </div> 
        <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                             
    </div>
</template>

<script>
import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import modifyModal from "./attendanceModify.vue";
import { deleteRow, downloadExcel} from "../utils.js"; 

export default {  
    
    data() {
        return {    
          columnMapping: {            
            factory: "factory",
            year: 'year',
            month: 'month',
            day: 'day',
            shift: "shift",
            oscompany: "oscompany",
            employeenumber: "employeenumber",
            name : "name",
            surname : "surname", 
            team : "team",         
            line : "line",
            process : "process",
            position : "position",
            start_time : "start_time",      
            end_time : "end_time",
            worktime : "worktime",
            remark : "remark",
            duplicateconfirm : "duplicateconfirm",
            confirm: "confirm"     
          }
        }
    },    

    methods: {
        openSendModal(row) { this.selectedRowId = row.id;  this.showMoModal = true;  },
        handleClick(row) { this.confirmSend(row); this.callSearchData(); },
        cancelClick(row) { this.cancelSend(row); this.callSearchData(); },
        duplicateClick(row) { this.duplicateConfirmSend(row);  this.callSearchData(); },
        duplicateCancelClick(row) { this.duplicateCancelSend(row); this.callSearchData();},

        canClick(row) {            
            const currentUser = this.$route.meta.currentUser;            

            if (!currentUser) { return false; }            
            if (row.confirm === "Confirmed") {                
                return currentUser.id === 1 || currentUser.id === 2;
            }             
            else {              
                switch (row.oscompany) {
                    case "MAX POWER":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 4 || currentUser.id === 5 || currentUser.id === 8 || currentUser.id === 21 || currentUser.id === 22 || currentUser.id === 17;
                    case "MEGA STAFF":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 4 || currentUser.id === 5 || currentUser.id === 9 || currentUser.id === 23 || currentUser.id === 24 || currentUser.id === 17;
                    case "ZYX":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 4 || currentUser.id === 5 || currentUser.id === 10 || currentUser.id === 25 || currentUser.id === 26 || currentUser.id === 17;
                    case "INKATOM":
                        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 4 || currentUser.id === 5 || currentUser.id === 19 || currentUser.id === 17;
                    default:
                        return currentUser.id === 1;
                }
            }
        },

        canConfirm() {            
            const currentUser = this.$route.meta.currentUser;            
            if (currentUser) {return currentUser.id === 1 || currentUser.id === 2;}
            else { return false;}
        },

        async confirmSend(row) {         
            if (this.canConfirm()) {
            console.log('confirmSend called with row:', row);
                const formData = {
                    id: row.id,
                    tableName: 'attendance',
                    columnMapping: this.columnMapping,
                    values: {
                        factory: row.factory,
                        year: row.year,
                        month: row.month,
                        day: row.day,
                        shift: row.shift,
                        oscompany : row.oscompany,
                        employeenumber : row.employeenumber,              
                        name : row.name,
                        surname : row.surname,
                        team : row.team,
                        line : row.line,
                        process : row.process,
                        position : row.position,
                        start_time : row.start_time,      
                        end_time : row.end_time,
                        worktime : row.worktime,
                        remark : row.remark,
                        duplicateconfirm : row.duplicateconfirm,
                        confirm: 'Confirmed',           
                    }
                };

                const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

                if (hasUndefinedValue) {
                    console.error('Undefined value found in formData.values:', formData.values);
                    return;
                }

                formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
                console.log('formData:', formData);
                console.log('Mapping:', this.columnMapping);
                try {
                    const response = await axios.put(`/api/materialput/${row.id}`, formData);
                    console.log('Server response:', response);
                    console.log('Data updated successfully');          
                } catch (error) {
                    console.error('Error updating data:', error);
                }
            }
        },
        async cancelSend(row) {         
            if (this.canConfirm()) {
            console.log('confirmSend called with row:', row);
                const formData = {
                    id: row.id,
                    tableName: 'attendance',
                    columnMapping: this.columnMapping,
                    values: {
                        factory: row.factory,
                        year: row.year,
                        month: row.month,
                        day: row.day,
                        shift: row.shift,
                        oscompany : row.oscompany,
                        employeenumber : row.employeenumber,              
                        name : row.name,
                        surname : row.surname,
                        team : row.team,
                        line : row.line,
                        process : row.process,
                        position : row.position,
                        start_time : row.start_time,      
                        end_time : row.end_time,
                        worktime : row.worktime,
                        remark : row.remark,
                        duplicateconfirm : row.duplicateconfirm,
                        confirm: 'not yet',           
                    }
                };

                const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

                if (hasUndefinedValue) {
                    console.error('Undefined value found in formData.values:', formData.values);
                    return;
                }

                formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
                console.log('formData:', formData);
                console.log('Mapping:', this.columnMapping);
                try {
                    const response = await axios.put(`/api/materialput/${row.id}`, formData);
                    console.log('Server response:', response);
                    console.log('Data updated successfully');          
                } catch (error) {
                    console.error('Error updating data:', error);
                }
            }
        },
        async duplicateConfirmSend(row) {         
            if (this.canClick(row)) {
            console.log('confirmSend called with row:', row);
                const formData = {
                    id: row.id,
                    tableName: 'attendance',
                    columnMapping: this.columnMapping,
                    values: {
                        factory: row.factory,
                        year: row.year,
                        month: row.month,
                        day: row.day,
                        shift: row.shift,
                        oscompany : row.oscompany,
                        employeenumber : row.employeenumber,              
                        name : row.name,
                        surname : row.surname,
                        team : row.team,
                        line : row.line,
                        process : row.process,
                        position : row.position,
                        start_time : row.start_time,      
                        end_time : row.end_time,
                        worktime : row.worktime,
                        remark : row.remark,
                        duplicateconfirm : "Duplicate_OK",
                        confirm: row.confirm,        
                    }
                };

                const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

                if (hasUndefinedValue) {
                    console.error('Undefined value found in formData.values:', formData.values);
                    return;
                }

                formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
                console.log('formData:', formData);
                console.log('Mapping:', this.columnMapping);
                try {
                    const response = await axios.put(`/api/materialput/${row.id}`, formData);
                    console.log('Server response:', response);
                    console.log('Data updated successfully');          
                } catch (error) {
                    console.error('Error updating data:', error);
                }
            }
        },
        async duplicateCancelSend(row) {         
            if (this.canConfirm()) {
            console.log('confirmSend called with row:', row);
                const formData = {
                    id: row.id,
                    tableName: 'attendance',
                    columnMapping: this.columnMapping,
                    values: {
                        factory: row.factory,
                        year: row.year,
                        month: row.month,
                        day: row.day,
                        shift: row.shift,
                        oscompany : row.oscompany,
                        employeenumber : row.employeenumber,              
                        name : row.name,
                        surname : row.surname,
                        team : row.team,
                        line : row.line,
                        process : row.process,
                        position : row.position,
                        start_time : row.start_time,      
                        end_time : row.end_time,
                        worktime : row.worktime,
                        remark : row.remark,
                        duplicateconfirm : "",
                        confirm: row.confirm,          
                    }
                };

                const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

                if (hasUndefinedValue) {
                    console.error('Undefined value found in formData.values:', formData.values);
                    return;
                }

                formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
                console.log('formData:', formData);
                console.log('Mapping:', this.columnMapping);
                try {
                    const response = await axios.put(`/api/materialput/${row.id}`, formData);
                    console.log('Server response:', response);
                    console.log('Data updated successfully');          
                } catch (error) {
                    console.error('Error updating data:', error);
                }
            }
        },
        downExcel() { downloadExcel(this.item, "Attendance.xlsx"); },
        delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
    },

    components: { modifyModal },     

    setup() {        
        const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const team = ref(); const oscompany = ref();
        const dbTableName = 'attendance';    
        const showMoModal = ref(false);    
        const headers = ['Factory', 'Team', 'Year', "Month", "Day", 'shift', 'OS Company',  'Employee Number',  
                        'First Name', 'Surname', 'Line', 'Process', 'Position', 'Start', 'End', 'Work Time', 'Remark', 'Modify', 'Duplicate', 'Duplicate', 'Duplicate', 'Confirm', 'Cancel', 'DEL', 'Confirmed'];

        const dbData = reactive({ data: [], });
        
        const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, team.value, oscompany.value]; return {sendDateData};};     
        const searchData = async (tableName) => {
            const sendDateData = await sendDate();
            console.log('Requesting data with:', {
                tablename: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endtYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],
                team: sendDateData.sendDateData[6],
                oscompany: sendDateData.sendDateData[7],
            });
            const formData = {        
                tableName: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],
                team: sendDateData.sendDateData[6],
                oscompany: sendDateData.sendDateData[7],
            };  
            console.log('Requesting data with:', formData);
            axios.get('/api/searchPeriod', { params: {...formData },})
            //axios.get('/api/searchPeriod', { params: {...formData },})
                .then((res) => {
                    dbData.data = res.data.sort((a, b) => {
                        if (a.month < b.month) return -1; if (a.month > b.month) return 1;  
                        if (a.day < b.day) return -1; if (a.day > b.day) return 1;  
                        if (a.shift < b.shift) return -1; if (a.shift > b.shift) return 1;
                        if (a.team < b.team) return -1; if (a.team > b.team) return 1;
                        if (a.line < b.line) return -1; if (a.line > b.line) return 1;
                        if (a.oscompany < b.oscompany) return -1; if (a.oscompany > b.oscompany) return 1;                        
                        if (a.name < b.name) return -1; if (a.name > b.name) return 1;

                        return 0;
                    });
                });
        }

        const settingData = reactive({ data: [], });      

        const callSearchData = async () => {         
            const tableName = 'setting';
            const formData = { tableName: tableName };

            try {
                const res = await axios.get('/api/targetList', { params: formData });
                settingData.data = res.data;

                const attendanceSetting = settingData.data.find(s => Object.hasOwn(s, 'attendance'));  
                const isAttendanceEnabled = attendanceSetting && attendanceSetting.attendance === 1;
                if (!isAttendanceEnabled) {
                    alert("Attendance is currently disabled.");
                    return;
                }

                searchData(dbTableName);                           
            
            } catch (error) {
                console.error("Error attendance:", error.message);
            }
        };     

        const item = computed(() => {return dbData.data.map(d => {return { ...d,};});});            

        const countData = computed(() => {
            const counts = {};
            item.value.forEach(row => {
                const key = `${row.shift}|${row.line}|${row.process}|${row.position}`;
                if (counts[key]) {
                    counts[key] += 1;
                } else {
                    counts[key] = 1;
                }
            });

            let results = Object.entries(counts).map(([key, value]) => {
                const [shift, line, process, position] = key.split('|');
                return { shift: Number(shift), line, process, position, count: value };
            });

            results.sort((a, b) => {
                if (a.shift !== b.shift) return a.shift - b.shift;
                if (a.line !== b.line) return a.line.localeCompare(b.line);
                if (a.process !== b.process) return a.process.localeCompare(b.process);
                if (a.position !== b.position) return a.position.localeCompare(b.position);
                return 0;
            });

            return results;
        });

        const duplicatedRows = computed(() => {
            const counts = {};
            item.value.forEach(row => {
                const key = `${row.year}|${row.month}|${row.day}|${row.employeenumber}`;
                if (counts[key]) {
                    counts[key].push(row);
                } else {
                    counts[key] = [row];
                }
            });

            // 중복 키 찾기
            const duplicateKeys = Object.keys(counts).filter(key => {
                // 해당 키에 해당하는 모든 항목들의 'duplicateconfirm' 값이 "Duplicate_OK"인지 확인
                return counts[key].length > 1 && !counts[key].every(row => row.duplicateconfirm === "Duplicate_OK");
            });

            // 중복된 ID들을 단일 배열로 변환
            return duplicateKeys.flatMap(key => counts[key].map(row => row.id));
        });

        const isDuplicated = (id) => { return duplicatedRows.value.includes(id); };
        const isDuplicateOk = (duplicateConfirmValue) => {return duplicateConfirmValue === "Duplicate_OK"; };

        return { headers, item, callSearchData, dbTableName, deleteRow, showMoModal,
                startYear, startMonth, startDay, endYear, endMonth, endDay, team, oscompany, sendDate, countData, duplicatedRows, isDuplicated, isDuplicateOk}        
    }}

</script>

<style scoped>     
    .searchtarget {border: 0px; }
    .searchtargetselect {width : 200px}
    .sendButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none;
                border-radius: 4px; font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px;
                margin-left: 5px; margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .confirmButton { padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
                    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .cancelButton { padding: 5px 5px; background-color: #f38f4d; color: #fff; border: none; border-radius: 4px; 
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px;
                    cursor: pointer; transition: background-color 0.3s;        
    }
    .duplicateConfirm { padding: 5px 5px; background-color: #0395a8; color: #fff; border: none; border-radius: 4px; 
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px;
                    cursor: pointer; transition: background-color 0.3s;        
    }
    .duplicateCancel { padding: 5px 5px; background-color: #0395a8; color: #fff; border: none; border-radius: 4px; 
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px;
                    cursor: pointer; transition: background-color 0.3s;        
    }
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .table { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload:hover { background-color: #009625; }

    button { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
    .start,.end { display: flex; flex-direction: row; border: 0px; }
    .start input,.end input { margin-right: 5px;}
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }
    .searchTd { border: 0px; }
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; }
    input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }
    .countTable {margin-left:20px; width: 50%; }
    .duplicated-row { background-color: #ffcccc;}
    .duplicate-ok { background-color: rgb(233, 233, 140); }
    .duplicate { font-size: 1.5em; font-weight: bold; color: red; margin-left: 10px;}
    .horizontal-form { margin-top: 10px; margin-bottom: 10px; margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>