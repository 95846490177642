<template>
    <div class="maxpowerdb">            
        <h class="pagetitle">Monthly Cost Each Line</h>
        <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">                
                <div class="start">
                    <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                    <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                    <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
                </div>
                <div class="separator">~</div>
                <div class="end">
                    <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                    <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                    <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
                </div>                    
                <div class="searchTd">
                    <button type="submit" @click="callSearchData">Search</button>                        
                </div>                    
                <div class="searchTd">
                    <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
                </div>                                                                 
            </div>      
        </form>   
        </div>     
        <div class="countTable">
            <table> 
                <tr class="tableTh">
                    <th colspan="2">Line</th><th>Time</th><th>Cost</th>
                </tr>
                <tr class="lineSum">
                    <td colspan="2" class="lineName">BMW SUM</td><td>{{item.bmwSumHour }} </td><td>{{item.bmwSumCost }}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">12V</td><td>{{item.bmw12vHour}}</td><td>{{item.bmw12vCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">48V</td><td>{{item.bmw48vHour}}</td><td>{{item.bmw48vCost}}</td>
                </tr>
                <tr class="lineSum">
                    <td colspan="2" class="lineName">FCA GSW</td><td>{{item.fcaSumHour}} </td><td>{{item.fcaSumCost }}</td>
                </tr>
                <tr class="normalTr">                    
                    <td class="emptyTd"></td><td class="lineName">ICB</td><td>{{item.fcaIcbHour}}</td><td>{{item.fcaIcbCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">F/B</td><td>{{item.fcaFbHour}}</td><td>{{item.fcaFbCost}}</td>
                </tr>
                <tr class="lineSum">
                    <td colspan="2" class="lineName">Ford CX727</td><td>{{item.fordCx727Hour }} </td><td>{{item.fordCx727Cost }}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">ICB</td><td>{{item.fordCx727Hour }} </td><td>{{item.fordCx727Cost }}</td>                    
                </tr>
                <tr class="lineSum">
                    <td colspan="2" class="lineName">RSA BT6</td><td>{{item.bt6Hour }} </td><td>{{item.bt6Cost }}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">ICB</td><td>{{item.bt6Hour }} </td><td>{{item.bt6Cost }}</td>
                </tr>
                <tr class="lineSum">
                    <td colspan="2" class="lineName">RSA EV2020</td><td>{{item.ev2020Hour }} </td><td>{{item.ev2020Cost }}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">BFA</td><td>{{item.ev2020Hour }} </td><td>{{item.ev2020Cost }}</td>
                </tr>
                <tr class="lineSum">
                    <td colspan="2" class="lineName">VW EVO</td><td>{{item.evoSumHour }} </td><td>{{item.evoSumCost }}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">VW LS1</td><td>{{item.evo1stHour}}</td><td>{{item.evo1stCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">VW LS2</td><td>{{item.evo2ndHour}}</td><td>{{item.evo2ndCost}}</td>
                </tr>
                <tr class="lineSum">
                    <td colspan="2" class="lineName">Common</td><td>{{item.commonHour }} </td><td>{{item.commonCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">LS1 Production</td><td>{{item.production1stHour}}</td><td>{{item.production1stCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">LS1 Quality</td><td>{{item.qa1stHour}}</td><td>{{item.qa1stCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">LS1 WH</td><td>{{item.wh1stHour}}</td><td>{{item.wh1stCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">LS2 Production</td><td>{{item.production2ndHour}}</td><td>{{item.production2ndCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">LS2 Quality</td><td>{{item.qa2ndHour}}</td><td>{{item.qa2ndCost}}</td>
                </tr>
                <tr class="normalTr">
                    <td class="emptyTd"></td><td class="lineName">LS2 WH</td><td>{{item.wh2ndHour}}</td><td>{{item.wh2ndCost}}</td>
                </tr>                
            </table>     
            
        </div>        
    </div>    
</template>

<script>
    import { reactive, ref, computed, onMounted } from 'vue';
    import axios from "axios";
    import { downloadExcel } from "../../utils.js"; 

export default {
    methods: {                
        downExcel() { downloadExcel(this.itemArray, "Monthly_Cost.xlsx"); },
    },   
    setup() {        
        const startYear = ref(); 
        const startMonth = ref(); 
        const startDay = ref(); 
        const endYear = ref(); 
        const endMonth = ref(); 
        const endDay = ref(); 
        const dbTableName = 'attendance';                  
        const dbData = reactive({ data: [], });                
        
        const sendDate = async () => {
            const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value];
            return {sendDateData};
        };     

        const searchData = async (tableName) => {
            const sendDateData = await sendDate();  
            const formData = {        
                tableName: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],                
            };   
            axios.get('/api/searchPeriod', { params: {...formData}})
                .then((res) => { 
                const filteredData = res.data.filter(row => row.oscompany !== "INKATOM");
                dbData.data = filteredData; 
            });   
        };

        const callSearchData = () => { searchData(dbTableName); };

        onMounted(() => { searchData(dbTableName); });        

        const item = computed(() => {
            const workHourSum = {};
            const costSum = {};
            
            dbData.data.forEach(row => {
                const key = `${row.year}|${row.month}|${row.factory}|${row.line}`;
                if (!workHourSum[key]) { workHourSum[key] = 0; costSum[key] = 0; }
                workHourSum[key] += Number(row.worktime) / 60;
                costSum[key] += Number(row.worktime) / 60 * Number(row.timecost);
            });
            
            function sumByKeyIncludes(keys) {
                let hourSum = 0;
                let costSumValue = 0; 
                Object.keys(workHourSum).forEach(key => {
                    keys.forEach(includeKey => {
                        if (key.includes(includeKey)) {
                            hourSum += workHourSum[key];                            
                            costSumValue += costSum[key]; 
                        }
                    });
                });
                return { hour: hourSum.toFixed(0), cost: costSumValue.toFixed(0) };
            }  

            function sumForCommonLine(line, factory, processes) {
                let hourSum = 0;
                let costSum = 0;

                dbData.data.forEach(row => {                    
                    if (row.line === line && row.factory === factory && processes.includes(row.process)) {
                        hourSum += Number(row.worktime) / 60;
                        costSum += Number(row.worktime) / 60 * Number(row.timecost);
                    }
                });

                return { hour: hourSum.toFixed(0), cost: costSum.toFixed(0) };
            }
            
            const bmwSum = sumByKeyIncludes(["BMW12V", "BMW48V"]);
            const bmw12v = sumByKeyIncludes(["BMW12V"]);
            const bmw48v = sumByKeyIncludes(["BMW48V"]);
            const fcaSum = sumByKeyIncludes(["FCA 2", "FCA FLEX", "FCA FLEX BC", "FCA FLEX NE", "FCA FLEX PO", "FCA FLEX 67", "FCA FLEX 45"]);
            const fcaIcb = sumByKeyIncludes(["FCA 2"]);
            const fcaFb = sumByKeyIncludes(["FCA FLEX", "FCA FLEX BC", "FCA FLEX NE", "FCA FLEX PO", "FCA FLEX 67", "FCA FLEX 45"]);
            const fordCx727 = sumByKeyIncludes(["Ford 1 Front", "Ford 1 Rear", "Ford 2 Front", "Ford 2 Rear", "Ford 3 Front", "Ford 3 Rear",]);
            const bt6 = sumByKeyIncludes(["BT6"]);
            const ev2020 = sumByKeyIncludes(["EV2020 60kW", "EV2020 60FR", "EV2020 60RR", "EV2020 60", "EV2020 4060kW", "EV2020 4060FR", "EV2020 4060RR", "EV2020 4060",]);
            const evoSum = sumByKeyIncludes(["TR2 SMT", "TR2 Crimping", "TR2 FPCA", "TR3 SMT", "TR3 Crimping", "TR3 FPCA", "EVO Hybrid", "EVO 1", "EVO 2", "EVO 3", "EVO"]);                    
            const evo1st = sumByKeyIncludes(["TR2 SMT", "TR2 Crimping", "TR2 FPCA"]);                    
            const evo2nd = sumByKeyIncludes(["TR3 SMT", "TR3 Crimping", "TR3 FPCA", "EVO Hybrid", "EVO 1", "EVO 2", "EVO 3", "EVO"]);               
            const common = sumByKeyIncludes(["Common",]);               

            const production1st = sumForCommonLine("Common", 1, ["SAP", "Office", "Maintenance", "Production Logistics", "Scrap" ]);            
            const production2nd = sumForCommonLine("Common", 2, ["SAP", "Office", "Maintenance", "Production Logistics", "Scrap" ]);
            const qa1st = sumForCommonLine("Common", 1, ["Laboratory", "LQC", "IQC" ]);            
            const qa2nd = sumForCommonLine("Common", 2, ["Laboratory", "LQC", "IQC" ]);
            const wh1st = sumForCommonLine("Common", 1, ["Dispatch", "Logistics", ]);
            const wh2nd = sumForCommonLine("Common", 2, ["Dispatch", "Logistics", ]);  
                        
            return {                
                bmwSumHour: bmwSum.hour, bmwSumCost: bmwSum.cost,
                bmw12vHour: bmw12v.hour, bmw12vCost: bmw12v.cost,
                bmw48vHour: bmw48v.hour, bmw48vCost: bmw48v.cost,
                fcaSumHour: fcaSum.hour, fcaSumCost: fcaSum.cost,
                fcaIcbHour: fcaIcb.hour, fcaIcbCost: fcaIcb.cost,
                fcaFbHour: fcaFb.hour, fcaFbCost: fcaFb.cost,
                fordCx727Hour: fordCx727.hour, fordCx727Cost: fordCx727.cost,
                bt6Hour: bt6.hour, bt6Cost: bt6.cost,
                ev2020Hour: ev2020.hour, ev2020Cost: ev2020.cost,
                evoSumHour: evoSum.hour, evoSumCost: evoSum.cost,
                evo1stHour: evo1st.hour, evo1stCost: evo1st.cost,
                evo2ndHour: evo2nd.hour, evo2ndCost: evo2nd.cost,   
                commonHour: common.hour, commonCost: common.cost,
                production1stHour: production1st.hour, production1stCost: production1st.cost,            
                production2ndHour: production2nd.hour, production2ndCost: production2nd.cost,
                qa1stHour: qa1st.hour, qa1stCost: qa1st.cost,
                qa2ndHour: qa2nd.hour, qa2ndCost: qa2nd.cost,
                wh1stHour: wh1st.hour, wh1stCost: wh1st.cost,
                wh2ndHour: wh2nd.hour, wh2ndCost: wh2nd.cost,                                
            };
        });     

        const itemArray = computed(() => {
            const items = item.value; 
            return [
                { line: "BMW SUM", hour: items.bmwSumHour, cost: items.bmwSumCost },
                { line: "BMW 12V", hour: items.bmw12vHour, cost: items.bmw12vCost },
                { line: "BMW 48V", hour: items.bmw48vHour, cost: items.bmw48vCost },
                { line: "FCA GSW", hour: items.fcaSumHour, cost: items.fcaSumCost},
                { line: "FCA ICB" , hour: items.fcaIcbHour, cost: items.fcaIcbCost },
                { line: "FCA FB" , hour: items.fcaFbHour, cost: items.fcaFbCost },
                { line: "Ford CX727" , hour: items.fordCx727Hour, cost: items.fordCx727Cost },
                { line: "BT6" , hour: items.bt6Hour, cost: items.bt6Cost },
                { line: "EV2020" , hour: items.ev2020Hour, cost: items.ev2020Cost },
                { line: "EVO SUM" , hour: items.evoSumHour, cost: items.evoSumCost },
                { line: "EVO LS1" , hour: items.evo1stHour, cost: items.evo1stCost },
                { line: "EVO LS2" , hour: items.evo2ndHour, cost: items.evo2ndCost },
                { line: "Common" , hour: items.commonHour, cost: items.commonCost },
                { line: "LS1 Production" , hour: items.production1stHour, cost: items.production1stCost },
                { line: "LS1 Quality" , hour: items.qa1stHour, cost: items.qa1stCost },
                { line: "LS1 WH" , hour: items.wh1stHour, cost: items.wh1stCost },
                { line: "LS2 Production" , hour: items.production2ndHour, cost: items.production2ndCost },
                { line: "LS2 Quality" , hour: items.qa2ndHour, cost: items.qa2ndCost },
                { line: "LS2 WH" , hour: items.wh2ndHour, cost: items.wh2ndCost },
            ];
        });         

        return { 
            callSearchData, dbTableName, 
            startYear, startMonth, startDay, endYear, endMonth, endDay, sendDate, item, itemArray
        };        
    }
}
</script>

<style scoped> 
    .searchtarget {border: 0px; }
    .searchtargetselect {width : 200px}
    .sendButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none;
                border-radius: 4px; font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px;
                margin-left: 5px; margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .confirmButton { padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
                    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .cancelButton { padding: 5px 5px; background-color: #f38f4d; color: #fff; border: none; border-radius: 4px; 
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px;
                    cursor: pointer; transition: background-color 0.3s;        
    }
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .table { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    td { border : 1px solid black; text-align : right; padding-left : 30px; padding-right : 10px; font-size : 20px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 20px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .excelFile { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #a76106; color: #ffffff;
                 border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload:hover { background-color: #009625; }

    button { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto; margin-top: 10px;}
    .start,.end { display: flex; flex-direction: row; border: 0px; }
    .start input,.end input { margin-right: 5px;}
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }
    .searchTd { border: 0px; }
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; }
    input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }
    .countTable {margin-left:20px; width: 50%; margin-top: 10px;}
    .different { background-color: #ffcccc;}
    .emptyTd {width: 30px;}
    .lineSum {background-color : #d5e2f7; font-weight:bold; }  
    .lineName {text-align: left; padding-left : 10px; padding-right : 10px;} 
    .horizontal-form { margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>