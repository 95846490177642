<!-- 다운타임은 dbtablename과 linename 바꾸고, 내역 테이블 붙히고, class1,2,3 확인해서 바꾸면 끝남  -->
<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Downtime</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>                                    
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>
          </td>
          <td>   
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option>EVO_3P</option><option>2P12S</option><option>2P_GEN.1</option>
              </select>              
            </div>            
            <div class="form-group">
              <label for="start_hour">Start time</label>
              <div class="time-inputs">
                  <label for="start_hour">H:</label>
                  <input type="number" id="start_hour" v-model="startHour" min="0" max="23" />
                  <label for="start_minute">M:</label>
                  <input type="number" id="start_minute" v-model="startMinute" min="0" max="59" />
              </div>
            </div>            
            <div class="form-group">
              <label for="end_hour">End time</label>
              <div class="time-inputs">
                  <label for="end_hour">H:</label>
                  <input type="number" id="end_hour" v-model="endHour" min="0" max="23" />
                  <label for="end_minute">M:</label>
                  <input type="number" id="end_minute" v-model="endMinute" min="0" max="59" />
              </div>
            </div>  
            <div class="time">
              <label for="time">Time:</label>
              <input type="number" id="time" v-model="formInputs.time" readonly class="readWindow" />              
            </div>
          </td>
          <td>   
            <div class="form-group">
              <label for="class1">CLASS 1:</label>
              <select id="class1" v-model="formInputs.class1" @change="updateClass2Options()">            
                <option>Bd_B</option><option>CMA_B</option><option>NoL</option><option>PdM_B</option>
                <option>PvM</option><option>CoM</option><option>QRS</option><option>URS</option><option>MSH</option>
                <option></option>
              </select>              
            </div>
            <div class="form-group">
              <label for="class2">CLASS 2:</label>
              <select id="class2" v-model="formInputs.class2">            
                <option v-for="option in class2Options" :key="option">{{ option }}</option>
              </select>
            </div>                
            <div class="form-group">
              <label for="class3">CLASS 3:</label>
              <select id="class3" v-model="formInputs.class3">            
                <option>ALL</option><option>FS</option><option>LAS</option><option>FP</option>
                <option>MSE</option><option>BS</option><option>BH</option><option>BV</option><option>DT</option>
                <option>FPI</option><option>MOP</option><option>SAV</option><option>LWE</option><option>CHG</option>
                <option>WVN</option><option>EOL1</option><option>EOL2</option><option>MEE</option>
                <option>RCR</option><option>FIN</option><option>PC</option><option>CLR</option>
                <option>SEL</option><option>SFP</option><option>JC</option><option>GPE</option><option></option>
              </select>
            </div>
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="detail">Detail:</label>
              <textarea id="detail" v-model="formInputs.detail" rows="4" cols="25"></textarea>              
            </div>
            <div class="form-group">
              <label for="note">Note:</label>
              <input type="text" id="note" v-model="formInputs.note" readonly class="readWindow" /> 
            </div>       
          </td>
          </tr>
          </table>           
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref} from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {
    //setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "tr2bfa2pdt"      
        const lineName ="tr2bfa2p";    

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          shift: "",
          model: "",
          start_time: "",
          end_time: "",
          time: "",
          class1: "",
          class2: "",        
          class3: "",          
          detail: "",      
          note: "",    
        });
        // -끝-  
        

        const calculateTimeDifference = (start_time, end_time) => {
          if (!start_time || !end_time) {
            return "";
          }
          const [startHour, startMinute] = start_time.split(":").map(Number);
          const [endHour, endMinute] = end_time.split(":").map(Number);

          const startDate = new Date();
          startDate.setHours(startHour, startMinute);

          const endDate = new Date();
          endDate.setHours(endHour, endMinute);

          let timeDifference = (endDate - startDate) / 1000 / 60;

          if (timeDifference < 0) {
            timeDifference += 24 * 60;
          }

          return timeDifference;
        };

        //시작시간에 따라서 shift 및 시간 계산값 자동 입력 -시작-
        watch(
          () => [formInputs.start_time, formInputs.end_time],
          ([start_time, end_time]) => {
            // Update shift based on start_time
            const startHour = start_time.split(":")[0];
            formInputs.shift = startHour >= 6 && startHour <= 17 ? 1 : 2;

            // Update time based on start_time and end_time
            formInputs.time = calculateTimeDifference(start_time, end_time);
          }
        );
        //시작시간에 따라서 shift 및 시간 계산값 자동 입력 -끝-

        //시작시간과 끝시간을 시,분으로 나눠서 입력할 수 있게 하는거 -시작-
        const startHour = computed({
          get: () => {
            const [hour] = formInputs.start_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.start_time.split(":");
            formInputs.start_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const startMinute = computed({
          get: () => {
            const [, minute] = formInputs.start_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.start_time.split(":");
            formInputs.start_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });

        const endHour = computed({
          get: () => {
            const [hour] = formInputs.end_time.split(":");
            return parseInt(hour, 10);
          },
          set: (value) => {
            const [, minute] = formInputs.end_time.split(":");
            formInputs.end_time = `${value.toString().padStart(2, "0")}:${minute}`;
          },
        });

        const endMinute = computed({
          get: () => {
            const [, minute] = formInputs.end_time.split(":");
            return parseInt(minute, 10);
          },
          set: (value) => {
            const [hour] = formInputs.end_time.split(":");
            formInputs.end_time = `${hour}:${value.toString().padStart(2, "0")}`;
          },
        });
      //시작시간과 끝시간을 시,분으로 나눠서 입력할 수 있게 하는거 -끝-
      
      //년.월.일을 지금 시작으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
        const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝

        //input, select에 있는 id 이름과 database table 이름을 맞추는거, 
        //그래서 필히 id 이름이 database table 이름과 동일해야 함
        //시작 
        const getColumnMapping = () => {
          const inputs = document.querySelectorAll("input, select, textarea");
          const mapping = {};

          inputs.forEach(input => {
            let inputId = input.id;

            if (inputId === "start_hour" || inputId === "start_minute") {
              inputId = "start_time";
            } else if (inputId === "end_hour" || inputId === "end_minute") {
              inputId = "end_time";
            }

            if (!mapping[inputId]) {
              if (inputId === "start_time") {
                mapping[inputId] = "start_time";
              } else if (inputId === "end_time") {
                mapping[inputId] = "end_time";
              } else {
                mapping[inputId] = input.id;
              }
            }
          });

          return mapping;
        };
        //끝 

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
            alert("Please fill in all the fields: year, month, day and model.");
            return;
          }

          const columnMapping = getColumnMapping();

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              shift: formInputs.shift,
              model: formInputs.model,
              start_time: formInputs.start_time,
              end_time: formInputs.end_time,
              time: formInputs.time,
              class1: formInputs.class1,
              class2: formInputs.class2,
              class3: formInputs.class3,
              detail: formInputs.detail,     
              note: formInputs.note,     
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };
        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    

        const class2Options = ref([]); // Add this line to define class2Options as a ref

        //class1 에 따라 Cla22 선택 변경 -시작-
        const updateClass2Options = () => {
          if (formInputs.class1 === 'Bd_B') {
            class2Options.value = ['BRP', 'COP', 'MCE', 'RMC', 'COL', 'HUM', 'LOA', 'LTS', 'OTH', ''];
          } else if (formInputs.class1 === 'CMA_B') {
            class2Options.value = ['CDT', 'CBP', 'CTP', ''];
          } else if (formInputs.class1 === 'PdM_B') {
            class2Options.value = ['RHT', 'CHT', 'RLL', 'CLL', 'RPP', 'RCB', 'RMC', 'ROG', 'OTH', ''];
          } else if (formInputs.class1 === 'NoL') {
            class2Options.value = ['COR', 'CMO', 'CSH', 'MWU', 'TRA', 'AUD', 'TES', 'REW', ''];
          } else if (formInputs.class1 === 'PvM') {
            class2Options.value = ['TPM', 'CLE', 'CAL', 'OTH', ''];
          } else if (formInputs.class1 === 'CoM') {
            class2Options.value = ['TRN', 'TRF', 'TRD', 'TRR', ''];
          } else if (formInputs.class1 === 'QRS') {
            class2Options.value = ['QOA', 'QOM', ''];
          } else if (formInputs.class1 === 'URS') {
            class2Options.value = ['POW', 'AIR', ''];
          } else if (formInputs.class1 === 'MSH') {
            class2Options.value = [''];
          } else {
            class2Options.value = [''];
          }
        };

        //class1 에 따라 Cla22 선택 변경 -끝-

        watch(() => formInputs.class1, () => {
          updateClass2Options();
          combineValues();
        });

        watch(() => formInputs.class2, () => {
          combineValues();
        });

        watch(() => formInputs.class3, () => {
          combineValues();
        });

        const combineValues = () => {
          let formattedLineName = lineName.replace(/\s+/g, "").toLowerCase();
          let class1 = formInputs.class1.toLowerCase();
          let class2 = formInputs.class2.toLowerCase();
          let class3 = formInputs.class3.toLowerCase();

          const row = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName);

          let item1 = "";
          let item2 = "";
          let item3 = "";

          if (row) {
            if (class1) {
              const matchingclass1 = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName && row.class1.toLowerCase() === class1);
              if (matchingclass1) {
                item1 = matchingclass1.item1;
              }
            }

          if (class2) {
            const matchingclass2 = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName && row.class2.toLowerCase() === class2);
            if (matchingclass2) {
              item2 = matchingclass2.item2;
            }
          }

          if (class3) {
            const matchingclass3 = data.value.find((row) => row.line_name.replace(/\s+/g, "").toLowerCase() === formattedLineName && row.class3.toLowerCase() === class3);
            if (matchingclass3) {
              item3 = matchingclass3.item3;
            }
          }
        }
        formInputs.note = `${item1}_${item2}_${item3}`.replace(/(^_|_$)/g, '');
      };

      const data = ref([
        //TR2 BFA
        {line_name: 'tr2bfa2p', class1: 'Bd_B', item1: 'Breakdown', class2: 'BRP', item2: 'Barcode Problem', class3: 'ALL', item3: 'All of line'},
        {line_name: 'tr2bfa2p', class1: 'CMA_B', item1: 'Change Material', class2: 'COP', item2: 'Conveyor Problem', class3: 'FS', item3: 'Frame Supply'},
        {line_name: 'tr2bfa2p', class1: 'NoL', item1: 'No Load', class2: 'MCE', item2: 'Machine Error', class3: 'LAS', item3: 'Laser'},
        {line_name: 'tr2bfa2p', class1: 'PdM_B', item1: 'Predictive MTN', class2: 'RMC', item2: 'Component Broken', class3: 'FP', item3: 'Form Pad'},
        {line_name: 'tr2bfa2p', class1: 'PvM', item1: 'Preventive MTN', class2: 'COL', item2: 'Collision', class3: 'MSE', item3: 'Main Supply Elevator'},
        {line_name: 'tr2bfa2p', class1: 'CoM', item1: 'Corrective MTN', class2: 'HUM', item2: 'Human Mistake', class3: 'BS', item3: 'Busbar Supply'},
        {line_name: 'tr2bfa2p', class1: 'QRS', item1: 'Quality Reason Stop', class2: 'LOA', item2: 'Lots of Alarms', class3: 'BH', item3: 'Busbar Heating'},
        {line_name: 'tr2bfa2p', class1: 'URS', item1: 'Utility Reason Stop', class2: 'LTS', item2: 'LTS error', class3: 'BV', item3: 'Busbar Vision'},
        {line_name: 'tr2bfa2p', class1: 'MSH', item1: 'Material Shortage', class2: 'OTH', item2: 'Other', class3: 'DT', item3: 'Double Tape Sticking'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CDT', item2: 'Change Double tape', class3: 'FPI', item3: 'FPCB Input'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CBP', item2: 'Change Bottom PAD', class3: 'MOP', item3: 'Manual Operation'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CTP', item2: 'Change Thermistor PAD', class3: 'SAV', item3: 'Sensing arrange Vision'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'RHT', item2: 'Replace HeatStacking Tip', class3: 'LWE', item3: 'Laser Welding'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CHT', item2: 'Cleaning HeatStacking Tip', class3: 'CHG', item3: 'Connector Heating'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'RLL', item2: 'Replace Laser welding Lenz', class3: 'WVN', item3: 'Welding Vision'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CLL', item2: 'Cleaning Laser welding Lenz', class3: 'EOL1', item3: 'EOL-1'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'RPP', item2: 'Replace Pogo Pin', class3: 'EOL2', item3: 'EOL-2'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'RCB', item2: 'Replace Conveyor Belt', class3: 'MEE', item3: 'Main Emission Elevator'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'RMC', item2: 'Replace Machine component', class3: 'RCR', item3: 'Return Conveyor'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'ROG', item2: 'Peplace Oil, Grease, Cooler…', class3: 'FIN', item3: 'Final Inspection'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'OTH', item2: 'Other', class3: 'PC', item3: 'PC EAP'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'COR', item2: 'Change Order', class3: 'CLR', item3: 'Cover Loader'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CMO', item2: 'Change Model', class3: 'SEL', item3: 'Sub Elevator'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CSH', item2: 'Change Shift', class3: 'SFP', item3: 'Sub Form Pad'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'MWU', item2: 'Machine Warm up', class3: 'JC', item3: 'JIG Conveyor'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'TRA', item2: 'Training', class3: 'GPE', item3: 'Good Product Emission'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'AUD', item2: 'Audit', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'TES', item2: 'Test', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'REW', item2: 'Rework', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'TPM', item2: 'Regular Maintenance', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CLE', item2: 'Cleaning', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'CAL', item2: 'Calibration', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'OTH', item2: 'Other', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'TRN', item2: 'To reduce NG rate', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'TRF', item2: 'To reduce machine failure', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'TRD', item2: 'To reduce down time', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'TRR', item2: 'To reduce replacement', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'QOA', item2: 'Quality of Assembly', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'QOM', item2: 'Quality of Material', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'POW', item2: 'Electrical Power down', class3: 'null', item3: 'null'},
        {line_name: 'tr2bfa2p', class1: 'null', item1: 'null', class2: 'AIR', item2: 'Air compressure down', class3: 'null', item3: 'null'},
       ]);

          
          return { searchDate, dbTableName, formInputs, submitForm, closeModal, lineName,
                  calculateTimeDifference, startHour, startMinute, endHour, endMinute,
                  class2Options, updateClass2Options, combineValues};

        },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },            
    }, 
  };
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 1000px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 100px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>