<template>
    <div class="evo">
      <div class="act">
        <h class="pagetitle">EVO Hybrid</h>
         <div class="modalButtonContainer">        
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>
        <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>
        </div>
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>     
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" >{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers ">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index"  @click="editCellValue(dbTableName, row.id, header, $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-2P12S': row.model === '2P12S', 'model-3P8S': row.model === '3P8S', 'model-evo2p': row.model === 'EVO_2P', 'model-evo3p': row.model === 'EVO_3P'}" @click="editCellValue(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok"  @click="editCellValue(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'Verify Sum'" :key="index" class="verify" :class="{ 'zero-value_verify': row.ng_vesum === 0 }">{{ row.vesum }}</td>
                <td v-if="header === 'Laser welding verify'" :key="index" class="verify" :class="{ 'zero-value_verify': row.ve_laserwelding === 0 }"  @click="editCellValue(dbTableName, row.id, 've_laserwelding', $event)">{{ row.ve_laserwelding }}</td>
                <td v-if="header === 'FPCB Damage verify'" :key="index" class="verify" :class="{ 'zero-value_verify': row.ve_fpcbdamage === 0 }" @click="editCellValue(dbTableName, row.id, 've_fpcbdamage', $event)">{{ row.ve_fpcbdamage }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Heat Stacking'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_heatstacking === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_heatstacking', $event)">{{ row.ng_heatstacking }}</td>
                <td v-if="header === 'Busbar Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_busbardamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_busbardamage', $event)">{{ row.ng_busbardamage }}</td>
                <td v-if="header === 'FPCB Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_fpcbdamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_fpcbdamage', $event)">{{ row.ng_fpcbdamage }}</td>
                <td v-if="header === 'Weld Line Position'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_weldlineposition === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_weldlineposition', $event)">{{ row.ng_weldlineposition }}</td>
                <td v-if="header === 'Welding NG'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_weldingng === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_weldingng', $event)">{{ row.ng_weldingng }}</td>
                <td v-if="header === 'Final Slot'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_finalslot === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_finalslot', $event)">{{ row.ng_finalslot }}</td>
                <td v-if="header === 'Frame Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_framedamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_framedamage', $event)">{{ row.ng_framedamage }}</td>
                <td v-if="header === 'EOL1 Real'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eolreal === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_eolreal', $event)">{{ row.ng_eolreal }}</td>
                <td v-if="header === 'EOL2 Real'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eol2real === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_eol2real', $event)">{{ row.ng_eol2real }}</td>
                <td v-if="header === 'Coating'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coating === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_coating', $event)">{{ row.ng_coating }}</td>                
                <td v-if="header === 'Cover Hook'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coverhook === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_coverhook', $event)">{{ row.ng_coverhook }}</td>
                <td v-if="header === 'Cover Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coverdamage === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_coverdamage', $event)">{{ row.ng_coverdamage }}</td>
                <td v-if="header === 'Connector'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_connector === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_connector', $event)">{{ row.ng_connector }}</td>
                <td v-if="header === 'Welding Peel Test'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_weldingpeeltest === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_weldingpeeltest', $event)">{{ row.ng_weldingpeeltest }}</td>
                <td v-if="header === 'ETC'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_etc === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_etc', $event)">{{ row.ng_etc }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> 
                <td v-if="header === 'Fake EOL'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_eol === 0 }"  @click="editCellValue(dbTableName, row.id, 'fn_eol', $event)">{{ row.fn_eol }}</td>                                                 
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td>
                <td v-if="header === 'Week Code'" :key="index"  @click="editCellValue(dbTableName, row.id, 'weekcode', $event)">{{ row.weekcode }}</td>                 
                <td v-if="header === 'Bending Input'" :key="index" class="bending" :class="{ 'zero-value_bending': row.bdinput === 0 }">{{ row.bdinput }}</td>
                <td v-if="header === 'Bending OK'" :key="index" class="bending" :class="{ 'zero-value_bending': row.bd_ok === 0 }" @click="editCellValue(dbTableName, row.id, 'bd_ok', $event)">{{ row.bd_ok }}</td> 
                <td v-if="header === 'Bending NG SUM'" :key="index" class="bending" :class="{ 'zero-value_bending': row.bdngsum === 0 }">{{ row.bdngsum }}</td>
                <td v-if="header === 'Bending NG FPCA'" :key="index" class="bending" :class="{ 'zero-value_bending': row.bd_ng_fpcabendingng === 0 }" @click="editCellValue(dbTableName, row.id, 'bd_ng_fpcabendingng', $event)">{{ row.bd_ng_fpcabendingng }}</td>
                <td v-if="header === 'Bending NG Coating'" :key="index" class="bending" :class="{ 'zero-value_bending': row.bd_ng_fpcacoatingng === 0 }" @click="editCellValue(dbTableName, row.id, 'bd_ng_fpcacoatingng', $event)">{{ row.bd_ng_fpcacoatingng }}</td>
                <td v-if="header === 'Bedning NG Acrylic inside connector'" :key="index" class="bending" :class="{ 'zero-value_bending': row.bd_ng_acrylicinsideconnectorng === 0 }" @click="editCellValue(dbTableName, row.id, 'bd_ng_acrylicinsideconnectorng', $event)">{{ row.bd_ng_acrylicinsideconnectorng }}</td>
                <td v-if="header === 'Bending NG Rate'" :key="index" class="bending">{{ row.bd_ngRate }}%</td>
                <td v-if="header === 'Semi Frame Busbar Heat Stacking NG'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_ng_busbarheatstacking === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_ng_busbarheatstacking', $event)">{{ row.semi_ng_busbarheatstacking }}</td>
                <td v-if="header === 'Semi Frame Busbar No readable QR Code'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_ng_framebusbarqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_ng_framebusbarqrcode', $event)">{{ row.semi_ng_framebusbarqrcode }}</td>
                <td v-if="header === 'Semi Cover Double tape wrong position'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_ng_doubletape === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_ng_doubletape', $event)">{{ row.semi_ng_doubletape }}</td>
                <td v-if="header === 'Semi Cover No readable QR Code'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_ng_coverqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_ng_coverqrcode', $event)">{{ row.semi_ng_coverqrcode }}</td>
                <td v-if="header === 'Semi Frame No readable QR Code'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_ng_frameqrcode === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_ng_frameqrcode', $event)">{{ row.semi_ng_frameqrcode }}</td>
                <td v-if="header === 'Semi ETC'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_etc === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_etc', $event)">{{ row.semi_etc }}</td>
                <td v-if="header === 'Work Order'" :key="index"  @click="editCellValue(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index"  @click="editCellValue(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index"  @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index"  @click="editCellValue(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index"  @click="editCellValue(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td>                 
                <td v-if="header === 'Operator Quantity'" :key="index"  @click="editCellValue(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index"  @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].vesum }}</td>
          <td>{{ sums.shiftSums[1].ve_laserwelding }}</td>
          <td>{{ sums.shiftSums[1].ve_fpcbdamage }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[1].ng_busbardamage }}</td>
          <td>{{ sums.shiftSums[1].ng_fpcbdamage }}</td>
          <td>{{ sums.shiftSums[1].ng_weldlineposition }}</td>
          <td>{{ sums.shiftSums[1].ng_weldingng }}</td>
          <td>{{ sums.shiftSums[1].ng_finalslot }}</td>    
          <td>{{ sums.shiftSums[1].ng_framedamage }}</td>
          <td>{{ sums.shiftSums[1].ng_eolreal }}</td>
          <td>{{ sums.shiftSums[1].ng_eol2real }}</td>
          <td>{{ sums.shiftSums[1].ng_coating }}</td>
          <td>{{ sums.shiftSums[1].ng_coverhook }}</td>
          <td>{{ sums.shiftSums[1].ng_coverdamage }}</td>
          <td>{{ sums.shiftSums[1].ng_connector }}</td>
          <td>{{ sums.shiftSums[1].ng_weldingpeeltest }}</td>
          <td>{{ sums.shiftSums[1].ng_etc }}</td>  
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].fn_eol }}</td>   <!--FAKE SUM PART-->          
          <td>{{ sums.shiftSums[1].fn_eol }}</td>                            
          <td>{{ ((sums.shiftSums[1].fn_eol)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].vesum }}</td>
          <td>{{ sums.shiftSums[2].ve_laserwelding }}</td>
          <td>{{ sums.shiftSums[2].ve_fpcbdamage }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[2].ng_busbardamage }}</td>
          <td>{{ sums.shiftSums[2].ng_fpcbdamage }}</td>
          <td>{{ sums.shiftSums[2].ng_weldlineposition }}</td>
          <td>{{ sums.shiftSums[2].ng_weldingng }}</td>
          <td>{{ sums.shiftSums[2].ng_finalslot }}</td>    
          <td>{{ sums.shiftSums[2].ng_framedamage }}</td>
          <td>{{ sums.shiftSums[2].ng_eolreal }}</td>
          <td>{{ sums.shiftSums[2].ng_eol2real }}</td>
          <td>{{ sums.shiftSums[2].ng_coating }}</td>
          <td>{{ sums.shiftSums[2].ng_coverhook }}</td>
          <td>{{ sums.shiftSums[2].ng_coverdamage }}</td>
          <td>{{ sums.shiftSums[2].ng_connector }}</td>
          <td>{{ sums.shiftSums[2].ng_weldingpeeltest }}</td>
          <td>{{ sums.shiftSums[2].ng_etc }}</td>  
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].fn_eol }}</td>   <!--FAKE SUM PART-->          
          <td>{{ sums.shiftSums[2].fn_eol }}</td>                            
          <td>{{ ((sums.shiftSums[2].fn_eol)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th rowspan="2">Model</th>
          <td>EVO 2P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.modelSums['EVO_2P'].input }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok - sums.qwmodelSums['EVO_2P'].ng}}</td>   
          <td>{{ sums.modelSums['EVO_2P'].vesum }}</td>       
          <td>{{ sums.modelSums['EVO_2P'].ve_laserwelding }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ve_fpcbdamage }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_2P'].ng }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td> <!-- obj.ng_heatstacking 부분을 수정하면 된다.-->                    
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>   
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>       
          <td>{{ ((sums.modelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_2P'].ng) / (sums.modelSums['EVO_2P'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['EVO_2P'].fn_eol }}</td> <!--FAKE SUM PART-->          
          <td>{{ sums.modelSums['EVO_2P'].fn_eol }}</td>          
          <td>{{ ((sums.modelSums['EVO_2P'].fn_eol)/(sums.modelSums['EVO_2P'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>EVO 3P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.modelSums['EVO_3P'].input }}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok - sums.qwmodelSums['EVO_3P'].ng}}</td>   
          <td>{{ sums.modelSums['EVO_3P'].vesum }}</td>       
          <td>{{ sums.modelSums['EVO_3P'].ve_laserwelding }}</td>
          <td>{{ sums.modelSums['EVO_3P'].ve_fpcbdamage }}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng + sums.qwmodelSums['EVO_3P'].ng }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td> <!-- obj.ng_heatstacking 부분을 수정하면 된다.-->                    
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>   
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>       
          <td>{{ ((sums.modelSums['EVO_3P'].ng + sums.qwmodelSums['EVO_3P'].ng) / (sums.modelSums['EVO_3P'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['EVO_3P'].fn_eol }}</td> <!--FAKE SUM PART-->          
          <td>{{ sums.modelSums['EVO_3P'].fn_eol }}</td>          
          <td>{{ ((sums.modelSums['EVO_3P'].fn_eol)/(sums.modelSums['EVO_3P'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th colspan="3">EVO Total</th>         
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok + sums.shiftSums[2].ok - sums.qwmodelSums['EVO_2P'].ng - sums.qwmodelSums['EVO_3P'].ng}}</td>
          <td class="verify">{{ sums.shiftSums[1].vesum + sums.shiftSums[2].vesum }}</td>
          <td class="verify">{{ sums.shiftSums[1].ve_laserwelding + sums.shiftSums[2].ve_laserwelding  }}</td>
          <td class="verify">{{ sums.shiftSums[1].ve_fpcbdamage + sums.shiftSums[2].ve_fpcbdamage }}</td>          
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_3P'].ng}}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td> <!-- obj.ng_heatstacking 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_busbardamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fpcbdamage, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldlineposition, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingng, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_finalslot, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_framedamage, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eolreal, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_eol2real, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coating, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverhook, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_coverdamage, 0) }}</td>   
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_connector, 0) }}</td>  
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_etc, 0) }}</td>                 
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_3P'].ng) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_eol }}</td>          
          <td class="fn_type">{{ sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_eol }}</td>           
          <td class="fn">{{ (( sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_eol)/(sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader -->
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCellValue(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index"></td>
            <td v-if="header === 'Input'" :key="index"></td>
            <td v-if="header === 'OK'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'Verify Sum'" :key="index" ></td>
            <td v-if="header === 'Laser welding verify'" :key="index"></td>
            <td v-if="header === 'FPCB Damage verify'" :key="index" ></td>
            <td v-if="header === 'NG'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Heat Stacking'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_heatstacking === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_heatstacking', $event)">{{ qw.ng_heatstacking }}</td>
            <td v-if="header === 'Busbar Damage'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_busbardamage === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_busbardamage', $event)">{{ qw.ng_busbardamage }}</td>
            <td v-if="header === 'FPCB Damage'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_fpcbdamage === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_fpcbdamage', $event)">{{ qw.ng_fpcbdamage }}</td>
            <td v-if="header === 'Weld Line Position'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_weldlineposition === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_weldlineposition', $event)">{{ qw.ng_weldlineposition }}</td>
            <td v-if="header === 'Welding NG'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_weldingng === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_weldingng', $event)">{{ qw.ng_weldingng }}</td>
            <td v-if="header === 'Final Slot'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_finalslot === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_finalslot', $event)">{{ qw.ng_finalslot }}</td>
            <td v-if="header === 'Frame Damage'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_framedamage === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_framedamage', $event)">{{ qw.ng_framedamage }}</td>
            <td v-if="header === 'EOL1 Real'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_eolreal === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_eolreal', $event)">{{ qw.ng_eolreal }}</td>
            <td v-if="header === 'EOL2 Real'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_eol2real === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_eol2real', $event)">{{ qw.ng_eol2real }}</td>
            <td v-if="header === 'Coating'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_coating === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_coating', $event)">{{ qw.ng_coating }}</td>                
            <td v-if="header === 'Cover Hook'" :key="index"  :class="{ 'zero-value_qwngtype': qw.ng_coverhook === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_coverhook', $event)">{{ qw.ng_coverhook }}</td>
            <td v-if="header === 'Cover Damage'" :key="index"  :class="{ 'zero-value_qwngtype': qw.ng_coverdamage === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_coverdamage', $event)">{{ qw.ng_coverdamage }}</td>
            <td v-if="header === 'Connector'" :key="index"  :class="{ 'zero-value_qwngtype': qw.ng_connector === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_connector', $event)">{{ qw.ng_connector }}</td>
            <td v-if="header === 'Welding Peel Test'" :key="index"  :class="{ 'zero-value_qwngtype': qw.ng_weldingpeeltest === 0 }"></td>
            <td v-if="header === 'ETC'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_etc === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_etc', $event)">{{ qw.ng_etc }}</td>

          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCellValue(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>      
      <dtModal  v-if="showdtModal" @onClose="showdtModal = false; callSearchData();"/>         
      <drModal  v-if="showdrModal" @onClose="showdrModal = false; callSearchData();"/> 
      <qwModal  v-if="showqwModal" @onClose="showqwModal = false; callSearchData();"/> 
      <rdModal  v-if="showrdModal" @onClose="showrdModal = false; callSearchData();"/> 
  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./evo_prm.vue";
import dtModal from "./evo_dtm.vue";
import drModal from "./evo_drm.vue";
import qwModal from "./evo_qwm.vue";
import rdModal from "./evo_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },        
    
    setup() {
        const lineName = "evo"  //설비 변경시 필수 교체        
        const dbTableName = "evopr";  //searchData에 있는 값도 바꿔야 함 
        const dtTableName = "evodt";  //searchData에 있는 값도 바꿔야 함
        const drTableName = "evodr";  //searchData에 있는 값도 바꿔야 함
        const qwTableName = "evoqw";  //searchData에 있는 값도 바꿔야 함
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'Verify Sum', 'Laser welding verify', 'FPCB Damage verify',
                         'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position",
                         "Welding NG", 'Final Slot', 'Frame Damage', 'EOL1 Real', 'EOL2 Real', 'Coating',
                         'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Fake Sum', 'Fake EOL',
                         'Fake NG Rate', 'Week Code', 'Bending Input', 'Bending OK',
                         'Bending NG SUM', 'Bending NG FPCA', 'Bending NG Coating',
                         'Bedning NG Acrylic inside connector', 'Bending NG Rate',
                         'Semi Frame Busbar Heat Stacking NG', 'Semi Frame Busbar No readable QR Code',
                         'Semi Cover Double tape wrong position', 'Semi Cover No readable QR Code',
                         'Semi Frame No readable QR Code', 'Semi ETC', 'Work Order', 'Supervisor',
                         'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Work Time',
                         'row_no']; //설비 변경시 필수 교체

        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], });   

        //Repeat Data part
        const showrdModal = ref(false);       
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, laserverify, fpcbdamageverify, fneol, bdok, bdngfpca, bdngcoating, bdngacrylic) => {
          const input = ok + ng;
          const vesum = laserverify + fpcbdamageverify;
          const bdngsum = bdngfpca + bdngcoating + bdngacrylic;
          const bdinput = bdngsum + bdok;
          const fakeng = fneol;   

          let ngRate = 0;
          let fn_ngRate = 0;
          let bd_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          if (bdngsum !== 0) {
            bd_ngRate = ((bdngsum / bdinput) * 100).toFixed(1);
          }
          return { input, ngRate, vesum, bdngsum, bdinput, fakeng, fn_ngRate, bd_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, vesum, bdngsum, bdinput, fakeng, fn_ngRate, bd_ngRate } = 
            calculateInputAndNgRate(d.ok, d.ng, d.ve_laserwelding, d.ve_fpcbdamage, d.fn_eol, d.bd_ok, d.bd_ng_fpcabendingng, d.bd_ng_fpcacoatingng, d.bd_ng_acrylicinsideconnectorng, );
            return { ...d, input, ngRate, vesum, bdngsum, bdinput, fakeng, fn_ngRate, bd_ngRate, }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
            console.log('Requesting data with:', {
              tablename: tableName,
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
            });

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };

            prTableData.data = [];
            dtTableData.data = [];
            drTableData.data = [];
            qwTableData.data = [];

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'evopr':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                  case 'evodt':
                    dtTableData.data = res.data;
                    break;
                  case 'evodr':
                    drTableData.data = res.data;
                    break;
                  case 'evoqw':
                    qwTableData.data = res.data;
                    break;
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName);
            searchData(dtTableName);
            searchData(drTableName);
            searchData(qwTableName);
            settingCall();
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let shiftSums = {
            1: { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0,
                ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, 
                ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0,
                ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, fn_eol: 0 }, 
          
            2: { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0,
                ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, 
                ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0,
                ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, fn_eol: 0 }, 
            };

          let modelSums = {
            "EVO_2P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0,
                        ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, 
                        ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0,
                        ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, fn_eol: 0 }, 
          
            "EVO_3P": { target: 0, input: 0, ok: 0, vesum: 0, ve_laserwelding: 0, ve_fpcbdamage: 0, ng: 0, ng_heatstacking:0,
                        ng_busbardamage: 0, ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, 
                        ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0,
                        ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0, fn_eol: 0 }, 
            };

          let qwmodelSums = {
            "EVO_2P": { target: 0, input: 0, ok: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, 
                        ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, 
                        ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0,
                        ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,}, 
          
            "EVO_3P": { target: 0, input: 0, ok: 0, ng: 0, ng_heatstacking:0, ng_busbardamage: 0, 
                        ng_fpcbdamage: 0, ng_weldlineposition: 0, ng_weldingng: 0, 
                        ng_finalslot: 0, ng_framedamage: 0, ng_eolreal: 0, ng_eol2real: 0, ng_coating: 0, ng_coverhook: 0, ng_coverdamage: 0,
                        ng_connector: 0, ng_weldingpeeltest: 0, ng_etc: 0,}, 
            };

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].ok += row.ok;              
              shiftSums[row.shift].vesum += row.vesum;    
              shiftSums[row.shift].ve_laserwelding += row.ve_laserwelding;
              shiftSums[row.shift].ve_fpcbdamage += row.ve_fpcbdamage;
              shiftSums[row.shift].ng += row.ng;    
              shiftSums[row.shift].ng_heatstacking += row.ng_heatstacking;
              shiftSums[row.shift].ng_busbardamage += row.ng_busbardamage;
              shiftSums[row.shift].ng_fpcbdamage += row.ng_fpcbdamage;
              shiftSums[row.shift].ng_weldlineposition += row.ng_weldlineposition;
              shiftSums[row.shift].ng_weldingng += row.ng_weldingng;
              shiftSums[row.shift].ng_finalslot += row.ng_finalslot;
              shiftSums[row.shift].ng_framedamage += row.ng_framedamage;
              shiftSums[row.shift].ng_eolreal += row.ng_eolreal;
              shiftSums[row.shift].ng_eol2real += row.ng_eol2real;
              shiftSums[row.shift].ng_coating += row.ng_coating;
              shiftSums[row.shift].ng_coverhook += row.ng_coverhook;
              shiftSums[row.shift].ng_coverdamage += row.ng_coverdamage;
              shiftSums[row.shift].ng_connector += row.ng_connector;
              shiftSums[row.shift].ng_weldingpeeltest += row.ng_weldingpeeltest;
              shiftSums[row.shift].ng_etc += row.ng_etc;
              shiftSums[row.shift].fn_eol += row.fn_eol;              
              }

            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {              
              modelSums[row.model].target += row.target;            
              modelSums[row.model].input += row.input;
              modelSums[row.model].ok += row.ok;         
              modelSums[row.model].vesum += row.vesum;           
              modelSums[row.model].ve_laserwelding += row.ve_laserwelding;
              modelSums[row.model].ve_fpcbdamage += row.ve_fpcbdamage;
              modelSums[row.model].ng += row.ng;    
              modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
              modelSums[row.model].ng_busbardamage += row.ng_busbardamage;
              modelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
              modelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
              modelSums[row.model].ng_weldingng += row.ng_weldingng;
              modelSums[row.model].ng_finalslot += row.ng_finalslot;
              modelSums[row.model].ng_framedamage += row.ng_framedamage;
              modelSums[row.model].ng_eolreal += row.ng_eolreal;
              modelSums[row.model].ng_eol2real += row.ng_eol2real;
              modelSums[row.model].ng_coating += row.ng_coating;
              modelSums[row.model].ng_coverhook += row.ng_coverhook;
              modelSums[row.model].ng_coverdamage += row.ng_coverdamage;
              modelSums[row.model].ng_connector += row.ng_connector;
              modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
              modelSums[row.model].ng_etc += row.ng_etc;
              modelSums[row.model].fn_eol += row.fn_eol; 
            }
          }

          for (const row of qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
             qwmodelSums[row.model].ok += row.ok;
             qwmodelSums[row.model].ng += row.ng;    
             qwmodelSums[row.model].ng_heatstacking += row.ng_heatstacking;
             qwmodelSums[row.model].ng_busbardamage += row.ng_busbardamage;
             qwmodelSums[row.model].ng_fpcbdamage += row.ng_fpcbdamage;
             qwmodelSums[row.model].ng_weldlineposition += row.ng_weldlineposition;
             qwmodelSums[row.model].ng_weldingng += row.ng_weldingng;
             qwmodelSums[row.model].ng_finalslot += row.ng_finalslot;
             qwmodelSums[row.model].ng_framedamage += row.ng_framedamage;
             qwmodelSums[row.model].ng_eolreal += row.ng_eolreal;
             qwmodelSums[row.model].ng_eol2real += row.ng_eol2real;
             qwmodelSums[row.model].ng_coating += row.ng_coating;
             qwmodelSums[row.model].ng_coverhook += row.ng_coverhook;
             qwmodelSums[row.model].ng_coverdamage += row.ng_coverdamage;
             qwmodelSums[row.model].ng_connector += row.ng_connector;
             qwmodelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
             qwmodelSums[row.model].ng_etc += row.ng_etc;       
            }
          }     

          return { shiftSums, modelSums, qwmodelSums };
        });

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error download excel data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });
          const wsDt = XLSX.utils.json_to_sheet(dtTableData.data, { header: dtHeaders.value });
          const wsDr = XLSX.utils.json_to_sheet(drTableData.data, { header: drHeaders.value });
          const wsQw = XLSX.utils.json_to_sheet(qwTableData.data, { header: headers.value });

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "PR Data");
          XLSX.utils.book_append_sheet(wb, wsDt, "DT Data");
          XLSX.utils.book_append_sheet(wb, wsDr, "DR Data");
          XLSX.utils.book_append_sheet(wb, wsQw, "QW Data");

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "data.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}
.modalButtonContainer {
  display: inline-flex;
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .qwallButton {
    padding: 5px 5px;
    background-color: #00eb91;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, .qwallButton:hover, 
.button:hover {
    background-color: #2980b9;
  }

  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input
  {
  width : 80px;  
  margin-right: 5px;
  padding : 5px;
 }

label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}

#dailyReport td {
  text-align: left;
}

.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
}

.model-2P12S { background-color: #fafc83; } 
.model-3P8S { background-color: #e07510; } 
.model-evo2p { background-color: #04c192; }
.model-evo3p { background-color: #c2c2c2; }

.ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.zero-value_ng {
  background-color: #fa7979; 
  color: #fa7979; 
}

.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.zero-value_ngtype {
  background-color: #fdb08d; 
  color: #fdb08d;
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.zero-value_fn {
  background-color: #ff72d5; 
  color: #ff72d5; 
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}

.zero-value_fntype {
  background-color: #ff9bcd; 
  color: #ff9bcd; 
}

.verify {
  background-color: #ddf505; 
}

.zero-value_verify {
  background-color: #ddf505; 
  color: #ddf505; 
}

.bending {
  background-color: #7ec578; 
}

.zero-value_bending {
  background-color: #7ec578; 
  color: #7ec578; 
}

.semi {
  background-color: #fccc66; 
}

.zero-value_semi {
  background-color: #fccc66; 
  color: #fccc66; 
}
.qwall {
  background-color: #66b179;  
}.zero-value_qwngtype {
  background-color: #66b179;  
  color:#66b179;  
}
.shift1 { background-color: rgb(174, 240, 147); }
.shift2 { background-color: rgba(248, 250, 145, 0.979); }
</style>