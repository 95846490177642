<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Purchase List</h>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">        
          <div class="start">
              <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
              <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
              <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
          </div>
          <div class="separator">~</div>
          <div class="end">
              <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
              <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
              <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
          </div>
          <div class="searchTda">
            <input type="text" id="word" v-model="word" placeholder="Search word" />
            <input type="text" id="requester" v-model="requester" placeholder="Requester" />                    
            <select type="text" id="progress" v-model="progress" placeholder="Progress">
              <option>Offer</option><option>Report</option><option>PR</option><option>PR Signed</option>
              <option>Need Pre Pay</option><option>PO</option><option>Proceeding</option><option>Finished_Not Paid</option>
              <option>Paid</option><option>Paid By Card</option><option>Ordered in GA</option><option>Private Paid</option>
              <option>Waiting for Approval</option><option></option>
            </select>
          </div>
          <div class="searchTd">
              <button type="submit" @click="callSearchData">Search</button>                        
          </div>
          <div class="searchTd">
              <button class="button" @click="showModal = true">+ New Purchase</button>                                                                   
          </div>
          <div class="searchTd">
              <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
          </div>                                  
        </div>      
      </form>  
    </div>      
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>                
        </tr>      
        <tr v-for="row in Item" :key="row.id" >       
        <template v-for="(header, index) in headers">  
          <td v-if="header === 'Year'" :key="index" >{{ row.year }}</td>
          <td v-if="header === 'Month'" :key="index" >{{ row.month }}</td>
          <td v-if="header === 'Day'" :key="index" >{{ row.day }}</td>
          <td v-if="header === 'Request No'" :key="index">{{ row.requestnumber }}</td>
          <td v-if="header === 'Cost Type'" :key="index">{{ row.costtype }}</td>
          <td v-if="header === 'Type'" :key="index" >{{ row.type }}</td>          
          <td v-if="header === 'Supplier'" :key="index" >{{ row.supplier }}</td>
          <td v-if="header === 'Item Name'" :key="index" >{{ row.itemname }}</td>
          <td v-if="header === 'Specification'" :key="index" >{{ row.specification }}</td>
          <td v-if="header === 'Quantity'" :key="index" >{{ row.quantity }}</td>
          <td v-if="header === 'Unit Cost(PLN)'" :key="index" >{{ row.unitcost1 }}</td>
          <td v-if="header === 'Unit Cost(EUR)'" :key="index" >{{ row.unitcost2 }}</td>
          <td v-if="header === 'Price(PLN)'" :key="index" >{{ row.price1 }}</td>
          <td v-if="header === 'Price(EUR)'" :key="index" >{{ row.price2 }}</td>
          <td v-if="header === 'Requester'" :key="index" >{{ row.requester }}</td>
          <td v-if="header === 'Modify'" :key="index" ><button type="submit" @click="openSendModal(row)" class="mobutton">Modify</button></td>
          <td v-if="header === 'Progress'" :key="index" >{{ row.progress }}</td>
          <td v-if="header === 'Offer'" :key="index" >{{ getFileName(row.offer) }}<br>            
              <button class="fileDownLoadButton" @click="downloadFile(row.offerpath)">Download</button>
          </td>
          <td v-if="header === 'Assets Number'" :key="index" >{{ row.assetsnumber }}</td>
          <td v-if="header === 'PR Number'" :key="index" >{{ row.prnumber }}</td>
          <td v-if="header === 'PO Number'" :key="index" >{{ row.ponumber }}</td>
          <td v-if="header === 'PO Date'" :key="index" >{{ row.podate }}</td>
          <td v-if="header === 'Expect Date'" :key="index" >{{ row.expectdate }}</td>
          <td v-if="header === 'Arrival Date'" :key="index" >{{ row.arrivaldate }}</td>
          <td v-if="header === 'Confirmation Document'" :key="index" >{{ getFileName(row.confirmationdocument) }}<br>
              <button class="fileDownLoadButton" @click="downloadFile(row.confirmationdocumentpath)">Download</button>
          </td>
          <td v-if="header === 'Invoice Number'" :key="index" >{{ row.invoicenumber }}</td>
          <td v-if="header === 'Remark'" :key="index" class="content-cell" >{{ row.remark }}</td>
          <td v-if="header === 'DEL'" :key="index"><button class="del" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="deleteRow(dbTableName, row.id)">DEL</button></td>
        </template>   
      </tr>
    </table>
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./purchaseModal.vue";
import modifyModal from "./purchaseMoModal.vue";
import { deleteRow, downloadExcel,} from "../utils.js"; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      downExcel() { downloadExcel(this.Item, "PurchaseList.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
      canClick() {          
        const currentUser = this.$route.meta.currentUser;
        console.log('currentUser', currentUser)
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3; } 
        else { return false; }
      }, 
      downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }
        window.location.href = filepath;
      },    
      getFileName(filePath) {
        if (!filePath) return ''; const parts = filePath.split('-');
        if (parts.length > 1) { return parts.slice(1).join('-'); }
        return filePath;
      }
    },

    components: { Modal, modifyModal, },     

    setup() {   
        const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();
        const word = ref(); const requester = ref(); const progress = ref();
        const dbTableName = 'purchaselist';
        const showModal = ref(false);
        const showMoModal = ref(false);      
        const headers = ['Year', 'Month', 'Day', 'Request No', 'Cost Type', 'Type', 'Supplier', 'Item Name', 'Specification', 'Quantity',
                        'Unit Cost(PLN)', 'Unit Cost(EUR)', 'Price(PLN)', 'Price(EUR)', 'Requester', 'Modify', 'Progress',
                        'Offer', 'Assets Number', 'PR Number', 'PO Number', 'PO Date', 'Expect Date', 'Arrival Date',
                         'Confirmation Document', 'Invoice Number', 'Remark', 'DEL'];
        const purchaseData = reactive({ data: [], })        
         
        const sendDate = async () => {const sendDateData = [
              startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value,
              word.value, requester.value, progress.value,    
        ]; return {sendDateData};};     

        const searchData = async () => {  
          const sendDateData = await sendDate();
          const formData = {        
                tableName: dbTableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],                
                word: sendDateData.sendDateData[6],
                requester: sendDateData.sendDateData[7],
                progress: sendDateData.sendDateData[8],
            };  

          console.log('Requesting data with:', formData);

          axios.get('/api/purchaseList', { params: formData })                      
            .then((res) => {
              const updatedData = res.data.map((row) => {
                // 여기에서 계산하고 새로운 속성을 추가
                const newRow = {
                  ...row,
                  price1: (row.quantity * row.unitcost1).toFixed(2),
                  price2: (row.quantity * row.unitcost2).toFixed(2),
                  podate: `${row.poyear}.${row.pomonth}.${row.poday}`,
                  expectdate: `${row.expectyear}.${row.expectmonth}.${row.expectday}`,
                  arrivaldate: `${row.arrivalyear}.${row.arrivalmonth}.${row.arrivalday}`,
                };
                return newRow;
              });

              // 정렬된 데이터를 purchaseData.data에 저장
              purchaseData.data = updatedData.sort((a, b) => {
              if (a.year < b.year) return -1; if (a.year > b.year) return 1;    
              if (a.month < b.month) return -1; if (a.month > b.month) return 1;  
              if (a.day < b.day) return -1; if (a.day > b.day) return 1;  
              if (a.type < b.type) return -1; if (a.type > b.type) return 1;                        
              if (a.progress < b.progress) return -1; if (a.progress> b.progress) return 1;                       

              return 0;
          });
      });
        }
        const Item = computed(() => { return purchaseData.data.map(d => { return { ...d, }; });  });

        const callSearchData = () => { searchData(dbTableName); };        

        return { Item, deleteRow, headers, dbTableName, callSearchData, showModal, showMoModal,
                 startYear, startMonth, startDay, endYear, endMonth, endDay, word, requester, progress,
                 sendDate, downloadExcel,  
        };        
    }
  }
</script>

<style scoped> 
    .table-container { margin-left : 10px; margin-right: 10px;}
    .searchTable{margin-left: 10px; margin-top: 10px;}
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload:hover { background-color: #009625; }

    button { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .del { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 11px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto;}
    .start,.end { display: flex; flex-direction: row; border: 0px; }
    .start input,.end input { margin-right: 5px;}    
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }z
    .searchTd { border: 0px !important; }
    .searchTda { border: 0px !important; width: 350px; }
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }
    input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }    
    .horizontal-form { margin-top: 10px; margin-left: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>