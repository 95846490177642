<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Repeat Data</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <td>
                  <div class="form-group date-time-group">
                      <label>Year/Month/Day:</label>
                      <div class="date-time-inputs">
                        <input type="text" id="year" v-model="searchDate.year"  />
                        <input type="text" id="month" v-model="searchDate.month" />
                        <input type="text" id="day" v-model="searchDate.day" />                  
                      </div> 
                  </div>                                         
                  <div class="form-group">
                    <label for="shift">Shift:</label>
                    <select id="shift" v-model="formInputs.shift">
                      <option>1</option><option>2</option>
                    </select>
                  </div>  
                  <div class="form-group">
                    <label for="workorder">Work Order:</label>
                    <input type="text" id="workorder" v-model="formInputs.workorder" />
                  </div>  
                </td>                               
                <td>
                  <div class="form-group">
                    <label for="supervisor">Supervisor:</label>
                    <input type="text" id="supervisor" v-model="formInputs.supervisor" />
                  </div>
                  <div class="form-group">
                    <label for="leader">Leader:</label>
                    <input type="text" id="leader" v-model="formInputs.leader" />
                  </div>
                  <div class="form-group">
                    <label for="technician">Technician:</label>
                    <input type="text" id="technician" v-model="formInputs.technician" />
                  </div>
                  <div class="form-group">
                    <label for="shiftname">Shift Name:</label>
                    <input type="text" id="shiftname" v-model="formInputs.shiftname" />
                  </div>
                  <div class="form-group">
                    <label for="operator_qty">Operator Quantity:</label>
                    <input type="number" id="operatorqty" v-model="formInputs.operatorqty" />
                  </div>  
                </td>
              </tr>
            </table>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                                        
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate } from "../utils.js"; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "repeatdata"      
        const lineName ="BT6";    

        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const formInputs = reactive({ shift : "", supervisor : "", leader : "", technician : "", shiftname : "", operatorqty : "", workorder : "", });   
        const searchDate = reactive({ ...getCurrentDate(), }); 
        
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day) {
            alert("Please fill in all the fields: year, month, and day.");
            return;
          }

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {
              year: "year",
              month: "month",
              day: "day",
              line_name: "line_name",
              shift : "shift",
              supervisor : "supervisor",
              leader : "leader",
              technician : "technician",
              shiftname : "shiftname",
              operatorqty : "operatorqty",   
              workorder : "workorder"
          }     

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              line_name: lineName,
              shift : formInputs.shift,
              supervisor : formInputs.supervisor,
              leader : formInputs.leader,
              technician : formInputs.technician,
              shiftname : formInputs.shiftname,
              operatorqty : formInputs.operatorqty,    
              workorder : formInputs.workorder              
            }
         };
         
          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };
  
      return { searchDate, dbTableName, formInputs, submitForm, closeModal, lineName, saveStatus, isProcessing};
    },     

    props: { onSubmit: { type: Function, required: true, }, }, 
};
  
</script>
    
<style scoped>  
  * { margin: 0; padding: 0; box-sizing: border-box; }
  
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 500px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 50px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }
</style>