<template>
    <div class="checksheet">            
      <div class="act">               
         <h class="pagetitle">EVO Hybrid Maintenance Check Sheet</h>                  
      </div>
      <table class="searchBar">
        <tr>
          <td class="searchBarTd">
          <form @submit.prevent="sendDate()">             
            <label>Search : </label>
            <input id="year" v-model="searchDate.year" placeholder="Year"/>          
            <input id="month" v-model="searchDate.month" placeholder="Month" />                       
            <input id="day" v-model="searchDate.day" placeholder="Day"/>              
            <button class="searchButton" type="submit"  @click="callSearchData">Search</button>          
          </form>
          </td>
        </tr>
        <tr>
        <td class="searchBarTd">              
          <button class="modalButton" @click="cs1showModal = true">Check Sheet 1</button>  
          <button class="modalButton" @click="cs2showModal = true">Check Sheet 2</button>  
          <button class="modalButton" @click="cs3showModal = true">Check Sheet 3</button>  
          <button class="modalButton" @click="cs4showModal = true">Check Sheet 4</button>  
          <button class="modalButton" @click="cs5showModal = true">Check Sheet 5</button>  
        </td>
        </tr>
      </table>   
      <div class="table-container">
      <table class="tables">
        <tr>
          <th v-for="(headers, index) in headers" :key="index" class="sticky-header">{{ headers }}</th>             
        </tr>
        <tr v-for="item in combinedCheckSheetData" :key="item.id">
          <td>{{ item.station }}</td>
          <td>{{ item.technician }}</td>
          <td>{{ item.time }}</td>
          <td>{{ item.part }}</td>
          <td>{{ item.itemName }}</td>
          <td>{{ item.standard }}</td>
          <td>{{ item.value }}</td>
          <td>{{ item.unit }}</td>
          <td :class="{ 'ok': item.result === 'ok', 'ng': item.result === 'ng', 'pass': item.result === 'pass', 'need-check': item.result === 'need check' , 'except': item.result === 'Except' }">{{ item.result }}</td>
          <td>{{ item.cycle }}</td>
          <td>{{ item.cycledate }}</td>
          <td>{{ item.remark }}</td>
        </tr>
      </table>  
      </div>

      <cs1Modal  v-if="cs1showModal" @onClose="cs1showModal = false; callSearchData();"/>    
      <cs2Modal  v-if="cs2showModal" @onClose="cs2showModal = false; callSearchData();"/>       
      <cs3Modal  v-if="cs3showModal" @onClose="cs3showModal = false; callSearchData();"/>  
      <cs4Modal  v-if="cs4showModal" @onClose="cs4showModal = false; callSearchData();"/>  
      <cs5Modal  v-if="cs5showModal" @onClose="cs5showModal = false; callSearchData();"/>  
  </div>  
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";

import cs1Modal from "./checkSheet1Modal.vue";
import cs2Modal from "./checkSheet2Modal.vue";
import cs3Modal from "./checkSheet3Modal.vue";
import cs4Modal from "./checkSheet4Modal.vue";
import cs5Modal from "./checkSheet5Modal.vue";

export default {      
    components: { cs1Modal, cs2Modal, cs3Modal, cs4Modal, cs5Modal },  
    
    setup() {             
        const lineName ="EVO Hybrid"
        const cs1showModal = ref(false); const cs2showModal = ref(false); const cs3showModal = ref(false); const cs4showModal = ref(false); const cs5showModal = ref(false);   
        const headers = ['Station Name', 'Technician', 'Time', 'Part Name', 'Item Name', 'Standard', 'Value', 'Unit', 'Result', 'Cycle', 'Cycledate', 'Remark' ];        
        const searchDate = ref({ year: '', month: '', day: '',});
        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day,];
            return {sendDateData};             
        };       

        const checkTableName = "maintenancechecksheet"; const checkTableData = reactive({ data: [], });     
        const targetTableName = "maintenancechecktarget"; const targetList = reactive({ data: [], })     
        
        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
          const formData = {
            tableName: tableName,
            values: {
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
              line: lineName,
            }
          };  

          axios.get('/api/checksheet', { params: formData })
            .then((res) => {
              const filteredData = filterLatestData(res.data);
              checkTableData.data = filteredData;
            });
        };
        
        const filterLatestData = (data) => {
          const dataMap = new Map();
          data.forEach(item => {
            const key = `${item.year}-${item.month}-${item.day}-${item.line}-${item.station}`;
            dataMap.set(key, item); 
          });
          return Array.from(dataMap.values()); 
        };

        const targetSearchData = async () => {   
          const formData = { tableName: targetTableName, line: lineName };
          console.log('Requesting data with:', formData);
          axios.get('/api/targetList', { params: formData })
            .then((res) => { targetList.data = res.data; })                     
          };

        const callSearchData = () => { 
          searchData(checkTableName); 
          targetSearchData(targetTableName);
        };  
   
        const combinedCheckSheetData = computed(() => {
          return checkTableData.data.map(sheet => {
            const values = sheet.itemvalues.split(';');   
            const results = sheet.judgement.split(';');
            const cycle = sheet.cycle.split(';');
            const cycleDates = sheet.cycledate.split(';');
            const remarks = sheet.remark.split(';');         
            return values.map((value, index) => {
              const target = targetList.data.find(target => 
                target.line === sheet.line && target.station === sheet.station && target.station_row === index + 1);

            if (!target) { return null; }

            return {
              station: sheet.station,
              technician: sheet.technician,
              time: sheet.time,
              part: target.part,
              itemName: target.item,
              standard: target.standvalue || `${target.lowerlimit}~${target.upperlimit}`,
              value,
              unit: target.unit,
              result :results[index],
              cycle: cycle[index],
              cycledate: cycleDates[index],
              remark: remarks[index] || ''         
            };
              
            }).filter(item => item);
          }).flat();
        });

      return { cs1showModal, cs2showModal, cs3showModal, cs4showModal, cs5showModal,
               callSearchData, sendDate, searchData,  searchDate, checkTableName, targetSearchData, 
               headers, combinedCheckSheetData,
             };        
    },
}
</script>

<style scoped>
  .act { display: flex; padding: 10px 10px 5px 5px; }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; } 

  .searchButton {
    padding: 5px 5px;  background-color: #94a5b1;
    color: #000000; border: none;
    border-radius: 4px; font-size: 14px; font-weight: 600;
    margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }

  .excelButton {
    padding: 5px 5px; background-color: #10a802;
    color: #fff; border: none;
    border-radius: 4px; font-size: 14px;
    font-weight: 600; cursor: pointer;
    transition: background-color 0.3s;
  }

  .modalButton {
    padding: 5px 5px;  background-color: #1d0fe0;
    color: #fff;  border: none;  border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 5px; margin-right: 5px;
    cursor: pointer; transition: background-color 0.3s;
  }  
    
  .searchBar {
    display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
    margin-bottom: 20px; margin-left: 20px;
  }
  .searchBar > *:not(:last-child) { margin-right: 5px; }

  .searchBar select, .searchBar input
    { width : 80px;  margin-right: 5px; padding : 5px; }

  label { font-size: 14px; font-weight: 600;  margin-right: 8px; }

  .searchBarTd { border : 0px; }  

  .table-container { margin-left: 10px; margin-right: 10px;}
.tables {
  width: 100%;  
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 그림자 효과 */
  background-color: #ffffff; /* 밝은 배경색 */
}
.tables th {
    padding: 0.75rem;  
    text-align: center; /* Center align text */
    border: 1px solid #808080;
    background-color: #dad8d8; /* 밝은 배경색 */
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white; /* Or the background color of your header */
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
  }
.tables td {
    padding: 0.75rem;
    text-align: center; /* Center align text */
    border: 1px solid #808080;
    word-wrap: break-word; white-space: normal;
  }  

  .ok { background-color: #089927; color: #ffffff;   }
  .ng { background-color: #f87b6a; color: #ffffff;   }
  .need-check { background-color: rgb(235, 176, 49); color: rgb(0, 0, 0); }
  .pass { background-color: rgb(227, 227, 151); color: #000000; }
  .except { background-color: rgb(142, 142, 142); color: rgb(0, 0, 0);}

</style>