<template>
  <div class="planning">    
    <div class="act">  
      <form class="searchBar">      
        <button class="nav-button" @click.prevent="fetchDataForPrevWeek">Previous</button>        
        <button class="nav-button" @click.prevent="fetchDataForNextWeek">Next</button>
        <button class="modalbutton" @click.prevent="showModal = true">+ New Maintenance Schedule</button>    
        <button class="modalbutton" @click.prevent="showAcModal = true">Actual Maintenance Result</button>           
        <button class="excelDownload" @click="downExcel">Down Load</button>   
      </form>
    </div>
    <div class="tables">
      <table id="weekplanning" class="custom-table">
        <th>Week</th><th>MON</th><th>TUE</th><th>WED</th><th>THU</th><th>FRI</th><th class="weeksat">SAT</th><th class="weeksun">SUN</th>
        <template v-for="(week,weekIndex) in weeks" :key="week">
          <tr>
            <th rowspan="2"> Week {{ getWeekNumber(week[0]) }}</th>
            <th v-for="day in week" :key="day">{{ day.toLocaleDateString() }}</th>
          </tr>
          <tr>            
            <td v-for="(day, index) in days" :key="day">
            <div v-for="item in itemsByDate[weekIndex * 7 + index]" :key="item.id">  
              <button :class="{'planButton': item.type === 'Plan', 'actualButton': item.type === 'Actual'}" type="submit" @click="openSendModal(item.id)" >
                {{ item.line }} - {{ item.maincycle }} - {{ item.plandate }}
              </button>                
            </div>
          </td>
          </tr>
        </template>
        </table>          
      </div>        
    <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" /> 
    <actualModal v-if="showAcModal" @onClose="showAcModal = false; callSearchData();" />     
    <modifyModal v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();" />      
  </div>    
</template>

<script>
import { ref, computed, reactive, onMounted } from "vue";
import axios from "axios";
import { downloadExcel} from "../../utils.js"; 
import Modal from "./annualModal.vue";
import actualModal from "./actualModal.vue";
import modifyModal from "./modifyModal.vue";

export default {  
  components: { Modal, actualModal, modifyModal },   
  mounted () { this.initializeWeeks(), console.log(this.weeks)},
  methods: {
    openSendModal(itemId) { this.selectedRowId = itemId; this.showMoModal = true; },      
    fetchDataForPrevWeek() { this.prevWeek(); this.initializeWeeks(); this.callSearchData(); },  
    fetchDataForNextWeek() { this.nextWeek(); this.initializeWeeks(); this.callSearchData(); },      
    initializeWeeks (startDate) {
      startDate = startDate || this.weekDates[0] || new Date()
      const ONEDAY = 1000*60*60*24
      const startOfThisWeek = new Date( startDate.getTime() - ((startDate.getDay() - 1) * ONEDAY))
      const buildWeekDays = st => [...new Array(7)].map((_, i) => new Date(st.getTime() + i*ONEDAY))
      this.weeks = [...new Array(this.sizeOfWeeks)].map((_, i) => buildWeekDays(new Date(startOfThisWeek.getTime() + i*ONEDAY*7)))
    },
    getWeekNumber(date) {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);  
      const daysDiff = (date - firstDayOfYear) / (24 * 60 * 60 * 1000);
      const dayOfFirstDay = firstDayOfYear.getDay();
      const daysUntilFirstWeek = (dayOfFirstDay <= 4) ? dayOfFirstDay - 1 : dayOfFirstDay - 8;
      const weekNumber = Math.ceil((daysDiff - daysUntilFirstWeek) / 7);
      return weekNumber;
    },
    downExcel() { downloadExcel(this.itemDownLoad, "Annual.xlsx"); },
  },
  data() { return { show_edit_Modal: false, selectedRowId: null, fileName: "",  sizeOfWeeks: 4, weeks: [], }; },
  setup() {
    const showMoModal = ref(false);   
    const showAcModal = ref(false);   
    const showModal = ref(false);   
    const dbTableName = "maintenanceannual";    
    const monthlyScheduleData = reactive({ data: [], }) 
    const downLoad = reactive({ data: [], }) 

    const Item = computed(() => {return monthlyScheduleData.data.map(d => {return { ...d,};});});    
    const itemDownLoad = computed(() => {return downLoad.data.map(d => {return { ...d,};});});    

    const currentDate = ref(new Date());
    const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    const weekDates = computed(() => {
      const weekStart = new Date(currentDate.value);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);

      return Array.from({ length: 28 }, (_, i) => {
        const date = new Date(weekStart);
        date.setDate(weekStart.getDate() + i);
        return date;
      });
    });

    const prevWeek = () => {
      currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() - 28));
    };
    const nextWeek = () => {
      currentDate.value = new Date(currentDate.value.setDate(currentDate.value.getDate() + 28));
    };

    const searchData = async () => {
      const startWeek = weekDates.value[0];
      const endWeek = weekDates.value[weekDates.value.length - 1];
      endWeek.setHours(23, 59, 59, 999); // 주의 마지막 날을 23:59:59로 설정

      const formData = {
        tableName: dbTableName,
        startYear: startWeek.getFullYear(),
        startMonth: startWeek.getMonth() + 1,
        startDay: startWeek.getDate(),
        endYear: endWeek.getFullYear(),
        endMonth: endWeek.getMonth() + 1,
        endDay: endWeek.getDate(),
      };

      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { monthlyScheduleData.data = res.data });              
    };

    const searchDataDownLoad = async () => {
      const startWeek = weekDates.value[0];
      const endWeek = weekDates.value[weekDates.value.length - 1];
      endWeek.setHours(23, 59, 59, 999); // 주의 마지막 날을 23:59:59로 설정

      const formData = {
        tableName: dbTableName,
        startYear: startWeek.getFullYear(),
        startMonth: '1',
        startDay: '1',
        endYear: endWeek.getFullYear(),
        endMonth: '12',
        endDay: '31'
      };

      axios.get('/api/searchPeriod', { params: formData })
        .then((res) => { downLoad.data = res.data });              
    };
          
    const callSearchData = () => { searchData(); searchDataDownLoad(); };  
    onMounted(() => { callSearchData()});

    const itemsByDate = computed(() => {
      return weekDates.value.map(date => {
        // 서버에서 받은 데이터의 날짜와 일치하는 항목만 필터링
        return monthlyScheduleData.data.filter(item => {
          const itemDate = new Date(item.year, item.month - 1, item.day);
          return itemDate.toDateString() === date.toDateString();
        });
      });
    });
   
    return {     
      itemsByDate, prevWeek, nextWeek, showModal, showMoModal, showAcModal, days, weekDates, dbTableName, Item, itemDownLoad, callSearchData,     
    };
  }, 

}
</script>

<style scoped>
.act {
  display: flex; justify-content: space-between; align-items: center;
  margin-bottom: 1rem; margin-left : 20px;
}
.nav-button {
  background-color: #007bff; color: white;
  border: none; border-radius: 4px; cursor: pointer;
  font-weight: bold; padding: 0.1rem 0.5rem; width: 200px; 
  transition: background-color 0.3s; font-size: 20px; margin-left: 10px; margin-right: 30px;
}
.nav-button:hover { background-color: #0056b3; }

.weeknumber{text-align : center;  font-weight: bold; padding-left : 10px; padding-right : 10px; font-size : 20px;}

th {
    position: sticky; top: 0; z-index: 10;
    background-color: rgb(230, 230, 230) ; border : 1px solid black;        
    text-align : center;  padding-left : 10px; padding-right : 10px; font-size : 20px; 
}

td {
    background-color: rgb(255, 255, 255) ;  border : 1px solid black;        
    text-align : left;  padding-left : 10px; padding-right : 10px; font-size : 20px; vertical-align: top;   
}
.tables {
  display: flex; flex-direction: column; align-items: stretch; overflow: auto;
  padding-left: 20px; padding-right: 20px; height: 700px;   
} 
.custom-table { font-size: 10px; width: 80%; overflow-x: auto; white-space: nowrap;} 

.searchBar { display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center; margin-bottom: 0px; margin-left: 20px;}
.modalbutton{
  padding: 5px 5px; background-color: #5653ee; color: #ffffff; border: none; border-radius: 4px;
  font-size: 20px; font-weight: 600; margin-left: 30px; margin-right: 30px; cursor: pointer;        
  transition: background-color 0.3s;        
}
.modalbutton:hover { background-color: #8280ee; }
.excelDownload {
  background-color: #347003;  color: white;
  border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
  padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
}
.excelDownload :hover { background-color: #009625; }
.planButton{
  padding: 5px 5px; background-color: #d5efad; color: #000000; border: none; border-radius: 4px;
  font-size: 15px; font-weight: 600; margin-left: 10px; margin-right: 10px; margin-bottom: 10px; margin-top: 10px; cursor: pointer;        
  transition: background-color 0.3s; align-content: left; border : 1px solid black;  
}
.planButton:hover { background-color: #8e9f73; }
.actualButton{
  padding: 5px 5px; background-color: #3f45ee; color: #ffffff; border: none; border-radius: 4px;
  font-size: 15px; font-weight: 600; margin-left: 10px; margin-right: 10px; margin-bottom: 10px; margin-top: 10px; cursor: pointer;        
  transition: background-color 0.3s; align-content: left; border : 1px solid black;  
}
.actualButton:hover { background-color: #2c2f9d; }
.weeksat { background-color: #9cc7f8;; }
.weeksun { background-color: #f8a29c;; }

</style>