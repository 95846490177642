<template>
    <div class="maxpowerdb">            
        <h class="pagetitle">Monthly check ZYX</h>
        <div class="search">
        <form @submit.prevent="sendDate()">
            <div class="horizontal-form">                
                <div class="start">
                    <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                    <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                    <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
                </div>
                <div class="separator">~</div>
                <div class="end">
                    <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                    <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                    <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
                </div>                    
                <div class="searchTd">
                    <button type="submit" @click="callSearchData">Search</button>                        
                </div>                    
                <div class="searchTd">
                    <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
                </div>    
                <div class="searchTd">
                    <label for="file-input" class="excelFile">Excel file Choose</label>                
                    <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
                    <span class="fileName">{{ fileName }}</span>
                    <button class="custom-file-upload"  @click="upExcel('attendancepaper')">Upload</button>
                </div>                                              
            </div>      
        </form>   
        </div>     
        <div class="countTable">
            <table> 
                <tr>
                    <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
                </tr>                      
                <tr v-for="row in countData" :key="row.year + row.month + row.oscompany + row.employeenumber"  :class="{ 'different': isDifferented(row.different) }">
                    <td>{{ row.year }}</td>
                    <td>{{ row.month }}</td>
                    <td>{{ row.oscompany }}</td>
                    <td>{{ row.employeenumber }}</td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.surname }}</td>                  
                    <td>{{ row.worktimewebhour.toFixed(1) }}</td>
                    <td>{{ row.worktimepaper.toFixed(1)}}</td>
                    <td>{{ row.different.toFixed(1) }}</td>          
                    <td><button class="sendButton" type="submit" @click="openSendModal(row)">Work Time Paper Modify</button></td>                   
                </tr>
            </table>
        </div>
        <!-- <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>   -->
        
        <modifyModal v-if="showMoModal" :selected-data="selectedData" @onClose="showMoModal = false; callSearchData();"/>
    </div>    
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue';
import axios from "axios";
import modifyModal from "./monthlyModify.vue";
import { onFileChange, uploadExcel, downloadExcel} from "../../utils.js"; 

export default {  

    data() { return { excelFile: null, fileName: "", };},
    
    methods: {
        openSendModal(row) {
            this.selectedData = { 
                id: row.id,
                year: row.year,
                month: row.month,
                oscompany: row.oscompany,
                employeenumber: row.employeenumber,
                worktime: row.worktimepaper
            };            
            this.showMoModal = true;
        },
        fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
        upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
        downExcel() { downloadExcel(this.item, "Monthly_Attendance.xlsx"); },
    },

    components: { modifyModal },        

    setup() {        
        const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); 
        const dbTableName = 'attendance';        
        const paperTableName = 'attendancepaper';    
        const showMoModal = ref(false);               

        const dbData = reactive({ data: [], });
        const paperData = reactive({ data: [], });

        const headers = ['Year', 'Month', 'OS Company', "Employee Number", "First Name", 'Surname', 'Work Time(Web)', 'Work Time(Paper)', 'Different', 'Modify'];
        
        const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, ]; return {sendDateData};};     
        const searchData = async (tableName) => {
            const sendDateData = await sendDate();
            console.log('Requesting data with:', {
                tablename: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endtYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],                
            });
            const formData = {        
                tableName: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],
                startDay: sendDateData.sendDateData[2],
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],
                endDay: sendDateData.sendDateData[5],                
            };  
            console.log('Requesting data with:', formData);
            axios.get('/api/searchPeriod', { params: {...formData, oscompany: 'ZYX', },})
                .then((res) => { dbData.data = res.data; });                          
                   
        }

        const searchDataPaper = async (tableName) => {
            const sendDateData = await sendDate();
            console.log('Requesting data with:', {
                tablename: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],  
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],                            
            });
            const formData = {        
                tableName: tableName,
                startYear: sendDateData.sendDateData[0],
                startMonth: sendDateData.sendDateData[1],                            
                endYear: sendDateData.sendDateData[3],
                endMonth: sendDateData.sendDateData[4],                            
            };  
            console.log('Requesting data with:', formData);
            axios.get('/api/attendancepaper', { params: {...formData, oscompany: 'ZYX', },})
                .then((res) => { 
                    paperData.data = res.data.map(d => ({
                        ...d,
                        id: d.id // 'id' 값을 포함시킵니다.
                    })); 
                });        
        } 


        const callSearchData = () => { searchData(dbTableName); searchDataPaper(paperTableName);  };

        const item = computed(() => {return dbData.data.map(d => {return { ...d,};});});    
        const paperitem = computed(() => {return paperData.data.map(d => {return { ...d,};});});    

        onMounted(() => { searchData(dbTableName); searchDataPaper(paperTableName);  });

        const countData = computed(() => {
            const worktimeweb = {};
            const worktimepaper = {};
            const employeeDetails = {}; 

            item.value.forEach(row => {
                const key = `${row.year}|${row.month}|${row.employeenumber}`;
                if (worktimeweb[key]) {
                    worktimeweb[key] += Number(row.worktime); 
                } else {
                    worktimeweb[key] = Number(row.worktime); 
                }                
                employeeDetails[row.employeenumber] = {
                    oscompany: row.oscompany,
                    name: row.name,
                    surname: row.surname
                };
            });

            paperitem.value.forEach(row => {
                const key = `${row.year}|${row.month}|${row.employeenumber}`;
                if (worktimepaper[key]) {
                    // 이미 존재하는 key에 대해 worktime을 더합니다.
                    worktimepaper[key].worktime += Number(row.worktime);
                } else {
                    // 새로운 key라면 객체를 생성하고 초기값을 설정합니다.
                    worktimepaper[key] = {
                        worktime: Number(row.worktime),
                        id: row.id // 'id' 값을 포함시킵니다.
                    };
                }
            }); 

            let results = Object.entries(worktimeweb).map(([key, value]) => {
                const [year, month, employeenumber] = key.split('|');
                const worktimewebhour = value / 60;
                const paperKey = `${year}|${month}|${employeenumber}`;
                const worktimepaperValue = worktimepaper[paperKey] ? worktimepaper[paperKey].worktime : 0;
                const worktimepaperId = worktimepaper[paperKey] ? worktimepaper[paperKey].id : null;
                const different = worktimewebhour - worktimepaperValue;      
                const employeeDetail = employeeDetails[employeenumber] || {};

                return {
                    year: Number(year),
                    month: Number(month),
                    employeenumber,
                    worktimeweb: value,
                    worktimewebhour,
                    worktimepaper: worktimepaperValue,
                    different,
                    id: worktimepaperId,
                    oscompany: employeeDetail.oscompany || '',
                    name: employeeDetail.name || '',
                    surname: employeeDetail.surname || ''
                };
            });

            results.sort((a, b) => {
                if (a.employeenumber < b.employeenumber) return -1;
                if (a.employeenumber > b.employeenumber) return 1;
                return 0;
            });

            return results;
        });       

        const isDifferented = (value) => {
            return value !== 0;
        };

        return { headers,  item, paperitem, callSearchData, dbTableName, paperTableName, showMoModal, 
                startYear, startMonth, startDay, endYear, endMonth, endDay, sendDate, countData, isDifferented}        
    }}

</script>

<style scoped> 
    .searchtarget {border: 0px; }
    .searchtargetselect {width : 200px}
    .sendButton { padding: 5px 5px; background-color: #0d6d00; color: #fff; border: none;
                border-radius: 4px; font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px;
                margin-left: 5px; margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .confirmButton { padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
                    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
    }
    .cancelButton { padding: 5px 5px; background-color: #f38f4d; color: #fff; border: none; border-radius: 4px; 
                    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px; margin-right: 5px;
                    cursor: pointer; transition: background-color 0.3s;        
    }
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 14px; font-weight: 600; margin-right: 8px; }
    .table { display: flex; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 12px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .excelFile { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #a76106; color: #ffffff;
                 border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
    }
    .custom-file-upload:hover { background-color: #009625; }

    button { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .search { position: relative; background-color: #ffffff; padding: 0px; height: auto; margin-top: 10px; width: 70%}    
    .searchTable tr td {border: 0px; text-align: left}    
    .start input,.end input { margin-right: 5px;}
    .separator { font-size: 24px; margin: 0 10px; border: 0px; }    
    input::placeholder { color: gray; }
    select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; }
    input[type="number"], input[type="text"] { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }
    .countTable {margin-left:20px; width: 50%; margin-top: 10px;}
    .different { background-color: #ffcccc;}    
    .horizontal-form { margin-left: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>