<template>
  <div class="header">    
    <nav class="menu-nav">
      <div class="menu-titles">
        <div class="title" @click="toggleMenu(1)">Production 1st</div>
        <div class="title" @click="toggleMenu(2)">Production 2nd</div>
        <div class="title" @click="toggleMenu(3)">Inkatom</div>
        <div class="title" @click="toggleMenu(4)">Schedule</div>      
        <div class="title" @click="toggleMenu(5)">Material</div>
        <div class="title" @click="toggleMenu(6)">Report</div>
        <div class="title" @click="goToShipping">Shipping</div>        
        <div class="title" @click="toggleMenu(8)">Attendance</div>
        <div class="title" @click="toggleMenu(9)">Check & Parameter</div>
        <div class="title" @click="toggleMenu(10)">Maintenance</div>
        <div class="title" @click="toggleMenu(11)">Task & Purchase</div>
        <div class="title" @click="goToTargetList">Target</div>        
        <div class="title" @click="toggleMenu(13)">Data</div>
        <div class="title" @click="goToDashBoard">Dash Board</div>            
      </div>      
      <ul class="menu-list" v-if="activeMenu === 1">    
        <li class="violet" @click="goToTr2smtPr">TR2 SMT</li>     
        <li class="violet" @click="goToTr2crimpingPr">TR2 Crimp</li>
        <li class="violet" @click="goToTr2fpcaPr">TR2 FPCA</li>  
        <li class="violet" @click="goToTr2bfa3pPr">TR2 BFA 3P</li>                                     
        <li class="littleskyblue" @click="goToFord1frPr">Ford#1 FR</li>   
        <li class="littleskyblue" @click="goToFord1rrPr">Ford#1 RR</li>   
        <li class="littleskyblue" @click="goToFord2frPr">Ford#2 FR</li>   
        <li class="littleskyblue" @click="goToFord2rrPr">Ford#2 RR</li>   
        <li class="littleskyblue" @click="goToFord3frPr">Ford#3 FR</li>   
        <li class="littleskyblue" @click="goToFord3rrPr">Ford#3 RR</li>   
        <li class="littlegray" @click="goToFca1Pr">FCA #1</li>   
        <li class="littlegray" @click="goToFca2Pr">FCA #2</li>   
        <li class="littlegray" @click="goToBt6Pr">BT6</li>   
        <li class="orange" @click="goToBmw12vPr">BMW 12V</li>   
        <li class="orange" @click="goToBmw48vPr">BMW 48V</li>
        <li class="skyblue" @click="toggleSubMenu(1)">FCA Flexible</li>         
      </ul>      
      <ul class="menu-list" v-if="activeMenu === 2">
        <li class="violet" @click="goToTr3smtPr">TR3 SMT</li>       
        <li class="violet" @click="goToTr3crimpingPr">TR3 Crimp</li>
        <li class="violet" @click="goToTr3fpcaPr">TR3 FPCA</li>    
        <li class="violet" @click="goToTr3bfa2pPr">EVO 1</li>            
        <li class="violet" @click="goToTr3bfa3pPr">EVO 2</li>  
        <li class="violet" @click="goToTr2bfa2pPr">EVO 3</li>
        <li class="violet" @click="goToEvoPr">EVO Hybrid</li>    
        <li class="littlegray" @click="goToThermistorTape">Thermistor Tape</li>    
        <li class="littlegray" @click="goToThermistorPad">Thermistor Pad</li>   
        <li class="littlegray" @click="goToBendingEvo">Bending EVO FPCA</li>  
        <li class="littleviolet" @click="goToEv202060FrPr">EV2020 60 FR</li>    
        <li class="littleviolet" @click="goToEv202060RrPr">EV2020 60 RR</li>   
        <li class="littleviolet" @click="goToEv20204060FrPr">EV2020 4060 FR</li>   
        <li class="littleviolet" @click="goToEv20204060RrPr">EV2020 4060 RR</li>                   
      </ul>
      <ul class="menu-list" v-if="activeMenu === 3">
        <li class="violet" @click="goToInkatomFirst">1st Factory (BFA)</li>  
        <li class="littlegray" @click="goToInkatom45">1st Factory (45)</li>
        <li class="littlegray" @click="goToInkatom67">1st Factory (67)</li>
        <li class="littlegray" @click="goToInkatomPo">1st Factory (Positive)</li>
        <li class="littlegray" @click="goToInkatomNe">1st Factory (Negative)</li>
        <li class="violet" @click="goToInkatomSecond">2nd Factory (EVO)</li> 
        <li class="orange" @click="goToInkatomSearch">Period Search</li> 
      </ul> 
      <ul class="menu-list" v-if="activeMenu === 4">
        <li class="violet" @click="goToProductionPlan">Production Plan</li>
        <li class="littlegray" @click="goToSchedule">Schedule</li>       
      </ul>      
      <ul class="menu-list" v-if="activeMenu === 5">
        <li class="littlegray" @click="goToMaterialRequest">Material Request</li>
        <li class="littlegray" @click="goToRequestLog">Request Log</li>
        <li class="littlegray" @click="goToMaterialList">Material List</li>        
        <li class="skyblue" @click="toggleSubMenu(2)">Stock</li>
        <li class="littlegray" @click="goToMovementLog">Movement Log</li>
        <li class="littlegray" @click="goToScrap">Scrap</li>
        <li class="littlegray" @click="goToScrapLog">Scrap Log</li>
        <li class="littlegray" @click="goToProductionSpare">Production Spare</li>
        <li class="violet" @click="toggleSubMenu(14)">Barcode</li>
      </ul>
      <ul class="menu-list" v-if="activeMenu === 6">
        <li class="littleskyblue" @click="goTosearchPeriod">SearchPeriod</li>
        <li class="littleskyblue" @click="goToReport01">Report</li>
        <li class="littleskyblue" @click="goToReport02">Daily Report</li>   
        <li class="littleskyblue" @click="goToMonthlyReport">Monthly Report</li>   
        <li class="littleskyblue" @click="goToFailureReport">Failure Report</li>   
        <li class="littlegray" @click="goToCoAc">Corrective Action</li>   
        <li class="orange" @click="goToInvestList">Invest List</li>     
        <li class="orange" @click="goToAssetList">Asset List</li>  
        <li class="littlegray" @click="goToDocumentReport">Document Report</li>  
      </ul>      
      <ul class="menu-list" v-if="activeMenu === 8"> 
        <li class="littleskyblue" @click="toggleSubMenu(7)">Team attendance</li>        
        <li class="orange" @click="goToSearchAll">ALL List</li>   
        <li class="orange" @click="goToWeekendWork">Request Manpower</li>  
        <li class="littleviolet" @click="toggleSubMenu(8)">OS Company DB</li>       
        <li class="violet" @click="toggleSubMenu(9)">Monthly Data</li>     
        <li class="littlegray" @click="goToLineWorkerList">Line Worker List</li>
        <li class="strongskyblue" @click="toggleSubMenu(11)">Evaluation</li>
        <li class="littlegray" @click="goToLsList">LS List</li>
      </ul>
      <ul class="menu-list" v-if="activeMenu === 9">
        <li class="strongskyblue" @click="toggleSubMenu(12)">1st Check Sheet</li>
        <li class="violet" @click="toggleSubMenu(3)">2nd Check Sheet</li> 
        <li class="skyblue" @click="toggleSubMenu(4)">Parameter</li>  
        <li class="littleviolet" @click="toggleSubMenu(5)">Cleaning</li>          
        <li class="littlegray" @click="goToTakeOver">Take Over</li>    
      </ul>
      <ul class="menu-list" v-if="activeMenu === 10">        
        <li class="littlegray" @click="goToAnnualMaintenance">Annual Maintenance</li> 
        <li class="strongskyblue"  @click="toggleSubMenu(13)">1st Check Sheet</li>
        <li class="violet"  @click="toggleSubMenu(6)">2nd Check Sheet</li>
        <li class="littlegray" @click="goToMaintenanceWorkList">Maintenance Work List</li>
        <li class="littlegray" @click="goToSpareList">Spare List</li> 
        <li class="skyblue"  @click="toggleSubMenu(10)">Failure Rate</li>   
        <li class="littlegray" @click="goToEquipmentLedger">Equipment Ledger</li>         
        <li class="littlegray" @click="goToAnnualCalculation">Annul Cycle Date</li> 
        <li class="littlegray" @click="goToMachineGrade">Machine Grade</li> 
        <li class="littlegray" @click="goToConstructionInspection">Construction/equipment Inspection</li> 
      </ul>
      <ul class="menu-list" v-if="activeMenu === 11">
        <li class="littlegray" @click="goToToDoList">To Do List</li>
        <li class="littlegray"  @click="goToPurchaseList">Purchase List</li>        
        <li class="littlegray"  @click="goToPurchaseRequest">Purchase Request</li>     
      </ul>      
      <ul class="menu-list" v-if="activeMenu === 13">
        <li class="littlegray" @click="goToExcelUpload">Excel Upload</li>
        <li class="littlegray" @click="goToDataDelete">Data Delete</li>
        <li class="littlegray" @click="goToMachineData">Machine Data</li>
        <li class="littlegray" @click="goToProductionClass">Production Class</li>
        <li class="littlegray" @click="goToSelectOption">Select Option</li>
        <li class="littlegray" @click="goToSetting">Setting</li>
        <li class="littlegray" @click="goToArduino">Arduino</li>
        <li class="littlegray" @click="goToArduinoLog">Arduino Log</li>             
      </ul>
      <!--Sub Menu Start-->
      <ul class="sub-menu" v-if="activeSubMenu === 1">
        <li class="skyblue" @click="goToFcaFlexBcPr">Busbar Carrier</li>     
        <li class="skyblue" @click="goToFcaFlexPoPr">Positive</li>     
        <li class="skyblue" @click="goToFcaFlex67Pr">6+7-</li>    
        <li class="skyblue" @click="goToFcaFlexNePr">Negative</li>    
        <li class="skyblue" @click="goToFcaFlex45Pr">4-5+</li>   
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 2">
        <li class="skyblue" @click="goToStockList">Stock</li>             
        <li class="skyblue" @click="goToNotNormalStock">Not Normal Stock</li>     
        <li class="skyblue" @click="goToTrayStock">Tray Stock</li>     
        <li class="skyblue" @click="goToInOutMaterial">In-Out Material</li>   
        <li class="skyblue" @click="goToMonthlyInventoryCheck">Monthly Inventory Check</li> 
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 3">
        <li class="violet" @click="goToCheckTarget">Target</li>     
        <li class="violet" @click="goToTr3Smt">TR3 SMT</li>             
        <li class="violet" @click="goToTr3Crimping">TR3 Crimping</li> 
        <li class="violet" @click="goToTr3Fpca">TR3 FPCA</li> 
        <li class="violet" @click="goToTr3Bfa2p">EVO 1</li> 
        <li class="violet" @click="goToTr3Bfa3p">EVO 2</li> 
        <li class="violet" @click="goToEvo">EVO Hybrid</li> 
        <li class="violet" @click="goToEv202060kw">EV2020 60kW</li> 
        <li class="violet" @click="goToEv20204060kw">EV2020 4060kW</li>         
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 4">
        <li class="skyblue" @click="goToEolConnectorPin">EOL Connector Pin</li>             
        <li class="skyblue"  @click="goToSmtLaser">SMT Laser</li>   
        <li class="skyblue"  @click="goToBfaQrLaser">QR Marking Laser</li>   
        <li class="skyblue"  @click="goToBfaWeldLaser">Weld Laser</li>   
        <li class="skyblue" @click="goToCalibrationDate">Calibration</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 5">
        <li class="littleviolet" @click="goToCleaningCycle1st">Cleaning Cycle 1st</li>     
        <li class="littleviolet" @click="goToCleaningActual1st">Cleaning Actual 1st</li> 
        <li class="littleviolet" @click="goToCleaningCycle2nd">Cleaning Cycle 2nd</li>  
        <li class="littleviolet" @click="goToCleaningActual2nd">Cleaning Actual 2nd</li>    
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 6">
        <li class="violet" @click="goToMaintenanceCheckTarget">Target</li>             
        <li class="violet" @click="goToMaintenanceCheckTR3SMT">TR3 SMT</li>   
        <li class="violet" @click="goToMaintenanceCheckTR3Crimping">TR3 Crimping</li>  
        <li class="violet" @click="goToMaintenanceCheckTR3FPCA">TR3 FPCA</li>  
        <li class="violet" @click="goToMaintenanceCheckEVO1">EVO 1</li>  
        <li class="violet" @click="goToMaintenanceCheckEVO2">EVO 2</li>  
        <li class="violet" @click="goToMaintenanceCheckEVOHybrid">EVO Hybrid</li>          
        <li class="violet" @click="goToMaintenanceCheckEV202060">EV2020 60kW</li>          
        <li class="violet" @click="goToMaintenanceCheckEV20204060">EV2020 4060kW</li>          
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 7">
        <li class="littleskyblue" @click="goToProduction1st">Production 1st List</li>     
        <li class="littleskyblue" @click="goToProduction2nd">Production 2nd List</li>     
        <li class="littleskyblue" @click="goToQA">QA List</li>   
        <li class="littleskyblue" @click="goToWarehouse">Warehouse List</li>   
        <li class="littleskyblue" @click="goToInkatom">Inkatom List</li>   
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 8">
        <li class="littleviolet" @click="goToMaxPowerDB">Max Power DB</li>    
        <li class="littleviolet" @click="goToMegaStaffDB">Mega Staff DB</li>    
        <li class="littleviolet" @click="goToZxyDB">ZYX DB</li>           
        <li class="littleviolet" @click="goToInkatomDB">Inkatom DB</li>  
        <li class="littleviolet" @click="goToLsevpDB">LSEVP DB</li>  
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 9">
        <li class="violet" @click="goToMonthlyMaxPower">Monthly Max Power</li>
        <li class="violet" @click="goToMonthlyMegaStaff">Monthly Mega Staff</li>
        <li class="violet" @click="goToMonthlyZyx">Monthly ZYX</li>
        <li class="violet" @click="goToMonthlyInkatom">Monthly Inkatom</li>
        <li class="violet" @click="goToMonthlyLineCost">Monthly Line Cost</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 10">
        <li class="skyblue"  @click="goToFailureTarget">Failure Target</li>  
        <li class="skyblue"  @click="goToFailureClass">Failure Class</li>                     
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 11">
        <li class="strongskyblue" @click="goToEvaluationLine">Evaluation Line Base</li>
        <li class="strongskyblue" @click="goToEvaluationPerson">Evaluation Person Base</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 12">
        <li class="strongskyblue" @click="goToCheckTarget">Target</li>     
        <li class="strongskyblue" @click="goToTr2Smt">TR2 SMT</li>             
        <li class="strongskyblue" @click="goToTr2Crimping">TR2 Crimping</li> 
        <li class="strongskyblue" @click="goToTr2Fpca">TR2 FPCA</li>       
        <li class="strongskyblue" @click="goToCheckBmw12v">BMW 12V</li>       
        <li class="strongskyblue" @click="goToCheckBmw48v">BMW 48V</li>
        <li class="strongskyblue" @click="goToCheckFcaFlexBc">FCA FLEX BC</li>
        <li class="strongskyblue" @click="goToCheckFca2">FCA2</li>
        <li class="strongskyblue" @click="goToCheckFord2Front">Ford 2 Front</li>
        <li class="strongskyblue" @click="goToCheckFord2Rear">Ford 2 Rear</li>
        <li class="strongskyblue" @click="goToCheckFord3Front">Ford 3 Front</li>
        <li class="strongskyblue" @click="goToCheckFord3Rear">Ford 3 Rear</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 13">
        <li class="strongskyblue" @click="goToMaintenanceCheckTarget">Target</li>             
        <li class="strongskyblue" @click="goToMaintenanceCheckTR2SMT">TR2 SMT</li>           
        <li class="strongskyblue" @click="goToMaintenanceCheckTR2Crimping">TR2 Crimping</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckTR2Fpca">TR2 FPCA</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckFord2Front">Ford 2 Front</li>      
        <li class="strongskyblue" @click="goToMaintenanceCheckFord2Rear">Ford 2 Rear</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckFord3Front">Ford 3 Front</li>      
        <li class="strongskyblue" @click="goToMaintenanceCheckFord3Rear">Ford 3 Rear</li>           
        <li class="strongskyblue" @click="goToMaintenanceCheckBMW48V">BMW 48V</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckBMW12V">BMW 12V</li>   
        <li class="strongskyblue" @click="goToMaintenanceCheckFca2">FCA 2</li>   
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 14">
        <li class="violet" @click="goToBarcodePrint">Barcode Print</li>             
        <li class="violet" @click="goToBarcodeRead">Barcode Read</li>                   
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {      
      activeMenu: null, // 활성화된 메뉴를 추적
      activeSubMenu: null, // 활성화된 서브메뉴를 추적
    };
  },
  methods: {    
    toggleMenu(menuNumber) {if (this.activeMenu === menuNumber) {this.activeMenu = null; this.activeSubMenu = null;} else {this.activeMenu = menuNumber; this.activeSubMenu = null; }},
    toggleSubMenu(subMenuNumber) {if (this.activeSubMenu === subMenuNumber) {this.activeSubMenu = null;} else {this.activeSubMenu = subMenuNumber; }},
    
    /*toggleSubMenu(subMenu) {
      this.activeSubMenu = this.activeSubMenu === subMenu ? null : subMenu;
    },*/
    goToTr2smtPr() { this.$router.push('/tr2smtpr'); },
    goToTr2fpcaPr() { this.$router.push('/tr2fpcapr'); },
    goToTr2crimpingPr() { this.$router.push('/tr2crimpingpr'); },
    goToTr2bfa2pPr() { this.$router.push('/tr2bfa2ppr'); },
    goToTr2bfa3pPr() { this.$router.push('/tr2bfa3ppr'); },
    goToFord1frPr() { this.$router.push('/ford1frpr'); },
    goToFord1rrPr() { this.$router.push('/ford1rrpr'); },
    goToFord2frPr() { this.$router.push('/ford2frpr'); },
    goToFord2rrPr() { this.$router.push('/ford2rrpr'); },
    goToFord3frPr() { this.$router.push('/ford3frpr'); },
    goToFord3rrPr() { this.$router.push('/ford3rrpr'); },
    goToFca1Pr() { this.$router.push('/fca1pr'); },
    goToFca2Pr() { this.$router.push('/fca2pr'); },
    goToBt6Pr() { this.$router.push('/bt6pr'); },
    goToBmw12vPr() { this.$router.push('/bmw12vpr'); },
    goToBmw48vPr() { this.$router.push('/bmw48vpr'); },
    goToFcaFlexBcPr() { this.$router.push('/fcaflexbcpr'); },    
    goToFcaFlexPoPr() { this.$router.push('/fcaflexpopr'); },  
    goToFcaFlex67Pr() { this.$router.push('/fcaflex67pr'); },  
    goToFcaFlexNePr() { this.$router.push('/fcaflexnepr'); }, 
    goToFcaFlex45Pr() { this.$router.push('/fcaflex45pr'); }, 
    goToTr3smtPr() { this.$router.push('/tr3smtpr'); },
    goToTr3crimpingPr() { this.$router.push('/tr3crimpingpr'); },       
    goToTr3fpcaPr() { this.$router.push('/tr3fpcapr'); },   
    goToTr3bfa2pPr() { this.$router.push('/tr3bfa2ppr'); },   
    goToTr3bfa3pPr() { this.$router.push('/tr3bfa3ppr'); },   
    goToEvoPr() { this.$router.push('/evopr'); },    
    goToThermistorTape() { this.$router.push('/thermistortape'); },        
    goToThermistorPad() { this.$router.push('/thermistorpad'); },        
    goToBendingEvo() { this.$router.push('/bendingevo'); },   
    
    goToEv202060FrPr() { this.$router.push('/ev202060frpr'); },     
    goToEv202060RrPr() { this.$router.push('/ev202060rrpr'); },     
    goToEv20204060FrPr() { this.$router.push('/ev20204060frpr'); },     
    goToEv20204060RrPr() { this.$router.push('/ev20204060rrpr'); },     
    goTosearchPeriod() { this.$router.push('/searchPeriod'); },    
    goToReport01() { this.$router.push('/report01'); },
    goToReport02() { this.$router.push('/report02'); },
    goToMonthlyReport() { this.$router.push('/monthlyreport'); },
    goToCoAc() { this.$router.push('/correctiveaction'); },
    goToInvestList() { this.$router.push('/investlist'); }, 
    goToAssetList() { this.$router.push('/assetlist'); }, 
    goToDocumentReport() { this.$router.push('/documentreport'); }, 

    goToShipping() { this.$router.push('/shipping'); this.activeMenu = null; this.activeSubMenu = null; },
    goToTargetList() { this.$router.push('/targetList'); this.activeMenu = null; this.activeSubMenu = null; },
    goToExcelUpload() { this.$router.push('/excelupload'); },
    goToDataDelete() { this.$router.push('/datadelete'); },
    goToScrap() { this.$router.push('/scrap'); this.activeSubMenu = null; },
    goToScrapLog() { this.$router.push('/scraplog'); this.activeSubMenu = null;},
    
    goToCheckTarget() { this.$router.push('/checktarget'); },    
    goToTr3Smt() { this.$router.push('/tr3smtchecksheet'); },      
    goToTr3Crimping() { this.$router.push('/tr3crimpingchecksheet'); },
    goToTr3Fpca() { this.$router.push('/tr3fpcachecksheet'); },
    goToTr3Bfa2p() { this.$router.push('/evo1checksheet'); },
    goToTr3Bfa3p() { this.$router.push('/evo2checksheet'); },
    goToEvo() { this.$router.push('/evohybridchecksheet'); },
    goToEv202060kw() { this.$router.push('/ev202060kwchecksheet'); },
    goToEv20204060kw() { this.$router.push('/ev20204060kwchecksheet'); },  
    goToTr2Smt() { this.$router.push('/tr2smtchecksheet'); },      
    goToTr2Crimping() { this.$router.push('/tr2crimpingchecksheet'); },
    goToTr2Fpca() { this.$router.push('/tr2fpcachecksheet'); },        
    goToCheckBmw48v() { this.$router.push('/bmw48vchecksheet'); },
    goToCheckBmw12v() { this.$router.push('/bmw12vchecksheet'); },
    goToCheckFcaFlexBc() { this.$router.push('/fcaflexbcchecksheet'); },
    goToCheckFca2() { this.$router.push('/fca2checksheet'); },
    goToCheckFord2Front() { this.$router.push('/ford2frontchecksheet'); },
    goToCheckFord2Rear() { this.$router.push('/ford2rearchecksheet'); },
    goToCheckFord3Front() { this.$router.push('/ford3frontchecksheet'); },
    goToCheckFord3Rear() { this.$router.push('/ford3rearchecksheet'); },

    goToEolConnectorPin() { this.$router.push('/eolConnectorPin'); }, 
    goToSmtLaser() { this.$router.push('/smtlaser'); }, 
    goToBfaQrLaser() { this.$router.push('/bfaqrlaser'); }, 
    goToBfaWeldLaser() { this.$router.push('/bfaweldlaser'); }, 
    goToCalibrationDate() { this.$router.push('/calibrationDate'); },   

    goToCleaningCycle1st() { this.$router.push('/cleaningcycle1st'); },    
    goToCleaningActual1st() { this.$router.push('/cleaningactual1st'); },      
    goToCleaningCycle2nd() { this.$router.push('/cleaningcycle2nd'); },    
    goToCleaningActual2nd() { this.$router.push('/cleaningactual2nd'); },  
    
    goToTakeOver() { this.$router.push('/takeover'); },  
    
    goToInkatomFirst() { this.$router.push('/inkatomFirst'); },    
    goToInkatom45() { this.$router.push('/inkatom45'); },     
    goToInkatom67() { this.$router.push('/inkatom67'); },     
    goToInkatomPo() { this.$router.push('/inkatomPo'); },     
    goToInkatomNe() { this.$router.push('/inkatomNe'); },     
    goToInkatomSecond() { this.$router.push('/inkatomSecond'); },      
    goToInkatomSearch() { this.$router.push('/inkatomSearch'); },  

    goToAnnualMaintenance() { this.$router.push('/annualmaintenance'); },
    goToMaintenanceCheckTarget() { this.$router.push('/maintenancechecktarget'); },
    goToMaintenanceCheckTR3SMT() { this.$router.push('/maintenancechecktr3smt'); },  
    goToMaintenanceCheckTR3Crimping() { this.$router.push('/maintenancechecktr3crimping'); },  
    goToMaintenanceCheckTR3FPCA() { this.$router.push('/maintenancechecktr3fpca'); },  
    goToMaintenanceCheckEVO1() { this.$router.push('/maintenancecheckevo1'); },  
    goToMaintenanceCheckEVO2() { this.$router.push('/maintenancecheckevo2'); }, 
    goToMaintenanceCheckEVOHybrid() { this.$router.push('/maintenancecheckevohybrid'); }, 
    goToMaintenanceCheckEV202060() { this.$router.push('/maintenancecheckev202060'); },
    goToMaintenanceCheckEV20204060() { this.$router.push('/maintenancecheckev20204060'); },
    goToMaintenanceCheckTR2SMT() { this.$router.push('/maintenancechecktr2smt'); },  
    goToMaintenanceCheckTR2Crimping() { this.$router.push('/maintenancechecktr2crimping'); },  
    goToMaintenanceCheckTR2Fpca() { this.$router.push('/maintenancechecktr2fpca'); },  
    goToMaintenanceCheckFord2Front() { this.$router.push('/maintenancecheckford2front'); },      
    goToMaintenanceCheckFord2Rear() { this.$router.push('/maintenancecheckford2rear'); },     
    goToMaintenanceCheckFord3Front() { this.$router.push('/maintenancecheckford3front'); },      
    goToMaintenanceCheckFord3Rear() { this.$router.push('/maintenancecheckford3rear'); },      
    goToMaintenanceCheckBMW48V() { this.$router.push('/maintenancecheckbmw48v'); },  
    goToMaintenanceCheckBMW12V() { this.$router.push('/maintenancecheckbmw12v'); },  
    goToMaintenanceCheckFca2() { this.$router.push('/maintenancecheckfca2'); },  
    
    goToMaintenanceWorkList() { this.$router.push('/maintenanceworklist'); this.activeSubMenu = null; },      
    goToFailureTarget() { this.$router.push('/failuretarget'); },  
    goToFailureClass() { this.$router.push('/failureclass'); },   
    goToFailureReport() { this.$router.push('/failurereport'); },  
    goToEquipmentLedger() { this.$router.push('/equipmentledger'); },  
    goToAnnualCalculation() { this.$router.push('/annualcalculation'); },  
    goToMachineGrade() { this.$router.push('/machinegrade'); },  
    goToConstructionInspection() { this.$router.push('/constructioninspection'); },  
    
    goToSpareList() { this.$router.push('/spareList');this.activeSubMenu = null; }, 
        
    goToMaterialRequest() { this.$router.push('/materialrequest'); this.activeSubMenu = null; },
    goToRequestLog() { this.$router.push('/requestlog'); this.activeSubMenu = null; },
    goToMaterialList() { this.$router.push('/materiallist'); this.activeSubMenu = null; },
    goToStockList() { this.$router.push('/stockList');  },        
    goToNotNormalStock() { this.$router.push('/notnormalstock'); },     
    goToTrayStock() { this.$router.push('/trayStock'); },     
    goToInOutMaterial() { this.$router.push('/inoutmaterial'); },     
    goToMonthlyInventoryCheck() { this.$router.push('/monthlyinventorycheck'); }, 
    goToMovementLog() { this.$router.push('/movementlog'); this.activeSubMenu = null; },
    goToProductionSpare() { this.$router.push('/productionspare'); this.activeSubMenu = null; },
    goToBarcodePrint() { this.$router.push('/barcodeprint'); },
    goToBarcodeRead() { this.$router.push('/barcoderead'); },

    goToProductionPlan() { this.$router.push('/productionplan'); },
    goToSchedule() { this.$router.push('/monthlyschedule'); },   
    
    goToProduction1st() { this.$router.push('/production1stattendance'); },    
    goToProduction2nd() { this.$router.push('/production2ndattendance'); },    
    goToQA() { this.$router.push('/qaattendance'); },    
    goToWarehouse() { this.$router.push('/warehouseattendance'); },    
    goToInkatom() { this.$router.push('/inkatomattendance'); },
    goToSearchAll() { this.$router.push('/attendancesearchall'); this.activeSubMenu = null; },    
    goToWeekendWork() { this.$router.push('/weekendWork'); this.activeSubMenu = null; },      
    goToMaxPowerDB() { this.$router.push('/maxpowerdb'); },  
    goToMegaStaffDB() { this.$router.push('/megastaffdb'); }, 
    goToZxyDB() { this.$router.push('/zxydb'); }, 
    goToInkatomDB() { this.$router.push('/inkatomdb'); }, 
    goToLsevpDB() { this.$router.push('/lsevpdb'); }, 
    
    goToMonthlyMaxPower() { this.$router.push('/monthlymaxpower'); }, 
    goToMonthlyMegaStaff() { this.$router.push('/monthlymegastaff'); }, 
    goToMonthlyZyx() { this.$router.push('/monthlyzyx'); }, 
    goToMonthlyInkatom() { this.$router.push('/monthlyinkatom'); }, 
    goToMonthlyLineCost() { this.$router.push('/monthlylinecost'); },
    goToLineWorkerList() { this.$router.push('/lineworkerlist'); this.activeSubMenu = null; }, 
    goToEvaluationLine() { this.$router.push('/evaluationline'); }, 
    goToEvaluationPerson() { this.$router.push('/evaluationperson'); }, 
    goToLsList() { this.$router.push('/lslist'); this.activeSubMenu = null;}, 

    goToDashBoard() { this.$router.push('/dashboard'); this.activeMenu = null; this.activeSubMenu = null; },    
    goToToDoList() { this.$router.push('/todolist'); },
    goToPurchaseList() { this.$router.push('/purchaselist'); },
    goToPurchaseRequest() { this.$router.push('/purchaserequest'); },
    goToMachineData() {this.$router.push('/machinedata');},
    goToProductionClass() {this.$router.push('/productionclass');},
    goToSelectOption() {this.$router.push('/selectoption');},
    goToSetting() { this.$router.push('/setting'); },    
    goToArduino() { this.$router.push('/arduino'); },    
    goToArduinoLog() { this.$router.push('/arduinolog'); },    
  },
};
</script>

<style scoped>
.header { font-family: Arial, sans-serif; text-align: left; margin-bottom: 1rem; padding-left: 10px; }
h1 { font-size: 1.5rem; text-align: left; margin-bottom: 0.5rem;}
/* .menu-nav { display: flex; justify-content: left; flex-direction: column; } */
.menu-titles { margin-top: 10px; display: flex;  justify-content: left;  }
.menu-titles div { cursor: pointer; padding: 0.5rem; background-color: #4f54eb; border: 1px solid #ccc; margin-right: 10px; color: white;}
.menu-titles div:hover {  cursor: pointer; padding: 0.5rem; background-color: #262bbb; border: 1px solid #ccc; margin-right: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.menu-list { display: flex; list-style: none; padding: 0; margin: 0; margin-top: 0.5rem;  margin-bottom: 0.2rem; /* Added margin between the two lists */}
.title { font-weight: bold; font-size : 20px;}
li { font-size: 1rem; text-align: center; margin-left: 10px; padding: 0.3rem; border: 1px solid #000000; }
.sub-menu { display: flex; list-style: none; padding: 0; margin: 0; margin-top: 0.5rem;  margin-bottom: 0.2rem; }

.littlegray {
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #f1f1f1; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.littlegray:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.skyblue {
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #9ecef5; border: 1px solid #5e78ff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.skyblue:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.littleskyblue {
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #c6d3ff; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);  
}
.littleskyblue:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.orange {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #f5c04e; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.orange:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.littleviolet {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #c06ef7; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.littleviolet:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.violet {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #4b20af; color: white; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.violet:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.strongskyblue {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #3a9dee; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.strongskyblue:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}
</style>