<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Check Item and Target</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>
                  <div class="form-group">
                <label for="line">Line:</label>
                <select id="line" v-model="formInputs.line">
                  <option v-for="line in uniqueLines" :key="line">{{ line }}</option>                  
                </select>              
              </div>
              <div class="form-group">
                <label for="station">Station:</label>
                <select id="station" v-model="formInputs.station">
                  <option v-for="station in uniqueStations" :key="station">{{ station }}</option>
                </select>              
              </div>
              <div class="form-group">
              <label for="stationrow">Station Row:</label>
              <input type="number" id="stationrow" v-model="formInputs.station_row" />
            </div>
              <div class="form-group">
                <label for="part">Part:</label>
                <input type="text" id="part" v-model="formInputs.part" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="item">Item:</label>
              <input type="text" id="item" v-model="formInputs.item" />
            </div>
            <div class="form-group">
              <label for="lowerlimit">Lower Limit:</label>
              <input type="text" id="lowerlimit" v-model="formInputs.lowerlimit" />
            </div>
            <div class="form-group">
              <label for="standvalue">Standard Value:</label>
              <input type="text" id="standvalue" v-model="formInputs.standvalue" />
            </div>
            <div class="form-group">
              <label for="upperlimit">Upper Limit:</label>
              <input type="text" id="upperlimit" v-model="formInputs.upperlimit" />
            </div>
            <div class="form-group">
              <label for="unit">Unit:</label>
              <input type="text" id="unit" v-model="formInputs.unit" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="cycle">Cycle:</label>
              <input type="text" id="cycle" v-model="formInputs.cycle" />
            </div>
            <div class="form-group">
              <label for="week">Week:</label>
              <input type="text" id="week" v-model="formInputs.week" />
            </div>
            <div class="form-group date-time-group">
              <label>Cycle Year/Month/Day:</label>
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="formInputs.cycleyear"  />
                <input type="text" id="month" v-model="formInputs.cyclemonth" />
                <input type="text" id="day" v-model="formInputs.cycleday" />                  
              </div> 
            </div>   
          </td>
          <td>
            <div class="form-group">
              <label for="updatedate">Update Date(yyyy/mm/dd):</label>
              <input type="text" id="updatedate" v-model="formInputs.updatedate" />
            </div>
            <div class="form-group">
              <label for="acceptance">Acceptance:</label>
              <input type="text" id="acceptance" v-model="formInputs.acceptance" />
            </div>
            <div class="form-group">
              <label for="instruction">Instruction:</label>
              <input type="text" id="instruction" v-model="formInputs.instruction" />
            </div>
            <div class="form-group">
              <label for="remark">Remark:</label>
              <input type="text" id="remark" v-model="formInputs.remark" />
            </div>
            <div class="form-group">
              <label for="row_no">Row No:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" />
            </div>
            </td>
            </tr>
            </table>
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, ref, watch } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "maintenancechecktarget"    
        const uniqueLines = ref([]);   
        const uniqueStations = ref([]);     
        
        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          line: "", station: "", part: "", item: "",
          lowerlimit: "", standvalue: "", upperlimit: "",
          unit: "", updatedate: "", acceptance: "", 
          instruction: "", cycle: "", week: "", cycleyear: "",
          cyclemonth: "", cycleday: "", remark: "",
          station_row: "", row_no: "", except_row: "",          
        });
        // -끝-   
 
        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!formInputs.line || !formInputs.station_row || !formInputs.row_no ) {
            alert("Please fill in all the fields: line, and model.");
            return;
          }

          const columnMapping = {
            line : 'line',
            station : 'station', 
            part : 'part',
            item : 'item',
            lowerlimit : 'lowerlimit',
            standvalue : 'standvalue',
            upperlimit : 'upperlimit',
            unit : 'unit',
            updatedate : 'updatedate',
            acceptance : 'acceptance',
            instruction : 'instruction',
            cycle : 'cycle',
            week : 'week',
            cycleyear : 'cycleyear',
            cyclemonth : 'cyclemonth',
            cycleday : 'cycleday',
            remark : 'remark',    
            station_row : 'station_row',
            row_no : 'row_no',
            except_row : 'except_row',
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              line : formInputs.line,       
              station : formInputs.station, 
              part : formInputs.part,
              item : formInputs.item,
              lowerlimit : formInputs.lowerlimit,
              standvalue : formInputs.standvalue,
              upperlimit : formInputs.upperlimit,
              unit : formInputs.unit,
              updatedate : formInputs.updatedate,
              acceptance : formInputs.acceptance,
              instruction : formInputs.instruction,
              cycle : formInputs.cycle,
              week : formInputs.week,
              cycleyear : formInputs.cycleyear,
              cyclemonth : formInputs.cyclemonth,
              cycleday : formInputs.cycleday,
              remark : formInputs.remark,    
              station_row : formInputs.station_row,
              row_no : formInputs.row_no,
              except_row : "",
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };

        const fetchLine = () => {
          const tableName = 'machinedata';         
          console.log("fetchmaterialname called with materialcode:", formInputs.line);
          axios
            .get('/api/where2', { params: { table_name: tableName } })
            .then((res) => {
              console.log('Response from /api/targetList:', res.data);       

              // Set the fetched materialname to formInputs.materialname
              if (res.data && res.data.length > 0) {
                const lines = res.data.map(item => item.line);
                uniqueLines.value = [...new Set(lines)];  // 중복 값을 제거                
              } 
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };  
        const fetchStation = () => {
          const tableName = 'machinedata';         
          console.log("fetchmaterialname called with materialcode:", formInputs.line);
          axios
            .get('/api/where2', { params: { conditions: [
                {column: 'line', value: formInputs.line}],   
                table_name: tableName } })
            .then((res) => {
              console.log('Response from /api/where:', res.data);       

              // Set the fetched materialname to formInputs.materialname
              if (res.data && res.data.length > 0) {
                const stations = res.data.map(item => item.station);
                uniqueStations.value = [...new Set(stations)];  // 중복 값을 제거하여 uniqueStations에 const할당
                
              } else {
                formInputs.station = "";
              }
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };  

      fetchLine();
      watch(() => formInputs.line, () => {fetchStation(); });
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-  
  
      return { dbTableName, formInputs, submitForm, closeModal, uniqueLines, uniqueStations };

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 


};
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex; justify-content: center; align-items: center;
    }
    
    .modal-wrapper { width: 100%;  max-width: 1000px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
  </style>