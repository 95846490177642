<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Downtime</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">            
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
              </div>                                    
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option>2P12S</option><option>3P8S</option><option>EVO_2P</option><option>EVO_3P</option>
              </select>              
            </div>            
            <div class="form-group">
              <label for="start_hour">Start time</label>
              <div class="time-inputs">
                  <label for="start_hour">H:</label>
                  <input type="number" id="start_hour" v-model="startHour" min="0" max="23" />
                  <label for="start_minute">M:</label>
                  <input type="number" id="start_minute" v-model="startMinute" min="0" max="59" />
              </div>
            </div>            
            <div class="form-group">
              <label for="end_hour">End time</label>
              <div class="time-inputs">
                  <label for="end_hour">H:</label>
                  <input type="number" id="end_hour" v-model="endHour" min="0" max="23" />
                  <label for="end_minute">M:</label>
                  <input type="number" id="end_minute" v-model="endMinute" min="0" max="59" />
              </div>
            </div>  
            <div class="time">
              <label for="time">Time:</label>
              <input type="number" id="time" v-model="formInputs.time" readonly class="readWindow" />              
            </div>
          </td>
          <td>          
            <div class="form-group">
              <label for="class1">CLASS 1:</label>
              <select id="class1" v-model="formInputs.class1">
                <option v-for="class1 in uniqueClass1" :key="class1">{{ class1 }}</option>                
              </select>                              
            </div>
            <div class="form-group">
              <label for="class2">CLASS 2:</label>
              <select id="class2" v-model="formInputs.class2">
                <option v-for="class2 in uniqueClass2" :key="class2">{{ class2 }}</option>                
              </select>   
            </div>                
            <div class="form-group">
              <label for="class3">CLASS 3:</label>
              <select id="station" v-model="formInputs.class3">
                <option v-for="station in uniqueStations" :key="station">{{ station }}</option>
              </select>  
            </div>
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="detail">Detail:</label>
              <textarea id="detail" v-model="formInputs.detail" rows="4" cols="25"></textarea>              
            </div>          
          </td>
          </tr>
          </table>                          
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref} from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, setupWatchers, fetchStation, productionFetchClass1, productionFetchClass2} from "../utils.js"; 
   
  export default {                
    setup(props, { emit }) {    
      const dbTableName = "tr3fpcadt"      
      const lineName ="TR3 FPCA";             
      
      const uniqueClass1 = ref([]);
      const uniqueClass2 = ref([]);
      const uniqueStations = ref([]);         
      
      const formInputs = reactive({
        shift: "", model: "", start_time: "", end_time: "", time: "",
        class1: "", class2: "", class3: "", detail: "",
      });      

      const { startHour, startMinute, endHour, endMinute } = setupWatchers(formInputs);
    
      const searchDate = reactive({ ...getCurrentDate(), });  

      const loadStations = async (line) => { uniqueStations.value = await fetchStation(line);};
      loadStations(lineName);      

      const loadClass1 = async (line) => { uniqueClass1.value = await productionFetchClass1(line); };
      loadClass1(lineName);

      const loadClass2 = async (line, class1) => { uniqueClass2.value = await productionFetchClass2(line, class1); }; 
      watch(() => formInputs.class1, (newClass) => { loadClass2(lineName, newClass); }); 
      
      const submitForm = async () => {
        if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
          alert("Please fill in all the fields: year, month, day and model.");
          return;
        }

        const columnMapping = {    
          year: 'year', month: 'month', day: 'day', shift: 'shift', model: 'model',
          start_time: 'start_time', end_time: 'end_time', time: 'time',
          class1: 'class1', class2: 'class2', class3: 'class3',
          detail: 'detail',            
        }    

        const formData = {
          tableName: dbTableName,
          columnMapping: columnMapping,
          values: {
            year: searchDate.year,
            month: searchDate.month,
            day: searchDate.day,
            shift: formInputs.shift,
            model: formInputs.model,
            start_time: formInputs.start_time,
            end_time: formInputs.end_time,
            time: formInputs.time,
            class1: formInputs.class1,
            class2: formInputs.class2,
            class3: formInputs.class3,
            detail: formInputs.detail,  
          }
        };        
        await axios.post('/api/post', formData);        
        closeModal();
      };

      const closeModal = () => { emit("onClose"); };        
        
      return { searchDate, dbTableName, formInputs, submitForm, closeModal,
               startHour, startMinute, endHour, endMinute,
               uniqueStations, uniqueClass1, uniqueClass2,
      };
    },     

    props: { onSubmit: { type: Function, required: true,},}, 
  };
  
</script>
    
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }       
    .modal-wrapper { width: 100%;  max-width: 1000px; }    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem;
    }    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }  
    .modal-header h3 { font-size: 1.2rem; }  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }  
    label { font-weight: bold; }  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }  
    input[type="number"] { width: 100%;}    
    .readWindow[readonly] { background-color: #f0f0f0; }  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }  
    button[type="submit"]:hover { background-color: #45a049; }  
    table { table-layout: fixed; width: 100%; }  
    td { width: 20%; vertical-align: top; padding: 0 15px; }    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 100px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }     
  </style>