<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-container">
        <div class="modal-header">          
          <table class="title">            
            <tr>
              <td><h3>Check Sheet (4) </h3></td>
              <td><button class="button" :class="{ 'disabled': isSaving }" type="submit" id="save" @click="submitAllDatas" :disabled="isSaving">Save</button></td>              
              <td><span v-if="isSaving">Saving...</span></td>
            </tr>
          </table>        
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitData">        
          <table>
            <tr>
              <td>
                <div class="form-group date-time-group">
                  <label>Year/Month/Day:</label>
                  <div class="date-time-inputs">
                    <input type="text" id="year" v-model="searchDate.year"  />
                    <input type="text" id="month" v-model="searchDate.month" />
                    <input type="text" id="day" v-model="searchDate.day" />                  
                  </div> 
                </div>
                </td>
                <td>
                  <div class="form-group twoinputs">
                  <label for="line">Line Name/Shift:</label>
                  <div class="twoinputs">
                    <input type="text" id="line" :value="formInputs.line" readonly class="readWindow" />                          
                    <input type="number" clsss="shiftInput" id="shift" readonly class="readWindow" v-model="formInputs.shift"/>
                  </div>
                </div>   
                </td>
                <td>
                  <div class="form-group">
                    <label for="time">Check Time</label>
                    <div class="time-inputs">
                      <label for="time_hour">H:</label>
                      <input type="number" id="time_hour" v-model="timeHour" min="0" max="23" />
                      <label for="time_minute">M:</label>
                      <input type="number" id="time_minute" v-model="timeMinute" min="0" max="59" />
                  </div>
                </div>    
                </td>
                <td>
                  <div class="form-group">
                  <label for="model">Model:</label>
                  <select class="modelSelect" v-model="formInputs.model">            
                    <option>EVO_2P</option><option>EVO_3P</option>
                  </select>              
                </div>  
                </td>
                <td>
                <div class="form-group">
                  <label for="technician">Technician:</label>
                  <input type="text" id="technician" v-model="formInputs.technician" />              
                </div>    
                </td>
            </tr>
          </table>    

            <div v-for="(process, processIndex) in processs" :key="processIndex">
              <h3 class="processName">{{ processNames[processIndex] }}</h3>
              <div class="runningLocation">
                <label class="running" >Running :</label>
                <input class="radio" type="radio" :id="'yes' + processIndex" value="1" v-model="processRun[processIndex]">
                <label class="running" :for="'yes' + processIndex">Yes</label>
                <input class="radio" type="radio" :id="'no' + processIndex" value="0" v-model="processRun[processIndex]">
                <label class="running" :for="'no' + processIndex">No</label>
              </div>
              <table class="mainTable" v-if="displayContext.show">
                <tr>
                  <th>Item</th><th>Standard</th><th>Value</th><th>Unit</th><th>Judgement</th><th>Acceptance</th><th>Remark</th>
                </tr>
                <tr v-for="(item, index) in process" :key="index">               
                  <td>{{ item.item }}</td>               
                  <td>{{ formatStandard(item) }}</td>
                  <td>                    
                    <input v-if="isRange(item)" class="value" type="text" v-model="item.value" @change="updateJudgement(item)" />      
                    <select v-else class="value" v-model="item.value" @change="updateJudgement(item)">
                      <option>O</option><option>X</option><option></option>
                    </select>
                  </td>
                  <td>{{ item.unit }}</td>               
                  <td :class="{ 'ok': item.judgement === 'ok', 'ng': item.judgement === 'ng', 'pass': item.judgement === 'Pass', 'need-check': item.judgement === 'Need Check' , 'except': item.judgement === 'Except' || item.judgement === 'Not Running' }" >{{ item.judgement }}</td>                    
                  <td>{{ item.acceptance }}</td>         
                  <td><input class="value" type="text" v-model="item.remark" /></td>
                </tr>
              </table>
              <button class="process_save" @click="submitProcessData(processIndex)">
                Process {{ processIndex + 1 }} Save
              </button>  
            </div><br> 

            <table class="title2">
              <tr>
              <td><button class="button" :class="{ 'disabled': isSaving }" type="submit" id="save" @click="submitAllDatas" :disabled="isSaving">Save</button></td>              
              <td><span v-if="isSaving">Saving...</span></td>
            </tr>
            </table> 
            </form>            
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, onMounted, ref, nextTick} from 'vue';  
  import axios from 'axios';  

  export default {   

    setup(_, { emit }) {  
      const lineName = 'TR2 FPCA';  //설비 이름 교체
      const processNames = computed(() => { return setProcessNames1.value.slice(4, 8); }); //Process 수에 따라서 0, 6 부분을 수정해야 하는데, 30개로 우선 입력해 놨음. 만약 로딩이 느리다면 수정해 봐야함
      const isSaving = ref(false);                     
      const dbTableName = "checksheet"      
      const targetTableName = "checksheettarget"; const targetList = reactive({ data: [], });       
      const processRun = reactive({});  
      const setProcessNames1 = ref([]);                   

      const formInputs = reactive({ line: lineName, shift: "", time: "", model: "", technician: "", });      
              
      const timeHour = computed({
        get: () => { const [hour] = formInputs.time.split(":"); return parseInt(hour, 10); },
        set: (value) => { const [, minute] = formInputs.time.split(":"); formInputs.time = `${value.toString().padStart(2, "0")}:${minute || '00'}`;},
      });
      const timeMinute = computed({
        get: () => { const [, minute] = formInputs.time.split(":"); return minute ? parseInt(minute, 10) : 0; },
        set: (value) => { const [hour] = formInputs.time.split(":"); formInputs.time = `${hour}:${value.toString().padStart(2, "0")}`; },
      });    
      watch(() => [formInputs.time ],([time]) => {const timeHour = time.split(":")[0]; formInputs.shift = timeHour >= 6 && timeHour <= 17 ? 1 : 2;});        
      const getCurrentDate = () => {
        let today = new Date(); const day = today.getDate(); const hour = today.getHours();
        if (hour < 6) { today.setDate(day - 1); }
        const adjustedYear = today.getFullYear();
        const adjustedMonth = today.getMonth() + 1;
        const adjustedDay = today.getDate();
        return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
      };
      const searchDate = reactive({ ...getCurrentDate(), });      
                  
      const targetSearchData = async () => {
        const formData = { tableName: targetTableName, line: lineName };
        try {
          const response = await axios.get('/api/targetList', { params: formData });
          targetList.data = response.data.sort((a, b) => a.row_no - b.row_no);
          setProcessNames2(); 
        } catch (error) {
          console.error('Data fetch error:', error);
        }
      };  

      const submitDataForProcess = async (processData) => {
        const tableName = dbTableName;
        const columnMapping = {
          year: 'year', 
          month: 'month', 
          day: 'day', 
          line: 'line', 
          shift: 'shift',
          time: 'time', 
          model: 'model',
          process: 'process',
          itemvalues: 'itemvalues', 
          judgement: 'judgement',
          technician: 'technician', 
          remark: 'remark',    
        };

        const processGroups = processData.reduce((groups, item) => {
          if (!groups[item.process]) { groups[item.process] = []; }
          groups[item.process].push(item);
          return groups;
        }, {});

        for (const process in processGroups) {
          const items = processGroups[process];
          const processIndex = processNames.value.indexOf(process); 
          const values = {
            year: searchDate.year,
            month: searchDate.month,
            day: searchDate.day,
            line: formInputs.line,
            shift: formInputs.shift,
            time: formInputs.time,
            model: formInputs.model,
            process: process,
            value: items.map(i => i.value).join(';'),
            judgement: items.map(i => calculateJudgement(i, processIndex)).join(';'),
            technician: formInputs.technician,    
            remark: items.map(i => i.remark).join(';'),
          };

          try {
            await axios.post('/api/post', { tableName, columnMapping, values });
            console.log('Data for process', process, 'sent successfully');
          } catch (error) {
            console.error('Error sending data for process', process, ':', error);
          }
        }
      };

      const submitAllDatas = async () => {
        if (!formInputs.time || !formInputs.technician || !formInputs.model ) { 
          alert("Please fill in all the fields: Time, Technician and Model"); 
          return; 
        }

        isSaving.value = true;

        //스테이션 가감
        await submitDataForProcess(process_04.value);
        await submitDataForProcess(process_05.value);
        await submitDataForProcess(process_06.value);
        await submitDataForProcess(process_07.value);
                  
        closeModal();
        isSaving.value = false;       
      }; 

      const setProcessNames2 = () => {
        const processSet = new Set();
        const sortedData = [...targetList.data].sort((a, b) => a.row_no - b.row_no);
        sortedData.forEach(item => { processSet.add(item.process); });
        setProcessNames1.value = Array.from(processSet);
      };   

      const displayContext = reactive({show: true})
      
      const updateJudgement = async (item, processIndex) => {
        if (processRun[processIndex] === "0") { item.judgement = "Not Running";}
        else { item.judgement = calculateJudgement(item); }
        displayContext.show = false;
        await nextTick();
        displayContext.show = true;
        return item;
      }

      watch(processRun, () => {
        processs.value.forEach((process, index) => { process.forEach(item => { updateJudgement(item, index); }); });
      }, { deep: true });
  
      const createProcessComputed = (processIndex) => {
        return computed(() => {
          if (processNames.value.length > processIndex) {
            return targetList.data
              .filter(item => item.process === processNames.value[processIndex] && (!formInputs.model || item.model === formInputs.model))
              .map(item => {
                updateJudgement(item);            
                return { ...item };
              });
          }
          return [];
        });
      };  

      //스테이션 가감      
      const process_04 = createProcessComputed(0);
      const process_05 = createProcessComputed(1);
      const process_06 = createProcessComputed(2);
      const process_07 = createProcessComputed(3);

      const updateProcesssData = (processs) => {
        processs.forEach(process => {
          process.value = process.value.map(item => {
            return { ...item};
          });
        });
      };

      watch(() => formInputs.model, () => {
        updateProcesssData([process_04, process_05, process_06, process_07,]); //스테이션 가감
      });

      const processs = computed(() => [ 
        process_04.value, process_05.value, process_06.value, process_07.value //스테이션 가감
      ]);

      const processDatas = [process_04, process_05, process_06, process_07, ]; //스테이션 가감
    
      const submitProcessData = async (processIndex) => {           
        if (!formInputs.time || !formInputs.technician || !formInputs.model ) { 
          alert("Please fill in all the fields: Time, Technician and Model"); 
          return; 
        }
        const processData = processDatas[processIndex].value;       
        try { await submitDataForProcess(processData);
              alert("Data Save success"); 
        }
        catch (error) {
          console.error(`Process ${processIndex + 1} error when data saving:`, error);
        }
      };

      const formatStandard = (item) => {
        if (item.lowerlimit != null && item.lowerlimit !== "" && item.upperlimit != null && item.upperlimit !== "") {
          return `${item.lowerlimit} ~ ${item.upperlimit}`;
        }
        return item.standardvalue || '';
      };
      const isRange = (item) => { return item.lowerlimit != null && item.lowerlimit !== "" && item.upperlimit != null && item.upperlimit !== ""; };
      
      const calculateJudgement = (item, processIndex) => {    
        if (processRun[processIndex] === '0') { return "Not Running"; }
        if (item.except_row === "except") { return "Except"; }  
        
        if (!item.value || item.value.trim() === '') { return 'Need check'; }
        
        if (item.lowerlimit != null && item.lowerlimit !== "" && item.upperlimit != null && item.upperlimit !== "") {
          const numericValue = parseFloat(item.value);
          if (isNaN(numericValue)) return 'ng';

          const lowerLimit = parseFloat(item.lowerlimit);
          const upperLimit = parseFloat(item.upperlimit);
          return (numericValue >= lowerLimit && numericValue <= upperLimit) ? 'ok' : 'ng';
        } else if (item.standardvalue !== null) {
          return item.value === item.standardvalue ? 'ok' : 'ng';
        }
        return 'ng';
      };   

      onMounted(async () => { await targetSearchData();
        processNames.value.forEach((processName, index) => { processRun[index] = '1'; }); }
      );

      const closeModal = () => { emit("onClose"); };  


      return { searchDate, dbTableName, closeModal, timeHour, timeMinute, isSaving, submitAllDatas, isRange, processs,              
               formInputs, targetSearchData, processNames, formatStandard, calculateJudgement, updateJudgement,
               displayContext, processRun, submitProcessData
      };
  },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },   
    }
};   
</script>
    
<style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }       
    .modal-wrapper { width: 100%;  max-width: 1300px; }    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }  
    .modal-header h3 { font-size: 1.2rem; }  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
    .value { width: 100px;}
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }

    .button.disabled { background-color: #ccc; cursor: not-allowed; }

    .title {width: 1000px;}
    .title2 {width: 700px;}
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }  

    .mainTable td {padding: 0.75rem; text-align: center; font-size :13px; border: 1px solid #808080;  }
    
    th { padding: 0.75rem; text-align: center; font-size :13px; border: 1px solid #808080; background-color: #dad8d8;}
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 100px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }  
    
    .twoinputs { display: flex; gap: 5px;}
    .twoinputs input { width: 100px; size: 8px; }  
    .ok { background-color: #089927; color: #ffffff;   }
    .ng { background-color: #f87b6a; color: #ffffff;   }
    .need-check { background-color: rgb(235, 176, 49); color: rgb(0, 0, 0); }
    .pass { background-color: rgb(227, 227, 151); color: #000000; }
    .except { background-color: rgb(142, 142, 142); color: rgb(0, 0, 0);}
    .comment { text-align: left !important; vertical-align: middle; font-size: 10px;}     
    .lineUnderRemark { width: calc(100%);  border-top: 5px solid rgb(247, 6, 6) }
    .radio { margin-left: 10px; margin-right: 5px; }
    .processName {margin-top: 20px; font-weight: bold;}
    .modelSelect {width: 100px;}
    .shiftInput {width: 50px !important;}
    .running {font-size: 16px; font-weight: bole;}
    .runningLocation { text-align: right; margin-bottom: 10px;}
    .process_save {
      background-color: #4c4faf; color: white;
      padding: 5px 20px; margin-top: 10px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }  
    .process_save:hover { background-color: #05498d; }
  </style>