import axios from 'axios';

const apiKey = 'phskRvn3_ZBfCZMqNFdaQq3WMjZ7gbywMhAOp5vGTBYXAzFuqwnqeg%3D%3D';
const nodeEnv = process.env.NODE_ENV;
const localURL = 'http://localhost:8080';

let baseURL;

if (nodeEnv === 'development') {
  baseURL = localURL;
} else if (nodeEnv === 'production') {  
  if (window.location.hostname === 'mx.lsevpweb.com') {
    baseURL = 'https://mx.lsevpweb.com';
  }
}

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use((config) => {
  if (baseURL === 'https://mx.lsevpweb.com') {
    const separator = config.url.includes('?') ? '&' : '?';
    config.url = `${config.url}${separator}code=${apiKey}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axios;


/*
import axios from 'axios';

const apiKey = 'br59nrLCKYSawHF6mif44R8yKmL2-4nWclgJDfDgbxupAzFu21Asgg%3D%3D';
const nodeEnv = process.env.NODE_ENV;
const localURL = 'http://localhost:8080';

let baseURL;

if (nodeEnv === 'development') {
  baseURL = localURL;
} else if (nodeEnv === 'production') {  
  if (window.location.hostname === 'pl.lsevpweb.com') {
    baseURL = 'https://pl.lsevpweb.com';
  } else if (window.location.hostname === 'pl.lsevpweb.com') {
    baseURL = 'https://pl.lsevpweb.com';
  }
}

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use((config) => {
  if (baseURL === 'https://pl.lsevpweb.com') {
    const separator = config.url.includes('?') ? '&' : '?';
    config.url = `${config.url}${separator}code=${apiKey}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axios;
*/
