<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Cleaning Actual</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>            
            <div class="form-group plan-group">   
              <label>Date :</label>             
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="formInputs.year" readonly class="readWindow"/>  
                <input type="text" id="month" v-model="formInputs.month" readonly class="readWindow"/>  
                <input type="text" id="day" v-model="formInputs.day" readonly class="readWindow" />                  
              </div>
            </div>  
            <div class="form-group">
              <label for="factory">Line:</label>                          
              <input type="text" id="factory" v-model="formInputs.line" readonly class="readWindow" />                            
            </div>
            <div class="form-group">
              <label for="factory">Station:</label>                          
              <input type="text" id="station" v-model="formInputs.station" readonly class="readWindow" />                            
            </div>            
            <div class="form-group">
              <label for="factory">Part:</label>                          
              <input type="text" id="station" v-model="formInputs.part" readonly class="readWindow" />                            
            </div>            
            <div class="form-group">
              <label for="factory">Plan:</label>                          
              <input type="text" id="plan" v-model="formInputs.plan" readonly class="readWindow" />                            
            </div>
            <div class="form-group">
              <label for="factory">Actual:</label>                          
              <select type="text" id="result" v-model="formInputs.result">
                <option>O</option><option>Input</option><option></option>
              </select>
            </div>    
            <div class="form-group">
              <label for="factory">Remark:</label>                          
              <input type="text" id="remark" v-model="formInputs.remark"/>                            
            </div>    
          </td>
          </tr>
        </table>                
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button class="savebutton" type="submit" id="save">Save</button>                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
    
<script>  
  import { ref, onMounted, watch } from 'vue';  
  import axios from 'axios';       
    
  export default {       
    setup(props, { emit }) {  
      const dbTableName = "cleaninglist1st"            
      const formInputs = ref({
      year: '',
      month: '',
      day: '',
      line: '',
      station: '',
      part: '',
      plan: '',
      result: '',
      remark: '',
    });

    watch(props, () => {
      if (props.cleaning) {
        formInputs.value.year = props.cleaning.year;
        formInputs.value.month = props.cleaning.month;
        formInputs.value.day = props.cleaning.day;
        formInputs.value.line = props.cleaning.line;
        formInputs.value.station = props.cleaning.station;
        formInputs.value.part = props.cleaning.part;
        formInputs.value.plan = props.cleaning.plan;
      }
    }, { deep: true });

      const submitForm = async () => {  
        const columnMapping = {
          year: "year", month: "month", day: "day", line: "line", station: "station", part: "part",
          plan: "plan", result: "result", remark: "remark", 
        }   
        if (formInputs.value.result !== "O") {
          alert("You need to write actual value.");
          return;
        } 
        const formData = {              
          tableName: dbTableName,
          columnMapping: columnMapping,
          values: {
              year: formInputs.value.year,
              month: formInputs.value.month,
              day: formInputs.value.day,                            
              line: formInputs.value.line,              
              station: formInputs.value.station,              
              part: formInputs.value.part,              
              plan: formInputs.value.plan,
              result: formInputs.value.result,
              remark: formInputs.value.remark,              
            }
        };     
        await axios.post('/api/post', formData);
        closeModal();
      };

      const closeModal = () => { emit("onClose"); };     

     onMounted(() => {
      if (props.cleaning) {
        formInputs.value.year = props.cleaning.year;
        formInputs.value.month = props.cleaning.month;
        formInputs.value.day = props.cleaning.day;
        formInputs.value.line = props.cleaning.line;
        formInputs.value.station = props.cleaning.station;
        formInputs.value.part = props.cleaning.part;
        formInputs.value.plan = props.cleaning.plan;
      }
    }); 

      return {formInputs, dbTableName, submitForm, closeModal};                      
    },     

    props: {      
      cleaning: { type: Object, required: true },
      year: { type: String, required: true },
      month: { type: String, required: true },
      day: { type: String, required: true },
      line: { type: String, required: true },
      station: { type: String, required: true },
      part: { type: String, required: true },
      plan: { type: String, required: true }
    }
  };    
</script>
      
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }   
  
  .modal-wrapper { width: 100%;  max-width: 400px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  .savebutton {
    background-color: #4CAF50; color: white;
    padding: 5px 20px; margin-right : 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  .savebutton:hover { background-color: #45a049; }

  .delbutton {
    background-color: #f1862d; color: white;
    padding: 5px 20px; margin-right : 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  .delbutton:hover { background-color: #fcae81; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 50px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>