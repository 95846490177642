<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Employee</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="modalTable">
              <tr>
                <td>                                       
                  <div class="form-group">
                    <label class="modalLabel" for="employeenumber">Employee Number:</label>
                    <input class="modalInput" id="employeenumber" v-model="formInputs.employeenumber" />
                  </div>                  
                  <div class="form-group">
                    <label class="modalLabel" for="oscompany">OS Company:</label>
                    <select class="modalSelect" id="oscompany" v-model="formInputs.oscompany">
                      <option v-for="oscompany in uniqueCompany" :key="oscompany">{{ oscompany }}</option><option></option>        
                    </select>   
                  </div>
                  <div class="form-group">
                    <label class="modalLabel" for="name">Name:</label>
                    <input class="modalInput" type="text" id="name" v-model="formInputs.name" />
                  </div>
                  <div class="form-group">
                    <label class="modalLabel" for="surname">Surname:</label>
                    <input class="modalInput" type="text" id="surname" v-model="formInputs.surname" />
                  </div>
                </td>                               
                <td>
                  <div class="form-group">
                    <label class="modalLabel" for="factory">Factory:</label>
                    <select class="modalSelect" type="text" id="factory" v-model="formInputs.factory">
                      <option>1</option><option>2</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="modalLabel" for="line">Line:</label>
                    <select class="modalSelect" id="line" v-model="formInputs.line">
                      <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
                    </select>   
                  </div>
                  <div class="form-group">
                    <label class="modalLabel" for="process">Process:</label>
                    <select class="modalSelect" id="process" v-model="formInputs.process">
                      <option v-for="process in uniqueProcess" :key="process">{{ process }}</option><option></option>        
                    </select>  
                  </div>  
                  <div class="form-group">
                    <label class="modalLabel" for="position">Position:</label>
                    <select class="modalSelect" id="position" v-model="formInputs.position">
                      <option v-for="position in uniquePosition" :key="position">{{ position }}</option><option></option>        
                    </select>  
                  </div> 
                  <div class="form-group">
                    <label class="modalLabel" for="timecost">Cost Per Hour:</label>
                    <input class="modalInput" type="text" id="timecost" v-model="formInputs.timecost" />
                  </div> 
                  <div class="form-group">
                    <label class="modalLabel" for="remark">Remark:</label>
                    <input class="modalInput" type="text" id="remark" v-model="formInputs.remark" />
                  </div> 
                </td>
              </tr>
            </table>                     
            <button class="saveButton" type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
  import { fetchLine, fetchProcess, fetchPosition, fetchCompany} from "../../utils.js"; 
   
  export default {                 
    setup(props, { emit }) {   
        const dbTableName = "employeedata"    
        const uniqueLines = ref([]);     
        const uniqueProcess = ref([]);   
        const uniquePosition = ref([]);
        const uniqueCompany = ref([]);  

        const formInputs = reactive({          
          employeenumber : "", oscompany : "", name : "", surname : "", factory : "", line : "",
          process : "", position : "", timecost: "", remark : "",
        });

        const submitForm = async () => {
          if (!formInputs.employeenumber || !formInputs.oscompany ) {
            alert("Please fill in all the fields: Employee Number, OS Company.");
            return;
          }

          const columnMapping = {
              employeenumber : "employeenumber",
              oscompany : "oscompany",
              name : "name",
              surname : "surname",
              factory : "factory",
              line : "line",
              process : "process",
              position : "position",
              timecost : "timecost",
              remark : "remark",              
          }     

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {              
              employeenumber : formInputs.employeenumber,
              oscompany : formInputs.oscompany,
              name : formInputs.name,
              surname : formInputs.surname,
              factory : formInputs.factory,
              line : formInputs.line,
              process : formInputs.process,
              position : formInputs.position,
              timecost : formInputs.timecost,
              remark : formInputs.remark,
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };   
        
        const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
        const loadProcess = async () => { uniqueProcess.value = await fetchProcess(); }; loadProcess();
        const loadPosition = async () => { uniquePosition.value = await fetchPosition(); }; loadPosition();
        const loadCompany = async () => { uniqueCompany.value = await fetchCompany(); }; loadCompany();
  
      return { dbTableName, formInputs, submitForm, closeModal, uniqueLines, uniqueProcess, uniquePosition, uniqueCompany}; },     

    props: { onSubmit: { type: Function, required: true, },}, 
};
  
</script>
    
<style scoped>  
  * { margin: 0; padding: 0; box-sizing: border-box; }
  
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 500px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  .modalLabel { font-weight: bold; }

  .modalInput { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px;  width: 100%; }   
  .modalSelect { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px;  width: 100%; } 

  .readWindow[readonly] { background-color: #f0f0f0; }

  .saveButton[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px; font-weight: bold;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }
  .saveButton[type="submit"]:hover { background-color: #45a049; }
  .modalTable { table-layout: fixed; width: 100%; }
  .modalTable td { width: 20%; vertical-align: top; padding: 0 15px; }
</style>