<template>
  <div class="arduinotest">      
    <hr>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table">       
      <tr>          
        <th v-for="(header, index) in headers" :key="index" >{{ header }}</th>          
      </tr>          
        <tr v-for="row in item" :key="row.id">
          <template v-for="(header, index) in headers ">         
          <td v-if="header === 'Value 1'" :key="index" >{{ row.value1 }}</td>
          <td v-if="header === 'Value 2'" :key="index" >{{ row.value2 }}</td>
          <td v-if="header === 'Value 3'" :key="index" >{{ row.value3 }}</td>          
        </template>   
      </tr>    
    </table>
    </div>    
  </div> <!-- test 62 -->    
  
</template>

<script>
import { reactive, computed, onMounted } from 'vue';
import axios from "axios";

export default {         
    setup() {   
        const targetTableName = "arduinotest";            
        const headers = ['Value 1', 'Value 2', 'Value 3'];
        const targetList = reactive({ data: [], })

        onMounted(() => { searchData(); });   

        const searchData = async () => {         

          const formData = {
            tableName: targetTableName,
          };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => {
              console.log("Response from /api/target:", res.data);
              targetList.data = res.data
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const item = computed(() => {
          return targetList.data.map(d => {
            return { ...d, };
          });
        });        
  

        return { item, targetList, headers, targetTableName,};        
    }
  }
</script>

<style scoped>
.table-container {
  overflow-x: hidden;
  position: relative;
}

.table-header,
.table-body {
  overflow: hidden;
  width: 100%;  
}

.table-body {
  max-height: 350px; /* Adjust the height to your needs */
  overflow-y: auto;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  table-layout: fixed;
}


.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f8f9fa;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* Custom table styles */
.target {
  font-family: Arial, Helvetica, sans-serif;
}

.target .table {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.target .table td {
  text-align: center;
}

.target .table tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.target .table tr:hover {
  background-color: #e9ecef;
  cursor: pointer;
}
.target hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));
  margin-bottom: 1rem;
}
</style>