
import { reactive } from 'vue';
import axios from 'axios';
import { targetSearchData, searchData, workSearchData, calculateNgRate, calculateOkAchieve, calculateSums, dtcalculateSums, failureCalculateSums } from './utils.js';

export const productionData = [
  { id: 1, line: 'TR2 SMT', targetName: 'TR2 SMT', prDB: 'tr2smtpr', dtDB: 'tr2smtdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 SMT', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 2, line: 'TR2 Crimping', targetName: 'TR2 Crimping', prDB: 'tr2crimpingpr', dtDB: 'tr2crimpingdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0,  tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 Crimping', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 3, line: 'TR2 FPCA', targetName: 'TR2 FPCA', prDB: 'tr2fpcapr', dtDB: 'tr2fpcadt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 FPCA', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 4, line: 'TR3 SMT', targetName: 'TR3 SMT', prDB: 'tr3smtpr', dtDB: 'tr3smtdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR3 SMT', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},  
  { id: 5, line: 'TR3 Crimping', targetName: 'TR3 Crimping', prDB: 'tr3crimpingpr', dtDB: 'tr3crimpingdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0,  tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR3 Crimping', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},  
  { id: 6, line: 'TR3 FPCA', targetName: 'TR3 FPCA', prDB: 'tr3fpcapr', dtDB: 'tr3fpcadt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR3 FPCA', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 7, line: 'TR2 BFA 3P', targetName: 'TR2 BFA 3P', prDB: 'tr2bfa3ppr', dtDB: 'tr2bfa3pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 BFA 3P', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 8, line: 'EVO 1', targetName: 'EVO 1', prDB: 'tr3bfa2ppr', dtDB: 'tr3bfa2pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'EVO 1', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 9, line: 'EVO 2', targetName: 'EVO 2', prDB: 'tr3bfa3ppr', dtDB: 'tr3bfa3pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EVO 2', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 10, line: 'EVO 3', targetName: 'EVO 3', prDB: 'tr2bfa2ppr', dtDB: 'tr2bfa2pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'EVO 3', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 11, line: 'EVO Hybrid', targetName: 'EVO Hybrid', prDB: 'evopr', dtDB: 'evodt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EVO Hybrid', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 12, line: 'Ford 1 Front', targetName: 'Ford 1 Front', prDB: 'ford1frpr', dtDB: 'ford1frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 1 Front', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 13, line: 'Ford 1 Rear', targetName: 'Ford 1 Rear', prDB: 'ford1rrpr', dtDB: 'ford1rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 1 Rear', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},  
  { id: 14, line: 'Ford 2 Front', targetName: 'Ford 2 Front', prDB: 'ford2frpr', dtDB: 'ford2frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 2 Front', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 15, line: 'Ford 2 Rear', targetName: 'Ford 2 Rear',  prDB: 'ford2rrpr', dtDB: 'ford2rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 2 Rear', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 16, line: 'Ford 3 Front', targetName: 'Ford 3 Front', prDB: 'ford3frpr', dtDB: 'ford3frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 3 Front', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 17, line: 'Ford 3 Rear', targetName: 'Ford 3 Rear', prDB: 'ford3rrpr', dtDB: 'ford3rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'Ford 3 Rear', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 18, line: 'EV2020 60kW Front', targetName: 'EV2020 60FR', prDB: 'ev2020_60frpr', dtDB: 'ev2020_60frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EV2020 60kW', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 19, line: 'EV2020 60kW Rear', targetName: 'EV2020 60RR', prDB: 'ev2020_60rrpr', dtDB: 'ev2020_60rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EV2020 60kW', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 20, line: 'EV2020 4060kW Front', targetName: 'EV2020 4060FR', prDB: 'ev2020_4060frpr', dtDB: 'ev2020_4060frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EV2020 4060kW', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 21, line: 'EV2020 4060kW Rear', targetName: 'EV2020 4060RR', prDB: 'ev2020_4060rrpr', dtDB: 'ev2020_4060rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'EV2020 4060kW', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},    
  { id: 22, line: 'BMW 48V', targetName: 'BMW48V', prDB: 'bmw48vpr', dtDB: 'bmw48vdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'BMW 48V', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 23, line: 'BMW 12V', targetName: 'BMW12V', prDB: 'bmw12vpr', dtDB: 'bmw12vdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'BMW 12V', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 24, line: 'BT6', targetName: 'BT6', prDB: 'bt6pr', dtDB: 'bt6dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'BT6', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 25, line: 'FCA 1', targetName: 'FCA 1', prDB: 'fca1pr', dtDB: 'fca1dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA 1', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 26, line: 'FCA 2', targetName: 'FCA 2', prDB: 'fca2pr', dtDB: 'fca2dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA 2', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 27, line: 'FCA FLEX BC', targetName: 'FCA FLEX BC', prDB: 'fca_flex_bcpr', dtDB: 'fca_flex_bcdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX BC', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 28, line: 'FCA FLEX PO', targetName: 'FCA FLEX PO', prDB: 'fca_flex_popr', dtDB: 'fca_flex_podt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX PO', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 29, line: 'FCA FLEX NE', targetName: 'FCA FLEX NE', prDB: 'fca_flex_nepr', dtDB: 'fca_flex_nedt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX NE', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 30, line: 'FCA FLEX 45', targetName: 'FCA FLEX 45', prDB: 'fca_flex_45pr', dtDB: 'fca_flex_45dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX 45', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
  { id: 31, line: 'FCA FLEX 67', targetName: 'FCA FLEX 67', prDB: 'fca_flex_67pr', dtDB: 'fca_flex_67dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, tacttime: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX 67', failureTime: 0, failureCount: 0, failureDB: 'maintenanceworklist'},
];

export const dataMonth = {
  JAN: [], FEB: [], MAR: [], APR: [], MAY: [], JUN: [], JUL: [], AUG: [], SEP: [], OCT: [], NOV: [], DEC: []
};

export const addData = reactive([
  { id: 1, name: 'Target', dbName : 'target', data: [], api: 'targetSearchData' },
]);

const settingData = reactive({ data: [], });   

export const updateResultData = async (year, line) => {

  if (!year || !line) { alert("Please choice Year and Line.");  return; }

  const tableName = 'setting';
  const formData = { tableName: tableName };
  try {
    const res = await axios.get('/api/targetList', { params: formData });
    settingData.data = res.data;
    const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));  
    const isReportEnabled = reportSetting && reportSetting.report === 1;
    if (!isReportEnabled) { alert("Report is currently disabled.");return;}
  }
  catch (error) { console.error("Error report setting data:", error.message); return; }

  // 선택한 연도와 라인에 해당하는 데이터만 처리
  for (let month = 1; month <= 12; month++) {
    const monthKey = new Date(year, month - 1).toLocaleString('en-us', {month: 'short'}).toUpperCase();
    dataMonth[monthKey] = []; // 해당 월의 데이터 초기화
    
    const selectedLineData = productionData.find(item => item.line.replace(/\s+/g, '') === line.replace(/\s+/g, ''));
    if (!selectedLineData) {
      console.error("Selected line data not found");
      return;
    }

    let item = {...selectedLineData, model: "", target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, failureTime: 0, failureCount: 0};

    const startDay = 1;
    const endDay = 31;
    const prData = await searchData(item.prDB, year, month, startDay, year, month, endDay);
    const dtData = await searchData(item.dtDB, year, month, startDay, year, month, endDay);    
    const failureData = await workSearchData(item.failureDB, year, month, startDay, year, month, endDay);
 
    if (failureData && Array.isArray(failureData)) {
      failureData.forEach(fd => {      
        if (fd.line.replace(/\s+/g, '').toLowerCase() === item.checkName.replace(/\s+/g, '').toLowerCase()) {
          failureCalculateSums(fd, item);
        }
      });
    } else {
      console.error("failureData is not an array, received:", failureData);
    }

    if (prData) { calculateSums(prData, item); }
    if (dtData) { dtcalculateSums(dtData, item); }  

    item.ngRate = calculateNgRate(item.ng, item.ok);
    item.okAchieve = calculateOkAchieve(item.ok, item.target);    

    dataMonth[monthKey].push(item);
  }
};

export const fetchDataForAddData = async () => {
  for (const item of addData) {
    if (item.api === 'targetSearchData') {
      // targetSearchData 함수를 사용하여 데이터 가져오기
      item.data = await targetSearchData(item.dbName);
    } else if (item.api === 'searchData') {
      // searchData 함수를 사용하여 데이터 가져오기
      const result = await searchData(item.dbName);
      if (result) {
        item.data = result;
      }
    }    
  }  
};

export const updateTactTargets = () => {
  const targetData = addData.find(d => d.name === 'Target')?.data;
  if (targetData) {
    productionData.forEach(prodLine => {
      // 'line_name'과 'line'을 소문자로 변환하고 빈칸을 제거한 후 비교합니다.
      const lineTarget = targetData.find(t => 
        t.line_name.toLowerCase().replace(/\s+/g, '') === prodLine.targetName.toLowerCase().replace(/\s+/g, '')
      );
      if (lineTarget) {
        prodLine.tacttime = lineTarget.real_tacttime;
      }
    });
  }  
};