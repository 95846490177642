
import { reactive } from 'vue';
import axios from 'axios';
import { getCurrentDate, getResult, getMaintenanceResult, searchData, targetSearchData,
         calculateNgRate, calculateOkAchieve, getModelFromLastData, calculateSums, dtcalculateSums
       } from './utils.js';

export const productionData = [
  { id: 1, factory: '1st', line: 'TR2 SMT', targetName: 'TR2 SMT', prDB: 'tr2smtpr', dtDB: 'tr2smtdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 SMT', check: '', mcheck: '',},
  { id: 2, factory: '2nd', line: 'TR3 SMT', targetName: 'TR3 SMT', prDB: 'tr3smtpr', dtDB: 'tr3smtdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR3 SMT', check: '', mcheck: '', },
  { id: 3, factory: '1st', line: 'TR2 Crimping', targetName: 'TR2 Crimping', prDB: 'tr2crimpingpr', dtDB: 'tr2crimpingdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0,  ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 Crimping', check: '', mcheck: '', },
  { id: 4, factory: '2nd', line: 'TR3 Crimping', targetName: 'TR3 Crimping', prDB: 'tr3crimpingpr', dtDB: 'tr3crimpingdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0,  ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR3 Crimping', check: '', mcheck: '', },
  { id: 5, factory: '1st', line: 'TR2 FPCA', targetName: 'TR2 FPCA', prDB: 'tr2fpcapr', dtDB: 'tr2fpcadt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 FPCA', check: '', mcheck: '', },
  { id: 6, factory: '2nd', line: 'TR3 FPCA', targetName: 'TR3 FPCA', prDB: 'tr3fpcapr', dtDB: 'tr3fpcadt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR3 FPCA', check: '', mcheck: '', },
  { id: 7, factory: '1st', line: 'TR2 BFA 3P', targetName: 'TR2 BFA 3P', prDB: 'tr2bfa3ppr', dtDB: 'tr2bfa3pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'TR2 BFA 3P', check: '', mcheck: '', },
  { id: 8, factory: '2nd', line: 'EVO 1', targetName: 'EVO 1', prDB: 'tr3bfa2ppr', dtDB: 'tr3bfa2pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'EVO 1',   check: '', mcheck: '', },
  { id: 9, factory: '1st', line: 'Ford 1 Front', targetName: 'Ford 1 Front', prDB: 'ford1frpr', dtDB: 'ford1frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 1 Front',  check: '', mcheck: '', },
  { id: 10, factory: '2nd', line: 'EVO 2', targetName: 'EVO 2', prDB: 'tr3bfa3ppr', dtDB: 'tr3bfa3pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EVO 2',  check: '', mcheck: '', },
  { id: 11, factory: '1st', line: 'Ford 1 Rear', targetName: 'Ford 1 Rear', prDB: 'ford1rrpr', dtDB: 'ford1rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 1 Rear',  check: '', mcheck: '', },  
  { id: 12, factory: '2nd', line: 'EVO 3', targetName: 'EVO 3', prDB: 'tr2bfa2ppr', dtDB: 'tr2bfa2pdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'EVO 3',   check: '', mcheck: '', },
  { id: 13, factory: '1st', line: 'Ford 2 Front', targetName: 'Ford 2 Front', prDB: 'ford2frpr', dtDB: 'ford2frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 2 Front',  check: '', mcheck: '', },
  { id: 14, factory: '2nd', line: 'EVO Hybrid', targetName: 'EVO Hybrid', prDB: 'evopr', dtDB: 'evodt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EVO Hybrid',  check: '', mcheck: '', },
  { id: 15, factory: '1st', line: 'Ford 2 Rear', targetName: 'Ford 2 Rear',  prDB: 'ford2rrpr', dtDB: 'ford2rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 2 Rear',  check: '', mcheck: '', },
  { id: 16, factory: '2nd', line: 'EV2020 60kW Front', targetName: 'EV2020 60FR', prDB: 'ev2020_60frpr', dtDB: 'ev2020_60frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EV2020 60kW',  check: '', mcheck: '', },
  { id: 17, factory: '1st', line: 'Ford 3 Front', targetName: 'Ford 3 Front', prDB: 'ford3frpr', dtDB: 'ford3frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'Ford 3 Front',  check: '', mcheck: '', },
  { id: 18, factory: '2nd', line: 'EV2020 60kW Rear', targetName: 'EV2020 60RR', prDB: 'ev2020_60rrpr', dtDB: 'ev2020_60rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EV2020 60kW',  check: '', mcheck: '', },
  { id: 19, factory: '1st', line: 'Ford 3 Rear', targetName: 'Ford 3 Rear', prDB: 'ford3rrpr', dtDB: 'ford3rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'Ford 3 Rear',  check: '', mcheck: '', },
  { id: 20, factory: '2nd', line: 'EV2020 4060kW Front', targetName: 'EV2020 4060FR', prDB: 'ev2020_4060frpr', dtDB: 'ev2020_4060frdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0,checkName: 'EV2020 4060kW',  check: '', mcheck: '', },
  { id: 21, factory: '1st', line: 'BMW 48V', targetName: 'BMW48V', prDB: 'bmw48vpr', dtDB: 'bmw48vdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'BMW48V',  check: '', mcheck: '', },
  { id: 22, factory: '2nd', line: 'EV2020 4060kW Rear', targetName: 'EV2020 4060RR', prDB: 'ev2020_4060rrpr', dtDB: 'ev2020_4060rrdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'EV2020 4060kW',  check: '', mcheck: '', },    
  { id: 23, factory: '1st', line: 'BMW 12V', targetName: 'BMW12V', prDB: 'bmw12vpr', dtDB: 'bmw12vdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'BMW12V',  check: '', mcheck: '', },
  { id: 24, factory: '2nd', line: 'INKATOM', targetName: 'Inkatom', prDB: 'inkatom2nd', dtDB: '', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: '',  check: '', mcheck: '', },  
  //{ id: 25, factory: '1st', line: 'BT6', targetName: 'BT6', prDB: 'bt6pr', dtDB: 'bt6dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'BT6',  check: '', mcheck: '', },
  //{ id: 26, factory: '1st', line: 'FCA 2', targetName: 'FCA 2', prDB: 'fca2pr', dtDB: 'fca2dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA 2',  check: '', mcheck: '', },
  //{ id: 27, factory: '1st', line: 'FCA FLEX BC', targetName: 'FCA FLEX BC', prDB: 'fca_flex_bcpr', dtDB: 'fca_flex_bcdt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX BC',  check: '', mcheck: '', },
  //{ id: 28, factory: '1st', line: 'FCA FLEX PO', targetName: 'FCA FLEX PO', prDB: 'fca_flex_popr', dtDB: 'fca_flex_podt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX PO',  check: '', mcheck: '', },
  //{ id: 29, factory: '1st', line: 'FCA FLEX NE', targetName: 'FCA FLEX NE', prDB: 'fca_flex_nepr', dtDB: 'fca_flex_nedt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX NE',  check: '', mcheck: '', },
  //{ id: 30, factory: '1st', line: 'FCA FLEX 45', targetName: 'FCA FLEX 45', prDB: 'fca_flex_45pr', dtDB: 'fca_flex_45dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX 45',  check: '', mcheck: '', },
  //{ id: 31, factory: '1st', line: 'FCA FLEX 67', targetName: 'FCA FLEX 67', prDB: 'fca_flex_67pr', dtDB: 'fca_flex_67dt', model: '', target: 0, ok: 0, ng: 0, work_minute: 0, time: 0, ngRateTarget: 0, ngRate: 0, okAchieve: 0, checkName: 'FCA FLEX 67',  check: '', mcheck: '', },
];

export const addData = reactive([
  { id: 1, name: 'Target', dbName : 'target', data: [], api: 'targetSearchData' },
  { id: 2, name: 'Calibration', dbName : 'calibrationdate', data: [], api: 'targetSearchData' },
  { id: 3, name: 'Evaluation', dbName : 'evaluation', data: [], api: 'targetSearchData' },
  { id: 4, name: 'Stocktray', dbName : 'stocktray', data: [], api: 'searchData' }, 
  { id: 5, name: 'WeekPlan', dbName : 'weekplan', data: [], api: 'searchData' }, 
  { id: 6, name: 'SpareList', dbName : 'sparelist', data: [], api: 'targetSearchData' }, 
  { id: 7, name: 'ProductionSpare', dbName : 'productionspare', data: [], api: 'targetSearchData' }, 
  { id: 8, name: 'ParameterSmtLaser', dbName : 'parasmtlaser', data: [], api: 'searchData' }, 
  { id: 9, name: 'ParameterBfaQrLaser', dbName : 'parabfaqrlaser', data: [], api: 'searchData' }, 
  { id: 10, name: 'ParameterBfaWeldLaser', dbName : 'parabfaweldlaser', data: [], api: 'searchData' }, 
]);

const checkTargetTableName = "checksheettarget"; const checkTargetData = reactive({ data: [], });
const checkTableName = "checksheet"; const checkTableData = reactive({ data: [], });

const maintenanceCheckTargetTableName = "maintenancechecktarget"; const maintenanceCheckTargetData = reactive({ data: [], });
const maintenanceCheckTableName = "maintenancechecksheet"; const maintenanceCheckTableData = reactive({ data: [], });

export const checktargetSearch = async () => {    
  const formData = { tableName: checkTargetTableName };
  try {
    const response = await axios.get('/api/targetList', { params: formData });
    checkTargetData.data = response.data;    
    productionData.forEach(prodLine => {
      const processList = checkTargetData.data
        .filter(target => target.line === prodLine.checkName)
        .map(target => target.process);   
      prodLine.check = csResult(prodLine.checkName, processList);
    });
    productionData.splice(0, productionData.length, ...productionData);
  } catch (error) {
    console.error("Error fetching target data:", error.message);
  }
};

export const checksheetSearchData = async () => {
  const currentDate = getCurrentDate();
  const formData = {
    tableName: checkTableName,
    values: { year: currentDate.year,  month: currentDate.month, day: currentDate.day }
  }; 
  axios.get('/api/productionData', { params: formData })
    .then((res) => { checkTableData.data = res.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));})
    .catch((error) => {console.error("Error fetching checksheet data:", error.message);});
};

export const csResult = (checkName, processlist) => {
  let resultFound = false;
  let allOkOrExcept = true;
  for (let process of processlist) {
    const result = getResult(checkName, process, checkTableData);
    if (result) {
      resultFound = true;
      const judgements = result.split(';');    
      if (judgements.some(judgement => judgement.toLowerCase() !== 'ok' && judgement.toLowerCase() !== 'except' && judgement.toLowerCase() !== 'not running')) {
        allOkOrExcept = false;
        break;
      }
    }
  }
  if (!resultFound) { return 'data'; }
  return allOkOrExcept ? 'ok' : 'ng';
};

export const maintenanceChecktargetSearch = async () => {    
  const formData = { tableName: maintenanceCheckTargetTableName };
  try {
    const response = await axios.get('/api/targetList', { params: formData });
    maintenanceCheckTargetData.data = response.data;    
    productionData.forEach(prodLine => {
      const stationList = maintenanceCheckTargetData.data
        .filter(target => target.line === prodLine.checkName)
        .map(target => target.station);   
      prodLine.mcheck = maintenanceCsResult(prodLine.checkName, stationList);
    });
    productionData.splice(0, productionData.length, ...productionData);
  } catch (error) {
    console.error("Error fetching maintenance target data:", error.message);
  }
};

export const maintenanceChecksheetSearchData = async () => {
  const currentDate = getCurrentDate();
  const formData = {
    tableName: maintenanceCheckTableName,
    values: { year: currentDate.year,  month: currentDate.month, day: currentDate.day }
  }; 
  axios.get('/api/productionData', { params: formData })
    .then((res) => { maintenanceCheckTableData.data = res.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));})
    .catch((error) => {console.error("Error fetching maintenance checksheet data:", error.message);});
};

export const maintenanceCsResult = (checkName, stationlist) => {
  let resultFound = false;
  let allOkOrExcept = true;
  for (let station of stationlist) {
    const result = getMaintenanceResult(checkName, station, maintenanceCheckTableData);
    if (result) {
      resultFound = true;
      const judgements = result.split(';');    
      if (judgements.some(judgement => judgement.toLowerCase() !== 'ok' && judgement.toLowerCase() !== 'except' && judgement.toLowerCase() !== 'pass')) {
        allOkOrExcept = false;
        break;
      }
    }
  }
  if (!resultFound) { return 'data'; }
  return allOkOrExcept ? 'ok' : 'ng';
};

const settingData = reactive({ data: [], });   

export const updateResultData = async () => {
  const tableName = 'setting';
  const formData = { tableName: tableName };
  try {
    const res = await axios.get('/api/targetList', { params: formData });
    settingData.data = res.data;
    const dashboardSetting = settingData.data.find(s => Object.hasOwn(s, 'dashboard'));  
    const isDashboardEnabled = dashboardSetting && dashboardSetting.dashboard === 1;
    if (!isDashboardEnabled) { alert("Dashboard is currently disabled.");return;}
  }
  catch (error) { console.error("Error download setting data:", error.message); return; }

  for (const item of productionData) {
    // 각 항목의 값을 초기화
    item.model = "";
    item.target = 0;
    item.ok = 0;
    item.ng = 0;
    item.work_minute = 0;
    item.time = 0;

    const prData = item.prDB ? await searchData(item.prDB) : null;    
    const dtData = item.dtDB ? await searchData(item.dtDB) : null;

    if (prData) {
      const modelData = getModelFromLastData(prData);
      item.model = modelData ? modelData.model : '';
      calculateSums(prData, item); // 계산된 총합을 적용
    }

    if (dtData) {
      dtcalculateSums(dtData, item); // 계산된 총합을 적용
    }
    item.ngRate = calculateNgRate(item.ng, item.ok);
    item.okAchieve = calculateOkAchieve(item.ok, item.target);
  }
  productionData.splice(0, productionData.length, ...productionData);
};

export const fetchDataForAddData = async () => {
  for (const item of addData) {
    if (item.api === 'targetSearchData') {
      // targetSearchData 함수를 사용하여 데이터 가져오기
      item.data = await targetSearchData(item.dbName);
    } else if (item.api === 'searchData') {
      // searchData 함수를 사용하여 데이터 가져오기
      const result = await searchData(item.dbName);
      if (result) {
        item.data = result;
      }
    }    
  }  
};

export const updateNgRateTargets = () => {
  const targetData = addData.find(d => d.name === 'Target')?.data;
  if (targetData) {
    productionData.forEach(prodLine => {
      // 'line_name'과 'line'을 소문자로 변환하고 빈칸을 제거한 후 비교합니다.
      const lineTarget = targetData.find(t => 
        t.line_name.toLowerCase().replace(/\s+/g, '') === prodLine.targetName.toLowerCase().replace(/\s+/g, '')
      );
      if (lineTarget) {
        prodLine.ngRateTarget = lineTarget.ngrate * 100;
      }
    });
  }  
};




