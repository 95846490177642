<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Shipping</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>     
            <div class="form-group">
              <label for="type">Plan/Result:</label>
              <div>
                <input class="radio" type="radio" id="plan" value="Plan" v-model="formInputs.type">
                <label for="plan">Plan</label>
                <input class="radio" type="radio" id="result" value="Result" v-model="formInputs.type">
                <label for="result">Result</label>    
              </div>
            </div>  
            <div class="form-group">
              <label for="project">Project:</label>
              <div>
                <input class="radio" type="radio" id="bmw" value="BMW" v-model="formInputs.project">
                <label for="bmw">BMW</label>
                <input class="radio"  type="radio" id="fca" value="FCA" v-model="formInputs.project">
                <label for="fca">FCA</label>
                <input class="radio"  type="radio" id="ford" value="Ford" v-model="formInputs.project">
                <label for="ford">Ford</label>
                <input class="radio"  type="radio" id="renault" value="Renault" v-model="formInputs.project">
                <label for="renault">Renault</label>
                <input class="radio"  type="radio" id="vw" value="VW" v-model="formInputs.project">
                <label for="vw">VW</label>
              </div>
            </div>
            <div class="form-group">
              <label for="model">Model:</label>                          
              <select type="text" id="model" v-model="formInputs.model">
                <option v-for="option in modelOptions" :key="option">{{ option }}</option>
              </select>
            </div>                  
            <div class="form-group">
              <label for="modelnumber">Model Number:</label>
              <input type="number" id="modelnumber" v-model="formInputs.modelnumber" readonly class="readWindow" />
            </div>
            <div class="form-group">
              <label for="quantity">Quantity:</label>
              <input type="number" id="quantity" v-model="formInputs.quantity" />
            </div>                 
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch, ref } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const dbTableName = "shippingallproject"              

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          type: "",
          project: "",
          model: "",
          modelnumber: "",
          quantity: "",          
        });
        // -끝-   

      
      //년.월.일을 지금 시간으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
       const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        


        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day) {
            alert("Please fill in all the fields: year, month, and day.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            type: "type",
            project: "project",
            model: "model",
            modelnumber: "modelnumber",
            quantity: "quantity",   
          };

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              type: formInputs.type,
              project: formInputs.project,
              model: formInputs.model,
              modelnumber: formInputs.modelnumber,
              quantity: formInputs.quantity
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const modelOptions = ref([]); 

        const modelCode = {
          'BMW' : {'BMW 12V F40' : '2179213','BMW 12V HAF' : '2174724', 'BMW 48V GEN1.5 AI' : '2184104', 'BMW 48V GEN1.5 AJ' : '2185308'},
          'FCA' : {'FCA Front' : '2182418', 'FCA Rear' : '2182419', 'FCA Flexible POS' : '2179721', 'FCA Flexible NEG' : '2179722', 'FCA Flexible 4-5+' : '2179724', 'FCA Flexible 6+7-' : '2179723', 'FCA Flexible BSBR&CARR' : '2182420', 'FCA Flexible BSBR_A' : '4903791'},
          'Ford' : {'Front 4P8S' : '2187237', 'Rear 4P8S' : '2187238', 'Front 4P7S' : '2187239', 'Rear 4P7S' : '2187240', 'Front 3P10S' : '2187241', 'Rear 3P10S' : '2187242', 'Front 3P8S' : '2187243', 'Rear 3P8S' : '2187244' },
          'Renault' : {'BT6 Front' : '2174220', 'BT6 Rear' : '2174221', 'EV2020 60kW Front' : '2183497', 'EV2020 60kW Rear' : '2183498', 'EV2020 40kW Front' : '2183495', 'EV2020 40kW Rear' : '2183496', 'EV2020 FFC_ASSY' : '4904201'},
          'VW' : {'GEN1 2P12S' : '2175902', 'GEN1 3P8S' : '2175903', 'EVO 2P12S' : '2189891', 'EVO 3P8S' : '2189892'},
        }

        const updateModel = () => {
          console.log("updateModel called, line:", formInputs.project);
            if (formInputs.project === 'BMW') { modelOptions.value = ['BMW 12V F40','BMW 12V HAF','BMW 48V GEN1.5 AI', 'BMW 48V GEN1.5 AJ'];}
            else if (formInputs.project === 'FCA') { modelOptions.value = ['FCA Front', 'FCA Rear', 'FCA Flexible POS', 'FCA Flexible NEG', 'FCA Flexible 4-5+', 'FCA Flexible 6+7-', 'FCA Flexible BSBR&CARR', 'FCA Flexible BSBR_A']; }
            else if (formInputs.project === 'Ford') { modelOptions.value = ['Front 4P8S', 'Rear 4P8S', 'Front 4P7S', 'Rear 4P7S', 'Front 3P10S', 'Rear 3P10S', 'Front 3P8S', 'Rear 3P8S']; }
            else if (formInputs.project === 'Renault') { modelOptions.value = ['BT6 Front', 'BT6 Rear', 'EV2020 60kW Front', 'EV2020 60kW Rear', 'EV2020 40kW Front', 'EV2020 40kW Rear', 'EV2020 FFC_ASSY']; }
            else if (formInputs.project === 'VW') { modelOptions.value = ['GEN1 2P12S', 'GEN1 3P8S', 'EVO 2P12S', 'EVO 3P8S']; }

            else { modelOptions.value = ['Please select a line']; }

            if (formInputs.project in modelCode && formInputs.model in modelCode[formInputs.project]) {
                formInputs.modelnumber = modelCode[formInputs.project][formInputs.model];
              } else {
                formInputs.modelnumber = '';
              }
        };        

        watch(() => [formInputs.project, formInputs.model], updateModel);






        const closeModal = () => {
          emit("onClose");
        };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, modelOptions, saveStatus, isProcessing};

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 


};
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 400px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
    .radio { margin-left: 10px; margin-right: 5px; }

    .disabled { background-color: #ccc; cursor: not-allowed; }
</style>