import axios from 'axios';

export const getCurrentDate = () => {
  const z00 = a => a < 10 ? `0${a}` : a;
  let today = new Date();
  const day = today.getDate();
  const hour = today.getHours();
  const minute = z00(today.getMinutes());
  const sec = z00(today.getSeconds());
  if (hour < 6) { today.setDate(day - 1); }
  const adjustedYear = today.getFullYear();
  const adjustedMonth = today.getMonth() + 1;
  const adjustedDay = today.getDate();
  return { year: adjustedYear, month: adjustedMonth, day: adjustedDay, hour: z00(hour), min: minute, sec: sec };
}; 

export const getResult = (line, process, checkTableData) => {
  let item = [...checkTableData.data].reverse().find(d => d.line === line && d.process === process);        
  return item ? item.judgement : null;
};

export const getMaintenanceResult = (line, station, checkTableData) => {
  let item = [...checkTableData.data].reverse().find(d => d.line === line && d.station === station);        
  return item ? item.judgement : null;
};

export const searchData = async (tableName) => {
  const currentDate = getCurrentDate();
  const formData = {
    tableName: tableName,
    values: {                
      year: currentDate.year,
      month: currentDate.month,
      day: currentDate.day,
    }
  };          
    
  try {
    const response = await axios.get('/api/productionData', { params: formData });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return null;
  }
};

export const targetSearchData = async (tableName) => {
  const formData = { tableName: tableName };
  try {
    const res = await axios.get('/api/targetList', { params: formData });    
    return res.data
  } catch (error) {
    console.error("Error fetching target data:", error.message);
    return [];
  }
};

export const calculateNgRate = (ng, ok) => {const total = ok + ng; return total === 0 ? 0 : ((ng / total) * 100).toFixed(2);};
export const calculateOkAchieve = (ok, target) => { return target === 0 ? 0 : ((ok / target) * 100).toFixed(2);};

export const getModelFromLastData = (data) => {if (data && data.length > 0) { return data[data.length - 1]; }  return null;};
export const calculateSums = (data, item) => { for (const row of data) { item.ok += row.ok; item.ng += row.ng; item.target += row.target; item.work_minute += row.work_minute;}};
export const dtcalculateSums = (data, item) => {for (const row of data) { item.time += row.time; }}; 