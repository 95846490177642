<template>
    <div class="container">        
      <div class="search">
        <form @submit.prevent="sendDate()">
            <table class="searchTable">
                <tr>
                    <td class="start">
                        <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                        <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                        <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
                    </td>
                    <td class="separator">~</td>
                    <td class="end">
                        <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                        <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                        <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
                    </td>
                    <td class="searchTd">
                        <button type="submit" @click="callSearchData">Search</button>                        
                    </td>
                </tr>
            </table>      
        </form>
      </div>            
      <table>       
        <tr><th colspan="1"></th><th colspan="3" class="okhead">OK</th><th colspan="4" class="nghead">NG</th>
            <th colspan="3" class="tacttimehead">Tact Time (sec)</th><th colspan="3" class="downtimehead">Down Time (min)</th><th colspan="4" class="oeehead">OEE</th>
        </tr> 
        <tr><th v-for="(header, index) in headers" :key="index">{{ header }}</th></tr>         
        <tr v-for="(target, index) in targets" :key="target.name">        
            <td><B>{{ target.name }}</B></td>
            <td>{{ rowData[index].okTarget }}</td>
            <td class="okhead"><B>{{ rowData[index].ok }}</B></td>
            <td class="okhead" :style="aS(rowData[index].achieve / 100)"><B>{{ rowData[index].achieve }}%</B></td>    
            <td >{{ rowData[index].ngTarget }}</td>
            <td >{{ rowData[index].ng}}</td>
            <td class="nghead"><B>{{ rowData[index].ngRate }}%</B></td>
            <td class="nghead" :style="aS(rowData[index].achieveNG / 100)"><B>{{ rowData[index].achieveNG }}%</B></td>
            <!--<td class="nghead"><B>{{ rowData[index].achieveNG }}%</B></td>-->
            <td >{{ rowData[index].tactTimeTarget }}</td>
            <td class="tacttimehead"><B>{{ rowData[index].actualTactTime }}</B></td>
            <td class="tacttimehead" :style="aS(rowData[index].achieveTactTime / 100)"><B>{{ rowData[index].achieveTactTime }}%</B></td>
            <td >{{ rowData[index].downTimeTarget }}</td>
            <td class="downtimehead"><B>{{ rowData[index].downTime }}</B></td>
            <td class="downtimehead" :style="aS(rowData[index].achieveDownTime / 100)"><B>{{ rowData[index].achieveDownTime }}%</B></td>
            <td >{{ rowData[index].oeeAvailable }}%</td>
            <td >{{ rowData[index].oeeEfficiency }}%</td>
            <td >{{ rowData[index].oeeQuality }}%</td>
            <td class="oeehead" :style="aS(rowData[index].oee / 100)"><B>{{ rowData[index].oee }}%</B></td>
            <td >{{ rowData[index].workMinute }}</td>                 
            <td >{{ rowData[index].uph }}</td>   
        </tr>
      </table>  
    </div>    
  </template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();

    //각 설비별 데이터 베이스 table 이름을 입력해야 됨
    const targetTableName = "target";  
    const tr3smt_dbTableName = 'tr3smtpr';
    const tr3smt_dtTableName = 'tr3smtdt';
    const tr3smt_qwTableName = 'tr3smtqw';
    const tr3crimping_dbTableName = 'tr3crimpingpr';
    const tr3crimping_dtTableName = 'tr3crimpingdt';
    const tr3crimping_qwTableName = 'tr3crimpingqw';
    const tr3fpca_dbTableName = 'tr3fpcapr';
    const tr3fpca_dtTableName = 'tr3fpcadt';
    const tr3fpca_qwTableName = 'tr3fpcaqw';
    const tr3bfa2p_dbTableName = 'tr3bfa2ppr';
    const tr3bfa2p_dtTableName = 'tr3bfa2pdt';
    const tr3bfa2p_qwTableName = 'tr3bfa2pqw';
    const tr3bfa3p_dbTableName = 'tr3bfa3ppr';
    const tr3bfa3p_dtTableName = 'tr3bfa3pdt';
    const tr3bfa3p_qwTableName = 'tr3bfa3pqw';
    const evo_dbTableName = 'evopr';
    const evo_dtTableName = 'evodt';
    const evo_qwTableName = 'evoqw';
    const ev2020_60fr_dbTableName = 'ev2020_60frpr';
    const ev2020_60fr_dtTableName = 'ev2020_60frdt';
    const ev2020_60fr_qwTableName = 'ev2020_60frqw';
    const ev2020_60rr_dbTableName = 'ev2020_60rrpr';
    const ev2020_60rr_dtTableName = 'ev2020_60rrdt';
    const ev2020_60rr_qwTableName = 'ev2020_60rrqw';
    const ev2020_4060fr_dbTableName = 'ev2020_4060frpr';
    const ev2020_4060fr_dtTableName = 'ev2020_4060frdt';
    const ev2020_4060fr_qwTableName = 'ev2020_4060frqw';
    const ev2020_4060rr_dbTableName = 'ev2020_4060rrpr';
    const ev2020_4060rr_dtTableName = 'ev2020_4060rrdt';
    const ev2020_4060rr_qwTableName = 'ev2020_4060rrqw';
    const tr2smt_dbTableName = 'tr2smtpr';
    const tr2smt_dtTableName = 'tr2smtdt';
    const tr2smt_qwTableName = 'tr2smtqw';
    const tr2crimping_dbTableName = 'tr2crimpingpr';
    const tr2crimping_dtTableName = 'tr2crimpingdt';
    const tr2crimping_qwTableName = 'tr2crimpingqw';
    const tr2fpca_dbTableName = 'tr2fpcapr';
    const tr2fpca_dtTableName = 'tr2fpcadt';
    const tr2fpca_qwTableName = 'tr2fpcaqw';
    const tr2bfa2p_dbTableName = 'tr2bfa2ppr';
    const tr2bfa2p_dtTableName = 'tr2bfa2pdt';
    const tr2bfa2p_qwTableName = 'tr2bfa2pqw';
    const tr2bfa3p_dbTableName = 'tr2bfa3ppr';
    const tr2bfa3p_dtTableName = 'tr2bfa3pdt';
    const tr2bfa3p_qwTableName = 'tr2bfa3pqw';
    const ford1fr_dbTableName = 'ford1frpr';
    const ford1fr_dtTableName = 'ford1frdt';
    const ford1fr_qwTableName = 'ford1frqw';
    const ford1rr_dbTableName = 'ford1rrpr';
    const ford1rr_dtTableName = 'ford1rrdt';
    const ford1rr_qwTableName = 'ford1rrqw';
    const ford2fr_dbTableName = 'ford2frpr';
    const ford2fr_dtTableName = 'ford2frdt';
    const ford2fr_qwTableName = 'ford2frqw';
    const ford2rr_dbTableName = 'ford2rrpr';
    const ford2rr_dtTableName = 'ford2rrdt';
    const ford2rr_qwTableName = 'ford2rrqw';
    const ford3fr_dbTableName = 'ford3frpr';
    const ford3fr_dtTableName = 'ford3frdt';
    const ford3fr_qwTableName = 'ford3frqw';
    const ford3rr_dbTableName = 'ford3rrpr';
    const ford3rr_dtTableName = 'ford3rrdt';
    const ford3rr_qwTableName = 'ford3rrqw';
    const fca1_dbTableName = 'fca1pr';
    const fca1_dtTableName = 'fca1dt';
    const fca1_qwTableName = 'fca1qw';
    const fca2_dbTableName = 'fca2pr';
    const fca2_dtTableName = 'fca2dt';
    const fca2_qwTableName = 'fca2qw';
    const bt6_dbTableName = 'bt6pr';
    const bt6_dtTableName = 'bt6dt';
    const bt6_qwTableName = 'bt6qw';
    const bmw12v_dbTableName = 'bmw12vpr';
    const bmw12v_dtTableName = 'bmw12vdt';
    const bmw12v_qwTableName = 'bmw12vqw';
    const bmw48v_dbTableName = 'bmw48vpr';
    const bmw48v_dtTableName = 'bmw48vdt';
    const bmw48v_qwTableName = 'bmw48vqw';
    const fca_flex_bc_dbTableName = 'fca_flex_bcpr';
    const fca_flex_bc_dtTableName = 'fca_flex_bcdt';
    const fca_flex_bc_qwTableName = 'fca_flex_bcqw';
    const fca_flex_po_dbTableName = 'fca_flex_popr';
    const fca_flex_po_dtTableName = 'fca_flex_podt';
    const fca_flex_po_qwTableName = 'fca_flex_poqw';
    const fca_flex_67_dbTableName = 'fca_flex_67pr';
    const fca_flex_67_dtTableName = 'fca_flex_67dt';
    const fca_flex_67_qwTableName = 'fca_flex_67qw';
    const fca_flex_ne_dbTableName = 'fca_flex_nepr';
    const fca_flex_ne_dtTableName = 'fca_flex_nedt';
    const fca_flex_ne_qwTableName = 'fca_flex_neqw';
    const fca_flex_45_dbTableName = 'fca_flex_45pr';
    const fca_flex_45_dtTableName = 'fca_flex_45dt';
    const fca_flex_45_qwTableName = 'fca_flex_45qw';

    const headers = [ 'Line', 'Target', 'QTY', 'Achieve',
                      'Target','QTY','Rate','Achieve',
                      'Target', 'Actual', 'Achieve',
                      'Target', 'Actual', 'Achieve',
                      'Available', 'Efficiency', 'Quality', 'OEE', 'Running Time', 'UPH'
    ];

    //각 설비별 Data를 받아올 함수 명을 입력해야 됨
    const targetList = reactive({ data: [], })
    const tr3smt_prTableData = reactive({ data: [] });
    const tr3smt_dtTableData = reactive({ data: [] });
    const tr3smt_qwTableData = reactive({ data: [] });
    const tr3crimping_prTableData = reactive({ data: [] });
    const tr3crimping_dtTableData = reactive({ data: [] });
    const tr3crimping_qwTableData = reactive({ data: [] });
    const tr3fpca_prTableData = reactive({ data: [] });
    const tr3fpca_dtTableData = reactive({ data: [] });
    const tr3fpca_qwTableData = reactive({ data: [] });
    const tr3bfa2p_prTableData = reactive({ data: [] });
    const tr3bfa2p_dtTableData = reactive({ data: [] });
    const tr3bfa2p_qwTableData = reactive({ data: [] });
    const tr3bfa3p_prTableData = reactive({ data: [] });
    const tr3bfa3p_dtTableData = reactive({ data: [] });
    const tr3bfa3p_qwTableData = reactive({ data: [] });
    const evo_prTableData = reactive({ data: [] });
    const evo_dtTableData = reactive({ data: [] });
    const evo_qwTableData = reactive({ data: [] });
    const ev2020_60fr_prTableData = reactive({ data: [] });
    const ev2020_60fr_dtTableData = reactive({ data: [] });
    const ev2020_60fr_qwTableData = reactive({ data: [] });
    const ev2020_60rr_prTableData = reactive({ data: [] });
    const ev2020_60rr_dtTableData = reactive({ data: [] });
    const ev2020_60rr_qwTableData = reactive({ data: [] });
    const ev2020_4060fr_prTableData = reactive({ data: [] });
    const ev2020_4060fr_dtTableData = reactive({ data: [] });
    const ev2020_4060fr_qwTableData = reactive({ data: [] });
    const ev2020_4060rr_prTableData = reactive({ data: [] });
    const ev2020_4060rr_dtTableData = reactive({ data: [] });
    const ev2020_4060rr_qwTableData = reactive({ data: [] });
    const tr2smt_prTableData = reactive({ data: [] });
    const tr2smt_dtTableData = reactive({ data: [] });
    const tr2smt_qwTableData = reactive({ data: [] });
    const tr2crimping_prTableData = reactive({ data: [] });
    const tr2crimping_dtTableData = reactive({ data: [] });
    const tr2crimping_qwTableData = reactive({ data: [] });
    const tr2fpca_prTableData = reactive({ data: [] });
    const tr2fpca_dtTableData = reactive({ data: [] });
    const tr2fpca_qwTableData = reactive({ data: [] });
    const tr2bfa2p_prTableData = reactive({ data: [] });
    const tr2bfa2p_dtTableData = reactive({ data: [] });
    const tr2bfa2p_qwTableData = reactive({ data: [] });
    const tr2bfa3p_prTableData = reactive({ data: [] });
    const tr2bfa3p_dtTableData = reactive({ data: [] });
    const tr2bfa3p_qwTableData = reactive({ data: [] });
    const ford1fr_prTableData = reactive({ data: [] });
    const ford1fr_dtTableData = reactive({ data: [] });
    const ford1fr_qwTableData = reactive({ data: [] });
    const ford1rr_prTableData = reactive({ data: [] });
    const ford1rr_dtTableData = reactive({ data: [] });
    const ford1rr_qwTableData = reactive({ data: [] });
    const ford2fr_prTableData = reactive({ data: [] });
    const ford2fr_dtTableData = reactive({ data: [] });
    const ford2fr_qwTableData = reactive({ data: [] });
    const ford2rr_prTableData = reactive({ data: [] });
    const ford2rr_dtTableData = reactive({ data: [] });
    const ford2rr_qwTableData = reactive({ data: [] });
    const ford3fr_prTableData = reactive({ data: [] });
    const ford3fr_dtTableData = reactive({ data: [] });
    const ford3fr_qwTableData = reactive({ data: [] });
    const ford3rr_prTableData = reactive({ data: [] });
    const ford3rr_dtTableData = reactive({ data: [] });
    const ford3rr_qwTableData = reactive({ data: [] });
    const fca1_prTableData = reactive({ data: [] });
    const fca1_dtTableData = reactive({ data: [] });
    const fca1_qwTableData = reactive({ data: [] });
    const fca2_prTableData = reactive({ data: [] });
    const fca2_dtTableData = reactive({ data: [] });
    const fca2_qwTableData = reactive({ data: [] });
    const bt6_prTableData = reactive({ data: [] });
    const bt6_dtTableData = reactive({ data: [] });
    const bt6_qwTableData = reactive({ data: [] });
    const bmw12v_prTableData = reactive({ data: [] });
    const bmw12v_dtTableData = reactive({ data: [] });
    const bmw12v_qwTableData = reactive({ data: [] });
    const bmw48v_prTableData = reactive({ data: [] });
    const bmw48v_dtTableData = reactive({ data: [] });
    const bmw48v_qwTableData = reactive({ data: [] });
    const fca_flex_bc_prTableData = reactive({ data: [] });
    const fca_flex_bc_dtTableData = reactive({ data: [] });
    const fca_flex_bc_qwTableData = reactive({ data: [] });
    const fca_flex_po_prTableData = reactive({ data: [] });
    const fca_flex_po_dtTableData = reactive({ data: [] });
    const fca_flex_po_qwTableData = reactive({ data: [] });
    const fca_flex_67_prTableData = reactive({ data: [] });
    const fca_flex_67_dtTableData = reactive({ data: [] });
    const fca_flex_67_qwTableData = reactive({ data: [] });
    const fca_flex_ne_prTableData = reactive({ data: [] });
    const fca_flex_ne_dtTableData = reactive({ data: [] });
    const fca_flex_ne_qwTableData = reactive({ data: [] });
    const fca_flex_45_prTableData = reactive({ data: [] });
    const fca_flex_45_dtTableData = reactive({ data: [] });
    const fca_flex_45_qwTableData = reactive({ data: [] });    

    const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value]; return {sendDateData};};     
    const searchData = async (tableName) => {
        const sendDateData = await sendDate();
        console.log('Requesting data with:', {
            tablename: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endtYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        });
        const formData = {        
            tableName: tableName,
            startYear: sendDateData.sendDateData[0],
            startMonth: sendDateData.sendDateData[1],
            startDay: sendDateData.sendDateData[2],
            endYear: sendDateData.sendDateData[3],
            endMonth: sendDateData.sendDateData[4],
            endDay: sendDateData.sendDateData[5],
        };  

        targetList.data = [];
        tr2smt_prTableData.data = [];
        tr2smt_dtTableData.data = [];
        tr2smt_qwTableData.data = [];
        tr2crimping_prTableData.data = [];
        tr2crimping_dtTableData.data = [];
        tr2crimping_qwTableData.data = [];
        tr2fpca_prTableData.data = [];
        tr2fpca_dtTableData.data = [];
        tr2fpca_qwTableData.data = [];
        tr2bfa2p_prTableData.data = [];
        tr2bfa2p_dtTableData.data = [];
        tr2bfa2p_qwTableData.data = [];
        tr2bfa3p_prTableData.data = [];
        tr2bfa3p_dtTableData.data = [];
        tr2bfa3p_qwTableData.data = [];
        tr3smt_prTableData.data = [];
        tr3smt_dtTableData.data = [];
        tr3smt_qwTableData.data = [];
        tr3crimping_prTableData.data = [];
        tr3crimping_dtTableData.data = [];
        tr3crimping_qwTableData.data = [];
        tr3fpca_prTableData.data = [];
        tr3fpca_dtTableData.data = [];
        tr3fpca_qwTableData.data = [];
        tr3bfa2p_prTableData.data = [];
        tr3bfa2p_dtTableData.data = [];
        tr3bfa2p_qwTableData.data = [];
        tr3bfa3p_prTableData.data = [];
        tr3bfa3p_dtTableData.data = [];
        tr3bfa3p_qwTableData.data = [];
        evo_prTableData.data = [];
        evo_dtTableData.data = [];
        evo_qwTableData.data = [];
        ev2020_60fr_prTableData.data = [];
        ev2020_60fr_dtTableData.data = [];
        ev2020_60fr_qwTableData.data = [];
        ev2020_60rr_prTableData.data = [];
        ev2020_60rr_dtTableData.data = [];
        ev2020_60rr_qwTableData.data = [];
        ev2020_4060fr_prTableData.data = [];
        ev2020_4060fr_dtTableData.data = [];
        ev2020_4060fr_qwTableData.data = [];
        ev2020_4060rr_prTableData.data = [];
        ev2020_4060rr_dtTableData.data = [];
        ev2020_4060rr_qwTableData.data = [];
        ford1fr_prTableData.data = [];
        ford1fr_dtTableData.data = [];
        ford1fr_qwTableData.data = [];
        ford1rr_prTableData.data = [];
        ford1rr_dtTableData.data = [];
        ford1rr_qwTableData.data = [];
        ford2fr_prTableData.data = [];
        ford2fr_dtTableData.data = [];
        ford2fr_qwTableData.data = [];
        ford2rr_prTableData.data = [];
        ford2rr_dtTableData.data = [];
        ford2rr_qwTableData.data = [];
        ford3fr_prTableData.data = [];
        ford3fr_dtTableData.data = [];
        ford3fr_qwTableData.data = [];
        ford3rr_prTableData.data = [];
        ford3rr_dtTableData.data = [];
        ford3rr_qwTableData.data = [];
        fca1_prTableData.data = [];
        fca1_dtTableData.data = [];
        fca1_qwTableData.data = [];
        fca2_prTableData.data = [];
        fca2_dtTableData.data = [];
        fca2_qwTableData.data = [];
        bt6_prTableData.data = [];
        bt6_dtTableData.data = [];
        bt6_qwTableData.data = [];
        bmw12v_prTableData.data = [];
        bmw12v_dtTableData.data = [];
        bmw12v_qwTableData.data = [];
        bmw48v_prTableData.data = [];
        bmw48v_dtTableData.data = [];
        bmw48v_qwTableData.data = [];
        fca_flex_bc_prTableData.data = [];
        fca_flex_bc_dtTableData.data = [];
        fca_flex_bc_qwTableData.data = [];
        fca_flex_po_prTableData.data = [];
        fca_flex_po_dtTableData.data = [];
        fca_flex_po_qwTableData.data = [];
        fca_flex_67_prTableData.data = [];
        fca_flex_67_dtTableData.data = [];
        fca_flex_67_qwTableData.data = [];
        fca_flex_ne_prTableData.data = [];
        fca_flex_ne_dtTableData.data = [];
        fca_flex_ne_qwTableData.data = [];
        fca_flex_45_prTableData.data = [];
        fca_flex_45_dtTableData.data = [];
        fca_flex_45_qwTableData.data = [];

      console.log('Requesting data with:', formData);
      axios.get('/api/searchPeriod', { params: formData }) //각 설비별로 DB table 이름과 변수 이름을 입력해야 됨
        .then((res) => {          
          switch (tableName) {           
            case 'tr3smtpr': tr3smt_prTableData.data = res.data; break;
            case 'tr3smtdt': tr3smt_dtTableData.data = res.data; break;                          
            case 'tr3smtqw': tr3smt_qwTableData.data = res.data; break;
            case 'tr3crimpingpr': tr3crimping_prTableData.data = res.data; break;
            case 'tr3crimpingdt': tr3crimping_dtTableData.data = res.data; break;                          
            case 'tr3crimpingqw': tr3crimping_qwTableData.data = res.data; break;
            case 'tr3fpcapr': tr3fpca_prTableData.data = res.data; break;
            case 'tr3fpcadt': tr3fpca_dtTableData.data = res.data; break;                          
            case 'tr3fpcaqw': tr3fpca_qwTableData.data = res.data; break;
            case 'tr3bfa2ppr': tr3bfa2p_prTableData.data = res.data; break;
            case 'tr3bfa2pdt': tr3bfa2p_dtTableData.data = res.data; break;                          
            case 'tr3bfa2pqw': tr3bfa2p_qwTableData.data = res.data; break;
            case 'tr3bfa3ppr': tr3bfa3p_prTableData.data = res.data; break;
            case 'tr3bfa3pdt': tr3bfa3p_dtTableData.data = res.data; break;                          
            case 'tr3bfa3pqw': tr3bfa3p_qwTableData.data = res.data; break;
            case 'evopr': evo_prTableData.data = res.data; break;
            case 'evodt': evo_dtTableData.data = res.data; break;                          
            case 'evoqw': evo_qwTableData.data = res.data; break;
            case 'ev2020_60frpr': ev2020_60fr_prTableData.data = res.data; break;
            case 'ev2020_60frdt': ev2020_60fr_dtTableData.data = res.data; break;                          
            case 'ev2020_60frqw': ev2020_60fr_qwTableData.data = res.data; break;
            case 'ev2020_60rrpr': ev2020_60rr_prTableData.data = res.data; break;
            case 'ev2020_60rrdt': ev2020_60rr_dtTableData.data = res.data; break;                          
            case 'ev2020_60rrqw': ev2020_60rr_qwTableData.data = res.data; break;
            case 'ev2020_4060frpr': ev2020_4060fr_prTableData.data = res.data; break;
            case 'ev2020_4060frdt': ev2020_4060fr_dtTableData.data = res.data; break;                          
            case 'ev2020_4060frqw': ev2020_4060fr_qwTableData.data = res.data; break;
            case 'ev2020_4060rrpr': ev2020_4060rr_prTableData.data = res.data; break;
            case 'ev2020_4060rrdt': ev2020_4060rr_dtTableData.data = res.data; break;                          
            case 'ev2020_4060rrqw': ev2020_4060rr_qwTableData.data = res.data; break;
            case 'tr2smtpr': tr2smt_prTableData.data = res.data; break;
            case 'tr2smtdt': tr2smt_dtTableData.data = res.data; break;                          
            case 'tr2smtqw': tr2smt_qwTableData.data = res.data; break;
            case 'tr2crimpingpr': tr2crimping_prTableData.data = res.data; break;
            case 'tr2crimpingdt': tr2crimping_dtTableData.data = res.data; break;                          
            case 'tr2crimpingqw': tr2crimping_qwTableData.data = res.data; break;
            case 'tr2fpcapr': tr2fpca_prTableData.data = res.data; break;
            case 'tr2fpcadt': tr2fpca_dtTableData.data = res.data; break;                          
            case 'tr2fpcaqw': tr2fpca_qwTableData.data = res.data; break;
            case 'tr2bfa2ppr': tr2bfa2p_prTableData.data = res.data; break;
            case 'tr2bfa2pdt': tr2bfa2p_dtTableData.data = res.data; break;                          
            case 'tr2bfa2pqw': tr2bfa2p_qwTableData.data = res.data; break;
            case 'tr2bfa3ppr': tr2bfa3p_prTableData.data = res.data; break;
            case 'tr2bfa3pdt': tr2bfa3p_dtTableData.data = res.data; break;                          
            case 'tr2bfa3pqw': tr2bfa3p_qwTableData.data = res.data; break;
            case 'ford1frpr': ford1fr_prTableData.data = res.data; break;
            case 'ford1frdt': ford1fr_dtTableData.data = res.data; break;                          
            case 'ford1frqw': ford1fr_qwTableData.data = res.data; break;
            case 'ford1rrpr': ford1rr_prTableData.data = res.data; break;
            case 'ford1rrdt': ford1rr_dtTableData.data = res.data; break;                          
            case 'ford1rrqw': ford1rr_qwTableData.data = res.data; break;
            case 'ford2frpr': ford2fr_prTableData.data = res.data; break;
            case 'ford2frdt': ford2fr_dtTableData.data = res.data; break;                          
            case 'ford2frqw': ford2fr_qwTableData.data = res.data; break;
            case 'ford2rrpr': ford2rr_prTableData.data = res.data; break;
            case 'ford2rrdt': ford2rr_dtTableData.data = res.data; break;                          
            case 'ford2rrqw': ford2rr_qwTableData.data = res.data; break;
            case 'ford3frpr': ford3fr_prTableData.data = res.data; break;
            case 'ford3frdt': ford3fr_dtTableData.data = res.data; break;                          
            case 'ford3frqw': ford3fr_qwTableData.data = res.data; break;
            case 'ford3rrpr': ford3rr_prTableData.data = res.data; break;
            case 'ford3rrdt': ford3rr_dtTableData.data = res.data; break;                          
            case 'ford3rrqw': ford3rr_qwTableData.data = res.data; break;
            case 'fca1pr': fca1_prTableData.data = res.data; break;
            case 'fca1dt': fca1_dtTableData.data = res.data; break;                          
            case 'fca1qw': fca1_qwTableData.data = res.data; break;
            case 'fca2pr': fca2_prTableData.data = res.data; break;
            case 'fca2dt': fca2_dtTableData.data = res.data; break;                          
            case 'fca2qw': fca2_qwTableData.data = res.data; break;
            case 'bt6pr': bt6_prTableData.data = res.data; break;
            case 'bt6dt': bt6_dtTableData.data = res.data; break;                          
            case 'bt6qw': bt6_qwTableData.data = res.data; break;
            case 'bmw12vpr': bmw12v_prTableData.data = res.data; break;
            case 'bmw12vdt': bmw12v_dtTableData.data = res.data; break;                          
            case 'bmw12vqw': bmw12v_qwTableData.data = res.data; break;
            case 'bmw48vpr': bmw48v_prTableData.data = res.data; break;
            case 'bmw48vdt': bmw48v_dtTableData.data = res.data; break;                          
            case 'bmw48vqw': bmw48v_qwTableData.data = res.data; break;
            case 'fca_flex_bcpr': fca_flex_bc_prTableData.data = res.data; break;
            case 'fca_flex_bcdt': fca_flex_bc_dtTableData.data = res.data; break;                          
            case 'fca_flex_bcqw': fca_flex_bc_qwTableData.data = res.data; break;
            case 'fca_flex_popr': fca_flex_po_prTableData.data = res.data; break;
            case 'fca_flex_podt': fca_flex_po_dtTableData.data = res.data; break;                          
            case 'fca_flex_poqw': fca_flex_po_qwTableData.data = res.data; break;
            case 'fca_flex_67pr': fca_flex_67_prTableData.data = res.data; break;
            case 'fca_flex_67dt': fca_flex_67_dtTableData.data = res.data; break;                          
            case 'fca_flex_67qw': fca_flex_67_qwTableData.data = res.data; break;
            case 'fca_flex_nepr': fca_flex_ne_prTableData.data = res.data; break;
            case 'fca_flex_nedt': fca_flex_ne_dtTableData.data = res.data; break;                          
            case 'fca_flex_neqw': fca_flex_ne_qwTableData.data = res.data; break;
            case 'fca_flex_45pr': fca_flex_45_prTableData.data = res.data; break;
            case 'fca_flex_45dt': fca_flex_45_dtTableData.data = res.data; break;                          
            case 'fca_flex_45qw': fca_flex_45_qwTableData.data = res.data; break;
          }
        });
    };    

    const settingData = reactive({ data: [], });        

    //각 설비별 Data를 호출하는 것을 입력해야 됨
    const callSearchData = async () => {         
        const tableName = 'setting';
        const formData = { tableName: tableName };

        try {
            const res = await axios.get('/api/targetList', { params: formData });
            settingData.data = res.data;

            const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));  
            const isReportEnabled = reportSetting && reportSetting.report === 1;
            if (!isReportEnabled) {
                alert("Report is currently disabled.");
                return;
            }

            targetSearchData();
            searchData(tr3smt_dbTableName);
            searchData(tr3smt_dtTableName);      
            searchData(tr3smt_qwTableName);      
            searchData(tr3crimping_dbTableName);
            searchData(tr3crimping_dtTableName);      
            searchData(tr3crimping_qwTableName);      
            searchData(tr3fpca_dbTableName);
            searchData(tr3fpca_dtTableName);      
            searchData(tr3fpca_qwTableName);      
            searchData(tr3bfa2p_dbTableName);
            searchData(tr3bfa2p_dtTableName);      
            searchData(tr3bfa2p_qwTableName);      
            searchData(tr3bfa3p_dbTableName);
            searchData(tr3bfa3p_dtTableName);      
            searchData(tr3bfa3p_qwTableName);      
            searchData(evo_dbTableName);
            searchData(evo_dtTableName);      
            searchData(evo_qwTableName);     
            searchData(ev2020_60fr_dbTableName);
            searchData(ev2020_60fr_dtTableName);      
            searchData(ev2020_60fr_qwTableName);     
            searchData(ev2020_60rr_dbTableName);
            searchData(ev2020_60rr_dtTableName);      
            searchData(ev2020_60rr_qwTableName);     
            searchData(ev2020_4060fr_dbTableName);
            searchData(ev2020_4060fr_dtTableName);      
            searchData(ev2020_4060fr_qwTableName);     
            searchData(ev2020_4060rr_dbTableName);
            searchData(ev2020_4060rr_dtTableName);      
            searchData(ev2020_4060rr_qwTableName);  
            searchData(tr2smt_dbTableName);
            searchData(tr2smt_dtTableName);      
            searchData(tr2smt_qwTableName);      
            searchData(tr2crimping_dbTableName);
            searchData(tr2crimping_dtTableName);      
            searchData(tr2crimping_qwTableName);      
            searchData(tr2fpca_dbTableName);
            searchData(tr2fpca_dtTableName);      
            searchData(tr2fpca_qwTableName);      
            searchData(tr2bfa2p_dbTableName);
            searchData(tr2bfa2p_dtTableName);      
            searchData(tr2bfa2p_qwTableName);      
            searchData(tr2bfa3p_dbTableName);
            searchData(tr2bfa3p_dtTableName);      
            searchData(tr2bfa3p_qwTableName);      
            searchData(ford1fr_dbTableName);
            searchData(ford1fr_dtTableName);      
            searchData(ford1fr_qwTableName);     
            searchData(ford1rr_dbTableName);
            searchData(ford1rr_dtTableName);      
            searchData(ford1rr_qwTableName);     
            searchData(ford2fr_dbTableName);
            searchData(ford2fr_dtTableName);      
            searchData(ford2fr_qwTableName);     
            searchData(ford2rr_dbTableName);
            searchData(ford2rr_dtTableName);      
            searchData(ford2rr_qwTableName);     
            searchData(ford3fr_dbTableName);
            searchData(ford3fr_dtTableName);      
            searchData(ford3fr_qwTableName);     
            searchData(ford3rr_dbTableName);
            searchData(ford3rr_dtTableName);      
            searchData(ford3rr_qwTableName);     
            searchData(fca1_dbTableName);
            searchData(fca1_dtTableName);      
            searchData(fca1_qwTableName);   
            searchData(fca2_dbTableName);
            searchData(fca2_dtTableName);      
            searchData(fca2_qwTableName);       
            searchData(bt6_dbTableName);
            searchData(bt6_dtTableName);      
            searchData(bt6_qwTableName);       
            searchData(bmw12v_dbTableName);
            searchData(bmw12v_dtTableName);      
            searchData(bmw12v_qwTableName);       
            searchData(bmw48v_dbTableName);
            searchData(bmw48v_dtTableName);      
            searchData(bmw48v_qwTableName);       
            searchData(fca_flex_bc_dbTableName);
            searchData(fca_flex_bc_dtTableName);      
            searchData(fca_flex_bc_qwTableName);    
            searchData(fca_flex_po_dbTableName);
            searchData(fca_flex_po_dtTableName);      
            searchData(fca_flex_po_qwTableName);    
            searchData(fca_flex_67_dbTableName);
            searchData(fca_flex_67_dtTableName);      
            searchData(fca_flex_67_qwTableName);    
            searchData(fca_flex_ne_dbTableName);
            searchData(fca_flex_ne_dtTableName);      
            searchData(fca_flex_ne_qwTableName);    
            searchData(fca_flex_45_dbTableName);
            searchData(fca_flex_45_dtTableName);      
            searchData(fca_flex_45_qwTableName);                          
        
        } catch (error) {
            console.error("Error download excel data:", error.message);
        }
    };   

    //각 설비별로 item 이름과 변수 이름을 입력해 줘야 함
    const targetItem = computed(() => {const items = {}; targetList.data.forEach((d) => {items[d.line_name] = d;});return items;});
    const tr3smt_item = computed(() => { return tr3smt_prTableData.data.map(d => {return { ...d};});});
    const tr3smt_dtItem = computed(() => {return tr3smt_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3smt_qwItem = computed(() => {return tr3smt_qwTableData.data.map(d => {return { ...d,};});});    
    const tr3crimping_item = computed(() => { return tr3crimping_prTableData.data.map(d => {return { ...d};});});
    const tr3crimping_dtItem = computed(() => {return tr3crimping_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3crimping_qwItem = computed(() => {return tr3crimping_qwTableData.data.map(d => {return { ...d,};});});    
    const tr3fpca_item = computed(() => { return tr3fpca_prTableData.data.map(d => {return { ...d};});});
    const tr3fpca_dtItem = computed(() => {return tr3fpca_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3fpca_qwItem = computed(() => {return tr3fpca_qwTableData.data.map(d => {return { ...d,};});});    
    const tr3bfa2p_item = computed(() => { return tr3bfa2p_prTableData.data.map(d => {return { ...d};});});
    const tr3bfa2p_dtItem = computed(() => {return tr3bfa2p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3bfa2p_qwItem = computed(() => {return tr3bfa2p_qwTableData.data.map(d => {return { ...d,};});});    
    const tr3bfa3p_item = computed(() => { return tr3bfa3p_prTableData.data.map(d => {return { ...d};});});
    const tr3bfa3p_dtItem = computed(() => {return tr3bfa3p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr3bfa3p_qwItem = computed(() => {return tr3bfa3p_qwTableData.data.map(d => {return { ...d,};});});    
    const evo_item = computed(() => { return evo_prTableData.data.map(d => {return { ...d};});});
    const evo_dtItem = computed(() => {return evo_dtTableData.data.map(d => {return { ...d,};});});    
    const evo_qwItem = computed(() => {return evo_qwTableData.data.map(d => {return { ...d,};});});   
    const ev2020_60fr_item = computed(() => { return ev2020_60fr_prTableData.data.map(d => {return { ...d};});});
    const ev2020_60fr_dtItem = computed(() => {return ev2020_60fr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_60fr_qwItem = computed(() => {return ev2020_60fr_qwTableData.data.map(d => {return { ...d,};});});   
    const ev2020_60rr_item = computed(() => { return ev2020_60rr_prTableData.data.map(d => {return { ...d};});});
    const ev2020_60rr_dtItem = computed(() => {return ev2020_60rr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_60rr_qwItem = computed(() => {return ev2020_60rr_qwTableData.data.map(d => {return { ...d,};});});   
    const ev2020_4060fr_item = computed(() => { return ev2020_4060fr_prTableData.data.map(d => {return { ...d};});});
    const ev2020_4060fr_dtItem = computed(() => {return ev2020_4060fr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_4060fr_qwItem = computed(() => {return ev2020_4060fr_qwTableData.data.map(d => {return { ...d,};});});   
    const ev2020_4060rr_item = computed(() => { return ev2020_4060rr_prTableData.data.map(d => {return { ...d};});});
    const ev2020_4060rr_dtItem = computed(() => {return ev2020_4060rr_dtTableData.data.map(d => {return { ...d,};});});    
    const ev2020_4060rr_qwItem = computed(() => {return ev2020_4060rr_qwTableData.data.map(d => {return { ...d,};});});   
    const tr2smt_item = computed(() => { return tr2smt_prTableData.data.map(d => {return { ...d};});});
    const tr2smt_dtItem = computed(() => {return tr2smt_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2smt_qwItem = computed(() => {return tr2smt_qwTableData.data.map(d => {return { ...d,};});});    
    const tr2crimping_item = computed(() => { return tr2crimping_prTableData.data.map(d => {return { ...d};});});
    const tr2crimping_dtItem = computed(() => {return tr2crimping_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2crimping_qwItem = computed(() => {return tr2crimping_qwTableData.data.map(d => {return { ...d,};});});    
    const tr2fpca_item = computed(() => { return tr2fpca_prTableData.data.map(d => {return { ...d};});});
    const tr2fpca_dtItem = computed(() => {return tr2fpca_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2fpca_qwItem = computed(() => {return tr2fpca_qwTableData.data.map(d => {return { ...d,};});});    
    const tr2bfa2p_item = computed(() => { return tr2bfa2p_prTableData.data.map(d => {return { ...d};});});
    const tr2bfa2p_dtItem = computed(() => {return tr2bfa2p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2bfa2p_qwItem = computed(() => {return tr2bfa2p_qwTableData.data.map(d => {return { ...d,};});});    
    const tr2bfa3p_item = computed(() => { return tr2bfa3p_prTableData.data.map(d => {return { ...d};});});
    const tr2bfa3p_dtItem = computed(() => {return tr2bfa3p_dtTableData.data.map(d => {return { ...d,};});});    
    const tr2bfa3p_qwItem = computed(() => {return tr2bfa3p_qwTableData.data.map(d => {return { ...d,};});});  
    const ford1fr_item = computed(() => { return ford1fr_prTableData.data.map(d => {return { ...d};});});
    const ford1fr_dtItem = computed(() => {return ford1fr_dtTableData.data.map(d => {return { ...d,};});});    
    const ford1fr_qwItem = computed(() => {return ford1fr_qwTableData.data.map(d => {return { ...d,};});});   
    const ford1rr_item = computed(() => { return ford1rr_prTableData.data.map(d => {return { ...d};});});
    const ford1rr_dtItem = computed(() => {return ford1rr_dtTableData.data.map(d => {return { ...d,};});});    
    const ford1rr_qwItem = computed(() => {return ford1rr_qwTableData.data.map(d => {return { ...d,};});});   
    const ford2fr_item = computed(() => { return ford2fr_prTableData.data.map(d => {return { ...d};});});
    const ford2fr_dtItem = computed(() => {return ford2fr_dtTableData.data.map(d => {return { ...d,};});});    
    const ford2fr_qwItem = computed(() => {return ford2fr_qwTableData.data.map(d => {return { ...d,};});});   
    const ford2rr_item = computed(() => { return ford2rr_prTableData.data.map(d => {return { ...d};});});
    const ford2rr_dtItem = computed(() => {return ford2rr_dtTableData.data.map(d => {return { ...d,};});});    
    const ford2rr_qwItem = computed(() => {return ford2rr_qwTableData.data.map(d => {return { ...d,};});});   
    const ford3fr_item = computed(() => { return ford3fr_prTableData.data.map(d => {return { ...d};});});
    const ford3fr_dtItem = computed(() => {return ford3fr_dtTableData.data.map(d => {return { ...d,};});});    
    const ford3fr_qwItem = computed(() => {return ford3fr_qwTableData.data.map(d => {return { ...d,};});});   
    const ford3rr_item = computed(() => { return ford3rr_prTableData.data.map(d => {return { ...d};});});
    const ford3rr_dtItem = computed(() => {return ford3rr_dtTableData.data.map(d => {return { ...d,};});});    
    const ford3rr_qwItem = computed(() => {return ford3rr_qwTableData.data.map(d => {return { ...d,};});});   
    const fca1_item = computed(() => { return fca1_prTableData.data.map(d => {return { ...d};});});
    const fca1_dtItem = computed(() => {return fca1_dtTableData.data.map(d => {return { ...d,};});});    
    const fca1_qwItem = computed(() => {return fca1_qwTableData.data.map(d => {return { ...d,};});});   
    const fca2_item = computed(() => { return fca2_prTableData.data.map(d => {return { ...d};});});
    const fca2_dtItem = computed(() => {return fca2_dtTableData.data.map(d => {return { ...d,};});});    
    const fca2_qwItem = computed(() => {return fca2_qwTableData.data.map(d => {return { ...d,};});});   
    const bt6_item = computed(() => { return bt6_prTableData.data.map(d => {return { ...d};});});
    const bt6_dtItem = computed(() => {return bt6_dtTableData.data.map(d => {return { ...d,};});});    
    const bt6_qwItem = computed(() => {return bt6_qwTableData.data.map(d => {return { ...d,};});});  
    const bmw12v_item = computed(() => { return bmw12v_prTableData.data.map(d => {return { ...d};});});
    const bmw12v_dtItem = computed(() => {return bmw12v_dtTableData.data.map(d => {return { ...d,};});});    
    const bmw12v_qwItem = computed(() => {return bmw12v_qwTableData.data.map(d => {return { ...d,};});});  
    const bmw48v_item = computed(() => { return bmw48v_prTableData.data.map(d => {return { ...d};});});
    const bmw48v_dtItem = computed(() => {return bmw48v_dtTableData.data.map(d => {return { ...d,};});});    
    const bmw48v_qwItem = computed(() => {return bmw48v_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_bc_item = computed(() => { return fca_flex_bc_prTableData.data.map(d => {return { ...d};});});
    const fca_flex_bc_dtItem = computed(() => {return fca_flex_bc_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_bc_qwItem = computed(() => {return fca_flex_bc_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_po_item = computed(() => { return fca_flex_po_prTableData.data.map(d => {return { ...d};});});
    const fca_flex_po_dtItem = computed(() => {return fca_flex_po_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_po_qwItem = computed(() => {return fca_flex_po_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_67_item = computed(() => { return fca_flex_67_prTableData.data.map(d => {return { ...d};});});
    const fca_flex_67_dtItem = computed(() => {return fca_flex_67_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_67_qwItem = computed(() => {return fca_flex_67_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_ne_item = computed(() => { return fca_flex_ne_prTableData.data.map(d => {return { ...d};});});
    const fca_flex_ne_dtItem = computed(() => {return fca_flex_ne_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_ne_qwItem = computed(() => {return fca_flex_ne_qwTableData.data.map(d => {return { ...d,};});});  
    const fca_flex_45_item = computed(() => { return fca_flex_45_prTableData.data.map(d => {return { ...d};});});
    const fca_flex_45_dtItem = computed(() => {return fca_flex_45_dtTableData.data.map(d => {return { ...d,};});});    
    const fca_flex_45_qwItem = computed(() => {return fca_flex_45_qwTableData.data.map(d => {return { ...d,};});});  

    const sums = computed(() => {   
      let sumsObject = {};
      targets.forEach((target) => {
        sumsObject[target.pr] = { target: 0, ok: 0, ng: 0, work_minute: 0 };
        sumsObject[target.dt] = { time: 0 };
        sumsObject[target.qw] = { ok: 0, ng: 0 };
      });

      // 설비 추가시 여기에 Data 추가해야함
      for (const row of tr3smt_item.value) {
        const targetKey = 'tr3smtpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr3smt_dtItem.value) {
        const targetKey = 'tr3smtdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr3smt_qwItem.value) {
        const targetKey = 'tr3smtqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr3crimping_item.value) {
        const targetKey = 'tr3crimpingpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr3crimping_dtItem.value) {
        const targetKey = 'tr3crimpingdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr3crimping_qwItem.value) {
        const targetKey = 'tr3crimpingqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr3fpca_item.value) {
        const targetKey = 'tr3fpcapr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr3fpca_dtItem.value) {
        const targetKey = 'tr3fpcadt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr3fpca_qwItem.value) {
        const targetKey = 'tr3fpcaqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr3bfa2p_item.value) {
        const targetKey = 'tr3bfa2ppr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr3bfa2p_dtItem.value) {
        const targetKey = 'tr3bfa2pdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr3bfa2p_qwItem.value) {
        const targetKey = 'tr3bfa2pqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr3bfa3p_item.value) {
        const targetKey = 'tr3bfa3ppr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr3bfa3p_dtItem.value) {
        const targetKey = 'tr3bfa3pdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr3bfa3p_qwItem.value) {
        const targetKey = 'tr3bfa3pqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of evo_item.value) {
        const targetKey = 'evopr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of evo_dtItem.value) {
        const targetKey = 'evodt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of evo_qwItem.value) {
        const targetKey = 'evoqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ev2020_60fr_item.value) {
        const targetKey = 'ev2020_60frpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ev2020_60fr_dtItem.value) {
        const targetKey = 'ev2020_60frdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ev2020_60fr_qwItem.value) {
        const targetKey = 'ev2020_60frqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ev2020_60rr_item.value) {
        const targetKey = 'ev2020_60rrpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ev2020_60rr_dtItem.value) {
        const targetKey = 'ev2020_60rrdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ev2020_60rr_qwItem.value) {
        const targetKey = 'ev2020_60rrqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ev2020_4060fr_item.value) {
        const targetKey = 'ev2020_4060frpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ev2020_4060fr_dtItem.value) {
        const targetKey = 'ev2020_4060frdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ev2020_4060fr_qwItem.value) {
        const targetKey = 'ev2020_4060frqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ev2020_4060rr_item.value) {
        const targetKey = 'ev2020_4060rrpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ev2020_4060rr_dtItem.value) {
        const targetKey = 'ev2020_4060rrdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ev2020_4060rr_qwItem.value) {
        const targetKey = 'ev2020_4060rrqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr2smt_item.value) {
        const targetKey = 'tr2smtpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr2smt_dtItem.value) {
        const targetKey = 'tr2smtdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr2smt_qwItem.value) {
        const targetKey = 'tr2smtqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr2crimping_item.value) {
        const targetKey = 'tr2crimpingpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr2crimping_dtItem.value) {
        const targetKey = 'tr2crimpingdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr2crimping_qwItem.value) {
        const targetKey = 'tr2crimpingqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr2fpca_item.value) {
        const targetKey = 'tr2fpcapr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr2fpca_dtItem.value) {
        const targetKey = 'tr2fpcadt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr2fpca_qwItem.value) {
        const targetKey = 'tr2fpcaqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr2bfa2p_item.value) {
        const targetKey = 'tr2bfa2ppr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr2bfa2p_dtItem.value) {
        const targetKey = 'tr2bfa2pdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr2bfa2p_qwItem.value) {
        const targetKey = 'tr2bfa2pqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of tr2bfa3p_item.value) {
        const targetKey = 'tr2bfa3ppr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of tr2bfa3p_dtItem.value) {
        const targetKey = 'tr2bfa3pdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of tr2bfa3p_qwItem.value) {
        const targetKey = 'tr2bfa3pqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ford1fr_item.value) {
        const targetKey = 'ford1frpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ford1fr_dtItem.value) {
        const targetKey = 'ford1frdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ford1fr_qwItem.value) {
        const targetKey = 'ford1frqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ford1rr_item.value) {
        const targetKey = 'ford1rrpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ford1rr_dtItem.value) {
        const targetKey = 'ford1rrdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ford1rr_qwItem.value) {
        const targetKey = 'ford1rrqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ford2fr_item.value) {
        const targetKey = 'ford2frpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ford2fr_dtItem.value) {
        const targetKey = 'ford2frdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ford2fr_qwItem.value) {
        const targetKey = 'ford2frqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ford2rr_item.value) {
        const targetKey = 'ford2rrpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ford2rr_dtItem.value) {
        const targetKey = 'ford2rrdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ford2rr_qwItem.value) {
        const targetKey = 'ford2rrqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ford3fr_item.value) {
        const targetKey = 'ford3frpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ford3fr_dtItem.value) {
        const targetKey = 'ford3frdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ford3fr_qwItem.value) {
        const targetKey = 'ford3frqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of ford3rr_item.value) {
        const targetKey = 'ford3rrpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of ford3rr_dtItem.value) {
        const targetKey = 'ford3rrdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of ford3rr_qwItem.value) {
        const targetKey = 'ford3rrqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of fca1_item.value) {
        const targetKey = 'fca1pr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of fca1_dtItem.value) {
        const targetKey = 'fca1dt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of fca1_qwItem.value) {
        const targetKey = 'fca1qw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of fca2_item.value) {
        const targetKey = 'fca2pr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of fca2_dtItem.value) {
        const targetKey = 'fca2dt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of fca2_qwItem.value) {
        const targetKey = 'fca2qw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of bt6_item.value) {
        const targetKey = 'bt6pr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of bt6_dtItem.value) {
        const targetKey = 'bt6dt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of bt6_qwItem.value) {
        const targetKey = 'bt6qw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of bmw12v_item.value) {
        const targetKey = 'bmw12vpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of bmw12v_dtItem.value) {
        const targetKey = 'bmw12vdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of bmw12v_qwItem.value) {
        const targetKey = 'bmw12vqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of bmw48v_item.value) {
        const targetKey = 'bmw48vpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of bmw48v_dtItem.value) {
        const targetKey = 'bmw48vdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of bmw48v_qwItem.value) {
        const targetKey = 'bmw48vqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of fca_flex_bc_item.value) {
        const targetKey = 'fca_flex_bcpr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of fca_flex_bc_dtItem.value) {
        const targetKey = 'fca_flex_bcdt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of fca_flex_bc_qwItem.value) {
        const targetKey = 'fca_flex_bcqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of fca_flex_po_item.value) {
        const targetKey = 'fca_flex_popr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of fca_flex_po_dtItem.value) {
        const targetKey = 'fca_flex_podt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of fca_flex_po_qwItem.value) {
        const targetKey = 'fca_flex_poqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of fca_flex_67_item.value) {
        const targetKey = 'fca_flex_67pr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of fca_flex_67_dtItem.value) {
        const targetKey = 'fca_flex_67dt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of fca_flex_67_qwItem.value) {
        const targetKey = 'fca_flex_67qw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of fca_flex_ne_item.value) {
        const targetKey = 'fca_flex_nepr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of fca_flex_ne_dtItem.value) {
        const targetKey = 'fca_flex_nedt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of fca_flex_ne_qwItem.value) {
        const targetKey = 'fca_flex_neqw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }
      for (const row of fca_flex_45_item.value) {
        const targetKey = 'fca_flex_45pr';
        sumsObject[targetKey].target += row.target;
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
        sumsObject[targetKey].work_minute += row.work_minute;
      }
      for (const row of fca_flex_45_dtItem.value) {
        const targetKey = 'fca_flex_45dt';
        sumsObject[targetKey].time += row.time;
      }
      for (const row of fca_flex_45_qwItem.value) {
        const targetKey = 'fca_flex_45qw';
        sumsObject[targetKey].ok += row.ok;
        sumsObject[targetKey].ng += row.ng;
      }

      return sumsObject;
    });


    const targetSearchData= async () => {         

      const formData = {
        tableName: targetTableName
      };

      console.log('Requesting data with:', formData);

      axios.get('/api/targetList', { params: formData })
        .then((res) => {
          console.log("Response from /api/target:", res.data);
          targetList.data = res.data
        })
        .catch((error) => {
          console.error("Error fetching target data:", error.message);
        });
      };

    const callTarget = (lineName) => {return targetItem.value[lineName] || {};};

    const aS = (value) => {
      if (isNaN(value)) {
          return { backgroundColor: 'transparent' }; // Transparent color
      } else if (value < 0.8499) {
          return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // Light red
      } else if (value < 0.9499) {
          return { backgroundColor: 'rgba(255, 255, 0, 0.3)' }; // Light yellow
      } else {
          return { backgroundColor: 'rgba(144, 238, 144, 0.3)' }; // Light green
      }
    };

    // 설비 추가시 여기에 Data 추가해야함
    const targets = reactive([  
          { name: "TR2 SMT", pr: "tr2smtpr", dt: "tr2smtdt", qw: "tr2smtqw" },
          { name: "TR2 Crimping", pr: "tr2crimpingpr", dt: "tr2crimpingdt", qw: "tr2crimpingqw" },
          { name: "TR2 FPCA", pr: "tr2fpcapr", dt: "tr2fpcadt", qw: "tr2fpcaqw" },
          { name: "TR2 BFA 3P", pr: "tr2bfa3ppr", dt: "tr2bfa3pdt", qw: "tr2bfa3pqw" },               
          { name: "TR3 SMT", pr: "tr3smtpr", dt: "tr3smtdt", qw: "tr3smtqw" },
          { name: "TR3 Crimping", pr: "tr3crimpingpr", dt: "tr3crimpingdt", qw: "tr3crimpingqw" },
          { name: "TR3 FPCA", pr: "tr3fpcapr", dt: "tr3fpcadt", qw: "tr3fpcaqw" },
          { name: "EVO 1", pr: "tr3bfa2ppr", dt: "tr3bfa2pdt", qw: "tr3bfa2pqw" },
          { name: "EVO 2", pr: "tr3bfa3ppr", dt: "tr3bfa3pdt", qw: "tr3bfa3pqw" },    
          { name: "EVO 3", pr: "tr2bfa2ppr", dt: "tr2bfa2pdt", qw: "tr2bfa2pqw" },                      
          { name: "EVO Hybrid", pr: "evopr", dt: "evodt", qw: "evoqw" },     
          { name: "EV2020 60FR", pr: "ev2020_60frpr", dt: "ev2020_60frdt", qw: "ev2020_60frqw" },     
          { name: "EV2020 60RR", pr: "ev2020_60rrpr", dt: "ev2020_60rrdt", qw: "ev2020_60rrqw" },     
          { name: "EV2020 4060FR", pr: "ev2020_4060frpr", dt: "ev2020_4060frdt", qw: "ev2020_4060frqw" },     
          { name: "EV2020 4060RR", pr: "ev2020_4060rrpr", dt: "ev2020_4060rrdt", qw: "ev2020_4060rrqw" },     
          { name: "Ford 1 Front", pr: "ford1frpr", dt: "ford1frdt", qw: "ford1frqw" },     
          { name: "Ford 1 Rear", pr: "ford1rrpr", dt: "ford1rrdt", qw: "ford1rrqw" },     
          { name: "Ford 2 Front", pr: "ford2frpr", dt: "ford2frdt", qw: "ford2frqw" },     
          { name: "Ford 2 Rear", pr: "ford2rrpr", dt: "ford2rrdt", qw: "ford2rrqw" },     
          { name: "Ford 3 Front", pr: "ford3frpr", dt: "ford3frdt", qw: "ford3frqw" },     
          { name: "Ford 3 Rear", pr: "ford3rrpr", dt: "ford3rrdt", qw: "ford3rrqw" },     
          { name: "FCA 1", pr: "fca1pr", dt: "fca1dt", qw: "fca1qw" },    
          { name: "FCA 2", pr: "fca2pr", dt: "fca2dt", qw: "fca2qw" },    
          { name: "BT6", pr: "bt6pr", dt: "bt6dt", qw: "bt6qw" },    
          { name: "BMW12V", pr: "bmw12vpr", dt: "bmw12vdt", qw: "bmw12vqw" },    
          { name: "BMW48V", pr: "bmw48vpr", dt: "bmw48vdt", qw: "bmw48vqw" },    
          { name: "FCA FLEX BC", pr: "fca_flex_bcpr", dt: "fca_flex_bcdt", qw: "fca_flex_bcqw" },
          { name: "FCA FLEX PO", pr: "fca_flex_popr", dt: "fca_flex_podt", qw: "fca_flex_poqw" },
          { name: "FCA FLEX 67", pr: "fca_flex_67pr", dt: "fca_flex_67dt", qw: "fca_flex_67qw" },
          { name: "FCA FLEX NE", pr: "fca_flex_nepr", dt: "fca_flex_nedt", qw: "fca_flex_neqw" },
          { name: "FCA FLEX 45", pr: "fca_flex_45pr", dt: "fca_flex_45dt", qw: "fca_flex_45qw" },          
    ]);    

    const getTableRowData = (targetName) => {
      const target = targets.find((t) => t.name === targetName);      
    
      if (!target) { return {}; }
      
      const sumsObject = sums.value;

      const prData = sumsObject[target.pr] || {};
      const dtData = sumsObject[target.dt] || {};
      const qwData = sumsObject[target.qw] || {};   
      
      const targetData = callTarget(targetName);
      const okTarget = prData.target;
      const ok = prData.ok - qwData.ng;
      const achieve = ((ok / prData.target) * 100).toFixed(1);      
      const ngTarget = (targetData.ngrate * 100).toFixed(1);
      const ng = prData.ng + qwData.ng;
      const ngRate = ((ng / (ok + ng)) * 100).toFixed(1);
      const achieveNG = ((targetData.ngrate / ngRate) * 10000).toFixed(1);
      const tactTimeTarget = targetData.real_tacttime;
      const actualTactTime = (((prData.work_minute - dtData.time) * 60) / prData.ok).toFixed(1);
      const achieveTactTime = ((tactTimeTarget / actualTactTime) * 100).toFixed(1);
      const downTimeTarget = (targetData.downtimerate * prData.work_minute).toFixed(1);
      const downTime = dtData.time;
      const achieveDownTime = ((downTimeTarget / downTime) * 100).toFixed(1);      
      const oeeAvailable = (((prData.work_minute - dtData.time) / prData.work_minute) * 100).toFixed(1);
      const oeeEfficiency = ((tactTimeTarget / actualTactTime) * 100).toFixed(1);
      const oeeQuality = ((ok / (prData.ok + prData.ng)) * 100).toFixed(1);
      const oee = ((oeeAvailable * oeeEfficiency * oeeQuality)/10000).toFixed(1);
      const workMinute = prData.work_minute;
      const uph = (ok/(prData.work_minute/60)).toFixed(0);

      return {
             okTarget, ok, achieve, ngTarget, ng, ngRate, achieveNG, tactTimeTarget, actualTactTime,
             achieveTactTime, downTimeTarget, downTime, achieveDownTime, oee, oeeAvailable, oeeEfficiency,
             oeeQuality, workMinute, uph,
      };


    };   
   
    const rowData = computed(() => { return targets.map((target) => getTableRowData(target.name)); });

    return {
      headers, aS, startYear, startMonth, startDay, endYear, endMonth, endDay,
      targetItem, targetList, targetTableName, callTarget, targets, getTableRowData,
      searchData, callSearchData, sendDate, sums, rowData, 
    };
  },
};
</script>

<style scoped>
   * {
    box-sizing: border-box;
  }

  .container {
    margin-top: 0px;
    padding-top: 0px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .search {
  position: relative;
  background-color: #ffffff; /* Set a background color for better visibility */  
  padding: 0px; /* Add some padding for better appearance */
  height: auto;
  width: 800px;
}
  .start,
  .end {
    display: flex;
    flex-direction: row;    
    border: 0px;
  }

  .start input,
  .end input {
    margin-right: 5px;
  }

  .separator {
    font-size: 24px;
    margin: 0 10px;
    border: 0px;
  }
  .searchTd {    
    border: 0px;
  }

  input::placeholder {
    color: gray;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100px;
  }

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 8px;
    font-size: 12px;    
  }

th {
    position: sticky;
    top: 0;    
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;        
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #e6e6e6;
  }
  .ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}
.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.okhead {
  background-color: #8dc7fd; /* Adjust the color as needed */
}
.nghead {
  background-color: #fdb08d; /* Adjust the color as needed */
} 
.tacttimehead {
  background-color: #fac069; /* Adjust the color as needed */
} 
.downtimehead {
  background-color: #569e56; /* Adjust the color as needed */
} 
.oeehead {
  background-color: #bbbd4a; /* Adjust the color as needed */
} 
</style>