<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Calibration Add</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">                        
            <table>
              <tr>
                <td>
                  <div class="form-group">
                    <label for="line">Line:</label>
                    <select type="text" id="line" v-model="formInputs.line">
                      <option v-for="option in lineOptions" :key="option">{{ option }}</option>
                    </select>
                  </div>                  
                  <div class="form-group">
                    <label for="sation">Station:</label>
                    <input type="text" id="station" v-model="formInputs.station" />
                  </div>
                  <div class="form-group">
                    <label for="type">Type:</label>
                    <select type="text" id="type" v-model="formInputs.type">
                      <option>Master Sample</option><option>Device</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" id="name" v-model="formInputs.name" />
                  </div>
                  <div class="form-group">
                    <label for="target">Measurement Target:</label>
                    <input type="text" id="target" v-model="formInputs.target" />
                  </div>
                </td>
                <td>
                  <div class="form-group date-time-group">
                      <label>ISSUED Year/Month/Day:</label>
                      <div class="date-time-inputs">
                        <input type="text" id="is_year" v-model="formInputs.is_year"  />
                        <input type="text" id="is_month" v-model="formInputs.is_month" />
                        <input type="text" id="is_day" v-model="formInputs.is_day" />                  
                      </div> 
                  </div> 
                  <div class="form-group date-time-group">
                      <label>EXPIRE Year/Month/Day:</label>
                      <div class="date-time-inputs">
                        <input type="text" id="ex_year" v-model="formInputs.ex_year"  />
                        <input type="text" id="ex_month" v-model="formInputs.ex_month" />
                        <input type="text" id="ex_day" v-model="formInputs.ex_day" />                  
                      </div> 
                  </div> 
                  <div class="form-group">
                    <label for="registraion">Registraion Number:</label>
                    <input type="text" id="registraion" v-model="formInputs.registraion" />
                  </div>  
                  <div class="form-group">
                    <label for="manage">Manage:</label>
                    <select type="text" id="manage" v-model="formInputs.manage">
                      <option>yes</option><option>no</option>
                    </select>
                  </div>    
                  <div class="form-group">
                    <label for="remark">Remark:</label>
                    <input type="text" id="remark" v-model="formInputs.remark" />
                  </div>  
                  <div class="form-group">
                    <label for="row_no">Row no:</label>
                    <input type="text" id="row_no" v-model="formInputs.row_no" />                
                  </div>  
                </td>
              </tr>
            </table>
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "calibrationdate"                 

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          line: "",  station: "", type: "", name: "", target: "", is_year: "", is_month: "", is_day: "",
          ex_year: "", ex_month: "", ex_day: "", registraion: "", remark: "", row_no: "",
        });
        // -끝-   
        const lineOptions = ref(['BMW48V', 'BMW12V', 'BT6', 'FCA 1', 'FCA 2', 'FCA FLEX', 
                                 'Ford 1 Front', 'Ford 1 Rear', 'Ford 2 Front', 'Ford 2 Rear', 'Ford 3 Front', 'Ford 3 Rear', 
                                 'TR2 SMT', 'TR2 Crimping', 'TR2 FPCA', 'TR2 BFA 2P', 'TR2 BFA 3P',                                  
                                 'TR3 SMT', 'TR3 Crimping', 'TR3 FPCA', 'EVO 1', 'EVO 2', 'EVO Hybrid',
                                 'EV2020 60', 'EV2020 4060', 'LQC', "IQC", 'Laboratory'
                                ]);   

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!formInputs.line || !formInputs.station || !formInputs.type ) {
            alert("Please fill in all the fields: line, station and type .");
            return;
          }

          const columnMapping = {
            line: 'line',  station: 'station', type: 'type', name: 'name', target: 'target', 
            is_year: 'is_year', is_month: 'is_month', is_day: 'is_day', 
            ex_year: 'ex_year', ex_month: 'ex_month', ex_day: 'ex_day', registraion: "registraion", manage: 'manage', remark: 'remark', row_no: 'row_no',
          };

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              line : formInputs.line,
              station : formInputs.station,
              type : formInputs.type,
              name : formInputs.name,
              target : formInputs.target,
              is_year : formInputs.is_year,
              is_month : formInputs.is_month,
              is_day : formInputs.is_day,
              ex_year : formInputs.ex_year,
              ex_month : formInputs.ex_month,
              ex_day : formInputs.ex_day,
              registraion : formInputs.registraion,
              manage : formInputs.manage,
              remark : formInputs.remark,
              row_no : formInputs.row_no,
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    

      return { dbTableName, formInputs, submitForm, closeModal, lineOptions};

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 


};
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }       
    .modal-wrapper { width: 100%;  max-width: 700px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>