<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>New Spare</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>
            <td>     
            <div class="form-group">
              <label for="evpcode">EVP Code:</label>
              <div>
                <input type="text" id="evpcode" v-model="formInputs.evpcode" />
              </div>
            </div>  
            <div class="form-group">
              <label for="name">Item Name:</label>
              <div>
                <input type="text" id="name" v-model="formInputs.name" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="specification">Specification:</label>
              <div>
                <input type="text" id="specification" v-model="formInputs.specification" />                  
              </div>
            </div>  
            <div class="form-group">
              <label for="maker">Maker:</label>
              <div>
                <input type="text" id="maker" v-model="formInputs.maker" />
              </div>
            </div>  
            </td>
            <td>
            <div class="form-group">
              <label for="qty">Quantity:</label>
              <div>
                <input type="number" id="qty" v-model="formInputs.qty" />                  
              </div>
            </div>  
            <div class="form-group">
              <label for="limitqty">Limit Quantity:</label>
              <div>
                <input type="number" id="limitqty" v-model="formInputs.limitqty" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="factory">Factory:</label>
              <div>
                <input class="radio" type="radio" id="1st" value="1" v-model="formInputs.factory">
                <label for="1st">1st</label>
                <input class="radio"  type="radio" id="2nd" value="2" v-model="formInputs.factory">
                <label for="2nd">2nd</label>
              </div>
            </div>   
            <div class="form-group">
              <label for="rack">Rack Number:</label>
              <div>
                <input type="text" id="rack" v-model="formInputs.rack" />         
              </div>
            </div> 
            <div class="form-group">
              <label for="row">P (Row):</label>
              <div>
                <input type="text" id="row" v-model="formInputs.row_rack" />         
              </div>
            </div> 
            <div class="form-group">
              <label for="col">K (Col):</label>
              <div>
                <input type="text" id="col" v-model="formInputs.col" />         
              </div>
            </div>    
            </td>
            <td>
              <div class="form-group">
              <label for="line">Line:</label>
              <div>
                <select type="text" id="line" v-model="formInputs.line">
                  <option>SMT</option><option>Crimping</option><option>FPCA</option>
                  <option>EVO</option><option>EV2020</option>
                  <option>Ford</option><option>BMW 48V</option><option>BMW 12V</option>
                  <option></option>                  
                </select>
              </div>
            </div> 
            <div class="form-group">
              <label for="costscore">Cost Score (1~5):</label>
              <div>
                <input type="number" id="costscore" v-model="formInputs.costscore" />                  
              </div>
            </div>  
            <div class="form-group">
              <label for="deliveryscore">Delivery Score (1~5):</label>
              <div>
                <input type="number" id="deliveryscore" v-model="formInputs.deliveryscore" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="stopmachinescore">Stop Machine Score (1~5):</label>
              <div>
                <input type="number" id="stopmachinescore" v-model="formInputs.stopmachinescore" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="cost">Unit Cost(PLN):</label>
              <div>
                <input type="text" id="cost" v-model="formInputs.cost" />                  
              </div>
            </div>   
            <div class="form-group">
              <label for="code">Part Code(base on supplier):</label>
              <div>
                <input type="text" id="code" v-model="formInputs.code" />                  
              </div>
            </div>               
            <div class="form-group">
              <label for="remark">Remark:</label>
              <div>
                <input type="text" id="remark" v-model="formInputs.remark" />
              </div>
            </div>   
            <div class="form-group">
              <label for="rowno">Row Number:</label>
              <div>
                <input type="number" id="rowno" v-model="formInputs.row_no" />
              </div>
            </div>              
          </td>
            </tr>
            </table>       
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, ref } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        
        const dbTableName = "sparelist"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);             

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          evpcode: "", name: "", specification: "", maker: "", qty: "0", factory: "", rack: "", row_rack: "", col: "", line: "",
          limitqty: "0", costscore: "0", deliveryscore: "0", stopmachinescore: "0", cost: "", code: "", remark: "", row_no: ""
        });
        // -끝-   

        const submitForm = async () => {          

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {
            evpcode: 'evpcode',
            name: 'name',            
            specification: 'specification',
            maker: 'maker',
            qty: 'qty',
            factory: 'factory',
            rack: 'rack',
            row_rack: 'row_rack',
            col: 'col',
            line: 'line',
            limitqty: 'limitqty',
            costscore: 'costscore',
            deliveryscore: 'deliveryscore',
            stopmachinescore: 'stopmachinescore',
            cost: 'cost',
            code: 'code',            
            remark: 'remark', 
            row_no: 'row_no'
          };

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              evpcode: formInputs.evpcode,
              name: formInputs.name,            
              specification: formInputs.specification,
              maker: formInputs.maker,
              qty: formInputs.qty,
              factory: formInputs.factory,
              rack: formInputs.rack,
              row_rack: formInputs.row_rack,
              col: formInputs.col,
              line: formInputs.line,
              limitqty: formInputs.limitqty,
              costscore: formInputs.costscore,
              deliveryscore: formInputs.deliveryscore,
              stopmachinescore: formInputs.stopmachinescore,
              cost: formInputs.cost,
              code: formInputs.code,
              remark: formInputs.remark, 
              row_no: formInputs.row_no
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    

      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing };

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 

};
  
  </script>
    
  <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 800px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }  
    .radio { margin-left: 10px; margin-right: 5px; }
    .disabled { background-color: #ccc; cursor: not-allowed; }
</style>