<template>
    <div>
      <h2>Upload Excel File</h2>
      <div class="excelFile">
        <label for="file-input" class="custom-file-upload">Choose file</label>                
        <input id="file-input" type="file" @change="onFileChange" accept=".xlsx, .xls" style="display: none" />
        <span class="fileName">{{ fileName }}</span>
      </div>
      <table>
        <thead>
          <tr>
            <th>Line</th><th>Production Result</th>
            <th>Daily report</th><th>Downtime</th><th>Q-Wall</th>
          </tr>
        </thead>
        <tbody>  <!--Excel File 업로드시에는 ID 부분은 삭제하고 올려도 됨 -->
          <tr>
            <td>FORD #1 FRONT</td>                                        
            <td><button @click="uploadExcel('ford1frpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ford1frdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ford1frdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ford1frqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FORD #1 REAR</td>                                        
            <td><button @click="uploadExcel('ford1rrpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ford1rrdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ford1rrdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ford1rrqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FORD #2 FRONT</td>                                        
            <td><button @click="uploadExcel('ford2frpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ford2frdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ford2frdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ford2frqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FORD #2 REAR</td>                                        
            <td><button @click="uploadExcel('ford2rrpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ford2rrdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ford2rrdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ford2rrqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FORD #3 FRONT</td>                                        
            <td><button @click="uploadExcel('ford3frpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ford3frdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ford3frdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ford3frqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FORD #3 REAR</td>                                        
            <td><button @click="uploadExcel('ford3rrpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ford3rrdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ford3rrdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ford3rrqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FCA #1</td>                                        
            <td><button @click="uploadExcel('fca1pr')">PR Upload</button></td>
            <td><button @click="uploadExcel('fca1dr')">DR Upload</button></td>
            <td><button @click="uploadExcel('fca1dt')">DT Upload</button></td>
            <td><button @click="uploadExcel('fca1qw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FCA #2</td>                                        
            <td><button @click="uploadExcel('fca2pr')">PR Upload</button></td>
            <td><button @click="uploadExcel('fca2dr')">DR Upload</button></td>
            <td><button @click="uploadExcel('fca2dt')">DT Upload</button></td>
            <td><button @click="uploadExcel('fca2qw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>BT6</td>                                        
            <td><button @click="uploadExcel('bt6pr')">PR Upload</button></td>
            <td><button @click="uploadExcel('bt6dr')">DR Upload</button></td>
            <td><button @click="uploadExcel('bt6dt')">DT Upload</button></td>
            <td><button @click="uploadExcel('bt6qw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>BMW 12V</td>                                        
            <td><button @click="uploadExcel('bmw12vpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('bmw12vdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('bmw12vdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('bmw12vqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>BMW 48V</td>                                        
            <td><button @click="uploadExcel('bmw48vpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('bmw48vdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('bmw48vdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('bmw48vqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FCA FLEXIBLE Busbar Carrier</td>                                        
            <td><button @click="uploadExcel('fca_flex_bcpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_bcdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_bcdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_bcqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FCA FLEXIBLE Positive</td>                                        
            <td><button @click="uploadExcel('fca_flex_popr')">PR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_podr')">DR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_podt')">DT Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_poqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FCA FLEXIBLE 6+7-</td>                                        
            <td><button @click="uploadExcel('fca_flex_67pr')">PR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_67dr')">DR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_67dt')">DT Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_67qw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FCA FLEXIBLE Negative</td>                                        
            <td><button @click="uploadExcel('fca_flex_nepr')">PR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_nedr')">DR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_nedt')">DT Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_neqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>FCA FLEXIBLE 4-5+</td>                                        
            <td><button @click="uploadExcel('fca_flex_45pr')">PR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_45dr')">DR Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_45dt')">DT Upload</button></td>
            <td><button @click="uploadExcel('fca_flex_45qw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>TR2 SMT</td>                                        
            <td><button @click="uploadExcel('tr2smtpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr2smtdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr2smtdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr2smtqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>TR2 Crimping</td>                           
            <td><button @click="uploadExcel('tr2crimpingpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr2crimpingdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr2crimpingdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr2crimpingqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>TR2 FPCA</td>                                       
            <td><button @click="uploadExcel('tr2fpcapr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr2fpcadr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr2fpcadt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr2fpcaqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>T23 BFA 2P</td>                                         
            <td><button @click="uploadExcel('tr2bfa2ppr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr2bfa2pdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr2bfa2pdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr2bfa2pqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>TR2 BFA 3P</td>                                         
            <td><button @click="uploadExcel('tr2bfa3ppr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr2bfa3pdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr2bfa3pdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr2bfa3pqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>TR3 SMT</td>                                        
            <td><button @click="uploadExcel('tr3smtpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr3smtdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr3smtdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr3smtqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>TR3 Crimping</td>                           
            <td><button @click="uploadExcel('tr3crimpingpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr3crimpingdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr3crimpingdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr3crimpingqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>TR3 FPCA</td>                                       
            <td><button @click="uploadExcel('tr3fpcapr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr3fpcadr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr3fpcadt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr3fpcaqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>EVO 1</td>                                         
            <td><button @click="uploadExcel('tr3bfa2ppr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr3bfa2pdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr3bfa2pdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr3bfa2pqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>EVO 2</td>                                         
            <td><button @click="uploadExcel('tr3bfa3ppr')">PR Upload</button></td>
            <td><button @click="uploadExcel('tr3bfa3pdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('tr3bfa3pdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('tr3bfa3pqw')">QW Upload</button></td>
          </tr>
          <tr>            
            <td>EVO Hybrid</td>                                       
            <td><button @click="uploadExcel('evopr')">PR Upload</button></td>
            <td><button @click="uploadExcel('evodr')">DR Upload</button></td>
            <td><button @click="uploadExcel('evodt')">DT Upload</button></td>
            <td><button @click="uploadExcel('evoqw')">QW Upload</button></td>
          </tr>
          <tr>            
            <td>Thermistor Tape</td>                                       
            <td><button @click="uploadExcel('thermistortapepr')">PR Upload</button></td>
            <td><button @click="uploadExcel('thermistortapedr')">DR Upload</button></td>
            <td><button @click="uploadExcel('thermistortapedt')">DT Upload</button></td>
            <td></td>
          </tr>
          <tr>            
            <td>Thermistor Pad</td>                                       
            <td><button @click="uploadExcel('thermistorpadpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('thermistorpaddr')">DR Upload</button></td>
            <td><button @click="uploadExcel('thermistorpaddt')">DT Upload</button></td>
            <td></td>
          </tr>
          <tr>
            <td>EV2020 40/60kW Front</td>                                         
            <td><button @click="uploadExcel('ev2020_4060frpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_4060frdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_4060frdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ev2020_4060frqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>EV2020 40/60kW Rear</td>                                        
            <td><button @click="uploadExcel('ev2020_4060rrpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_4060rrdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_4060rrdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ev2020_4060rrqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>EV2020 60kW Front</td>                                     
            <td><button @click="uploadExcel('ev2020_60frpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_60frdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_60frdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ev2020_60frqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>EV2020 60kW Rear</td>                                        
            <td><button @click="uploadExcel('ev2020_60rrpr')">PR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_60rrdr')">DR Upload</button></td>
            <td><button @click="uploadExcel('ev2020_60rrdt')">DT Upload</button></td>
            <td><button @click="uploadExcel('ev2020_60rrqw')">QW Upload</button></td>
          </tr>
          <tr>
            <td>Stock</td>                                        
            <td><button @click="uploadExcel('stock')">Stock Upload</button></td>            
          </tr>
          <tr>
            <td>Parameter</td>                                        
            <td><button @click="uploadExcel('paraeolconnectorpin')">EOL PIN Upload</button></td>            
          </tr>
          <tr>
            <td>Attendance</td>                                        
            <td><button @click="uploadExcel('attendance')">Attendance Upload</button></td>            
          </tr>
          <tr>
            <td>Employee</td>                                        
            <td><button @click="uploadExcel('employeedata')">Employee Upload</button></td>            
          </tr>
          <tr>
            <td>Purchase List</td>                                        
            <td><button @click="uploadExcel('purchaselist')">Purchase List</button></td>            
          </tr>
          <tr>
            <td>Spare List</td>                                        
            <td><button @click="uploadExcel('sparelist')">Spare List</button></td>            
          </tr>
          <tr>
            <td>Annual Maintenance</td>                                        
            <td><button @click="uploadExcel('maintenanceannual')">Annual Maintenance</button></td>            
          </tr>  
          <tr>
            <td>Corrective Action</td>                                        
            <td><button @click="uploadExcel('correctiveaction')">Corrective Action</button></td>            
          </tr>          
          <tr>
            <td>Equipment Ledger</td>                                        
            <td><button @click="uploadExcel('equipmentledger')">Equipment Ledger</button></td>            
          </tr>    
          <tr>
            <td>Production Spare</td>                                        
            <td><button @click="uploadExcel('productionspare')">Production Spare</button></td>            
          </tr>
           <tr>
            <td>Purchase List</td>                                        
            <td><button @click="uploadExcel('purchaselist')">Purchase List</button></td>            
          </tr>  
           <tr>
            <td>Purchase Request</td>                                        
            <td><button @click="uploadExcel('purchaserequest')">Purchase Request</button></td>            
          </tr>  
           <tr>
            <td>To Do List</td>                                        
            <td><button @click="uploadExcel('todolist')">To Do List</button></td>            
          </tr>        
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import readXlsxFile from 'read-excel-file';
  import axios from 'axios';
  
  export default {
    data() {        
        return {
        excelFile: null,
        fileName: "",
        };
    },
    methods: {
        onFileChange(event) {
        this.excelFile = event.target.files ? event.target.files[0] : null;
        this.fileName = this.excelFile ? this.excelFile.name : "";
        },
        async uploadExcel(tableName) {
        if (!this.excelFile) {
            alert('Please select an Excel file.');
            return;
        }

        try {
            const rows = await readXlsxFile(this.excelFile);
            const columns = rows[0];
            const data = rows.slice(1);

            console.log('column :', columns.join(', '));
            data.forEach((row, index) => {
            console.log(`value row${index + 1} :`, row.join(', '));
            });

            console.log('Sending data to API:', { data: rows, tableName: tableName });
            const response = await axios.post('/api/upload-excel', {
            data: rows,
            tableName: tableName,
            });

            console.log(response);
            alert('File uploaded successfully.');
        } catch (error) {
            console.error(error);
            alert('Error uploading the file.');
        }
        },
    },
    };
  </script>

<style scoped>
  h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  .excelFile {
    margin-left:150px;
    margin-bottom:10px;
  }

  .fileName {
    margin-left:10px;
  }

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  td {
    border: 1px solid black;
    text-align: center;
    padding: 10px;
    font-size: 12px;
  }

  th {
    position: sticky;
    top: 0;    
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;        
    padding: 10px;
    font-size : 12px; 
    font-weight: 600;
}

  table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
  }

  .custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #347003;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.custom-file-upload:hover {
  background-color: #009625;
}
</style>