<template>
    <div class="scrapPost">
      <div class="search">      
        <label>Year/Month/Day:</label>        
        <div class="date-time-inputs">          
          <input type="text" id="year" v-model="searchDate.year"  />
          <input type="text" id="month" v-model="searchDate.month" />
          <input type="text" id="day" v-model="searchDate.day" />               
          {{ fileName }}
          <label for="file-input" class="custom-file-upload">Choose file</label>
          <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
          <button class="listuploadbutton" @click.prevent="upExcel('scrapmateriallog')">Material Scrap</button>                
        </div>
        <br>
        <h class="pagetitle">Scrap Post</h>        
        <form>
          <table class="postTable">
            <tr>
              <td class="postTd">
                <label for="productcode">Product Code:</label>
                <input type="text" id="productcode" v-model="formInputs.productcode" />
              </td>
              <td class="postTd">
                <label for="quantity">Quantity:</label>
                <input type="text" id="quantity" v-model="formInputs.proquantity"  />
              </td>
              <td class="postTd">
                <label for="unit">Unit:</label>
                <select type="text" id="unit" v-model="formInputs.unit">
                  <option>EA</option><option>gram</option><option>ROL</option>
                </select>
              </td>
              <td class="postTd">
                <label for="factory">Factory:</label>
                <select type="text" id="factory" v-model="formInputs.factory">
                  <option>1</option><option>2</option>
                </select>
              </td>
              <td class="postTd">
                <label for="workorder">Work Order:</label>
                <input type="text" id="workorder" v-model="formInputs.workorder"/>
              </td>
              <td class="postTd">
                <label for="remark">Remark:</label>
                <input type="text" id="remark" v-model="formInputs.remark"/>
              </td>
              <td class="postTd">
                <button type="submit" @click.prevent="proSubmit">Submit</button>                
              </td>
              </tr>
              <tr>
              <td class="postTd">
                <label for="materialcode">Material Code:</label>
                <input type="text" id="materialcode" v-model="formInputs.materialcode" />
              </td>
              <td class="postTd">
                <label for="quantity">Quantity:</label>
                <input type="text" id="quantity" v-model="formInputs.maquantity"  />
              </td>
              <td class="postTd">
                <label for="unit">Unit:</label>
                <select type="text" id="unit" v-model="formInputs.unit">
                  <option>EA</option><option>gram</option><option>ROL</option>
                </select>
              </td>
              <td class="postTd">
                <label for="factory">Factory:</label>
                <select type="text" id="factory" v-model="formInputs.factory">
                  <option>1</option><option>2</option>
                </select>
              </td>
              <td class="postTd">
                <label for="workorder">Work Order:</label>
                <input type="text" id="workorder" v-model="formInputs.workorder"/>
              </td>
              <td class="postTd">
                <label for="remark">Remark:</label>
                <input type="text" id="remark" v-model="formInputs.remark"/>
              </td>
              <td class="postTd">
                <button type="submit" @click.prevent="maSubmit">Submit</button>                
              </td>
            </tr>                              
          </table>
        </form>            
      </div>
      <div class="productList">
        <div class="buttonContainer">
          {{ saveStatus }} 
          <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitScrapData" :disabled="isProcessing">Scrap</button>          
        </div>
        <form>
          <table class="productListTable">
            <thead>              
              <tr><th>Product Code</th><th>Product Name</th><th>Quantity</th><th>Unit</th><th>Factory</th><th>Work Order</th><th>Remark</th></tr>
            </thead>
            <tbody>      
              <tr v-if="formInputs.productcode">
                <td class="productTd">{{ productCode }}</td>
                <td class="productTd">{{ productName }}</td>
                <td class="productTd">{{ formInputs.proquantity }}</td>          
                <td class="productTd">{{ formInputs.unit }}</td>
                <td class="productTd">{{ formInputs.factory }}</td>
                <td class="productTd">{{ formInputs.workorder }}</td>
                <td class="productTd">{{ formInputs.remark }}</td>
              </tr>            
            </tbody>
          </table>
        </form>        
     </div>
      <div class="scrapList">
        <form>
          <table class="listTable">
            <thead>
              <tr><th>Material Code</th><th>Material Name</th><th>Quantity</th><th>Unit</th><th>Factory</th><th>Work Order</th><th>Remark</th><th>DEL</th></tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in item" :key="row.id">          
                <td class="listTd">{{ row.materialcode }}</td>
                <td class="listTd">{{ row.materialname }}</td>
                <td class="listTd">{{ row.quantity }}</td>                          
                <td class="listTd">{{ row.bomunit }}</td>                
                <td class="listTd">{{ row.factory }}</td>    
                <td class="listTd">{{ row.workorder }}</td>   
                <td class="listTd">{{ row.remark }}</td>
                <td class="listTd"><button class="del" type="submit" @click.prevent="deleteRow(index)">DEL</button></td>
              </tr>            
            </tbody>
          </table>
        </form>        
     </div>
  </div>
</template>
  
<script>
import { reactive, ref, computed} from 'vue';
import axios from "axios";

import { getCurrentDate, onFileChange, uploadExcel,  } from "../utils.js"; 

export default {
  data() { return { excelFile: null, fileName: "", }; }, 
  
  methods: {
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },     
  },

 setup(){
  const formInputs = reactive({ productcode: "", proquantity: "", maquantity: "", unit: "", materialcode: "", factory: "", workorder: "", remark: "" });
  const dbTableName = 'materiallist';
  const tableData = reactive({ data: [], }); 
  const saveStatus = ref(''); 
  const isProcessing = ref(false);   

  const searchDate = reactive({...getCurrentDate(),});      

  const productSearchData = async (tableName) => {    
    const columnName = 'productcode';    
    axios.get('/api/where', { params: { value: formInputs.productcode, table_name: tableName, column_name: columnName } })
      .then((res) => {
        tableData.data = res.data
      });
  };   

  const materialSearchData = async (tableName) => {    
    const columnName = 'materialcode';    
    axios.get('/api/where', { params: { value: formInputs.materialcode, table_name: tableName, column_name: columnName } })
      .then((res) => {
        if (res.data && res.data.length > 0) {  //자재 검색시 여러개가 나오니까 위에 1개만 가지고 오게 만든거
          tableData.data = [res.data[0]];
        } else {
          tableData.data = [];
        }
    });
  };   

  const item = computed(() => {
      return tableData.data.map(d => { let quantity = formInputs.maquantity;
        if (formInputs.proquantity && formInputs.proquantity > 0) {
          quantity = d.bom * formInputs.proquantity;
        }
        return { ...d, quantity: quantity, factory: formInputs.factory,  workorder: formInputs.workorder, remark: formInputs.remark };});
  });
  const productCode = computed(() => { return item.value.length > 0 ? item.value[0].productcode : '';});
  const productName = computed(() => { return item.value.length > 0 ? item.value[0].productname : '';});

  const callSearchData = () => { productSearchData(dbTableName); materialSearchData(dbTableName);};

  const proSubmit = () => { callSearchData(); productSearchData(dbTableName); };
  const maSubmit = () => { callSearchData(); materialSearchData(dbTableName); };

  const resetForm = () => {
    formInputs.productcode = "";
    formInputs.proquantity = "";
    formInputs.maquantity = "";
    formInputs.unit = "";
    formInputs.materialcode = "";
    formInputs.factory = "";
    formInputs.workorder = "";
    formInputs.remark = "";
    tableData.data = [];
  };

  const resetScreen = () => {
    productCode.value = "";
    productName.value = "";
    for (let row of item.value) {
      row.materialcode = "";
      row.materialname = "";
      row.quantity = "";
      row.bomunit = "";
      row.factory = "";
      row.workorder = "";
      row.remark = "";
    }
  };  

  const submitScrapData = async () => {
    isProcessing.value = true; 
    saveStatus.value = 'Processing...';
    let scrapData = []; 

    if (formInputs.productcode) {
      await axios.post('/api/post', {
        tableName: 'scrapproductlog',
        columnMapping: {
          year: 'year',
          month: 'month',
          day: 'day',
          productcode: 'productcode', 
          productname: 'productname', 
          quantity: 'quantity', 
          unit: 'unit',
          factory: 'factory',
          workorder: 'workorder',
          remark: 'remark'
        },
        values: { 
          year: searchDate.year,
          month: searchDate.month,
          day: searchDate.day,
          productcode: productCode.value, 
          productname: productName.value, 
          quantity: formInputs.proquantity, 
          unit: formInputs.unit,
          factory: formInputs.factory,
          workorder: formInputs.workorder,
          remark: formInputs.remark 
        }
      });
    }
   
    scrapData = item.value.map(row => ({
      year: searchDate.year,
      month: searchDate.month,
      day: searchDate.day,
      materialcode: row.materialcode, 
      materialname: row.materialname, 
      quantity: row.quantity, 
      unit: row.bomunit, 
      factory: row.factory, 
      workorder: row.workorder,
      remark: row.remark,
      sap: "Not yet", 
      realscrap: "Not yet"
    }));
    
    await axios.post('/api/postAll', {
      tableName: 'scrapmateriallog',
      columnMapping: {  
        year: 'year',
        month: 'month',
        day: 'day',
        materialcode: 'materialcode', 
        materialname: 'materialname', 
        quantity: 'quantity', 
        unit: 'unit', 
        factory: 'factory', 
        workorder: 'workorder',
        remark: 'remark',
        sap: 'sap', 
        realscrap: 'realscrap'
      },
      values: scrapData
    });

    saveStatus.value = ''; 
    isProcessing.value = false;
    resetForm();
    resetScreen();
};

  const deleteRow = (index) => { tableData.data.splice(index, 1); };
  
  return {formInputs, item, productCode, productName, proSubmit, maSubmit, submitScrapData, searchDate, deleteRow, saveStatus, isProcessing };
  }
}
 
</script>
  
<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.scrapPost {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search {
  width: 100%;
  margin-bottom: 1rem;    
}

.postTable {
  width: 100%;
  border-collapse: collapse;
}

.postTd {
  padding: 0.5rem;
  text-align: left;
}

input[type="text"],
select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #3498db;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

button:hover { background-color: #2980b9; }

.buttonContainer { display: flex; justify-content: flex-end; }

.productList,
.scrapList { width: 100%; margin: 1rem 0; }

.productListTable,
.listTable {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #f5f5f5;
}

.productTd,
.listTd {
  padding: 0.5rem;
  text-align: left;
}

.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 150px; size: 8px; }

.custom-file-upload {
  padding: 6px 6px; background-color: #347003;
  color: #fff; border: none; border-radius: 4px; font-size: 14px;   
  font-weight: 600; margin-left: 10px; cursor: pointer;        
  transition: background-color 0.3s;   
}
.custom-file-upload:hover { background-color: #009625; }

.listuploadbutton {
  padding: 6px 6px; background-color: #3498db;
  color: #fff; border: none; border-radius: 4px; font-size: 14px;   
  font-weight: 600; margin-left: 10px; cursor: pointer;        
  transition: background-color 0.3s;        
}

.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>