<template>
  <div class="container">        
    <div class="RequestLog">
      <h class="pagetitle">Maintenance Work List</h>        
      <form @submit.prevent="sendDate()" class="searchBar">
          <table>  
              <tr>
                  <td class="start">
                      <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
                      <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
                      <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
                  </td>
                  <td class="separator">~</td>
                  <td class="end">
                      <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
                      <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
                      <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
                  </td>
                  <td class="searchTd">                    
                      <select id="factory" v-model="factory">
                        <option>1</option><option>2</option><option></option>
                      </select>                        
                  </td> 
                  <td class="searchTd">                    
                      <select id="type" v-model="type">
                        <option>Repair</option><option>Failure</option><option>TPM</option><option>Patrol</option><option>Installation</option>
                         <option>Improvement</option><option>Other</option><option></option>                 
                      </select>                        
                  </td> 
                  <td class="searchTd">
                      <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
                  </td> 
                  <td class="searchTd">
                      <button class="excelButton" @click="downExcel">Excel</button>                                              
                  </td>
                  <td class="searchTd">
                     <button class="addButton" @click="showModal = true">+ Add</button>   
                  </td>
              </tr>
          </table>      
      </form>
    </div>            
    <div class="tables">
        <table id="productionResult" class="custom-table">
        <tr>          
          <th rowspan="2">Date</th><th rowspan="2">Factory</th><th rowspan="2">Shift</th>
          <th rowspan="2">Type</th><th rowspan="2">Time</th><th rowspan="2">Line/Station</th><th rowspan="2">Class</th>
          <th colspan="4">Spare</th><th rowspan="2">Contents</th><th rowspan="2">Name</th><th rowspan="2">Done</th><th rowspan="2">Modify</th><th rowspan="2">DEL</th>
        </tr>
        <tr>
          <th>Box No</th><th>Name/Spec</th><th>Qty</th><th>Unit</th>          
        </tr>          
        <tr v-for="row in item" :key="row.id">            
          <td>{{ row.year}}-{{ row.month  }}-{{ row.day  }}</td>                  
          <td>{{ row.factory }}</td>                
          <td>{{ row.shift }}</td>                
          <td>{{ row.type }}</td>     
          <td>{{ row.start_time }}~{{ row.end_time  }}</td>                  
          <td>{{ row.line }}<br>{{ row.station }}</td>                               
          <td>{{ row.class1 }}<br>{{ row.class2 }}<br>{{ row.class3 }}</td>                   
          <td v-html="row.spareboxSplit"></td>
          <td v-html="row.spareSplit"></td>
          <td v-html="row.spareqtySplit"></td>
          <td v-html="row.spareunitSplit"></td>
          <td class="contents" >{{ row.contents }}</td>     
          <td>{{ row.name }}</td>                  
          <td class="list" :class="{ 'gray-background': row.done == 'Done' }">
            <button class="confirmButton" :class="{ 'disabledButton': row.done == 'Done' || !canConfirmClick() }" type="submit" @click="handleClickSAP(row)" :disabled="!canConfirmClick()">Done</button></td>  
          <td><button type="submit" @click="openSendModal(row)" class="moButton">Modify</button></td>
          <td><button :class="['del', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>                                        
        </tr>
  </table>    
  </div>
  <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
  <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>
</div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import Modal from "./workListModal.vue";
import modifyModal from "./workMoModal.vue";
import axios from 'axios';
import { deleteRow, downloadExcel, } from "../../utils.js"; 


export default {
  components: { Modal, modifyModal },    

data() {
  return {                     
    columnMapping: {
      year: 'year', month: 'month' , day: 'day', shift: 'shift', line: 'line', station: 'station', start_time: 'start_time',
      end_time: 'end_time', time: 'time', class1 : 'class1', class2 : 'class2', class3 : 'class3',
      spare : 'spare', sparebox : 'sparebox', spareqty : 'spareqty', spareunit : 'spareunit',
      contents: 'contents', name: 'name', done: 'done'
    }
  }
},

methods: {  
  openSendModal(row) {this.selectedRowId = row.id; this.showMoModal = true;},  
  handleClickSAP(row) { this.confirmSendSAP(row); this.callSearchData(); },  
  downExcel() { downloadExcel(this.item, "Work List.xlsx"); },
  delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
  canConfirmClick() {                    
    const currentUser = this.$route.meta.currentUser;
    const isUserValid = currentUser && (currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3 || currentUser.id === 20 );         
    return isUserValid;
  },
  canDelClick() {    
    const currentUser = this.$route.meta.currentUser;
    console.log('currentUser', currentUser)
    if (currentUser) {            
        return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3  ;
    } else {
        return false;
    }
  },

  async confirmSendSAP(row) {  
      if (this.canConfirmClick()) {
      console.log('confirmSend called with row:', row);      
      const formData = {
        id: row.id,
        tableName: 'maintenanceworklist',
        columnMapping: this.columnMapping,
        values: {
          year: row.year,
          month: row.month,
          day: row.day,                                                
          shift: row.shift,
          start_time: row.start_time,
          end_time: row.end_time,
          time: row.time,
          line: row.line,
          station: row.station,
          class1: row.class1,
          class2: row.class2,
          class3: row.class3,
          spare: row.spare,
          sparebox: row.sparebox,
          spareqty: row.spareqty,
          spareunit: row.spareunit,
          contents: row.contents,
          name: row.name,
          done: "Done",
        }
      };
      const hasUndefinedValue = Object.values(formData.values).some(value => value === undefined);

        if (hasUndefinedValue) {
          console.error('Undefined value found in formData.values:', formData.values);
          return;
        }

        formData.columnMapping = JSON.parse(JSON.stringify(formData.columnMapping));
        console.log('formData:', formData);
            console.log('Mapping:', this.columnMapping);
        try {
            const response = await axios.put(`/api/materialput/${row.id}`, formData);
            console.log('Server response:', response);
            console.log('Data updated successfully');          
          } catch (error) {
            console.error('Error updating data:', error);
          }
      }
  },
},

setup() {
  const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); const factory = ref(); const type = ref(); 

  const dbTableName = 'maintenanceworklist';  
  const headers = ['Date', 'Factory', 'Shift', 'Type', 'Time', 'Line/Station', 'Class', 'Spare', 'Contents', 'Name', 'Done', 'Modify', 'DEL', ];    
  const tableData = reactive({ data: [], });  
  const showModal = ref(false);     
  const showMoModal = ref(false);     

  const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, factory.value, type.value ]; return {sendDateData};};     
  const searchData = async (tableName) => {
    const sendDateData = await sendDate();
    const formData = {        
        tableName: tableName,
        startYear: sendDateData.sendDateData[0],
        startMonth: sendDateData.sendDateData[1],
        startDay: sendDateData.sendDateData[2],
        endYear: sendDateData.sendDateData[3],
        endMonth: sendDateData.sendDateData[4],
        endDay: sendDateData.sendDateData[5],
        factory: sendDateData.sendDateData[6],
        type: sendDateData.sendDateData[7],
    };    
    axios.get('/api/searchPeriod', { params: formData })
      .then((res) => { 
        tableData.data = res.data.sort((a, b) => {
          if (a.factory !== b.factory) return a.factory - b.factory;
          if (a.year !== b.year) return a.year - b.year;
          if (a.month !== b.month) return a.month - b.month;
          if (a.day !== b.day) return a.day - b.day;
          if (a.shift !== b.shift) return a.shift - b.shift;
          return a.start_time.localeCompare(b.start_time);
        });
      });
  };  

  const callSearchData = () => { searchData(dbTableName); };  

  const item = computed(() => {
    return tableData.data.map(d => {     
      function formatData(data) {
        return data && data.includes(';') ? data.split(';').join('<br>') : '';
      }
      return {
        ...d,
        spareboxSplit: formatData(d.sparebox),
        spareSplit: formatData(d.spare),
        spareqtySplit: formatData(d.spareqty),
        spareunitSplit: formatData(d.spareunit)
      };
    });
  });

  return {
    startYear, startMonth, startDay, endYear, endMonth, endDay, factory, type,
    headers, dbTableName, item, showModal, showMoModal,
    searchData, callSearchData, sendDate, 
  };
},
};
</script>

<style scoped>
 * { box-sizing: border-box;}

.container { margin-top: 0px; padding-top: 10px; max-width: 95%; margin-left: auto; margin-right: auto;}
.start,.end { display: flex; flex-direction: row; border: 0px;}
.start input,.end input { margin-right: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px;}
.searchTd { border: 0px;}

input::placeholder { color: gray;}

label { font-size: 14px; font-weight: 600; margin-right: 8px;}

select,input[type="number"],input[type="text"] {
  padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
  font-size: 14px; width: 100%; max-width: 100px;
}

.del {
  padding: 4px 4px;
  background-color: #bfbebe;
  color: #000000; border: 10px; border-color: #000000;
  font-size: 10px; font-weight: 600; cursor: pointer;
  transition: background-color 0.3s;
}

.RequestLog{ width: 500px;}
.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-bottom: 20px; margin-left: 20px;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }

.searchBar select,.searchBar input{ width : 100px; margin-right: 5px; padding : 5px; }

label { font-size: 14px; font-weight: 600; margin-right: 8px; }

td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; }

th { 
  position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ;
  border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 12px; 
}
.tables {
  display: flex; flex-direction: column; align-items: stretch;
  padding-left : 20px; padding-right : 20px;
}

table { width: 80%; }
.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px; }
.disabledButton { background-color: gray !important; cursor: not-allowed !important; }
.confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.contents { width: 500px !important; text-align: left;}
.gray-background { background-color: gray;} 

.searchButton, .excelButton, .addButton { width: 100px; height: 30px !important; font-size: 15px}
.searchButton {
    background-color: #929292;  color: rgb(0, 0, 0);
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
}
.searchButton:hover { background-color: #a4a4a4; }
.excelButton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
}
.excelButton:hover { background-color: #009625; }
.addButton {
    background-color: #007396;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
}
.addButton:hover { background-color:  #3498db; }
.moButton {
    padding: 5px 5px; background-color: #0d4d01; color: #fff; border: none; border-radius: 4px;
    font-size: 11px; font-weight: 600; margin-top: 3px; margin-bottom: 3px; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}

</style>