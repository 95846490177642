<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Attendance</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
                  <div class="form-group date-time-group">
                    <label>Year/Month/Day:</label>
                    <div class="date-time-inputs">
                      <input type="text" id="year" v-model="formInputs.year"  />
                      <input type="text" id="month" v-model="formInputs.month" />
                      <input type="text" id="day" v-model="formInputs.day" />                  
                    </div> 
                  </div>    
                  <div class="form-group">
                    <label for="start_hour">Start time Card</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="start_hour_card" v-model="startHourCard" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="start_minute_card" v-model="startMinuteCard" min="0" max="59" />
                    </div>
                  </div>            
                  <div class="form-group">
                    <label for="end_hour">End time Card</label>
                    <div class="time-inputs">
                        <label for="end_hour">H:</label>
                        <input type="number" id="end_hour_card" v-model="endHourCard" min="0" max="23" />
                        <label for="end_minute">M:</label>
                        <input type="number" id="end_minute_card" v-model="endMinuteCard" min="0" max="59" />
                    </div>
                  </div>    
                  <div class="form-group">
                    <label for="start_hour">Start time Work</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="start_hour_work" v-model="startHourWork" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="start_minute_work" v-model="startMinuteWork" min="0" max="59" />
                    </div>
                  </div>            
                  <div class="form-group">
                    <label for="end_hour">End time Card Work</label>
                    <div class="time-inputs">
                        <label for="end_hour">H:</label>
                        <input type="number" id="end_hour_work" v-model="endHourWork" min="0" max="23" />
                        <label for="end_minute">M:</label>
                        <input type="number" id="end_minute_work" v-model="endMinuteWork" min="0" max="59" />
                    </div>
                  </div>                    
                  </td>
                  <td>
                    <div class="form-group">
                    <label for="oscompany">Company:</label>
                    <select type="text" id="oscompany" v-model="formInputs.oscompany">
                      <option>LSCP</option>
                    </select>
                   </div>
                    <div class="form-group">
                    <label for="employeenumber">Employee Number:</label>
                    <input id="employeenumber" v-model="formInputs.employeenumber" />
                    </div>     
                    <div class="form-group">
                      <label for="name">Name:</label>
                      <input type="text" id="name" v-model="formInputs.name" />
                    </div>
                    <div class="form-group">
                      <label for="surname">Surname:</label>
                      <input type="text" id="surname" v-model="formInputs.surname" />
                    </div>                  
                    <div class="form-group">
                      <label for="team">team:</label>
                      <input type="text" id="team" v-model="formInputs.team" />
                    </div>   
                    <div class="form-group">
                      <label for="position">Position:</label>
                      <input type="text" id="position" v-model="formInputs.position" />
                    </div>                   
                </td>
                <td>
                  <div class="form-group">
                    <label for="start_hour">Overtime 50%</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="overtime50_hour" v-model="overTime50Hour" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="overtime50_minute" v-model="overTime50Minute" min="0" max="59" />
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="start_hour">Overtime 100%</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="overtime100_hour" v-model="overTime100Hour" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="overtime100_minute" v-model="overTime100Minute" min="0" max="59" />
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="start_hour">Overtime 200%</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="overtime200_hour" v-model="overTime200Hour" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="overtime200_minute" v-model="overTime200Minute" min="0" max="59" />
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="start_hour">Late for Work</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="latecometowork_hour" v-model="latecometoworkHour" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="latecometowrok_minute" v-model="latecometoworkMinute" min="0" max="59" />
                    </div>
                  </div>  
                  <div class="form-group">
                    <label for="start_hour">Leave Early</label>
                    <div class="time-inputs">
                        <label for="start_hour">H:</label>
                        <input type="number" id="earlygotohome_hour" v-model="earlygotohomeHour" min="0" max="23" />
                        <label for="start_minute">M:</label>
                        <input type="number" id="earlygotohome_minute" v-model="earlygotohomeMinute" min="0" max="59" />
                    </div>
                  </div>  
                </td>
              </tr>
            </table>         
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch,  ref } from 'vue';  
  import axios from 'axios';   
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "attendancels";
        const startHourCard = ref(0);  const startMinuteCard = ref(0);  const endHourCard = ref(0);  const endMinuteCard = ref(0);                 
        const startHourWork = ref(0);  const startMinuteWork = ref(0);  const endHourWork = ref(0);  const endMinuteWork = ref(0);                 
        const overTime50Hour = ref(0);  const overTime50Minute = ref(0);  const overTime100Hour = ref(0);  const overTime100Minute = ref(0);  const overTime200Hour = ref(0);  const overTime200Minute = ref(0);  
        const latecometoworkHour = ref(0);  const latecometoworkMinute = ref(0);  const earlygotohomeHour = ref(0);  const earlygotohomeMinute = ref(0);  
        const formInputs = reactive({   
          id: "",
          year: "",
          month: "",
          day: "",
          oscompany: "",
          employeenumber: "",
          team : "",
          position : "",
          name : "",
          surname : "",             
          start_time_card : "",      
          end_time_card : "",          
          start_time_work : "",
          end_time_work : "",
          overtime_50 : "",
          overtime_100 : "",
          overtime_200 : "",
          latecometowork : "",
          earlygotohome : "",
        });

          const updateStartTime_card = () => { formInputs.start_time_card = `${startHourCard.value.toString().padStart(2, "0")}:${startMinuteCard.value.toString().padStart(2, "0")}`; };
          watch(startHourCard, updateStartTime_card);  watch(startMinuteCard, updateStartTime_card); 
          const updateEndTime_card = () => { formInputs.end_time_card = `${endHourCard.value.toString().padStart(2, "0")}:${endMinuteCard.value.toString().padStart(2, "0")}`; };
          watch(endHourCard, updateEndTime_card);  watch(endMinuteCard, updateEndTime_card);

          const updateStartTime_work = () => { formInputs.start_time_work = `${startHourWork.value.toString().padStart(2, "0")}:${startMinuteWork.value.toString().padStart(2, "0")}`; };
          watch(startHourWork, updateStartTime_work);  watch(startMinuteWork, updateStartTime_work);  
          const updateEndTime_work = () => { formInputs.end_time_work = `${endHourWork.value.toString().padStart(2, "0")}:${endMinuteWork.value.toString().padStart(2, "0")}`; };
          watch(endHourWork, updateEndTime_work);  watch(endMinuteWork, updateEndTime_work);

          const updateOverTime_50 = () => { formInputs.overtime_50 = `${overTime50Hour.value.toString().padStart(2, "0")}:${overTime50Minute.value.toString().padStart(2, "0")}`; };
          watch(overTime50Hour, updateOverTime_50);  watch(overTime50Minute, updateOverTime_50);  
          const updateOverTime_100 = () => { formInputs.overtime_100 = `${overTime100Hour.value.toString().padStart(2, "0")}:${overTime100Minute.value.toString().padStart(2, "0")}`; };
          watch(overTime100Hour, updateOverTime_100);  watch(overTime100Minute, updateOverTime_100);  
          const updateOverTime_200 = () => { formInputs.overtime_200 = `${overTime200Hour.value.toString().padStart(2, "0")}:${overTime200Minute.value.toString().padStart(2, "0")}`; };
          watch(overTime200Hour, updateOverTime_200);  watch(overTime200Minute, updateOverTime_200);  

          const updateLatecometowork = () => { formInputs.latecometowork = `${latecometoworkHour.value.toString().padStart(2, "0")}:${latecometoworkMinute.value.toString().padStart(2, "0")}`; };
          watch(latecometoworkHour, updateLatecometowork);  watch(latecometoworkMinute, updateLatecometowork);  
          const updateEarlygotohome = () => { formInputs.earlygotohome = `${earlygotohomeHour.value.toString().padStart(2, "0")}:${earlygotohomeMinute.value.toString().padStart(2, "0")}`; };
          watch(earlygotohomeHour, updateEarlygotohome);  watch(earlygotohomeMinute, updateEarlygotohome);  

        const submitForm = async () => {
          if (!formInputs.employeenumber || !formInputs.oscompany ) {
            alert("Please fill in all the fields: Employee Number, OS Company.");
            return;
          }
          const columnMapping = {                          
            year: 'year',
            month: 'month',
            day: 'day',            
            oscompany: "oscompany",
            employeenumber: "employeenumber",
            team : "team",
            position : "position",
            name : "name",
            surname : "surname",               
            start_time_card : "start_time_card",
            end_time_card : "end_time_card",
            start_time_work : "start_time_work",
            end_time_work : "end_time_work",
            overtime_50 : "overtime_50",
            overtime_100 : "overtime_100",
            overtime_200 : "overtime_200",
            latecometowork : "latecometowork",
            earlygotohome : "earlygotohome",
          }     

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {                            
              year: formInputs.year,
              month: formInputs.month,
              day: formInputs.day,              
              oscompany : formInputs.oscompany,
              employeenumber : formInputs.employeenumber,              
              team : formInputs.team,
              position : formInputs.position,
              name : formInputs.name,
              surname : formInputs.surname,
              start_time_card : formInputs.start_time_card,
              end_time_card : formInputs.end_time_card,
              start_time_work : formInputs.start_time_work,
              end_time_work : formInputs.end_time_work,
              overtime_50 : formInputs.overtime_50,
              overtime_100 : formInputs.overtime_100,
              overtime_200 : formInputs.overtime_200,
              latecometowork : formInputs.latecometowork,
              earlygotohome : formInputs.earlygotohome,
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }
         
         await axios.put(`/api/materialput/${id}`, formData);         
         closeModal();
        };
        const closeModal = () => {
          emit("onClose");
        };   

        const parseTimeString = (timeString) => {
            const [hour, minute] = timeString.split(':');
            return { hour: parseInt(hour, 10), minute: parseInt(minute, 10) };
          };
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];
              
              formInputs.id = rowData.id;              
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,              
              formInputs.oscompany = rowData.oscompany,
              formInputs.employeenumber = rowData.employeenumber,              
              formInputs.team = rowData.team,
              formInputs.position = rowData.position,
              formInputs.name = rowData.name,
              formInputs.surname = rowData.surname                     
              
              const { hour: startHourCardValue, minute: startMinuteCardValue } = parseTimeString(rowData.start_time_card);
              startHourCard.value = startHourCardValue;  startMinuteCard.value = startMinuteCardValue;              
              const { hour: endHourCardValue, minute: endMinuteCardValue } = parseTimeString(rowData.end_time_card);              
              endHourCard.value = endHourCardValue; endMinuteCard.value = endMinuteCardValue;

              const { hour: startHourWorkValue, minute: startMinuteWorkValue } = parseTimeString(rowData.start_time_work);
              startHourWork.value = startHourWorkValue;  startMinuteWork.value = startMinuteWorkValue;              
              const { hour: endHourWorkValue, minute: endMinuteWorkValue } = parseTimeString(rowData.end_time_work);              
              endHourWork.value = endHourWorkValue; endMinuteWork.value = endMinuteWorkValue;

              const { hour: overTime50HourValue, minute: overTime50MinuteValue } = parseTimeString(rowData.overtime_50);              
              overTime50Hour.value = overTime50HourValue; overTime50Minute.value = overTime50MinuteValue;
              const { hour: overTime100HourValue, minute: overTime100MinuteValue } = parseTimeString(rowData.overtime_100);              
              overTime100Hour.value = overTime100HourValue; overTime100Minute.value = overTime100MinuteValue;
              const { hour: overTime200HourValue, minute: overTime200MinuteValue } = parseTimeString(rowData.overtime_200);              
              overTime200Hour.value = overTime200HourValue; overTime200Minute.value = overTime200MinuteValue;

              const { hour: latecometoworkHourValue, minute: latecometoworkMinuteValue } = parseTimeString(rowData.latecometowork);              
              latecometoworkHour.value = latecometoworkHourValue; latecometoworkMinute.value = latecometoworkMinuteValue;
              const { hour: earlygotohomeHourValue, minute: earlygotohomeMinuteValue } = parseTimeString(rowData.earlygotohome);              
              earlygotohomeHour.value = earlygotohomeHourValue; earlygotohomeMinute.value = earlygotohomeMinuteValue;


            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  
          

      return { dbTableName, formInputs, submitForm, closeModal,
               startHourCard, startMinuteCard, endHourCard, endMinuteCard, startHourWork, startMinuteWork, endHourWork, endMinuteWork,
               overTime50Hour, overTime50Minute, overTime100Hour, overTime100Minute, overTime200Hour, overTime200Minute,
               latecometoworkHour, latecometoworkMinute, earlygotohomeHour, earlygotohomeMinute
            };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; justify-content: center; align-items: center;
  }
  
  .modal-wrapper { width: 100%;  max-width: 1000px; }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }
  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

  .modal-header h3 { font-size: 1.2rem; }

  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

  .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

  label { font-weight: bold; }

  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  input[type="number"] { width: 100%;}  

  .readWindow[readonly] { background-color: #f0f0f0; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  table { table-layout: fixed; width: 100%; }

  td { width: 20%; vertical-align: top; padding: 0 15px; }
  
  .time-inputs { display: flex; gap: 5px; }
  .time-inputs input[type="number"] { width: 80px; }
  .date-time-inputs { display: flex; gap: 5px;}
  .date-time-inputs input { width: 50px; size: 8px; }

  .ng-dropdown-container { position: relative; display: inline-block; }
  .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
  .ng-dropdown-menu { 
    position: absolute; padding: 4px;
    left: 0; top: 100%; z-index: 10; 
    background-color: white; border: 1px solid #ccc;  
  }
  .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
  .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

  .ngmaintype{ max-width: 120px; min-width: 120px;}
</style>