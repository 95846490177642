<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>To do List Write Task</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>
            <td>
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>     
            <div class="form-group">
              <label for="type">Team:</label>
              <div>
                <select type="text" id="team" v-model="formInputs.team">
                  <option>Production 1st</option><option>Production 2nd</option><option>Planning</option><option>Quality</option>
                  <option>Sales</option><option>Purchase</option><option>HR/HSE/GA</option><option></option>
                </select>
              </div>
            </div>  
            <div class="form-group">
              <label for="project">Project:</label>
              <div>
                <input class="radio" type="radio" id="bmw" value="BMW" v-model="formInputs.project">
                <label for="bmw">BMW</label>
                <input class="radio"  type="radio" id="fca" value="FCA" v-model="formInputs.project">
                <label for="fca">FCA</label>
                <input class="radio"  type="radio" id="ford" value="Ford" v-model="formInputs.project">
                <label for="ford">Ford</label>
                <input class="radio"  type="radio" id="renault" value="Renault" v-model="formInputs.project">
                <label for="renault">Renault</label>
                <input class="radio"  type="radio" id="vw" value="VW" v-model="formInputs.project">
                <label for="vw">VW</label>
                <input class="radio"  type="radio" id="all" value="ALL" v-model="formInputs.project">
                <label for="vw">ALL</label>
              </div>  
            </div>
            <div class="form-group">
              <label for="type">Type:</label>
              <div>
                <select type="text" id="type" v-model="formInputs.type">
                  <option>Productivity</option><option>Optimize manpower</option><option>NG Reduction</option><option>ETC</option><option></option>
                </select>
              </div>
            </div>                
            <div class="form-group">
              <label for="type">Line:</label>
              <div>
                <select type="text" id="line" v-model="formInputs.line">
                <option v-for="option in lineOptions" :key="option">{{ option }}</option>
              </select>       
              </div>
            </div>  
            <div class="form-group">  
              <label for="type">In Charge:</label>
              <div>
                <input type="text" id="incharge" v-model="formInputs.incharge" />                  
              </div>
            </div>  
            <div class="form-group date-time-group">
                <label>Due Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="dueyear" v-model="formInputs.dueyear"  />
                  <input type="text" id="duemonth" v-model="formInputs.duemonth" />
                  <input type="text" id="dueday" v-model="formInputs.dueday" />                  
                </div> 
            </div>   
          </td>
          <td>
            <div class="form-group">
              <label for="project">Progress:</label>
              <div>
                <input class="radio" type="radio" id="notyet" value="Not Yet" v-model="formInputs.progress">
                <label for="notyet">Not Yet</label>
                <input class="radio"  type="radio" id="ongoing" value="On going" v-model="formInputs.progress">
                <label for="ongoing">On going</label>
                <input class="radio"  type="radio" id="done" value="Done" v-model="formInputs.progress">
                <label for="done">Done</label>                
              </div>
            </div>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="contents">Contents:</label>
              <textarea id="contents" v-model="formInputs.contents" rows="4" cols="25"></textarea>              
            </div>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="effect">Effect:</label>
              <textarea id="effect" v-model="formInputs.effect" rows="4" cols="25"></textarea>              
            </div>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="remark">Remark:</label>
              <textarea id="remark" v-model="formInputs.remark" rows="4" cols="25"></textarea>              
            </div>   
            <div class="form-group">
               <label for="type">File Upload:</label>
              </div>
              <div>           
                <input type="file" @change="handleFileUpload('file')($event)" />              
                <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button><br>
                {{ fileSaveStatus.file }}   
               </div>    
            </td>
            <td>
              <div class="form-group">
               <label for="type">Image 1 Upload:</label>
              </div>
              <div>           
                <input type="file" @change="handleFileUpload('imageone')($event)" />              
                <button class="fileUploadButton" type="button" @click="fileUpload('imageone')">Image 1 Upload</button><br>
                {{ fileSaveStatus.imageone }}   
               </div><br>
               <div class="form-group">
               <label for="type">Image 2 Upload:</label>
              </div>
              <div>           
                <input type="file" @change="handleFileUpload('imagetwo')($event)" />              
                <button class="fileUploadButton" type="button" @click="fileUpload('imagetwo')">Image 2 Upload</button><br>
                {{ fileSaveStatus.imagetwo }}   
               </div>            
            </td>
            </tr>
            <tr>
              <td>                        
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}  
           </td>
            </tr>
            </table>       
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, ref, watch } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, uploadFile } from '../utils.js';
   
  export default {    

    setup(props, { emit }) {       
      const dbTableName = "todolist";
      const selectedFiles = reactive({ file: null,  imageone: null, imagetwo: null, });     
      const saveStatus = ref('');
      const fileSaveStatus = reactive({ file: '', imageone: '', imagetwo: '', });
      const isProcessing = ref(false);      
      const uploadType = 'toDoList';    
      const formInputs = reactive({
        team: "",  project: "", type: "", line: "", contents: "", incharge: "", progress: "", effect: "",
        dueyear: "", duemonth: "", dueday: "", remark: "", file: "", filepath: "", imageone: "", imageonepath: "",
        imagetwo: "", imagetwopath: "",
      });
      const searchDate = reactive({ ...getCurrentDate()  });        
      
      const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

      const submitForm = async () => {
        if (!searchDate.year || !searchDate.month || !searchDate.day) {
          alert("Please fill in all the fields: year, month, and day.");
          return;
        }

        isProcessing.value = true;
        saveStatus.value = 'Processing...';                

        const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            team: 'team',
            project: 'project',
            type: 'type',
            line: 'line',
            contents: 'contents',
            incharge: 'incharge',
            progress: 'progress',
            effect: 'effect',
            dueyear: 'dueyear',
            duemonth: 'duemonth',
            dueday: 'dueday',                       
            remark: 'remark',          
            file: 'file',
            filepath: 'filepath',   
            imageone: 'imageone',
            imageonepath: 'imageonepath',
            imagetwo: 'imagetwo',
            imagetwopath: 'imagetwopath'
          };

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              team: formInputs.team,
              project: formInputs.project,
              type: formInputs.type,
              line: formInputs.line,
              contents: formInputs.contents,
              incharge: formInputs.incharge,
              progress: formInputs.progress,
              effect: formInputs.effect,
              dueyear: formInputs.dueyear,
              duemonth: formInputs.duemonth,
              dueday: formInputs.dueday,              
              remark: formInputs.remark,
              file: formInputs.file,
              filepath: formInputs.filepath,
              imageone: formInputs.imageone,
              imageonepath: formInputs.imageonepath,
              imagetwo: formInputs.imagetwo,
              imagetwopath: formInputs.imagetwopath
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

      const closeModal = () => { emit("onClose"); };

      const fileUpload = (type) => uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);         

      const lineOptions = ref([]);    

      const updateLine = () => {          
          if (formInputs.project === 'BMW') { lineOptions.value = ['BMW 48V', 'BMW 12V',];}
          else if (formInputs.project === 'FCA') { lineOptions.value = ['FCA 2', 'FCA FLEX PO', 'FCA FLEX NE', 'FCA FLEX BC', 'FCA FLEX 45', 'FCA FLEX 67']; }
          else if (formInputs.project === 'Ford') { lineOptions.value = ['Ford 2 Front', 'Ford 2 Rear', 'Ford 3 Front', 'Ford 3 Rear',]; }
          else if (formInputs.project === 'Renault') { lineOptions.value = ['BT6', 'EV2020 4060kW', 'EV2020 60kW',]; }
          else if (formInputs.project === 'VW') { lineOptions.value = ['TR2 SMT', 'TR2 Crimping', 'TR2 FCA', 'TR2 BFA 2P', 'TR2 BFA 3P',
                                                                      'TR3 SMT', 'TR3 Crimping', 'TR3 FPCA', 'EVO Hybrid', 'EVO 1', 'EVO 2',]; }            
          else if (formInputs.project === 'ALL') { lineOptions.value = ['ALL', ]; }    
          else { lineOptions.value = ['Please select a project']; }
      };        

      watch(() => [formInputs.project, formInputs.line], updateLine);       
  

    return { saveStatus, isProcessing, formInputs, searchDate, selectedFiles, 
             lineOptions, handleFileUpload,  submitForm, closeModal, dbTableName, fileUpload, fileSaveStatus,
    };
  },     

  props: { onSubmit: { type: Function,  required: true, },}, 
};
  
  </script>
    
  <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 800px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }

    .fileUploadButton {
      background-color: #c6c6c6; color: rgb(0, 0, 0);
      padding: 5px 5px; margin-top: 10px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
      display: inline-block; transition-duration: 0.4s;
    }

    .fileUploadButton:hover { background-color: #5f5f5f; }    
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
    .radio { margin-left: 10px; margin-right: 5px; }
    .disabled { background-color: #ccc; cursor: not-allowed; }
</style>