<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Daily Report</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>                                   
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="contents">Contents:</label>
              <textarea id="contents" v-model="formInputs.contents" rows="4" cols="25"></textarea>              
            </div>                 
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "ford2frdr"      
        const lineName ="ford2fr";    

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          contents: "",          
        });
        // -끝-   

      
      //년.월.일을 지금 시간으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
       const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝

        //input, select에 있는 id 이름과 database table 이름을 맞추는거, 
        //그래서 필히 id 이름이 database table 이름과 동일해야 함
        //시작 
        const getColumnMapping = () => {
          const inputs = document.querySelectorAll("input, select, textarea");
          const mapping = {};

          inputs.forEach(input => {
            let inputId = input.id;

            if (inputId === "start_hour" || inputId === "start_minute") {
              inputId = "start_time";
            } else if (inputId === "end_hour" || inputId === "end_minute") {
              inputId = "end_time";
            }

            if (!mapping[inputId]) {
              if (inputId === "start_time") {
                mapping[inputId] = "start_time";
              } else if (inputId === "end_time") {
                mapping[inputId] = "end_time";
              } else {
                mapping[inputId] = input.id;
              }
            }
          });

          return mapping;
        };
        //끝 

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day) {
            alert("Please fill in all the fields: year, month, and day.");
            return;
          }

          const columnMapping = getColumnMapping();

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              contents: formInputs.contents,              
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    



  
      return { searchDate, dbTableName, formInputs, submitForm, closeModal, lineName};

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 


};
  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   

.modal-wrapper { width: 100%;  max-width: 500px; }

.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}

.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

.modal-header h3 { font-size: 1.2rem; }

.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

label { font-weight: bold; }

input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

input[type="number"] { width: 100%;}  

.readWindow[readonly] { background-color: #f0f0f0; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s;
}

button[type="submit"]:hover { background-color: #45a049; }

table { table-layout: fixed; width: 100%; }

td { width: 20%; vertical-align: top; padding: 0 15px; }

.time-inputs { display: flex; gap: 5px; }
.time-inputs input[type="number"] { width: 50px; }
.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 50px; size: 8px; }

.ng-dropdown-container { position: relative; display: inline-block; }
.ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
.ng-dropdown-menu { 
  position: absolute; padding: 4px;
  left: 0; top: 100%; z-index: 10; 
  background-color: white; border: 1px solid #ccc;  
}
.ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
.ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

.ngmaintype{ max-width: 120px; min-width: 120px;}
</style>