// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import axios from 'axios';

import Tr2smtPr from './components/tr2smt/tr2smt_pr.vue';
import Tr2fpcaPr from './components/tr2fpca/tr2fpca_pr.vue';
import Tr2crimpingPr from './components/tr2crimping/tr2crimping_pr.vue';
import Tr2bfa2pPr from './components/tr2bfa2p/tr2bfa2p_pr.vue';
import Tr2bfa3pPr from './components/tr2bfa3p/tr2bfa3p_pr.vue';
import Ford1frPr from './components/ford1fr/ford1fr_pr.vue';
import Ford1rrPr from './components/ford1rr/ford1rr_pr.vue';
import Ford2frPr from './components/ford2fr/ford2fr_pr.vue';
import Ford2rrPr from './components/ford2rr/ford2rr_pr.vue';
import Ford3frPr from './components/ford3fr/ford3fr_pr.vue';
import Ford3rrPr from './components/ford3rr/ford3rr_pr.vue';
import Fca1Pr from './components/fca1/fca1_pr.vue';
import Fca2Pr from './components/fca2/fca2_pr.vue';
import Bt6Pr from './components/bt6/bt6_pr.vue';
import Bmw12vPr from './components/bmw12v/bmw12v_pr.vue';
import Bmw48vPr from './components/bmw48v/bmw48v_pr.vue';

import FcaFlexBcPr from './components/fca_flex/busbarCarrier/fca_flex_bc_pr.vue';
import FcaFlexPoPr from './components/fca_flex/positive/fca_flex_po_pr.vue';
import FcaFlex67Pr from './components/fca_flex/67/fca_flex_67_pr.vue';
import FcaFlexNePr from './components/fca_flex/negative/fca_flex_ne_pr.vue';
import FcaFlex45Pr from './components/fca_flex/45/fca_flex_45_pr.vue';
import Tr3smtPr from './components/tr3smt/tr3smt_pr.vue';
import Tr3crimpingPr from './components/tr3crimping/tr3crimping_pr.vue';
import Tr3fpcaPr from './components/tr3fpca/tr3fpca_pr.vue';
import Tr3bfa2pPr from './components/tr3bfa2p/tr3bfa2p_pr.vue';
import Tr3bfa3pPr from './components/tr3bfa3p/tr3bfa3p_pr.vue';
import EvoPr from './components/evo/evo_pr.vue';
import thermistorTape from './components/thermistorTape/thermistorTape_pr.vue';
import thermistorPad from './components/thermistorPad/thermistorPad_pr.vue';
import bendingEvo from './components/bendingEvo/bendingEvo_pr.vue';
import Ev2020_60FrPr from './components/ev2020_60fr/ev2020_60fr_pr.vue';
import Ev2020_60RrPr from './components/ev2020_60rr/ev2020_60rr_pr.vue';
import Ev2020_4060FrPr from './components/ev2020_4060fr/ev2020_4060fr_pr.vue';
import Ev2020_4060RrPr from './components/ev2020_4060rr/ev2020_4060rr_pr.vue';

import searchPeriod from './components/search/searchPeriod.vue';
import report01 from './components/search/reportOne.vue';
import report02 from './components/search/reportTwo.vue';
import monthlyReport from './components/search/monthlyReport/monthlyReport.vue';
import correctiveAction from './components/correctiveAction/correctiveAction.vue';
import investList from './components/invest/investList.vue';
import assetList from './components/search/assetList/assetList.vue';
import documentReport from './components/documentReport/documentReport.vue';

import shippingAllProject from './components/shippingAndInventory/allProjectPlanAndResult.vue';

import targetList from './components/target/targetList.vue';
import excelupload from './components/search/excelUpload.vue';
import datadelete from './components/search/dataDelete.vue';
import scrap from './components/scrap/scrapPost.vue';
import scrapLog from './components/scrap/scrapLog.vue';
import productionSpare from './components/stock/productionSpare/productionSpare.vue';

import checkTarget from './components/checkSheet/checkTarget.vue';
import tr3SmtCheckSheet from './components/checkSheet/tr3smt/checkSheet.vue';
import tr3CrimpingCheckSheet from './components/checkSheet/tr3crimping/checkSheet.vue';
import tr3FpcaCheckSheet from './components/checkSheet/tr3fpca/checkSheet.vue';
import evo1CheckSheet from './components/checkSheet/evo1/checkSheet.vue';
import evo2CheckSheet from './components/checkSheet/evo2/checkSheet.vue';
import evohybridCheckSheet from './components/checkSheet/evohybrid/checkSheet.vue';
import ev202060kwCheckSheet from './components/checkSheet/ev202060kw/checkSheet.vue';
import ev20204060kwCheckSheet from './components/checkSheet/ev20204060kw/checkSheet.vue';
import tr2SmtCheckSheet from './components/checkSheet/tr2smt/checkSheet.vue';
import tr2CrimpingCheckSheet from './components/checkSheet/tr2crimping/checkSheet.vue';
import tr2FpcaCheckSheet from './components/checkSheet/tr2fpca/checkSheet.vue';
import bmw12vCheckSheet from './components/checkSheet/bmw12v/checkSheet.vue';
import bmw48vCheckSheet from './components/checkSheet/bmw48v/checkSheet.vue';
import fcaFlexBcCheckSheet from './components/checkSheet/fcaFlexBc/checkSheet.vue';
import fca2CheckSheet from './components/checkSheet/fca2/checkSheet.vue';
import ford2FrontCheckSheet from './components/checkSheet/ford2Front/checkSheet.vue';
import ford2RearCheckSheet from './components/checkSheet/ford2Rear/checkSheet.vue';
import ford3FrontCheckSheet from './components/checkSheet/ford3Front/checkSheet.vue';
import ford3RearCheckSheet from './components/checkSheet/ford3Rear/checkSheet.vue';

import eolConnectorPin from './components/parameter/eol/eolConnectorPin.vue';
import smtLaser from './components/parameter/smtParameter/smtLaser/smtLaser.vue';
import bfaQrLaser from './components/parameter/bfaParameter/qrLaser/qrLaser.vue';
import bfaWeldLaser from './components/parameter/bfaParameter/weldLaser/weldLaser.vue';
import calibrationDate from './components/parameter/calibrationDate/calibrationDate.vue';

import cleaningcycle1st from './components/5Scleaning/1st/cleanTarget.vue';
import cleaningactual1st from './components/5Scleaning/1st/cleaningList.vue';
import cleaningcycle2nd from './components/5Scleaning/2nd/cleanTarget.vue';
import cleaningactual2nd from './components/5Scleaning/2nd/cleaningList.vue';

import takeOver from './components/takeOver/takeOver.vue';

import inkatomSearch from './components/inkatom/inkatomSearch.vue';
import inkatomFirst from './components/inkatom/1stbfa/inkatomFirst.vue';
import inkatomFlex45 from './components/inkatom/1st45/inkatomFlex45.vue';
import inkatomFlex67 from './components/inkatom/1st67/inkatomFlex67.vue';
import inkatomFlexNe from './components/inkatom/1stnegative/inkatomFlexNe.vue';
import inkatomFlexPo from './components/inkatom/1stpositive/inkatomFlexPo.vue';
import inkatomSecond from './components/inkatom/2ndevo/inkatomSecond.vue';

import annualMaintenance from './components/maintenance/annualMaintenance/annualMaintenance.vue';
import maintenanceCheckTarget from './components/maintenance/maintenanceCheckSheet/mainCheckTarget.vue';
import maintenanceCheckTr3Smt from './components/maintenance/maintenanceCheckSheet/tr3smt/checkSheet.vue';
import maintenanceCheckTr3Crimping from './components/maintenance/maintenanceCheckSheet/tr3crimping/checkSheet.vue';
import maintenanceCheckTr3Fpca from './components/maintenance/maintenanceCheckSheet/tr3fpca/checkSheet.vue';
import maintenanceCheckEVO1 from './components/maintenance/maintenanceCheckSheet/evo1/checkSheet.vue';
import maintenanceCheckEVO2 from './components/maintenance/maintenanceCheckSheet/evo2/checkSheet.vue';
import maintenanceCheckEVOHybrid from './components/maintenance/maintenanceCheckSheet/evoHybrid/checkSheet.vue';
import maintenanceCheckEV202060 from './components/maintenance/maintenanceCheckSheet/ev202060/checkSheet.vue';
import maintenanceCheckEV20204060 from './components/maintenance/maintenanceCheckSheet/ev20204060/checkSheet.vue';
import maintenanceCheckTr2Smt from './components/maintenance/maintenanceCheckSheet/tr2smt/checkSheet.vue';
import maintenanceCheckTR2Crimping from './components/maintenance/maintenanceCheckSheet/tr2crimping/checkSheet.vue';
import maintenanceCheckTR2Fpca from './components/maintenance/maintenanceCheckSheet/tr2fpca/checkSheet.vue';
import maintenanceCheckFord2Front from './components/maintenance/maintenanceCheckSheet/ford2front/checkSheet.vue';
import maintenanceCheckFord2Rear from './components/maintenance/maintenanceCheckSheet/ford2rear/checkSheet.vue';
import maintenanceCheckFord3Front from './components/maintenance/maintenanceCheckSheet/ford3front/checkSheet.vue';
import maintenanceCheckFord3Rear from './components/maintenance/maintenanceCheckSheet/ford3rear/checkSheet.vue';
import maintenanceCheckBMW48V from './components/maintenance/maintenanceCheckSheet/bmw48v/checkSheet.vue';
import maintenanceCheckBMW12V from './components/maintenance/maintenanceCheckSheet/bmw12v/checkSheet.vue';
import maintenanceCheckFca2 from './components/maintenance/maintenanceCheckSheet/fca2/checkSheet.vue';

import maintenanceWorkList from './components/maintenance/workList/maintenanceWorkList.vue';
import failureTarget from './components/maintenance/failureRate/failureTarget.vue';
import failureClass from './components/maintenance/failureRate/failureClass.vue';
import failureReport from './components/maintenance/failureRate/failureReport.vue';
import spareList from './components/maintenance/spareList/spareList.vue';
import equipmentLedger from './components/maintenance/equipmentLedger/equipmentLedger.vue';
import annualCalculation from './components/maintenance/annualMaintenance/annualCalculation.vue';
import machineGrade from './components/maintenance/machineGrade/machineGrade.vue';
import constructionInspection from './components/maintenance/constructionInspection/constructionInspection.vue';




import materialRequest from './components/materialRequest/materialRequest.vue';
import RequestLog from './components/materialRequest/RequestLog.vue';
import materialList from './components/materialRequest/materialList.vue';
import stockList from './components/stock/stockList.vue';
import notnormalstock from './components/stock/notNormalStock.vue';
import trayStock from './components/stock/trayStock.vue';
import monthlyinventorycheck from './components/stock/inventoryCheck.vue';
import movementlog from './components/stock/movementLog.vue';
import barcodePrint from './components/stock/barcodePrint/barcodePrint.vue';
import barcodeRead from './components/stock/barcodePrint/barcodeRead.vue';


import productionPlan from './components/schedule/planning/week_Planning.vue';
import monthlySchedule from './components/schedule/schedule/monthlySchedule.vue';

import production1stAttendance from './components/attendance/production1st/production1stList.vue';
import production2ndAttendance from './components/attendance/production2nd/production2ndList.vue';
import qaAttendance from './components/attendance/qa/qaList.vue';
import warehouseAttendance from './components/attendance/warehouse/warehouseList.vue';
import inkatomAttendance from './components/attendance/inkatom/inkatomList.vue';
import searchAllAttendance from './components/attendance/searchAll.vue';
import weekendWork from './components/attendance/requestManPower/weekendWork.vue';
import lsevpDB from './components/attendance/db/lsevpDB.vue';
//import lscpDB from './components/attendance/db/lscpDB.vue';
import maxPowerDB from './components/attendance/db/maxPowerDB.vue';
import megaStaffDB from './components/attendance/db/megaStaffDB.vue';
import zxyDB from './components/attendance/db/zxy_DB.vue';
import inkatomDB from './components/attendance/db/inkatomDB.vue';
import monthlyMaxPower from './components/attendance/monthlyCost/monthlyMaxPower.vue';
import monthlyMegaStaff from './components/attendance/monthlyCost/monthlyMegaStaff.vue';
import monthlyZYX from './components/attendance/monthlyCost/monthlyZYX.vue';
import monthlyInkatom from './components/attendance/monthlyCost/monthlyInkatom.vue';
import monthlyLineCost from './components/attendance/monthlyCost/monthlyLine.vue';
//import lsAttendance from './components/attendance/ls/lsAttendance.vue';
import lineWorkerList from './components/attendance/lineWorkerList/lineWorkerList.vue';
import evaluationLine from './components/attendance/evaluation/evaluationLine.vue';
import evaluationPerson from './components/attendance/evaluation/evaluationPerson.vue';
import lsList from './components/attendance/ls/lsList.vue';

import mainDashBoard from './components/dashBoard/mainDashBoard.vue';

import toDoList from './components/toDoList/toDoList.vue';
import purchaseList from './components/purchaseList/purchaseList.vue';
import purchaseRequest from './components/purchaseList/purchaseRequest.vue';
import setting from './components/setting/settingList.vue';
import inOutMaterial from './components/stock/inOutMaterial.vue';
import machineData from './components/machineData/machineData.vue';
import productionClass from './components/setting/productionClass/productionClass.vue';
import selectOption from './components/setting/selectOption/selectOption.vue';
import arduino from './components/arduino/arduinoData.vue';
import arduinoLog from './components/arduino/arduinoLog.vue';

const kId = [2, 34, 35, 36, 37, 38, 39, 41, 42, 43, 44]; //한국사람 ID
const poId = [3, 40] //Poland Engineer ID
const p1Id = [4, 6, 14, 15, 17, 21, 23, 25, 27, 32]; //생산1공장 ID
const p2Id = [5, 7, 13, 14, 16, 17, 18, 20, 22, 24, 26, 27, 32]; //생산2공장 ID
const suId = [4, 5] //super Visor ID
const mId = [20, 31] //maintenance ID

const routes = [  
  { path: '/tr2smtpr', component: Tr2smtPr, meta: { allowedIds: [1, ...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/tr2fpcapr', component: Tr2fpcaPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/tr2crimpingpr', component: Tr2crimpingPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/tr2bfa2ppr', component: Tr2bfa2pPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/tr2bfa3ppr', component: Tr2bfa3pPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/ford1frpr', component: Ford1frPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/ford1rrpr', component: Ford1rrPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/ford2frpr', component: Ford2frPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/ford2rrpr', component: Ford2rrPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/ford3frpr', component: Ford3frPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/ford3rrpr', component: Ford3rrPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/fca1pr', component: Fca1Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, ...poId, 33], currentUser: null } },  
  { path: '/fca2pr', component: Fca2Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, ...poId, 33], currentUser: null } },  
  { path: '/bt6pr', component: Bt6Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/bmw12vpr', component: Bmw12vPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },  
  { path: '/bmw48vpr', component: Bmw48vPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },    
  { path: '/fcaflexbcpr', component: FcaFlexBcPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },    
  { path: '/fcaflexpopr', component: FcaFlexPoPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },   
  { path: '/fcaflex67pr', component: FcaFlex67Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },   
  { path: '/fcaflexnepr', component: FcaFlexNePr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } },   
  { path: '/fcaflex45pr', component: FcaFlex45Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, 33], currentUser: null } }, 

  { path: '/tr3smtpr', component: Tr3smtPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },  
  { path: '/tr3crimpingpr', component: Tr3crimpingPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },    
  { path: '/tr3fpcapr', component: Tr3fpcaPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },  
  { path: '/tr3bfa2ppr', component: Tr3bfa2pPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },  
  { path: '/tr3bfa3ppr', component: Tr3bfa3pPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },  
  { path: '/evopr', component: EvoPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },   
  { path: '/thermistortape', component: thermistorTape, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },   
  { path: '/thermistorpad', component: thermistorPad, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },   
  { path: '/bendingevo', component: bendingEvo, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },   
  { path: '/ev202060frpr', component: Ev2020_60FrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },    
  { path: '/ev202060rrpr', component: Ev2020_60RrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } }, 
  { path: '/ev20204060frpr', component: Ev2020_4060FrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },  
  { path: '/ev20204060rrpr', component: Ev2020_4060RrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id, 33], currentUser: null } },  

  { path: '/searchPeriod', component: searchPeriod, meta: { allowedIds: [1, ...kId, ...poId, ...suId, 14, 33], currentUser: null } },  
  { path: '/report01', component: report01, meta: { allowedIds: [1, ...kId, ...poId, ...suId, 14, 17 ,18, 27, 33], currentUser: null } },
  { path: '/report02', component: report02, meta: { allowedIds: [1, ...kId, ...poId, 14, 17 ,18, 27, 33], currentUser: null } },
  { path: '/monthlyreport', component: monthlyReport, meta: { allowedIds: [1, ...kId, ...poId, 14, 17 ,18, 27, 33], currentUser: null } },
  { path: '/correctiveaction', component: correctiveAction, meta: { allowedIds: [1, ...kId, ...poId, 14, 17 ,18, 27, 33], currentUser: null } },  
  { path: '/investlist', component: investList, meta: { allowedIds: [1, ...kId, ...poId, 33 ], currentUser: null } },  
  { path: '/assetlist', component: assetList, meta: { allowedIds: [1, ...kId, ...poId, ], currentUser: null } },    
  { path: '/documentreport', component: documentReport, meta: { allowedIds: [1, ...kId, ...poId, ], currentUser: null } },  


  { path: '/shipping', component: shippingAllProject, meta: { allowedIds: [1,...kId,...poId, 11, 14 ,18, 33], currentUser: null } },  

  { path: '/targetList', component: targetList, meta: { allowedIds: [1,...kId,...poId, 14, 33], currentUser: null } },
  { path: '/excelupload', component: excelupload, meta: { allowedIds: [1,...kId,...poId, 33], currentUser: null } },
  { path: '/datadelete', component: datadelete, meta: { allowedIds: [1,...kId,...poId, 33], currentUser: null } },
  { path: '/scrap', component: scrap, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 33], currentUser: null } },
  { path: '/scraplog', component: scrapLog, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 33], currentUser: null } },
  
  { path: '/checktarget', component: checkTarget, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 33], currentUser: null } },  
  { path: '/tr3smtchecksheet', component: tr3SmtCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/tr3crimpingchecksheet', component: tr3CrimpingCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/tr3fpcachecksheet', component: tr3FpcaCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/evo1checksheet', component: evo1CheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/evo2checksheet', component: evo2CheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/evohybridchecksheet', component: evohybridCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/ev202060kwchecksheet', component: ev202060kwCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/ev20204060kwchecksheet', component: ev20204060kwCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/tr2smtchecksheet', component: tr2SmtCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/tr2crimpingchecksheet', component: tr2CrimpingCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/tr2fpcachecksheet', component: tr2FpcaCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/bmw12vchecksheet', component: bmw12vCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/bmw48vchecksheet', component: bmw48vCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/fcaflexbcchecksheet', component: fcaFlexBcCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/fca2checksheet', component: fca2CheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/ford2frontchecksheet', component: ford2FrontCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/ford2rearchecksheet', component: ford2RearCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/ford3frontchecksheet', component: ford3FrontCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },
  { path: '/ford3rearchecksheet', component: ford3RearCheckSheet, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32, 33], currentUser: null } },

  { path: '/cleaningcycle1st', component: cleaningcycle1st, meta: { allowedIds: [1,...kId,...poId, 33], currentUser: null } },
  { path: '/cleaningactual1st', component: cleaningactual1st, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },
  { path: '/cleaningcycle2nd', component: cleaningcycle2nd, meta: { allowedIds: [1,...kId,...poId, 33], currentUser: null } },
  { path: '/cleaningactual2nd', component: cleaningactual2nd, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },

  { path: '/takeover', component: takeOver, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32, 33], currentUser: null } },

  { path: '/eolConnectorPin', component: eolConnectorPin, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18, 33], currentUser: null } },
  { path: '/smtlaser', component: smtLaser, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18, 33], currentUser: null } },
  { path: '/bfaqrlaser', component: bfaQrLaser, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18, 33], currentUser: null } },
  { path: '/bfaweldlaser', component: bfaWeldLaser, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18, 33], currentUser: null } },
  { path: '/calibrationDate', component: calibrationDate, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 15, 16, 17, ...mId, 33], currentUser: null } },
  
  { path: '/inkatomSearch', component: inkatomSearch, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32, 33], currentUser: null } },
  { path: '/inkatomFirst', component: inkatomFirst, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32, 33], currentUser: null } },
  { path: '/inkatom45', component: inkatomFlex45, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32, 33], currentUser: null } },
  { path: '/inkatom67', component: inkatomFlex67, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32, 33], currentUser: null } },
  { path: '/inkatomNe', component: inkatomFlexNe, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32, 33], currentUser: null } },
  { path: '/inkatomPo', component: inkatomFlexPo, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32, 33], currentUser: null } },
  { path: '/inkatomSecond', component: inkatomSecond, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32, 33], currentUser: null } },
      
  { path: '/annualmaintenance', component: annualMaintenance, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancechecktarget', component: maintenanceCheckTarget, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancechecktr3smt', component: maintenanceCheckTr3Smt, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancechecktr3crimping', component: maintenanceCheckTr3Crimping, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancechecktr3fpca', component: maintenanceCheckTr3Fpca, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckevo1', component: maintenanceCheckEVO1, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckevo2', component: maintenanceCheckEVO2, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckevohybrid', component: maintenanceCheckEVOHybrid, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckev202060', component: maintenanceCheckEV202060, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckev20204060', component: maintenanceCheckEV20204060, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancechecktr2smt', component: maintenanceCheckTr2Smt, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancechecktr2crimping', component: maintenanceCheckTR2Crimping, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancechecktr2fpca', component: maintenanceCheckTR2Fpca, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckford2front', component: maintenanceCheckFord2Front, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckford2rear', component: maintenanceCheckFord2Rear, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckford3front', component: maintenanceCheckFord3Front, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckford3rear', component: maintenanceCheckFord3Rear, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckbmw48v', component: maintenanceCheckBMW48V, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckbmw12v', component: maintenanceCheckBMW12V, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/maintenancecheckfca2', component: maintenanceCheckFca2, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },

  { path: '/maintenanceworklist', component: maintenanceWorkList, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/failuretarget', component: failureTarget, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/failureclass', component: failureClass, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },
  { path: '/failurereport', component: failureReport, meta: { allowedIds: [1,...kId,...poId, ...mId, 33 ], currentUser: null } },  
  { path: '/spareList', component: spareList, meta: { allowedIds: [1,...kId,...poId, ...mId, 32, 33 ], currentUser: null } },  
  { path: '/equipmentledger', component: equipmentLedger, meta: { allowedIds: [1,...kId,...poId, ...mId, 32, 33 ], currentUser: null } },  
  { path: '/annualcalculation', component: annualCalculation, meta: { allowedIds: [1,...kId,...poId, ...mId, 32, 33 ], currentUser: null } },  
  { path: '/machinegrade', component: machineGrade, meta: { allowedIds: [1,...kId,...poId, ...mId, 32, 33 ], currentUser: null } },  
  { path: '/constructioninspection', component: constructionInspection, meta: { allowedIds: [1,], currentUser: null } },  

      

  { path: '/materialrequest', component: materialRequest, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 11, 12, 13, 14, 17 ,18, 33], currentUser: null } },  
  { path: '/requestlog', component: RequestLog, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 11, 12, 13, 14, 17 ,18, 33], currentUser: null } },  
  { path: '/materiallist', component: materialList, meta: { allowedIds: [1,...kId,...poId, ...suId, 11, 12, 13, 14 ,18, 33], currentUser: null } },  
    
  { path: '/stockList', component: stockList, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17, 18, 21, 22, 23, 24, 25, 26, 33], currentUser: null } },  
  { path: '/notnormalstock', component: notnormalstock, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26, 33], currentUser: null } },  
  { path: '/trayStock', component: trayStock, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18,21, 22, 23, 24, 25, 26, 33], currentUser: null } },  

  { path: '/barcodeprint', component: barcodePrint, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17, 18, 21, 22, 23, 24, 25, 26, 33], currentUser: null } },  
  { path: '/barcoderead', component: barcodeRead, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17, 18, 21, 22, 23, 24, 25, 26, 33 ], currentUser: null } },  

  { path: '/monthlyinventorycheck', component: monthlyinventorycheck, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26, 33], currentUser: null } },  
  { path: '/movementlog', component: movementlog, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26, 33], currentUser: null } },  
  { path: '/productionspare', component: productionSpare, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26, 32, 33], currentUser: null } },  
  
  { path: '/productionplan', component: productionPlan, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ,18, 21, 22, 23, 24, 25, 26, 27, 32, 33], currentUser: null } },  
  { path: '/monthlyschedule', component: monthlySchedule, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ,18, 21, 22, 23, 24, 25, 26, 27, 32, 33], currentUser: null } },  

  { path: '/production1stattendance', component: production1stAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33 ], currentUser: null } },    
  { path: '/production2ndattendance', component: production2ndAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33 ], currentUser: null } },    
  { path: '/qaattendance', component: qaAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33 ], currentUser: null } },  
  { path: '/warehouseattendance', component: warehouseAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33 ], currentUser: null } },  
  { path: '/inkatomattendance', component: inkatomAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33], currentUser: null } },  
  { path: '/attendancesearchall', component: searchAllAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33 ], currentUser: null } },    
  { path: '/weekendWork', component: weekendWork, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33], currentUser: null } },    
  { path: '/lsevpdb', component: lsevpDB, meta: { allowedIds: [1,...kId,...poId, ...mId, 14, 28, 31, 32, 33], currentUser: null } },    
  //{ path: '/lscpdb', component: lscpDB, meta: { allowedIds: [1,...kId, 28], currentUser: null } },    
  { path: '/maxpowerdb', component: maxPowerDB, meta: { allowedIds: [1,...kId,...poId, 8, 14, 28, 33], currentUser: null } },    
  { path: '/megastaffdb', component: megaStaffDB, meta: { allowedIds: [1,...kId,...poId, 9, 14, 28, 33], currentUser: null } },    
  { path: '/zxydb', component: zxyDB, meta: { allowedIds: [1,...kId,...poId, 10, 14, 28, 33], currentUser: null } },    
  { path: '/inkatomdb', component: inkatomDB, meta: { allowedIds: [1,...kId,...poId, 19, 14, 28, 33], currentUser: null } },    
  { path: '/monthlymaxpower', component: monthlyMaxPower, meta: { allowedIds: [1,...kId,...poId, 8, 14, 28, 33], currentUser: null } },    
  { path: '/monthlymegastaff', component: monthlyMegaStaff, meta: { allowedIds: [1,...kId,...poId, 9, 14, 28, 33], currentUser: null } },    
  { path: '/monthlyzyx', component: monthlyZYX, meta: { allowedIds: [1,...kId,...poId, 10, 14, 28, 33], currentUser: null } },    
  { path: '/monthlyinkatom', component: monthlyInkatom, meta: { allowedIds: [1,...kId,...poId, 19, 14, 28, 33], currentUser: null } },    
  { path: '/monthlylinecost', component: monthlyLineCost, meta: { allowedIds: [1,...kId,...poId, 14, 28, 33], currentUser: null } },    
  //{ path: '/lsattendance', component: lsAttendance, meta: { allowedIds: [1,...kId,...poId, 28, 29], currentUser: null } },    
  { path: '/lineworkerlist', component: lineWorkerList, meta: { allowedIds: [1,...kId,...poId, ...mId, 8, 9, 10, 14, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33], currentUser: null } },
  { path: '/evaluationline', component: evaluationLine, meta: { allowedIds: [1,...kId,...poId, ...mId, 8, 9, 10, 14, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33], currentUser: null } },
  { path: '/evaluationperson', component: evaluationPerson, meta: { allowedIds: [1,...kId,...poId, ...mId, 8, 9, 14, 10, 19, 21, 22, 23, 24, 25, 26, 28, 32, 33], currentUser: null } },
  { path: '/lslist', component: lsList, meta: { allowedIds: [1,...kId,...poId, 28, 33], currentUser: null } },    

  { path: '/dashboard', component: mainDashBoard, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 6, 7, 8, 9, 11, 14, 17 ,18, 21, 22, 23, 24, 25, 26, 27, 30, 32, 33], currentUser: null } },      
  { path: '/todolist', component: toDoList, meta: { allowedIds: [1,...kId,...poId, 33 ], currentUser: null } },    
  { path: '/purchaselist', component: purchaseList, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 11, 12, 13, 14, 15, 16, 17, 18, 28, 32, 33 ], currentUser: null } },    
  { path: '/purchaserequest', component: purchaseRequest, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 11, 12, 13, 14, 15, 16, 17, 18, 28, 32, 33 ], currentUser: null } },    
  { path: '/machinedata', component: machineData, meta: { allowedIds: [1, ...kId,...poId, 17, 32, 33], currentUser: null } }, 
  { path: '/productionclass', component: productionClass, meta: { allowedIds: [1, ...kId,...poId, 17, 32, 33 ], currentUser: null } }, 
  { path: '/selectoption', component: selectOption, meta: { allowedIds: [1, ...kId, ...poId, 17, 32, 33 ], currentUser: null } }, 
  { path: '/setting', component: setting, meta: { allowedIds: [1 ], currentUser: null } },    
  { path: '/arduino', component: arduino, meta: { allowedIds: [1, ...kId, ...poId], currentUser: null } },      
  { path: '/arduinolog', component: arduinoLog, meta: { allowedIds: [1, ...kId, ...poId], currentUser: null } }, 
  { path: '/inoutmaterial', component: inOutMaterial, meta: { allowedIds: [1 ], currentUser: null } },      
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function getUserID() {
  const token = Cookies.get('token'); 

  if (token) {
    try {
      const decodedToken = jwtDecode(token);      
      return decodedToken.id;
    } catch (error) {
      console.error('Error decoding JWT token:', error);     
    }
  }

  return null;
}

/*async function getCurrentUser() {
  try {
    const response = await axios.get('/api/accountGet');
    return response.data;
  } catch (error) {
    console.error('Error fetching current user:', error);
    return null;
  }
}*/

async function getCurrentUser() {
  try {
    const response = await axios.get('/api/accountGet');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // 401 오류를 무시      
      return null;
    } else {
      console.error('Error fetching current user:', error);
      return null;
    }
  }
}

router.beforeEach(async (to, from, next) => {  
  const userId = getUserID(); 
  const currentUser = await getCurrentUser();
  if (to.meta) {
    to.meta.currentUser = currentUser;
  }
  if (to.meta.allowedIds && !to.meta.allowedIds.includes(userId)) {  
    next('/error');
  } else {
    next();
  }

});

export default router;
