<template>
  <div class="checkTarget">      
    <hr>
    <div class="parent">
      <table class="searchTable"> 
        <tr>
          <td class="searchTd">
            <select id="line" v-model="formInputs.line">
              <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
              <option></option>              
            </select>  
          </td>
          <td class="searchTd">
            <button class="searchButton" type="submit" @click="callSearchData">Search</button>                        
          </td>
          <td class="searchTd">
            <div class="addbutton">        
              <button class="addButton" @click="showModal = true">+ Add</button>   
              <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
              <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
              <span class="fileName">{{ fileName }}</span>   
              <button class="excelbutton" @click="upExcel('maintenancechecktarget')">Upload</button>
              <button class="excelbutton" @click="downExcel">Down Load</button>                
              <button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="delAll(targetTableName, { line: formInputs.line })">ALL DEL</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-container">      
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.line_name">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Line name'" :key="index" >{{ row.line }}</td>
          <td v-if="header === 'Station Row'" :key="index" @click="editCell(targetTableName, row.id, 'station_row', $event)">{{ row.station_row }}</td>
          <td v-if="header === 'Station'" :key="index" @click="editCell(targetTableName, row.id, 'station', $event)">{{ row.station }}</td>
          <td v-if="header === 'Part'" :key="index" @click="editCell(targetTableName, row.id, 'part', $event)">{{ row.part }}</td>          
          <td v-if="header === 'Item'" :key="index" @click="editCell(targetTableName, row.id, 'item', $event)">{{ row.item }}</td>          
          <td v-if="header === 'Lower Limit'" :key="index" @click="editCell(targetTableName, row.id, 'lowerlimit', $event)">{{ row.lowerlimit }}</td>
          <td v-if="header === 'Standard Value'" :key="index" @click="editCell(targetTableName, row.id, 'standvalue', $event)">{{ row.standvalue }}</td>
          <td v-if="header === 'Upper Limit'" :key="index" @click="editCell(targetTableName, row.id, 'upperlimit', $event)">{{ row.upperlimit }}</td>
          <td v-if="header === 'Unit'" :key="index" @click="editCell(targetTableName, row.id, 'unit', $event)">{{ row.unit }}</td>
          <td v-if="header === 'Update Date'" :key="index" @click="editCell(targetTableName, row.id, 'updatedate', $event)">{{ row.updatedate }}</td>
          <td v-if="header === 'Acceptance Criteria'" :key="index" @click="editCell(targetTableName, row.id, 'acceptance', $event)">{{ row.acceptance }}</td>          
          <td class="instruction" v-if="header === 'Instruction'" :key="index" @click="editCell(targetTableName, row.id, 'instruction', $event)">{{ row.instruction }}</td>
          <td v-if="header === 'Cycle'" :key="index" @click="editCell(targetTableName, row.id, 'cycle', $event)">{{ row.cycle }}</td>
          <td v-if="header === 'Week'" :key="index" @click="editCell(targetTableName, row.id, 'week', $event)">{{ row.week }}</td>
          <td v-if="header === 'Cycle Year'" :key="index" @click="editCell(targetTableName, row.id, 'cycleyear', $event)">{{ row.cycleyear }}</td>
          <td v-if="header === 'Cycle Month'" :key="index" @click="editCell(targetTableName, row.id, 'cyclemonth', $event)">{{ row.cyclemonth }}</td>
          <td v-if="header === 'Cycle Day'" :key="index" @click="editCell(targetTableName, row.id, 'cycleday', $event)">{{ row.cycleday }}</td>
          <td v-if="header === 'Remark'" :key="index" @click="editCell(targetTableName, row.id, 'remark', $event)">{{ row.remark }}</td>  
          <td v-if="header === 'Row'" :key="index" @click="editCell(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
          <td v-if="header === 'Except'" :key="index" @click="editCell(targetTableName, row.id, 'except_row', $event)">{{ row.except_row }}</td>
        </template>   
      </tr>
    </tbody>     
    </table>
    </div>    
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
    </div>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import Modal from "./mainTargetModal.vue";
import axios from "axios";
import { onFileChange, uploadExcel, downloadExcel, editCellValue, deleteAll,} from "../../utils.js"; 

export default {         
    components: { Modal, }, 
    data() { return { excelFile: null, fileName: "", }; }, 

    methods: {
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.targetItem, "MaintenanceCheckTarget.xlsx"); },
      delAll(tableName, filters) { deleteAll(tableName, filters, this.callSearchData); },
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      }, 
      canClick() {            
        const currentUser = this.$route.meta.currentUser;            
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;}
        else { return false;}
      }, 
    },   

    setup() {   
        const targetTableName = "maintenancechecktarget";                    
        const target_headers = ['Line name', 'Station Row', 'Station', 'Part', 'Item', 'Lower Limit', 'Standard Value', 'Upper Limit', 'Unit', 'Update Date', 
                                'Acceptance Criteria', 'Instruction', 'Cycle', 'Week', 'Cycle Year', 'Cycle Month', 'Cycle Day', 'Remark', 'Row', 'Except'];
        const targetList = reactive({ data: [], })
        const formInputs = reactive({ line: "" });
        const uniqueLines = ref([]);  
        const showModal = ref(false);         

        const searchData = async () => {        

          const formData = {
            tableName: targetTableName,
            line: formInputs.line,
          };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => { targetList.data = res.data.sort((a, b) => a.row_no - b.row_no);})
            .catch((error) => { console.error("Error fetching target data:", error.message);});
          };

        const targetItem = computed(() => { return targetList.data.map(d => { return { ...d, }; }); });     
        
        const callSearchData = () => { searchData();};   

        const fetchLine = () => {
          const tableName = 'maintenancechecktarget';                   
          axios
            .get('/api/where2', { params: { table_name: tableName } })
            .then((res) => {
              if (res.data && res.data.length > 0) {
                const lines = res.data.map(item => item.line);
                uniqueLines.value = [...new Set(lines)].sort((a, b) => a.localeCompare(b));                
              } 
            })
            .catch((error) => {
              console.error('Error fetching target data:', error.message);
            });
        };   

        fetchLine();

        return { targetItem, targetList, target_headers, targetTableName, showModal, callSearchData,
                 formInputs, uniqueLines
        };        
    }
  }
</script>

<style scoped>
  .parent { display: flex; justify-content: space-between; }
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .searchTd { border: 0px !important;}   
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px; margin-bottom: 10px; }   
  .table-container { overflow-x: hidden; position: relative;}
  .table-header,.table-body { overflow: hidden; width: 100%; }
  .table-body { max-height: 350px; overflow-y: auto;}
  .table {width: 100%; max-width: 100%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed; font-size: 14px }
  .table th,.table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; text-align: center; word-wrap: break-word; white-space: normal;}
  .table thead th { position: sticky; top: 0; z-index: 100; background-color: #f8f9fa; font-weight: 600;
                    text-transform: uppercase; letter-spacing: 1px; font-size: 14px; vertical-align: bottom;
                    border-bottom: 2px solid #dee2e6;
  }
  .table tbody + tbody { border-top: 2px solid #dee2e6; }
  .table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075); }
  .addButton { padding: 5px 10px; background-color: #3498db; color: #fff; border: none;
               border-radius: 4px; font-size: 14px; font-weight: 600; margin-right: 30px; margin-left: 30px;
               margin-bottom: 10px; cursor: pointer; transition: background-color 0.3s;
  }
  .custom-file-upload {
    display: inline-block;  padding: 4px 12px;  cursor: pointer;
    background-color: #c49105;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }
  .custom-file-upload:hover { background-color: #f3a359; }
  .excelbutton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .excelbutton:hover { background-color: #009625; }
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; margin-bottom: 10px;
  }
  .delButton {
    padding: 4px 12px; background-color: #bfbebe; color: #000000; border: 10px; border-color: #000000;
    font-size: 14px; font-weight: 600; cursor: pointer; transition: background-color 0.3s; border-radius: 4px;
  }
  .disabledButton { background-color: gray; cursor: not-allowed; }

  .rownostandard { width: 50%; max-width: 50%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed;}
  .rownostandard th, .rownostandard td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #000000; text-align: center; }
  .rownostandard thead th { position: sticky; top: 0; z-index: 100; background-color: #f8f9fa;
    font-weight: 600; text-transform: uppercase; letter-spacing: 1px; font-size: 0.9rem; vertical-align: bottom;
    border-bottom: 2px solid #000000;
  }
  .rownostandard tbody + tbody { border-top: 2px solid #000000; }
  
</style>