<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Check Item and Target</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>
            <div class="form-group">   
              <label for="line">Line Name:</label>    
              <select type="text" id="line" v-model="formInputs.line" @change="updateModel()">
                <option>BMW 48V</option><option>BMW 12V</option><option>BT6</option><option>FCA 1</option>
                <option>FCA 2</option><option>FCA FLEX BC</option><option>FCA FLEX PO</option><option>FCA FLEX 67</option>
                <option>FCA FLEX 45</option><option>FCA FLEX NE</option><option>Ford 1 Front</option><option>Ford 1 Rear</option>
                <option>Ford 2 Front</option><option>Ford 2 Rear</option><option>Ford 2 Front</option><option>Ford 2 Rear</option>
                <option>TR2 SMT</option><option>TR2 Crimping</option><option>TR2 FPCA</option><option>TR2 BFA 2P</option><option>TR2 BFA 3P</option>
                <option>TR3 SMT</option><option>TR3 Crimping</option><option>TR3 FPCA</option><option>EVO 1</option><option>EVO 2</option>
                <option>EVO Hybrid</option><option>EV2020 4060kW</option><option>EV2020 60kW</option>
              </select>
            </div>
            <div class="form-group">
              <label for="process">Process:</label>
              <input type="text" id="process" v-model="formInputs.process" />
            </div>
            <div class="form-group">
              <label for="process_row">Process Row:</label>
              <input type="number" id="process_row" v-model="formInputs.process_row" />
            </div>
            <div class="form-group">
              <label for="model">Model:</label>                          
              <select type="text" id="model" v-model="formInputs.model">
                <option v-for="option in modelOptions" :key="option">{{ option }}</option>
              </select>
            </div>  
          </td>
          <td>
            <div class="form-group">
              <label for="item">Item:</label>
              <input type="text" id="item" v-model="formInputs.item" />
            </div>
            <div class="form-group">
              <label for="lowerlimit">Lower Limit:</label>
              <input type="text" id="lowerlimit" v-model="formInputs.lowerlimit" />
            </div>
            <div class="form-group">
              <label for="standardvalue">Standard Value:</label>
              <input type="text" id="standardvalue" v-model="formInputs.standardvalue" />
            </div>
            <div class="form-group">
              <label for="upperlimit">Upper Limit:</label>
              <input type="text" id="upperlimit" v-model="formInputs.upperlimit" />
            </div>
            <div class="form-group">
              <label for="unit">Unit:</label>
              <input type="text" id="unit" v-model="formInputs.unit" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="updatedate">Update Date(yyyy/mm/dd):</label>
              <input type="text" id="updatedate" v-model="formInputs.updatedate" />
            </div>
            <div class="form-group">
              <label for="acceptance">Acceptance:</label>
              <input type="text" id="acceptance" v-model="formInputs.acceptance" />
            </div>
            <div class="form-group">
              <label for="remark">Remark:</label>
              <input type="text" id="remark" v-model="formInputs.remark" />
            </div>
            <div class="form-group">
              <label for="row_no">Row No:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" />
            </div>
            </td>
            </tr>
            </table>
            <button type="submit" id="save">Save</button>         
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, ref, watch } from 'vue';  
  import axios from 'axios';   
   
  export default {                
    setup(props, { emit }) {   
    //setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "checksheettarget"      
        
        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          line: "", process: "", model: "", item: "",
          lowerlimit: "", standardvalue: "", upperlimit: "",
          unit: "", updatedate: "", acceptance: "", remark: "",
          process_row: "", row_no: "", except_row: "",
        });
        // -끝-   

        const modelOptions = ref([]); 

        const updateModel = () => {
          console.log("updateModel called, line:", formInputs.line);
            if (formInputs.line === 'BMW 48V') { modelOptions.value = ['GEN1.5',];}
            else if (formInputs.line === 'BMW 12V') { modelOptions.value = ['F40', 'HAF',]; }
            else if (formInputs.line === 'BT6') { modelOptions.value = ['Front', 'Rear',]; }
            else if (formInputs.line === 'FCA 1') { modelOptions.value = ['Front', 'Rear',]; }
            else if (formInputs.line === 'FCA 2') { modelOptions.value = ['Front', 'Rear',]; }
            else if (formInputs.line === 'FCA FLEX PO') { modelOptions.value = ['Positive',] ; }
            else if (formInputs.line === 'FCA FLEX NE') { modelOptions.value = ['Negative',] ; }
            else if (formInputs.line === 'FCA FLEX BC') { modelOptions.value = ['Busbar Carrier',] ; }
            else if (formInputs.line === 'FCA FLEX 45') { modelOptions.value = ['4-5+',] ; }
            else if (formInputs.line === 'FCA FLEX 67') { modelOptions.value = ['6+7-',] ; }
            else if (formInputs.line === 'EV2020 4060kW') { modelOptions.value = ['40kW','60kW'] ; }            
            else if (formInputs.line === 'EV2020 60kW') { modelOptions.value = ['60kW'] ; }            
            else if (formInputs.line === 'Ford 1 Front') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 1 Rear') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 2 Front') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 2 Rear') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 3 Front') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 3 Rear') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'TR2 SMT') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR2 Crimping') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR2 FPCA') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR2 BFA 2P') { modelOptions.value = ['2P12S', 'EVO_2P'] ; }
            else if (formInputs.line === 'TR2 BFA 3P') { modelOptions.value = ['3P8S', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR3 SMT') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR3 Crimping') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR3 FPCA') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR3 BFA 2P') { modelOptions.value = ['2P12S', 'EVO_2P',] ; }
            else if (formInputs.line === 'TR3 BFA 3P') { modelOptions.value = ['3P8S', 'EVO_3P'] ; }
            else if (formInputs.line === 'EVO 1') { modelOptions.value = ['EVO_3P'] ; }            
            else if (formInputs.line === 'EVO 2') { modelOptions.value = ['EVO_3P'] ; }            
            else if (formInputs.line === 'EVO 3') { modelOptions.value = ['EVO_3P'] ; }            
            else if (formInputs.line === 'EVO Hybrid') { modelOptions.value = ['EVO_2P', 'EVO_3P'] ; }

            else { modelOptions.value = ['Please select a line']; }

        };        

        watch(() => [formInputs.line, formInputs.model], updateModel);
 
        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!formInputs.line || !formInputs.model ) {
            alert("Please fill in all the fields: line, and model.");
            return;
          }

          const columnMapping = {
            line : 'line',
            process : 'process', 
            model : 'model',
            item : 'item',
            lowerlimit : 'lowerlimit',
            standardvalue : 'standardvalue',
            upperlimit : 'upperlimit',
            unit : 'unit',
            updatedate : 'updatedate',
            acceptance: 'acceptance',
            remark : 'remark',    
            process_row: 'process_row',
            row_no : 'row_no',
            except_row : 'except_row'
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              line : formInputs.line,
              process : formInputs.process, 
              model : formInputs.model,
              item : formInputs.item,
              lowerlimit : formInputs.lowerlimit,
              standardvalue : formInputs.standardvalue,
              upperlimit : formInputs.upperlimit,
              unit : formInputs.unit,
              updatedate : formInputs.updatedate,
              acceptance : formInputs.acceptance,
              remark : formInputs.remark,                       
              process_row : formInputs.process_row,
              row_no : formInputs.row_no,
              except_row : "",
            }
         };
         
          await axios.post('/api/post', formData);
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    
  
      return { dbTableName, formInputs, submitForm, closeModal, updateModel, modelOptions,};

    },     

    props: {
      onSubmit: {
        type: Function,
        required: true,        
      },      
     
    }, 


};
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex; justify-content: center; align-items: center;
    }
    
    .modal-wrapper { width: 100%;  max-width: 800px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
  </style>