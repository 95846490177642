<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <table>
            <tr>
              <td><h3>Add Inkatom Result</h3></td>
              <td><button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button></td>              
              <td>{{ saveStatus }}</td>              
            </tr>
          </table>  
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
              <label>Year/Month/Day:</label>
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="searchDate.year"  />
                <input type="text" id="month" v-model="searchDate.month" />
                <input type="text" id="day" v-model="searchDate.day" />                  
              </div> 
            </div>                                  
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>            
            <div class="form-group">
              <label for="start_time">Start Time:</label>              
              <select id="start_time" v-model="formInputs.start_time">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>      
            </div>
            <div class="form-group">
              <label for="end_time">End Time:</label>
              <select id="end_time" v-model="formInputs.end_time" readonly class="readWindow">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>
            </div>
            <div class="form-group">
              <label for="work_minute">Work Time(minute):</label>
              <select id="work_minute" v-model="formInputs.work_minute">            
                <option>5</option><option>15</option><option>30</option><option>45</option><option>60</option>    
              </select>                            
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option>2P12S</option><option>3P8S</option>
                <option>EVO_2P</option><option>EVO_3P</option>
                <option>EVO_MC25_3P</option>
              </select>              
            </div>
            <div class="form-group">
              <label for="target">Target:</label>
              <input type="number" id="target" v-model="formInputs.target" readonly class="readWindow" />
            </div>                
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div> 
            <div class="form-group">
              <label for="ok">Verification:</label>
              <input type="number" id="verification" v-model="formInputs.verification" />
            </div> 
          </td>
          <td>
            <div class="form-group">
              <label for="ng">NG:</label>
              <input type="number" id="ng" v-model="formInputs.ng" />
            </div>            
            <div class="form-group">
              <label for="ng_heatstacking">NG HEAT STACKING:</label>
              <input type="number" id="ng_heatstacking" v-model="formInputs.ng_heatstacking"  />
            </div>
            <div class="form-group">
              <label for="ng_busbardamage">NG BUSBAR DAMAGE:</label>
              <input type="number" id="ng_busbardamage" v-model="formInputs.ng_busbardamage" />
            </div>
            <div class="form-group">
              <label for="ng_fpcbdamage">NG FPCB DAMAGE:</label>
              <input type="number" id="ng_fpcbdamage" v-model="formInputs.ng_fpcbdamage"  />
            </div>
            <div class="form-group">
              <label for="ng_weldlineposition">NG WELD LINE POSITION:</label>
              <input type="number" id="ng_weldlineposition" v-model="formInputs.ng_weldlineposition" />
            </div>
            <div class="form-group">
              <label for="ng_weldingng">NG WELDING NG:</label>
              <input type="number" id="ng_weldingng" v-model="formInputs.ng_weldingng" />
            </div>
            <div class="form-group">
              <label for="ng_finalslot">NG FINAL SLOT:</label>
              <input type="number" id="ng_finalslot" v-model="formInputs.ng_finalslot"  />
            </div>
            <div class="form-group">
              <label for="ng_framedamage">NG FRAME DAMAGE:</label>
              <input type="number" id="ng_framedamage" v-model="formInputs.ng_framedamage" />
            </div>
            <div class="form-group">
              <label for="ng_eolreal">NG EOL REAL:</label>
              <input type="number" id="ng_eolreal" v-model="formInputs.ng_eolreal" />
            </div>
            <div class="form-group">
              <label for="ng_coating">NG COATING:</label>
              <input type="number" id="ng_coating" v-model="formInputs.ng_coating" />
            </div>
            <div class="form-group">
              <label for="ng_coverdamage">NG COVER DAMAGE:</label>
              <input type="number" id="ng_coverdamage" v-model="formInputs.ng_coverdamage" />
            </div>
            <div class="form-group">
              <label for="ng_etc">NG ETC:</label>
              <input type="number" id="ng_etc" v-model="formInputs.ng_etc" />
            </div>
          </td>         
          
          <td>            
            <div class="form-group">
              <label for="workorder">Work Order:</label>
              <input type="text" id="workorder" v-model="formInputs.workorder"  />
            </div>            
            <div class="form-group">
              <label for="leader">Leader:</label>
              <input type="text" id="leader" v-model="formInputs.leader" />
            </div>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="remark">Remark:</label>
              <textarea id="remark" v-model="formInputs.remark" rows="4" cols="25"></textarea>              
            </div>                         
            <div class="form-group">
              <label for="row_no">Row_no:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" readonly class="readWindow" />
            </div>     
          </td>       
        </tr>
           </table>           
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                     
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref } from 'vue';  
  import axios from 'axios';  

  export default {    
    data() {
      return { //드랍다운 박스 별로 여기에 값을 넣어줘야 한다.
        dropdownsVisible: {
        bendingng: false, semi: false
        },
      };
    },
    methods: {
      toggleDropdown(dropdown) {
        for (const key in this.dropdownsVisible) {
          this.dropdownsVisible[key] = key === dropdown ? !this.dropdownsVisible[key] : false;
        }
    },
    selectOption(option) {
        console.log("Selected option:", option);
        this.dropdownVisible = false;
      },
    },
    setup(_, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "inkatom2nd"  
        const saveStatus = ref(''); 
        const isProcessing = ref(false);        

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          shift: "",
          start_time: "",
          end_time: "",
          work_minute: "",
          model: "",
          target: "0",        
          ok: "",      
          verification: "",            
          ng: "0",
          ng_heatstacking: "0",
          ng_busbardamage: "0",
          ng_fpcbdamage: "0",
          ng_weldlineposition: "0",
          ng_weldingng: "0",
          ng_finalslot: "0",
          ng_framedamage: "0",
          ng_eolreal: "0",
          ng_coating: "0",
          ng_coverdamage: "0",          
          ng_etc: "0",                    
          workorder: "",          
          leader: "",    
          remark: "",
          row_no: "",          
        });
        // -끝-

        //시작시간에 따라서 shift와 row_no 자동 입력 -시작-
        watch(() => formInputs.start_time, (newValue) => {
          const start_time_num = parseInt(newValue, 10);

          if (start_time_num >= 6 && start_time_num <= 13) {
            formInputs.shift = 1; // 오전 6시부터 오후 1시까지는 교대 1
          } else if (start_time_num >= 14 && start_time_num <= 21) {
            formInputs.shift = 2; // 오후 2시부터 오후 9시까지는 교대 2
          } else {
            formInputs.shift = 3; // 그 외 시간(오후 10시부터 오전 5시)은 교대 3
          }

          if (start_time_num >= 6 && start_time_num <= 24) {
            formInputs.row_no = start_time_num - 5;
          } else if (start_time_num >= 1 && start_time_num <= 5) {
            formInputs.row_no = start_time_num + 19;
          }
        });
        //끝

        //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -시작- 
        
        const targetProduct = reactive({
            data: [],
        })

        const targetTableName = 'target';
        const columnName = 'line_name';
        axios
          .get('/api/where', { params: { value: 'Inkatom', table_name: targetTableName, column_name: columnName } })
          .then((res) => {
            console.log('Response from /api/where:', res.data);
            targetProduct.data = res.data;
          })
          .catch((error) => {
            console.error('Error fetching target data:', error.message);
          });

        const productValue = computed(() => targetProduct.data[0]?.product || null);

        watch(
          [productValue, () => formInputs.work_minute],
          ([currentProductValue, currentWorkMinute]) => {
            if (currentProductValue && currentWorkMinute) {
              formInputs.target = parseFloat((currentProductValue * currentWorkMinute).toFixed(0));
            }
          }
        );        
                
       //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -끝- 
      
      //년.월.일을 지금 시작으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
         const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝          

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
            alert("Please fill in all the fields: year, month, day and model.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            shift: 'shift',
            start_time: 'start_time',
            end_time: 'end_time',
            work_minute: 'work_minute',
            model: 'model',
            target: 'target',
            ok: 'ok',    
            verification: 'verification',        
            ng: 'ng',
            ng_heatstacking: 'ng_heatstacking',
            ng_busbardamage: 'ng_busbardamage',
            ng_fpcbdamage: 'ng_fpcbdamage',
            ng_weldlineposition: 'ng_weldlineposition',
            ng_weldingng: 'ng_weldingng',
            ng_finalslot: 'ng_finalslot',
            ng_framedamage: 'ng_framedamage',
            ng_eolreal: 'ng_eolreal',
            ng_coating: 'ng_coating',
            ng_coverdamage: 'ng_coverdamage',            
            ng_etc: 'ng_etc',                        
            workorder: 'workorder',            
            leader: 'leader',            
            remark: 'remark',       
            row_no: 'row_no',
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              shift: formInputs.shift,
              start_time: formInputs.start_time,
              end_time: formInputs.end_time,
              work_minute: formInputs.work_minute,
              model: formInputs.model,
              target: formInputs.target,        
              ok: formInputs.ok,   
              verification : formInputs.verification,               
              ng: formInputs.ng,
              ng_heatstacking: formInputs.ng_heatstacking,
              ng_busbardamage: formInputs.ng_busbardamage,
              ng_fpcbdamage: formInputs.ng_fpcbdamage,
              ng_weldlineposition: formInputs.ng_weldlineposition,
              ng_weldingng: formInputs.ng_weldingng,
              ng_finalslot: formInputs.ng_finalslot,
              ng_framedamage: formInputs.ng_framedamage,
              ng_eolreal: formInputs.ng_eolreal,
              ng_coating: formInputs.ng_coating,
              ng_coverdamage: formInputs.ng_coverdamage,              
              ng_etc: formInputs.ng_etc,              
              workorder: formInputs.workorder,              
              leader: formInputs.leader,              
              remark: formInputs.remark,   
              row_no: formInputs.row_no,    
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-            

      watch(
        () => formInputs.start_time,
        (newStartTime) => {
          const startTime = parseInt(newStartTime, 10);
          if (startTime < 24) {
            formInputs.end_time = (startTime + 1).toString().padStart(2, '0');
          } else {
            formInputs.end_time = '01';
          }
        }
      );      

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, targetProduct, productValue,
              targetTableName, columnName, saveStatus, isProcessing };

    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      // currentDate: Object,
    },    
  };   
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex; justify-content: center; align-items: center;
    }
    
    .modal-wrapper { width: 100%;  max-width: 1000px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
    .disabled { background-color: #ccc; cursor: not-allowed; }
  </style>