<template>
    <div class="bmw12v">            
      <div class="act">               
         <h class="pagetitle">BMW 12V</h>
         <div class="modalButtonContainer">
         <button class="button" @click="showModal = true">+ Result</button>
         <button class="button" @click="showdtModal = true">+ Downtime</button>
         <button class="button" @click="showdrModal = true">+ Daily Report</button>
         <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>      
         <button class="button" @click="showrdModal = true">+ Repeat Data</button> 
        </div>
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>     
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-F4012V': row.model === 'F40 12V', 'model-HAF': row.model === 'HAF'}" @click="editCellValue(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCellValue(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Lack of Components'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lackofcomponents === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lackofcomponents', $event)">{{ row.ng_lackofcomponents }}</td>
                <td v-if="header === 'Coating too much'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingtoomuch === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingtoomuch', $event)">{{ row.ng_coatingtoomuch }}</td>
                <td v-if="header === 'Coating too less'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingtooless === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingtooless', $event)">{{ row.ng_coatingtooless }}</td>                
                <td v-if="header === 'Coating bubbles'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingbubbles === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_coatingbubbles', $event)">{{ row.ng_coatingbubbles }}</td>
                <td v-if="header === 'Soldering too much'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_solderingtoomuch === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_solderingtoomuch', $event)">{{ row.ng_solderingtoomuch }}</td>
                <td v-if="header === 'Soldering too less'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_solderingtooless === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_solderingtooless', $event)">{{ row.ng_solderingtooless }}</td>
                <td v-if="header === 'Soldering balls'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_solderingballs === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_solderingballs', $event)">{{ row.ng_solderingballs }}</td>
                <td v-if="header === 'Lack of barcode'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lackofbarcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_lackofbarcode', $event)">{{ row.ng_lackofbarcode }}</td>
                <td v-if="header === 'Damage barcode'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagebarcode === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagebarcode', $event)">{{ row.ng_damagebarcode }}</td>
                <td v-if="header === 'Busbar scraches'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_busbarscraches === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_busbarscraches', $event)">{{ row.ng_busbarscraches }}</td>
                <td v-if="header === 'Dirty busbar'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_dirtybusbar === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_dirtybusbar', $event)">{{ row.ng_dirtybusbar }}</td>
                <td v-if="header === 'Damage PCB'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagepcb === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagepcb', $event)">{{ row.ng_damagepcb }}</td>
                <td v-if="header === 'Dirty housing'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_dirtyhousing === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_dirtyhousing', $event)">{{ row.ng_dirtyhousing }}</td>
                <td v-if="header === 'Damage housing'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagehousing === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_damagehousing', $event)">{{ row.ng_damagehousing }}</td>
                <td v-if="header === 'EOL'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eol === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_eol', $event)">{{ row.ng_eol }}</td>
                <td v-if="header === 'OQC'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_oqc === 0 }" @click="editCellValue(dbTableName, row.id, 'ng_oqc', $event)">{{ row.ng_oqc }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> <!--need to make cal-->
                <td v-if="header === 'Fake Soldering'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_soldering === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_soldering', $event)">{{ row.fn_soldering }}</td>
                <td v-if="header === 'Fake Coating'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_coating === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_coating', $event)">{{ row.fn_coating }}</td>
                <td v-if="header === 'Fake EOL'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_eol === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_eol', $event)">{{ row.fn_eol }}</td>
                <td v-if="header === 'Fake Vision'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_vision === 0 }" @click="editCellValue(dbTableName, row.id, 'fn_vision', $event)">{{ row.fn_vision }}</td>                                
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> <!--need to make cal-->
                <td v-if="header === 'Remark'" :key="index" @click="editCellValue(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCellValue(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCellValue(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCellValue(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCellValue(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCellValue(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Product code'" :key="index" @click="editCellValue(dbTableName, row.id, 'productcode', $event)">{{ row.productcode }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_lackofcomponents }}</td>
          <td>{{ sums.shiftSums[1].ng_coatingtoomuch }}</td>
          <td>{{ sums.shiftSums[1].ng_coatingtooless }}</td>
          <td>{{ sums.shiftSums[1].ng_coatingbubbles }}</td>
          <td>{{ sums.shiftSums[1].ng_solderingtoomuch }}</td>          
          <td>{{ sums.shiftSums[1].ng_solderingtooless }}</td>          
          <td>{{ sums.shiftSums[1].ng_solderingballs }}</td>
          <td>{{ sums.shiftSums[1].ng_lackofbarcode }}</td>
          <td>{{ sums.shiftSums[1].ng_damagebarcode }}</td>
          <td>{{ sums.shiftSums[1].ng_busbarscraches }}</td>
          <td>{{ sums.shiftSums[1].ng_dirtybusbar }}</td>
          <td>{{ sums.shiftSums[1].ng_damagepcb }}</td>
          <td>{{ sums.shiftSums[1].ng_dirtyhousing }}</td>
          <td>{{ sums.shiftSums[1].ng_damagehousing }}</td>
          <td>{{ sums.shiftSums[1].ng_eol }}</td>
          <td>{{ sums.shiftSums[1].ng_oqc }}</td>       
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol }}</td>
          <td>{{ sums.shiftSums[1].fn_soldering }}</td>
          <td>{{ sums.shiftSums[1].fn_coating }}</td>
          <td>{{ sums.shiftSums[1].fn_eol }}</td>
          <td>{{ sums.shiftSums[1].fn_vision }}</td>                    
          <td>{{ ((sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_lackofcomponents }}</td>
          <td>{{ sums.shiftSums[2].ng_coatingtoomuch }}</td>
          <td>{{ sums.shiftSums[2].ng_coatingtooless }}</td>
          <td>{{ sums.shiftSums[2].ng_coatingbubbles }}</td>
          <td>{{ sums.shiftSums[2].ng_solderingtoomuch }}</td>          
          <td>{{ sums.shiftSums[2].ng_solderingtooless }}</td>          
          <td>{{ sums.shiftSums[2].ng_solderingballs }}</td>
          <td>{{ sums.shiftSums[2].ng_lackofbarcode }}</td>
          <td>{{ sums.shiftSums[2].ng_damagebarcode }}</td>
          <td>{{ sums.shiftSums[2].ng_busbarscraches }}</td>
          <td>{{ sums.shiftSums[2].ng_dirtybusbar }}</td>
          <td>{{ sums.shiftSums[2].ng_damagepcb }}</td>
          <td>{{ sums.shiftSums[2].ng_dirtyhousing }}</td>
          <td>{{ sums.shiftSums[2].ng_damagehousing }}</td>
          <td>{{ sums.shiftSums[2].ng_eol }}</td>
          <td>{{ sums.shiftSums[2].ng_oqc }}</td>       
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol }}</td>
          <td>{{ sums.shiftSums[2].fn_soldering }}</td>
          <td>{{ sums.shiftSums[2].fn_coating }}</td>
          <td>{{ sums.shiftSums[2].fn_eol }}</td>
          <td>{{ sums.shiftSums[2].fn_vision }}</td>                    
          <td>{{ ((sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th rowspan="2">Model</th>
          <td>F40 12V</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['F40 12V'].target }}</td>
          <td>{{ sums.modelSums['F40 12V'].input }}</td>
          <td>{{ sums.modelSums['F40 12V'].ok - sums.qwmodelSums['F40 12V'].ng}}</td>
          <td>{{ sums.modelSums['F40 12V'].ng + sums.qwmodelSums['F40 12V'].ng }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td> <!-- obj.ng_lackofcomponents 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>          
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>
          <td>{{ ((sums.modelSums['F40 12V'].ng + sums.qwmodelSums['F40 12V'].ng) / (sums.modelSums['F40 12V'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['F40 12V'].fn_soldering + sums.modelSums['F40 12V'].fn_coating + sums.modelSums['F40 12V'].fn_vision + sums.modelSums['F40 12V'].fn_eol }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_soldering }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_coating }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_eol }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_vision }}</td>                    
          <td>{{ ((sums.modelSums['F40 12V'].fn_soldering + sums.modelSums['F40 12V'].fn_coating + sums.modelSums['F40 12V'].fn_vision + sums.modelSums['F40 12V'].fn_eol)/(sums.modelSums['F40 12V'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>HAF</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['HAF'].target }}</td>
          <td>{{ sums.modelSums['HAF'].input }}</td>
          <td>{{ sums.modelSums['HAF'].ok - sums.qwmodelSums['HAF'].ng}}</td>
          <td>{{ sums.modelSums['HAF'].ng + sums.qwmodelSums['HAF'].ng }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td> <!-- obj.ng_lackofcomponents 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>          
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>
          <td>{{ ((sums.modelSums['HAF'].ng + sums.qwmodelSums['HAF'].ng) / (sums.modelSums['HAF'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['HAF'].fn_soldering + sums.modelSums['HAF'].fn_coating + sums.modelSums['HAF'].fn_vision + sums.modelSums['HAF'].fn_eol }}</td>
          <td>{{ sums.modelSums['HAF'].fn_soldering }}</td>
          <td>{{ sums.modelSums['HAF'].fn_coating }}</td>
          <td>{{ sums.modelSums['HAF'].fn_eol }}</td>
          <td>{{ sums.modelSums['HAF'].fn_vision }}</td>                    
          <td>{{ ((sums.modelSums['HAF'].fn_soldering + sums.modelSums['HAF'].fn_coating + sums.modelSums['HAF'].fn_vision + sums.modelSums['HAF'].fn_eol)/(sums.modelSums['HAF'].input) * 100).toFixed(1) }}%</td>
        </tr>        
        <tr>
         <th colspan="3">BMW 12V Total</th>         
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok + sums.shiftSums[2].ok - sums.qwmodelSums['F40 12V'].ng - sums.qwmodelSums['HAF'].ng }}</td>
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['F40 12V'].ng + sums.qwmodelSums['HAF'].ng }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td> <!-- obj.ng_lackofcomponents 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>           
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>          
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['F40 12V'].ng + sums.qwmodelSums['HAF'].ng ) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol }}</td>
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_soldering, 0) }}</td>               
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_coating, 0) }}</td>               
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_eol, 0) }}</td>               
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_vision, 0) }}</td>                    
          <td class="fn">{{ ((sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol)/(sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader --> 
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCellValue(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index" ></td>
            <td v-if="header === 'Input'" :key="index" ></td>
            <td v-if="header === 'OK'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Lack of Components'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_lackofcomponents', $event)">{{ qw.ng_lackofcomponents }}</td>
            <td v-if="header === 'Coating too much'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_coatingtoomuch', $event)">{{ qw.ng_coatingtoomuch }}</td>
            <td v-if="header === 'Coating too much'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_coatingtooless', $event)">{{ qw.ng_coatingtooless }}</td>
            <td v-if="header === 'Coating bubbles'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_coatingbubbles', $event)">{{ qw.ng_coatingbubbles }}</td>
            <td v-if="header === 'Soldering too much'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_solderingtoomuch', $event)">{{ qw.ng_solderingtoomuch }}</td>            
            <td v-if="header === 'Soldering too less'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_solderingtooless', $event)">{{ qw.ng_solderingtooless }}</td>
            <td v-if="header === 'Soldering balls'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_solderingballs', $event)">{{ qw.ng_solderingballs }}</td>
            <td v-if="header === 'Lack of barcode'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_lackofbarcode', $event)">{{ qw.ng_lackofbarcode }}</td>
            <td v-if="header === 'Damage barcode'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_damagebarcode', $event)">{{ qw.ng_damagebarcode }}</td>
            <td v-if="header === 'Busbar scraches'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_busbarscraches', $event)">{{ qw.ng_busbarscraches }}</td>
            <td v-if="header === 'Dirty busbar'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_dirtybusbar', $event)">{{ qw.ng_dirtybusbar }}</td>
            <td v-if="header === 'Damage PCB'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_damagepcb', $event)">{{ qw.ng_damagepcb }}</td>
            <td v-if="header === 'Dirty housing'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_dirtyhousing', $event)">{{ qw.ng_dirtyhousing }}</td>
            <td v-if="header === 'Damage housing'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_damagehousing', $event)">{{ qw.ng_damagehousing }}</td>
            <td v-if="header === 'EOL'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_eol', $event)">{{ qw.ng_eol }}</td>
            <td v-if="header === 'OQC'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng_oqc', $event)">{{ qw.ng_oqc }}</td>
          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCellValue(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>      
      <dtModal  v-if="showdtModal" @onClose="showdtModal = false; callSearchData();"/>         
      <drModal  v-if="showdrModal" @onClose="showdrModal = false; callSearchData();"/> 
      <qwModal  v-if="showqwModal" @onClose="showqwModal = false; callSearchData();"/> 
      <rdModal  v-if="showrdModal" @onClose="showrdModal = false; callSearchData();"/> 
  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./bmw12v_prm.vue";
import dtModal from "./bmw12v_prdtm.vue";
import drModal from "./bmw12v_prdrm.vue";
import qwModal from "./bmw12v_qwm.vue";
import rdModal from "./bmw12v_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },    

    setup() {
        const lineName = "bmw12v"  //설비 변경시 필수 교체        
        const dbTableName = "bmw12vpr";  //searchData에 있는 값도 바꿔야 함 
        const dtTableName = "bmw12vdt";  //searchData에 있는 값도 바꿔야 함
        const drTableName = "bmw12vdr";  //searchData에 있는 값도 바꿔야 함
        const qwTableName = "bmw12vqw";  //searchData에 있는 값도 바꿔야 함
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'NG', "Lack of Components", "Coating too much",
                         "Coating too less", 'Coating bubbles', 'Soldering too much', 'Soldering too less', 'Soldering balls',
                         'Lack of barcode', 'Damage barcode', 'Busbar scraches', 'Dirty busbar', 'Damage PCB', 'Dirty housing',
                         'Damage housing', 'EOL', 'OQC', 'NG Rate', 'Fake Sum', 'Fake Soldering', 'Fake Coating',
                         'Fake EOL', 'Fake Vision', 'Fake NG Rate', 'Remark', 'Work Order', 'Supervisor', 
                         'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Product code', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], });   

        //Repeat Data part
        const showrdModal = ref(false);  
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, fn_soldering, fn_coating, fn_vision, fn_eol) => {
          const input = ok + ng;
          const fakeng = fn_soldering + fn_coating + fn_vision + fn_eol 
          let ngRate = 0;
          let fn_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          return { input, ngRate, fakeng, fn_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(d.ok, d.ng, d.fn_soldering, d.fn_coating, d.fn_vision, d.fn_eol, );
            return { ...d, input, ngRate, fakeng, fn_ngRate, }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
            console.log('Requesting data with:', {
              tablename: tableName,
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
            });

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };

            prTableData.data = [];
            dtTableData.data = [];
            drTableData.data = [];
            qwTableData.data = [];

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'bmw12vpr':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                  case 'bmw12vdt':
                    dtTableData.data = res.data;
                    break;
                  case 'bmw12vdr':
                    drTableData.data = res.data;
                    break;
                  case 'bmw12vqw':
                    qwTableData.data = res.data;
                    break;
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName);
            searchData(dtTableName);
            searchData(drTableName);
            searchData(qwTableName);
            settingCall();
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let shiftSums = {
            1: { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, 
                  ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, 
                  ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,
                  fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0  }, 
          
            2: { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, 
                  ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, 
                  ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,
                  fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0 }, 

          };

          let modelSums = {
            "F40 12V": { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, 
                  ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, 
                  ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,
                  fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0  }, 
             
            "HAF": { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, 
                  ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, 
                  ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,
                  fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0 },                       
          };

          let qwmodelSums = {
            "F40 12V": { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, 
                  ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, 
                  ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,
                  },
             
            "HAF": { target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, 
                  ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, 
                  ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,
                  },
          };

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].ok += row.ok;
              shiftSums[row.shift].ng += row.ng;    
              shiftSums[row.shift].ng_lackofcomponents += row.ng_lackofcomponents;
              shiftSums[row.shift].ng_coatingtoomuch += row.ng_coatingtoomuch;
              shiftSums[row.shift].ng_coatingtooless += row.ng_coatingtooless;
              shiftSums[row.shift].ng_coatingbubbles += row.ng_coatingbubbles;
              shiftSums[row.shift].ng_solderingtoomuch += row.ng_solderingtoomuch;
              shiftSums[row.shift].ng_solderingtooless += row.ng_solderingtooless;
              shiftSums[row.shift].ng_solderingballs += row.ng_solderingballs;
              shiftSums[row.shift].ng_lackofbarcode += row.ng_lackofbarcode;
              shiftSums[row.shift].ng_damagebarcode += row.ng_damagebarcode;
              shiftSums[row.shift].ng_busbarscraches += row.ng_busbarscraches;
              shiftSums[row.shift].ng_dirtybusbar += row.ng_dirtybusbar;
              shiftSums[row.shift].ng_damagepcb += row.ng_damagepcb;
              shiftSums[row.shift].ng_dirtyhousing += row.ng_dirtyhousing;
              shiftSums[row.shift].ng_damagehousing += row.ng_damagehousing;
              shiftSums[row.shift].ng_eol += row.ng_eol;
              shiftSums[row.shift].ng_oqc += row.ng_oqc;              
              shiftSums[row.shift].fn_soldering += row.fn_soldering;
              shiftSums[row.shift].fn_coating += row.fn_coating;
              shiftSums[row.shift].fn_eol += row.fn_eol;               
              shiftSums[row.shift].fn_vision += row.fn_vision;                             
              }

            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
              modelSums[row.model].target += row.target;
              modelSums[row.model].input += row.input;
              modelSums[row.model].ok += row.ok;
              modelSums[row.model].ng += row.ng;    
              modelSums[row.model].ng_lackofcomponents += row.ng_lackofcomponents;
              modelSums[row.model].ng_coatingtoomuch += row.ng_coatingtoomuch;
              modelSums[row.model].ng_coatingtooless += row.ng_coatingtooless;
              modelSums[row.model].ng_coatingbubbles += row.ng_coatingbubbles;
              modelSums[row.model].ng_solderingtoomuch += row.ng_solderingtoomuch;
              modelSums[row.model].ng_solderingtooless += row.ng_solderingtooless;
              modelSums[row.model].ng_solderingballs += row.ng_solderingballs;
              modelSums[row.model].ng_lackofbarcode += row.ng_lackofbarcode;
              modelSums[row.model].ng_damagebarcode += row.ng_damagebarcode;
              modelSums[row.model].ng_busbarscraches += row.ng_busbarscraches;
              modelSums[row.model].ng_dirtybusbar += row.ng_dirtybusbar;
              modelSums[row.model].ng_damagepcb += row.ng_damagepcb;
              modelSums[row.model].ng_dirtyhousing += row.ng_dirtyhousing;
              modelSums[row.model].ng_damagehousing += row.ng_damagehousing;
              modelSums[row.model].ng_eol += row.ng_eol;
              modelSums[row.model].ng_oqc += row.ng_oqc;              
              modelSums[row.model].fn_soldering += row.fn_soldering;
              modelSums[row.model].fn_coating += row.fn_coating;
              modelSums[row.model].fn_eol += row.fn_eol;               
              modelSums[row.model].fn_vision += row.fn_vision;              
            }
          }

          for (const row of qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
              qwmodelSums[row.model].ok += row.ok;
              qwmodelSums[row.model].ng += row.ng;    
              qwmodelSums[row.model].ng_lackofcomponents += row.ng_lackofcomponents;
              qwmodelSums[row.model].ng_coatingtoomuch += row.ng_coatingtoomuch;
              qwmodelSums[row.model].ng_coatingtooless += row.ng_coatingtooless;
              qwmodelSums[row.model].ng_coatingbubbles += row.ng_coatingbubbles;
              qwmodelSums[row.model].ng_solderingtoomuch += row.ng_solderingtoomuch;
              qwmodelSums[row.model].ng_solderingtooless += row.ng_solderingtooless;
              qwmodelSums[row.model].ng_solderingballs += row.ng_solderingballs;
              qwmodelSums[row.model].ng_lackofbarcode += row.ng_lackofbarcode;
              qwmodelSums[row.model].ng_damagebarcode += row.ng_damagebarcode;
              qwmodelSums[row.model].ng_busbarscraches += row.ng_busbarscraches;
              qwmodelSums[row.model].ng_dirtybusbar += row.ng_dirtybusbar;
              qwmodelSums[row.model].ng_damagepcb += row.ng_damagepcb;
              qwmodelSums[row.model].ng_dirtyhousing += row.ng_dirtyhousing;
              qwmodelSums[row.model].ng_damagehousing += row.ng_damagehousing;
              qwmodelSums[row.model].ng_eol += row.ng_eol;
              qwmodelSums[row.model].ng_oqc += row.ng_oqc;        
            }
          }     

          return { shiftSums, modelSums, qwmodelSums };
        });

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error fetching target data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });
          const wsDt = XLSX.utils.json_to_sheet(dtTableData.data, { header: dtHeaders.value });
          const wsDr = XLSX.utils.json_to_sheet(drTableData.data, { header: drHeaders.value });
          const wsQw = XLSX.utils.json_to_sheet(qwTableData.data, { header: headers.value });

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "PR Data");
          XLSX.utils.book_append_sheet(wb, wsDt, "DT Data");
          XLSX.utils.book_append_sheet(wb, wsDr, "DR Data");
          XLSX.utils.book_append_sheet(wb, wsQw, "QW Data");

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "data.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}

.modalButtonContainer {
  display: inline-flex;
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .qwallButton {
    padding: 5px 5px;
    background-color: #00eb91;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, .qwallButton:hover, 
.button:hover {
    background-color: #2980b9;
  }

  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input
  {
  width : 80px;  
  margin-right: 5px;
  padding : 5px;
 }

label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}

#dailyReport td {
  text-align: left;
}

.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
}

.model-F4012V {
  background-color: #a0eefc; /* Adjust the color as needed for 2P12S */
}

.model-HAF {
  background-color: #9887e4; /* Adjust the color as needed for 3P8S */
}

.ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.zero-value_ng {
  background-color: #fa7979; 
  color: #fa7979; 
}

.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.zero-value_ngtype {
  background-color: #fdb08d; 
  color: #fdb08d;
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.zero-value_fn {
  background-color: #ff72d5; 
  color: #ff72d5; 
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}

.zero-value_fntype {
  background-color: #ff9bcd; 
  color: #ff9bcd; 
}

.qwall {
  background-color: #66b179;  
}

.shift1 { background-color: rgb(174, 240, 147); }
.shift2 { background-color: rgba(248, 250, 145, 0.979); }

</style>