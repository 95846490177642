<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Corrective Action Modify</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
            <tr>
            <td>
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="formInputs.year"  />
                  <input type="text" id="month" v-model="formInputs.month" />
                  <input type="text" id="day" v-model="formInputs.day" />                  
                </div> 
            </div>     
            <div class="form-group">
              <label for="type">Team:</label>
              <div>
                <select type="text" id="team" v-model="formInputs.team">
                  <option>Production 1st</option><option>Production 2nd</option><option>Planning</option><option>Quality</option>
                  <option>Sales</option><option>Purchase</option><option>HR/HSE/GA</option><option></option>
                </select>
              </div>
            </div>     
            <div class="form-group">
              <label for="type">Type:</label>
              <div>
                <select type="text" id="type" v-model="formInputs.type">
                  <option>Productivity</option><option>NG</option><option>Failure</option><option>Safety</option><option>3C5S</option>
                  <option></option>
                </select>
              </div>
            </div>                
            <div class="form-group">
              <label for="type">Line:</label>
              <div>
                <select id="line" v-model="formInputs.line">
                  <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
                </select>         
              </div>
            </div>  
            <div class="form-group">
              <label for="station">Station:</label>
              <div>
                <select id="station" v-model="formInputs.station">
                  <option v-for="station in uniqueStations" :key="station">{{ station }}</option><option></option>        
                </select>         
              </div>
            </div>  
            <div class="form-group">
              <label for="part">Part:</label>
              <div>
                <input type="text" id="part" v-model="formInputs.part" />
              </div>
            </div> 
            <div class="form-group">
              <label for="type">In Charge:</label>
              <div>
                <input type="text" id="incharge" v-model="formInputs.incharge" />                  
              </div>
            </div>  
            <div class="form-group date-time-group">
                <label>Due Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="dueyear" v-model="formInputs.dueyear"  />
                  <input type="text" id="duemonth" v-model="formInputs.duemonth" />
                  <input type="text" id="dueday" v-model="formInputs.dueday" />                  
                </div> 
            </div>   
          </td>
          <td>
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="phenomenon">Phenomenon:</label>
              <textarea id="phenomenon" v-model="formInputs.phenomenon" rows="4" cols="25"></textarea>              
            </div>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="cause">Cause:</label>
              <textarea id="cause" v-model="formInputs.cause" rows="4" cols="25"></textarea>              
            </div>
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="problem">Problem:</label>
              <textarea id="problem" v-model="formInputs.problem" rows="4" cols="25"></textarea>              
            </div>          
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="solution">Solution:</label>
              <textarea id="solution" v-model="formInputs.solution" rows="4" cols="25"></textarea>              
            </div>  
          </td>
          <td>  
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="remark">Remark:</label>
              <textarea id="remark" v-model="formInputs.remark" rows="4" cols="25"></textarea>              
            </div>  
            <div class="form-group">
              <label for="project">Progress:</label>
              <div>
                <input class="radio" type="radio" id="notyet" value="Not Yet" v-model="formInputs.progress">
                <label for="notyet">Not Yet</label>
                <input class="radio"  type="radio" id="ongoing" value="On going" v-model="formInputs.progress">
                <label for="ongoing">On going</label>
                <input class="radio"  type="radio" id="done" value="Done" v-model="formInputs.progress">
                <label for="done">Done</label>                
              </div>
            </div>   
            <div class="form-group">
               <label for="type">File Upload:</label>
              </div>
              <div>           
                <input type="file" @change="handleFileUpload('file')($event)" />              
                <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button>
                <button class="fileUploadButton" type="button" @click="delFile('file')">File Delete</button>
                {{ fileSaveStatus.file }}   
               </div>        
              <div class="form-group">
               <label for="type">Image 1 Upload:</label>
              </div>
              <div>           
                <input type="file" @change="handleFileUpload('imageone')($event)" />              
                <button class="fileUploadButton" type="button" @click="fileUpload('imageone')">Image 1 Upload</button>
                <button class="fileUploadButton" type="button" @click="delFile('imageone')">Image Delete</button>
               </div>  
               <div>                                           
                {{ fileSaveStatus.imageone }}              
               </div><br>
               <div class="form-group">
               <label for="type">Image 2 Upload:</label>
              </div>
              <div>           
                <input type="file" @change="handleFileUpload('imagetwo')($event)" />              
                <button class="fileUploadButton" type="button" @click="fileUpload('imagetwo')">Image 2 Upload</button>
                <button class="fileUploadButton" type="button" @click="delFile('imagetwo')">Image Delete</button>
               </div>  
               <div>                                           
                {{ fileSaveStatus.imagetwo }}              
               </div>              
            </td>
            </tr>
            <tr>
              <td>                        
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}  
           </td>
            </tr>
           </table>  
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';     
  import { fetchLine, fetchStation, uploadFile, fileDel } from '../utils.js';
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "correctiveaction";   
        const saveStatus = ref('');
        const fileSaveStatus = reactive({ file: '', imageone: '', imagetwo: '', });    
        const isProcessing = ref(false);   
        const selectedFiles = reactive({ file: null,  imageone: null, imagetwo: null, });             
        const uploadType = 'correctiveAction';  
        const uniqueLines = ref([]);   
        const uniqueStations = ref([]);               
        const formInputs = reactive({
          id: "", year:"", month: "", day: "", team: "", type: "", line: "", station: "", part: "", phenomenon: "", cause: "", 
          problem: "", solution: "", incharge: "", progress: "", dueyear: "", duemonth: "", dueday: "", remark: "",
          file: "", filepath: "", imageone: "", imageonepath: "", imagetwo: "", imagetwopath: "",
        });

        const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

        const submitForm = async () => {

          isProcessing.value = true;
          saveStatus.value = 'Processing...';    
     
          const columnMapping = {
            year: 'year',
            month: 'month',
            day: 'day',
            team: 'team',            
            type: 'type',
            line: 'line',
            station: 'station',
            part: 'part',
            phenomenon: 'phenomenon',
            cause: 'cause',
            problem: 'problem',
            solution: 'solution',
            incharge: 'incharge',
            progress: 'progress',            
            dueyear: 'dueyear',
            duemonth: 'duemonth',
            dueday: 'dueday',                       
            remark: 'remark',          
            file: 'file',
            filepath: 'filepath',   
            imageone: 'imageone',
            imageonepath: 'imageonepath',
            imagetwo: 'imagetwo',
            imagetwopath: 'imagetwopath'
          };

          const formData = {
            id : formInputs.id,
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: formInputs.year,
              month: formInputs.month,
              day: formInputs.day,
              team: formInputs.team,              
              type: formInputs.type,
              line: formInputs.line,
              station: formInputs.station,
              part: formInputs.part,
              phenomenon: formInputs.phenomenon,
              cause: formInputs.cause,
              problem: formInputs.problem,
              solution: formInputs.solution,              
              incharge: formInputs.incharge,
              progress: formInputs.progress,              
              dueyear: formInputs.dueyear,
              duemonth: formInputs.duemonth,
              dueday: formInputs.dueday,              
              remark: formInputs.remark,
              file: formInputs.file,
              filepath: formInputs.filepath,
              imageone: formInputs.imageone,
              imageonepath: formInputs.imageonepath,
              imagetwo: formInputs.imagetwo,
              imagetwopath: formInputs.imagetwopath
            }
         };
         const id = formInputs.id;
         if (!id) { alert("ID is missing"); return; }
         
         await axios.put(`/api/materialput/${id}`, formData);         
         closeModal();
        };

        const closeModal = () => { emit("onClose"); }; 
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];

              formInputs.id = rowData.id;
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,
              formInputs.team = rowData.team,              
              formInputs.type = rowData.type,
              formInputs.line = rowData.line,
              formInputs.station = rowData.station,
              formInputs.part = rowData.part,
              formInputs.phenomenon = rowData.phenomenon,
              formInputs.cause = rowData.cause,
              formInputs.problem = rowData.problem,
              formInputs.solution = rowData.solution,              
              formInputs.incharge = rowData.incharge,
              formInputs.progress = rowData.progress,              
              formInputs.dueyear = rowData.dueyear,
              formInputs.duemonth = rowData.duemonth,
              formInputs.dueday = rowData.dueday,              
              formInputs.remark = rowData.remark,
              formInputs.file = rowData.file,
              formInputs.filepath = rowData.filepath,
              formInputs.imageone = rowData.imageone,
              formInputs.imageonepath = rowData.imageonepath,
              formInputs.imagetwo = rowData.imagetwo,
              formInputs.imagetwopath = rowData.imagetwopath

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );      
      
      const fileUpload = (type) => { if (formInputs[`${type}path`]) { alert("First delete file");return; }
            uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);
      };
      const delFile = (type) => fileDel(type, formInputs, fileSaveStatus);

      const loadLines = async () => { uniqueLines.value = await fetchLine(); }; loadLines();
      const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };  
      watch(() => formInputs.line, (newLine) => { loadStations(newLine); });

      return { dbTableName, formInputs, submitForm, closeModal,  saveStatus, isProcessing,
               selectedFiles, handleFileUpload, fileSaveStatus, fileUpload, delFile, uniqueLines, uniqueStations 
      };
    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
/* Reset */
* { margin: 0; padding: 0; box-sizing: border-box; }

/* Modal styles */
.modal-mask {
  position: fixed; z-index: 9998;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
  display: flex; justify-content: flex-start; align-items: flex-start; 
}   

.modal-wrapper { width: 100%;  max-width: 800px; }

.modal-container {
  background-color: #ffffff;
  border-radius: 12px; padding: 20px;
  max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
}

.modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }

.modal-header h3 { font-size: 1.2rem; }

.close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }

.form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }

label { font-weight: bold; }

input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

input[type="number"] { width: 100%;}  

.readWindow[readonly] { background-color: #f0f0f0; }

button[type="submit"] {
  background-color: #4CAF50; color: white;
  padding: 5px 20px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 16px;
  display: inline-block; transition-duration: 0.4s;
}

button[type="submit"]:hover { background-color: #45a049; }

.fileUploadButton {
  background-color: #c6c6c6; color: rgb(0, 0, 0);
  padding: 5px 5px; margin-top: 10px; margin-right: 10px;
  border: none; border-radius: 4px; cursor: pointer;
  text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
  display: inline-block; transition-duration: 0.4s;
}

.fileUploadButton:hover { background-color: #5f5f5f; }  

table { table-layout: fixed; width: 100%; }

td { width: 20%; vertical-align: top; padding: 0 15px; }

.time-inputs { display: flex; gap: 5px; }
.time-inputs input[type="number"] { width: 50px; }
.date-time-inputs { display: flex; gap: 5px;}
.date-time-inputs input { width: 50px; size: 8px; }

.ng-dropdown-container { position: relative; display: inline-block; }
.ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
.ng-dropdown-menu { 
  position: absolute; padding: 4px;
  left: 0; top: 100%; z-index: 10; 
  background-color: white; border: 1px solid #ccc;  
}
.ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
.ng-dropdown-menu > div:hover { background-color: #f5f5f5; }

.ngmaintype{ max-width: 120px; min-width: 120px;}
.radio { margin-left: 10px; margin-right: 5px; }
</style>