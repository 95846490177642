<template>
    <div class="ev202060fr">
      <div class="act">
        <h class="pagetitle">EV2020 60kW Front</h>
         <div class="modalButtonContainer">        
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>
        <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>
        </div>
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downloadExcel">Excel</button>     
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index" >{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers "> <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index"  @click="editCellValue(dbTableName, row.id, header, $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-2P12S': row.model === '2P12S', 'model-3P8S': row.model === '3P8S'}" @click="editCellValue(dbTableName, row.id, header, $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCellValue(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok"  @click="editCellValue(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>                
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Busbar Assembly'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_busbarassembly === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_busbarassembly', $event)">{{ row.ng_busbarassembly }}</td>
                <td v-if="header === 'Heat Stacking'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_heatstacking === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_heatstacking', $event)">{{ row.ng_heatstacking }}</td>
                <td v-if="header === 'EOL'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eol === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_eol', $event)">{{ row.ng_eol }}</td>
                <td v-if="header === 'Laser Welding'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_laserwelding === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_laserwelding', $event)">{{ row.ng_laserwelding }}</td>
                <td v-if="header === 'Slot'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_slot === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_slot', $event)">{{ row.ng_slot }}</td>
                <td v-if="header === 'Hole Position JIG'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_holepositionjig === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_holepositionjig', $event)">{{ row.ng_holepositionjig }}</td>
                <td v-if="header === 'Final Inspection'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_finalinspection === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_finalinspection', $event)">{{ row.ng_finalinspection }}</td>
                <td v-if="header === 'Others'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_others === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_others', $event)">{{ row.ng_others }}</td>                
                <td v-if="header === 'Welding Peel Test'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_weldingpeeltest === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_weldingpeeltest', $event)">{{ row.ng_weldingpeeltest }}</td>
                <td v-if="header === 'Test'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_test === 0 }"  @click="editCellValue(dbTableName, row.id, 'ng_test', $event)">{{ row.ng_test }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>                
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> 
                <td v-if="header === 'Heat Stacking Vision'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_heatstackingvision === 0 }"  @click="editCellValue(dbTableName, row.id, 'fn_heatstackingvision', $event)">{{ row.fn_heatstackingvision }}</td>                                                 
                <td v-if="header === 'Laser Vision'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_laservision === 0 }"  @click="editCellValue(dbTableName, row.id, 'fn_laservision', $event)">{{ row.fn_laservision }}</td>                                                 
                <td v-if="header === 'EOL Fake'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_eol === 0 }"  @click="editCellValue(dbTableName, row.id, 'fn_eol', $event)">{{ row.fn_eol }}</td>                                                 
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td>                
                <td v-if="header === 'Semi Hook Broken'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_hookbroken === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_hookbroken', $event)">{{ row.semi_hookbroken }}</td>
                <td v-if="header === 'Semi FPCB Bending'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_fpcbending === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_fpcbending', $event)">{{ row.semi_fpcbending }}</td>                
                <td v-if="header === 'Semi ETC'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_etc === 0 }" @click="editCellValue(dbTableName, row.id, 'semi_etc', $event)">{{ row.semi_etc }}</td>
                <td v-if="header === 'Work Order'" :key="index"  @click="editCellValue(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index"  @click="editCellValue(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index"  @click="editCellValue(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index"  @click="editCellValue(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index"  @click="editCellValue(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td>                 
                <td v-if="header === 'Operator Quantity'" :key="index"  @click="editCellValue(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCellValue(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index"  @click="editCellValue(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr> 
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>          
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_busbarassembly }}</td>
          <td>{{ sums.shiftSums[1].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[1].ng_eol }}</td>
          <td>{{ sums.shiftSums[1].ng_laserwelding }}</td>
          <td>{{ sums.shiftSums[1].ng_slot }}</td>
          <td>{{ sums.shiftSums[1].ng_holepositionjig }}</td>
          <td>{{ sums.shiftSums[1].ng_finalinspection }}</td>    
          <td>{{ sums.shiftSums[1].ng_others }}</td>
          <td>{{ sums.shiftSums[1].ng_weldingpeeltest }}</td>          
          <td>{{ sums.shiftSums[1].ng_test }}</td>  
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].fakeng }}</td>            
          <td>{{ sums.shiftSums[1].fn_heatstackingvision }}</td>
          <td>{{ sums.shiftSums[1].fn_laservision }}</td>
          <td>{{ sums.shiftSums[1].fn_eol }}</td>
          <td>{{ ((sums.shiftSums[1].fakeng)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>          
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_busbarassembly }}</td>
          <td>{{ sums.shiftSums[2].ng_heatstacking }}</td>
          <td>{{ sums.shiftSums[2].ng_eol }}</td>
          <td>{{ sums.shiftSums[2].ng_laserwelding }}</td>
          <td>{{ sums.shiftSums[2].ng_slot }}</td>
          <td>{{ sums.shiftSums[2].ng_holepositionjig }}</td>
          <td>{{ sums.shiftSums[2].ng_finalinspection }}</td>    
          <td>{{ sums.shiftSums[2].ng_others }}</td>
          <td>{{ sums.shiftSums[2].ng_weldingpeeltest }}</td>          
          <td>{{ sums.shiftSums[2].ng_test }}</td>    
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].fakeng }}</td>            
          <td>{{ sums.shiftSums[2].fn_heatstackingvision }}</td>
          <td>{{ sums.shiftSums[2].fn_laservision }}</td>
          <td>{{ sums.shiftSums[2].fn_eol }}</td>
          <td>{{ ((sums.shiftSums[2].fakeng)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>         
        <tr>
         <th colspan="3">EV2020 60kW Front Total</th>         
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok + sums.shiftSums[2].ok - sums.qwmodelSums['60kW Front'].ng}}</td>                
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['60kW Front'].ng }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_busbarassembly, 0) }}</td>           
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_heatstacking, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_laserwelding, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_slot, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_holepositionjig, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_finalinspection, 0) }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_weldingpeeltest, 0) }}</td>                    
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['60kW Front']].reduce((sum, obj) => sum + obj.ng_test, 0) }}</td>
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['60kW Front'].ng) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ sums.shiftSums[1].fakeng + sums.shiftSums[2].fakeng }}</td>
          <td class="fn_type">{{ sums.shiftSums[1].fn_heatstackingvision + sums.shiftSums[2].fn_heatstackingvision }}</td>           
          <td class="fn_type">{{ sums.shiftSums[1].fn_laservision + sums.shiftSums[2].fn_laservision }}</td>           
          <td class="fn_type">{{ sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_eol }}</td>           
          <td class="fn">{{ (( sums.shiftSums[1].fakeng + sums.shiftSums[2].fakeng)/(sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
        <!-- Use header instead of qwheader -->
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCellValue(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index"></td>
            <td v-if="header === 'Input'" :key="index"></td>
            <td v-if="header === 'OK'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>            
            <td v-if="header === 'NG'" :key="index" @click="editCellValue(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Busbar Assembly'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_busbarassembly === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_busbarassembly', $event)">{{ qw.ng_busbarassembly }}</td>
            <td v-if="header === 'Heat Stacking'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_heatstacking === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_heatstacking', $event)">{{ qw.ng_heatstacking }}</td>
            <td v-if="header === 'EOL'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_eol === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_eol', $event)">{{ qw.ng_eol }}</td>
            <td v-if="header === 'Laser Welding'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_laserwelding === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_laserwelding', $event)">{{ qw.ng_laserwelding }}</td>
            <td v-if="header === 'slot'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_slot === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_slot', $event)">{{ qw.ng_slot }}</td>
            <td v-if="header === 'Hole Position JIG'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_holepositionjig === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_holepositionjig', $event)">{{ qw.ng_holepositionjig }}</td>
            <td v-if="header === 'Final Inspection'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_finalinspection === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_finalinspection', $event)">{{ qw.ng_finalinspection }}</td>            
            <td v-if="header === 'Others'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_others === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_others', $event)">{{ qw.ng_others }}</td>            
            <td v-if="header === 'Welding Peel Test'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_weldingpeeltest === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_weldingpeeltest', $event)">{{ qw.ng_weldingpeeltest }}</td>            
            <td v-if="header === 'Test'" :key="index" :class="{ 'zero-value_qwngtype': qw.ng_test === 0 }"  @click="editCellValue(qwTableName, qw.id, 'ng_test', $event)">{{ qw.ng_test }}</td>            
          </template>
        </tr> 
      </table>           
      
      <p></p>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCellValue(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <p></p>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCellValue(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>      
      <dtModal  v-if="showdtModal" @onClose="showdtModal = false; callSearchData();"/>         
      <drModal  v-if="showdrModal" @onClose="showdrModal = false; callSearchData();"/> 
      <qwModal  v-if="showqwModal" @onClose="showqwModal = false; callSearchData();"/> 
      <rdModal  v-if="showrdModal" @onClose="showrdModal = false; callSearchData();"/> 
  </div>
  </template>

<script>

import { reactive, ref, computed, } from 'vue';
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

import Modal from "./ev2020_60fr_prm.vue";
import dtModal from "./ev2020_60fr_prdtm.vue";
import drModal from "./ev2020_60fr_prdrm.vue";
import qwModal from "./ev2020_60fr_qwm.vue";
import rdModal from "./ev2020_60fr_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },        
    
    setup() {
        const lineName = "ev202060fr"  //설비 변경시 필수 교체        
        const dbTableName = "ev2020_60frpr";  //searchData에 있는 값도 바꿔야 함 
        const dtTableName = "ev2020_60frdt";  //searchData에 있는 값도 바꿔야 함
        const drTableName = "ev2020_60frdr";  //searchData에 있는 값도 바꿔야 함
        const qwTableName = "ev2020_60frqw";  //searchData에 있는 값도 바꿔야 함
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly",
                         "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection',
                         'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Fake Sum', 'Heat Stacking Vision',
                         'Laser Vision', 'EOL Fake','Fake NG Rate', 'Semi Hook Broken', 'Semi FPCB Bending', 
                         'Semi ETC', 'Work Order', 'Supervisor', 'Leader', 'Technician', 'Shift Name', 
                         'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체

        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], });   

        //Repeat Data part
        const showrdModal = ref(false);     
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, heatstackingvision, laservision, fneol, ) => {
          const input = ok + ng;                    
          const fakeng = heatstackingvision + laservision + fneol;   

          let ngRate = 0;
          let fn_ngRate = 0;          
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }          
          return { input, ngRate, fakeng, fn_ngRate, };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = 
            calculateInputAndNgRate(d.ok, d.ng, d.fn_heatstackingvision, d.fn_laservision, d.fn_eol, );
            return { ...d, input, ngRate, fakeng, fn_ngRate }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const searchData = async (tableName) => {
          const sendDateData = await sendDate();
            console.log('Requesting data with:', {
              tablename: tableName,
              year: sendDateData.sendDateData[0],
              month: sendDateData.sendDateData[1],
              day: sendDateData.sendDateData[2],
            });

            const formData = {
              tableName: tableName,
              values: {
                year: sendDateData.sendDateData[0],
                month: sendDateData.sendDateData[1],
                day: sendDateData.sendDateData[2],
              }
            };

            prTableData.data = [];
            dtTableData.data = [];
            drTableData.data = [];
            qwTableData.data = [];

            console.log('Requesting data with:', formData);

            axios.get('/api/productionData', { params: formData })
              .then((res) => {
                // You can assign the result to the corresponding table data based on the tableName
                switch (tableName) {
                  case 'ev2020_60frpr':
                    prTableData.data = res.data.sort((a, b) => a.row_no - b.row_no); // Sort by row_no
                    break;
                  case 'ev2020_60frdt':
                    dtTableData.data = res.data;
                    break;
                  case 'ev2020_60frdr':
                    drTableData.data = res.data;
                    break;
                  case 'ev2020_60frqw':
                    qwTableData.data = res.data;
                    break;
                }
              });
          };

          const callSearchData = () => {
            searchData(dbTableName);
            searchData(dtTableName);
            searchData(drTableName);
            searchData(qwTableName);
            settingCall();
          };
          
          const editCellValue = async (tableName, id, header, event) => {
            const currentValue = event.target.innerText;
            const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);

            if (newValue !== null && newValue !== currentValue) {
              // Update the value in the database and update the tableData
              try {
                // Replace input value with the desired value
                await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue });

                // Refresh the data by calling searchData()
                searchData(tableName);
              } catch (error) {
                console.error("Error updating cell value:", error);
              }
            }
          };       

        //데이터 더하기 -시작-
  
        const sums = computed(() => {
          let shiftSums = {
            1: { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0,
                ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, 
                ng_weldingpeeltest: 0, ng_test: 0, fakeng: 0, fn_heatstackingvision: 0, fn_laservision: 0, fn_eol: 0, }, 
          
            2: { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0,
                ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, 
                ng_weldingpeeltest: 0, ng_test: 0, fakeng: 0, fn_heatstackingvision: 0, fn_laservision: 0, fn_eol: 0, }, 
            };

          let modelSums = {
            "60kW Front": { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0,
                            ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, 
                            ng_weldingpeeltest: 0, ng_test: 0, fakeng: 0, fn_heatstackingvision: 0, fn_laservision: 0, fn_eol: 0, },       
            };

          let qwmodelSums = {
            "60kW Front": { target: 0, input: 0, ok: 0, ng: 0, ng_busbarassembly: 0, ng_heatstacking: 0, ng_eol: 0,
                            ng_laserwelding: 0, ng_slot: 0, ng_holepositionjig: 0, ng_finalinspection: 0, ng_others: 0, 
                            ng_weldingpeeltest: 0, ng_test: 0,  
                          },           
            };

          for (const row of item.value) {
            if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
              shiftSums[row.shift].target += row.target;
              shiftSums[row.shift].input += row.input;
              shiftSums[row.shift].ok += row.ok;                            
              shiftSums[row.shift].ng += row.ng;    
              shiftSums[row.shift].ng_busbarassembly += row.ng_busbarassembly;
              shiftSums[row.shift].ng_heatstacking += row.ng_heatstacking;
              shiftSums[row.shift].ng_eol += row.ng_eol;
              shiftSums[row.shift].ng_laserwelding += row.ng_laserwelding;
              shiftSums[row.shift].ng_slot += row.ng_slot;
              shiftSums[row.shift].ng_holepositionjig += row.ng_holepositionjig;
              shiftSums[row.shift].ng_finalinspection += row.ng_finalinspection;
              shiftSums[row.shift].ng_others += row.ng_others;
              shiftSums[row.shift].ng_weldingpeeltest += row.ng_weldingpeeltest;
              shiftSums[row.shift].ng_test += row.ng_test;
              shiftSums[row.shift].fakeng += row.fakeng;
              shiftSums[row.shift].fn_heatstackingvision += row.fn_heatstackingvision;
              shiftSums[row.shift].fn_laservision += row.fn_laservision;
              shiftSums[row.shift].fn_eol += row.fn_eol;              
              }

            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {              
              modelSums[row.model].target += row.target;            
              modelSums[row.model].input += row.input;
              modelSums[row.model].ok += row.ok;         
              modelSums[row.model].ng += row.ng;    
              modelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
              modelSums[row.model].ng_heatstacking += row.ng_heatstacking;
              modelSums[row.model].ng_eol += row.ng_eol;
              modelSums[row.model].ng_laserwelding += row.ng_laserwelding;
              modelSums[row.model].ng_slot += row.ng_slot;
              modelSums[row.model].ng_holepositionjig += row.ng_holepositionjig;
              modelSums[row.model].ng_finalinspection += row.ng_finalinspection;
              modelSums[row.model].ng_others += row.ng_others;
              modelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;
              modelSums[row.model].ng_test += row.ng_test;
              modelSums[row.model].fakeng += row.fakeng;
              modelSums[row.model].fn_heatstackingvision += row.fn_heatstackingvision;
              modelSums[row.model].fn_laservision += row.fn_laservision;
              modelSums[row.model].fn_eol += row.fn_eol;     
            }
          }

          for (const row of qwItem.value) {
            if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
              qwmodelSums[row.model].ok += row.ok;
              qwmodelSums[row.model].ng += row.ng;    
              qwmodelSums[row.model].ng_busbarassembly += row.ng_busbarassembly;
              qwmodelSums[row.model].ng_heatstacking += row.ng_heatstacking;
              qwmodelSums[row.model].ng_eol += row.ng_eol;
              qwmodelSums[row.model].ng_laserwelding += row.ng_laserwelding;
              qwmodelSums[row.model].ng_slot += row.ng_slot;
              qwmodelSums[row.model].ng_holepositionjig += row.ng_holepositionjig;
              qwmodelSums[row.model].ng_finalinspection += row.ng_finalinspection;
              qwmodelSums[row.model].ng_others += row.ng_others;
              qwmodelSums[row.model].ng_weldingpeeltest += row.ng_weldingpeeltest;              
              qwmodelSums[row.model].ng_test += row.ng_test;
            }
          }     

          return { shiftSums, modelSums, qwmodelSums };
        });

        const settingData = reactive({ data: [], });        

        const settingCall = async () => {         
            const tableName ='setting';
            const formData = { tableName: tableName };
            axios.get('/api/targetList', { params: formData })
            .then((res) => { settingData.data = res.data; })
            .catch((error) => { console.error("Error fetching target data:", error.message); });
        };

        const downloadExcel = () => {
          const exceldownloadSetting = settingData.data.find(s => Object.hasOwn(s, 'exceldownload'));  
          const isExcelDownloadEnabled = exceldownloadSetting && exceldownloadSetting.exceldownload === 1;
          if (!isExcelDownloadEnabled) { alert("Excel download is currently disabled."); return; }
          const wb = XLSX.utils.book_new();

          // Create worksheets for each table
          const wsPr = XLSX.utils.json_to_sheet(prTableData.data, { header: headers.value });
          const wsDt = XLSX.utils.json_to_sheet(dtTableData.data, { header: dtHeaders.value });
          const wsDr = XLSX.utils.json_to_sheet(drTableData.data, { header: drHeaders.value });
          const wsQw = XLSX.utils.json_to_sheet(qwTableData.data, { header: headers.value });

          // Append worksheets to the workbook with custom sheet names
          XLSX.utils.book_append_sheet(wb, wsPr, "PR Data");
          XLSX.utils.book_append_sheet(wb, wsDt, "DT Data");
          XLSX.utils.book_append_sheet(wb, wsDr, "DR Data");
          XLSX.utils.book_append_sheet(wb, wsQw, "QW Data");

          // Write the workbook and create a Blob
          const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Save the file
          saveAs(blob, "data.xlsx");
        };

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, editCellValue, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped>
.act {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 5px;
}
.pagetitle {
  text-align: left;
  font-size: 1.0rem;
  font-weight: bold;
  margin-left : 15px;
  
}
.modalButtonContainer {
  display: inline-flex;
}

.button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;   
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .qwallButton {
    padding: 5px 5px;
    background-color: #00eb91;
    color: #000000;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
.searchButton {
  padding: 5px 5px;
  background-color: #94a5b1;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.excelButton:hover, .searchButton:hover, .qwallButton:hover, 
.button:hover {
    background-color: #2980b9;
  }

  
.searchBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.searchBar > *:not(:last-child) {
  margin-right: 5px;
}

.searchBar select, 
.searchBar input
  {
  width : 80px;  
  margin-right: 5px;
  padding : 5px;
 }

label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.sticky-time { position: sticky;  left: 0;  background-color: white;  z-index: 1;}

td {
  border : 1px solid black;
  text-align : center;
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
}

th {
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
}
.tables {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left : 20px;
  padding-right : 20px;
}

table {
  width: 80%;
}

#dailyReport td {
  text-align: left;
}

.custom-table {
  font-size: 10px;
  width: 80%;
  overflow-x: auto;
  white-space: nowrap;
}

.model-2P12S {
  background-color: #fafc83; /* Adjust the color as needed for 2P12S */
}

.model-3P8S {
  background-color: #e07510; /* Adjust the color as needed for 3P8S */
}

.ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.zero-value_ng {
  background-color: #fa7979; 
  color: #fa7979; 
}

.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.zero-value_ngtype {
  background-color: #fdb08d; 
  color: #fdb08d;
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.zero-value_fn {
  background-color: #ff72d5; 
  color: #ff72d5; 
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}

.zero-value_fntype {
  background-color: #ff9bcd; 
  color: #ff9bcd; 
}

.verify {
  background-color: #ddf505; 
}

.zero-value_verify {
  background-color: #ddf505; 
  color: #ddf505; 
}

.bending {
  background-color: #7ec578; 
}

.zero-value_bending {
  background-color: #7ec578; 
  color: #7ec578; 
}

.semi {
  background-color: #fccc66; 
}

.zero-value_semi {
  background-color: #fccc66; 
  color: #fccc66; 
}
.qwall {
  background-color: #66b179;  
}.zero-value_qwngtype {
  background-color: #66b179;  
  color:#66b179;  
}

.shift1 { background-color: rgb(174, 240, 147); }
.shift2 { background-color: rgba(248, 250, 145, 0.979); }
</style>