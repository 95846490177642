import axios from 'axios';
// import { getCurrentDate, } from '../../utils.js';

export const searchData = async (tableName, startY, startM, startD, endY, endM, endD) => {  
  const formData = {        
    tableName: tableName,
    startYear: startY,
    startMonth: startM,
    startDay: startD,
    endYear: endY,
    endMonth: endM,
    endDay: endD
  };  
  try {
    const response = await axios.get('/api/searchPeriod', { params: formData });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return null;
  }  
};    

export const targetSearchData = async (table) => {
  const formData = { tableName: table };
  try {
    const res = await axios.get('/api/targetList', { params: formData });    
    return res.data
  } catch (error) {
    console.error("Error fetching target data:", error.message);
    return [];
  }
};

export const calculateNgRate = (ng, ok) => {const total = ok + ng; return total === 0 ? 0 : ((ng / total) * 100).toFixed(2);};
export const calculateOkAchieve = (ok, target) => { return target === 0 ? 0 : ((ok / target) * 100).toFixed(2);};

export const getModelFromLastData = (data) => {if (data && data.length > 0) { return data[data.length - 1]; }  return null;};
export const calculateSums = (data, item) => { for (const row of data) { item.ok += row.ok; item.ng += row.ng; item.target += row.target; item.work_minute += row.work_minute;}};
export const dtcalculateSums = (data, item) => {for (const row of data) { item.time += row.time; }}; 
//export const failureCalculateSums = (data, item) => {for (const row of data) { item.failureTime += row.time; item.failureCount += 1; }};


export const failureCalculateSums = (data, item) => {
  // 배열이 아닌 경우, 배열로 만들어 처리
  if (!Array.isArray(data)) {
    data = [data];
  }

  for (const row of data) {
    item.failureTime += row.time;
    item.failureCount += 1;
  }
};



export const workSearchData = async (tableName, startY, startM, startD, endY, endM, endD) => {  
  const formData = {        
      tableName: tableName,
      startYear: startY,
      startMonth: startM,
      startDay: startD,
      endYear: endY,
      endMonth: endM,
      endDay: endD,
      type: "failure"
  };  

  try {
    const response = await axios.get('/api/searchPeriod', { params: formData });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return null;
  }  
};   