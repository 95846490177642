<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h class="pagetitle">Asset List</h>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">                
          <div>
            <select class="classType" v-model="classType">
              <option>Land</option><option>Building</option><option>Construct</option>
              <option>Machinery</option><option>Vehicle transport (forklift, etc.)</option>
              <option>Equipment(computer etc.)</option><option>Tool (measure etc.)</option>
              <option>Software</option><option>Under construction</option><option></option>                      
            </select>
          </div>
          <div class="searchTd">
              <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
          </div>
          <div class="searchTd">                        
              <button class="addButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" @click="showModal = true">+ New Asset</button>
          </div>
          <div class="searchTd">
              <button class="excelDownload" @click.prevent="downExcel">Down Load</button>     
          </div>            
        </div>      
      </form>  
    </div>          
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>                
        </tr>              
        <tr v-for="row in item" :key="row.id" >          
        <template v-for="(header, index) in headers">  
          <td v-if="header === 'Image'" :key="index" >
            <img v-if="row.imagepath && row.imagepath !== '0'" :src="getImageUrl(row.imagepath)" alt="Image" style="width: 100px; height: auto;" @click="openImageInPopup(getImageUrl(row.imagepath))">
            <template v-else></template>              
          </td>
          <td v-if="header === 'Date'" :key="index" >{{ row.date }}</td>          
          <td v-if="header === 'Asset Number'" :key="index" >{{ row.assetnumber }}</td>
          <td v-if="header === 'Name'" :key="index" >{{ row.name }}</td>
          <td v-if="header === 'Contents'" :key="index" >{{ row.contents }}</td>
          <td v-if="header === 'Class'" :key="index">{{ row.classtype }}</td>          
          <td v-if="header === 'Location'" :key="index" >{{ row.location }}</td>          
          <td v-if="header === 'In Charge'" :key="index" >{{ row.incharge }}</td>                    
          <td v-if="header === 'Remark'" :key="index" >{{ row.remark }}</td>             
          <td v-if="header === 'Modify'" :key="index" ><button class="moButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>          
          <td v-if="header === 'DEL'" :key="index"><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>
        </template>   
      </tr>
    </table>    
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    </div>  
</template>

<script>
import { ref, reactive, computed } from 'vue';
import axios from 'axios';
import Modal from './assetModal.vue';
import modifyModal from './assetMoModal.vue';
import { deleteRow, downloadExcel, } from "../../utils.js"; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      downExcel() { downloadExcel(this.item, "AssetList.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
      canClick() {          
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 }
        else { return false;}
      },  
      //getImageUrl(filepath) {return `http://localhost:3000/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `https://lsevpweb.ngrok.app/uploads/${filepath}`;},
      //getImageUrl(filepath) {return `http://pl.lsevpweb.com/uploads/${filepath}`;},
      getImageUrl(filepath) { return filepath;},
      openImageInPopup(imageUrl) { window.open(imageUrl, '_blank'); }
    },

    components: { Modal, modifyModal, },     

    setup() {           
        const classType = ref();
        const dbTableName = 'assetlist';
        const showModal = ref(false);        
        const showMoModal = ref(false);      
        const headers = ['Image', 'Date', 'Asset Number', 'Name', 'Contents', 'Class', 'Location',
                         'In Charge', 'Remark', 'Modify', 'DEL'];
        const assetData = reactive({ data: [], })        

        const sendDate = async () => {const sendDateData = [classType.value,]; return { sendDateData };};    

        const searchData = async () => {            
          const sendDateData = await sendDate();          
          const formData = {        
                tableName: dbTableName,                
                classtype: sendDateData.sendDateData[0],       
          };  

          axios.get('/api/targetList', { params: formData, })                      
               .then((res) => { assetData.data = res.data.sort((a,b) => {
                if (a.assetnumber < b.assetnumber) return -1;
                if (a.assetnumber > b.assetnumber) return 1;
                return 0;
               });
          });  
        };        

        const item = computed(() => {
          return assetData.data.map(d => {            
            const formattedDate = `${d.year}-${d.month}-${d.day}`;
            return { ...d, date: formattedDate };
          });
        });

        const callSearchData = () => { searchData(dbTableName);  };   

        return { item, headers, dbTableName, callSearchData, showModal, showMoModal, 
                 classType, sendDate, 
        };        
    }
  }
</script>

<style scoped> 
  .table-container { margin-left : 10px; margin-right: 10px;}
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .disabledButton { background-color: gray; cursor: not-allowed; }
  label { font-size: 17px; font-weight: 600; margin-right: 8px; }
  .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
  .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px; }
  th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
      text-align : center; padding: 10px; font-size : 15px; font-weight: 600;
  }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .excelDownload {
        background-color: #347003;  color: white;
        border: none; border-radius: 4px; cursor: pointer;  font-size: 14px; font-weight: 600; margin-right : 10px;
        padding: 5px 5px; transition: background-color 0.3s; margin-left : 10px; 
    }
  .excelDownload :hover { background-color: #009625; }  

  .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
  }
  .moButton { padding: 5px 5px; background-color: #347003; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchTd { border: 0px !important;}    
  .classType { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .maker { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .factory { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  
  input::placeholder { color: gray; }
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }   
  
  .radio { margin-left: 10px; margin-right: 5px; }
  .red-row { background-color: rgb(237, 117, 117);}
  .yellow-row { background-color: rgb(238, 249, 140);}
  .qty { background-color: rgb(247, 227, 124); font-weight: bold }
  .horizontal-form { margin-left: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>