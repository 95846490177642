<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Line Worker</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>
                <div class="form-group">
                  <label for="employeenumber">Employee Number</label>
                  <input type="text" id="employeenumber" v-model="formInputs.employeenumber" />
                </div>
                  <div class="form-group">
                <label for="line">Line:</label>
                <select id="line" v-model="formInputs.line">
                  <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
                  <option></option>        
                </select>              
              </div>
              <div class="form-group">
                <label for="station">Station:</label>
                <select id="station" v-model="formInputs.station">
                  <option v-for="station in uniqueStations" :key="station">{{ station }}</option>                  
                  <option></option>
                </select>              
              </div>
              <div class="form-group date-time-group">
                <label>Work Start Date(Year/Month/Day):</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="formInputs.startyear"  />
                  <input type="text" id="month" v-model="formInputs.startmonth" />
                  <input type="text" id="day" v-model="formInputs.startday" />                  
                </div> 
              </div>  
              <div class="form-group">
                <label for="oscompany">Company:</label>
                <select id="oscompany" v-model="formInputs.oscompany" >
                   <option>MAX POWER</option><option>MEGA STAFF</option><option>ZYX</option><option>Inkatom</option>
                   <option>LSEVP</option><option></option>
                </select>
              </div>
              <div class="form-group">
                <label for="position">Position:</label>
                <select type="text" id="position" v-model="formInputs.position">
                  <option>Leader</option><option>Technician</option>
                  <option>Senior Operator</option><option>Junior Operator</option>
                  <option>Senior Inspector</option><option>Inspector</option>
                  <option>Logistics</option><option>Forklift</option><option>Truck</option>
                  <option>Maintenance</option>
                </select>
              </div>
              <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" id="name" v-model="formInputs.name" />
            </div>
            <div class="form-group">
              <label for="surname">Surname:</label>
              <input type="text" id="surname" v-model="formInputs.surname" />
            </div>
            </td>
            </tr>
            </table>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}        
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, ref, watch } from 'vue';  
  import axios from 'axios';   
  import { fetchLine, fetchStation, fetchname } from '../../utils.js'; 
   
  export default {                
    setup(props, { emit }) {       
        const dbTableName = "lineworkerlist"    
        const uniqueLines = ref([]);   
        const uniqueStations = ref([]);     
        const saveStatus = ref(''); 
        const isProcessing = ref(false);         
        
        const formInputs = reactive({
          line: "", station: "", oscompany: "", employeenumber: "",
          name: "", surname: "", position: "", startyear: "", startmonth: "", startday: "",
        });

        const submitForm = async () => {
          if (!formInputs.line || !formInputs.oscompany || !formInputs.employeenumber ) {
            alert("Please fill in all the fields: line, and model.");
            return;
          }

          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = {
            line : 'line',
            station : 'station', 
            startyear : 'startyear',
            startmonth : 'startmonth',
            startday : 'startday',
            oscompany : 'oscompany',
            employeenumber : 'employeenumber',
            position: 'position',
            name : 'name',
            surname : 'surname',            
          }

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              line :  formInputs.line,
              station :  formInputs.station, 
              startyear :  formInputs.startyear, 
              startmonth :  formInputs.startmonth, 
              startday :  formInputs.startday, 
              oscompany :  formInputs.oscompany,
              employeenumber :  formInputs.employeenumber,
              position : formInputs.position,
              name :  formInputs.name,
              surname :  formInputs.surname,              
            }
         };
         
         try {
            await axios.post('/api/post', formData); // 데이터 저장 요청
            saveStatus.value = 'Completed'; 

            // 데이터 저장 성공 후 이메일 발송 요청
            const emailData = {
              //to: 'jbrd@lspoland.com', // 받는 사람의 이메일 주소 (여러명에게 보낼때는 이메일주소1,이메일주소2 이런식으로 콤마(,)로 구분)          
              to: 'rudra00@naver.com', // 받는 사람의 이메일 주소 (여러명에게 보낼때는 이메일주소1,이메일주소2 이런식으로 콤마(,)로 구분)          
              subject: 'Line Worker Addition Confirmation', // 이메일 제목
              body: `A new line worker has been added: ${formInputs.line} ${formInputs.employeenumber} ${formInputs.name} ${formInputs.surname}` // 이메일 내용
            };

            await axios.post('/api/send-email', emailData); // 이메일 발송 요청            
          } catch (error) {
            console.error('Error during submission or email sending:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };

        const loadLines = async () => { uniqueLines.value = await fetchLine(); };
        const loadStations = async (line) => { uniqueStations.value = await fetchStation(line); };

        loadLines();
        watch(() => formInputs.line, (newLine) => { loadStations(newLine); });

        const employeeUpdate = (data) => {
          if (data) {
            formInputs.oscompany = data.oscompany || "";
            formInputs.position = data.position || "";
            formInputs.name = data.name || "";
            formInputs.surname = data.surname || "";
            // 여기에 추가적인 필드를 업데이트할 수 있습니다.
          } else {
            // 초기화 코드
            formInputs.oscompany = "";
            formInputs.position = "";
            formInputs.name = "";
            formInputs.surname = "";
            // 여기에 추가적인 필드 초기화 코드를 추가할 수 있습니다.
          }
        };

        watch(() => formInputs.employeenumber, () => {fetchname(formInputs.employeenumber, employeeUpdate); });
  
      return { dbTableName, formInputs, submitForm, closeModal, uniqueLines, uniqueStations, saveStatus, isProcessing };

    },     

    props: { onSubmit: { type: Function, required: true, },      
     
  }, 


};
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex; justify-content: center; align-items: center;
    }
    
    .modal-wrapper { width: 100%;  max-width: 500px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
  </style>