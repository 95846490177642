<template>
  <div class="container">        
    <div class="search">
      <form @submit.prevent="sendDate()">
          <table class="searchTable">
              <tr>
                  <td class="start">
                      <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
                      <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
                      <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
                  </td>
                  <td class="separator">~</td>
                  <td class="end">
                      <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
                      <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
                      <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
                  </td>
                  <td class="searchTd">
                      <button type="submit" @click="callSearchData">Search</button>                        
                  </td>
              </tr>
          </table>      
      </form>
    </div>            
    <table>       
      <tr><th rowspan="1"></th><th colspan="3" >Data</th><th colspan="3" class="okhead" >MTTR</th>
        <th colspan="3" class="tacttimehead">MTBF</th><th colspan="3" class="downtimehead">Failure Rate</th>
      </tr> 
      <tr><th v-for="(header, index) in headers" :key="index">{{ header }}</th></tr>         
      <tr v-for="(target, index) in targets" :key="target.name">        
          <td><B>{{ target.name }}</B></td>
          <td>{{ rowData[index].workMinute }}</td>
          <td>{{ rowData[index].failureTime }}</td>
          <td>{{ rowData[index].failureCount }}</td>
          <td>{{ rowData[index].mttrTarget }}</td>
          <td class="okhead"><B>{{ rowData[index].actualMttr }}</B></td>
          <td :style="aS(rowData[index].achieveMttr / 100)"><B>{{ rowData[index].achieveMttr }}%</B></td>
          <td>{{ rowData[index].mtbfTarget }}</td>
          <td class="tacttimehead"><B>{{ rowData[index].actualMtbf }}</B></td>
          <td :style="aS(rowData[index].achievMmtbf / 100)"><B>{{ rowData[index].achievMmtbf }}%</B></td>
          <td>{{ rowData[index].failureRateTarget }}</td>
          <td class="downtimehead"><B>{{ rowData[index].actualFailureRate }}</B></td>
          <td :style="aS(rowData[index].achieveFailureRate / 100)"><B>{{ rowData[index].achieveFailureRate }}%</B></td>    
      </tr>
    </table>  
 
  </div>    
</template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from 'axios';

export default {
setup() {
  const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref();

  //각 설비별 데이터 베이스 table 이름을 입력해야 됨
  const targetTableName = "failuretarget";  
  const workTableName = "maintenanceworklist";  
  const tr3smt_dbTableName = 'tr3smtpr';
  const tr3smt_dtTableName = 'tr3smtdt';  
  const tr3crimping_dbTableName = 'tr3crimpingpr';
  const tr3crimping_dtTableName = 'tr3crimpingdt';   
  const tr3fpca_dbTableName = 'tr3fpcapr';
  const tr3fpca_dtTableName = 'tr3fpcadt'; 
  const tr3bfa2p_dbTableName = 'tr3bfa2ppr';
  const tr3bfa2p_dtTableName = 'tr3bfa2pdt'; 
  const tr3bfa3p_dbTableName = 'tr3bfa3ppr';
  const tr3bfa3p_dtTableName = 'tr3bfa3pdt';
  const evo_dbTableName = 'evopr';
  const evo_dtTableName = 'evodt'; 
  const ev2020_60fr_dbTableName = 'ev2020_60frpr';
  const ev2020_60fr_dtTableName = 'ev2020_60frdt'; 
  const ev2020_60rr_dbTableName = 'ev2020_60rrpr';
  const ev2020_60rr_dtTableName = 'ev2020_60rrdt'; 
  const ev2020_4060fr_dbTableName = 'ev2020_4060frpr';
  const ev2020_4060fr_dtTableName = 'ev2020_4060frdt'; 
  const ev2020_4060rr_dbTableName = 'ev2020_4060rrpr';
  const ev2020_4060rr_dtTableName = 'ev2020_4060rrdt';
  const tr2smt_dbTableName = 'tr2smtpr';
  const tr2smt_dtTableName = 'tr2smtdt';
  const tr2crimping_dbTableName = 'tr2crimpingpr';
  const tr2crimping_dtTableName = 'tr2crimpingdt';
  const tr2fpca_dbTableName = 'tr2fpcapr';
  const tr2fpca_dtTableName = 'tr2fpcadt';
  const tr2bfa2p_dbTableName = 'tr2bfa2ppr';
  const tr2bfa2p_dtTableName = 'tr2bfa2pdt'; 
  const tr2bfa3p_dbTableName = 'tr2bfa3ppr';
  const tr2bfa3p_dtTableName = 'tr2bfa3pdt';
  const ford1fr_dbTableName = 'ford1frpr';
  const ford1fr_dtTableName = 'ford1frdt'; 
  const ford1rr_dbTableName = 'ford1rrpr';
  const ford1rr_dtTableName = 'ford1rrdt';  
  const ford2fr_dbTableName = 'ford2frpr';
  const ford2fr_dtTableName = 'ford2frdt';
  const ford2rr_dbTableName = 'ford2rrpr';
  const ford2rr_dtTableName = 'ford2rrdt'; 
  const ford3fr_dbTableName = 'ford3frpr';
  const ford3fr_dtTableName = 'ford3frdt';
  const ford3rr_dbTableName = 'ford3rrpr';
  const ford3rr_dtTableName = 'ford3rrdt';
  const fca1_dbTableName = 'fca1pr';
  const fca1_dtTableName = 'fca1dt';
  const fca2_dbTableName = 'fca2pr';
  const fca2_dtTableName = 'fca2dt';
  const bt6_dbTableName = 'bt6pr';
  const bt6_dtTableName = 'bt6dt';
  const bmw12v_dbTableName = 'bmw12vpr';
  const bmw12v_dtTableName = 'bmw12vdt';
  const bmw48v_dbTableName = 'bmw48vpr';
  const bmw48v_dtTableName = 'bmw48vdt';
  const fca_flex_bc_dbTableName = 'fca_flex_bcpr';
  const fca_flex_bc_dtTableName = 'fca_flex_bcdt';
  const fca_flex_po_dbTableName = 'fca_flex_popr';
  const fca_flex_po_dtTableName = 'fca_flex_podt';
  const fca_flex_67_dbTableName = 'fca_flex_67pr';
  const fca_flex_67_dtTableName = 'fca_flex_67dt';
  const fca_flex_ne_dbTableName = 'fca_flex_nepr';
  const fca_flex_ne_dtTableName = 'fca_flex_nedt';
  const fca_flex_45_dbTableName = 'fca_flex_45pr';
  const fca_flex_45_dtTableName = 'fca_flex_45dt';

  const headers = [ 'Line', 'Running Time', 'Failure Time', 'Count of Failure', 'Target', 'Actual', 'Achieve', 'Target', 'Actual', 'Achieve', 'Target', 'Actual', 'Achieve'
  ];

  //각 설비별 Data를 받아올 함수 명을 입력해야 됨
  const targetList = reactive({ data: [], })
  const workList = reactive({ data: [], })
  const tr3smt_prTableData = reactive({ data: [] });
  const tr3smt_dtTableData = reactive({ data: [] });
  const tr3crimping_prTableData = reactive({ data: [] });
  const tr3crimping_dtTableData = reactive({ data: [] });
  const tr3fpca_prTableData = reactive({ data: [] });
  const tr3fpca_dtTableData = reactive({ data: [] });    
  const tr3bfa2p_prTableData = reactive({ data: [] });
  const tr3bfa2p_dtTableData = reactive({ data: [] });   
  const tr3bfa3p_prTableData = reactive({ data: [] });
  const tr3bfa3p_dtTableData = reactive({ data: [] });   
  const evo_prTableData = reactive({ data: [] });
  const evo_dtTableData = reactive({ data: [] });   
  const ev2020_60fr_prTableData = reactive({ data: [] });
  const ev2020_60fr_dtTableData = reactive({ data: [] });    
  const ev2020_60rr_prTableData = reactive({ data: [] });
  const ev2020_60rr_dtTableData = reactive({ data: [] });   
  const ev2020_4060fr_prTableData = reactive({ data: [] });
  const ev2020_4060fr_dtTableData = reactive({ data: [] });   
  const ev2020_4060rr_prTableData = reactive({ data: [] });
  const ev2020_4060rr_dtTableData = reactive({ data: [] });   
  const tr2smt_prTableData = reactive({ data: [] });
  const tr2smt_dtTableData = reactive({ data: [] });    
  const tr2crimping_prTableData = reactive({ data: [] });
  const tr2crimping_dtTableData = reactive({ data: [] });   
  const tr2fpca_prTableData = reactive({ data: [] });
  const tr2fpca_dtTableData = reactive({ data: [] });    
  const tr2bfa2p_prTableData = reactive({ data: [] });
  const tr2bfa2p_dtTableData = reactive({ data: [] });    
  const tr2bfa3p_prTableData = reactive({ data: [] });
  const tr2bfa3p_dtTableData = reactive({ data: [] });    
  const ford1fr_prTableData = reactive({ data: [] });
  const ford1fr_dtTableData = reactive({ data: [] });   
  const ford1rr_prTableData = reactive({ data: [] });
  const ford1rr_dtTableData = reactive({ data: [] });   
  const ford2fr_prTableData = reactive({ data: [] });
  const ford2fr_dtTableData = reactive({ data: [] });   
  const ford2rr_prTableData = reactive({ data: [] });
  const ford2rr_dtTableData = reactive({ data: [] });    
  const ford3fr_prTableData = reactive({ data: [] });
  const ford3fr_dtTableData = reactive({ data: [] });    
  const ford3rr_prTableData = reactive({ data: [] });
  const ford3rr_dtTableData = reactive({ data: [] });   
  const fca1_prTableData = reactive({ data: [] });
  const fca1_dtTableData = reactive({ data: [] });   
  const fca2_prTableData = reactive({ data: [] });
  const fca2_dtTableData = reactive({ data: [] });   
  const bt6_prTableData = reactive({ data: [] });
  const bt6_dtTableData = reactive({ data: [] });   
  const bmw12v_prTableData = reactive({ data: [] });
  const bmw12v_dtTableData = reactive({ data: [] });    
  const bmw48v_prTableData = reactive({ data: [] });
  const bmw48v_dtTableData = reactive({ data: [] });   
  const fca_flex_bc_prTableData = reactive({ data: [] });
  const fca_flex_bc_dtTableData = reactive({ data: [] });    
  const fca_flex_po_prTableData = reactive({ data: [] });
  const fca_flex_po_dtTableData = reactive({ data: [] });    
  const fca_flex_67_prTableData = reactive({ data: [] });
  const fca_flex_67_dtTableData = reactive({ data: [] });   
  const fca_flex_ne_prTableData = reactive({ data: [] });
  const fca_flex_ne_dtTableData = reactive({ data: [] });    
  const fca_flex_45_prTableData = reactive({ data: [] });
  const fca_flex_45_dtTableData = reactive({ data: [] });     

  const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value]; return {sendDateData};};     
  const searchData = async (tableName) => {
      const sendDateData = await sendDate();
      console.log('Requesting data with:', {
          tablename: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endtYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
      });
      const formData = {        
          tableName: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
      };  

      targetList.data = [];    
      tr2smt_prTableData.data = [];
      tr2smt_dtTableData.data = [];      
      tr2crimping_prTableData.data = [];
      tr2crimping_dtTableData.data = [];    
      tr2fpca_prTableData.data = [];
      tr2fpca_dtTableData.data = [];     
      tr2bfa2p_prTableData.data = [];
      tr2bfa2p_dtTableData.data = [];     
      tr2bfa3p_prTableData.data = [];
      tr2bfa3p_dtTableData.data = []; 
      tr3smt_prTableData.data = [];
      tr3smt_dtTableData.data = [];
      tr3crimping_prTableData.data = [];
      tr3crimping_dtTableData.data = [];
      tr3fpca_prTableData.data = [];
      tr3fpca_dtTableData.data = []; 
      tr3bfa2p_prTableData.data = [];
      tr3bfa2p_dtTableData.data = [];
      tr3bfa3p_prTableData.data = [];
      tr3bfa3p_dtTableData.data = [];
      evo_prTableData.data = [];
      evo_dtTableData.data = [];  
      ev2020_60fr_prTableData.data = [];
      ev2020_60fr_dtTableData.data = []; 
      ev2020_60rr_prTableData.data = [];
      ev2020_60rr_dtTableData.data = []; 
      ev2020_4060fr_prTableData.data = [];
      ev2020_4060fr_dtTableData.data = [];
      ev2020_4060rr_prTableData.data = [];
      ev2020_4060rr_dtTableData.data = [];
      ford1fr_prTableData.data = [];
      ford1fr_dtTableData.data = [];
      ford1rr_prTableData.data = [];
      ford1rr_dtTableData.data = [];
      ford2fr_prTableData.data = [];
      ford2fr_dtTableData.data = [];
      ford2rr_prTableData.data = [];
      ford2rr_dtTableData.data = [];
      ford3fr_prTableData.data = [];
      ford3fr_dtTableData.data = [];
      ford3rr_prTableData.data = [];
      ford3rr_dtTableData.data = [];
      fca1_prTableData.data = [];
      fca1_dtTableData.data = [];
      fca2_prTableData.data = [];
      fca2_dtTableData.data = [];
      bt6_prTableData.data = [];
      bt6_dtTableData.data = [];
      bmw12v_prTableData.data = [];
      bmw12v_dtTableData.data = [];
      bmw48v_prTableData.data = [];
      bmw48v_dtTableData.data = [];
      fca_flex_bc_prTableData.data = [];
      fca_flex_bc_dtTableData.data = [];
      fca_flex_po_prTableData.data = [];
      fca_flex_po_dtTableData.data = []; 
      fca_flex_67_prTableData.data = [];
      fca_flex_67_dtTableData.data = [];
      fca_flex_ne_prTableData.data = [];
      fca_flex_ne_dtTableData.data = [];
      fca_flex_45_prTableData.data = [];
      fca_flex_45_dtTableData.data = [];


    console.log('Requesting data with:', formData);
    axios.get('/api/searchPeriod', { params: formData }) //각 설비별로 DB table 이름과 변수 이름을 입력해야 됨
      .then((res) => {          
        switch (tableName) {    
          case 'maintenanceworklist': workList.data = res.data; break;       
          case 'tr3smtpr': tr3smt_prTableData.data = res.data; break;
          case 'tr3smtdt': tr3smt_dtTableData.data = res.data; break;  
          case 'tr3crimpingpr': tr3crimping_prTableData.data = res.data; break;
          case 'tr3crimpingdt': tr3crimping_dtTableData.data = res.data; break;  
          case 'tr3fpcapr': tr3fpca_prTableData.data = res.data; break;
          case 'tr3fpcadt': tr3fpca_dtTableData.data = res.data; break;   
          case 'tr3bfa2ppr': tr3bfa2p_prTableData.data = res.data; break;
          case 'tr3bfa2pdt': tr3bfa2p_dtTableData.data = res.data; break;  
          case 'tr3bfa3ppr': tr3bfa3p_prTableData.data = res.data; break;
          case 'tr3bfa3pdt': tr3bfa3p_dtTableData.data = res.data; break;  
          case 'evopr': evo_prTableData.data = res.data; break;
          case 'evodt': evo_dtTableData.data = res.data; break;   
          case 'ev2020_60frpr': ev2020_60fr_prTableData.data = res.data; break;
          case 'ev2020_60frdt': ev2020_60fr_dtTableData.data = res.data; break; 
          case 'ev2020_60rrpr': ev2020_60rr_prTableData.data = res.data; break;
          case 'ev2020_60rrdt': ev2020_60rr_dtTableData.data = res.data; break;  
          case 'ev2020_4060frpr': ev2020_4060fr_prTableData.data = res.data; break;
          case 'ev2020_4060frdt': ev2020_4060fr_dtTableData.data = res.data; break;  
          case 'ev2020_4060rrpr': ev2020_4060rr_prTableData.data = res.data; break;
          case 'ev2020_4060rrdt': ev2020_4060rr_dtTableData.data = res.data; break; 
          case 'tr2smtpr': tr2smt_prTableData.data = res.data; break;
          case 'tr2smtdt': tr2smt_dtTableData.data = res.data; break;    
          case 'tr2crimpingpr': tr2crimping_prTableData.data = res.data; break;
          case 'tr2crimpingdt': tr2crimping_dtTableData.data = res.data; break;  
          case 'tr2fpcapr': tr2fpca_prTableData.data = res.data; break;
          case 'tr2fpcadt': tr2fpca_dtTableData.data = res.data; break;  
          case 'tr2bfa2ppr': tr2bfa2p_prTableData.data = res.data; break;
          case 'tr2bfa2pdt': tr2bfa2p_dtTableData.data = res.data; break;  
          case 'tr2bfa3ppr': tr2bfa3p_prTableData.data = res.data; break;
          case 'tr2bfa3pdt': tr2bfa3p_dtTableData.data = res.data; break;   
          case 'ford1frpr': ford1fr_prTableData.data = res.data; break;
          case 'ford1frdt': ford1fr_dtTableData.data = res.data; break;   
          case 'ford1rrpr': ford1rr_prTableData.data = res.data; break;
          case 'ford1rrdt': ford1rr_dtTableData.data = res.data; break;  
          case 'ford2frpr': ford2fr_prTableData.data = res.data; break;
          case 'ford2frdt': ford2fr_dtTableData.data = res.data; break;  
          case 'ford2rrpr': ford2rr_prTableData.data = res.data; break;
          case 'ford2rrdt': ford2rr_dtTableData.data = res.data; break;  
          case 'ford3frpr': ford3fr_prTableData.data = res.data; break;
          case 'ford3frdt': ford3fr_dtTableData.data = res.data; break; 
          case 'ford3rrpr': ford3rr_prTableData.data = res.data; break;
          case 'ford3rrdt': ford3rr_dtTableData.data = res.data; break;   
          case 'fca1pr': fca1_prTableData.data = res.data; break;
          case 'fca1dt': fca1_dtTableData.data = res.data; break;   
          case 'fca2pr': fca2_prTableData.data = res.data; break;
          case 'fca2dt': fca2_dtTableData.data = res.data; break;   
          case 'bt6pr': bt6_prTableData.data = res.data; break;
          case 'bt6dt': bt6_dtTableData.data = res.data; break;  
          case 'bmw12vpr': bmw12v_prTableData.data = res.data; break;
          case 'bmw12vdt': bmw12v_dtTableData.data = res.data; break;   
          case 'bmw48vpr': bmw48v_prTableData.data = res.data; break;
          case 'bmw48vdt': bmw48v_dtTableData.data = res.data; break;    
          case 'fca_flex_bcpr': fca_flex_bc_prTableData.data = res.data; break;
          case 'fca_flex_bcdt': fca_flex_bc_dtTableData.data = res.data; break;  
          case 'fca_flex_popr': fca_flex_po_prTableData.data = res.data; break;
          case 'fca_flex_podt': fca_flex_po_dtTableData.data = res.data; break;  
          case 'fca_flex_67pr': fca_flex_67_prTableData.data = res.data; break;
          case 'fca_flex_67dt': fca_flex_67_dtTableData.data = res.data; break; 
          case 'fca_flex_nepr': fca_flex_ne_prTableData.data = res.data; break;
          case 'fca_flex_nedt': fca_flex_ne_dtTableData.data = res.data; break; 
          case 'fca_flex_45pr': fca_flex_45_prTableData.data = res.data; break;
          case 'fca_flex_45dt': fca_flex_45_dtTableData.data = res.data; break;                          
         
        }
      });
  };    

   const workSearchData = async (tableName) => {
      const sendDateData = await sendDate();        
      const formData = {        
          tableName: tableName,
          startYear: sendDateData.sendDateData[0],
          startMonth: sendDateData.sendDateData[1],
          startDay: sendDateData.sendDateData[2],
          endYear: sendDateData.sendDateData[3],
          endMonth: sendDateData.sendDateData[4],
          endDay: sendDateData.sendDateData[5],
          type: "failure"
      };  

      workList.data = [];    

    console.log('Requesting data with:', formData);
    axios.get('/api/searchPeriod', { params: formData }) 
      .then((res) => {          
        switch (tableName) {    
          case 'maintenanceworklist': workList.data = res.data; break;             
        }
      });
  };    

  const settingData = reactive({ data: [], });        

  //각 설비별 Data를 호출하는 것을 입력해야 됨
  const callSearchData = async () => {         
      const tableName = 'setting';
      const formData = { tableName: tableName };

      try {
          const res = await axios.get('/api/targetList', { params: formData });
          settingData.data = res.data;

          const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));  
          const isReportEnabled = reportSetting && reportSetting.report === 1;
          if (!isReportEnabled) {
              alert("Report is currently disabled.");
              return;
          }

          targetSearchData();
          workSearchData(workTableName);            
          searchData(tr3smt_dbTableName);
          searchData(tr3smt_dtTableName);     
          searchData(tr3crimping_dbTableName);
          searchData(tr3crimping_dtTableName);  
          searchData(tr3fpca_dbTableName);
          searchData(tr3fpca_dtTableName);   
          searchData(tr3bfa2p_dbTableName);
          searchData(tr3bfa2p_dtTableName);   
          searchData(tr3bfa3p_dbTableName);
          searchData(tr3bfa3p_dtTableName);  
          searchData(evo_dbTableName);
          searchData(evo_dtTableName);  
          searchData(ev2020_60fr_dbTableName);
          searchData(ev2020_60fr_dtTableName); 
          searchData(ev2020_60rr_dbTableName);
          searchData(ev2020_60rr_dtTableName);   
          searchData(ev2020_4060fr_dbTableName);
          searchData(ev2020_4060fr_dtTableName);  
          searchData(ev2020_4060rr_dbTableName);
          searchData(ev2020_4060rr_dtTableName);  
          searchData(tr2smt_dbTableName);
          searchData(tr2smt_dtTableName);   
          searchData(tr2crimping_dbTableName);
          searchData(tr2crimping_dtTableName);  
          searchData(tr2fpca_dbTableName);
          searchData(tr2fpca_dtTableName);    
          searchData(tr2bfa2p_dbTableName);
          searchData(tr2bfa2p_dtTableName);   
          searchData(tr2bfa3p_dbTableName);
          searchData(tr2bfa3p_dtTableName);  
          searchData(ford1fr_dbTableName);
          searchData(ford1fr_dtTableName);   
          searchData(ford1rr_dbTableName);
          searchData(ford1rr_dtTableName);  
          searchData(ford2fr_dbTableName);
          searchData(ford2fr_dtTableName);  
          searchData(ford2rr_dbTableName);
          searchData(ford2rr_dtTableName);   
          searchData(ford3fr_dbTableName);
          searchData(ford3fr_dtTableName);  
          searchData(ford3rr_dbTableName);
          searchData(ford3rr_dtTableName);  
          searchData(fca1_dbTableName);
          searchData(fca1_dtTableName);    
          searchData(fca2_dbTableName);
          searchData(fca2_dtTableName);  
          searchData(bt6_dbTableName);
          searchData(bt6_dtTableName);    
          searchData(bmw12v_dbTableName);
          searchData(bmw12v_dtTableName); 
          searchData(bmw48v_dbTableName);
          searchData(bmw48v_dtTableName);   
          searchData(fca_flex_bc_dbTableName);
          searchData(fca_flex_bc_dtTableName);  
          searchData(fca_flex_po_dbTableName);
          searchData(fca_flex_po_dtTableName);  
          searchData(fca_flex_67_dbTableName);
          searchData(fca_flex_67_dtTableName);  
          searchData(fca_flex_ne_dbTableName);
          searchData(fca_flex_ne_dtTableName);  
          searchData(fca_flex_45_dbTableName);
          searchData(fca_flex_45_dtTableName);  
      
      } catch (error) {
          console.error("Error download excel data:", error.message);
      }
  };   

  //각 설비별로 item 이름과 변수 이름을 입력해 줘야 함
  const targetItem = computed(() => {const items = {}; targetList.data.forEach((d) => {items[d.line] = d;});return items;});
  const workItem = computed(() => { return workList.data.map(d => {return { ...d};});});
  const tr3smt_item = computed(() => { return tr3smt_prTableData.data.map(d => {return { ...d};});});
  const tr3smt_dtItem = computed(() => {return tr3smt_dtTableData.data.map(d => {return { ...d,};});});       
  const tr3crimping_item = computed(() => { return tr3crimping_prTableData.data.map(d => {return { ...d};});});
  const tr3crimping_dtItem = computed(() => {return tr3crimping_dtTableData.data.map(d => {return { ...d,};});});    
  const tr3fpca_item = computed(() => { return tr3fpca_prTableData.data.map(d => {return { ...d};});});
  const tr3fpca_dtItem = computed(() => {return tr3fpca_dtTableData.data.map(d => {return { ...d,};});});        
  const tr3bfa2p_item = computed(() => { return tr3bfa2p_prTableData.data.map(d => {return { ...d};});});
  const tr3bfa2p_dtItem = computed(() => {return tr3bfa2p_dtTableData.data.map(d => {return { ...d,};});});        
  const tr3bfa3p_item = computed(() => { return tr3bfa3p_prTableData.data.map(d => {return { ...d};});});
  const tr3bfa3p_dtItem = computed(() => {return tr3bfa3p_dtTableData.data.map(d => {return { ...d,};});});    
  const evo_item = computed(() => { return evo_prTableData.data.map(d => {return { ...d};});});
  const evo_dtItem = computed(() => {return evo_dtTableData.data.map(d => {return { ...d,};});});        
  const ev2020_60fr_item = computed(() => { return ev2020_60fr_prTableData.data.map(d => {return { ...d};});});
  const ev2020_60fr_dtItem = computed(() => {return ev2020_60fr_dtTableData.data.map(d => {return { ...d,};});});  
  const ev2020_60rr_item = computed(() => { return ev2020_60rr_prTableData.data.map(d => {return { ...d};});});
  const ev2020_60rr_dtItem = computed(() => {return ev2020_60rr_dtTableData.data.map(d => {return { ...d,};});});   
  const ev2020_4060fr_item = computed(() => { return ev2020_4060fr_prTableData.data.map(d => {return { ...d};});});
  const ev2020_4060fr_dtItem = computed(() => {return ev2020_4060fr_dtTableData.data.map(d => {return { ...d,};});});  
  const ev2020_4060rr_item = computed(() => { return ev2020_4060rr_prTableData.data.map(d => {return { ...d};});});
  const ev2020_4060rr_dtItem = computed(() => {return ev2020_4060rr_dtTableData.data.map(d => {return { ...d,};});});  
  const tr2smt_item = computed(() => { return tr2smt_prTableData.data.map(d => {return { ...d};});});
  const tr2smt_dtItem = computed(() => {return tr2smt_dtTableData.data.map(d => {return { ...d,};});});   
  const tr2crimping_item = computed(() => { return tr2crimping_prTableData.data.map(d => {return { ...d};});});
  const tr2crimping_dtItem = computed(() => {return tr2crimping_dtTableData.data.map(d => {return { ...d,};});});   
  const tr2fpca_item = computed(() => { return tr2fpca_prTableData.data.map(d => {return { ...d};});});
  const tr2fpca_dtItem = computed(() => {return tr2fpca_dtTableData.data.map(d => {return { ...d,};});});    
  const tr2bfa2p_item = computed(() => { return tr2bfa2p_prTableData.data.map(d => {return { ...d};});});
  const tr2bfa2p_dtItem = computed(() => {return tr2bfa2p_dtTableData.data.map(d => {return { ...d,};});});    
  const tr2bfa3p_item = computed(() => { return tr2bfa3p_prTableData.data.map(d => {return { ...d};});});
  const tr2bfa3p_dtItem = computed(() => {return tr2bfa3p_dtTableData.data.map(d => {return { ...d,};});});   
  const ford1fr_item = computed(() => { return ford1fr_prTableData.data.map(d => {return { ...d};});});
  const ford1fr_dtItem = computed(() => {return ford1fr_dtTableData.data.map(d => {return { ...d,};});});   
  const ford1rr_item = computed(() => { return ford1rr_prTableData.data.map(d => {return { ...d};});});
  const ford1rr_dtItem = computed(() => {return ford1rr_dtTableData.data.map(d => {return { ...d,};});}); 
  const ford2fr_item = computed(() => { return ford2fr_prTableData.data.map(d => {return { ...d};});});
  const ford2fr_dtItem = computed(() => {return ford2fr_dtTableData.data.map(d => {return { ...d,};});}); 
  const ford2rr_item = computed(() => { return ford2rr_prTableData.data.map(d => {return { ...d};});});
  const ford2rr_dtItem = computed(() => {return ford2rr_dtTableData.data.map(d => {return { ...d,};});});  
  const ford3fr_item = computed(() => { return ford3fr_prTableData.data.map(d => {return { ...d};});});
  const ford3fr_dtItem = computed(() => {return ford3fr_dtTableData.data.map(d => {return { ...d,};});}); 
  const ford3rr_item = computed(() => { return ford3rr_prTableData.data.map(d => {return { ...d};});});
  const ford3rr_dtItem = computed(() => {return ford3rr_dtTableData.data.map(d => {return { ...d,};});}); 
  const fca1_item = computed(() => { return fca1_prTableData.data.map(d => {return { ...d};});});
  const fca1_dtItem = computed(() => {return fca1_dtTableData.data.map(d => {return { ...d,};});});    
  const fca2_item = computed(() => { return fca2_prTableData.data.map(d => {return { ...d};});});
  const fca2_dtItem = computed(() => {return fca2_dtTableData.data.map(d => {return { ...d,};});});  
  const bt6_item = computed(() => { return bt6_prTableData.data.map(d => {return { ...d};});});
  const bt6_dtItem = computed(() => {return bt6_dtTableData.data.map(d => {return { ...d,};});});    
  const bmw12v_item = computed(() => { return bmw12v_prTableData.data.map(d => {return { ...d};});});
  const bmw12v_dtItem = computed(() => {return bmw12v_dtTableData.data.map(d => {return { ...d,};});});  
  const bmw48v_item = computed(() => { return bmw48v_prTableData.data.map(d => {return { ...d};});});
  const bmw48v_dtItem = computed(() => {return bmw48v_dtTableData.data.map(d => {return { ...d,};});}); 
  const fca_flex_bc_item = computed(() => { return fca_flex_bc_prTableData.data.map(d => {return { ...d};});});
  const fca_flex_bc_dtItem = computed(() => {return fca_flex_bc_dtTableData.data.map(d => {return { ...d,};});});  
  const fca_flex_po_item = computed(() => { return fca_flex_po_prTableData.data.map(d => {return { ...d};});});
  const fca_flex_po_dtItem = computed(() => {return fca_flex_po_dtTableData.data.map(d => {return { ...d,};});}); 
  const fca_flex_67_item = computed(() => { return fca_flex_67_prTableData.data.map(d => {return { ...d};});});
  const fca_flex_67_dtItem = computed(() => {return fca_flex_67_dtTableData.data.map(d => {return { ...d,};});}); 
  const fca_flex_ne_item = computed(() => { return fca_flex_ne_prTableData.data.map(d => {return { ...d};});});
  const fca_flex_ne_dtItem = computed(() => {return fca_flex_ne_dtTableData.data.map(d => {return { ...d,};});}); 
  const fca_flex_45_item = computed(() => { return fca_flex_45_prTableData.data.map(d => {return { ...d};});});
  const fca_flex_45_dtItem = computed(() => {return fca_flex_45_dtTableData.data.map(d => {return { ...d,};});});      

  const sums = computed(() => {   
    let sumsObject = {};
    targets.forEach((target) => {
      if (target.pr1) {
        sumsObject[target.pr1] = { work_minute: 0 };
        sumsObject[target.pr2] = { work_minute: 0 };
      } else if (target.pr) {
        sumsObject[target.pr] = { work_minute: 0 };
      }
      if (target.dt1) {
        sumsObject[target.dt1] = { time: 0 };
        sumsObject[target.dt2] = { time: 0 };
      } else if (target.dt) {
        sumsObject[target.dt] = { time: 0 };
      }
      sumsObject[target.name] = { time: 0, count: 0 };
    });

    // 설비 추가시 여기에 Data 추가해야함
    for (const row of workItem.value) {        
      if (sumsObject[row.line]) {
        sumsObject[row.line].time += row.time;
        sumsObject[row.line].count += 1;
      }
    }   
    for (const row of tr3smt_item.value) {
      const targetKey = 'tr3smtpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr3smt_dtItem.value) {
      const targetKey = 'tr3smtdt';
      sumsObject[targetKey].time += row.time;
    }      
    for (const row of tr3crimping_item.value) {
      const targetKey = 'tr3crimpingpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr3crimping_dtItem.value) {
      const targetKey = 'tr3crimpingdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr3fpca_item.value) {
      const targetKey = 'tr3fpcapr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr3fpca_dtItem.value) {
      const targetKey = 'tr3fpcadt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr3bfa2p_item.value) {
      const targetKey = 'tr3bfa2ppr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr3bfa2p_dtItem.value) {
      const targetKey = 'tr3bfa2pdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr3bfa3p_item.value) {
      const targetKey = 'tr3bfa3ppr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr3bfa3p_dtItem.value) {
      const targetKey = 'tr3bfa3pdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of evo_item.value) {
      const targetKey = 'evopr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of evo_dtItem.value) {
      const targetKey = 'evodt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ev2020_60fr_item.value) {
      const targetKey = 'ev2020_60frpr';  
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ev2020_60fr_dtItem.value) {
      const targetKey = 'ev2020_60frdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ev2020_60rr_item.value) {
      const targetKey = 'ev2020_60rrpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ev2020_60rr_dtItem.value) {
      const targetKey = 'ev2020_60rrdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ev2020_4060fr_item.value) {
      const targetKey = 'ev2020_4060frpr'; 
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ev2020_4060fr_dtItem.value) {
      const targetKey = 'ev2020_4060frdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ev2020_4060rr_item.value) {
      const targetKey = 'ev2020_4060rrpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ev2020_4060rr_dtItem.value) {
      const targetKey = 'ev2020_4060rrdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr2smt_item.value) {
      const targetKey = 'tr2smtpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr2smt_dtItem.value) {
      const targetKey = 'tr2smtdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr2crimping_item.value) {
      const targetKey = 'tr2crimpingpr'; 
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr2crimping_dtItem.value) {
      const targetKey = 'tr2crimpingdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr2fpca_item.value) {
      const targetKey = 'tr2fpcapr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr2fpca_dtItem.value) {
      const targetKey = 'tr2fpcadt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr2bfa2p_item.value) {
      const targetKey = 'tr2bfa2ppr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr2bfa2p_dtItem.value) {
      const targetKey = 'tr2bfa2pdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of tr2bfa3p_item.value) {
      const targetKey = 'tr2bfa3ppr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of tr2bfa3p_dtItem.value) {
      const targetKey = 'tr2bfa3pdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ford1fr_item.value) {
      const targetKey = 'ford1frpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ford1fr_dtItem.value) {
      const targetKey = 'ford1frdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ford1rr_item.value) {
      const targetKey = 'ford1rrpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ford1rr_dtItem.value) {
      const targetKey = 'ford1rrdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ford2fr_item.value) {
      const targetKey = 'ford2frpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ford2fr_dtItem.value) {
      const targetKey = 'ford2frdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ford2rr_item.value) {
      const targetKey = 'ford2rrpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ford2rr_dtItem.value) {
      const targetKey = 'ford2rrdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ford3fr_item.value) {
      const targetKey = 'ford3frpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ford3fr_dtItem.value) {
      const targetKey = 'ford3frdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of ford3rr_item.value) {
      const targetKey = 'ford3rrpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of ford3rr_dtItem.value) {
      const targetKey = 'ford3rrdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of fca1_item.value) {
      const targetKey = 'fca1pr';     
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of fca1_dtItem.value) {
      const targetKey = 'fca1dt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of fca2_item.value) {
      const targetKey = 'fca2pr';  
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of fca2_dtItem.value) {
      const targetKey = 'fca2dt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of bt6_item.value) {
      const targetKey = 'bt6pr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of bt6_dtItem.value) {
      const targetKey = 'bt6dt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of bmw12v_item.value) {
      const targetKey = 'bmw12vpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of bmw12v_dtItem.value) {
      const targetKey = 'bmw12vdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of bmw48v_item.value) {
      const targetKey = 'bmw48vpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of bmw48v_dtItem.value) {
      const targetKey = 'bmw48vdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of fca_flex_bc_item.value) {
      const targetKey = 'fca_flex_bcpr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of fca_flex_bc_dtItem.value) {
      const targetKey = 'fca_flex_bcdt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of fca_flex_po_item.value) {
      const targetKey = 'fca_flex_popr'; 
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of fca_flex_po_dtItem.value) {
      const targetKey = 'fca_flex_podt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of fca_flex_67_item.value) {
      const targetKey = 'fca_flex_67pr';   
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of fca_flex_67_dtItem.value) {
      const targetKey = 'fca_flex_67dt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of fca_flex_ne_item.value) {
      const targetKey = 'fca_flex_nepr';  
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of fca_flex_ne_dtItem.value) {
      const targetKey = 'fca_flex_nedt';
      sumsObject[targetKey].time += row.time;
    }
    for (const row of fca_flex_45_item.value) {
      const targetKey = 'fca_flex_45pr';
      sumsObject[targetKey].work_minute += row.work_minute;
    }
    for (const row of fca_flex_45_dtItem.value) {
      const targetKey = 'fca_flex_45dt';
      sumsObject[targetKey].time += row.time;
    }
    return sumsObject;
  });

  const targetSearchData= async () => {        

    const formData = {
      tableName: targetTableName,
      year: startYear.value,
      
    };

    console.log('Requesting data with:', formData);

    axios.get('/api/targetList', { params: formData })
      .then((res) => {
        console.log("Response from /api/target:", res.data);
        targetList.data = res.data
      })
      .catch((error) => {
        console.error("Error fetching target data:", error.message);
      });
    };

  const callTarget = (lineName) => {return targetItem.value[lineName] || {};};

  const aS = (value) => {
    if (isNaN(value)) {
        return { backgroundColor: 'transparent' }; // Transparent color
    } else if (value < 0.8499) {
        return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // Light red
    } else if (value < 0.9499) {
        return { backgroundColor: 'rgba(255, 255, 0, 0.3)' }; // Light yellow
    } else {
        return { backgroundColor: 'rgba(144, 238, 144, 0.3)' }; // Light green
    }
  };

  // 설비 추가시 여기에 Data 추가해야함
  const targets = reactive([  
        { name: "TR2 SMT", pr: "tr2smtpr", dt: "tr2smtdt", },
        { name: "TR2 Crimping", pr: "tr2crimpingpr", dt: "tr2crimpingdt",  },
        { name: "TR2 FPCA", pr: "tr2fpcapr", dt: "tr2fpcadt",  },
        { name: "TR2 BFA 2P", pr: "tr2bfa2ppr", dt: "tr2bfa2pdt", },
        { name: "TR2 BFA 3P", pr: "tr2bfa3ppr", dt: "tr2bfa3pdt", },   
        { name: "TR3 SMT", pr: "tr3smtpr", dt: "tr3smtdt", },
        { name: "TR3 Crimping", pr: "tr3crimpingpr", dt: "tr3crimpingdt",  },
        { name: "TR3 FPCA", pr: "tr3fpcapr", dt: "tr3fpcadt",  },
        { name: "EVO 1", pr: "tr3bfa2ppr", dt: "tr3bfa2pdt",},
        { name: "EVO 2", pr: "tr3bfa3ppr", dt: "tr3bfa3pdt", },        
        { name: "EVO Hybrid", pr: "evopr", dt: "evodt",},     
        { name: "EV2020 60kW", pr1: "ev2020_60frpr", pr2: "ev2020_60rrpr", dt1: "ev2020_60frdt", dt2: "ev2020_60rrdt" },   
        { name: "EV2020 4060kW", pr1: "ev2020_4060frpr", pr2: "ev2020_4060rrpr", dt1: "ev2020_4060frdt", dt2: "ev2020_4060rrdt" },     
        { name: "Ford 1 Front", pr: "ford1frpr", dt: "ford1frdt",  },     
        { name: "Ford 1 Rear", pr: "ford1rrpr", dt: "ford1rrdt",  },     
        { name: "Ford 2 Front", pr: "ford2frpr", dt: "ford2frdt",  },     
        { name: "Ford 2 Rear", pr: "ford2rrpr", dt: "ford2rrdt", },     
        { name: "Ford 3 Front", pr: "ford3frpr", dt: "ford3frdt",  },     
        { name: "Ford 3 Rear", pr: "ford3rrpr", dt: "ford3rrdt",  },     
        { name: "FCA 1", pr: "fca1pr", dt: "fca1dt",  },    
        { name: "FCA 2", pr: "fca2pr", dt: "fca2dt", },    
        { name: "BT6", pr: "bt6pr", dt: "bt6dt",  },    
        { name: "BMW12V", pr: "bmw12vpr", dt: "bmw12vdt",  },    
        { name: "BMW48V", pr: "bmw48vpr", dt: "bmw48vdt",  },    
        { name: "FCA FLEX BC", pr: "fca_flex_bcpr", dt: "fca_flex_bcdt", },
        { name: "FCA FLEX PO", pr: "fca_flex_popr", dt: "fca_flex_podt", },
        { name: "FCA FLEX 67", pr: "fca_flex_67pr", dt: "fca_flex_67dt", },
        { name: "FCA FLEX NE", pr: "fca_flex_nepr", dt: "fca_flex_nedt", },
        { name: "FCA FLEX 45", pr: "fca_flex_45pr", dt: "fca_flex_45dt", },          
  ]);    

  const getTableRowData = (targetName) => {
    const target = targets.find((t) => t.name === targetName);      
  
    if (!target) { return {}; }
    
    const sumsObject = sums.value;

     // dtData의 합산 (예시로 구현)
    // const dtData1 = sumsObject[target.dt1] || { time: 0 };
    // const dtData2 = sumsObject[target.dt2] || { time: 0 };
    // const dtData = {
    //   time: dtData1.time + dtData2.time
    // }; 
    let prData = { work_minute: 0 };
    if (target.pr1) {
      const prData1 = sumsObject[target.pr1] || { work_minute: 0 };
      const prData2 = sumsObject[target.pr2] || { work_minute: 0 };
      prData.work_minute = prData1.work_minute + prData2.work_minute;
    } else {
      prData = sumsObject[target.pr] || { work_minute: 0 };
    }
    const workData = sumsObject[target.name] || {};        
    const targetData = callTarget(targetName);       

    const workMinute = prData.work_minute;
    const failureTime = workData.time;
    const failureCount = workData.count;
    const mttrTarget = targetData.mttr;
    const actualMttr = (failureTime / failureCount).toFixed(1);
    const achieveMttr = ((mttrTarget / actualMttr) * 100).toFixed(1);
    const mtbfTarget = targetData.mtbf;
    const actualMtbf = (workMinute / failureCount).toFixed(1);
    const achievMmtbf = ((actualMtbf /mtbfTarget ) * 100).toFixed(1);
    const failureRateTarget = targetData.failurerate;      
    const actualFailureRate = ((failureTime / workMinute) * 100).toFixed(1);
    const achieveFailureRate = ((failureRateTarget / actualFailureRate) * 100).toFixed(1);
    
    //const downTime = dtData.time;
    

    return { workMinute, failureTime, failureCount, 
             mttrTarget, actualMttr, achieveMttr, mtbfTarget, actualMtbf, achievMmtbf, failureRateTarget, actualFailureRate, achieveFailureRate
      
                 
    };
  };   
 
  const rowData = computed(() => { return targets.map((target) => getTableRowData(target.name)); });

  return {
    headers, aS, startYear, startMonth, startDay, endYear, endMonth, endDay,
    targetItem, targetList, targetTableName, callTarget, targets, getTableRowData,
    searchData, workSearchData, callSearchData, sendDate, sums, rowData, 
  };
},
};
</script>

<style scoped>
 * {
  box-sizing: border-box;
}

.container {
  margin-top: 0px;
  padding-top: 0px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.search {
position: relative;
background-color: #ffffff; /* Set a background color for better visibility */  
padding: 0px; /* Add some padding for better appearance */
height: auto;
width: 800px;
}
.start,
.end {
  display: flex;
  flex-direction: row;    
  border: 0px;
}

.start input,
.end input {
  margin-right: 5px;
}

.separator {
  font-size: 24px;
  margin: 0 10px;
  border: 0px;
}
.searchTd {    
  border: 0px;
}

input::placeholder {
  color: gray;
}
label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

select,
input[type="number"],
input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100px;
}

button {
  padding: 5px 5px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

table {
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 8px;
  font-size: 12px;    
}

th {
  position: sticky;
  top: 0;    
  z-index: 10;
  background-color: rgb(230, 230, 230) ;
  border : 1px solid black;        
  text-align : center;        
  padding-left : 10px;
  padding-right : 10px;
  font-size : 12px; 
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}

tr:hover {
  background-color: #e6e6e6;
}
.ok {
background-color: #94b7f8; /* Adjust the color as needed */
font-weight: bold;
}
.ng {
background-color: #fa7979; /* Adjust the color as needed */
font-weight: bold;
}
.ng_type {
background-color: #fdb08d; /* Adjust the color as needed */
}

.okhead {
background-color: #8dc7fd; /* Adjust the color as needed */
}
.nghead {
background-color: #fdb08d; /* Adjust the color as needed */
} 
.tacttimehead {
background-color: #fac069; /* Adjust the color as needed */
} 
.downtimehead {
background-color: #569e56; /* Adjust the color as needed */
} 
.oeehead {
background-color: #bbbd4a; /* Adjust the color as needed */
} 
</style>